import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import { FormattedMessage } from "react-intl"
//import Card from "react-bootstrap/Card"
import Image from "shared/Image"
import Select from "shared/Select"
import Loading from "shared/Loading"
import _ from "lodash"
import classNames from "classnames"

function MyAlerts(props) {
  let {
    filterBy,
    setFilterBy,
    sortBy,
    setSortBy,
    sortByOpts,
    filterByOpts,
    alertTypeToAlertText,
    //isHeaderAlerts,
    alerts,
    markAlertRead,
    goToView,
    onClickAccessSettings,
    loader,
  } = props

  const isHeaderAlert = false
  let alertsF = _.clone(alerts)

  // if (filterBy.v != "all")
  //   alertsF = alertsF?.filter((d) => d.isRead == filterBy.v)

  if (filterBy.v != "all") {
    if (filterBy.v === true || filterBy.v === false) {
      alertsF = alertsF.filter((d) => d.isRead == filterBy.v)
    } else {
      alertsF = alertsF.filter((d) => d.alertType === filterBy.v)
    }
  }

  if (sortBy.v != "desc") alertsF = alertsF.reverse()

  if (sortBy.v === "desc") {
    alertsF = alertsF.sort((a, b) => b.date - a.date)
  } else if (sortBy.v === "asc") {
    alertsF = alertsF.sort((a, b) => a.date - b.date)
  } else {
    alertsF.sort((a, b) => {
      const aContains = a.alertType.includes(sortBy.v)
      const bContains = b.alertType.includes(sortBy.v)

      if (aContains && !bContains) return -1
      if (!aContains && bContains) return 1

      // if (a.alertType === sortBy.v) return -1;
      // if (b.alertType === sortBy.v) return 1;
      return 0
    })
  }

  return (
    <Fragment>
      {loader ? (
        <div>
          <div className="loader" style={{ top: 250 }}></div>
        </div>
      ) : (
        <div
          className={classNames({ "row m-0 text-left mt-2": !isHeaderAlert })}
        >
          <div
            className={classNames({ "col-9 m-0 myprofile": !isHeaderAlert })}
          >
            <Fragment>
              <div
                className={classNames("row mx-0 my-3", {
                  headingalert: !isHeaderAlert,
                  "modelsection m-0": isHeaderAlert,
                })}
              >
                {!isHeaderAlert && (
                  <>
                    <div
                      className="col-5 pl-2 mb-2 alerttext"
                      style={{
                        fontSize: "1.25rem",
                        // marginTop: "-5px",
                      }}
                    >
                      <FormattedMessage id="alertview.alerts" />
                    </div>
                    <div
                      className="col-1 labeltext p-0 line-height"
                      style={{ position: "relative", right: 10 }}
                    >
                      <FormattedMessage id="alertview.sortby" />
                    </div>
                    <div className="col-2 text-left mni-alert-sortby">
                      <div className="sortby">
                        <Select
                          options={sortByOpts}
                          labelKey="l"
                          className="sort-dropdown"
                          selected={sortBy}
                          onSelect={setSortBy}
                        />
                      </div>
                    </div>
                    <div
                      className="col-2 labeltext p-0 line-height"
                      style={{
                        position: "relative",
                        left: 42,
                        marginLeft: -30,
                      }}
                    >
                      <FormattedMessage id="alertview.filterby" />
                    </div>
                    <div className="col-2 text-left mni-alert-filterby">
                      <div className="alertby">
                        <Select
                          options={filterByOpts}
                          labelKey="l"
                          className="sort-dropdown"
                          selected={filterBy}
                          onSelect={setFilterBy}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <Loading id="alert_notification">
                <div
                  style={{ marginBottom: 10, marginLeft: 10 }}
                  className={classNames({
                    mnidatarender: !isHeaderAlert,
                    "col-12": isHeaderAlert,
                  })}
                >
                  {!alertsF[0] && !isHeaderAlert && (
                    <div className="col-sm-12 col-md-12 col-lg-12 text-center nodata">
                      <FormattedMessage id="nodata.alerts" />
                    </div>
                  )}
                  {alertsF.map((d, i) => (
                    <div
                      key={i}
                      onClick={() => markAlertRead(d, i)}
                      className={
                        d.isRead
                          ? "row m-0 no-pointer eachrow-read eachrow"
                          : "row eachrow m-0 no-pointer eachrow-unread"
                      }
                    >
                      <div
                        className={classNames("pl-5", {
                          "col-11 padding": !isHeaderAlert,
                          "col-sm-10": isHeaderAlert,
                        })}
                      >
                        <div className="heading-mnialert text-break">
                          <FormattedMessage
                            id={alertTypeToAlertText[d.alertType]}
                            values={d}
                          />
                          <span
                            style={{
                              color: "#337AB7",
                              cursor: "pointer",
                              textDecoration: "únderline",
                              fontWeight: "bold",
                              marginLeft: "8px",
                            }}
                            onClick={() => goToView(d, i)}
                          >
                            {d.alertData + "    "}
                          </span>
                        </div>
                        <div
                          className={classNames({
                            subtextalert: !isHeaderAlert,
                            subtext: isHeaderAlert,
                          })}
                        >
                          <div className="datetime">{d.date}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Loading>
            </Fragment>
          </div>
          {!isHeaderAlert && (
            <div className="col-3 alert-setting">
              <div className="text-right">
                <span className="closed mr-0"></span>
              </div>
              <div
                className="alert-wrap"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="">
                  <Image
                    className="alertimg"
                    src="clock1.svg"
                    alt=""
                    width="120"
                    height="120"
                  />
                </div>
              </div>
              <div
                className="alert-content name"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FormattedMessage id="alertview.alertsettingname" />
              </div>
              <div className="ml-2 alert-content text">
                <FormattedMessage id="alertview.dummytext" />
              </div>
              <div
                className="alert-btn "
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={() => {
                    onClickAccessSettings()
                  }}
                >
                  Access settings
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Old code commented */}
      {/* <div className="col-sm-12 col-md-12 col-lg-12 d-flex mni-myalert">
        <div className="col-sm-4 labelgenerator myprofile">
          <FormattedMessage id="myalerts.heading" />
        </div>
        <div className="form-input-block col-sm-4 mni-style">
          <span className="label">
            <FormattedMessage id="alertview.sortby" />
          </span>
          <Select
            options={sortByOpts}
            labelKey="l"
            className="sort-dropdown w-90"
            selected={sortBy}
            onSelect={setSortBy}
          />
        </div>
        <div className="form-input-block col-sm-4 mni-style">
          <span className="label">
            <FormattedMessage id="alertview.filterby" />
          </span>
          <Select
            options={filterByOpts}
            labelKey="l"
            className="sort-dropdown w-90"
            selected={filterBy}
            onSelect={setFilterBy}
          />
        </div>
      </div> */}
      {/* <div style={{ marginTop: 76 }}> */}
      {/* {!alertsF[0] && (
          <div className="col-sm-12 col-md-12 col-lg-12 text-center nodata">
            <FormattedMessage id="nodata.alerts" />
          </div>
        )} */}
      {/* {alertsF.map((d, i) => ( */}
      {/* <Card
            key={i}
            onClick={() => markAlertRead(d, i)}
            className={
              d.isRead ? "myalert-read-notifi" : "myalert-unread-notifi"
            }
            body
          >
            <div className="card-content">
              <FormattedMessage
                id={alertTypeToAlertText[d.alertType]}
                values={d}
              /> */}
      {/* <span
                style={{
                  color: "#337AB7",
                  cursor: "pointer",
                  textDecoration: "únderline",
                  fontWeight: "bold",
                }}
                onClick={() => goToView(d, i)}
              > */}
      {/* {" " + d.alertData[0] + " "} */}
      {/* {" " + d.alertData + " "} */}
      {/* </span> */}
      {/* </div>
            <div className="card-timestamp">
              <div>{d.date}</div>
              <div></div>
            </div> */}
      {/* </Card> */}
      {/* ))} */}
      {/* </div> */}
    </Fragment>
  )
}
export default enhancer(MyAlerts)
