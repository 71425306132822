import React from "react"
import Image from "shared/HelpImage/index"

function MyOrders() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3 ">Dashboard: My Orders</h3>
      <p className="text-justify pl-3 pt-2">
        <p>
          The My Order calendar shows the current month and year. There are
          predefined search options for Month, Week, and Today that the user can
          choose.{" "}
        </p>
        <p>
          The user has the option to click on the green arrows to move to the
          next month or the previous months.{" "}
        </p>
        <p>
          Each Green dot on the calendar represents there is atleast one service
          scheduled for that day.{" "}
        </p>
        <p>
          The grey dot is representing that there is at least one projected
          services for that day.
        </p>
        <p>The blue dot is the current date.</p>
        <p>
          If there is a day with no dot, then there are no services completed or
          projected for that day.
        </p>
        <Image src="my_order_help.png" alt="" />
        <p>
          The My Orders calendar shows the dots representing completed services
          and projected services.
        </p>
        <p>
          If the user chooses a specific range on the calendar (month, week,
          today), a listing of the completed and/or projected services will show
          up in a listing under “Services” at the bottom of the calendar.
        </p>
        <p>
          If the user clicks on a specific date, the services on just that date
          will be displayed under “Services” as well.
        </p>
        <p>The “View All” link will jump the user to the “my order” page.</p>
        <Image src="myorders-dashboard.png" alt="" />
      </p>
    </div>
  )
}
export default MyOrders
