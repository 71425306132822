import React, { useState, useEffect, useRef } from "react"
import { BarChart, Bar, XAxis } from "recharts"
import { useWindowDimension } from "shared"
import colors from "./colors"
import "./style.scss"
import { weekDays, weeks } from "shared"

const Chart = ({ data, config, height }) => {
  const [width, setWidth] = useState(0)
  const elementRef = useRef(null)
  if (data[0]?.day == "remove") data?.splice(0, 1)
  let dim = useWindowDimension()

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width)
  }, dim)

  let xaxis = (d) => {
    let df = weekDays.includes(d)
      ? d.match(/^.{0,1}/, "")[0]
      : weeks.includes(d)
      ? d
      : d
    return `${df}`
  }

  return (
    <div ref={elementRef}>
      <BarChart
        width={width + 50}
        height={height}
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <XAxis dataKey={config.x} interval={0} tickFormatter={xaxis} />
        <Bar
          dataKey={config.y.key}
          name={config.y.label}
          fill={colors.bar_lite}
          barSize={10}
        />
      </BarChart>
    </div>
  )
}

export default Chart
