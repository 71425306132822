import React, { useState, useEffect, useRef } from "react"
import { AreaChart, Area, XAxis } from "recharts"
import { useWindowDimension } from "shared"
import colors from "./colors"
import "./style.scss"
import { weekDays, weeks } from "shared"

const Chart = ({ data, config, height, type = "linear" }) => {
  const [width, setWidth] = useState(0)
  const elementRef = useRef(null)
  if (data[0].day == "remove") data.splice(0, 1)
  let metaColors = {
    linear: {
      stroke: colors.area_linear,
      fill: "url(#area_linear)",
    },
    monotone: {
      stroke: colors.area_monotone,
      fill: "url(#area_monotone)",
    },
  }

  let xaxis = (d) => {
    let df = weekDays.includes(d)
      ? d.match(/^.{0,1}/, "")[0]
      : weeks.includes(d)
      ? d
      : d
    return `${df}`
  }

  let dim = useWindowDimension()

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width)
  }, dim)

  return (
    <div className="areachart" ref={elementRef}>
      <AreaChart
        width={width + 30}
        height={height}
        data={data}
        margin={{ top: 0, right: 20, left: 15, bottom: 0 }}
      >
        <defs>
          <linearGradient id="area_linear" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={colors.area_linear}
              stopOpacity={0.8}
            />
            <stop offset="95%" stopColor={colors.area_linear} stopOpacity={0} />
          </linearGradient>
          <linearGradient id="area_monotone" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={colors.area_monotone}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={colors.area_monotone}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <XAxis dataKey={config.x} interval={0} tickFormatter={xaxis} />
        <Area
          dataKey={config.y.key}
          name={config.y.label}
          fillOpacity={1}
          type={type}
          stroke={metaColors[type].stroke}
          fill={metaColors[type].fill}
        />
      </AreaChart>
    </div>
  )
}

export default Chart
