const alerts = [
  //"profile_resubmit",
  "profile_expiring",
  "profile_creation",
  "profile_approved",
  "generator_creation",
]
const alertsCOMMUser = ["generator_creation"]

export { alerts, alertsCOMMUser }
