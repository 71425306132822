import React from "react"
import { Quill } from "react-quill"

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 6.25H4.88438L7.12625 4.00875L6.25 3.125L2.5 6.875L6.25 10.625L7.12625 9.74062L4.88625 7.5H12.5C13.4946 7.5 14.4484 7.89509 15.1517 8.59835C15.8549 9.30161 16.25 10.2554 16.25 11.25C16.25 12.2446 15.8549 13.1984 15.1517 13.9017C14.4484 14.6049 13.4946 15 12.5 15H7.5V16.25H12.5C13.8261 16.25 15.0979 15.7232 16.0355 14.7855C16.9732 13.8479 17.5 12.5761 17.5 11.25C17.5 9.92392 16.9732 8.65215 16.0355 7.71447C15.0979 6.77678 13.8261 6.25 12.5 6.25Z"
      fill="#212529"
    />
  </svg>
)

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 6.25H15.1156L12.8737 4.00875L13.75 3.125L17.5 6.875L13.75 10.625L12.8737 9.74062L15.1137 7.5H7.5C6.50544 7.5 5.55161 7.89509 4.84835 8.59835C4.14509 9.30161 3.75 10.2554 3.75 11.25C3.75 12.2446 4.14509 13.1984 4.84835 13.9017C5.55161 14.6049 6.50544 15 7.5 15H12.5V16.25H7.5C6.17392 16.25 4.90215 15.7232 3.96447 14.7855C3.02678 13.8479 2.5 12.5761 2.5 11.25C2.5 9.92392 3.02678 8.65215 3.96447 7.71447C4.90215 6.77678 6.17392 6.25 7.5 6.25Z"
      fill="#212529"
    />
  </svg>
)

const CustomLink = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2813 4.22503C17.9329 3.87543 17.5189 3.59804 17.0631 3.40877C16.6073 3.2195 16.1186 3.12207 15.625 3.12207C15.1315 3.12207 14.6428 3.2195 14.187 3.40877C13.7312 3.59804 13.3172 3.87543 12.9688 4.22503L13.8563 5.11253C14.089 4.87984 14.3652 4.69526 14.6692 4.56934C14.9733 4.44341 15.2991 4.37859 15.6282 4.37859C15.9572 4.37859 16.2831 4.44341 16.5871 4.56934C16.8911 4.69526 17.1674 4.87984 17.4 5.11253C17.6327 5.34521 17.8173 5.62145 17.9432 5.92547C18.0692 6.22949 18.134 6.55533 18.134 6.8844C18.134 7.21347 18.0692 7.53931 17.9432 7.84333C17.8173 8.14735 17.6327 8.42359 17.4 8.65628L12.4 13.6563C11.9309 14.1262 11.2944 14.3905 10.6304 14.3911C9.96638 14.3917 9.32935 14.1285 8.85942 13.6594C8.38949 13.1903 8.12515 12.5537 8.12457 11.8897C8.12398 11.2257 8.38719 10.5887 8.85629 10.1188L9.73754 9.23128L8.85629 8.34378L7.96879 9.23128C7.61919 9.57966 7.3418 9.99364 7.15253 10.4495C6.96326 10.9053 6.86583 11.394 6.86583 11.8875C6.86583 12.3811 6.96326 12.8698 7.15253 13.3256C7.3418 13.7814 7.61919 14.1954 7.96879 14.5438C8.67597 15.2419 9.63134 15.6308 10.625 15.625C11.1205 15.6271 11.6114 15.5309 12.0695 15.3421C12.5276 15.1533 12.9437 14.8756 13.2938 14.525L18.2938 9.52503C18.9944 8.82025 19.3866 7.86619 19.3842 6.87244C19.3819 5.87869 18.9852 4.9265 18.2813 4.22503Z"
      fill="#212529"
    />
    <path
      d="M2.61879 15.5125C2.38541 15.2802 2.20022 15.0041 2.07386 14.7C1.94749 14.396 1.88244 14.0699 1.88244 13.7407C1.88244 13.4114 1.94749 13.0853 2.07386 12.7813C2.20022 12.4772 2.38541 12.2011 2.61879 11.9688L7.61879 6.96878C7.85109 6.7354 8.1272 6.55021 8.43127 6.42384C8.73534 6.29748 9.06138 6.23243 9.39067 6.23243C9.71995 6.23243 10.046 6.29748 10.3501 6.42384C10.6541 6.55021 10.9302 6.7354 11.1625 6.96878C11.3944 7.2029 11.577 7.48119 11.6994 7.78716C11.8218 8.09313 11.8815 8.42055 11.875 8.75003C11.8769 9.08053 11.8133 9.40813 11.6878 9.71388C11.5623 10.0196 11.3774 10.2974 11.1438 10.5313L9.81879 11.875L10.7063 12.7625L12.0313 11.4375C12.7366 10.7322 13.1328 9.77561 13.1328 8.77815C13.1328 7.78069 12.7366 6.82409 12.0313 6.11878C11.326 5.41347 10.3694 5.01723 9.37192 5.01723C8.37446 5.01723 7.41785 5.41347 6.71254 6.11878L1.71254 11.1188C1.362 11.4673 1.08382 11.8816 0.893994 12.338C0.704168 12.7944 0.606445 13.2839 0.606445 13.7782C0.606445 14.2725 0.704168 14.7619 0.893994 15.2183C1.08382 15.6747 1.362 16.089 1.71254 16.4375C2.42431 17.1303 3.38185 17.5124 4.37504 17.5C5.37698 17.501 6.33862 17.1055 7.05004 16.4L6.16254 15.5125C5.93025 15.7459 5.65413 15.9311 5.35006 16.0575C5.04599 16.1838 4.71995 16.2489 4.39067 16.2489C4.06138 16.2489 3.73534 16.1838 3.43127 16.0575C3.1272 15.9311 2.85109 15.7459 2.61879 15.5125Z"
      fill="#212529"
    />
  </svg>
)
// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo()
}
function redoChange() {
  this.quill.history.redo()
}

// Add sizes to whitelist and register them
// const Size = Quill.import("formats/size")
// Size.whitelist = ["extra-small", "small", "medium", "large"]
// Quill.register(Size, true)

const Size = Quill.import("attributors/style/size")

Size.whitelist = [
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
  "30px",
]
Quill.register(Size, true)

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font")
Font.whitelist = [
  "roboto",
  "bebas",
  "opensans",
  "arial",
  "lucida",
  "times-new-roman",
  "courier-new",
  "comic-sans",
  "georgia",
]
Quill.register(Font, true)

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },

  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
}

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
]

// Quill Toolbar component
const QuillToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats">
      <select className="ql-font mar-right-10" defaultValue="roboto">
        <option className="capital" value="roboto">
          Roboto
        </option>
        <option value="bebas">bebas</option>
        <option value="opensans">Open Sans</option>
        <option value="arial">Arail</option>
        <option value="lucida">Lucida</option>
        <option value="times-new-roman">Times-New-Roman</option>
        <option value="courier-new">Courier-New</option>
        <option value="comic-sans">Comic-Sans </option>
        <option value="georgia">Georgia</option>
      </select>
      <select className="ql-size" defaultValue="14px">
        <option value="9px">9</option>
        <option value="10px">10</option>
        <option value="11px">11</option>
        <option value="12px">12</option>
        <option value="14px">14</option>
        <option value="16px">16</option>
        <option value="18px">18</option>
        <option value="20px">20</option>
        <option value="22px">22</option>
        <option value="24px">24</option>
        <option value="26px">26</option>
        <option value="28px">28</option>
        <option value="30px">30</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" title="Bold" />
      <button className="ql-italic" title="Italic" />
      <button className="ql-underline" title="Underline" />
      <button className="ql-strike" title="Strike Through" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" title="Numbering Bullets" />
      <button className="ql-list" value="bullet" title="Bullets" />
      <button className="ql-indent" value="-1" title="Decrease Indent" />
      <button className="ql-indent" value="+1" title="Increase Indent" />
    </span>

    <span className="ql-formats">
      <select className="ql-align" title="Text Align" />
      <select className="ql-color" title="Font Color" />
      <select className="ql-background" title="Text Highlight Color" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" title="Add Link/Hyperlink">
        <CustomLink />
      </button>
    </span>
    <span className="ql-formats">
      <button className="ql-undo" title="Undo Text Fill Effect">
        <CustomUndo />
      </button>
      <button className="ql-redo" title="Repeat Text Fill Effect">
        <CustomRedo />
      </button>
    </span>
  </div>
)

export default QuillToolbar
