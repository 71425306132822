import { useEffect } from "react"
import { PDFDocument, rgb, StandardFonts } from "pdf-lib"

const GeneratePdf = ({ setPdfData, jsonData }) => {
  useEffect(() => {
    const createPdf = async () => {
      const pdfDoc = await PDFDocument.create()
      const pageSize = [595.28, 841.89]
      const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
      const timesRomanBoldFont = await pdfDoc.embedFont(
        StandardFonts.TimesRomanBold
      )
      const fontSize = 12
      const blueColor = rgb(0, 0, 1)

      let maxContentPerPage = jsonData.lables
      let labelIndex = 1

      const drawContent = (page, yOffset, data) => {
        const textWidth = timesRomanBoldFont.widthOfTextAtSize(
          data.wasteType,
          24
        )
        const pageWidth = page.getWidth()
        const xPosition = (pageWidth - textWidth) / 2

        page.drawText(`${data.wasteType}`, {
          x: xPosition,
          y: yOffset,
          size: 24,
          font: timesRomanBoldFont,
          color: blueColor,
        })

        page.drawLine({
          start: { x: 50, y: yOffset - 25 },
          end: { x: 545, y: yOffset - 25 },
          thickness: 1,
          color: rgb(0, 0, 0),
        })

        // const text1Width = timesRomanBoldFont.widthOfTextAtSize("Regulated by EPA or a State/Local Agency", 24);
        // const x1Position = (pageWidth - text1Width) / 2;
        page.drawText("Regulated by EPA or a State/Local Agency", {
          x: 190,
          y: yOffset - 40,
          size: fontSize,
          font: timesRomanBoldFont,
        })

        page.drawText(
          "IF FOUND, CONTACT THE NEAREST POLICE or PUBLIC SAFETY AUTHORITY",
          {
            x: 85,
            y: yOffset - 60,
            size: fontSize,
            font: timesRomanFont,
          }
        )

        page.drawText("AND THE WASHINGTON STATE DEPARTMENT OF ECOLOGY", {
          x: 130,
          y: yOffset - 75,
          size: fontSize,
          font: timesRomanFont,
        })

        page.drawText(`Generator - EPA ID: ${data.epaId}`, {
          x: 50,
          y: yOffset - 110,
          size: fontSize,
          font: timesRomanBoldFont,
        })

        page.drawText(`${data.address}`, {
          x: 50,
          y: yOffset - 130,
          size: fontSize,
          font: timesRomanFont,
          lineHeight: 15,
        })

        page.drawText(`Phone:`, {
          x: 400,
          y: yOffset - 110,
          size: fontSize,
          font: timesRomanBoldFont,
        })

        const phoneWidth = timesRomanBoldFont.widthOfTextAtSize(
          "Phone:",
          fontSize
        )
        page.drawText(`${data.phone}`, {
          x: 400 + phoneWidth + 5,
          y: yOffset - 110,
          size: fontSize,
          font: timesRomanFont,
        })

        page.drawText("Waste Codes", {
          x: 400,
          y: yOffset - 135,
          size: fontSize,
          font: timesRomanBoldFont,
          lineHeight: 15,
        })
        page.drawText(`${data?.epaCodes === null ? "N/A" : data?.epaCodes}`, {
          x: 403,
          y: yOffset - 150,
          size: fontSize,
          font: timesRomanFont,
          lineHeight: 15,
        })

        page.drawText("Proper D.O.T. Shipping Name", {
          x: 50,
          y: yOffset - 170,
          size: fontSize,
          font: timesRomanBoldFont,
        })

        page.drawText(`${data.dotShippingName}`, {
          x: 50,
          y: yOffset - 190,
          size: fontSize,
          font: timesRomanBoldFont,
          lineHeight: 15,
        })

        page.drawText("Profile # ", {
          x: 50,
          y: yOffset - 255,
          size: fontSize,
          font: timesRomanBoldFont,
          lineHeight: 15,
        })

        const profileWidth = timesRomanBoldFont.widthOfTextAtSize(
          "Profile #",
          fontSize
        )
        page.drawText(`${data.profileId}`, {
          x: 50 + profileWidth + 5,
          y: yOffset - 255,
          size: fontSize,
          font: timesRomanFont,
        })

        page.drawText(`LABPACK INCINERATION`, {
          x: 50,
          y: yOffset - 270,
          size: fontSize,
          font: timesRomanFont,
        })

        page.drawText(`Waste Ctg: `, {
          x: 50,
          y: yOffset - 300,
          size: fontSize,
          font: timesRomanBoldFont,
        })

        const wasteCtgWidth = timesRomanBoldFont.widthOfTextAtSize(
          "Waste Ctg:",
          fontSize
        )
        page.drawText(`${data.wasteCtg}`, {
          x: 50 + wasteCtgWidth + 5,
          y: yOffset - 300,
          size: fontSize,
          font: timesRomanFont,
        })

        page.drawText(`Date: `, {
          x: 300,
          y: yOffset - 300,
          size: fontSize,
          font: timesRomanBoldFont,
        })

        const dateWidth = timesRomanBoldFont.widthOfTextAtSize(
          "Date:",
          fontSize
        )
        page.drawText(`${data.accumulationDate}`, {
          x: 300 + dateWidth + 5,
          y: yOffset - 300,
          size: fontSize,
          font: timesRomanFont,
        })

        page.drawText(`Label `, {
          x: 450,
          y: yOffset - 300,
          size: fontSize,
          font: timesRomanBoldFont,
        })

        const labelWidth = timesRomanBoldFont.widthOfTextAtSize(
          "Label",
          fontSize
        )
        page.drawText(`${labelIndex} of ${jsonData.lables}`, {
          x: 450 + labelWidth + 5,
          y: yOffset - 300,
          size: fontSize,
          font: timesRomanFont,
        })
      }

      let remainingContent = maxContentPerPage
      let currentPage
      let yOffset

      while (remainingContent > 0) {
        if (remainingContent === maxContentPerPage || yOffset < 200) {
          currentPage = pdfDoc.addPage(pageSize)
          yOffset = pageSize[1] - 70
        }

        drawContent(currentPage, yOffset, jsonData, labelIndex)
        yOffset -= 380

        remainingContent -= 1
        labelIndex += 1
      }

      const pdfBytes = await pdfDoc.save()
      setPdfData(new Blob([pdfBytes], { type: "application/pdf" }))
    }

    createPdf()
  }, [setPdfData, jsonData])

  return null
}

export default GeneratePdf
