import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import api from "api"
import {
  ALL_GENERATORS,
  ALL_ER_SERVICE_NAMES,
  PHONE_NUMBER_BY_PROVIDER_NAME,
  GENERATOR_ID_STATUS,
  NAICS_CODE,
  SHIP_COUNTRY_LIST,
  SHIP_STATE_LIST,
  PROFILE_CRETAE_FROM_GENE,
  CURRENT_PROFILE,
  SET_NEW_PROGRESS_STEP,
  SET_PROGRESS_STEP,
} from "store/reducer/actionType"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import { getOptions } from "routes/Utilities/util"
// import { useHarsco } from "shared"
import { toast } from "react-toastify"

let enhancer =
  (Component) =>
  ({ history, ...props }) => {
    const [TSDFFile, setTSDFFile] = useState(null)
    const [erNameValue, setERNameValue] = useState("")
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [shippingError, setShippingError] = useState(false)
    const [manifestError, setManifestError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [hasNoErrors, setHasNoErrors] = useState(true)
    const [files, setFile] = useState(null)
    const setFiles = (f) => {
      setFile(f)
    }
    const useQuery = () => {
      return new URLSearchParams(useLocation().search)
    }
    const query = useQuery()
    const generatorFrom = query.get("from")

    const shipCountryCodes = useSelector(
      (state) => state.generatorDetails?.shipCountryList
    )
    const genCountries = getOptions(
      shipCountryCodes,
      "country",
      "countryTerritoryCode"
    )

    const shipStateCodes = useSelector(
      (state) => state.generatorDetails?.shipStateList
    )
    const changeCustomer = useSelector((state) => state.changeCustomer)
    const shipStateOpts = getOptions(shipStateCodes, "state", "stateCode")

    const [shipStates, setShipStates] = useState([])
    const [createdGenId, setCreatedGenId] = useState("")
    const [showAddressModal, setShowAddressModal] = useState(false)
    const [recShippingAddr, setRecShippingAddr] = useState({
      city: "",
      state: "",
      streetAddress: "",
      zipCode: "",
    })
    const [recManifestAddr, setRecManifestAddr] = useState({
      city: "",
      state: "",
      streetAddress: "",
      zipCode: "",
    })
    const [recManifestError, setRecManifestError] = useState("")
    const [recShipError, setRecShipError] = useState("")
    const [enterAddress, setEnterAddress] = useState(true)
    const [enterManifestAddress, setEnterManifestAddress] = useState(true)
    const [addrLoader, setAddrLoader] = useState(false)
    const [createdGenIDProfile, setCreatedGenIDProfile] = useState("")

    const ERNameCodes = useSelector(
      (state) => state.generatorDetails?.allErServiceNames
    )

    const knowyourcsr = useSelector(
      (state) => state.generatorDetails?.knowyourcsr
    )

    let ERNameOpts = getOptions(ERNameCodes, "name", "erServiceProviderId")

    const genIdStatusCodes = useSelector(
      (state) => state.generatorDetails?.generatorIdStatus
    )

    let genIdStatusOpts = getOptions(
      genIdStatusCodes,
      "generatorIdStatusValue",
      "generatorIdStatus"
    )

    const naicsCodes = useSelector((state) => state.generatorDetails?.naicsCode)

    let naicsOpts = getOptions(naicsCodes, "naicsName", "sfdcId")

    let subpartPOpts = [
      {
        l: "Not Applicable",
        v: "Not Applicable",
      },
      {
        l: "Registered",
        v: "Registered",
      },
      {
        l: "Opted out",
        v: "Opted out",
      },
    ]

    const dispatch = useDispatch()

    let initValues = {
      generatorName: "",
      emergencyResponseProvider: true,
      emergencyResponseInfo: { l: "", v: 0 },
      emergencyResponsePhoneNo: "(877) 577-2669",
      shippingCountry: null,
      shippingAddressLine1: "",
      shippingCity: "",
      shippingState: null,
      shippingZip: "",
      shippingCounty: "",
      shippingPhoneNo: "",
      shippingFax: "",
      shippingEmail: "",
      shippingAttention: "",
      sameAsShipping: false,
      manifestName: "",
      manifestAddressLine1: "",
      manifestCity: "",
      manifestState: null,
      manifestZip: "",
      manifestCountry: null,
      manifestPhoneNo: "",
      manifestFax: "",
      manifestEmail: "",
      manifestAttention: "",
      naics: null,
      generatorIdStatus: null,
      epaId: "",
      stateId: "",
      subpartP: null,
      isTsdfApprovalFile: false,
      disposalInstructions: "",
    }
    const [initialValues, setInitialValues] = useState(initValues)
    const [savedInfo, setSavedInfo] = useState(initValues)
    // const [state, setState] = useState("")

    let valSchema = {
      generatorName: Yup.string().required("Generator Name is required"),
      emergencyResponseProvider: Yup.boolean(),
      emergencyResponseInfo: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.number(),
        })
        .nullable(),
      emergencyResponsePhoneNo: Yup.string(),
      shippingCountry: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string(),
        })
        .nullable()
        .required("Country is required"),
      shippingAddressLine1: Yup.string().required("Street is required"),
      shippingCity: Yup.string().required("City is required"),
      shippingState: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string(),
        })
        .nullable()
        .required("State/Province is required"),
      // shippingZip: Yup.string()
      //   .matches(/^\d{5}(-\d{4})?$/, "ZIP code is not valid")
      //   .required("ZIP code is required"),
      shippingZip: Yup.string().when("shippingCountry.v", {
        is: "US",
        then: Yup.string()
          .matches(/^\d{5}(-\d{4})?$/, "ZIP code is not valid")
          .required("ZIP code is required"),
        otherwise: Yup.string()
          .max(20, "ZIP code can be a maximum of 20 characters")
          .required("ZIP code is required"),
      }),
      shippingCounty: Yup.string(),
      shippingPhoneNo: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone number is not valid")
        .required("Phone number is required"),
      shippingFax: Yup.string(),
      shippingEmail: Yup.string().email("Invalid email address"),
      shippingAttention: Yup.string().required("Contact name is required"),
      sameAsShipping: Yup.boolean(),
      manifestName: Yup.string().required("Manifest name is required"),
      manifestAddressLine1: Yup.string().when("sameAsShipping", {
        is: false,
        then: Yup.string().required("Street is required"),
        otherwise: Yup.string().nullable(),
      }),
      manifestCity: Yup.string().required("City is required"),
      manifestState: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string(),
        })
        .nullable()
        .required("State/Province is required"),
      manifestZip: Yup.string().when(["sameAsShipping", "manifestCountry.v"], {
        is: (sameAsShipping, country) => !sameAsShipping && country === "US",
        then: Yup.string()
          .matches(/^\d{5}(-\d{4})?$/, "ZIP code is not valid")
          .required("ZIP code is required"),
        otherwise: Yup.string().when("sameAsShipping", {
          is: false,
          then: Yup.string()
            .max(20, "ZIP code can be a maximum of 20 characters")
            .required("ZIP code is required"),
          otherwise: Yup.string().nullable(),
        }),
      }),
      manifestCountry: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string(),
        })
        .nullable()
        .required("Country is required"),
      manifestPhoneNo: Yup.string().when("sameAsShipping", {
        is: false,
        then: Yup.string()
          .matches(/^[0-9]{10}$/, "Phone number is not valid")
          .required("Phone number is required"),
        otherwise: Yup.string().nullable(),
      }),
      manifestFax: Yup.string(),
      manifestEmail: Yup.string().when("sameAsShipping", {
        is: false,
        then: Yup.string().email("Invalid email address"),
        otherwise: Yup.string().nullable(),
      }),
      manifestAttention: Yup.string().when("sameAsShipping", {
        is: false,
        then: Yup.string().required("Contact name is required"),
        otherwise: Yup.string().nullable(),
      }),
      naics: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string(),
        })
        .nullable()
        .required("NAICS is required"),
      generatorIdStatus: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string(),
        })
        .nullable()
        .required("Generator Id Status is required"),
      epaId: Yup.string().when("generatorIdStatus.v", {
        is: (value) =>
          ["To Be Assigned (TBA)", "Exempt", "HHW"].includes(value),
        then: Yup.string()
          // /^(TBA|Exempt|HHW|[A-Za-z]{2}(?=[A-Za-z0-9]{10}$)(?=.*[A-Za-z])[A-Za-z0-9]{10})$/, //last 10 should alphanumeric
          .matches(
            /^(TBA|Exempt|HHW|[A-Za-z]{2}[A-Za-z0-9]{10})$/,
            "EPAID is not valid"
          )
          .required("EPA ID is required"),
        otherwise: Yup.string()
          // /^[A-Za-z]{2}(?=[A-Za-z0-9]{10}$)(?=.*[A-Za-z])[A-Za-z0-9]{10}$/, //last 10 should alphanumeric
          .matches(/^[A-Za-z]{2}[A-Za-z0-9]{10}$/, "EPAID is not valid")
          .required("EPA ID is required"),
      }),
      subpartP: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string(),
        })
        .nullable()
        .required("Subpart P is required"),
      disposalInstructions: Yup.string(),
      files: Yup.string().when("isTsdfApprovalFile", {
        is: true,
        then: Yup.string().required("File is required"),
        otherwise: Yup.string(),
      }),
    }

    const formatPhoneNumber = (phoneNumber) => {
      let phnumber = ("" + phoneNumber).replace(/\D/g, "")
      let match = phnumber.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3]
      }
      return phoneNumber
    }

    const onFinalSubmit = async (data) => {
      var formData = new FormData()
      let zipCode
      if (!enterAddress) {
        zipCode = recShippingAddr?.zipCode
      } else if (enterAddress) {
        zipCode = data?.shippingZip
      }

      let mzipCode
      if (!enterManifestAddress) {
        mzipCode = recManifestAddr?.zipCode
      } else if (enterManifestAddress) {
        mzipCode = data?.sameAsShipping ? data?.shippingZip : data?.manifestZip
      }

      formData.append("GeneratorName", data?.generatorName || "")
      formData.append("CustomerName", changeCustomer?.customer?.l)
      formData.append(
        "EmergencyResponseProvider",
        data?.emergencyResponseProvider || false
      )

      formData.append(
        "EmergencyResponseInfo",
        data?.emergencyResponseInfo?.v === 0
          ? "Clean Earth"
          : data?.emergencyResponseInfo?.l
      )
      formData.append(
        "EmergencyResponsePhoneNo",
        data?.emergencyResponseInfo?.v === 0 ? "(877) 577-2669" : erNameValue
      )
      formData.append("ShippingCountry", data?.shippingCountry?.v)
      formData.append(
        "ShippingAddressLine1",
        enterAddress
          ? data?.shippingAddressLine1 || ""
          : recShippingAddr?.streetAddress || ""
      )
      formData.append(
        "ShippingCity",
        enterAddress ? data?.shippingCity || "" : recShippingAddr?.city || ""
      )
      formData.append(
        "ShippingState",
        enterAddress ? data?.shippingState?.v : recShippingAddr?.state || ""
      )
      formData.append("ShippingZip", zipCode || "")
      formData.append("ShippingCounty", data?.shippingCounty || "")
      formData.append(
        "ShippingPhoneNo",
        data && data.shippingPhoneNo
          ? formatPhoneNumber(data.shippingPhoneNo)
          : ""
      )
      formData.append("ShippingFax", data?.shippingFax || "")
      formData.append("ShippingEmail", data?.shippingEmail || "")
      formData.append("ShippingAttention", data?.shippingAttention || "")
      formData.append("SameAsShipping", data?.sameAsShipping || false)
      formData.append("ManifestName", data?.manifestName || "")
      formData.append(
        "ManifestAddressLine1",
        enterManifestAddress
          ? data?.sameAsShipping
            ? data?.shippingAddressLine1
            : data?.manifestAddressLine1 || ""
          : recManifestAddr?.streetAddress || ""
      )
      formData.append(
        "ManifestCity",
        enterManifestAddress
          ? data?.sameAsShipping
            ? data?.shippingCity
            : data?.manifestCity || ""
          : recManifestAddr?.city || ""
      )
      formData.append(
        "ManifestState",
        enterManifestAddress
          ? data?.sameAsShipping
            ? data?.shippingState?.v
            : data?.manifestState?.v || ""
          : recManifestAddr?.state
      )
      formData.append("ManifestZip", mzipCode || "")
      formData.append(
        "ManifestCountry",
        data?.sameAsShipping
          ? data?.shippingCountry?.v
          : data?.manifestCountry?.v
      )
      formData.append(
        "ManifestPhoneNo",
        data?.sameAsShipping
          ? formatPhoneNumber(data?.shippingPhoneNo)
          : data?.manifestPhoneNo
          ? formatPhoneNumber(data?.manifestPhoneNo)
          : ""
      )
      formData.append(
        "ManifestFax",
        data?.sameAsShipping ? data?.shippingFax : data?.manifestFax || ""
      )
      formData.append(
        "ManifestEmail",
        data?.sameAsShipping ? data?.shippingEmail : data?.manifestEmail || ""
      )
      formData.append(
        "ManifestAttention",
        data?.sameAsShipping
          ? data?.shippingAttention
          : data?.manifestAttention || ""
      )
      formData.append("Naics", data?.naics?.v || 0)
      formData.append("EpaId", data?.epaId || "")
      formData.append("StateId", data?.stateId || "")
      formData.append("SubpartP", data?.subpartP?.v || "")
      formData.append("DisposalRestrictions", data?.disposalInstructions || "")
      formData.append("CsrEmail", knowyourcsr?.email)
      formData.append("TsdfFileUpload", files)
      formData.append("GeneratorIdStatus", data?.generatorIdStatus?.v || "")
      formData.append("GeneratorTypeDesc", "Desc")
      formData.append("ExpireDay", 90)
      formData.append("Loadit", true)
      formData.append("Submit", true)
      if (hasNoErrors) {
        try {
          setAddrLoader(false)
          setShowAddressModal(false)
          setLoading(true)
          setInitialValues(data)
          let res = await api.f_saveNewGenerator(formData)
          if (!res) return
          if (res?.data?.generatorId !== null) {
            if (res?.statusCode === 500) {
              toast.error(res?.message)
              setLoading(false)
            } else {
              setLoading(false)
              setCreatedGenId(data?.generatorName)
              setCreatedGenIDProfile(res?.data?.generatorId)
              setShowSuccessModal(true)
            }
          } else {
            if (res?.message) {
              toast.error(res?.message)
            } else {
              toast.error("Generator Creation is not successful")
            }
          }
        } catch (err) {
        } finally {
          setLoading(false)
        }
      }
    }

    const areAddressesEqual = (enteredAddress, uspsaResponseAddress) => {
      const normalize = (str) => (str ? str.toLowerCase() : "")
      return (
        enteredAddress?.ZIPCode === uspsaResponseAddress?.zipCode &&
        normalize(enteredAddress.state) ===
          normalize(uspsaResponseAddress.state) &&
        normalize(enteredAddress.city) ===
          normalize(uspsaResponseAddress.city) &&
        normalize(enteredAddress.streetAddress) ===
          normalize(uspsaResponseAddress.streetAddress) &&
        enteredAddress?.ZIPPlus4 === uspsaResponseAddress?.zipPlus4
      )
    }

    const onSubmit = async (finaldata) => {
      if (finaldata?.shippingCountry?.v === "US") {
        setRecManifestError("")
        setRecShipError("")
        let zipCode = ""
        let zipPlus = ""
        if ((finaldata?.shippingZip).length > 5) {
          let [zip1, zip2] = finaldata?.shippingZip.split("-")
          zipCode = zip1
          zipPlus = zip2
        } else {
          zipCode = finaldata?.shippingZip
        }
        let mzipCode = ""
        let mzipPlus = ""
        if (finaldata?.manifestZip.length > 5) {
          let [mzip1, mzip2] = finaldata?.manifestZip.split("-")
          mzipCode = mzip1
          mzipPlus = mzip2
        } else {
          mzipCode = finaldata?.manifestZip
        }
        let USPSaddr = {
          streetAddress: finaldata?.shippingAddressLine1 || "",
          secondaryAddress: "",
          city: finaldata?.shippingCity || "",
          state: finaldata?.shippingState?.v || "",
          ZIPCode: zipCode,
          ZIPPlus4: zipPlus,
        }
        let USPSManifestAddr = {
          streetAddress: finaldata?.sameAsShipping
            ? finaldata?.shippingAddressLine1
            : finaldata?.manifestAddressLine1 || "",
          secondaryAddress: "",
          city: finaldata?.sameAsShipping
            ? finaldata?.shippingCity
            : finaldata?.manifestCity || "",
          state: finaldata?.sameAsShipping
            ? finaldata?.shippingState?.v
            : finaldata?.manifestState?.v || "",
          ZIPCode: finaldata?.sameAsShipping ? zipCode : mzipCode,
          ZIPPlus4: finaldata?.sameAsShipping ? zipPlus : mzipPlus,
        }
        setSavedInfo(finaldata)
        if (finaldata?.sameAsShipping) {
          try {
            setLoading(true)
            let res = await api.p_USPSAddressValidation(USPSaddr)
            const { data } = res
            const zipc = data?.zipPlus4
              ? `${data?.zipCode}-${data?.zipPlus4}`
              : data?.zipCode
            let shipaddr = {
              city: data?.city || "",
              state: data?.state || "",
              streetAddress: data?.streetAddress || "",
              zipCode: zipc || "",
            }
            let manifestaddr = {
              city: data?.city || "",
              state: data?.state || "",
              streetAddress: data?.streetAddress || "",
              zipCode: zipc || "",
            }
            if (res?.statusCode === 200) {
              setRecShippingAddr(shipaddr)
              setRecManifestAddr(manifestaddr)
            }
            if (res?.statusCode === 500) {
              setRecManifestError("Invalid Address")
              setRecShipError("Invalid Address")
            }
            if (areAddressesEqual(USPSaddr, data)) {
              onFinalSubmit(finaldata)
            } else {
              setLoading(false)
              setShowAddressModal(true)
              setAddrLoader(true)
            }
          } catch (error) {
          } finally {
            setAddrLoader(false)
            setInitialValues(finaldata)
          }
        }
        if (finaldata?.sameAsShipping === false) {
          try {
            setLoading(true)
            let shipRes = await api.p_USPSAddressValidation(USPSaddr)
            const { data } = shipRes
            const zipc = data?.zipPlus4
              ? `${data?.zipCode}-${data?.zipPlus4}`
              : data?.zipCode
            let shipaddr = {
              city: data?.city || "",
              state: data?.state || "",
              streetAddress: data?.streetAddress || "",
              zipCode: zipc || "",
            }
            if (shipRes?.statusCode === 200) {
              setRecShippingAddr(shipaddr)
            } else if (shipRes?.statusCode === 500) {
              setRecShipError("Invalid Address")
            }
            let manifestRes = await api.p_USPSAddressValidation(
              USPSManifestAddr
            )
            const manifestData = manifestRes && manifestRes.data
            const zipm = manifestData?.zipPlus4
              ? `${manifestData?.zipCode}-${manifestData?.zipPlus4}`
              : manifestData?.zipCode
            let manifestaddr = {
              city: manifestData?.city || "",
              state: manifestData?.state || "",
              streetAddress: manifestData?.streetAddress || "",
              zipCode: zipm || "",
            }
            if (manifestRes?.statusCode === 200) {
              setRecManifestAddr(manifestaddr)
            } else if (manifestRes?.statusCode === 500) {
              setRecManifestError("Invalid Address")
            }
            if (
              areAddressesEqual(USPSaddr, data) &&
              areAddressesEqual(USPSManifestAddr, manifestData)
            ) {
              onFinalSubmit(finaldata)
            } else {
              setLoading(false)
              setShowAddressModal(true)
              setAddrLoader(true)
            }
          } catch (error) {
          } finally {
            setAddrLoader(false)
            setInitialValues(finaldata)
          }
        }
      } else {
        var formData = new FormData()
        let zipCode = finaldata?.shippingZip
        let mzipCode = finaldata?.sameAsShipping
          ? finaldata?.shippingZip
          : finaldata?.manifestZip
        formData.append("GeneratorName", finaldata?.generatorName || "")
        formData.append("CustomerName", changeCustomer?.customer?.l)
        formData.append(
          "EmergencyResponseProvider",
          finaldata?.emergencyResponseProvider || false
        )

        formData.append(
          "EmergencyResponseInfo",
          finaldata?.emergencyResponseInfo?.v === 0
            ? "Clean Earth"
            : finaldata?.emergencyResponseInfo?.l
        )
        formData.append(
          "EmergencyResponsePhoneNo",
          finaldata?.emergencyResponseInfo?.v === 0
            ? "(877) 577-2669"
            : erNameValue
        )
        formData.append("ShippingCountry", finaldata?.shippingCountry?.v)
        formData.append(
          "ShippingAddressLine1",
          finaldata?.shippingAddressLine1 || ""
        )
        formData.append("ShippingCity", finaldata?.shippingCity || "")
        formData.append("ShippingState", finaldata?.shippingState?.v)
        formData.append("ShippingZip", zipCode || "")
        formData.append("ShippingCounty", finaldata?.shippingCounty || "")
        formData.append(
          "ShippingPhoneNo",
          finaldata && finaldata.shippingPhoneNo
            ? formatPhoneNumber(finaldata.shippingPhoneNo)
            : ""
        )
        formData.append("ShippingFax", finaldata?.shippingFax || "")
        formData.append("ShippingEmail", finaldata?.shippingEmail || "")
        formData.append("ShippingAttention", finaldata?.shippingAttention || "")
        formData.append("SameAsShipping", finaldata?.sameAsShipping || false)
        formData.append("ManifestName", finaldata?.manifestName || "")
        formData.append(
          "ManifestAddressLine1",
          finaldata?.sameAsShipping
            ? finaldata?.shippingAddressLine1
            : finaldata?.manifestAddressLine1 || ""
        )
        formData.append(
          "ManifestCity",
          finaldata?.sameAsShipping
            ? finaldata?.shippingCity
            : finaldata?.manifestCity || ""
        )
        formData.append(
          "ManifestState",
          finaldata?.sameAsShipping
            ? finaldata?.shippingState?.v
            : finaldata?.manifestState?.v
        )
        formData.append("ManifestZip", mzipCode || "")
        formData.append(
          "ManifestCountry",
          finaldata?.sameAsShipping
            ? finaldata?.shippingCountry?.v
            : finaldata?.manifestCountry?.v
        )
        formData.append(
          "ManifestPhoneNo",
          finaldata?.sameAsShipping
            ? formatPhoneNumber(finaldata?.shippingPhoneNo)
            : finaldata?.manifestPhoneNo
            ? formatPhoneNumber(finaldata?.manifestPhoneNo)
            : ""
        )
        formData.append(
          "ManifestFax",
          finaldata?.sameAsShipping
            ? finaldata?.shippingFax
            : finaldata?.manifestFax || ""
        )
        formData.append(
          "ManifestEmail",
          finaldata?.sameAsShipping
            ? finaldata?.shippingEmail
            : finaldata?.manifestEmail || ""
        )
        formData.append(
          "ManifestAttention",
          finaldata?.sameAsShipping
            ? finaldata?.shippingAttention
            : finaldata?.manifestAttention || ""
        )
        formData.append("Naics", finaldata?.naics?.v || 0)
        formData.append("EpaId", finaldata?.epaId || "")
        formData.append("StateId", finaldata?.stateId || "")
        formData.append("SubpartP", finaldata?.subpartP?.v || "")
        formData.append(
          "DisposalRestrictions",
          finaldata?.disposalInstructions || ""
        )
        formData.append("CsrEmail", knowyourcsr?.email)
        formData.append("TsdfFileUpload", files)
        formData.append(
          "GeneratorIdStatus",
          finaldata?.generatorIdStatus?.v || ""
        )
        formData.append("GeneratorTypeDesc", "Desc")
        formData.append("ExpireDay", 90)
        formData.append("Loadit", true)
        formData.append("Submit", true)
        if (hasNoErrors) {
          try {
            setLoading(true)
            setInitialValues(finaldata)
            let res = await api.f_saveNewGenerator(formData)
            if (!res) return
            if (res?.data?.generatorId !== null) {
              if (res?.statusCode === 500) {
                toast.error(res?.message)
                setLoading(false)
              } else {
                setLoading(false)
                setCreatedGenId(finaldata?.generatorName)
                setCreatedGenIDProfile(res?.data?.generatorId)
                setShowSuccessModal(true)
              }
            } else {
              if (res?.message) {
                toast.error(res?.message)
              } else {
                toast.error("Generator Creation is not successful")
              }
            }
          } catch (err) {
          } finally {
            setLoading(false)
          }
        }
      }
    }

    let formik = {
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: Yup.object().shape(valSchema),
      onSubmit: onSubmit,
    }

    const getAllGenerators = async (val) => {
      try {
        let res = await api.g_getAllGenerators()
        if (!res) return
        await dispatch({ type: ALL_GENERATORS, payload: res })
        if (val === "fromProf") {
          goToCreateProfile("createprofile")
        }
      } catch (error) {
        setLoading(false)
      }
    }

    const getAllCountryList = async () => {
      try {
        let res = await api.g_getCountryList()
        if (!res) return
        dispatch({ type: SHIP_COUNTRY_LIST, payload: res })
      } catch (error) {}
    }

    const getAllStateList = async () => {
      try {
        let res = await api.g_getStateList()
        if (!res) return
        dispatch({ type: SHIP_STATE_LIST, payload: res })
      } catch (error) {}
    }

    const getAllErServiceNames = async () => {
      try {
        let res = await api.g_getAllErServiceNames()
        if (!res) return
        dispatch({ type: ALL_ER_SERVICE_NAMES, payload: res })
      } catch (error) {}
    }

    const getPhoneNumberByProviderName = async (req) => {
      try {
        let res = await api.g_getPhoneNumberByProviderName(req)
        if (!res) return
        setERNameValue(res?.phoneNumber)
        dispatch({ type: PHONE_NUMBER_BY_PROVIDER_NAME, payload: res })
      } catch (error) {}
    }

    const getGeneratorStatus = async () => {
      try {
        let res = await api.g_getGeneratorIdStatus()
        if (!res) return
        dispatch({ type: GENERATOR_ID_STATUS, payload: res })
      } catch (error) {}
    }

    const getNaicsCode = async () => {
      try {
        let res = await api.g_getNaicsCode()
        if (!res) return
        dispatch({ type: NAICS_CODE, payload: res })
      } catch (error) {}
    }

    useEffect(() => {
      getAllGenerators()
      getAllErServiceNames()
      getAllCountryList()
      getAllStateList()
      getGeneratorStatus()
      getNaicsCode()
    }, [])

    const handleShipState = (e) => {
      let states = shipStateOpts.filter(
        (state) => state.countryTerritoryCode === e.v
      )
      setShipStates([...states])
    }

    const mapDefaultGenerator = async (val) => {
      if (val) {
        getAllGenerators("fromProf")
        await dispatch({
          type: PROFILE_CRETAE_FROM_GENE,
          payload: {
            isProfileFromGenerator: true,
            selectedProfileGenerator: val,
          },
        })
      } else {
        await dispatch({
          type: PROFILE_CRETAE_FROM_GENE,
          payload: {
            isProfileFromGenerator: false,
            selectedProfileGenerator: null,
          },
        })
      }
    }

    const goToCreateProfile = async (page) => {
      await dispatch({ type: CURRENT_PROFILE, payload: {} })
      await dispatch({
        type: SET_NEW_PROGRESS_STEP,
      })
      await dispatch({
        type: SET_PROGRESS_STEP,
        step: 1,
        progressStep: 1,
        percentage: 0,
      })
      history.push(`/profiles/${page}?from=newGenerator`)
    }

    const cancelAction = async () => {
      if (generatorFrom === "createProfile") {
        setLoading(true)
        await mapDefaultGenerator(createdGenIDProfile)
      } else {
        history.push(`/generator`)
      }
    }

    return (
      <Component
        {...props}
        {...{
          formik,
          genCountries,
          shipStateOpts,
          handleShipState,
          shipStates,
          ERNameOpts,
          genIdStatusOpts,
          naicsOpts,
          subpartPOpts,
          getPhoneNumberByProviderName,
          TSDFFile,
          setTSDFFile,
          shippingError,
          setShippingError,
          manifestError,
          setManifestError,
          setFiles,
          showSuccessModal,
          setShowSuccessModal,
          cancelAction,
          erNameValue,
          createdGenId,
          loading,
          setHasNoErrors,
          showAddressModal,
          setShowAddressModal,
          recShippingAddr,
          setRecShippingAddr,
          recManifestAddr,
          setRecManifestAddr,
          recShipError,
          recManifestError,
          enterAddress,
          setEnterAddress,
          enterManifestAddress,
          setEnterManifestAddress,
          onFinalSubmit,
          savedInfo,
          addrLoader,
          // setState,
        }}
      />
    )
  }

export default enhancer
