import React, { useEffect, useRef } from "react"
import ReactQuill from "react-quill"
import QuillToolbar, { modules, formats } from "./EditorToolBar.js"
import "react-quill/dist/quill.snow.css"

export const Editor = (props) => {
  const {
    onValue,
    readOnly = false,
    value,
    onDescription,
    border = false,
    error = false,
  } = props
  const [state, setState] = React.useState({ value: value })
  const reactQuillRef = useRef(null)
  let module = {
    ...modules,
  }

  if (readOnly) {
    module = { ...modules, toolbar: false }
  }

  const handleChange = (value, delta, source, editor) => {
    setState({ value })
    if (!readOnly) {
      onValue(value)
      onDescription("description", editor.getText())
      onDescription("formattedDescription", value)
    }
  }

  useEffect(() => {
    setState({ value })
  }, [value])

  return (
    <div
      className={`text-editor ${
        (!readOnly || border) && "text-editor-outline"
      }  ${error && "error-border"} ${border && "pad-5"}`}
    >
      {!readOnly && <QuillToolbar />}
      <ReactQuill
        ref={reactQuillRef}
        theme="snow"
        value={state.value}
        onChange={handleChange}
        placeholder={`Enter a maximum of 1500 characters. \nOnly the first 50 characters will be displayed in the ticker.`}
        modules={module}
        formats={formats}
        readOnly={readOnly}
      />
    </div>
  )
}

export default Editor
