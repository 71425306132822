import React, { useState, useEffect, useRef } from "react"
import searchs from "assets/icon/ic_search.svg"
import classnames from "classnames"
import EllipsisText from "react-ellipsis-text"
import _ from "lodash"

let Select = (props) => {
  let {
    selected,
    options,
    onSelect,
    open,
    setOpen,
    label,
    clear,
    isToggleButton = false,
  } = props
  let [search, setSearch] = useState("")

  const elementRef = useRef()
  let updateSelect = (opt) => (e) => {
    let selected2 = [...selected]
    if (!selected2.includes(opt)) selected2.push(opt)
    else selected2 = selected2.filter((d) => d != opt)
    onSelect(selected2)
  }

  let updateGroup = (opt) => (e) => {
    let selected2 = []

    if (e.currentTarget.checked)
      selected2 = _.uniq([...selected, ...options[opt]])
    else selected2 = _.difference([...selected], options[opt])

    onSelect(selected2)
  }

  let isSelected = (opt) => selected.includes(opt)

  let updateAll = (e) => {
    let selected2 = []
    if (e.currentTarget.checked) selected2 = Object.values(options).flat()
    onSelect(selected2)
  }

  let isSearchable = (opts, area) => {
    if (search == "") return false
    var child
    if (area == "region") {
      child = !opts.toLowerCase().includes(search.toLowerCase())
      return !opts.toLowerCase().includes(search.toLowerCase())
    }
    if (area == "division") {
      return !opts.toLowerCase().includes(search.toLowerCase()) || child
    }
  }

  let checkHash = {}
  let checkAll = ""
  let allOptions = Object.values(options).flat()

  if (selected.length == allOptions.length) checkAll = "all"
  if (selected.length == 0) checkAll = "none"

  let selectedCount = selected.length

  Object.keys(options).forEach((d) => {
    checkHash[d] = ""
    let diff = _.intersection(selected, options[d])
    if (diff.length == options[d].length) checkHash[d] = "all"
    if (diff.length == 0) checkHash[d] = "none"
  })

  let groupKeyCount = Object.values(checkHash).filter((d) => d != "none").length

  useEffect(() => {
    setSearch("")
    if (elementRef.current.scrollTop > 0) {
      elementRef.current.scrollTop = 0
    }
  }, [open])

  let controls = (
    <div>
      <hr className="line" />
      <div className="search-box inputfield">
        <input
          type="text"
          placeholder="Search"
          className="searchField"
          onChange={(e) => setSearch(e.currentTarget.value)}
          value={search}
        />

        <img
          className={classnames("serchicon", {
            "toggle-search-icon": isToggleButton,
          })}
          src={searchs}
          height="9px"
          width="8px"
          alt=""
        />
      </div>
      <div className={"search-box all mt-2"}>
        {search == "" && (
          <label className="drop-container">
            All
            <input
              type="checkbox"
              name={"all" + label}
              onChange={updateAll}
              checked={checkAll == "all"}
              className={checkAll}
            />
            <span className="checkmark"></span>
          </label>
        )}

        {clear && (
          <span onClick={clear} className="clearall">
            {" "}
            Clear All{" "}
          </span>
        )}
      </div>
    </div>
  )

  return (
    <div
      className={classnames("custom-dropdown  group", {
        active: open,
      })}
    >
      <div
        className={`drop-select ${selectedCount ? "count-selected" : ""}`}
        role="button"
        onClick={() => setOpen(!open)}
      >
        {label}
        {!!selectedCount && ` (${groupKeyCount} | ${selectedCount})`}
        <span
          className={classnames("drop-arrows", { down: !open, up: open })}
        ></span>
      </div>
      <div
        className={classnames("controls", {
          "d-none": !open,
        })}
        ref={elementRef}
      >
        {controls}

        <ul className="group-select mt-3">
          {Object.keys(options).map((d) => (
            <li
              key={d}
              className={classnames({
                "d-none": isSearchable(d, "division"),
              })}
            >
              <label htmlFor={"groupd" + d} className="drop-container ">
                {d}
                <input
                  type="checkbox"
                  name={"groupd" + d}
                  id={"groupd" + d}
                  checked={checkHash[d] == "all"}
                  className={checkHash[d]}
                  onChange={updateGroup(d)}
                />
                <span className="checkmark"></span>
              </label>
              <ul className="pl-2 sub-list text-uppercase">
                {options[d].map((d2) => (
                  <li
                    className={classnames("listitem", {
                      "d-none": isSearchable(d2, "region"),
                    })}
                    onClick={updateSelect(d2)}
                    key={d2}
                  >
                    <label htmlFor={d2} className="drop-container">
                      <EllipsisText text={d2} length={20} />
                      <input
                        type="checkbox"
                        checked={isSelected(d2)}
                        onChange={(e) => {}}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Select
