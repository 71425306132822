import { SET_GENERATORS, DELETE_GENERATORS } from "./actionType"

const generators = (state = [], action) => {
  let newState = []
  switch (action.type) {
    case SET_GENERATORS:
      newState = [...action.store]
      return newState
    case DELETE_GENERATORS:
      return newState
    default:
      return state
  }
}

export default generators
