import { useState, useEffect, useRef } from "react"
import {
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  CartesianGrid,
  Legend,
} from "recharts"
import colors from "./colors"
import "./style.scss"
import { useIntl } from "react-intl"
import { weekDays, useWindowDimension } from "shared"

const Chart = ({ data, config, height, type = "linear", color }) => {
  const [width, setWidth] = useState(0)
  const elementRef = useRef(null)
  const intl = useIntl()
  let { yUnit = "" } = config

  let formatNumber = (n) =>
    `${intl.formatNumber(n, {
      notation: "standard",
    })}${yUnit}`

  let metaColors =
    type == "linear" ? colors.area_linear_l : colors.area_monotone_l
  if (color) metaColors = color

  let dim = useWindowDimension()

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width)
  }, dim)

  return (
    <div ref={elementRef} className="area-chart-wrapper">
      <AreaChart
        width={width}
        height={height}
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          {config.y.map((d, i) => (
            <linearGradient
              id={"color" + d.key}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
              key={d.key}
            >
              <stop offset="0%" stopColor={metaColors[i]} stopOpacity={0.5} />
              <stop offset="90%" stopColor={metaColors[i]} stopOpacity={0} />
            </linearGradient>
          ))}
        </defs>
        <XAxis
          dataKey={config.x}
          padding={{ left: 25 }}
          tickLine={false}
          tickFormatter={(d) =>
            weekDays.includes(d) ? d.match(/^.{0,3}/, "")[0] : d
          }
          tickCount={config.y.length + 2}
        />
        <YAxis
          // unit={yUnit}
          //domain={config.yDomain}
          tickFormatter={formatNumber}
          tickCount={7}
          //domain={[0, parseInt(hNumber.toFixed(0))]}
        />
        <CartesianGrid strokeDasharray="0" vertical={false} />
        <Tooltip
          active={true}
          formatter={formatNumber}
          label="{tooltip}"
          labelFormatter={(name) => {
            let tooltip = data.find((d) => d.tooltip?.includes(name))?.tooltip
            return tooltip ? tooltip : name
          }}
        />
        {config.y.map((d, i) => (
          <Area
            dataKey={d.key}
            key={d.key}
            name={d.label}
            stroke={metaColors[i]}
            strokeWidth="2"
            fillOpacity={1}
            type={type}
            fill={`url(#${"color" + d.key})`}
            legendType="square"
            dot={true}
          />
        ))}{" "}
        <Legend verticalAlign="bottom" width={width / 2} iconType="circle" />
      </AreaChart>
    </div>
  )
}

export default Chart
