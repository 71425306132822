import React from "react"
import Image from "shared/HelpImage/index"

function RequestSupplies() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">New Request: Request Supplies</h3>
      <p className="text-justify pl-3 pt-2">
        <p>
          Request Supplies for a store using this form. The contact information
          of the user is pre-populated.
        </p>
        <p>
          There is an option to cc other email addresses to receive confirmation
          of your request once it is submitted.
        </p>
        <p>Under Request details, select the Store from the drop-down menu.</p>
        <p>
          Under Supplies, the user can choose different supplies from the
          drop-down menu and indicate the quantity.
        </p>
        <p>Add or delete as many lines as needed for different supply types.</p>
        <p>
          Provide additional details in the space provided and hit submit
          request button.
        </p>
        <p>
          You will receive notification on your screen that your request was
          submitted successfully along with a confirmation number.
        </p>
      </p>
      <div className="img-section">
        <Image
          src="Request_Service_request_supplies.png"
          alt="Report Supplies Image"
        />
      </div>
      <p className="text-justify pl-3 pt-2">
        <i>
          As with all request service options a “success” confirmation screen
          will appear after the submit button is clicked.
        </i>
      </p>
    </div>
  )
}
export default RequestSupplies
