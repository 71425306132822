import { REACT_APP_CORPORATE_CLIENT_ID as clientId } from "config"

export const corporateConfig = {
  auth: {
    clientId,
  },
  cache: {
    cacheLocation: `localStorage`,
    storeAuthStateInCookie: false,
  },
}
