import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import * as dfns from "date-fns"
import { roles } from "config"
import { BusinessSegmentId } from "config"

function AnnouncementCheckout(props) {
  let {
    onReturnToUserAdminClick,
    onCreateAnotherNewUserClick,
    createUserService,
    formatDescription,
  } = props

  const getRolesorBusinessSegments = (title) => {
    let entries = Object.entries(title === "roles" ? roles : BusinessSegmentId)
    let rolesText = []
    entries.forEach((ele) => {
      if (title === "roles") {
        if (createUserService.roleIds.includes(ele[1])) {
          rolesText = [...rolesText, ele[0].replace(/_/g, " ")]
        }
      } else {
        if (createUserService.businessSegmentIds.includes(ele[1])) {
          rolesText = [...rolesText, ele[0].replace(/_/g, " ")]
        }
      }
    })
    return rolesText
  }

  return (
    <Fragment>
      <div className="requestServiceBlock requestSuccessBlock">
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left mt-4 mb-3">
          <div className="m-0 labelgenerator">
            {!createUserService.hasOwnProperty("deleteAnnouncement")
              ? "Created New Announcement"
              : ""}
            {createUserService.deleteAnnouncement ? "Announcement Deleted" : ""}
            {createUserService.hasOwnProperty("deleteAnnouncement") &&
            !createUserService.deleteAnnouncement
              ? "Edit Announcement"
              : ""}
          </div>
        </div>
        {!createUserService.deleteAnnouncement && (
          <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left">
            <div className="success-block">
              <div className="m-0 title">
                {createUserService.deleteAnnouncement ? (
                  "Announcement Deleted!"
                ) : (
                  <FormattedMessage id="usercheckout.success" />
                )}
              </div>
              <div className="m-0 subTitle">
                <div className="subTxtMsg">
                  {createUserService.deleteAnnouncement ? (
                    "Your announcement will not be displayed from"
                  ) : (
                    <FormattedMessage id={"announcement.success1"} />
                  )}
                  <b>
                    {` ${dfns.format(
                      createUserService.startDate,
                      "MM-dd-yyyy"
                    )}`}
                  </b>
                  <FormattedMessage id={"announcement.success2"} />
                  <b>
                    {` ${dfns.format(createUserService.endDate, "MM-dd-yyyy")}`}
                  </b>
                  <br />
                  <FormattedMessage id={"announcement.success3"} />
                  <br />
                  <b>
                    {getRolesorBusinessSegments(
                      "businessSegmentIds"
                    ).toString()}
                  </b>
                  <br />
                  <FormattedMessage id={"announcement.success4"} />
                  <br />
                  <b>{getRolesorBusinessSegments("roles").toString()}</b>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className={`${
            createUserService.deleteAnnouncement && "success-block"
          }`}
        >
          <div className="m-0 title">
            {createUserService.deleteAnnouncement && "Announcement Deleted!"}
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left">
            {!createUserService.deleteAnnouncement && (
              <div className="m-0 pad-l-5 contact-title">
                <FormattedMessage id="announcement.info" />
              </div>
            )}
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
            <div className="row m-0">
              <div className="m-0 col-2 pad-r-5 label">
                <FormattedMessage id="announcement.startDate" />
              </div>
              <div className="m-0 value col-10 p-0">
                {dfns.format(createUserService.startDate, "MM-dd-yyyy")}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
            <div className="row m-0">
              <div className="m-0 col-2 pad-r-5 label">
                <FormattedMessage id="announcement.endDate" />
              </div>
              <div className="m-0 value  col-10 p-0">
                {dfns.format(createUserService.endDate, "MM-dd-yyyy")}{" "}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
            <div className="row m-0">
              <div className="m-0 col-2 pad-r-5 announcement-details">
                <FormattedMessage id="announcement.roleIds" />
              </div>
              <div className="m-0 value col-10 p-0">
                {getRolesorBusinessSegments("roles").toString()}{" "}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
            <div className="row m-0">
              <div className="m-0 col-2 pad-r-5 label">
                <FormattedMessage id="announcement.businessSegmentIds" />
              </div>
              <div className="m-0 value col-10 p-0">
                {getRolesorBusinessSegments("businessSegmentIds").toString()}{" "}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
            <div className="row m-0">
              <div className="m-0 col-2 pad-r-5 label">
                <FormattedMessage id="announcement.description" />
              </div>
              <div className="m-0 value col-10 p-0">{formatDescription}</div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServicebutton text-center">
          <div className="row m-0">
            <div className="m-0 col-12 p-0">
              <button
                className="green-button"
                onClick={() => {
                  onReturnToUserAdminClick()
                }}
              >
                <FormattedMessage id="usercheckout.returntoadmin" />
              </button>
            </div>
            <div className="m-0 col-12 margin-top-1 mb-5">
              <span
                className="green-label create-new-text"
                onClick={() => {
                  onCreateAnotherNewUserClick()
                }}
              >
                <FormattedMessage id="announcement.createanothernewuser" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default enhancer(AnnouncementCheckout)
