import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import "./style.scss"

const UserTableGrid = (props) => {
  const { columnData, headers, rows } = props
  let history = useHistory()
  const [highlightedIndex, setHighlightedIndex] = useState(null)

  const handleMouseEnter = (index) => {
    setHighlightedIndex(index)
  }

  const handleMouseLeave = () => {
    setHighlightedIndex(null)
  }

  const handleSpanClick = (index) => {
    setHighlightedIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  const navigateToEditUser = (user) => {
    history.push("/admin/edituser", {
      userData: user[0],
    })
  }

  return (
    <div
      className="user-table-grid"
      style={{ width: "100%", "overflow-x": "auto" }}
    >
      <table>
        <thead>
          <tr>
            {headers?.map((ele, ind) => (
              <th key={ind} className={ele.className}>
                {ele.value}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((ele, ind) => {
            const accountDetails = ele.data
              .filter((d) => d.className === "New")
              .map((d) => d.value)
              .join("\n")
            const accountDetailsInfo =
              accountDetails.split("\n").length >= 7
                ? accountDetails.split("\n").slice(0, 5).join("\n")
                : accountDetails
            const column =
              columnData !== "EditAnnouncements" &&
              columnData !== undefined &&
              columnData.filter((d) => d.accountNumber == ele.data[3].value)
            return (
              <tr
                key={ind}
                className={`${ele.className} ${
                  rows.length === ind + 1 ? "border-bottom-1" : ""
                }`}
              >
                {ele.data
                  .filter((d) => d.className !== "New")
                  .map((element, index) => (
                    <td key={index} className={element.className}>
                      {typeof element.value === "string" &&
                      element.value.includes("Enterprise User")
                        ? element.value.replace(/[^a-zA-Z\s]/g, "")
                        : element.value}{" "}
                      {typeof element.value === "string" &&
                        element.value.includes("Enterprise User") &&
                        element.value.replace(/[^0-9]/g, "").length > 0 && (
                          <span
                            className="numusermanagement"
                            onMouseEnter={() => handleMouseEnter(ind)}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => handleSpanClick(ind)}
                          >
                            {parseInt(element.value.replace(/[^0-9]/g, ""), 10)}
                            {highlightedIndex === ind && (
                              <span className="tipdiv bottom-to-top">
                                {accountDetailsInfo
                                  .split("\n")
                                  .map((line, i) => (
                                    <p key={i}>{line}</p>
                                  ))}
                                {accountDetails.split("\n").length >= 7 && (
                                  <p
                                    style={{
                                      textDecoration: "underline",
                                      color: "blue",
                                      textAlign: "right",
                                      paddingRight: "10px",
                                    }}
                                    onClick={() => navigateToEditUser(column)}
                                  >
                                    more
                                  </p>
                                )}
                              </span>
                            )}
                          </span>
                        )}
                    </td>
                  ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default UserTableGrid
