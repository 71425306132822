const documentLibraryHeader = [
  {
    id: 1,
    value: "Invoice No",
    className: "tabelHeader",
  },
  {
    id: 2,
    value: "Generator Name",
    className: "tabelHeader",
  },
  {
    id: 5,
    value: "Order No",
    className: "tabelHeader",
  },
  {
    id: 3,
    value: "Invoice Date",
    className: "tabelHeader",
  },
  {
    id: 4,
    value: "Invoiced Amount",
    className: "tabelHeader",
  },
  {
    id: 6,
    value: "Generator Address",
    className: "tabelHeader",
  },
]

export default documentLibraryHeader
