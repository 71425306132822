import React from "react"
import Loading from "shared/Loading"
import BarChart from "./chart"
import { FormattedMessage, FormattedNumber } from "react-intl"
import Image from "shared/Image"
import _ from "lodash"

function WasteComposition(props) {
  let { wasteCompositionChart, chartTotal, totals, workOrderType } = props

  return (
    <div className="col-8 ">
      <div className="text-left status mb-2">
        <FormattedMessage id="serviceorder.wastecompostion" />
      </div>
      <Loading id="get_shipmentDetails">
        {workOrderType === "NoShip" ? (
          <div
            className={`col-12 text-center nodata wastecomposition borders ${
              totals.totalWeight == 0 ? "borders pr-1" : ""
            }`}
          >
            <div className="mt-5">
              <Image
                className="norecords"
                src={"Full_No_Ship_Icon.svg"}
                height="118px"
                width="118px"
                alt=""
              />
            </div>
            <FormattedMessage id="nodata.noship" />
          </div>
        ) : totals.totalWeight == 0 || _.isEmpty(wasteCompositionChart) ? (
          <div
            className={`col-12 text-center nodata wastecomposition  ${
              _.isEmpty(wasteCompositionChart) ? "borders pr-1" : ""
            }`}
          >
            <div className="mt-5">
              <Image
                className="norecords"
                src="no_records_bg.svg"
                height="118px"
                width="118px"
                alt=""
              />
            </div>
            <FormattedMessage id="nodata.wastecomposition" />
          </div>
        ) : (
          <div className={`borders wastecomposition py-4 pr-1`}>
            <BarChart
              data={wasteCompositionChart}
              height={200}
              config={{
                x: "description",
                y: { key: "weight" },
                yUnit: "lbs",
              }}
            />
            <div className="row m-0 wasteComposition">
              <div className="col-12 p-0 m-0">
                <div className="row pr-3 m-0 subcontent">
                  <div className="col-3 text-left total">
                    <FormattedMessage id="serviceorder.totalweight" />
                    <span className="space"></span>
                    <FormattedNumber
                      value={chartTotal.totalWeight || 0}
                      maximumFractionDigits={1}
                    />
                    <FormattedMessage id="serviceorder.lbs" />
                  </div>
                  <div className="col-3 text-left epa">
                    <FormattedMessage id="serviceorder.epahazardous" />
                    <span className="space"></span>
                    <FormattedNumber
                      value={chartTotal.epaHaz || 0}
                      maximumFractionDigits={1}
                    />
                    <FormattedMessage id="serviceorder.lbs" />
                  </div>
                  <div className="col-3 text-left toxic">
                    <FormattedMessage id="serviceorder.epaacute" />
                    <span className="space"></span>
                    <FormattedNumber
                      value={chartTotal.epaAcuteToxic || 0}
                      maximumFractionDigits={2}
                    />
                    <FormattedMessage id="serviceorder.lbss" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Loading>
    </div>
  )
}
export default WasteComposition
