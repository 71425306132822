import React from "react"
import Loading from "shared/Loading"
import Pagination from "shared/Pagination"
import { FormattedMessage } from "react-intl"
import _ from "lodash"

function History(props) {
  let { history, gotoPage, pageNo, serviceHistory } = props
  let totalRecords = history?.totalRecords
  return (
    <div className="col-4 p-0">
      <div className="text-left status mb-2">
        <FormattedMessage id="serviceorder.servicehistory" />
      </div>
      <div className="row borders m-0 historyservice">
        <Loading id="get_history">
          <div className="col-12 p-0 m-0">
            <div className="row pl-0 m-0">
              <div className="col-4  m-0 text-left">
                <div className="serviceheading">
                  <FormattedMessage id="serviceorder.number" />
                </div>
              </div>
              <div className="col-4  m-0 text-left">
                <div className="serviceheading">
                  <FormattedMessage id="serviceorder.date" />
                </div>
              </div>
              <div className="col-4  m-0 text-left">
                <div className="serviceheading">
                  <FormattedMessage id="serviceorder.status" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 p-0 m-0">
            <div
              className={`row p-0 m-0  serviceHistory ${
                _.isEmpty(history?.items) ? "" : "scrollbar"
              }`}
            >
              {history?.items &&
                history?.items.map((el, i) => (
                  <div className="col-12 p-0 m-0" key={i}>
                    <div className="row p-0 m-0 ">
                      <div className="col-4  m-0 text-left">
                        <div className="d-block servicerow numbers">
                          <span
                            onClick={() => {
                              serviceHistory(el.workOrderAzid)
                            }}
                          >
                            {el.workOrderId}
                          </span>
                        </div>
                      </div>
                      <div className="col-4  m-0 text-left">
                        <div className="d-block servicerow">
                          {el.dateFormat}
                        </div>
                      </div>
                      <div className="col-4  m-0 text-left">
                        <div className="d-block servicerow">{el.status}</div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {_.isEmpty(history?.items) && (
            <div className="col-12 text-center nodata">
              <FormattedMessage id="nodata.serviceHistory" />
            </div>
          )}
          {totalRecords > 0 && (
            <Pagination
              {...{
                pageNo,
                totalRecords,
                gotoPage,
                pageSize: 5,
                hastrue: true,
              }}
            />
          )}
        </Loading>
      </div>
    </div>
  )
}
export default History
