import React, { Fragment, useEffect, useState, useRef } from "react"
import chemicalcompositionenhancer from "./chemicalcompositionenhancer"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import Select from "shared/Select"
import Input from "routes/Admin/Components/Input"
import Image from "shared/Image"
import Modal from "shared/Modal"
import { Form, Formik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import { GET_PROGRESS_STEP } from "store/reducer/actionType"
import AlertModal from "./AlertModal"
// import { nanoid } from 'nanoid';

function ChemicalComposition(props) {
  let {
    formik,
    fields,
    setChemComp,
    setFields,
    backAction,
    cancelAction,
    showAlert,
    setShowAlert,
    handleAlerts,
    seekingStep,
    showError,
    setShowError,
    onSubmitAlone,
    getPotentialTriChemicals,
    resetChemicals,
    loadChemicals,
    genNameforView,
  } = props
  let defaultValues = {
    id: 0,
    Constituent: "Barometers",
    Key: "Range",
    Min: null,
    Max: null,
    UOM: "%",
  }
  const { id, type } = useParams()
  const ref = useRef()
  const [submit, setSubmit] = useState(false)
  const [isModified, setIsModified] = useState(false)
  const [addFlag, setAddFlag] = useState(false)
  const [editFlag, setEditFlag] = useState(false)
  const [editError, setEditError] = useState(false)
  const [selectTRI, setSelectTRI] = useState("")
  const [activeIndex, setActiveIndex] = useState(-1)
  const [minVal, setMinVal] = useState("")
  const [maxVal, setMaxVal] = useState("")
  const triItems = useSelector((state) => state.profileDetails.chemicals)
  let rangeOpts = {
    id: 101,
    options: [
      {
        l: "=",
        v: "=",
      },
      {
        l: ">",
        v: ">",
      },
      {
        l: "<",
        v: "<",
      },
      {
        l: "Range",
        v: "R",
      },
      {
        l: "Supplement Info",
        v: "S",
      },
      {
        l: "Balance",
        v: "B",
      },
      {
        l: "Trace",
        v: "T",
      },
    ],
  }
  let uomOpts = {
    id: 102,
    options: [
      {
        l: "PPM",
        v: "PPM",
      },
      {
        l: "MG/L ",
        v: "MG/L",
      },
      {
        l: "PPB",
        v: "PPB",
      },
      {
        l: "MG/K",
        v: "MG/K",
      },
      {
        l: "G/L",
        v: "G/L",
      },
      {
        l: "%",
        v: "%",
      },
    ],
  }

  const [showModal, setShowModal] = useState(false)
  const [maxError, setMaxError] = useState(false)
  const [minError, setMinError] = useState(false)
  const [rows, setRows] = useState([])
  const [errorMinRows, setErrorMinRows] = useState({})
  const [errorMaxRows, setErrorMaxRows] = useState({})
  const dispatch = useDispatch()

  const handleTriSelect = (item) => {
    let initialValue = { ...fields, CAS: item.cas }
    setSelectTRI(item.chemicalName)
    setFields({ ...initialValue })
  }
  const current_profile = useSelector(
    (state) => state.profileDetails.currentProfile
  )

  const handleAddRow = (e, tri) => {
    let initialValue
    if (tri == "Y") {
      initialValue = { ...fields, TRIStatus: tri, Constituent: selectTRI }
    } else {
      initialValue = { ...fields, TRIStatus: tri }
    }
    setRows([...rows, initialValue])
    setChemComp([...rows, initialValue])
    setFields({
      Constituent: "",
      Key: "",
      Min: null,
      Max: null,
      UOM: "",
      TRIStatus: "",
      CAS: "",
    })
  }

  const currentProfile = useSelector(
    (state) => state.profileDetails?.currentProfile
  )
  const generatorOptions = useSelector(
    (state) => state.generatorDetails.generatorList
  )

  const getDefaultGeneratorName = (id) => {
    return (
      generatorOptions?.filter((val) => val.generatorId === id)?.[0]
        ?.generatorName || ""
    )
  }

  const handleDeleteRow = (i) => {
    const list = [...rows]
    list.splice(i, 1)
    setRows(list)
    setChemComp(list)
  }

  useEffect(() => {
    if (
      id &&
      current_profile?.profileConstituents &&
      JSON.parse(current_profile?.profileConstituents)?.length > 0
    ) {
      setRows(JSON.parse(current_profile?.profileConstituents))
      setChemComp(JSON.parse(current_profile?.profileConstituents))
      dispatch({
        type: GET_PROGRESS_STEP,
        step: 3,
        progressStep: type === "viewprofile" ? 1 : 3,
        percentage: 100,
      })
    }
  }, [id, current_profile])

  useEffect(() => {
    if (showAlert) {
      ref.current.click()
    }
  }, [showAlert])

  const validateSave = (isSubmit) => {
    let rowData = [...rows]
    setEditFlag(true)
    const isError = rowData.some((val) => {
      return (
        val?.Constituent?.trim()?.length === 0 ||
        !val?.Key ||
        val?.Key?.length > 1 ||
        ((val?.Min === null ||
          val?.Min === undefined ||
          !(String(val?.Min)?.length > 0)) &&
          val?.Key !== "<" &&
          val?.Key !== "B" &&
          val?.Key !== "T" &&
          val?.Key !== "S") ||
        (!val?.Max &&
          val?.Key !== "=" &&
          val?.Key !== ">" &&
          val?.Key !== "B" &&
          val?.Key !== "T" &&
          val?.Key !== "S") ||
        (!val?.UOM &&
          val?.Key !== "B" &&
          val?.Key !== "T" &&
          val?.Key !== "S") ||
        (val?.Key === "R" &&
          (val?.Min === null ||
            val?.Min === undefined ||
            !(String(val?.Min)?.length > 0) ||
            !val?.Max ||
            (val?.Max && parseFloat(val?.Min) > parseFloat(val?.Max)))) ||
        (val?.Key === "S" &&
          val?.Min &&
          val?.Max &&
          String(val?.Min)?.length > 0 &&
          val?.Max &&
          parseFloat(val?.Min) > parseFloat(val?.Max))
      )
    })
    const errorobj = rowData.find(
      (val) =>
        val?.Constituent?.trim()?.length == 0 ||
        !val?.Key ||
        val?.Key?.length > 1 ||
        ((val?.Min === null ||
          val?.Min === undefined ||
          !(String(val?.Min)?.length > 0)) &&
          val?.Key !== "<" &&
          val?.Key !== "B" &&
          val?.Key !== "T" &&
          val?.Key !== "S") ||
        (!val?.Max &&
          val?.Key !== "=" &&
          val?.Key !== ">" &&
          val?.Key !== "B" &&
          val?.Key !== "T" &&
          val?.Key !== "S") ||
        (!val?.UOM &&
          val?.Key !== "B" &&
          val?.Key !== "T" &&
          val?.Key !== "S") ||
        (val?.Key == "R" &&
          (val?.Min === null ||
            val?.Min === undefined ||
            !(String(val?.Min)?.length > 0) ||
            !val?.Max ||
            ((val?.Min === null ||
              val?.Min === undefined ||
              !(String(val?.Min)?.length > 0)) &&
              val?.Max &&
              parseFloat(val?.Min) > parseFloat(val?.Max)))) ||
        (val?.Key === "S" &&
          val?.Min &&
          val?.Max &&
          String(val?.Min)?.length > 0 &&
          val?.Max &&
          parseFloat(val?.Min) > parseFloat(val?.Max))
    )
    const errorIndex = rows.findIndex(
      (val) =>
        val?.Constituent?.trim()?.length == 0 ||
        !val?.Key ||
        val?.Key?.length > 1 ||
        ((val?.Min === null ||
          val?.Min === undefined ||
          !(String(val?.Min)?.length > 0)) &&
          val?.Key !== "<" &&
          val?.Key !== "B" &&
          val?.Key !== "T" &&
          val?.Key !== "S") ||
        (!val?.Max &&
          val?.Key !== "=" &&
          val?.Key !== ">" &&
          val?.Key !== "B" &&
          val?.Key !== "T" &&
          val?.Key !== "S") ||
        (!val?.UOM &&
          val?.Key !== "B" &&
          val?.Key !== "T" &&
          val?.Key !== "S") ||
        (val?.Key == "R" &&
          (val?.Min === null ||
            val?.Min === undefined ||
            !(String(val?.Min)?.length > 0) ||
            !val?.Max ||
            ((val?.Min === null ||
              val?.Min === undefined ||
              !(String(val?.Min)?.length > 0)) &&
              val?.Max &&
              parseFloat(val?.Min) > parseFloat(val?.Max)))) ||
        (val?.Key === "S" &&
          val?.Min &&
          val?.Max &&
          String(val?.Min)?.length > 0 &&
          val?.Max &&
          parseFloat(val?.Min) > parseFloat(val?.Max))
    )
    if (
      errorobj?.Min &&
      errorobj?.Max &&
      Number(errorobj?.Min) > Number(errorobj?.Max)
    ) {
      setErrorMaxRows({
        ...errorMaxRows,
        [errorIndex]: true,
      })
    }
    setEditError(isError)
    if (!isError) {
      setChemComp(rowData)
      setEditError(false)
      if (isSubmit) {
        formik.onSubmit()
      } else {
        onSubmitAlone()
      }
    }
    setTimeout(() => setIsModified((val) => !val), 100)
  }

  return (
    <Fragment>
      {isModified && <></>}
      <Formik enableReinitialize={true} {...formik}>
        {({ values, errors, touched, setFieldValue, isValid }) => (
          <Form>
            <div
              className="ml-4 chemical-comp"
              style={{
                pointerEvents: type === "viewprofile" ? "none" : "auto",
              }}
            >
              <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus text-left py-4">
                <div
                  className="m-0 myprofile"
                  style={{
                    color: "#337AB7",
                    fontWeight: "bold",
                    fontFamily: "robotoregular",
                  }}
                >
                  <FormattedMessage id="profile.chemicalcomposition" />
                </div>
                <div className="form-input-block col-sm-4">
                  <span className="label">
                    <FormattedMessage id="profile.generator" />
                  </span>
                  <Input
                    value={
                      getDefaultGeneratorName(currentProfile?.generatorId) ||
                      genNameforView
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 generatorstatus text-left py-4">
                <FormattedMessage id="profile.chemicalcomposition.addoreditconst" />
                <span style={{ color: "red" }}>*</span>
              </div>
              {/*Header*/}
              <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 py-2 position-relative">
                <div className="col-sm-2 col-md-2 col-lg-2 ml-2 text-left field-fontFamily">
                  <FormattedMessage id="profile.chemicalcomposition.constituent" />
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2 m-0 text-left field-fontFamily">
                  <FormattedMessage id="profile.chemicalcomposition.key" />
                  {/* <span style={{ color: "red" }}>{" *"}</span> */}
                </div>
                <div
                  className="col-sm-2 col-md-2 col-lg-2 m-0 text-left field-fontFamily"
                  style={{ position: "relative", left: -20 }}
                >
                  <FormattedMessage id="profile.chemicalcomposition.min" />
                </div>
                <div
                  className="col-sm-2 col-md-2 col-lg-2 m-0 text-left field-fontFamily"
                  style={{ position: "relative", right: 40 }}
                >
                  <FormattedMessage id="profile.chemicalcomposition.max" />
                </div>
                <div
                  className="col-sm-2 col-md-2 col-lg-2 m-0 text-left field-fontFamily"
                  style={{ position: "relative", right: 60 }}
                >
                  <FormattedMessage id="profile.chemicalcomposition.uom" />
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2 m-0 text-left field-fontFamily">
                  {" "}
                </div>
              </div>
              {/* {"Add row"} */}
              <div
                className="dsp-flx col-sm-12 col-md-12 col-lg-12 m-0 position-relative"
                key={defaultValues.id}
                style={{ alignItems: "baseline" }}
              >
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <div className="form-input-block">
                    <Input
                      name={"Constituent"}
                      value={fields.Constituent}
                      onFieldChange={(e) => {
                        // setFieldValue("Constituent", e.target.value)
                        setFields({ ...fields, Constituent: e.target.value })
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <div
                    className="form-input-block mb-4 mni-style"
                    style={{ marginTop: 2 }}
                  >
                    <Select
                      name={"Key"}
                      label="Select"
                      labelKey={"l"}
                      className={"sort-dropdown"}
                      options={rangeOpts.options}
                      selected={
                        rangeOpts.options?.filter(
                          (val) => val?.v === fields.Key
                        )?.[0]
                      }
                      onSelect={(e) => {
                        // setFieldValue("Key", e?.v)
                        if (e?.v == "=" || e?.v == ">") {
                          setFields({ ...fields, Key: e?.v, Max: null })
                        } else if (e?.v == "<") {
                          setFields({ ...fields, Key: e?.v, Min: null })
                        } else if (e?.v == "B" || e?.v == "T") {
                          setFields({
                            ...fields,
                            Key: e?.v,
                            Min: "",
                            Max: "",
                            UOM: "",
                          })
                        } else {
                          setFields({ ...fields, Key: e?.v })
                        }
                        // setFields("Key", e.target.value)
                      }}
                      value={fields?.Key}
                      disabled={type === "viewprofile"}
                    />
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <div
                    className="form-input-block"
                    style={{ position: "relative", left: -30 }}
                  >
                    <Input
                      name={"Min"}
                      value={fields.Min}
                      type="number"
                      disabled={
                        fields?.Key === "<" ||
                        type === "viewprofile" ||
                        fields?.Key === "B" ||
                        fields?.Key === "T"
                          ? true
                          : false
                      }
                      onFieldChange={(e) => {
                        const { value } = e.target
                        if (/^\d*\.?\d*$/.test(value)) {
                          if (maxVal && Number(value) > maxVal) {
                            if (value) {
                              setMinError(true)
                              setMaxError(false)
                            }
                          } else {
                            if (value) {
                              setMinError(false)
                              setMaxError(false)
                            }
                          }
                          setFields({
                            ...fields,
                            Min: value ? value : null,
                          })
                          setMinVal(value && parseFloat(value))
                        }
                      }}
                      onBlurChange={(e) => {
                        const { value } = e.target
                        if (/^\d*\.?\d*$/.test(value)) {
                          // if (maxVal && Number(value) > maxVal) {
                          //   if (value) {
                          //     setMinError(true)
                          //     setMaxError(false)
                          //   }
                          // } else {
                          //   if (value) {
                          //     setMinError(false)
                          //     setMaxError(false)
                          //   }
                          // }
                          setFields({
                            ...fields,
                            Min: value ? value : null,
                          })
                          setMinVal(value && parseFloat(value))
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <div
                    className="form-input-block"
                    style={{ position: "relative", right: 50 }}
                  >
                    <Input
                      name={"Max"}
                      value={fields.Max}
                      type="number"
                      disabled={
                        fields?.Key === "=" ||
                        fields?.Key === ">" ||
                        type === "viewprofile" ||
                        fields?.Key === "B" ||
                        fields?.Key === "T"
                          ? true
                          : false
                      }
                      onFieldChange={(e) => {
                        const { value } = e.target
                        if (/^\d*\.?\d*$/.test(value)) {
                          if (minVal && Number(value) < minVal) {
                            setMaxError(true)
                            setMinError(false)
                          } else {
                            setMaxError(false)
                            setMinError(false)
                          }
                          setFields({
                            ...fields,
                            Max: value ? value : null,
                          })
                          setMaxVal(value && parseFloat(value))
                        }
                        // if (/^\d*\.?\d*$/.test(value)) {
                        //   setFields({ ...fields, Max: value })
                        // }
                      }}
                      onBlurChange={(e) => {
                        const { value } = e.target
                        if (/^\d*\.?\d*$/.test(value)) {
                          // if (minVal && Number(value) < minVal) {
                          //   setMaxError(true)
                          //   setMinError(false)
                          // } else {
                          //   setMaxError(false)
                          //   setMinError(false)
                          // }
                          setFields({
                            ...fields,
                            Max: value ? value : null,
                          })
                          setMaxVal(value && parseFloat(value))
                        }
                        // if (/^\d*\.?\d*$/.test(value)) {
                        //   setFields({ ...fields, Max: value })
                        // }
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <div
                    className="form-input-block mb-4 mni-style add-constituent"
                    style={{ position: "relative", right: 50 }}
                  >
                    <Select
                      label="Select"
                      name={"UOM"}
                      labelkey={"l"}
                      className={`sort-dropdown`}
                      options={uomOpts.options}
                      selected={
                        uomOpts.options?.filter(
                          (val) => val?.v === fields.UOM
                        )?.[0]
                      }
                      onSelect={(e) => {
                        setFields({ ...fields, UOM: e?.v })
                      }}
                      value={fields.UOM}
                      defaultValue={uomOpts.options[0]}
                      disabled={
                        type === "viewprofile" ||
                        fields?.Key === "B" ||
                        fields?.Key === "T"
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <div
                    className="form-input-block"
                    style={{ position: "relative", right: 50 }}
                  >
                    <button
                      className="edit-button"
                      onClick={() => {
                        if (
                          fields?.Constituent?.trim()?.length === 0 ||
                          maxError ||
                          minError ||
                          !fields?.Key ||
                          ((fields?.Min === null ||
                            fields?.Min === undefined ||
                            !(String(fields?.Min)?.length > 0)) &&
                            fields?.Key !== "<" &&
                            fields?.Key !== "B" &&
                            fields?.Key !== "T" &&
                            fields?.Key !== "S") ||
                          (!fields?.Max &&
                            fields?.Key !== "=" &&
                            fields?.Key !== ">" &&
                            fields?.Key !== "B" &&
                            fields?.Key !== "T" &&
                            fields?.Key !== "S") ||
                          (!fields?.UOM &&
                            fields?.Key !== "B" &&
                            fields?.Key !== "S" &&
                            fields?.Key !== "T") ||
                          (fields?.Key == "R" &&
                            (fields?.Min === null ||
                              fields?.Min === undefined ||
                              !(String(fields?.Min)?.length > 0) ||
                              !fields?.Max))
                        ) {
                          setAddFlag(true)
                        } else {
                          getPotentialTriChemicals(fields.Constituent)
                          setShowModal(true)
                        }
                      }}
                      type="button"
                      // disabled={
                      //   fields?.Constituent?.trim()?.length === 0 ||
                      //   !fields?.Key ||
                      //   !fields?.UOM
                      // }
                    >
                      <FormattedMessage id="profile.chemicalcomposition.add" />
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="dsp-flx col-sm-12 col-md-12 col-lg-12 pb-2 m-0 position-relative"
                key={defaultValues.id}
                style={{ alignItems: "baseline" }}
              >
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <div style={{ color: "red", fontSize: 10, paddingTop: 10 }}>
                    {addFlag && fields?.Constituent?.length === 0 ? (
                      <div>Constituent is required</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <div
                    style={{
                      color: "red",
                      fontSize: 10,
                      position: "relative",
                      left: -70,
                    }}
                  >
                    {addFlag && fields?.Key === "" ? (
                      <div>Key is required</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <div
                    style={{
                      color: "red",
                      fontSize: 10,
                      paddingTop: 10,
                      position: "relative",
                      right: 106,
                    }}
                  >
                    {addFlag &&
                    (fields?.Min === null ||
                      fields?.Min === undefined ||
                      !(String(fields?.Min)?.length > 0)) &&
                    (fields?.Key === "=" ||
                      fields?.Key === ">" ||
                      // fields?.Key === "B" ||
                      // fields?.Key === "T" ||
                      // fields?.Key === "S" ||
                      fields?.Key === "R") ? (
                      <div>Min is required</div>
                    ) : minError ? (
                      <div>
                        Minimum Value must be less than or equal to Maximum
                        Value
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <div
                    style={{
                      color: "red",
                      fontSize: 10,
                      paddingTop: 10,
                      position: "relative",
                      right: 160,
                    }}
                  >
                    {addFlag &&
                    (fields?.Max === null || !fields?.Max) &&
                    (fields?.Key === "<" ||
                      // fields?.Key === "S" ||
                      // fields?.Key === "B" ||
                      // fields?.Key === "T" ||
                      fields?.Key === "R") ? (
                      <div>Max is required</div>
                    ) : maxError ? (
                      <div>
                        Maximum Value must be greater than or Equal to Minimum
                        Value
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <div
                    style={{
                      color: "red",
                      fontSize: 10,
                      paddingTop: 10,
                      position: "relative",
                      left: -220,
                    }}
                  >
                    {addFlag &&
                    fields?.UOM === "" &&
                    fields?.Key !== "S" &&
                    fields?.Key !== "B" &&
                    fields?.Key !== "T" ? (
                      <div>UOM is required</div>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* Modal, to Add TRI Status */}
              <div className="mniChemDiv">
                <Modal show={showModal}>
                  <div className="col-sm-12 p-4">
                    <div className="mb-1 myprofile" style={{ fontSize: 14 }}>
                      <FormattedMessage id="profile.chemicalcomposition.TRIheader" />
                    </div>
                    <div
                      className="mb-2 text-description"
                      style={{ fontSize: 10 }}
                    >
                      <FormattedMessage id="profile.chemicalcomposition.TRIinstruction" />
                    </div>
                    <div
                      className="customerTableContainer"
                      style={{ maxHeight: "60VH", overflow: "auto" }}
                    >
                      <div className="row m-0 customerTableHeader">
                        <div className="col-sm-6 p-0 py-2 activeIndex">
                          <FormattedMessage id="profile.chemicalcomposition.TRIcascol" />
                        </div>
                        <div className="col-sm-6 p-0 py-2 activeIndex">
                          <FormattedMessage id="profile.chemicalcomposition.TRIdesccol" />
                        </div>
                      </div>
                      {loadChemicals === true ? (
                        <div style={{ marginTop: 70 }}>
                          <div className="loader"></div>
                        </div>
                      ) : (
                        triItems?.map((item, i) => (
                          <div
                            className="row customDataTab m-0"
                            key={i}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleTriSelect(item)
                              setActiveIndex(i)
                            }}
                          >
                            <div
                              className={`col-sm-6 px-1 py-2 mb-1 customCell ${
                                activeIndex === i ? "activeIndex" : ""
                              }`}
                            >
                              {item?.cas}
                            </div>
                            <div
                              className={`col-sm-6 px-1 py-2 mb-1 customCell ${
                                activeIndex === i ? "activeIndex" : ""
                              }`}
                            >
                              {item?.chemicalName}
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                    <div
                      className="col-sm-12"
                      style={{
                        padding: 0,
                        margin: "10px 0",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        className="green-button col-sm-3"
                        style={{ padding: 9, fontSize: 8 }}
                        onClick={(e) => {
                          handleAddRow(e, "N")
                          setAddFlag(false)
                          setShowModal(false)
                          setActiveIndex(-1)
                          resetChemicals()
                        }}
                      >
                        <FormattedMessage id="profile.chemicalcomposition.TRIuserconst" />
                      </button>
                      <button
                        className="cancel-button col-sm-3"
                        style={{ padding: 9, fontSize: 8 }}
                        onClick={() => {
                          setActiveIndex(-1)
                          setAddFlag(false)
                          setShowModal(false)
                          resetChemicals()
                        }}
                      >
                        <FormattedMessage id="profile.chemicalcomposition.TRIclose" />
                      </button>
                      {activeIndex === -1 ? (
                        <button
                          className="disabledBgBtn col-sm-3"
                          style={{ padding: 9, fontSize: 8, marginRight: 0 }}
                        >
                          <FormattedMessage id="profile.chemicalcomposition.TRIadd" />
                        </button>
                      ) : (
                        <button
                          className="green-button col-sm-3"
                          style={{ padding: 9, fontSize: 8, marginRight: 0 }}
                          onClick={(e) => {
                            handleAddRow(e, "Y")
                            setAddFlag(false)
                            setActiveIndex(-1)
                            setShowModal(false)
                            resetChemicals()
                          }}
                        >
                          <FormattedMessage id="profile.chemicalcomposition.TRIadd" />
                        </button>
                      )}
                    </div>
                  </div>
                </Modal>
              </div>
              {/* Content */}
              <div
                className="col-sm-11 col-md-11 col-lg-11 mx-3 p-0 table sticky isshipreport"
                // style={{ maxHeight: 400, overflow: "auto" }}
                style={{ overflow: "visible" }}
              >
                <div className="row m-0 headers" style={{ zIndex: 3 }}>
                  <div className={`tr active`}>
                    <div
                      className={`th customsort inactive title-container`}
                      colSpan={1}
                      style={{
                        display: "inline-block",
                        boxSizing: "border-box",
                        width: 175,
                      }}
                    >
                      <span className="title-text">
                        <FormattedMessage id="profile.chemicalcomposition.constituent" />
                      </span>
                    </div>
                    <div
                      className={`th customsort inactive title-container`}
                      colSpan={1}
                      style={{
                        display: "inline-block",
                        boxSizing: "border-box",
                        width: 140,
                      }}
                    >
                      <span className="title-text">
                        <FormattedMessage id="profile.chemicalcomposition.key" />
                      </span>
                    </div>
                    <div
                      className={`th customsort inactive title-container`}
                      colSpan={1}
                      style={{
                        display: "inline-block",
                        boxSizing: "border-box",
                        width: 125,
                      }}
                    >
                      <span className="title-text">
                        <FormattedMessage id="profile.chemicalcomposition.min" />
                      </span>
                    </div>
                    <div
                      className={`th customsort inactive title-container`}
                      colSpan={1}
                      style={{
                        display: "inline-block",
                        boxSizing: "border-box",
                        width: 125,
                      }}
                    >
                      <span className="title-text">
                        <FormattedMessage id="profile.chemicalcomposition.max" />
                      </span>
                    </div>
                    <div
                      className={`th customsort inactive title-container`}
                      colSpan={1}
                      style={{
                        display: "inline-block",
                        boxSizing: "border-box",
                        width: 100,
                      }}
                    >
                      <span className="title-text">
                        <FormattedMessage id="profile.chemicalcomposition.uom" />
                      </span>
                    </div>
                    <div
                      className={`th customsort inactive title-container`}
                      colSpan={1}
                      style={{
                        display: "inline-block",
                        boxSizing: "border-box",
                        width: 60,
                      }}
                    >
                      <span className="title-text">
                        <FormattedMessage id="profile.chemicalcomposition.tristatus" />
                      </span>
                    </div>
                    <div
                      className={`th customsort inactive title-container`}
                      colSpan={1}
                      style={{
                        display: "inline-block",
                        boxSizing: "border-box",
                        width: 100,
                      }}
                    >
                      <span className="title-text">
                        <FormattedMessage id="profile.chemicalcomposition.cas" />
                      </span>
                    </div>
                    <div
                      className={`th customsort inactive title-container`}
                      colSpan={1}
                      style={{
                        display: "inline-block",
                        boxSizing: "border-box",
                        width: 75,
                      }}
                    >
                      <span className="title-text"></span>
                    </div>
                  </div>
                </div>
                <div className="row m-0 body">
                  {rows.map((row, i) => (
                    <div
                      className="tr active"
                      style={{ display: "flex", minHeight: 70 }}
                      key={i}
                    >
                      {/* <div
                      className="row customDataTab m-0"
                      style={{
                        background: "#F6F9FB",
                      }}
                    > */}
                      <div
                        className="td custom"
                        colSpan={1}
                        style={{
                          display: "inline-block",
                          flexDirection: "column",
                          boxSizing: "border-box",
                          width: 175,
                        }}
                      >
                        <div
                          className="form-input-block"
                          style={{ marginTop: editError ? 7 : 5 }}
                        >
                          <Input
                            placeholder={row.Constituent}
                            name={row.Constituent}
                            disabled={
                              row.TRIStatus === true ||
                              row.triStatus === true ||
                              row.TRIStatus == "Y" ||
                              row.triStatus == "Y" ||
                              type === "viewprofile"
                            }
                            value={row.Constituent || row.constituent}
                            onFieldChange={(e) => {
                              let data = rows
                              data[i].Constituent = e.target.value
                              setRows(data)
                              setChemComp(data)
                              setIsModified((val) => !val)
                            }}
                          />
                        </div>
                        {editFlag &&
                          (errorMinRows?.[i] ||
                            errorMaxRows?.[i] ||
                            editError) && (
                            <div
                              style={{
                                color: "red",
                                fontSize: 9,
                                paddingTop: 10,
                              }}
                            >
                              {editFlag && row?.Constituent?.length === 0 ? (
                                <div>Constituent is required</div>
                              ) : null}
                            </div>
                          )}
                      </div>
                      <div
                        className="td custom"
                        colSpan={1}
                        style={{
                          overflow: "visible",
                          display: "inline-block",
                          flexDirection: "column",
                          boxSizing: "border-box",
                          width: 150,
                        }}
                      >
                        <div
                          className="form-input-block mb-dropdown mni-style table-key"
                          style={{
                            width: 120,
                            marginLeft: 25,
                            marginBottom:
                              editError ||
                              (editFlag &&
                                (errorMinRows?.[i] || errorMaxRows?.[i]))
                                ? 41
                                : 33,
                          }}
                        >
                          <Select
                            name={row.Key}
                            label="Select"
                            disabled={type == "viewprofile"}
                            labelKey={"l"}
                            className={"sort-dropdown"}
                            options={rangeOpts.options}
                            selected={
                              rangeOpts.options?.filter(
                                (val) => val.v === row.Key || val.l === row.key
                              )?.[0]
                            }
                            onSelect={(e) => {
                              let data = [...rows]
                              if (e?.v == "=" || e?.v == ">") {
                                data[i].Key = e?.v
                                data[i].Max = ""
                                if (data[i]?.max) {
                                  data[i].max = ""
                                }
                                setErrorMaxRows({
                                  ...errorMaxRows,
                                  [i]: false,
                                })
                              } else if (e?.v == "<") {
                                data[i].Key = e?.v
                                data[i].Min = ""
                                if (data[i]?.min) {
                                  data[i].min = ""
                                }
                                setErrorMinRows({
                                  ...errorMinRows,
                                  [i]: false,
                                })
                              } else if (e?.v == "B" || e?.v == "T") {
                                data[i].Key = e?.v
                                data[i].Min = ""
                                data[i].Max = ""
                                if (data[i]?.min) {
                                  data[i].min = ""
                                }
                                if (data[i]?.max) {
                                  data[i].max = ""
                                }
                                setErrorMinRows({
                                  ...errorMinRows,
                                  [i]: false,
                                })
                                setErrorMaxRows({
                                  ...errorMaxRows,
                                  [i]: false,
                                })
                                data[i].UOM = ""
                              } else {
                                data[i].Key = e?.v
                              }
                              setRows([...data])
                              setChemComp([...data])
                              setIsModified((val) => !val)
                            }}
                          />
                        </div>
                        {editFlag &&
                          (errorMinRows?.[i] ||
                            errorMaxRows?.[i] ||
                            editError) && (
                            <div
                              style={{
                                color: "red",
                                fontSize: 9,
                                // position: "relative",
                                left: -70,
                              }}
                            >
                              {editFlag &&
                              (row?.Key === "" || row?.Key?.length > 1) ? (
                                <div>Key is required</div>
                              ) : null}
                            </div>
                          )}
                        <span></span>
                      </div>
                      <div
                        className="td custom"
                        colSpan={1}
                        style={{
                          display: "inline-block",
                          flexDirection: "column",
                          boxSizing: "border-box",
                          width: 120,
                        }}
                      >
                        <div
                          className="form-input-block"
                          style={{
                            position: "relative",
                            top:
                              editError ||
                              (editFlag &&
                                (errorMinRows?.[i] || errorMaxRows?.[i]))
                                ? 15
                                : 2,
                            width: 85,
                          }}
                        >
                          <Input
                            // placeholder={row.Min}
                            disabled={
                              row?.Key == "<" ||
                              type === "viewprofile" ||
                              row?.Key === "B" ||
                              row?.Key === "T"
                                ? true
                                : false
                            }
                            name={row.Min}
                            // type="number"
                            value={
                              row.Min !== undefined && row.Min !== null
                                ? row?.Min
                                : row?.min !== undefined && row?.min !== null
                                ? row?.min
                                : ""
                            }
                            onFieldChange={(e) => {
                              const { value } = e.target
                              if (/^\d*\.?\d*$/.test(value)) {
                                let data = rows
                                if (
                                  data[i]?.Max &&
                                  value > Number(data[i].Max)
                                ) {
                                  setErrorMinRows({
                                    ...errorMinRows,
                                    [i]: true,
                                  })
                                  setErrorMaxRows({
                                    ...errorMaxRows,
                                    [i]: false,
                                  })
                                } else {
                                  setErrorMinRows({
                                    ...errorMinRows,
                                    [i]: false,
                                  })
                                  setErrorMaxRows({
                                    ...errorMaxRows,
                                    [i]: false,
                                  })
                                }
                                data[i].Min = value ? value : null
                                setRows([...data])
                                setChemComp([...data])
                                setIsModified((val) => !val)
                              }
                            }}
                            // onBlurChange={() => {}}
                          />
                        </div>
                        {editFlag &&
                          (errorMinRows?.[i] ||
                            errorMaxRows?.[i] ||
                            editError) && (
                            <div
                              style={{
                                color: "red",
                                fontSize: 8,
                                paddingTop: 15,
                                // position: "relative",
                                // right: 120,
                                lineHeight: "10px",
                                height:
                                  errorMinRows?.[i] ||
                                  errorMaxRows?.[i] ||
                                  editError
                                    ? 35
                                    : 5,
                                position: "static",
                              }}
                            >
                              {editFlag &&
                              (row?.Min === null ||
                                row?.Min === undefined ||
                                !(String(row?.Min)?.length > 0)) &&
                              (row?.Key === "=" ||
                                row?.Key === ">" ||
                                // row?.Key === "B" ||
                                // row?.Key === "T" ||
                                // row?.Key === "S" ||
                                row?.Key === "R" ||
                                row?.Key?.length > 1) ? (
                                <div>Min is required</div>
                              ) : errorMinRows?.[i] ? (
                                <div>
                                  Minimum Value must be less than or equal to
                                  Maximum Value
                                </div>
                              ) : null}
                            </div>
                          )}
                      </div>
                      <div
                        className="td custom"
                        colSpan={1}
                        style={{
                          display: "inline-block",
                          flexDirection: "column",
                          boxSizing: "border-box",
                          width: 120,
                        }}
                      >
                        <div
                          className="form-input-block"
                          style={{
                            width: 85,
                            position: "relative",
                            top:
                              editError ||
                              (editFlag &&
                                (errorMinRows?.[i] || errorMaxRows?.[i]))
                                ? 15
                                : 2,
                          }}
                        >
                          <Input
                            // placeholder={row.Max}
                            name={row.Max}
                            // type="number"
                            disabled={
                              row?.Key === "=" ||
                              row?.Key === ">" ||
                              type === "viewprofile" ||
                              row?.Key === "B" ||
                              row?.Key === "T"
                                ? true
                                : false
                            }
                            value={
                              row.Max !== undefined && row.Max !== null
                                ? row?.Max
                                : row?.max !== undefined && row?.max !== null
                                ? row?.max
                                : ""
                            }
                            onFieldChange={(e) => {
                              const { value } = e.target
                              if (/^\d*\.?\d*$/.test(value)) {
                                let data = rows
                                if (
                                  data[i].Min &&
                                  value < Number(data[i].Min)
                                ) {
                                  setErrorMaxRows({
                                    ...errorMaxRows,
                                    [i]: true,
                                  })
                                  setErrorMinRows({
                                    ...errorMinRows,
                                    [i]: false,
                                  })
                                } else {
                                  setErrorMinRows({
                                    ...errorMinRows,
                                    [i]: false,
                                  })
                                  setErrorMaxRows({
                                    ...errorMaxRows,
                                    [i]: false,
                                  })
                                }
                                data[i].Max = value ? value : null
                                setRows([...data])
                                setChemComp([...data])
                                setIsModified((val) => !val)
                              }
                            }}
                            // onBlurChange={() => {}}
                          />
                        </div>
                        {editFlag &&
                          (errorMinRows?.[i] ||
                            errorMaxRows?.[i] ||
                            editError) && (
                            <div
                              style={{
                                color: "red",
                                fontSize: 8,
                                paddingTop: 15,
                                // position: "relative",
                                // right: 180,
                                lineHeight: "10px",
                                height:
                                  errorMinRows?.[i] ||
                                  errorMaxRows?.[i] ||
                                  editError
                                    ? 35
                                    : 5,
                                position: "static",
                              }}
                            >
                              {editFlag &&
                              (row?.Max === null || !row?.Max) &&
                              (row?.Key === "<" ||
                                // row?.Key === "S" ||
                                // row?.Key === "B" ||
                                // row?.Key === "T" ||
                                row?.Key === "R" ||
                                row?.Key?.length > 1) ? (
                                <div>Max is required</div>
                              ) : (row?.Max !== null || row?.Max) &&
                                errorMaxRows?.[i] ? (
                                <div>
                                  Maximum Value must be greater than or Equal to
                                  Minimum Value
                                </div>
                              ) : null}
                            </div>
                          )}
                      </div>
                      <div
                        className="td custom d-ib"
                        colSpan={1}
                        style={{
                          overflow: "visible",
                          flexDirection: "column",
                          boxSizing: "border-box",
                          width: 100,
                        }}
                      >
                        <div
                          className="mb-dropdown form-input-block mni-style table-uom"
                          style={{
                            width: 80,
                            marginLeft: 25,
                            // marginTop: editError ? 0 : 0,
                            position: "relative",
                            top:
                              editError ||
                              (editFlag &&
                                (errorMinRows?.[i] || errorMaxRows?.[i]))
                                ? 3
                                : 0,
                          }}
                        >
                          <Select
                            label="Select"
                            name={row.UOM}
                            labelkey={"l"}
                            disabled={
                              type === "viewprofile" ||
                              row?.Key === "B" ||
                              row?.Key === "T"
                            }
                            selected={
                              uomOpts.options?.filter(
                                (val) => val.v === row.UOM || val.v === row.uom
                              )?.[0]
                            }
                            className={"sort-dropdown"}
                            options={uomOpts.options}
                            onSelect={(e) => {
                              let data = rows
                              data[i].UOM = e?.v
                              setRows(data)
                              setChemComp(data)
                              setIsModified((val) => !val)
                            }}
                          />
                        </div>
                        {editFlag &&
                          (errorMinRows?.[i] ||
                            errorMaxRows?.[i] ||
                            editError) && (
                            <div
                              style={{
                                color: "red",
                                fontSize: 9,
                                paddingTop: 4,
                                // position: "relative",
                                // left: -220,
                                position: "static",
                                height: 15,
                              }}
                            >
                              {editFlag &&
                              row?.UOM === "" &&
                              row?.Key !== "B" &&
                              row?.Key !== "S" &&
                              row?.Key !== "T" ? (
                                <div>UOM is required</div>
                              ) : null}
                            </div>
                          )}
                        <span></span>
                      </div>
                      <div
                        className="td custom"
                        colSpan={1}
                        style={{
                          display: "inline-block",
                          flexDirection: "column",
                          boxSizing: "border-box",
                          width: 60,
                        }}
                      >
                        {row?.TRIStatus == true ||
                        row?.TRIStatus == "Y" ||
                        row?.tristatus == true ||
                        row?.tristatus == "Y"
                          ? "Y"
                          : "N"}
                      </div>
                      <div
                        className="td custom"
                        colSpan={1}
                        style={{
                          display: "inline-block",
                          flexDirection: "column",
                          boxSizing: "border-box",
                          width: 100,
                        }}
                      >
                        {row?.TRIStatus == true ||
                        row?.TRIStatus == "Y" ||
                        row?.tristatus == true ||
                        row?.tristatus == "Y"
                          ? row.CAS || row.cas
                          : ""}
                      </div>
                      <div
                        className="td custom"
                        colSpan={1}
                        style={{
                          display: "inline-block",
                          boxSizing: "border-box",
                          flexDirection: "column",
                          width: 75,
                        }}
                      >
                        <Image
                          className="view-icon mr-2"
                          src="Delete.svg"
                          height="24px"
                          width="24px"
                          alt=""
                          onClick={() => {
                            // const updatedRows = [...rows]
                            // const deletedRow = updatedRows.splice(i, 1)[0]
                            // setRows(updatedRows)
                            // setChemComp(updatedRows)
                            handleDeleteRow(i)
                            setErrorMinRows({ ...errorMinRows, [i]: undefined })
                            setErrorMaxRows({ ...errorMaxRows, [i]: undefined })
                            setIsModified((val) => !val)
                          }}
                        />
                      </div>
                      {/* {editFlag &&
                        (errorMinRows?.[i] || errorMaxRows?.[i] || editError) && (
                          <div
                            className="row customDataTab m-0"
                            key={defaultValues.id}
                            style={{ alignItems: "baseline" }}
                          >
                            <div className="col-sm-3 col-md-3 col-lg-3">
                              <div
                                style={{
                                  color: "red",
                                  fontSize: 8,
                                  paddingTop: 10,
                                }}
                              >
                                {editFlag && row?.Constituent?.length === 0 ? (
                                  <div>Constituent is required</div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-sm-2 col-md-2 col-lg-2">
                              <div
                                style={{
                                  color: "red",
                                  fontSize: 8,
                                  // position: "relative",
                                  left: -70,
                                }}
                              >
                                {editFlag &&
                                  (row?.Key === "" || row?.Key?.length > 1) ? (
                                  <div>Key is required</div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-sm-1 col-md-1 col-lg-1">
                              <div
                                style={{
                                  color: "red",
                                  fontSize: 8,
                                  paddingTop: 10,
                                  // position: "relative",
                                  right: 120,
                                }}
                              >
                                {editFlag &&
                                  (row?.Min === null ||
                                    row?.Min === undefined ||
                                    !(String(row?.Min)?.length > 0)) &&
                                  (row?.Key === "=" ||
                                    row?.Key === ">" ||
                                    // row?.Key === "B" ||
                                    // row?.Key === "T" ||
                                    row?.Key === "S" ||
                                    row?.Key === "R" ||
                                    row?.Key?.length > 1) ? (
                                  <div>Min is required</div>
                                ) : null}
                                {errorMinRows?.[i] ? (
                                  <div>
                                    Minimum Value must be less than or equal to
                                    the Maximum Value
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-sm-2 col-md-2 col-lg-2">
                              <div
                                style={{
                                  color: "red",
                                  fontSize: 8,
                                  paddingTop: 10,
                                  // position: "relative",
                                  right: 180,
                                }}
                              >
                                {editFlag &&
                                  (row?.Max === null || !row?.Max) &&
                                  (row?.Key === "<" ||
                                    row?.Key === "S" ||
                                    // row?.Key === "B" ||
                                    // row?.Key === "T" ||
                                    row?.Key === "R" ||
                                    row?.Key?.length > 1) ? (
                                  <div>Max is required</div>
                                ) : null}
                                {errorMaxRows?.[i] ? (
                                  <div>
                                    Maximum Value must be greater than or Equal to
                                    Minimum Value
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-sm-1 col-md-1 col-lg-1">
                              <div
                                style={{
                                  color: "red",
                                  fontSize: 8,
                                  paddingTop: 10,
                                  // position: "relative",
                                  left: -220,
                                }}
                              >
                                {editFlag &&
                                  row?.UOM === "" &&
                                  row?.Key !== "B" &&
                                  row?.Key !== "T" ? (
                                  <div>UOM is required</div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-sm-1"></div>
                            <div className="col-sm-1"></div>
                            <div className="col-sm-1"></div>
                          </div>
                        )} */}
                    </div>
                  ))}
                </div>
              </div>
              {rows.length > 0 && (
                <div className="tr active mx-3 mb-4">
                  <div className="td custom" style={{ width: "100%" }}>
                    <button
                      className="cancel-button"
                      onClick={() => {
                        validateSave()
                      }}
                      type="button"
                      style={{
                        paddingLeft: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <label className="check-container">
                        <input type="checkbox" checked="checked" disabled />
                        <span
                          className={"checkmark"}
                          style={{ margin: "-.5rem 1rem 0 0" }}
                        />
                      </label>
                      <span className="" style={{ marginLeft: "40px" }}>
                        {"Save Changes"}
                      </span>
                    </button>
                  </div>
                </div>
              )}
              <span style={{ color: "red", fontSize: 10 }}>
                {submit && rows?.length === 0 ? (
                  <div>Select chemical composition</div>
                ) : null}
              </span>

              {/* Back, Save, Cancel Actions */}
              {type !== "viewprofile" && (
                <div className="row m-0 mt-4 new-row-block">
                  <div className="col-sm-10 col-md-12 col-lg-12 text-left pr-0 mt-2 ml-4">
                    <div className="row m-0 float-left">
                      <button
                        type="button"
                        className={`cancel-button`}
                        onClick={() => backAction()}
                      >
                        Back
                      </button>
                    </div>
                    <div className="row m-0 float-right m-r-2r">
                      <button
                        // type="submit"
                        ref={ref}
                        className={`green-button`}
                        onClick={() => {
                          setSubmit(true)
                          validateSave(true)
                          // formik.onSubmit()
                        }}
                      >
                        Save & Continue
                      </button>
                    </div>
                    <div className="row m-0 float-right m-r-2r">
                      <button
                        type="button"
                        className="cancel-button"
                        onClick={() => cancelAction()}
                      >
                        Cancel
                      </button>
                    </div>
                    <span className="float-right text-red m-r-2r">
                      {`*Required Fields`}
                    </span>
                  </div>
                </div>
              )}
              <AlertModal
                showError={showError}
                seekingStep={seekingStep}
                setShowError={setShowError}
                submitAction={() =>
                  handleAlerts(values, errors, isValid, seekingStep)
                }
                showAlert={showAlert}
                setShowAlert={setShowAlert}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default chemicalcompositionenhancer(ChemicalComposition)
