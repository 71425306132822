import { combineReducers } from "redux"
import user from "./user"
import stores from "./stores"
import loading from "./loading"
import alerts from "./alerts"
import config from "./config"
import filters from "./filters"
import profile from "./profile"
import generators from "./generators"
import changeCustomer from "./changeCustomer"
import customerList from "./customerList"
import roles from "./roles"
import profileDetails from "./Profile/createProfile"
import generatorDetails from "./Generator"

export default combineReducers({
  user,
  stores,
  loading,
  alerts,
  config,
  filters,
  profile,
  generators,
  changeCustomer,
  customerList,
  roles,
  profileDetails,
  generatorDetails,
})
