import React from "react"
import enhancer from "./enhancer"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import Image from "shared/Image"
import Input from "../Components/Input"
import Select from "../Components/Select"
import Loading from "shared/Loading"
import MultiSelect from "../Components/MultiSelect"
import { useSelector } from "react-redux"

const CreateUser = (props) => {
  const {
    goToUserManagement,
    onSelectField,
    fields,
    open,
    onInputChange,
    onSelectValue,
    userTypes,
    customers,
    enterpriseCustomers,
    handleSearchChange,
    submit,
    error,
    profile,
    checkForEdit,
    onUpdateActive,
    isEdit,
    roles,
    businessSegmentId,
    enterpriseIDs,
    division,
  } = props

  const onFieldChange = (event) => {
    onInputChange(event)
  }
  var isBrandValue = fields.userType.v
  const user = useSelector((state) => state.profile)

  const checkCondition = () => {
    if (roles.ENTERPRISE_USER === fields.userType.v) {
      return false
    }
    if (
      roles.CE_ADMIN === fields.userType.v &&
      roles.PORTAL_ADMIN === profile.roleId &&
      fields.businessSegmentId.v
    ) {
      return true
    } else if (
      fields.userType.v &&
      roles.CE_ADMIN !== fields.userType.v &&
      roles.PORTAL_ADMIN === profile.roleId
    ) {
      return true
    } else if (profile.roleId !== roles.PORTAL_ADMIN) {
      return true
    } else if (isBrandValue == roles.Brand_User) {
      return true
    } else {
      return false
    }
  }
  const checkIfEnterpriseSelected = () => {
    if (
      roles.ENTERPRISE_USER === fields?.userType?.v &&
      fields?.enterpriseID?.v &&
      enterpriseCustomers?.length > 0
    ) {
      return true
    }
    return false
  }

  return (
    <Loading id={`${checkForEdit() ? "edit_user" : "create_user"}`}>
      <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-3">
        <div className="col-sm-6 col-md-6 col-lg-6 mt-4 mb-3">
          <div className="row m-0 ">
            <div
              className="dp-al-ctr back-link"
              onClick={() => goToUserManagement()}
            >
              <Image
                className=""
                src={"back-button.svg"}
                height="14px"
                width="14px"
                alt=""
              />
              <div className="p-14th underline mr-f">{`BACK`}</div>
            </div>
          </div>
          <div className="row m-0 admin-home pd-b-0">{`${
            checkForEdit() ? "EDIT USER ACCOUNT" : "CREATE NEW USER"
          }`}</div>
          <div className="row m-0 text-description pd-rl-1">
            <span>
              {!checkForEdit()
                ? "Enter the information for the new Connect+ user you want to create."
                : "You can change the role of a Connect+ user."}
            </span>
            <span>{`Required fields are marked with an Asterisk ("*")`}</span>
          </div>
        </div>
        <div className="row m-0 px-4 new-row-block">
          <div
            className={`col-sm-3 col-md-4 col-lg-4 text-left p-0 pd-2r ${
              checkForEdit() && "none-edit"
            }`}
          >
            <div className="row m-0">
              <div className="col-sm-10 col-md-12 col-lg-12 text-left p-0 ">
                <div className="form-input-block">
                  <span className="label">
                    <FormattedMessage id="createuser.titlefirst" />
                  </span>
                  <Input
                    placeholder="Enter First Name"
                    name="firstName"
                    value={fields.firstName}
                    onFieldChange={onFieldChange}
                  />
                </div>
                {error.firstName && (
                  <span className="field-error">{`Please enter first Name `}</span>
                )}
              </div>
            </div>
          </div>
          <div
            className={`col-sm-3 col-md-4 col-lg-4 text-left p-0 pd-2l ${
              checkForEdit() && "none-edit"
            }`}
          >
            <div className="row m-0">
              <div className="col-sm-10 col-md-12 col-lg-12 text-left p-0 pd-2l">
                <div className="form-input-block">
                  <span className="label">
                    <FormattedMessage id="createuser.titlelast" />
                  </span>
                  <Input
                    placeholder="Enter Last Name"
                    name="lastName"
                    value={fields.lastName}
                    onFieldChange={onFieldChange}
                  />
                </div>
                {error.lastName && (
                  <span className="field-error">{`Please enter last Name `}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`row m-0 px-4 new-row-block mt-4 mb-3 ${
            checkForEdit() && "none-edit"
          }`}
        >
          <div className="col-sm-3 col-md-8 col-lg-8 text-left p-0">
            <div className="row m-0">
              <div className="col-sm-10 col-md-12 col-lg-12 text-left p-0">
                <div className="form-input-block">
                  <span className="label">
                    <FormattedMessage id="createuser.titleemail" />
                  </span>
                  <Input
                    placeholder="Enter Email"
                    name="email"
                    value={fields.email}
                    onFieldChange={onFieldChange}
                  />
                </div>
                {error.email && (
                  <span className="field-error">{`Please enter valid email `}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`row m-0 px-4 new-row-block mt-4 mb-3 ${
            checkForEdit() &&
            (checkIfEnterpriseSelected() ||
              profile?.roleId === roles.CE_ADMIN) &&
            "none-edit"
          }`}
        >
          <div className="col-sm-3 col-md-8 col-lg-8 text-left p-0">
            <div className="row m-0">
              <div className="col-sm-10 col-md-12 col-lg-12 text-left p-0">
                <div className="form-input-block">
                  <span className="label">
                    <FormattedMessage id="createuser.titleusertype" />
                  </span>
                  <Select
                    name="userType"
                    onSelectField={onSelectField}
                    open={open.userType}
                    search={false}
                    placeholder={"Select UserType"}
                    options={userTypes}
                    onSelectValue={onSelectValue}
                    value={fields.userType}
                  />
                </div>
                {error.userType && (
                  <span className="field-error">{`Please select userType `}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        {profile.roleId === roles.PORTAL_ADMIN &&
          fields.userType.v === roles.CE_ADMIN && (
            <div
              className={`row m-0 px-4 new-row-block mt-4 mb-3 ${
                (checkForEdit() || profile?.roleId === roles.CUSTOMER_ADMIN) &&
                "none-edit"
              }`}
            >
              <div className="col-sm-3 col-md-8 col-lg-8 text-left p-0">
                <div className="row m-0">
                  <div className="col-sm-10 col-md-12 col-lg-12 text-left p-0">
                    <div className="form-input-block">
                      <span className={`label ${open.userType && "index-0"}`}>
                        <FormattedMessage id="createuser.tilebusiness" />
                      </span>
                      <Select
                        name="businessSegmentId"
                        onSelectField={onSelectField}
                        open={open.businessSegmentId}
                        search={false}
                        placeholder={"Select Business Segment"}
                        options={businessSegmentId}
                        onSelectValue={onSelectValue}
                        value={fields.businessSegmentId}
                      />
                    </div>
                    {error.businessSegmentId && (
                      <span className="field-error">{`Please select Bussiness Segment `}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

        {profile.roleId === roles.PORTAL_ADMIN &&
          fields.userType.v === roles.ENTERPRISE_USER && (
            <div
              className={`row m-0 px-4 new-row-block mt-4 mb-3 ${
                (checkForEdit() || profile?.roleId === roles.CUSTOMER_ADMIN) &&
                "none-edit"
              }`}
            >
              <div className="col-sm-3 col-md-8 col-lg-8 text-left p-0">
                <div className="row m-0">
                  <div className="col-sm-10 col-md-12 col-lg-12 text-left p-0">
                    <div className="form-input-block">
                      <span className={`label ${open.userType && "index-0"}`}>
                        <FormattedMessage id="createuser.titleenterprise" />
                      </span>
                      <Select
                        name="enterpriseID"
                        onSelectField={onSelectField}
                        open={open.enterpriseID}
                        search={true}
                        placeholder={"Select Enterprise"}
                        options={enterpriseIDs}
                        handleSearchChange={handleSearchChange}
                        loadingId={"enterpriseID"}
                        searchField={"enterpriseID"}
                        onSelectValue={onSelectValue}
                        value={fields.enterpriseID}
                        hasId={true}
                      />
                    </div>
                    {fields?.enterpriseID?.v &&
                      enterpriseCustomers?.length <= 0 && (
                        <span className="field-error">{`No customers are associated with the selected enterprise.`}</span>
                      )}
                    {error.enterpriseID && (
                      <span className="field-error">{`Please select enterprise `}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        {checkCondition() && (
          <div
            className={`row m-0 px-4 new-row-block mt-4 mb-3 ${
              (checkForEdit() || profile?.roleId === roles.CUSTOMER_ADMIN) &&
              "none-edit"
            }`}
          >
            <div className="col-sm-3 col-md-8 col-lg-8 text-left p-0">
              <div className="row m-0">
                <div className="col-sm-10 col-md-12 col-lg-12 text-left p-0">
                  <div className="form-input-block">
                    <span
                      className={`label ${
                        (open.businessSegmentId || open.userType) && "index-0"
                      }`}
                    >
                      <FormattedMessage id="createuser.titleaccount" />
                    </span>
                    <Select
                      name="customerName"
                      onSelectField={onSelectField}
                      open={open.customerName}
                      search={true}
                      placeholder={"Select Account"}
                      options={
                        fields.userType.v === 9
                          ? customers?.filter(
                              (val) => val.businessSegmentId == 3
                            )
                          : fields.userType.v === 7 ||
                            fields.userType.v === 1 ||
                            fields.userType.v === 5
                          ? customers
                          : customers?.filter(
                              (val) => val.businessSegmentId != 3
                            )
                      }
                      onSelectValue={onSelectValue}
                      value={fields.customerName}
                      handleSearchChange={handleSearchChange}
                      loadingId={"customer_search"}
                      searchField={"customerSearch"}
                      hasId={true}
                      disabled={user?.roleId == roles.CUSTOMER_ADMIN}
                    />
                  </div>
                  {error.customerName && (
                    <span className="field-error">{`Please select account `}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {checkIfEnterpriseSelected() && (
          <div className={`row m-0 px-4 new-row-block mt-4 mb-3`}>
            <div className="col-sm-3 col-md-8 col-lg-8 text-left p-0">
              <div className="row m-0">
                <div className="col-sm-10 col-md-12 col-lg-12 text-left p-0">
                  <div className="form-input-block">
                    <span
                      className={`label ${
                        (open.enterpriseID || open.userType) && "index-0"
                      }`}
                    >
                      <FormattedMessage id="createuser.titleaccount" />
                    </span>
                    <MultiSelect
                      name="customerName"
                      onSelectField={onSelectField}
                      open={open.customerName}
                      search={true}
                      placeholder={"Select Account"}
                      options={enterpriseCustomers}
                      onSelectValue={onSelectValue}
                      value={fields.customerName}
                      loadingId={"entcustomer_search"}
                      hasId={true}
                    />
                  </div>
                  {error.customerName && (
                    <span className="field-error">{`Select at least one customer, with one as the default. `}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {isBrandValue == roles.Brand_User &&
          fields.customerName != "" &&
          checkCondition() && (
            <div className={`row m-0 px-4 new-row-block mt-4 mb-3`}>
              <div className="col-sm-3 col-md-8 col-lg-8 text-left p-0">
                <div className="row m-0">
                  <div className="col-sm-10 col-md-12 col-lg-12 text-left p-0">
                    <div className="form-input-block">
                      <span
                        className={`label ${
                          (open.businessSegmentId ||
                            open.userType ||
                            open.customerName) &&
                          "index-0"
                        }`}
                      >
                        <FormattedMessage id="createuser.tilebrand" />
                      </span>
                      <Select
                        name="division"
                        onSelectField={onSelectField}
                        open={open.division}
                        search={true}
                        placeholder={"Select Brand"}
                        options={division}
                        onSelectValue={onSelectValue}
                        value={fields.division}
                        handleSearchChange={handleSearchChange}
                        loadingId={"division_search"}
                        searchField={"divisionSearch"}
                        hasId={true}
                      />
                    </div>
                    {error.division && (
                      <span className="field-error">{`Please select brand`}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        {checkForEdit() && (
          <div className="row m-0 px-4 new-row-block mt-4 mb-3">
            <div className="col-sm-3 col-md-8 col-lg-8 text-left p-0">
              <div className="row m-0">
                <div className="col-sm-10 col-md-12 col-lg-12 text-left p-0">
                  <label className="check-container" style={{ top: "5px" }}>
                    <input type="checkbox" onClick={() => onUpdateActive()} />
                    <span className={"checkmark"} />
                  </label>
                  <span
                    className="px-4 text-description"
                    style={{ marginLeft: "16PX" }}
                  >
                    <FormattedMessage id={"user.deactivate"} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row m-0 new-row-block ">
          <div className="col-sm-10 col-md-12 col-lg-12 text-left pr-4">
            <div className="row m-0 float-right">
              <button
                type="submit"
                className={`green-button ${
                  !isEdit && checkForEdit() && "disabled none-edit "
                }`}
                onClick={() => submit()}
              >
                <FormattedMessage
                  id={`${
                    checkForEdit()
                      ? "createuser.titleedituser"
                      : "createuser.titlecreateuser"
                  }`}
                />
              </button>
            </div>
            {
              <div className="row m-0 float-right m-r-2r">
                <button
                  type="submit"
                  className="cancel-button"
                  onClick={() => goToUserManagement()}
                >
                  <FormattedMessage
                    id={`${
                      checkForEdit()
                        ? "createuser.titlecanceledit"
                        : "createuser.titlecanceluser"
                    }`}
                  />
                </button>
              </div>
            }
          </div>
        </div>
      </div>
    </Loading>
  )
}

export default enhancer(CreateUser)
