import React, { useState, useEffect } from "react"
import Calendar from "react-calendar"
import {
  format,
  startOfToday,
  endOfMonth,
  add,
  startOfYear,
  isToday,
  isWithinInterval,
  isAfter,
  getMonth,
  getDate,
  getYear,
} from "date-fns"
import classnames from "classnames"
import OutsideClickHandler from "react-outside-click-handler"
import previmgs from "assets/icon/ic_next.svg"

import Image from "shared/Image"

const DatePicker = (props) => {
  let {
    onChange,
    value: dates,
    type,
    disable,
    showCurrentYr,
    required = false,
    activeHeadButton,
    stateType,
    cb,
    additionalYears = 0,
  } = props
  let today = startOfToday()
  let pastValidDate = add(startOfYear(today), { years: -5 })
  const [open, setOpen] = useState(false)
  const [toMode, setToMode] = useState(false)
  const [fromMode, setFromMode] = useState(false)
  const [startDate, setStartDate] = useState(today)

  let cYear = new Date().getFullYear() + additionalYears
  let lastYears = [...Array(6).keys()].map((d) => cYear - d)
  if (showCurrentYr) {
    let years = cYear - getYear(new Date("12-01-2021"))

    lastYears = [...Array(years + 1).keys()].map((d) => cYear - d)
    pastValidDate = new Date("12-01-2021")
  }

  const [sYear, setSYear] = useState(cYear)

  const cMonth = getMonth(today)
  if (cMonth === 11) {
    const cDate = getDate(today)
    if (cDate > 24) lastYears.unshift(cYear + 1)
  }

  useEffect(() => {
    let selectedYr = dates[toMode ? "to" : "from"].getFullYear()
    setSYear(selectedYr)
  }, [toMode, fromMode])

  useEffect(() => {
    if (open) setStartDate(toMode ? dates.to : dates.from)
  }, [open, toMode])

  let updateYear = (e) => {
    startDate.setFullYear(e.target.value)
    setSYear(e.target.value)
    setStartDate(startDate)
    if (cb) {
      cb(e, startDate)
    }
  }

  let updateDate = (direction) => () => {
    let key = type == "date" ? "months" : "years"
    let opts = { [key]: 1 * direction }
    let n_date = add(startDate, opts)
    if (lastYears.includes(n_date.getFullYear())) {
      setStartDate(n_date)
      setSYear(n_date.getFullYear())
    }
  }

  let updateSelectedDate = (d) => {
    let newDates = { ...dates }
    if (!toMode) {
      newDates.from = d

      if (isAfter(d, dates.to)) {
        if (type == "date") newDates.to = d
        else newDates.to = endOfMonth(d)
      }
    } else {
      if (type == "date") newDates.to = d
      else newDates.to = endOfMonth(d)
    }

    onChange?.(newDates)

    if (toMode) {
      setTimeout(() => {
        setToMode(false)
        setOpen(false)
      }, 1000)
    } else {
      setStartDate(dates.to)
      setToMode(true)
    }
  }

  let openCal = (d) => () => {
    setFromMode(!fromMode)
    setToMode(d)
    setOpen(true)
  }

  let yearF = "yyyy"
  let date_format = {
    month_year: {
      l: `MMM ${yearF}`,
    },
    date: {
      sub: `MMM`,
      l: `MMM dd, ${yearF}`,
    },
  }

  const toCalendarType = (weekStartDay) =>
    weekStartDay === 0 ? "US" : "ISO 8601"

  let opts = { minDate: pastValidDate }
  if (disable == "past") opts.minDate = today
  if (disable == "future") opts.maxDate = today
  //to do for future reference
  // if (disable == "documentDateRange" && required) {
  // opts.minDate = new Date("12-01-2021")
  // opts.maxDate = today
  // }
  if (toMode) opts.minDate = dates.from

  let tileClassName = ({ date }) => {
    let cName = ""
    if (isToday(date)) cName = "today"
    try {
      if (isWithinInterval(date, { start: dates.from, end: dates.to }))
        cName += " selected"
    } catch (err) {
      // eslint-disable-next-line
      console.log(err)
    }
    return `cal-cel ${cName}`
  }
  let hasError = () => {
    if (activeHeadButton == "Document#" && stateType == "documentlibrary") {
      let toDate = format(new Date(dates.to), "yyyy-MM-dd")
      let fromDate = format(new Date(dates.from), "yyyy-MM-dd")
      const daysBetween = Math.floor(
        Math.abs(new Date(toDate) - new Date(fromDate)) / (1000 * 60 * 60 * 24)
      )
      // let year
      // year % 4 === 0 && year % 100 !== 0 || year % 400 === 0

      if (daysBetween > 365) {
        // let errorMsg =
        //   "Please contact your customer administrator for further assistance."
        // toast.warn(errorMsg, { autoClose: 3000 })
        return true
      }
    }
    return false
  }

  return (
    <div
      className={classnames(
        "d-inline-block fromDate",
        type == "date" ? "months" : "years",
        { active: open }
      )}
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpen(false)
          setToMode(false)
        }}
      >
        <div className={classnames("calendar", { "has-error": hasError() })}>
          <span onClick={openCal(false)}>
            <span className="from">
              {required && <span style={{ color: "red" }}>*</span>} From:{" "}
              {format(dates.from, date_format[type].l)}{" "}
              <Image
                className="iconCalendar"
                src="ic_calendar.svg"
                alt="Date icon"
                width="12px"
                height="12px"
              />
            </span>
            <span className="fromicon"> </span>
            <span
              className={classnames("drop-arrows", { up: open && !toMode })}
            ></span>
          </span>
          <span className="vertical">|</span>
          <span onClick={openCal(true)}>
            <span className="to">
              {required && <span style={{ color: "red" }}>*</span>} To:{" "}
              {format(dates.to, date_format[type].l)}{" "}
              <Image
                className="iconCalendar"
                src="ic_calendar.svg"
                alt="Date icon"
                width="12px"
                height="12px"
              />
            </span>
            <span className="toicon"></span>
            <span
              className={classnames("drop-arrows", { up: open && toMode })}
            ></span>
          </span>
          {
            <span className="filter-error">
              {" "}
              Date Range of only one year is allowed{" "}
            </span>
          }
        </div>
        {open && (
          <div
            className={classnames(
              "calendar-container",
              open && !toMode ? "from" : "to"
            )}
          >
            <span className="previmg">
              {" "}
              <img
                className=""
                src={previmgs}
                height="20px"
                width="20px"
                onClick={updateDate(-1)}
              />
            </span>
            <span className="ml-3 date">
              {date_format[type].sub &&
                format(startDate, date_format[type].sub)}{" "}
              <select
                value={sYear}
                onChange={updateYear}
                className={"border-0"}
              >
                {lastYears.map((d) => (
                  <option value={d} key={d}>
                    {d}
                  </option>
                ))}
              </select>
            </span>

            <span className="nextimg">
              <img
                className=""
                src={previmgs}
                height="20px"
                width="20px"
                onClick={updateDate(1)}
              />
            </span>
            <Calendar
              activeStartDate={startDate}
              calendarType={toCalendarType(0)}
              showNavigation={false}
              view={type == "date" ? "month" : "year"}
              tileClassName={tileClassName}
              onChange={updateSelectedDate}
              onClickMonth={updateSelectedDate}
              {...opts}
            />
          </div>
        )}
      </OutsideClickHandler>
    </div>
  )
}

export default DatePicker
