import _, { isNil } from "lodash"
import timePresetOptions from "./timePresets"
import * as dfns from "date-fns"
import store from "store"
import {
  BusinessSegmentId,
  AnnualScheduleReportServiceTypesByHomeDepotCustomer,
} from "config"
import { isRbsCorporateUser } from "shared"

export const ReportConfig = {
  general_status: {
    title: "Gen Status Report",
    type: "report",
    toggleButton: true,
    report: {
      apiKey: "p_genStatus",
      extra: {
        cellColor: (v) => {
          if (!_.isNumber(v)) return ""
          if (v >= 2200) return "#ff0000"
          if (v >= 220) return "#ffff00"
        },
      },
      columns: {
        emptyHeaders: [
          "Customer Name",
          "State",
          "Division",
          "Region",
          "EPA ID",
          "Brand Name",
          "Store",
          "City",
          "YYYY-MM",
          "YYYY-MM",
          "YYYY-MM",
          "YYYY-MM",
          "Grand Total",
        ],
        default: [
          {
            Header: "Customer Name",
            accessor: "customerName",
            sticky: "left",
          },
          {
            Header: "State",
            accessor: "state",
            sticky: "left",
          },
          {
            Header: "Division",
            accessor: "division",
          },
          {
            Header: "Region",
            accessor: "region",
          },
          {
            Header: "EPA ID",
            accessor: "epaId",
          },
          {
            Header: "Brand Name",
            accessor: "division",
          },
          {
            Header: "Store",
            accessor: "storeNumber",
          },
          {
            Header: "City",
            accessor: "city",
          },
          {
            Header: "Grand Total (lbs)",
            accessor: "totalWeight",
            unit: "number",
            sticky: "right",
          },
        ],
      },
    },
    filter: [
      {
        label: "Divisions | Regions",
        key: "divisions_regions",
        type: "grouped",
        toggletype: true,
        toggleButtonLabel: "Divisions, Regions",
      },
      {
        label: "Brands",
        key: "brands",
        type: "multiple",
        toggletype: true,
        toggleButtonLabel: "Brands",
      },
      {
        label: "States",
        key: "states",
        type: "multiple",
        toggletype: true,
        toggleButtonLabel: "States",
      },
      {
        label: "Stores",
        type: "multiple",
        key: "stores",
        toggletype: true,
        limitoptions: 100,
        toggleButtonLabel: "Stores",
        isrestricted: true,
        message: "You can choose up to 100 stores or all stores per query",
      },
      {
        label: "Predefined Queries",
        key: "time_presets_month",
        type: "single",
      },
      {
        label: "Fiscal Month/Year",
        key: "fiscal_month_year",
        type: "month_year",
        disable: "future",
      },
    ],
  },
  waste_summary_shipment_report: {
    title: "Waste Shipment Summary Report",
    type: "report",
    toggleButton: true,
    report: {
      apiKey: "p_wasteShipment",
      columns: {
        emptyHeaders: [
          "Customer Name",
          "Brand Name",
          "Location Number",
          // "Location Type",
          "Location Address",
          "Location Address 2",
          "Location City",
          "Location State",
          "Location Zip",
          "Location County",
          "EPA ID Number",
          "Order Number",
          "Service Date",
          "Shipment Date",
          "Document Number",
          "Document Type",
          "Document Line",
          "UoM Quantity",
          "UoM",
          "Container Type Quantity",
          "Container Type",
          "Profile Name",
          "Profile Number",
          "UN Number",
          "Proper Shipping Name",
          "DOT Hazard Class",
          "Packing Group",
          "EPA Waste Codes",
          "State Waste Code",
          "Source Code",
          "Form Code",
          "Transporter 1",
          "Transporter 1 EPA ID",
          "Transporter 2",
          "Transporter 2 EPA ID",
          "Receiving Facility Name",
          "Receiving Facility EPA ID",
          "Receiving Facility Signed Date",
          "Management Code",
          "Final Management Code",
        ],
        default: [
          {
            Header: "Customer Name",
            accessor: "customerName",
            sticky: "left",
          },
          {
            Header: "Brand Name",
            accessor: "division",
            sticky: "left",
          },
          {
            Header: "Location Number",
            accessor: "locationNumber",
            sticky: "left",
          },
          // {
          //   Header: "Location Type",
          //   accessor: "locationType",
          // },
          {
            Header: "Location Address",
            accessor: "locationAddress",
          },
          {
            Header: "Location Address 2",
            accessor: "locationAdrress2",
          },
          {
            Header: "Location City",
            accessor: "locationCity",
          },
          {
            Header: "Location State",
            accessor: "locationState",
          },
          {
            Header: "Location Zip",
            accessor: "locationZip",
          },
          {
            Header: "Location County",
            accessor: "locationCounty",
          },
          {
            Header: "EPA ID Number",
            accessor: "epaidNumber",
          },
          {
            Header: "Order Number",
            accessor: "orderNumber",
          },
          {
            Header: "Service Date",
            accessor: "serviceDateTime",
          },
          {
            Header: "Shipment Date",
            accessor: "shipmentDate",
          },
          {
            Header: "Document Number",
            accessor: "documentNumber",
          },
          {
            Header: "Document Type",
            accessor: "documentType",
          },
          {
            Header: "Document Line",
            accessor: "documentLine",
          },
          {
            Header: "UoM Quantity",
            accessor: "uoMQuantity",
          },
          {
            Header: "UoM",
            accessor: "uoM",
          },
          {
            Header: "Container Type Quantity",
            accessor: "containerTypeQuantity",
          },
          {
            Header: "Container Type",
            accessor: "containerType",
          },
          {
            Header: "Profile Name",
            accessor: "profileName",
          },
          {
            Header: "Profile Number",
            accessor: "profileNumber",
          },
          {
            Header: "UN Number",
            accessor: "unNumber",
          },
          {
            Header: "Proper Shipping Name",
            accessor: "properShippingName",
          },
          {
            Header: "DOT Hazard Class",
            accessor: "dotHazardClass",
          },
          {
            Header: "Packing Group",
            accessor: "packagingGroup",
          },
          {
            Header: "EPA Waste Codes",
            accessor: "epaWasteCodes",
          },
          {
            Header: "State Waste Code",
            accessor: "stateWasteCodes",
          },
          {
            Header: "Source Code",
            accessor: "sourceCode",
          },
          {
            Header: "Form Code",
            accessor: "fromCode",
          },
          {
            Header: "Transporter 1",
            accessor: "transporterName1",
          },
          {
            Header: "Transporter 1 EPA ID",
            accessor: "transporter1EPAID",
          },
          {
            Header: "Transporter 2",
            accessor: "transporterName2",
          },
          {
            Header: "Transporter 2 EPA ID",
            accessor: "transporter2EPAID",
          },
          {
            Header: "Receiving Facility Name",
            accessor: "receivingFacilityName",
          },
          {
            Header: "Receiving Facility EPA ID",
            accessor: "receivingFacilityEpaId",
          },
          {
            Header: "Receiving Facility Signed Date",
            accessor: "receivingFacilitySignedDate",
          },
          {
            Header: "Management Code",
            accessor: "managementCode",
          },
          {
            Header: "Final Management Code",
            accessor: "finalManagementCode",
          },
        ],
      },
    },
    filter: [
      {
        label: "Divisions | Regions",
        key: "divisions_regions",
        type: "grouped",
        toggletype: true,
        toggleButtonLabel: "Divisions, Regions",
      },
      {
        label: "Brands",
        key: "brands",
        type: "multiple",
        toggletype: true,
        toggleButtonLabel: "Brands",
      },
      {
        label: "States",
        key: "states",
        type: "multiple",
        toggletype: true,
        toggleButtonLabel: "States",
      },
      {
        label: "Stores",
        type: "multiple",
        key: "stores",
        toggletype: true,
        limitoptions: 100,
        toggleButtonLabel: "Stores",
        isrestricted: true,
        message: "You can choose up to 100 stores or all stores per query",
      },
      {
        label: "Profile Names",
        key: "profile_list",
        type: "multiple",
        size: "double",
        isrestricted: true,
        limitoptions: 10,
        showAll: false,
        message:
          "Profile names with available shipment data only (Allowed to select up to 10 profiles)",
      },
      {
        label: "Predefined Queries",
        key: "time_presets_quarter",
        type: "single",
      },
      {
        label: "Fiscal Month/Year",
        key: "fiscal_month_year",
        type: "date",
      },
    ],
  },
  exception_report: {
    title: "Exception Report",
    type: "report",
    toggleButton: true,
    report: {
      apiKey: "p_exceptionReport",
      columns: {
        emptyHeaders: [
          "Customer Name",
          "Location Name",
          "Division",
          "Region",
          "Location Number",
          "City",
          "State",
          "Service Date",
          "Service No.",
          "Exceptions",
        ],
        default: [
          {
            Header: "Customer Name",
            accessor: "customerName",
          },
          {
            Header: "Location Name",
            accessor: "locationName",
          },
          {
            Header: "Division",
            accessor: "division",
          },
          {
            Header: "Brand Name",
            accessor: "division",
          },
          {
            Header: "Region",
            accessor: "region",
          },
          {
            Header: "Location Number",
            accessor: "locationNumber",
          },
          {
            Header: "City",
            accessor: "city",
          },
          {
            Header: "State",
            accessor: "state",
          },
          {
            Header: "Service Date",
            accessor: "serviceDate",
          },
          {
            Header: "Service No.",
            accessor: "serviceNo",
          },
          {
            Header: "Exceptions",
            accessor: "exceptions",
            width: 200,
            unit: "array",
          },
        ],
      },
    },
    filter: [
      {
        label: "Divisions | Regions",
        key: "divisions_regions",
        type: "grouped",
        toggleButtonLabel: "Divisions, Regions",
        toggletype: true,
      },
      {
        label: "Brands",
        key: "brands",
        type: "multiple",
        toggletype: true,
        toggleButtonLabel: "Brands",
      },
      {
        label: "States",
        key: "states",
        type: "multiple",
        toggletype: true,
        toggleButtonLabel: "States",
      },
      {
        label: "Stores",
        type: "multiple",
        key: "stores",
        toggletype: true,
        limitoptions: 100,
        toggleButtonLabel: "Stores",
        isrestricted: true,
        message: "You can choose up to 100 stores or all stores per query",
      },

      {
        label: "Exception Types",
        key: "exception_type",
        type: "multiple",
        size: "double",
      },
      {
        label: "Predefined Queries",
        key: "time_presets_quarter",
        type: "single",
      },
      {
        label: "Fiscal Month/Year",
        key: "fiscal_month_year",
        type: "date",
        disable: "future",
      },
    ],
  },
  annual_schedule_report: {
    title: "Annual Schedule Report",
    type: "report",
    toggleButton: true,
    report: {
      apiKey: "p_annualServiceSchedule",
      columns: {
        emptyHeaders: [
          "Service Type",
          "Indoor Sump Service",
          "Frequency",
          "Location Number",
          "Location Name",
          "EPA ID Number",
          "Schedule Week",
          //"Generator",
        ],
        default: [
          {
            Header: "Service Type",
            accessor: "serviceType",
          },
          {
            Header: "Indoor Sump Service",
            accessor: "is_Sump",
          },
          {
            Header: "Frequency",
            accessor: "frequencyInWeeks",
          },
          {
            Header: "Location Number",
            accessor: "locationNumber",
          },
          {
            Header: "Location Name",
            accessor: "locationName",
          },
          {
            Header: "EPA ID Number",
            accessor: "epaiD_Number",
          },
          {
            Header: "Schedule Week",
            accessor: "schedule_week",
          },
          // {
          //   Header: "Generator",
          //   accessor: "genrator",
          // },
        ],
      },
    },
    filter: [
      {
        label: "Divisions | Regions",
        key: "divisions_regions",
        type: "grouped",
        toggleButtonLabel: "Divisions, Regions",
        toggletype: true,
      },
      {
        label: "States",
        key: "states",
        type: "multiple",
        toggletype: true,
        toggleButtonLabel: "States",
      },
      {
        label: "Stores",
        type: "multiple",
        key: "stores",
        toggletype: true,
        limitoptions: 100,
        toggleButtonLabel: "Stores",
        isrestricted: true,
        message: "You can choose up to 100 stores or all stores per query",
      },
      {
        label: "Service Types",
        type: "multiple",
        key: "service_type",
        required: true,
      },
      {
        label: "Year",
        key: "time_present_future_year",
        type: "single",
      },
    ],
  },
  exception_report_summary: {
    title: "Exception Report",
    type: "report",
    disabled: true,
    report: {
      apiKey: "p_exceptionReportSummary",
      columns: {
        emptyHeaders: ["Name", "Exception Count"],
        default: [
          {
            Header: "Customer Name",
            accessor: "customerName",
          },
          {
            Header: "Name",
            accessor: "exceptionName",
          },
          {
            Header: "Exception Count",
            accessor: "exceptionCount",
          },
        ],
      },
    },
    filter: [
      {
        label: "Divisions | Regions",
        key: "divisions_regions",
        type: "grouped",
      },
      {
        label: "States",
        key: "states",
        type: "multiple",
      },
      {
        label: "Stores",
        type: "multiple",
        key: "stores",
      },

      {
        label: "Exception Types",
        key: "exception_type",
        type: "multiple",
        size: "double",
      },
      {
        label: "Predefined Queries",
        key: "time_presets_quarter",
        type: "single",
      },
      {
        label: "Fiscal Month/Year",
        key: "fiscal_month_year",
        type: "date",
        disable: "future",
      },
    ],
  },
  pt_wood_weekly: {
    title: "PT Wood Weekly ",
    type: "report",
    disabled: true,
    report: { chart: "ontime" },
    filter: [
      {
        label: "PSC Location",
        key: "psc_location",
        options: ["All Sites", "Inglewood", "Rancho Cordova", "Santa Clara"],
        type: "single",
        required: true,
      },
    ],
  },
  invoice_report: {
    title: "Invoice Report",
    type: "report",
    isFullCircle: true,
    report: {
      apiKey: "p_invoiceReport",
      columns: {
        emptyHeaders: [
          "Customer Order Number",
          "Date Required",
          "PO Number",
          "Generator Company",
          "Generator City",
          "Generator Address",
          "Description",
          "Order Quantity",
          "Unit Price",
          "Extended Price",
          "Price UOM",
        ],
        default: [
          {
            Header: "Customer Order Number",
            accessor: "orderNumber",
          },
          {
            Header: "Date",
            accessor: "shippedDate",
          },
          {
            Header: "PO Number",
            accessor: "poNumber",
          },
          {
            Header: "Generator Company",
            accessor: "company",
          },
          {
            Header: "Generator City",
            accessor: "city",
          },
          {
            Header: "Generator Address",
            accessor: "address",
          },
          {
            Header: "Description",
            accessor: "description",
          },
          {
            Header: "Order Quantity",
            accessor: "orderQuantity",
          },
          {
            Header: "Unit Price",
            accessor: "unitePrice",
          },
          {
            Header: "Extended Price",
            accessor: "extendedPrice",
          },
          {
            Header: "Price UoM Code",
            accessor: "uomCode",
          },
        ],
      },
    },
    filter: [
      {
        label: "Generator City",
        type: "multiple",
        key: "cities",
      },
      {
        label: "Predefined Queries",
        key: "time_presets_lastquateryear",
        type: "single",
      },
      {
        type: "date",
        key: "fiscal_span",
      },
    ],
  },
  profile_report: {
    title: "Profile Report",
    type: "report",
    isFullCircle: true,
    report: {
      apiKey: "p_profileReport",
      columns: {
        emptyHeaders: [
          "Customer Order Number",
          "PO Number",
          "Generator Company",
          "Generator City",
          "Generator Address",
          "Generator State Province Code",
          "Description",
          "Order Quantity",
          "Price UOM Code",
        ],
        default: [
          {
            Header: "Customer Order Number",
            accessor: "orderNumber",
          },
          {
            Header: "PO Number",
            accessor: "poNumber",
          },
          {
            Header: "Generator Company",
            accessor: "company",
          },
          {
            Header: "Generator City",
            accessor: "city",
          },
          {
            Header: "Generator Address",
            accessor: "address",
          },
          {
            Header: "Generator State Province Code",
            accessor: "state",
          },
          {
            Header: "Description",
            accessor: "description",
          },
          {
            Header: "Order Quantity",
            accessor: "orderQuantity",
          },
          {
            Header: "Price UOM Code",
            accessor: "priceUOM",
          },
        ],
      },
    },
    filter: [
      {
        label: "Generator City",
        type: "multiple",
        key: "cities",
      },
      {
        label: "Predefined Queries",
        key: "time_presets_lastquateryear",
        type: "single",
      },
      {
        key: "fiscal_span",
        type: "date",
        disable: "future",
      },
    ],
  },
  myschedule: {
    title: "",
    type: "schedule",
    toggleButton: true,
    filter: [
      {
        label: "Divisions | Regions",
        key: "divisions_regions",
        type: "grouped",
        toggletype: true,
        toggleButtonLabel: "Divisions, Regions",
      },
      {
        label: "Brands",
        key: "brands",
        type: "multiple",
        toggletype: true,
        toggleButtonLabel: "Brands",
      },
      {
        label: "States",
        type: "multiple",
        key: "states",
        toggletype: true,
        toggleButtonLabel: "States",
      },
      {
        label: "Stores",
        type: "multiple",
        key: "stores",
        toggletype: true,
        limitoptions: 100,
        toggleButtonLabel: "Stores",
        isrestricted: true,
        message: "You can choose up to 100 stores or all stores per query",
      },
      {
        label: "Service Types",
        type: "multiple",
        key: "service_type",
        required: true,
      },
      {
        label: "Schedule Types",
        type: "multiple",
        key: "schedule_type",
        required: true,
      },
      {
        label: "Predefined Queries",
        key: "time_presets_quarter",
        type: "single",
      },
      {
        type: "date",
        key: "fiscal_span",
      },
    ],
  },
  myhistory: {
    title: "",
    type: "myhistory",
    filter: [
      {
        label: "Generator State",
        type: "multiple",
        key: "states",
      },
      {
        label: "Generator City",
        type: "multiple",
        key: "cities",
      },
      {
        label: "Generator Name",
        type: "multiple",
        key: "customers",
      },
      {
        label: "Predefined Queries",
        key: "time_presets_lastquateryear",
        type: "single",
      },
      {
        type: "date",
        key: "fiscal_span",
      },
    ],
  },
  myprofile: {
    title: "",
    type: "myprofile",
    filter: [
      {
        label: "States",
        type: "multiple",
        key: "states",
      },
      {
        label: "Predefined Queries",
        key: "time_presets_quarter",
        type: "single",
      },
      {
        label: "",
        type: "date",
        key: "dates",
        disable: "future",
      },
    ],
  },
  invoices: {
    title: "",
    type: "invoices",
    filter: [
      {
        label: "Generator State",
        type: "multiple",
        key: "states",
      },
      {
        label: "Generator City",
        type: "multiple",
        key: "cities",
      },
      {
        label: "Generator Name",
        type: "multiple",
        key: "customers",
      },
      {
        label: "Predefined Queries",
        key: "time_presets_lastquateryear",
        type: "single",
      },
      {
        label: "",
        type: "date",
        key: "dates",
        disable: "future",
      },
    ],
  },
  documentlibrary: {
    title: "",
    type: "documentlibrary",
    toggleButton: true,
    filter: [
      {
        label: "Document Types",
        options: [
          "Manifests",
          "BOLs",
          "Job Sheets",
          "Profiles",
          "Certificates of Disposal",
          "Approval Letters",
        ],
        type: "multiple",
        key: "docTypes",
        size: "double",
        required: true,
        isFullCircle: false,
      },
      {
        label: "Document Types",
        options: [
          "Manifests",
          "BOLs",
          "Job Sheets",
          "Profiles",
          "Certificates of Disposal",
          "Approval Letters",
        ],
        type: "single",
        key: "docTypes",
        size: "double",
        required: true,
        isFullCircle: false,
        isDocumentOption: true,
      },
      {
        label: "Document No",
        type: "text",
        key: "docNumber",
        isDocumentOption: true,
        isFullCircle: false,
        required: true,
        isCharRestricted: 12,
        minCharRequired: 4,
      },
      {
        label: "Brands",
        type: "multiple",
        key: "brands",
        toggletype: true,
        toggleButtonLabel: "Brands",
        isFullCircle: false,
      },
      {
        label: "States",
        type: "multiple",
        key: "states",
        toggletype: true,
        toggleButtonLabel: "States",
        isFullCircle: false,
      },
      {
        label: "Stores",
        type: "multiple",
        key: "stores",
        toggletype: true,
        limitoptions: 100,
        toggleButtonLabel: "Stores",
        isFullCircle: false,
        isrestricted: true,
        message: "You can choose up to 100 stores or all stores per query",
      },
      {
        label: "Predefined Queries",
        key: "time_presets_quarter",
        type: "single",
        isFullCircle: false,
        headLabel: "Filters",
      },
      {
        label: "Predefined Queries",
        key: "time_presets_year",
        type: "single",
        isFullCircle: false,
        isDocumentOption: true,
        headLabel: "Document#",
      },
      {
        label: "",
        type: "date",
        key: "dates",
        disable: "future",
        isFullCircle: false,
        isDocumentOption: true,
      },
      {
        label: "Generator Name",
        key: "customers",
        type: "multiple",
        isFullCircle: true,
      },
      {
        label: "Filter",
        // type: "multiple",
        // key: "states",
        toggletype: true,
        toggleButtonHeadLabel: "Filters",
        isFullCircle: false,
        isToggleHeadSelected: false,
      },
      {
        label: "Document",
        // type: "multiple",
        // key: "stores",
        toggletype: true,
        toggleButtonHeadLabel: "Document#",
        isFullCircle: false,
        isToggleHeadSelected: false,
      },
    ],
  },
  documentlibrarystores: {
    title: "",
    type: "documentlibrarystores",
    filter: [
      {
        label: "Document Types",
        options: [
          "Manifests",
          "BOLs",
          // "Weight Tickets",
          "Job Sheets",
          "Profiles",
          "Certificates of Disposal",
          "Approval Letters",
        ],
        type: "multiple",
        key: "docTypes",
        size: "double",
        required: true,
      },
      {
        label: "Predefined Queries",
        key: "time_presets_quarter",
        type: "single",
      },
      {
        label: "",
        type: "date",
        key: "dates",
        disable: "future",
      },
    ],
  },
  historicalldrs: {
    title: "",
    type: "historicalldrs",
    filter: [
      {
        label: "Stores",
        type: "single",
        key: "stores",
        hasSearch: true,
      },
    ],
  },
  mystoreschedule: {
    title: "",
    type: "schedule",
    filter: [
      {
        label: "Predefined Queries",
        key: "time_presets_quarter",
        type: "single",
      },
      {
        type: "date",
        key: "fiscal_span",
      },
    ],
  },
  requestserviceHistory: {
    title: "",
    type: "requestserviceHistory",
    filter: [
      {
        label: "Type of request",
        options: [
          "Report a Service Issue",
          "Change Request (Service Details)",
          "New Schedule Request",
          "Request Supplies",
        ],
        type: "multiple",
        key: "requestType",
        size: "double",
        required: true,
      },
      {
        label: "Status",
        options: ["Open", "Closed"],
        type: "multiple",
        key: "reqStatus",
      },
      {
        label: "Predefined Queries",
        key: "time_presets_quarter",
        type: "single",
      },
      {
        label: "",
        type: "date",
        key: "dates",
        disable: "future",
        showCurrentYr: true,
      },
    ],
  },
  requestserviceView: {
    title: "",
    type: "requestserviceHistory",
    filter: [
      {
        label: "Type of request",
        options: [
          "Report a Service Issue",
          "Change Request (Service Details)",
          "New Schedule Request",
          "Request Supplies",
        ],
        type: "multiple",
        key: "requestType",
        size: "double",
        required: true,
      },
      {
        label: "Status",
        options: ["Open", "Closed"],
        type: "multiple",
        key: "reqStatus",
      },
      {
        label: "Predefined Queries",
        key: "time_presets_quarter",
        type: "single",
      },
      {
        label: "",
        type: "date",
        key: "dates",
        disable: "future",
        showCurrentYr: true,
      },
    ],
  },
}

const checkCustomerCategory = (changeCustomer) => {
  if (changeCustomer) {
    return (
      changeCustomer?.customer?.businessSegmentId ===
      BusinessSegmentId.FULL_CIRCLE
    )
  }
}

export const getCurrentConfig = (type) => {
  const { config, stores, isHomeDepot, storeId, generators, profile } =
    getReduxStates()

  const { changeCustomer } = store.getState()

  let divRegs1 = _.groupBy(stores, "division")
  let divRegs = {}

  let {
    scheduleTypes = [],
    serviceTypes = [],
    exceptionType = [],
    docTypes = [],
    profileList = [],
  } = config

  if (isHomeDepot) {
    Object.keys(divRegs1)
      .sort()
      .forEach((k) => {
        if (k) {
          divRegs[k] = [...new Set(divRegs1[k].map((d) => d.region))].sort()
        }
      })
  }

  let storePairs = {}
  // const gen = {ALL_GENERATORS}
  stores.forEach((d) => {
    if (!storePairs[d.storeNumber])
      storePairs[d.storeNumber] = { states: [], divisions_regions: [] }
    let store = storePairs[d.storeNumber]
    if (!store.states.includes(d.state)) store.states.push(d.state)

    if (isHomeDepot && !store.divisions_regions.includes(d.region))
      store.divisions_regions.push(d.region)
  })
  let customerPairs = {}
  generators.forEach((d) => {
    if (!customerPairs[d.name])
      customerPairs[d.name] = { states: [], cities: [] }
    let store = customerPairs[d.name]
    if (!store.states.includes(d.state)) store.states.push(d.state)
    if (!store.cities.includes(d.city)) store.cities.push(d.city)
  })

  let nConfig = _.cloneDeep(ReportConfig[type]?.filter)
  let today = new Date()

  if (!isHomeDepot)
    nConfig = nConfig?.filter((d) => d.key !== "divisions_regions")
  if (!isRbsCorporateUser()) {
    nConfig = nConfig?.filter((d) => d.key !== "brands")
  }
  if (storeId)
    nConfig = nConfig?.filter(
      (d) =>
        d.key !== "divisions_regions" &&
        d.key !== "states" &&
        d.key !== "stores"
    )

  nConfig?.forEach((d) => {
    if (d.key == "schedule_type") d.options = scheduleTypes
    if (d.key == "service_type") d.options = serviceTypes
    if (d.key == "service_type" && type == "annual_schedule_report")
      d.options = serviceTypes.filter((serviceType) =>
        AnnualScheduleReportServiceTypesByHomeDepotCustomer.ServiceTypes.includes(
          serviceType
        )
      )
    if (d.key == "exception_type") d.options = exceptionType
    if (d.key === "profile_list") d.options = profileList

    if (d.key === "states" && checkCustomerCategory(changeCustomer, profile)) {
      let stateData = generators
        .filter((d) => d.state)
        .map((d) => d.state?.toUpperCase())
        .sort()
      d.options = _.uniq(stateData)
    }
    if (d.key == "cities" && checkCustomerCategory(changeCustomer, profile)) {
      let cityData = generators
        .filter((d) => d.city)
        .map((d) => d.city)
        .sort()
      d.options = _.uniq(cityData)
    }
    if (
      d.key == "customers" &&
      checkCustomerCategory(changeCustomer, profile)
    ) {
      let custData = generators
        .filter((d) => d.name)
        .map((d) => d.name)
        .sort()
      d.options = _.uniq(custData)
    }

    if (
      d.key == "states" &&
      changeCustomer?.customer?.businessSegmentId !==
        BusinessSegmentId.FULL_CIRCLE
    ) {
      let stateData = stores
        .filter((d) => d.state)
        .map((d) => d.state?.toUpperCase())
        .sort()
      d.options = _.uniq(stateData)
    }
    if (d.key == "stores") {
      d.options = stores
        .sort((a, b) =>
          a.storeNumber?.localeCompare(b.storeNumber, "en", { numeric: true })
        )
        .map((d) => d.storeNumber)
      d.options = _.uniq(d.options)
    }
    if (d.key == "brands") {
      let devisionData = generators
        .filter((d) => d.division)
        .map((d) => d.division?.toUpperCase())
        .sort()
      d.options = _.uniq(devisionData)
    }
    if (d.type == "single" || d.type == "multiple") {
      if (d.key == "time_presets" && type == "general_status")
        d.options = timePresetOptions
      else if (d.key == "time_presets") d.options = timePresetOptions
      else if (d.key == "time_presets_month") {
        d.options = timePresetOptions.filter((d) => !d.v.skipMonth)
        d.options = d.options.filter((d) => !d.v.skipyear)
      } else if (d.key == "time_presets_quarter") {
        d.options = timePresetOptions.filter((d) => !d.v.skipyear)
      } else if (d.key === "time_presets_lastquateryear")
        d.options = timePresetOptions.filter((d) => !d.v.skippresent)
      else if (d.key == "time_presets_year") {
        // to do need to make the code generic
        let cYear = new Date().getUTCFullYear()
        let lastYears = [...Array(6).keys()].map((d) => {
          let from = dfns.parseISO(cYear - d + "-01-01")
          let to =
            cYear - d == new Date().getUTCFullYear()
              ? dfns.startOfToday()
              : dfns.parseISO(cYear - d + "-12-31")

          return {
            l: (cYear - d).toString(),
            v: {
              from: from,
              // skipMonth: true,
              // skippresent: true,
              // skipyear: true,
              to: to,
            },
          }
        })
        d.options = [
          ...lastYears,
          {
            l: "Custom",
            v: {
              from: dfns.parseISO(dfns.format(new Date(), "yyyy-LL-dd")),
              to: dfns.parseISO(dfns.format(new Date(), "yyyy-LL-dd")),
            },
          },
        ]
      } else if (d.key == "time_present_future_year") {
        const currentYear = new Date().getUTCFullYear()

        // // Calculate the next year by adding 1 to the current year
        const nextYear = currentYear + 1
        const yearsArray = [
          { l: currentYear.toString(), v: currentYear.toString() },
          { l: nextYear.toString(), v: nextYear.toString() },
        ]
        d.options = yearsArray
      } else if (d.key == "docTypes") {
        let options = docTypes.map((d, i) => ({
          l: d.label,
          v: d.id,
          index: i,
        }))
        if (d.type != "single") d.options = options
        else {
          let newArr = [
            // { l: "None", v: "None", index: 0 },
            ...options,
          ]
          d.options = [...newArr]
        }
      } else
        d.options = d?.options?.map((d1, i) => {
          let obj = { l: d1, v: d1, index: i }
          if (d.key == "stores") {
            obj.storePair = storePairs[d1]
          }

          return obj
        })
    }
    if (d.type == "grouped") {
      d.value = []
      if (d.key == "divisions_regions") {
        // eslint-disable-next-line
        for (const [k, v] of Object.entries(divRegs)) {
          divRegs[k] = v.filter((j) => j != "")
          if (_.isEmpty(divRegs[k])) delete divRegs[k]
        }
        delete divRegs["null"]
        d.options = divRegs
      }
    }

    if (d.type == "date") {
      d.value = { from: today, to: today }
    }
    if (d.type == "month_year") {
      d.value = { from: dfns.startOfMonth(today), to: dfns.endOfMonth(today) }
    }
  })

  return nConfig
}

export const getCurrentTitle = (type) => {
  let nConfig = ReportConfig[type]?.title
  return nConfig
}

export const getTableConfig = (type) => {
  let nConfig = _.cloneDeep(ReportConfig[type]?.report)
  const { isHomeDepot } = getReduxStates()
  const isRbsCorprateuser = isRbsCorporateUser()

  if (!isHomeDepot && ["general_status", "exception_report"].includes(type)) {
    nConfig.columns.emptyHeaders = nConfig?.columns.emptyHeaders.filter(
      (d) => d != "Division" && d != "Region"
    )
    nConfig.columns.default = nConfig?.columns?.default?.filter(
      (d) => d.Header != "Division" && d.Header != "Region"
    )
  }

  if (
    !isRbsCorprateuser &&
    [
      "general_status",
      "exception_report",
      "waste_summary_shipment_report",
    ].includes(type)
  ) {
    nConfig.columns.emptyHeaders = nConfig?.columns.emptyHeaders.filter(
      (d) => d != "Brand Name"
    )
    nConfig.columns.default = nConfig?.columns?.default?.filter(
      (d) => d.Header != "Brand Name"
    )
  }
  return nConfig
}

export const getReduxStates = () => {
  let {
    config,
    stores,
    profile: { defaultGenId, isHomeDepotUser },
    generators,
    profile,
    changeCustomer: { customer },
    ALL_GENERATORS,
  } = store.getState()
  let isHomeDepot = isHomeDepotUser

  if (customer) {
    isHomeDepot = customer?.v?.indexOf("35922") !== -1
  }

  return {
    config,
    stores,
    isHomeDepot,
    storeId: defaultGenId,
    generators,
    profile,
    ALL_GENERATORS,
  }
}

export const hasToggleButton = (type) => {
  if (ReportConfig[type]?.hasOwnProperty("toggleButton")) return true
  else if (ReportConfig[type]?.hasOwnProperty("toggleHeadButton")) return true
  else return false
}

export const getToggleButtons = (type, action) => {
  const { isHomeDepot } = getReduxStates()
  let nConfig = _.cloneDeep(ReportConfig[type]?.filter)
  let button = nConfig?.filter((d) => d.toggletype)
  let buttons = button?.map((ele) => {
    return action == "Head" ? ele.toggleButtonHeadLabel : ele.toggleButtonLabel
  })
  buttons = buttons?.filter((u) => !isNil(u))

  if (!isHomeDepot) {
    buttons = buttons?.filter((d) => d !== "Divisions, Regions")
  }
  var RbsCorporateUser = isRbsCorporateUser()
  if (!RbsCorporateUser) {
    buttons = buttons?.filter((d) => d !== "Brands")
  }

  return buttons
}
