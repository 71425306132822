import React from "react"
import Image from "shared/HelpImage/index"

function ReportGenerator() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Report Generator </h3>

      <p className="text-justify pl-3 pt-2">
        The report Generator “tab” will take you to two report options, i.e.,
        Invoice Report and Profile Report.
      </p>
      <Image src="ReportGenerator_Fc.png" />
      <p className="text-justify pl-3 pt-2">
        <b>The Invoice Report – </b>The invoice report gives a consolidated view
        of the way the material was managed. This report consists of only the
        contract items of an order, unit, quantity and the total price. The
        report is downloadable.
      </p>
      <p className="text-justify pl-3 pt-2">
        <b>The Profile Report – </b> The profile report gives a consolidated
        view of the profiles. The report is downloadable.
      </p>
    </div>
  )
}
export default ReportGenerator
