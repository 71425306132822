import {
  GET_PRINT_STATUS,
  GET_PRINT_LABEL,
} from "../../store/reducer/actionType"
import api from "api"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import { useEffect, useState } from "react"
import { PDFDocument, rgb, StandardFonts } from "pdf-lib"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    const dispatch = useDispatch()

    const changeCustomer = useSelector((state) => state.changeCustomer)
    const [pdfData, setPdfData] = useState(null)

    const createPdf = async (jsonData) => {
      const pdfDoc = await PDFDocument.create()
      const pageSize = [595.28, 841.89]
      const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
      const timesRomanBoldFont = await pdfDoc.embedFont(
        StandardFonts.TimesRomanBold
      )
      const fontSize = 12
      const blueColor = rgb(0, 0, 1)

      let maxContentPerPage = jsonData.lables
      let labelIndex = 1

      const drawContent = (page, yOffset, data) => {
        const textWidth = timesRomanBoldFont.widthOfTextAtSize(
          data.wasteType,
          24
        )
        const pageWidth = page.getWidth()
        const xPosition = (pageWidth - textWidth) / 2

        page.drawText(`${data.wasteType}`, {
          x: xPosition,
          y: yOffset,
          size: 24,
          font: timesRomanBoldFont,
          color: blueColor,
        })

        page.drawLine({
          start: { x: 50, y: yOffset - 25 },
          end: { x: 545, y: yOffset - 25 },
          thickness: 1,
          color: rgb(0, 0, 0),
        })

        page.drawText("Regulated by EPA or a State/Local Agency", {
          x: 190,
          y: yOffset - 40,
          size: fontSize,
          font: timesRomanBoldFont,
        })

        page.drawText(
          "IF FOUND, CONTACT THE NEAREST POLICE or PUBLIC SAFETY AUTHORITY",
          {
            x: 85,
            y: yOffset - 60,
            size: fontSize,
            font: timesRomanFont,
          }
        )

        page.drawText("AND THE WASHINGTON STATE DEPARTMENT OF ECOLOGY", {
          x: 130,
          y: yOffset - 75,
          size: fontSize,
          font: timesRomanFont,
        })

        page.drawText(`Generator - EPA ID: ${data.epaId}`, {
          x: 50,
          y: yOffset - 110,
          size: fontSize,
          font: timesRomanBoldFont,
        })

        page.drawText(`${data.address}`, {
          x: 50,
          y: yOffset - 130,
          size: fontSize,
          font: timesRomanFont,
          lineHeight: 15,
        })

        page.drawText(`Phone:`, {
          x: 400,
          y: yOffset - 110,
          size: fontSize,
          font: timesRomanBoldFont,
        })

        const phoneWidth = timesRomanBoldFont.widthOfTextAtSize(
          "Phone:",
          fontSize
        )
        page.drawText(`${data.phone}`, {
          x: 400 + phoneWidth + 5,
          y: yOffset - 110,
          size: fontSize,
          font: timesRomanFont,
        })

        page.drawText("Waste Codes", {
          x: 400,
          y: yOffset - 135,
          size: fontSize,
          font: timesRomanBoldFont,
          lineHeight: 15,
        })
        page.drawText(`${data?.epaCodes === null ? "N/A" : data?.epaCodes}`, {
          x: 403,
          y: yOffset - 150,
          size: fontSize,
          font: timesRomanFont,
          lineHeight: 15,
        })

        page.drawText("Proper D.O.T. Shipping Name", {
          x: 50,
          y: yOffset - 170,
          size: fontSize,
          font: timesRomanBoldFont,
        })

        page.drawText(`${data.dotShippingName}`, {
          x: 50,
          y: yOffset - 190,
          size: fontSize,
          font: timesRomanBoldFont,
          lineHeight: 15,
        })

        page.drawText("Profile # ", {
          x: 50,
          y: yOffset - 255,
          size: fontSize,
          font: timesRomanBoldFont,
          lineHeight: 15,
        })

        const profileWidth = timesRomanBoldFont.widthOfTextAtSize(
          "Profile #",
          fontSize
        )
        page.drawText(`${data.profileId}`, {
          x: 50 + profileWidth + 5,
          y: yOffset - 255,
          size: fontSize,
          font: timesRomanFont,
        })

        page.drawText(`LABPACK INCINERATION`, {
          x: 50,
          y: yOffset - 270,
          size: fontSize,
          font: timesRomanFont,
        })

        page.drawText(`Waste Ctg: `, {
          x: 50,
          y: yOffset - 300,
          size: fontSize,
          font: timesRomanBoldFont,
        })

        const wasteCtgWidth = timesRomanBoldFont.widthOfTextAtSize(
          "Waste Ctg:",
          fontSize
        )
        page.drawText(`${data.wasteCtg}`, {
          x: 50 + wasteCtgWidth + 5,
          y: yOffset - 300,
          size: fontSize,
          font: timesRomanFont,
        })

        page.drawText(`Date: `, {
          x: 300,
          y: yOffset - 300,
          size: fontSize,
          font: timesRomanBoldFont,
        })

        const dateWidth = timesRomanBoldFont.widthOfTextAtSize(
          "Date:",
          fontSize
        )
        page.drawText(`${data.accumulationDate}`, {
          x: 300 + dateWidth + 5,
          y: yOffset - 300,
          size: fontSize,
          font: timesRomanFont,
        })

        page.drawText(`Label `, {
          x: 450,
          y: yOffset - 300,
          size: fontSize,
          font: timesRomanBoldFont,
        })

        const labelWidth = timesRomanBoldFont.widthOfTextAtSize(
          "Label",
          fontSize
        )
        page.drawText(`${labelIndex} of ${jsonData.lables}`, {
          x: 450 + labelWidth + 5,
          y: yOffset - 300,
          size: fontSize,
          font: timesRomanFont,
        })
      }

      let remainingContent = maxContentPerPage
      let currentPage
      let yOffset

      while (remainingContent > 0) {
        if (remainingContent === maxContentPerPage || yOffset < 200) {
          currentPage = pdfDoc.addPage(pageSize)
          yOffset = pageSize[1] - 70
        }

        drawContent(currentPage, yOffset, jsonData, labelIndex)
        yOffset -= 380

        remainingContent -= 1
        labelIndex += 1
      }
      const title = `Label_${profileId}_${
        new Date().toISOString().split("T")[0]
      }`

      // Set the title metadata of the PDF
      pdfDoc.setTitle(title)

      const pdfBytes = await pdfDoc.save()
      const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" })
      setPdfData(pdfBlob)
    }

    useEffect(() => {
      if (pdfData) {
        let url = window.URL.createObjectURL(pdfData)
        const iframe = document.querySelector("#pdf-frame")
        iframe.src = ""
        iframe.src = url
        iframe.style.display = "none"
        setTimeout(() => {
          window.URL.revokeObjectURL(url)
          document.querySelector("#pdf-frame").contentWindow.print()
        }, 1000)
        handlePrintState(false)
      }
    }, [pdfData])

    // const currentProfile = useSelector(
    //   (state) => state.profileDetails?.currentProfile
    // )
    // const profileList = useSelector(
    //   (state) => state.profileDetails.profilesList
    // )
    // const profileOverviewList = useSelector(
    //   (state) => state.profileDetails.profilesOverviewList
    // )

    const initValues = {
      labelCount: 0,
      labelAddress: true,
      printerFlag: { l: "", v: null },
      wasteTypeFlag: { l: "", v: null },
      state: "",
      customIdNumber: "",
      accumlationDatetime: null,
    }

    let printerOpts = {
      id: 1,
      options: [
        {
          l: "Laser",
          v: 1,
        },
        {
          l: "Dot Matrics",
          v: 0,
        },
      ],
    }

    let wasteTypeOpts = {
      id: 1,
      options: [
        {
          l: "Hazard",
          v: 1,
        },
        {
          l: "Non-Hazard",
          v: 0,
        },
      ],
    }

    // const [initialValues, setInitialValues] = useState(initValues)

    const handlePrintState = (print) => {
      dispatch({ type: GET_PRINT_STATUS, payload: print })
    }

    let valSchema = {
      labelCount: Yup.string()
        .required("This field is required")
        .test(
          "is-greater-than-zero",
          "Labels must be greater than 0",
          (value) => Number(value) > 0
        ),
      labelAddress: Yup.string().required("Please select a label address"),
      printerFlag: Yup.object().shape({
        l: Yup.string().required("Printer is required"),
        v: Yup.number().nullable().required("Value is required"),
      }),
    }

    const [isFirstRender, setIsFirstRender] = useState(true)
    const [loading, setLoading] = useState(false)

    const mockCall = async () => {
      let reqPayload = {
        roleId: "",
        userName: "",
        profileId: "115074SP-00",
        lables: 0,
        labelAddress: "",
        state: "",
        accumulationDate: "",
        labelPrinter: "",
        wasteType: "",
        customerId: "",
      }
      try {
        let res = await api.p_getPrintLabel(reqPayload)
        setIsFirstRender(false)
        if (!res) return
        await dispatch({ type: GET_PRINT_LABEL, payload: {} })
      } catch (error) {}
    }

    const currProfile = useSelector(
      (state) => state.profileDetails?.getProfileForPrintLabel
    )

    const [profileId, setProfileId] = useState()

    const commonFun = () => {
      let pId
      const versionStr = currProfile?.version.toString()
      if (versionStr.length > 1 && versionStr.startsWith("0")) {
        pId = currProfile?.profile + "-" + currProfile?.version
      } else {
        pId = currProfile?.profile + "-0" + currProfile?.version
      }
      setProfileId(pId)
    }
    useEffect(() => {
      commonFun()
    }, [currProfile])

    const onSubmit = async (formData) => {
      let address

      if (formData?.labelAddress == true) {
        address = "site"
      } else {
        address = "mailing"
      }

      let reqPayload = {
        profileId: profileId,
        lables: Number(formData?.labelCount),
        labelAddress: address,
        state: formData?.state || "NY",
        accumulationDate: formData?.accumlationDatetime || "",
        labelPrinter: formData?.printerFlag?.l || "Dot Matrics",
        wasteType: formData?.wasteTypeFlag?.l || "Hazard",
        customerId: changeCustomer?.customer?.v,
      }
      try {
        let res = await api.p_getPrintLabel(reqPayload)
        if (!res) return
        await createPdf(res)
      } catch (error) {
      } finally {
        setLoading(false)
      }
      // history.push("/pdf")
    }

    let formik = {
      enableReinitialize: true,
      initialValues: initValues,
      validationSchema: Yup.object().shape(valSchema),
      onSubmit: onSubmit,
    }

    return (
      <Component
        {...props}
        {...{
          handlePrintState,
          onSubmit,
          formik,
          printerOpts,
          wasteTypeOpts,
          isFirstRender,
          mockCall,
          loading,
          // getPrintLabel
        }}
      />
    )
  }
export default enhancer
