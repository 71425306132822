import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import api from "api"

let monthData = {}
let enhancer =
  (Component) =>
  ({ ...props }) => {
    let [dates, setDates] = useState([])
    let [cDates, setCDates] = useState([])
    let [ServiceData, setServiceData] = useState({})
    let [isEstimatedDate, setIsEstimatedDate] = useState(false)
    let history = useHistory()
    let [isload, setIsLoad] = useState(false)

    let updateCalenderConfig = async (config) => {
      let { start, end, selectedDate } = config
      setServiceData([])
      let nDates = [start, end]
      let key = `${start.getMonth()}-${end.getMonth()}`
      setIsLoad(true)
      let res = await api.g_serviceDates({
        dates: nDates,
        loading_key: "service-dates",
      })
      if (!res) {
        setIsLoad(false)
        return
      } else setIsLoad(false)
      // eslint-disable-next-line
      for (const k in res) {
        res[k] = res[k].map((d) => new Date(d))
      }
      monthData[key] = res
      setDates(monthData[key])

      let dates = selectedDate ? [selectedDate, selectedDate] : nDates
      setCDates(dates)
      setIsLoad(true)
      let response = await api.p_schedulesSummary({
        dates,
        loading_key: "schedules-summary",
      })
      if (!response) {
        setIsLoad(false)
        return
      } else setIsLoad(false)
      setServiceData(response)
    }

    const onMySchedule = (e) => {
      let [from, to] = cDates
      history.push("/myschedule", {
        fiscal_span: { from, to },
      })
    }

    const isEstimatedDt = (val) => {
      setIsEstimatedDate(val)
    }

    return (
      <Component
        {...props}
        {...{
          updateCalenderConfig,
          ServiceData,
          dates,
          onMySchedule,
          isEstimatedDt,
          isEstimatedDate,
          isload,
        }}
      />
    )
  }

export default enhancer
