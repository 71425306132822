import React, { useState, useEffect, useRef } from "react"
import { PieChart, Pie, Cell, Legend, Tooltip, Label, Sector } from "recharts"
import { useIntl } from "react-intl"
import colors from "./colors"
import "./style.scss"
import { useWindowDimension } from "shared"

const Chart = ({ data, height, label, value, yUnit = "lbs" }) => {
  const [width, setWidth] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const elementRef = useRef(null)
  const intl = useIntl()
  let total = 0
  if (data?.length > 0)
    data?.forEach((d, i) => {
      total += d.value
      d.color = colors.pie[i]
      d.name = d.description
      d.value = d.weight
    })

  data = data.filter((d) => {
    //data != undefined ?
    return parseInt(d.value) > 0
  }) //: []

  let formatNumber = (n) => {
    return `${intl.formatNumber(Math.trunc(n), {
      notation: "compact",
    })} ${yUnit}`
  }
  let tEllipses = (text, max = 45) => {
    let textBreak = text.length > max
    let t = text.substr(0, max - 1) + (textBreak ? "..." : "")
    return (
      <span className="color-blk" title={text}>
        {t}
      </span>
    )
  }

  let dim = useWindowDimension()

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width)
  }, dim)

  //rendering/changing the css of the pie slice based on the it's active status
  const renderActiveShape = (props) => {
    const {
      cx,
      cy,
      //midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      //payload,
      //percent,
      //value,
    } = props

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius - 5}
          outerRadius={outerRadius + 5}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    )
  }

  const onPieEnter = (data, index) => {
    setActiveIndex(index)
  }

  return (
    <div className="piechart" ref={elementRef}>
      <PieChart
        width={width}
        height={height}
        margin={{ top: 10, right: 40, left: 0, bottom: 10 }}
      >
        <Pie
          activeShape={renderActiveShape}
          data={data}
          dataKey={value}
          nameKey={label}
          cx="55%"
          cy="38%"
          innerRadius={60}
          outerRadius={80}
          paddingAngle={5}
          activeIndex={activeIndex}
        >
          {data.map((d, index) => (
            <Cell
              key={`cell-${index}`}
              fill={d.color}
              stroke=""
              onClick={() => onPieEnter(d, index)}
            />
          ))}
          <Label width={30} position="center">
            {Number(
              (data[activeIndex]?.value?.toFixed(2) / total.toFixed(2)) * 100
            ).toFixed(2) + "%"}
          </Label>
        </Pie>
        <Tooltip formatter={formatNumber} />
        <Legend
          layout="vertical"
          verticalAlign="bottom"
          align="center"
          iconType="circle"
          height={"12rem"}
          wrapperStyle={{
            position: "absolute",
            top: "13rem",
            //paddingBottom: 10,
          }}
          formatter={(v, e) => (
            <span className="fontSize text-break">
              {tEllipses(
                `${v} - ${formatNumber(Math.trunc(Number(e.payload.value)))}`
              )}
            </span>
          )}
        />
      </PieChart>
    </div>
  )
}

export default Chart
