import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import Image from "shared/Image"
import { useHistory } from "react-router-dom"
import { MandIReportNames } from "./Lists"

function List() {
  let history = useHistory()
  const isTablet = false
  let reportConfig = MandIReportNames
  return (
    <Fragment>
      <div className=" requestServiceBlock">
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left mt-4 mb-3">
          <div className="m-0 labelgenerator" style={{ fontSize: "1.25rem" }}>
            <FormattedMessage id="title.profilereport" />
          </div>
        </div>
        <div className="row m-0 px-4">
          {reportConfig.map((k) => {
            return (
              <div
                className={`${
                  isTablet
                    ? "col-sm-12 col-md-12 col-lg-12 text-left p-0 ml-4 request-title-block eachrow mr-5 mt-3 mb-3 tooltips reportgen"
                    : "col-sm-6 col-md-6 col-lg-6 text-left p-0 ml-4 request-title-block mr-5 mt-3 mb-3 tooltips reportgen"
                }`}
                key={reportConfig[0].l}
                onClick={() => history.push(`/reports/report`)}
              >
                <div className="row m-0">
                  <div className="col-sm-2 col-md-2 col-lg-2 reportgen">
                    <div className="img">
                      <Image
                        className=""
                        src={"ic_report_bg.svg"}
                        height="60px"
                        width="60px"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0 request-title">
                    <div className="service-title">Profile Listing reports</div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {/* <div
        style={{ position: "absolute", bottom: 0, right: 0, padding: "1rem" }}
      >
        <Image src="mi_report_icon.svg" style={{ opacity: 0.5 }} />
      </div> */}
    </Fragment>
  )
}

export default List
