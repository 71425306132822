import React, { useState, useEffect } from "react"
import Calendar from "react-calendar"
import { FormattedMessage } from "react-intl"
import classnames from "classnames"
import "./style.scss"
import {
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  format,
  add,
  isToday,
  isEqual,
  isBefore,
  startOfToday,
  startOfMonth,
  endOfMonth,
  isSameMonth,
  isPast,
  isFuture,
  startOfYear,
} from "date-fns"
import Loading from "shared/Loading"
import { useLocation } from "react-router-dom"

const CalendarComp = ({
  dates,
  updateCalenderConfig,
  disable,
  persist,
  isEstimatedDt,
  isload,
}) => {
  let today = startOfToday()
  const [startDate, setStartDate] = useState(today)
  const [weekMode, setWeekMode] = useState(false)
  const [monthMode, setMonthMode] = useState(false)
  const [selectedDate, setSelectedDate] = useState(today)
  const [weekDates, setWeekDates] = useState([])
  const [year, setYear] = useState(format(today, "yyyy"))
  let pastValidDate = add(startOfYear(today), { years: -6 })
  let cYear = new Date().getFullYear()
  let lastYears = [...Array(6).keys()].map((d) => cYear - d)

  let { pathname } = useLocation()

  let yearSelection = []

  lastYears.forEach((ele) => {
    yearSelection.push({ l: ele.toString(), option: ele.toString() })
  })
  const months = [
    { month: "Jan", position: 1 },
    { month: "Feb", position: 2 },
    { month: "Mar", position: 3 },
    { month: "Apr", position: 4 },
    { month: "May", position: 5 },
    { month: "Jun", position: 6 },
    { month: "Jul", position: 7 },
    { month: "Aug", position: 8 },
    { month: "Sep", position: 9 },
    { month: "Oct", position: 10 },
    { month: "Nov", position: 11 },
    { month: "Dec", position: 12 },
  ]

  let getWeekDates = (c_date) => {
    setWeekDates(
      eachDayOfInterval({
        start: startOfWeek(c_date),
        end: endOfWeek(c_date),
      })
    )
  }

  const onSelect = (event) => {
    setYear(event.target.value)
    let opts = {
      years: parseInt(event.target.value) - format(startDate, "yyyy"),
    }
    setStartDate(add(startDate, opts))
    if (!persist) setSelectedDate(null)
  }

  useEffect(() => {
    let c_dates = {}
    if (weekMode)
      c_dates = {
        start: startOfWeek(startDate),
        end: endOfWeek(startDate),
      }
    else
      c_dates = {
        start: startOfMonth(startDate),
        end: endOfMonth(startDate),
      }

    updateCalenderConfig({ ...c_dates, weekMode, selectedDate })
  }, [startDate, weekMode, selectedDate])

  useEffect(() => {
    const e_date = isActiveDate(selectedDate)
    const isEstimatedDate = e_date?.props?.className?.includes("estimated_dot")
    isEstimatedDt(isEstimatedDate)
  }, [dates, selectedDate])

  useEffect(() => {
    getWeekDates(startDate)
  }, [startDate])

  const updateMonthMode = (d) => {
    let c_date = today
    if (!isSameMonth(c_date, startDate)) {
      setStartDate(c_date)
    }
    setMonthMode(d)
    setSelectedDate(null)
    setWeekMode(false)
  }

  let updateWeekMode = (d) => () => {
    let c_date = today
    if (!isSameMonth(c_date, startDate)) {
      setStartDate(c_date)
    }
    setWeekMode(d)
    setSelectedDate(null)
    setMonthMode(false)
    setYear(format(today, "yyyy"))
  }

  let updateDate = (direction) => () => {
    let opts = { months: 1 * direction }
    if (weekMode) opts = { days: 7 * direction }
    setStartDate(add(startDate, opts))
    if (!persist) setSelectedDate(null)
  }

  const getMonthName = (date) => {
    return format(date, "LLL")
  }

  const getDirection = (direction) => {
    return (
      direction?.position -
      months.filter((ele) => ele.month === getMonthName(startDate))[0]?.position
    )
  }

  const updateMonthDate = (direction) => {
    let opts = { months: 1 * getDirection(direction) }
    if (weekMode) opts = { days: 7 * direction }
    setStartDate(add(startDate, opts))
    if (!persist) setSelectedDate(null)
  }

  let goTotoday = () => {
    let c_date = today
    if (!isSameMonth(c_date, startDate)) {
      setStartDate(c_date)
    }
    setSelectedDate(c_date)
    setWeekMode(false)
    setMonthMode(false)
    setYear(format(today, "yyyy"))
  }

  let isActiveDate = (value) => {
    if (
      !!dates.serviceDateTime?.find(
        (item) => item?.getTime() == value?.getTime()
      )
    ) {
      return <div className="calender_dot service_dot" />
    }
    if (
      !!dates.estimatedDateTime?.find(
        (item) => item?.getTime() == value?.getTime()
      )
    ) {
      return <div className="calender_dot estimated_dot" />
    }
  }

  let isSelectedDate = (d) => {
    return isEqual(d, selectedDate)
  }

  let updateSelectedDate = (d) => {
    if (isEqual(d, selectedDate) && !persist) setSelectedDate(false)
    else {
      setSelectedDate(d)
    }
  }

  const toCalendarType = (weekStartDay) =>
    weekStartDay === 0 ? "US" : "ISO 8601"

  let toDisable = ({ date }) => {
    if (!disable) return false
    if (disable && isToday(date)) return false
    if (disable == "past") return isPast(date)
    if (disable == "future") return isFuture(date)
  }

  let isFarPast = () => isBefore(startDate, pastValidDate)

  return (
    <div className="btns">
      <span className="weeklylabel">
        <span>{format(startDate, "MMMM Y")}</span>
      </span>
      {monthMode && (
        <select
          className={classnames("select-options", { disable: isload })}
          value={year}
          onChange={(e) => onSelect(e)}
        >
          {yearSelection.map((option) => {
            return (
              <option
                className="special first-last"
                key={option.l}
                value={option.value}
              >
                {option.l}
              </option>
            )
          })}
        </select>
      )}

      <div className="btn-group calender-action">
        <button
          onClick={() => updateMonthMode(true)}
          className={classnames(" btn-light month", {
            active_btn: monthMode,
            disable: isload,
          })}
        >
          Month
        </button>
        <button
          onClick={updateWeekMode(true)}
          className={classnames("btn-light week", {
            active_btn: weekMode && !selectedDate,
            disable: isload,
          })}
        >
          Week
        </button>
        <button
          onClick={goTotoday}
          className={classnames(" btn-light today", {
            active_btn: isToday(selectedDate),
            disable: isload,
          })}
        >
          <FormattedMessage id="dashboard.today" />
        </button>
      </div>

      {pathname?.includes("requestservice/change_request") && (
        <>
          <div
            onClick={updateDate(-1)}
            className={classnames("prevbtn mr-2 button-solid pad-rgt", {
              invisible: isFarPast(),
            })}
            type="button"
          >
            <span className="previmg"></span>
          </div>
          <div
            onClick={updateDate(1)}
            className="nextbtn button-solid pad-rgt"
            type="button"
          >
            <span className="nextimg"></span>
          </div>
        </>
      )}

      {/* <Loading id="schedules-summary"> */}
      <div
        className={classnames("months-boxes", {
          "d-none": !monthMode,
        })}
      >
        {months.map((ele, index) => {
          return (
            <>
              <button
                key={index}
                onClick={() => updateMonthDate(ele)}
                className={classnames("month-views-list", {
                  "month-active": getMonthName(startDate) === ele.month,
                  "month-inactive": getMonthName(startDate) !== ele.month,
                  disable: isload && getMonthName(startDate) !== ele.month,
                })}
              >
                {ele.month}
              </button>
            </>
          )
        })}
      </div>

      <Loading id="service-dates">
        <Calendar
          calendarType={toCalendarType(0)}
          activeStartDate={startDate}
          startOfWeek="Sunday"
          maxDetail="month"
          className={classnames("calCleanEarth", { "d-none": weekMode })}
          showNavigation={false}
          tileContent={({ date }) => isActiveDate(date)}
          onChange={updateSelectedDate}
          value={selectedDate}
          tileDisabled={toDisable}
          name="date"
          minDate={pastValidDate}
        />
        <div
          className={classnames("week-sec mt-3 mb-2", {
            "d-none": !weekMode,
            disable: isload,
          })}
        >
          <ul className="list-inline mb-0  weekview">
            <li className="d-inline-block text-center">
              <div
                onClick={updateDate(-1)}
                className={classnames("prevbtn mr-2", {
                  invisible: isFarPast(),
                })}
                type="button"
              >
                {/* <Icon.ChevronLeft className="mb-1" /> */}
                <span className="previmg height-30"></span>
              </div>
            </li>
            {weekDates.map((d) => (
              <li
                key={d}
                onClick={() => updateSelectedDate(d)}
                role="button"
                className={classnames(
                  "d-inline-block card mx-1 text-center position-relative",
                  { active: isSelectedDate(d), today: isToday(d) }
                )}
              >
                <span className="day d-block">{format(d, "eee")}</span>
                <span className="date d-block">{format(d, "d")}</span>
                <span className="mn d-block">{format(d, "MMM")}</span>

                {isActiveDate(d)}
              </li>
            ))}

            <li className="d-inline-block text-center">
              <div onClick={updateDate(1)} className="nextbtn mar-right-20">
                <span className="nextimg height-30"></span>
                {/* <Icon.ChevronRight className="mb-1 " /> */}
              </div>
            </li>
          </ul>
        </div>
        <div className="c-legend-block">
          <span className="c-legend estimated_dot" />{" "}
          <FormattedMessage id="calender.projectedServices" />
          <span className="c-legend service_dot ml-4" />{" "}
          <FormattedMessage id="calender.completedServices" />
        </div>
      </Loading>
    </div>
  )
}

export default CalendarComp
