import React, { useState, useEffect } from "react"
import Image from "shared/Image"
import { AnnouncementsHeader } from "../Components/constants"
import { useHistory } from "react-router-dom"
import api from "api"
import { format } from "date-fns"

let enhancer = (Component) => (props) => {
  let history = useHistory()
  let [pageNo, gotoPage] = useState(1)
  let [sortColumn, setSortColumn] = useState("")
  let [sortOrder, setSortOrder] = useState("")
  let [announcementData, setAnnouncementData] = useState([])
  let [totalRecords, setTotalRecords] = useState(0)
  const [sortName, setSortName] = useState("")

  let getAnnouncementData = async () => {
    let res = await api.g_getannouncements({
      loading_key: "user-management",
      index: pageNo,
      size: 20,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
    })
    if (res.items.length > 0) {
      setRows(res.items)
      setTotalRecords(res.totalRecords)
    }
  }

  useEffect(() => {
    ;(async () => {
      await getAnnouncementData()
    })()
  }, [pageNo, sortColumn, sortOrder])

  let columnHeader = (data, clsName = "") => {
    return (
      <div>
        <span className="announcement-grid-header">{data.headerTitle}</span>
        <Image
          className={clsName}
          src={data.sort ? "asc_icon_table.svg" : "desc_icon_table.svg"}
          onClick={() => changeSortColum(data)}
        />
      </div>
    )
  }

  const changeSortColum = (info) => {
    let header = [],
      sortColumn = ""
    AnnouncementsHeader.forEach((ele) => {
      if (ele.hasOwnProperty("sort")) {
        if (ele.headerTitle === info.headerTitle) {
          ele.sort = !ele.sort
          sortColumn = info.headerTitle
          setSortName(sortColumn)
        } else {
          ele.sort = false
        }
        ele.value = columnHeader("un-select-sort")
      }
      header = [...header, ele]
    })

    setSortColumn(info?.apiKey)
    setSortOrder(
      info?.hasOwnProperty("sort") ? (info?.sort ? "asc" : "desc") : ""
    )
  }

  const backToUserManagement = () => {
    history.push("/admin")
  }

  const navigateToNewAnnouncement = () => {
    history.push("/admin/manageannouncements")
  }

  let headers = []
  AnnouncementsHeader.forEach((ele) => {
    if (ele.hasOwnProperty("sort")) {
      ele.value = columnHeader(
        ele,
        sortName === ele.headerTitle ? "selected-sort-column" : "un-select-sort"
      )
    } else {
      ele.value = (
        <div>
          <span className="announcement-grid-header">{ele.headerTitle}</span>
        </div>
      )
    }
    headers = [...headers, ele]
  })

  const setRows = (data) => {
    let columnData = []
    data?.forEach((ele) => {
      let row = {
        data: [
          {
            value: (
              <div className="word-break" title={ele?.description}>
                {ele?.description || ""}
              </div>
            ),
            className: "user-grid-cell",
          },
          {
            value: ele?.startDate
              ? `${format(new Date(ele?.startDate), "LL/dd/yyyy") || "-"}`
              : "N/A",
            className: "user-grid-cell",
          },
          {
            value: ele?.endDate
              ? `${format(new Date(ele?.endDate), "LL/dd/yyyy") || "-"}`
              : "N/A",
            className: "user-grid-cell",
          },
          {
            value: ele?.status || "",
            className: "user-grid-cell",
          },
          {
            value: `${ele?.duration}  ${ele?.duration > 1 ? " days" : " day "}`,
            className: "user-grid-cell",
          },
          {
            value: (
              <div>
                <Image
                  className={`view-icon  `}
                  src="View_Icon_IC.svg"
                  height="26px"
                  width="53px"
                  alt=""
                  onClick={() => navigateToViewAnnouncement(ele)}
                />
                <Image
                  className={`view-icon m-l-10 ${
                    new Date(ele?.startDate) <= new Date()
                      ? "prevent-clics"
                      : ""
                  }`}
                  src="ic_user_edit_icon.svg"
                  height="26px"
                  width="53px"
                  alt=""
                  onClick={() => navigateToEditAnnouncement(ele)}
                />
              </div>
            ),
            className: "user-grid-cell w-20-per",
          },
        ],
      }
      columnData = [...columnData, row]
    })

    setAnnouncementData(columnData)
  }

  const navigateToViewAnnouncement = (announcement) => {
    history.push({
      pathname: "/admin/editannouncements",
      state: { value: announcement, viewState: true },
    })
  }

  const navigateToEditAnnouncement = (announcement) => {
    history.push({
      pathname: "/admin/editannouncements",
      state: { value: announcement, editState: true },
    })
  }

  return (
    <Component
      {...props}
      {...{
        backToUserManagement,
        navigateToNewAnnouncement,
        gotoPage,
        setSortColumn,
        setSortOrder,
        pageNo,
        sortColumn,
        sortOrder,
        announcementData,
        totalRecords,
      }}
    />
  )
}

export default enhancer
