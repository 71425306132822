import React from "react"
import enhancer from "./enhancer"
import UserTableGrid from "../Components/UserTableGrid"
import Loading from "shared/Loading"
import { AnnouncementsHeader } from "../Components/constants"
import Image from "shared/Image"
import { FormattedMessage } from "react-intl"
import "./style.scss"
import Pagination from "shared/Pagination"

const EditAnnouncements = (props) => {
  const {
    backToUserManagement,
    navigateToNewAnnouncement,
    gotoPage,
    totalRecords,
    pageNo,
    announcementData,
  } = props

  // console.log(announcementData)

  return (
    <div className="user-management">
      <div className="col-sm-6 col-md-9 col-lg-9 mt-4 mb-1">
        <div className="row m-0 ">
          <div
            className="dp-al-ctr back-link"
            onClick={() => backToUserManagement()}
          >
            <Image
              className=""
              src={"back-button.svg"}
              height="14px"
              width="14px"
              alt=""
            />
            <div className="p-14th underline">{`BACK`}</div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-md-9 col-lg-9 mt-1 mb-3">
        <div className="row m-0 ">
          <div className="announcement-edit-title">
            <FormattedMessage id={"announcementedit.title"} />
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-3">
        <div className="row m-0 flx-end">
          <div
            className="announcement-create-announcement"
            onClick={() => navigateToNewAnnouncement()}
          >
            <Image
              className=""
              src={"Add_Icon_Ic.svg"}
              height="20px"
              width="20px"
              alt=""
            />
            <span className="p-l-10 announcement-new-announcement-button">
              <FormattedMessage id={"admintilemanagement"} />
            </span>
          </div>
        </div>
      </div>
      <Loading id={"user-management"}>
        {totalRecords > 0 && (
          <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-3">
            <UserTableGrid
              columnData="EditAnnouncements"
              headers={AnnouncementsHeader}
              rows={announcementData}
            />
            <Pagination
              {...{
                pageNo: pageNo,
                totalRecords: totalRecords,
                gotoPage,
                data: announcementData,
                pageSize: 20,
              }}
            />
          </div>
        )}
        {totalRecords === 0 && (
          <div className="col-sm-12 col-md-12 col-lg-12 text-center pt-2 nodata">
            <FormattedMessage id="nodata.announcements" />
          </div>
        )}
      </Loading>
    </div>
  )
}

export default enhancer(EditAnnouncements)
