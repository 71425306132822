import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import { isRbsCorporateUser } from "shared"

function RequestCheckout(props) {
  let { onDashboardClick, onNewServiceRequestClick, requestService } = props

  let Row = ({ messagekey, keyvalue, detail, subkey }) => (
    <div>
      <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
        <div className="row m-0">
          <div className="m-0 col-2 p-0 label">
            <FormattedMessage id={`requestservicecheckout.${messagekey}`} />
          </div>
          <div
            className={`${
              Array.isArray(keyvalue) ? "m-0 value col-10" : "m-0 value"
            }`}
          >
            {Array.isArray(keyvalue) ? (
              <div className="suppliesitem row">
                <div className=" col-6 border-rb border-t">Name</div>
                <div className=" col-2 border-b border-t">Qty</div>
                {keyvalue.map((a, i) => (
                  <>
                    <div className=" col-6 border-rb" key={i}>
                      {a.key}
                    </div>
                    <div className=" col-2 border-b" key={i}>
                      {a.value}
                    </div>
                  </>
                ))}
              </div>
            ) : (
              keyvalue
            )}
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
        <div className="row m-0">
          <div className="m-0 col-2 p-0 label">
            <FormattedMessage id={`requestservicecheckout.${subkey}`} />
          </div>
          <div className="m-0 col-10 p-0 value">{detail}</div>
        </div>
      </div>
    </div>
  )
  return (
    <Fragment>
      <div className="requestServiceBlock requestSuccessBlock">
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left mt-4 mb-3">
          <div className="m-0 labelgenerator">
            {requestService?.type && (
              <FormattedMessage id={`requestservice.${requestService?.type}`} />
            )}
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left">
          <div className="success-block">
            <div className="m-0 title">
              <FormattedMessage id="requestservicecheckout.success" />
            </div>
            <div className="m-0 subTitle">
              <FormattedMessage id="requestservicecheckout.subsuccess1" />
              <br />
              <b>
                <FormattedMessage
                  id="requestservicecheckout.subsuccess2"
                  values={{ caseNumber: requestService.caseNumber }}
                />
              </b>
              <div className="subTxtMsg">
                <FormattedMessage
                  id="requestservicecheckout.subsuccess3"
                  values={{ email: requestService.email }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left">
          <div className="m-0 contact-title">
            <FormattedMessage id="requestservicecheckout.contactinfo" />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="requestservicecheckout.contactname" />
            </div>
            <div className="m-0 value col-10 p-0">{requestService.name}</div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="requestservicecheckout.contactphone" />
            </div>
            <div className="m-0 value col-10 p-0">{requestService.phone} </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="requestservicecheckout.email" />
            </div>
            <div className="m-0 value col-10 p-0">{requestService.email} </div>
          </div>
        </div>
        {requestService.additional_email != "" && (
          <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
            <div className="row m-0">
              <div className="m-0 col-2 p-0 label">
                <FormattedMessage id="requestservicecheckout.cc" />
              </div>
              <div className="m-0 value col-10 p-0">
                {requestService.additional_email.replace(/;/g, "; ")}
              </div>
            </div>
          </div>
        )}
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left">
          <div className="m-0 contact-title">
            <FormattedMessage id="requestservicecheckout.requestdetails" />
          </div>
        </div>
        {isRbsCorporateUser() && (
          <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
            <div className="row m-0">
              <div className="m-0 col-2 p-0 label">
                <FormattedMessage id="requestservicecheckout.brand" />
              </div>
              <div className="m-0 value col-10 p-0">
                {requestService.division}
              </div>
            </div>
          </div>
        )}
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="requestservicecheckout.store" />
            </div>
            <div className="m-0 value col-10 p-0">{requestService.store}</div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="requestservicecheckout.addresslabel" />
            </div>
            <div className="m-0 value">
              <FormattedMessage
                id="requestservicenewschedule.storeaddress"
                values={{ address: requestService.address }}
              />
            </div>
          </div>
        </div>
        {requestService?.type == "report_issue" && (
          <Row
            messagekey={"serviceIssue"}
            keyvalue={requestService.service_issue}
            detail={requestService.details}
            subkey={"details"}
          />
        )}
        {requestService?.type == "change_request" && (
          <Row
            messagekey={"dates"}
            keyvalue={requestService.date}
            detail={requestService.details}
            subkey={"details"}
          />
        )}
        {requestService?.type == "new_schedule_request" && (
          <div>
            <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
              <div className="row m-0">
                <div className="m-0 col-2 p-0 label">
                  <FormattedMessage id="requestservicecheckout.requesttype" />
                </div>
                <div className="m-0 value">{requestService.request_type}</div>
              </div>
            </div>
            <Row
              messagekey={"servicetype"}
              keyvalue={requestService.service_type}
              detail={requestService.details}
              subkey={"instruction"}
            />
          </div>
        )}
        {requestService?.type == "request_supplies" && (
          <Row
            messagekey={"supplies"}
            keyvalue={requestService.supplies}
            detail={requestService.details}
            subkey={"details"}
          />
        )}

        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServicebutton text-center">
          <div className="row m-0">
            <div className="m-0 col-12 p-0">
              <button
                className="green-button"
                onClick={() => {
                  onDashboardClick()
                }}
              >
                <FormattedMessage id="requestservicecheckout.returntodashboard" />
              </button>
            </div>
            <div className="m-0 col-12 mt-1 mb-5">
              <span
                className="green-label"
                onClick={() => {
                  onNewServiceRequestClick()
                }}
              >
                <FormattedMessage id="requestservicecheckout.generatenewrequest" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default enhancer(RequestCheckout)
