import React from "react"
import { FormattedMessage } from "react-intl"
import Loading from "shared/Loading"
import classnames from "classnames"
import { isHomeDepot } from "shared"

function PendingService(props) {
  let { pendingservice = [] } = props
  const isHD = isHomeDepot()

  return (
    <div className="col-4 pr-0">
      <div className="text-left status mb-2">
        <FormattedMessage id="serviceorder.pendingservice" />
      </div>
      <div className="row m-0 borders pendingService">
        <Loading id="get_pendingservice">
          <div
            className={classnames("col-12 p-0 m-0", {
              "d-none": !pendingservice[0],
            })}
          >
            <div className="row pl-0 m-0">
              <div className="col-6  m-0 text-left">
                <div className="serviceheading">
                  <FormattedMessage id="serviceorder.servicetype" />
                </div>
              </div>
              <div className="col-6  m-0 text-left">
                <div className="serviceheading">
                  <FormattedMessage id="serviceorder.dateofnextservice" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 p-0 m-0">
            <div
              className={`row p-0 m-0  ${
                pendingservice.length > 0 ? "scrollbar" : ""
              }`}
            >
              {pendingservice.map((el, i) => (
                <div className="col-12 p-0 m-0 " key={i}>
                  <div className="row p-0 m-0 ">
                    <div className="col-6  m-0 text-left">
                      <div className="d-block servicerow ">
                        {el.serviceType}
                      </div>
                    </div>
                    <div className="col-6  m-0 text-left">
                      <div className="d-block servicerow">
                        {el.noShip ? "" : el.dateFormat}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {pendingservice.length == 0 && (
            <div className="col-12 text-center nodata ">
              {isHD ? (
                <FormattedMessage id="pendingService.comingsoon" />
              ) : (
                <FormattedMessage id="nodata.pendingService" />
              )}
            </div>
          )}
        </Loading>
      </div>
    </div>
  )
}
export default PendingService
