import React from "react"
import ReactDOM from "react-dom/client"
import "./index.scss"
import App from "./App"
import "api/axios"
import reportWebVitals from "./reportWebVitals"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from "react-toastify"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <App />
    <ToastContainer
      position="top-center"
      hideProgressBar={true}
      closeOnClick
      rtl={false}
      pauseOnHover
      pauseOnFocusLoss
      draggable
      limit={1}
      progress={undefined}
      theme="colored"
      icon={false}
    />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
