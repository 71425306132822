import React, { useState, useEffect } from "react"
import { getCurrentTitle, getTableConfig } from "shared/Filter/config"
import { useParams } from "react-router-dom"
import { roles } from "config"
import domtoimage from "dom-to-image"
import fileDownload from "js-file-download"
import _ from "lodash"

import * as dfns from "date-fns"
import {
  format,
  eachMonthOfInterval,
  startOfMonth,
  endOfMonth,
  parse,
  // differenceInDays,
} from "date-fns"
import {
  dateForApi,
  round,
  formattedDate,
  isRbsCorporateUser,
  isHomeDepotSiteUser,
} from "shared"
import api from "api"
import excel from "exceljs"
import { saveAs } from "file-saver"
// import { toast } from "react-toastify"
// import { useIntl } from "react-intl"
import { useSelector } from "react-redux"
import axios from "axios"

let enhancer =
  (Component) =>
  ({ history, ...props }) => {
    let [reportData, setReportData] = useState({})
    const [config, setConfig] = useState({})
    const [title, setTitle] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const [filters, setFilters] = useState({})
    const [summary, setSummary] = useState({})
    const [showModel, setShowModal] = useState(false)
    const [showDownloadModel, setDownloadModel] = useState(false)
    let [pageNo, gotoPage] = useState(1)

    let { type, id, date } = useParams()
    // const { formatMessage } = useIntl()

    const user = useSelector((state) => state.profile)
    const storeInfo = useSelector((state) => state.stores)
    let sRConId = useSelector((state) => {
      return state?.user?.sRConId
    })

    let today = new Date()
    let monthStart, monthEnd
    if (date) {
      monthStart = parse(date, "MMMM", new Date())
      monthEnd = endOfMonth(monthStart)
    } else {
      monthStart = startOfMonth(today)
      monthEnd = endOfMonth(today)
    }

    let stores = false

    if (id) stores = id

    let downloadSelectOpts = [
      { l: "Download as PDF", ext: "pdf" },
      { l: "Download as XLX", ext: "xlxs" },
    ]

    const cancelTokenSource = axios.CancelToken.source()
    const loading_key = "reports"

    useEffect(() => {
      setConfig(loadConfig())
      setTitle(getCurrentTitle(type))

      if (filters.exception_type) fetchData()
    }, [type])

    let loadConfig = () => {
      let config = getTableConfig(type)
      config.emptyHeaders = config.columns.emptyHeaders
      config.columns = config.columns.default

      return config
    }
    let fetchSummary = async () => {
      let { fiscal_month_year, states, divisions_regions } = filters
      if (type == "general_status") {
        let req = {
          loading_key: "reports-sum",
        }
        if (stores) {
          if (fiscal_month_year) {
            req.states = states
            req.regions = divisions_regions
            if (user?.roleId == roles.Brand_User) {
              filters.brands = []
              filters.brands.push(user.division)
            }
            req.divisions = filters.brands
            req.dates = [fiscal_month_year.from, fiscal_month_year.to]
          } else {
            req.dates = [monthStart, today]
          }
          req.stores = stores.split(",")
        } else {
          req.states = states
          req.regions = divisions_regions
          if (user?.roleId == roles.Brand_User) {
            filters.brands = []
            filters.brands.push(user.division)
          }
          req.divisions = filters.brands
          req.dates = [fiscal_month_year.from, fiscal_month_year.to]
        }
        if (filters.stores) req.stores = filters.stores

        let res = await api.p_genStatusTotals(req)
        if (!res) return
        let nHeaders = eachMonthOfInterval({
          start: stores ? monthStart : fiscal_month_year.from,
          end: stores ? today : fiscal_month_year.to,
        }).map((d) => format(d, "yyyy-MM"))

        let summary = { groups: {}, total: {} }

        res.divisionTotals.forEach((d) => {
          summary.groups[d.state] = { totalWeight: round(d.totalWeight) }

          nHeaders.forEach((d1) => {
            summary.groups[d.state][d1] = 0
          })
          d.weightDetails.forEach((d1) => {
            summary.groups[d.state][d1.title] = round(d1.weight)
          })
        })
        summary.total = { totalWeight: round(res.totals.totalWeight) }
        nHeaders.forEach((d1) => {
          summary.total[d1] = 0
        })

        res.totals.weightDetails.forEach((d1) => {
          summary.total[d1.title] = round(d1.weight)
        })
        setSummary(summary)
      }
    }

    let fetchData = async (size = false) => {
      let config2 = loadConfig()
      let { columns } = config2
      let {
        fiscal_month_year,
        states,
        divisions_regions,
        exception_type,
        profile_list,
        fiscal_span,
        time_present_future_year,
        service_type,
        cities,
        brands,
      } = filters
      let req = {
        loading_key,
      }
      if (user?.roleId == roles.Brand_User) {
        brands = []
        brands.push(user.division)
      }
      if (stores) {
        req.stores = stores.split(",")
        if (fiscal_month_year) {
          req.states = states
          req.regions = divisions_regions
          req.divisions = brands
          req.dates = [fiscal_month_year.from, fiscal_month_year.to]
        } else req.dates = [monthStart, monthEnd]
      } else if (fiscal_span) {
        req.city = cities
        req.dates = [fiscal_span.from, fiscal_span.to]
      } else if (fiscal_month_year) {
        req.states = states
        req.regions = divisions_regions
        req.divisions = brands
        req.dates = [fiscal_month_year.from, fiscal_month_year.to]
      }
      if (time_present_future_year && type === "annual_schedule_report") {
        if (isHomeDepotSiteUser() && storeInfo.length === 1) {
          filters.stores = []
          req.stores = filters.stores.push(storeInfo[0].storeNumber)
        }
        req.states = states
        req.regions = divisions_regions
        req.service_type = service_type
        req.year = time_present_future_year
      }
      if (size) req.size = size
      else req.index = pageNo

      if (exception_type) {
        req.exceptions = exception_type
      }

      if (profile_list) {
        req.profiles = [...profile_list]
      }

      if (filters.stores) req.stores = filters.stores

      req.cancelToken = cancelTokenSource.token
      var res
      try {
        if (
          time_present_future_year === undefined &&
          type === "annual_schedule_report"
        )
          res = false
        else res = await api[config2.apiKey](req)
        if (!res) return
        setSubmitted(true)

        if (type == "general_status") {
          let nHeaders = []
          if (res.items[0]) {
            nHeaders = eachMonthOfInterval({
              start: stores ? monthStart : fiscal_month_year.from,
              end: stores ? today : fiscal_month_year.to,
            }).map((d) => {
              let accessor = format(d, "yyyy-MM")
              return {
                Header: `${accessor} (lbs)`,
                accessor,
                unit: "number",
              }
            })

            res.items.forEach((d1) => {
              d1.totalWeight = round(d1.totalWeight)
              d1.weightDetails.forEach((d) => {
                d1[d.title] = round(d.weight)
              })
              nHeaders.forEach((d) => {
                let { accessor } = d
                if (!d1[accessor]) d1[accessor] = 0
              })
              delete d1.generatorAzid
              delete d1.weightDetails
            })
            config2.columns = columns && [...columns.slice(0, 6), ...nHeaders]

            config2.columns = config2.columns.filter(
              (d) => d.accessor != "totalWeight"
            )
            config2.columns.push(columns[columns.length - 1])
          }
        }

        res.items.forEach((d) => {
          fixDates(d, size)
        })

        if (size) {
          let { columns, extra } = config2
          let { groups, total } = summary

          let body = { data: res.items, columns, extra, groups, total }
          return body
        } else {
          setConfig(config2)
          setReportData({ data: res.items, ...res })
        }
      } catch (err) {
        setSubmitted(false)
        setReportData({})
      }
    }
    useEffect(() => {
      if (pageNo == -1) return
      // if (!filters.fiscal_month_year && type !== "invoice_report") return
      // if (!filters.fiscal_span && type === "invoice_report") return
      fetchData()
      return () => {
        cancelTokenSource?.cancel(`cancel;${loading_key}`)
      }
    }, [pageNo])

    useEffect(() => {
      if (!filters.fiscal_month_year) return
      fetchSummary()
    }, [filters])

    useEffect(() => {
      // return console.log(stores)
      if (!stores) return
      fetchData()
      fetchSummary()
    }, [stores])

    let fixDates = (d, size = false) => {
      if (d.serviceDate) {
        if (size) {
          d.serviceDate = new Date(
            dfns.format(new Date(d.serviceDate), "MM/dd/yyyy")
          )
        } else {
          d.serviceDate = format(new Date(d.serviceDate), "MMMM dd, yyyy")
        }
      }
      if (!d.customerName) d.customerName = ""
      // d.shipmentDate = formattedDate(d.shipmentDate)
      // d.serviceDate = formattedDate(d.serviceDate)
      d.receivingFacilitySignedDate = formattedDate(
        d.receivingFacilitySignedDate
      )
      if (d.shippedDate)
        d.shippedDate = format(new Date(d.shippedDate), "LL/dd/yyyy")

      //change in date format serviceDateTime
      if (d.serviceDateTime && size) {
        d.serviceDateTime = new Date(
          dfns.format(new Date(d.serviceDateTime), "MM-dd-yyyy")
        )
      } else d.serviceDateTime = formattedDate(d.serviceDateTime)

      //change in date format shipmentDate
      if (d.shipmentDate && size)
        d.shipmentDate = new Date(
          dfns.format(new Date(d.shipmentDate), "MM-dd-yyyy")
        )
      else d.shipmentDate = formattedDate(d.shipmentDate)

      //change in date format receivingFacilitySignedDate
      if (
        d.receivingFacilitySignedDate &&
        size &&
        d.receivingFacilitySignedDate != "Not Available"
      )
        d.receivingFacilitySignedDate = new Date(d.receivingFacilitySignedDate)
      else
        d.receivingFacilitySignedDate = formattedDate(
          d.receivingFacilitySignedDate
        )

      if (d.exceptions) d.exceptions = d.exceptions.split(",")
    }

    let submit = async (nFilters) => {
      setFilters(nFilters)

      gotoPage(-1)
      setTimeout(() => {
        gotoPage(1)
      }, 10)
    }

    let graphTitle = () => {
      if (type == "pt_wood_weekly") {
        return `${title} - ${filters.psc_location}`
      }
      if (type == "store_dc_ontimeservice") {
        return `${title} - ${filters.report_type}`
      }
    }
    let download = () => {
      let title = graphTitle()
      if (config?.report?.chart)
        domtoimage
          .toBlob(document.getElementById("reports"))
          .then(function (blob) {
            fileDownload(blob, `${title}-${format(new Date(), "P")}.png`)
          })
    }
    let reset = () => {
      gotoPage(1)
      setReportData([])
    }

    let xlsHeader = (header) => {
      header.eachCell({ includeEmpty: true }, (cell) => {
        let thinB = { style: "thin" }
        cell.alignment = { wrapText: true }
        cell.font = {
          bold: true,
        }

        cell.border = {
          bottom: thinB,
          top: thinB,
          right: thinB,
          left: thinB,
        }

        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "d9e8f4" },
        }
      })
    }

    const showErrorModel = (status) => {
      setShowModal(status)
    }

    const limitingRecords = async (totalRecords) => {
      let body
      body = await fetchData(totalRecords)
      let { data, columns, groups, total, extra = {} } = body
      let { cellColor = false } = extra
      let filename = `${title} - ${dateForApi(new Date())}.xlsx`
      let headersk = {}
      columns.forEach((d) => {
        d.key = d.accessor
        headersk[d.key] = d.Header
      })

      const workbook = new excel.Workbook()
      const worksheet = workbook.addWorksheet("Report")

      worksheet.columns = columns
      type === "annual_schedule_report"
        ? (worksheet.properties.defaultColWidth = 21)
        : (worksheet.properties.defaultColWidth = 15)
      let data2 = []
      let next

      data.forEach((d, i) => {
        if (data[i - 1] && type == "general_status") {
          next = data[i - 1].state
          if (next != d.state) {
            groups[next].header = true
            groups[next].customerName = `${next} Total`
            data2.push(groups[next])
          }
        }
        if (d.exceptions) {
          d.exceptions = d.exceptions.join("\n")
        }
        data2.push(d)
      })
      if (type == "general_status") {
        next = _.last(data).state
        if (next) {
          groups[next].header = true
          groups[next].customerName = `${next} Total`
          data2.push(groups[next])
        }
      }
      headersk.header = true
      data2.unshift(headersk)

      if (type == "general_status") {
        total.header = true
        total.state = "Grand Total"
        data2.push(total)
      }

      data2.forEach(function (d, i) {
        let row = worksheet.addRow(d)
        if (d.header) xlsHeader(row)
        else {
          row.eachCell((cell) => {
            if (cell._column._key.startsWith("20")) {
              if (!cellColor) return
              let color = cellColor(cell.value)
              if (color) {
                color = color.replace("#", "")
                cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: color },
                }
              }
            }
          })
        }
      })

      setReportDetails(workbook)
      const buffer = await workbook.xlsx.writeBuffer()
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

      const blob = new Blob([buffer], { type: fileType })

      saveAs(blob, filename)
    }

    const showErrorDownloadModel = (status) => {
      setDownloadModel(status)
    }
    const updatedDownloadOpts = async () => {
      let { totalRecords } = reportData

      if (totalRecords > 200000) {
        showErrorModel(true)
        return false
      } else if (totalRecords <= 40000) {
        await limitingRecords(totalRecords)
      } else if (
        totalRecords > 40000 &&
        totalRecords <= 200000 &&
        type === "annual_schedule_report"
      ) {
        await limitingRecords(totalRecords)
      } else {
        setDownloadModel(true)
      }
    }

    const setReportDetails = (workbook) => {
      const worksheet2 = workbook.addWorksheet("Details")
      worksheet2.properties.defaultColWidth = 17
      worksheet2.columns = [
        { accessor: "label", sticky: "left", key: "label" },
        { accessor: "value", sticky: "left", key: "value" },
      ]
      const { firstName, lastName } = user

      const appliedFilters = getAppliedFilters()
      var filteredArray
      if (isRbsCorporateUser()) filteredArray = appliedFilters
      else
        filteredArray = appliedFilters.filter(
          (pair) => pair.label !== "Brands:"
        )
      const generated_dt = format(new Date(), "MMM dd, yyyy HH:mm z")

      const data = [
        { label: title, title: true },
        { label: "" },
        { label: "Filters Applied:" },
        ...filteredArray,
        { label: "" },
        // { label: "Safe Harbor Statement Coming Soon" },
        {
          label: "Generated By:",
          value: ` ${firstName} ${lastName}`,
          isNextColumn: true,
        },
        { label: "Generated Date: ", value: generated_dt, isNextColumn: true },
        { label: "" },
        { label: "Clean Earth Connect+" },
        { label: "" },
      ]
      data.forEach((d, i) => {
        const row = worksheet2.addRow(d)
        if (d.isNextColumn) worksheet2.mergeCells(`B${i + 1}:E${i + 1}`)
        else worksheet2.mergeCells(`A${i + 1}:E${i + 1}`)
        if (d.title) xlsHeader(row)
      })
      createOuterBorder(
        worksheet2,
        { row: 1, col: 1 },
        { row: data.length, col: 2 },
        "thin"
      )
    }

    const getAppliedFilters = () => {
      let values = []
      const filterKeys = {
        divisions_regions: "Divisions Regions",
        fiscal_month_year: "Date",
        states: "States",
        stores: "Stores",
        brands: "Brands",
        time_presets: "Date",
        time_presets_month: "Date",
        time_presets_quarter: "Date",
        time_presets_lastquateryear: "Date",
        exception_type: "Exception Types",
        profile_list: "Profile Names",
        cities: "Cities",
        fiscal_span: "Date",
        time_present_future_year: "Year",
        service_type: "Service Types",
      }

      // eslint-disable-next-line
      for (const key in filters) {
        if (key != "orgStores") {
          let obj = {}
          if (filterKeys[key])
            if (filterKeys[key] !== "Date")
              obj = {
                label: `${filterKeys[key]}:`,
                value: Array.isArray(filters[key])
                  ? filters[key].join(",")
                  : filters[key],
              }
          if (key == "stores" && filters.orgStores?.length) {
            obj = {
              label: `${filterKeys[key]}:`,
              value: Array.isArray(filters[key])
                ? filters[key].join(",")
                : filters[key],
            }
            obj.isNextColumn = true
            values.push(obj)
          }
          if (
            filterKeys[key] == "Year" &&
            filters?.hasOwnProperty("time_present_future_year") &&
            key === "time_present_future_year"
          ) {
            obj = {
              label: "Year:",
              value: filters.time_present_future_year,
            }
            obj.isNextColumn = true
            values.push(obj)
          }
          if (
            filterKeys[key] == "Date" &&
            filters?.hasOwnProperty("fiscal_month_year") &&
            key === "fiscal_month_year"
          ) {
            obj = {
              label: "Date Range:",
              value: `From: ${formattedDate(filters[key].from)}  
              To: ${formattedDate(filters[key].to)}`,
            }
            obj.isNextColumn = true
            values.push(obj)
          }
          if (
            filterKeys[key] == "Date" &&
            !filters?.hasOwnProperty("fiscal_month_year") &&
            [
              "time_presets",
              "time_presets_month",
              "time_presets_quarter",
              "time_presets_lastquateryear",
            ].includes(key)
          ) {
            obj = {
              label: "Date Range:",
              value: `From: ${formattedDate(filters[key]?.from)}  
              To: ${formattedDate(filters[key]?.to)}`,
            }
            obj.isNextColumn = true
            values.push(obj)
          }
          if (key === "service_type" && !filters[`allSelect ${key}`]) {
            obj = {
              label: `${filterKeys[key]}:`,
              value: Array.isArray(filters[key])
                ? filters[key].join(",")
                : filters[key],
            }
            obj.isNextColumn = true
            values.push(obj)
          } else if (key === "service_type" && filters[`allSelect ${key}`]) {
            obj = {
              label: `${filterKeys[key]}:`,
              value: "All",
            }
            obj.isNextColumn = true
            values.push(obj)
          }
          if (key === "exception_type") {
            obj = {
              label: `${filterKeys[key]}:`,
              value: Array.isArray(filters[key])
                ? filters[key].join(",")
                : filters[key],
            }
            obj.isNextColumn = true
            values.push(obj)
          }
          if (key === "profile_list") {
            obj = {
              label: `${filterKeys[key]}:`,
              value: Array.isArray(filters[key])
                ? filters[key].join(",")
                : filters[key],
            }
            obj.isNextColumn = true
            values.push(obj)
          }
          if (key === "states" && filters[`allSelect ${key}`]) {
            obj = {
              label: `${filterKeys[key]}:`,
              value: "All",
            }
            obj.isNextColumn = true
            values.push(obj)
          }
          if (key === "divisions_regions" && filters[`allSelect ${key}`]) {
            obj = {
              label: `${filterKeys[key]}:`,
              value: "All",
            }
            obj.isNextColumn = true
            values.push(obj)
          }
          if (key === "divisions_regions" && !filters[`allSelect ${key}`]) {
            obj = {
              label: `${filterKeys[key]}:`,
              value: Array.isArray(filters[key])
                ? filters[key].join(",")
                : filters[key],
            }
            obj.isNextColumn = true
            values.push(obj)
          }
          if (key === "brands" && filters[`allSelect ${key}`]) {
            obj = {
              label: `${filterKeys[key]}:`,
              value: "All",
            }
            obj.isNextColumn = true
            values.push(obj)
          }
          if (key === "brands" && !filters[`allSelect ${key}`]) {
            obj = {
              label: `${filterKeys[key]}:`,
              value: Array.isArray(filters[key])
                ? filters[key].join(",")
                : filters[key],
            }
            obj.isNextColumn = true
            values.push(obj)
          }
          if (key === "stores" && filters[`allSelect ${key}`]) {
            obj = {
              label: `${filterKeys[key]}:`,
              value: "All",
            }
            obj.isNextColumn = true
            values.push(obj)
          }
          if (key === "states" && !filters[`allSelect ${key}`]) {
            obj = {
              label: `${filterKeys[key]}:`,
              value: Array.isArray(filters[key])
                ? filters[key].join(",")
                : filters[key],
            }
            obj.isNextColumn = true
            values.push(obj)
          }
          if (key === "stores" && !filters[`allSelect ${key}`]) {
            obj = {
              label: `${filterKeys[key]}:`,
              value: Array.isArray(filters[key])
                ? filters[key].join(",")
                : filters[key],
            }
            obj.isNextColumn = true
            values.push(obj)
          }
          if (key === "cities" && filters["allSelect"]) {
            obj = {
              label: `${filterKeys[key]}:`,
              value: "All",
            }
            obj.isNextColumn = true
            values.push(obj)
          }
          if (key === "cities" && !filters["allSelect"]) {
            obj = {
              label: `${filterKeys[key]}:`,
              value: Array.isArray(filters[key])
                ? filters[key].join(",")
                : filters[key],
            }
            obj.isNextColumn = true
            values.push(obj)
          }
        }
      }
      return values
    }

    const createOuterBorder = (
      worksheet,
      start = { row: 1, col: 1 },
      end = { row: 1, col: 1 },
      borderWidth = "medium"
    ) => {
      const borderStyle = {
        style: borderWidth,
      }
      for (let i = start.row; i <= end.row; i++) {
        const leftBorderCell = worksheet.getCell(i, start.col)
        const rightBorderCell = worksheet.getCell(i, end.col)
        leftBorderCell.border = {
          ...leftBorderCell.border,
          left: borderStyle,
        }
        rightBorderCell.border = {
          ...rightBorderCell.border,
          right: borderStyle,
        }
      }
      for (let i = start.col; i <= end.col; i++) {
        const topBorderCell = worksheet.getCell(start.row, i)
        const bottomBorderCell = worksheet.getCell(end.row, i)
        topBorderCell.border = {
          ...topBorderCell.border,
          top: borderStyle,
        }
        bottomBorderCell.border = {
          ...bottomBorderCell.border,
          bottom: borderStyle,
        }
      }
    }

    let updateDownload = async (v) => {
      let { totalRecords } = reportData
      if (
        type == "waste_summary_shipment_report" &&
        totalRecords > 40000 &&
        totalRecords <= 200000
      ) {
        let size = totalRecords
        //eslint-disable-next-line
        // let config2 = loadConfig()
        let {
          fiscal_month_year,
          states,
          divisions_regions,
          exception_type,
          profile_list,
        } = filters
        let req = {
          loading_key,
        }
        if (stores) {
          req.stores = stores.split(",")
          req.dates = [monthStart, monthEnd]
        } else {
          req.states = states
          req.regions = divisions_regions
          req.dates = [fiscal_month_year.from, fiscal_month_year.to]
        }
        if (size) req.size = size
        else req.index = pageNo

        if (exception_type) {
          req.exceptions = exception_type
        }

        if (profile_list) {
          req.profiles = [...profile_list]
        }

        if (filters.stores) req.stores = filters.stores
        req.userId = user.userId
        req.userName = user.firstName + " " + user.lastName
        req.cancelToken = cancelTokenSource.token
        req.connectionId = sRConId
        await api.p_wasteShipmentDownload(req)
      }
      updatedDownloadOpts()
    }

    const toggleExceptionReport = (path) => {
      reset()
      setSubmitted(false)
      history.replace(`/reportgenerator/${path}`)
    }

    return (
      <Component
        {...props}
        {...{
          reportData,
          config,
          title,
          download,
          submit,
          graphTitle,
          reset,
          downloadSelectOpts,
          updatedDownloadOpts,
          pageNo,
          gotoPage,
          submitted,
          summary,
          updateDownload,
          stores,
          type,
          toggleExceptionReport,
          showModel,
          showErrorModel,
          showDownloadModel,
          showErrorDownloadModel,
        }}
      />
    )
  }

export default enhancer
