import classnames from "classnames"

let ToggleButton = (props) => {
  const { onClickMethod, classname, active, btnText, clickValue } = props

  return (
    <button
      onClick={() => onClickMethod(clickValue)}
      className={classnames(" " + classname, {
        active_btn: active,
        // disable: isload,
      })}
    >
      {btnText}
    </button>
  )
}

export default ToggleButton
