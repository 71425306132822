import { SET_PROFILE, DESTORY_PROFILE } from "./actionType"

const profile = (state = {}, action) => {
  let newState = {}
  switch (action.type) {
    case SET_PROFILE:
      newState = {
        ...state,
        ...action.profile,
      }
      return newState
    case DESTORY_PROFILE:
      return newState

    default:
      return state
  }
}

export default profile
