import React, { useState, useEffect } from "react"
import api from "api"
import { toast } from "react-toastify"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    let [res, setRes] = useState()
    let [tResult, setTotalWeight] = useState()

    useEffect(() => {
      ;(async () => {
        let res = await api.g_dashboardTreamentTypeTiles({
          loading_key: "quarter-overview",
        })

        let tResult = await api.g_dashboardTotalWeightTile({
          loading_key: "quarter-overview",
        })
        if (!res) return
        if (tResult?.totalWeight === null || tResult?.totalWeight === 0) {
          toast.warn(
            "Data is not available at this time.  Please contact support or your Fullcircle program manager for more information."
          )
        }
        setRes(res)
        setTotalWeight(tResult)
      })()
    }, [])

    return <Component {...props} {...{ res, tResult }} />
  }

export default enhancer
