import React from "react"
import Image from "shared/HelpImage/index"

function FourInformationTiles() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Dashboard: Four information Tiles</h3>

      <p className="text-justify pl-3 pt-2">
        The four tiles in this section each display a measure of their
        respective metric for the current week, the last 30 days or the last 90
        days. In the lower left-hand corner of each tile is a percentage and an
        arrow. This indicates a comparison from the past period to the current
        period being viewed. It indicates if the metric has gone up or down, and
        by what percentage. To the right of the comparison is a graph showing
        the metric over the time period: daily for the current week, weekly for
        the past 30 days and monthly for the past 90 days. You can select the
        time period for which you want to view the tiles by choosing Weekly,
        Last 30 Days, or Last 90 days.
      </p>
      <div className="img-section">
        <Image src="InformationTiles.png" alt="" />
      </div>
      <p className="text-justify pl-3 pt-2">
        <b>Weight picked up tile</b> – This shows the combined weight of all the
        hazardous and non-hazardous waste picked up during the specified time
        period. Note that the weight has been converted into pounds. Clicking on
        the Weight Picked Up tile will display a more detailed Total Waste
        Overview chart comparing this week to the previous week. The detailed
        chart will not be shown if the time period is Last 30 days or Last 90
        days.
      </p>
      <div className="img-section">
        <Image src="total_waste_overview.png" alt="" />
      </div>
      <p className="text-justify pl-3 pt-2">
        <b>Estimated Total Spend tile</b> – This shows the estimated amount to
        be charged for the waste-only portion of the pickups completed for the
        specified time period. It is calculated beginning at 8:00 am EST at the
        beginning of the time period. <br />
        Clicking on the Estimated Total Spend tile will display a more detailed
        Estimated Total Spend Overview chart comparing this week to the previous
        week. The detailed chart will not be shown if the time period is Last 30
        days or Last 90 days.
      </p>
      <div className="img-section">
        <Image src="estimated_total_spends_overview.png" alt="" />
      </div>
      <p className="text-justify pl-3 pt-2">
        <b>Estimated Scheduled Stops </b> –This shows the total number of
        estimated stops for the specified time period. It is calculated
        beginning from 8:00 am EST on Monday of the specified time period.
        <br />
        Clicking on the Estimated Scheduled Stops tile will display a more
        detailed Estimated Scheduled Stops Overview chart comparing this week to
        the previous week. The detailed chart will not be shown if the time
        period is Last 30 days or Last 90 days.
      </p>
      <div className="img-section">
        <Image src="estimated_scheduled_stops_overview.png" alt="" />
      </div>
      <p className="text-justify pl-3 pt-2">
        <b>Stops Completed</b> – This shows the number of pickups completed for
        the specified time period. A pickup means the waste has been picked up
        and the generator has signed the manifest.
        <br />
        Clicking on the Stops Completed tile will display a more detailed Stops
        Completed Overview chart comparing this week to the previous week. The
        detailed chart will not be shown if the time period is Last 30 days or
        Last 90 days.
      </p>
      <div className="img-section">
        <Image src="stops_completed_overview.png" alt="" />
      </div>
    </div>
  )
}
export default FourInformationTiles
