import React, { useState, useEffect } from "react"
import { formattedDate } from "shared"
import { useHistory } from "react-router-dom"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    const quaters = [
      { name: "Q1", d: "Jan 01", o: 1 },
      { name: "Q2", d: "Apr 01", o: 2 },
      { name: "Q3", d: "Jul 01", o: 3 },
      { name: "Q4", d: "Oct 01", o: 4 },
    ]
    let [areaChartData, setAreaChartData] = useState([
      { name: "" },
      { name: "" },
      { name: "" },
      { name: "" },
    ])
    let [currentSlide, setCurrentSlide] = useState(0)
    // let { periodText } = useQuarterDates()
    let [PeriodText, setPeriodText] = useState()

    let dates = []
    let history = useHistory()

    useEffect(() => {
      ;(async () => {
        let { res } = props
        if (!res) return
        setPeriodText(
          `(${formattedDate(res?.fromDate)} - ${formattedDate(res?.todate)})`
        )

        let oldQuater = quaters.filter((ele) =>
          formattedDate(res?.fromDate).includes(ele.d)
        )
        let order = oldQuater[0].o
        let orderQuaters = quaters.filter((ele) => ele.o >= order)

        orderQuaters.forEach((ele) => {
          // formatting name Quater followed by year
          // e.g name = Q1-2023
          ele.name = `${ele.name} -${
            formattedDate(res?.fromDate).split(",")[1]
          }`
        })

        let newQuaters = quaters.filter((ele) => ele.o < order)

        newQuaters.forEach((ele) => {
          // formatting name Quater followed by year
          // e.g name = Q1-2023
          ele.name = `${ele.name} -${formattedDate(res?.todate).split(",")[1]}`
        })

        let newArray = [...orderQuaters, ...newQuaters]

        let areaData = res.treatementTypeChart
        const areaMap = new Map()

        //clubing every quater in one object using map
        areaData.forEach((ele) => {
          // checking whether qauter details are present or not
          if (areaMap.has(ele["quarterName"])) {
            // fetching details quater
            let obj = areaMap.get(ele["quarterName"])
            obj = [...obj, ele]

            //  updating the quater details
            areaMap.set(ele["quarterName"], obj)
          } else {
            areaMap.set(ele["quarterName"], [ele])
          }
        })
        let array = [...areaMap.entries()]

        newArray.forEach((element, index) => {
          let quaterTreaments = array.filter((ele) =>
            element["name"].includes(ele[0])
          )
          areaChartData[index].name = `${element.name}`
          if (quaterTreaments.length > 0) {
            quaterTreaments[0][1].forEach((ele) => {
              ele.netWeight = Math.trunc(ele?.netWeight)
              setValue(ele, index)
            })
          }
        })
        setAreaChartData(areaChartData)
        dates = [new Date(res?.fromDate), new Date(res?.todate)]
      })()
    }, [props])

    let setValue = (element, index) => {
      let weight = element.netWeight
      switch (element.treatmentType) {
        case "Incineration":
          areaChartData[index].INCINERATION = weight
          break
        case "Landfill":
          areaChartData[index].LANDFILL = weight
          break
        case "Recycle/Reuse":
          areaChartData[index].RECYCLE = weight
          break
        case "Waste to Energy":
          areaChartData[index].WTE = weight
          break
        default:
          break
      }
    }

    let invoiceReportPage = () => {
      let [from, to] = dates
      return history.push({
        pathname: "/reportgenerator/invoice_report",
        state: { fiscal_span: { from, to } },
      })
    }

    return (
      <Component
        {...props}
        {...{
          areaChartData,
          currentSlide,
          setCurrentSlide,
          PeriodText,
          invoiceReportPage,
        }}
      />
    )
  }

export default enhancer
