import React from "react"
import enhancer from "./enhancer"
import { FormattedMessage } from "react-intl"
import LeftPanel from "./Components/LeftPanel"
import RightPanel from "./Components/RightPanel"
import Loading from "shared/Loading"
import Pagination from "shared/Pagination"

function Services(props) {
  let { serviceData, pageNo, gotoPage } = props
  let { totalRecords = 0 } = serviceData
  return (
    <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0 ">
      <Loading id="schedules-services" height="400">
        <div className={`row heading-label  `}>
          <div className="col-3 label-name">
            <FormattedMessage id="myschedule.history" />{" "}
            <span className="schedule-count">
              {!!totalRecords && totalRecords > 1 ? (
                <FormattedMessage
                  id="myschedule.schedules_countHistory"
                  values={{ count: totalRecords }}
                />
              ) : (
                <FormattedMessage
                  id="myschedule.schedules_countHistory"
                  values={{ count: totalRecords }}
                />
              )}
            </span>
          </div>
        </div>
        <div className="row m-0 service-section">
          {totalRecords == 0 && (
            <div className="col-sm-12 col-md-12 col-lg-12 text-center pt-2 nodatamsg">
              <FormattedMessage id="nodata.schedules" />
            </div>
          )}
          {totalRecords > 0 && (
            <>
              <LeftPanel {...props} />
              <RightPanel {...props} />
              <Pagination
                {...{ pageNo, totalRecords, gotoPage, data: serviceData }}
              />
            </>
          )}
        </div>
      </Loading>
    </div>
  )
}
export default enhancer(Services)
