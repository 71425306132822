import React, { useState, useEffect } from "react"
import * as dfns from "date-fns"
import api from "api"
import _ from "lodash"
import { useHistory } from "react-router-dom"
import { formattedDate, useToday } from "shared"
import { useSelector } from "react-redux"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    let today = useToday()
    let [type, setType] = useState("day")
    let [PeriodText, setPeriodText] = useState()
    let [docs, setDocs] = useState([])
    let [dates, setDates] = useState([])
    let { docTypes } = useSelector((state) => state.config)
    let history = useHistory()

    useEffect(() => {
      ;(async () => {
        if (_.isEmpty(docTypes)) return

        let n_dates = []
        if (type == "day") {
          n_dates = [today, today]
          setPeriodText(``)
        }
        if (type == "week") {
          n_dates = [dfns.startOfWeek(today), dfns.endOfWeek(today)]

          setPeriodText(
            `From  ${formattedDate(n_dates[0], false)} to ${formattedDate(
              n_dates[1],
              false
            )}`
          )
        }
        if (type == "month") {
          n_dates = [dfns.startOfMonth(today), dfns.endOfMonth(today)]
          setPeriodText(`For ${dfns.format(n_dates[0], "MMM YYY")}`)
        }

        setDates(n_dates)

        let res = await api.g_documentTabOverView({
          dates: n_dates,
          loading_key: "documents-tab",
        })
        if (!res) return
        else setDocs(res)
      })()
    }, [type, docTypes])

    const getDocuments = (document) => {
      return document.map((d) => d?.name)
    }

    const navigate = (document_type) => {
      let [from, to] = dates
      history.push("/documentlibrary", {
        document_type,
        fiscal_span: { from, to },
        documentTypes: getDocuments(docs),
      })
    }

    return (
      <Component
        {...props}
        {...{ type, setType, PeriodText, docs, navigate }}
      />
    )
  }

export default enhancer
