import React, { useEffect } from "react"
import api from "api"
import { syncUserProfile } from "shared"
import {
  storeQuickTour,
  adminQuickTour,
  Popover,
  Popoverstore,
} from "./quickTourSteps"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    let {
      setIsTourOpen,
      setIsQuickTourCompleted,
      setIsPopOverTour,
      profile,
      isStoreManager,
    } = props
    const quickTourSteps = isStoreManager ? storeQuickTour : adminQuickTour

    const popoverSteps = isStoreManager ? Popoverstore : Popover

    const { userPreferences } = profile
    const { is_quickTour_completed, is_tonsConversion_updated } =
      JSON.parse(userPreferences)
    const completedQuickTour = async () => {
      setIsTourOpen(false)
      setIsQuickTourCompleted(true)
      document.body.style.overflowY = "auto"
      let values = {}
      values["is_quickTour_completed"] = true
      values["is_tonsConversion_updated"] = is_tonsConversion_updated
      let formData = {
        email: profile.email,
      }
      let res = await api.pa_user({
        loading_key: "updateuser",
        ...formData,
        userPreferences: JSON.stringify(values),
      })
      if (!res) return res
      await syncUserProfile()
    }

    const startFeatures = () => {
      setIsPopOverTour(false)
      setIsTourOpen(true)
    }

    useEffect(() => {
      setIsQuickTourCompleted(is_quickTour_completed ? true : false)
    }, [profile])

    return (
      <Component
        {...props}
        {...{
          completedQuickTour,
          startFeatures,
          quickTourSteps,
          popoverSteps,
        }}
      />
    )
  }
export default enhancer
