import { SET_CONFIG, DELETE_CONFIG } from "./actionType"

const config = (state = {}, action) => {
  let newState = {}
  switch (action.type) {
    case SET_CONFIG:
      newState = {
        ...state,
        ...action.config,
      }
      return newState
    case DELETE_CONFIG:
      return newState
    default:
      return state
  }
}

export default config
