import React, { useEffect, useState } from "react"
import Image from "shared/Image"
import Modal from "shared/Modal"
import { syncUserProfile, useHarsco } from "shared"
import api from "api"
import Loading from "shared/Loading"
import { FormattedMessage } from "react-intl"
import Editor from "../../routes/Admin/Components/Editor"

const Announcements = (props) => {
  //'announcementsToShow' is new announcements which is user has not seen
  //'allAnnouncements' is all announcement falling between the date range
  const {
    announcementsToShow,
    open,
    setAnnouncements,
    tickerAnnouncements = 0,
    allAnnouncements,
  } = props
  let msgCount = tickerAnnouncements + 1

  const [announcements, setAnnouncement] = useState(announcementsToShow)
  const [messageCount, setMessageCount] = useState(1)

  const { profile } = useHarsco()
  const user = profile

  const getDescription = () => {
    return (
      <div className="announcements-description">
        <Editor
          readOnly={true}
          value={announcements[messageCount - 1]?.formattedDescription}
        />
      </div>
    )
  }

  const updateAnnouncements = async () => {
    if (
      user?.lastViewedAnnouncement !==
      announcements[announcements.length - 1]?.id
    ) {
      setAnnouncements(false)
      let res = await api.pa_updateLastViewedAnnnouncement({
        announcementId: announcements[announcements.length - 1]?.id,
        loading_key: "announcement_loading",
      })
      if (res === 1) {
        let newProfile = await syncUserProfile("announcement_loading")
        if (newProfile) {
          setAnnouncements(false)
        }
      }
      document.body.style.overflowY = "auto"
    } else {
      setAnnouncements(false)
      document.body.style.overflowY = "auto"
    }
  }

  useEffect(() => {
    if (announcementsToShow.length === 0) {
      setAnnouncement(allAnnouncements)
      setMessageCount(msgCount)
    }
  }, [msgCount])

  return (
    <div className="announcementretail">
      <Modal show={open} wrapperclass={"announcements-mockup"}>
        <div className="cal-100-20">
          <div className="announcements-title-logo">
            <Image
              className="logoimg anouncement-logo"
              src="logo_Clean_earth.svg"
              alt=""
            />
          </div>
          <Loading id={"announcement_loading"}>
            <div className="announcements-children">
              <div className="row">
                <div className="col-8 announcements-title">
                  <span style={{ width: "25px!important" }}>
                    <FormattedMessage id="announcement.heading" />
                  </span>
                </div>
                <div
                  className="col-4 text-right"
                  style={{ paddingTop: "20px" }}
                >
                  <span className="announcement-count">{`${messageCount}  of  ${announcements.length}`}</span>
                  <Image
                    className={`${
                      messageCount === 1
                        ? "announcements-pointer-disabled"
                        : "announcements-pointer"
                    } m-r-5`}
                    src={"forward_icon.svg"}
                    height="18px"
                    width="18px"
                    alt=""
                    onClick={() => {
                      setMessageCount(messageCount - 1)
                    }}
                  />
                  <Image
                    className={`${
                      messageCount === announcements.length
                        ? "announcements-pointer-disabled"
                        : "announcements-pointer"
                    } m-r-5`}
                    src={"backward_icon.svg"}
                    height="18px"
                    width="18px"
                    alt=""
                    onClick={() => {
                      setMessageCount(messageCount + 1)
                    }}
                  />
                </div>
              </div>
              {announcements.length > 0 && getDescription()}
              <div>
                <div className="announcements-dots pad-tb-10">
                  {announcements.map((ele, i) => {
                    return (
                      <div
                        key={i}
                        className="pad-l-5"
                        onClick={() => {
                          setMessageCount(i + 1)
                        }}
                      >
                        <div
                          className={
                            i === messageCount - 1
                              ? "announcementdot announcementdotactive"
                              : "announcementdot announcementdotinactive"
                          }
                        ></div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="text-center">
                <button
                  className="announcements-ok-button announcements-pointer"
                  onClick={() => updateAnnouncements()}
                >
                  OK
                </button>
              </div>
            </div>
          </Loading>
        </div>
      </Modal>
    </div>
  )
}

export default Announcements
