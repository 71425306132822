import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useDocumentTitle } from "shared"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    const { caseId } = useParams()
    const { serviceReqHistory, serviceReqSupplies } = useSelector(
      (state) => state.config
    )
    const [title, setTitle] = useState("")
    useDocumentTitle(title)
    const data = serviceReqHistory[caseId]
    if (data.suppliesRequested) {
      let rerSupplies = {}
      let supplies = []
      serviceReqSupplies.forEach((d) => {
        rerSupplies[d.supply_code] = d.supply_description
      })
      data.suppliesRequested.split(",").forEach((d) => {
        let [item = "", qty = ""] = d.split("-")
        supplies.push({ item: rerSupplies[item.trim()] || item, qty })
      })
      data.requestSupplies = supplies
    }
    if (data.additional_Emails__c)
      data.additional_Emails__c = data.additional_Emails__c
        .split(";")
        .map((email) => email.trim())
        .join("; ")
        .replace(/([^;]);([^ ]|$)/g, "$1; $2")

    useEffect(() => {
      setTitle(`${caseId} - ${data.createdDate}`)
    }, [])

    return (
      <Component
        {...props}
        {...{
          data,
          // contactName,
        }}
      />
    )
  }

export default enhancer
