import React from "react"
import Image from "shared/HelpImage/index"

function FilterFunctionality() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Filter Functionality</h3>
      <p className="text-justify pl-3 pt-2">
        When the user clicks on the ‘Stores’ dropdown to select the stores, they
        see the message to select only 20 stores per query.
      </p>
      <div className="img-section">
        <Image src="filter_functionality_1.png" alt="" />
      </div>
      <p className="text-justify pl-3 pt-2">
        When the user selects more than 20 stores, a pop-up message is displayed
        on the screen with the message “You can choose up to 20 stores per
        query”
      </p>
      <div className="img-section">
        <Image src="filter_functionality_2.png" alt="" />
      </div>
      <p className="text-justify pl-3 pt-2">
        When the user selects a state and clicks on apply button without
        selecting any store, a pop-up message is displayed on the screen with
        the message “Please select at least one store to proceed”
      </p>
      <div className="img-section">
        <Image src="filter_functionality_3.png" alt="" />
      </div>
    </div>
  )
}

export default FilterFunctionality
