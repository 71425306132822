import React, { useEffect, useState } from "react"
import {
  individualLogin,
  corporateLogin,
  useMsal,
  handleRedirectPromise,
  individualSSOLogin,
} from "msal"
import { useSelector } from "react-redux"
import { useLocation } from "react-router"

let enhancer = (Component) => (props) => {
  const { hash } = useLocation()
  const { login_status } = useSelector((state) => state.loading)
  const [loading, setLoading] = useState(
    hash && !hash.includes("#error") ? true : false
  )

  const [email, setEmail] = useState("")
  const [error, setError] = useState(false)
  handleRedirectPromise()
  useMsal()

  useEffect(() => {
    if (typeof login_status == "boolean") setLoading(login_status)
  }, [login_status])

  async function signInB2C(ssoLogin = false, brandname = "") {
    setLoading(true)
    if (ssoLogin) {
      await individualSSOLogin(brandname)
    } else {
      await individualLogin(email)
    }
  }

  async function signInB2B() {
    setLoading(true)
    await corporateLogin()
  }

  let onChange = (value) => {
    setEmail(value)
    if (value == "") setError(false)
  }

  const CheckIfSSOLogin = () => {
    const urlArray = [
      "https://ceinp-dev-cusportal-ui.azurewebsites.net",
      "https://ceinp-qa-cusportal-ui.azurewebsites.net",
      "https://ceinp-demo-cusportal-ui.azurewebsites.net",
      "https://connectplus.cleanearthinc.com",
      "https://customerportal-dev.cleanearthinc.com",
      "https://customerportal-qa.cleanearthinc.com",
      "https://customerportal-demo.cleanearthinc.com",
      "https://customerportal.cleanearthinc.com",
    ]
    const currentHostName = window.location.hostname
    // eslint-disable-next-line
    for (const url of urlArray) {
      const urlObject = new URL(url)
      if (urlObject.hostname === currentHostName) {
        return true
      }
    }
    return false
  }

  var isSSOLoginButton = CheckIfSSOLogin()

  const onSubmit = (e) => {
    let isValidEmail = email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    e.preventDefault()
    if (isValidEmail) signInB2C()
    else setError(true)
  }
  const onSSoSubmit = (e) => {
    e.preventDefault()
    var currenthost = window.location.hostname
    var brandname = currenthost.split(".")[0]
    //var app1=new URL('https://harsco.azurewebsites.net');
    //var app2=new URL('https://homedepot-qa.azurewebsites.net');

    if (brandname.includes("-")) {
      brandname = brandname.split("-")[0]
    }

    signInB2C(true, brandname)
  }

  return (
    <Component
      {...props}
      {...{
        signInB2B,
        isSSOLoginButton,
        signInB2C,
        loading,
        setEmail,
        onSubmit,
        onSSoSubmit,
        error,
        onChange,
      }}
    />
  )
}

export default enhancer
