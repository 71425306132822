import React from "react"

const TextArea = (props) => {
  const {
    placeholder,
    name,
    value,
    onFieldChange,
    clsName = "",
    parentCls = "",
    disabled = false,
    rows = 4,
    columns = 6,
    maxLength = 1000,
    noMaxLength = false,
  } = props
  const characterCount = value.length ? value.length : 0
  return (
    <div
      className={`col-sm-12 col-md-12 col-lg-12 ${
        parentCls.length > 0 ? parentCls : "textarea-text p-0"
      } dp-flx`}
    >
      <textarea
        placeholder={placeholder}
        name={name}
        className={`col-sm-12 col-md-12 col-lg-12 ${
          clsName.length > 0 ? clsName : "input-box"
        }`}
        style={{ height: "auto", resize: "none", padding: 10 }}
        rows={rows}
        cols={columns}
        value={value}
        onChange={(e) => onFieldChange(e)}
        disabled={disabled}
        maxLength={noMaxLength ? 524288 : maxLength}
      />
      {!noMaxLength && (
        <span
          className="d-flex"
          style={{ justifyContent: "flex-end", fontSize: 11 }}
        >
          Maximum {characterCount}/{maxLength} characters allowed
        </span>
      )}
    </div>
  )
}

export default React.memo(TextArea)
