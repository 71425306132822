/* eslint react/style-prop-object: "off" */
import { FormattedMessage, FormattedNumber } from "react-intl"
import enhancer from "./enhancer"
import Loading from "shared/Loading"
import {
  AreaChartNFC,
  AreaChartLite,
  BarChartLite,
  RadialChartLite,
  BarChart,
} from "routes/Dashboard/components/charts"

import Image from "shared/Image"
import ComingSoon from "shared/ComingSoon"
import { useIsTablet, formattedDate } from "shared"
import ToggleButton from "shared/ToggleButton"

let TrendArrow = ({ trend }) => (
  <Image
    className="limg"
    src={trend == "Down" ? "ic_down_arrow.svg" : "ic_high_arrow.svg"}
    height="12px"
    width=""
    alt=""
  />
)

let TrendArrows = ({ trend }) => (
  <Image
    className="limg"
    src={trend == "Down" ? "ic_down_arrow-Copy.svg" : "ic_high_arrow-Copy.svg"}
    height="12px"
    width=""
    alt=""
  />
)

let WeeklyOverview = (props) => {
  let {
    resetActiveState,
    is,
    onViewDetails,
    overViewData,
    //weekDates,
    showTotalSpend,
    ScheduledStopsHidden,
    overviewTypes,
    changeActiveOverview,
    activeDuration,
    activateChartOnToggle,
    showEstimatedtotalspent,
  } = props

  let isTablet = useIsTablet()
  let xKey = activeDuration?.xKey

  let weekDates = {
    chartLabel: activeDuration?.chartLabel,
    from: formattedDate(activeDuration?.currentFromDate, false),
    to: formattedDate(activeDuration?.currentToDate, false),
    chartLabelCurrent: activeDuration?.legends[0]?.label,
    chartLabelPrevious: activeDuration?.legends[1]?.label,
    headerLabel: activeDuration?.dashboardHeader,
  }

  return (
    <Loading id="weekly-overview">
      {overViewData && overViewData.weightPickedUp ? (
        <div className="row weekly-overview">
          <div className="col-sm-12 col-md-12 col-lg-12 text-left pr-0">
            <label className="weeklylabel ">
              <FormattedMessage id={weekDates.headerLabel} />
            </label>
            <span className="mg-l"></span>
            <div className="btn-group overview-action">
              <ToggleButton
                onClickMethod={changeActiveOverview}
                classname={" btn-light weekly"}
                active={overviewTypes.weekly.active}
                btnText="Weekly"
                clickValue="weekly"
              />
              <ToggleButton
                onClickMethod={changeActiveOverview}
                classname={" btn-light last30days"}
                active={overviewTypes.last30Days.active}
                btnText="Last 30 days"
                clickValue="last30Days"
              />
              <ToggleButton
                onClickMethod={changeActiveOverview}
                classname={" btn-light last90days"}
                active={overviewTypes.last90Days.active}
                btnText="Last 90 days"
                clickValue="last90Days"
              />
            </div>
            <div className="row rowtile">
              <div className="col-sm-3 col-md-3 col-lg-3 p-0 pr-2">
                <div
                  className={`tile pl-3 ${
                    activeDuration?.label == "weekly" && "ptr"
                  }`}
                  onClick={() =>
                    activateChartOnToggle(
                      "weightPickedUp",
                      activeDuration.label
                    )
                  }
                >
                  <div className="labelname">
                    <FormattedMessage id="dashboard.weight" />
                  </div>
                  <div className="tileQty">
                    <FormattedNumber
                      value={overViewData?.weightPickedUp?.total}
                      notation="compact"
                    />
                    <sup>
                      <FormattedMessage id="dashboard.lbs" />
                    </sup>{" "}
                  </div>
                  <div className="percentage row">
                    <div
                      className={`${
                        isTablet
                          ? "col-sm-2 col-md-2 col-lg-2 pr-0 percelineheight"
                          : "col-sm-3 col-md-3 col-lg-3 pr-0 percelineheight"
                      }`}
                    >
                      <FormattedNumber
                        value={overViewData?.weightPickedUp?.perc}
                        style="percent"
                      />
                      <span className="dashbaord-asterik d-none">
                        <FormattedMessage id="dashboard.asterik" />
                      </span>
                      <span className="arrows">
                        <TrendArrow
                          trend={overViewData?.weightPickedUp?.trend}
                        />
                      </span>
                    </div>

                    <div
                      className={`${
                        isTablet
                          ? "col-sm-10 col-md-10 col-lg-10 pr-0 minigraph rescale text-center"
                          : "col-sm-9 col-md-9 col-lg-9 pr-0 minigraph rescale text-center"
                      }`}
                    >
                      <AreaChartLite
                        data={overViewData?.weightPickedUp?.tileChart}
                        height={75}
                        config={{
                          x: "day",
                          y: { key: "cur", label: "Current Week" },
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={`arrow-down ${
                      is("weightPickedUp") ? "d-block" : "d-none"
                    }`}
                  ></div>
                </div>
              </div>
              {showTotalSpend && showEstimatedtotalspent && (
                <div className="col-sm-3 col-md-3 col-lg-3 pr-2 pl-0">
                  <div
                    className={`tile one pl-3 ${
                      activeDuration?.label == "weekly" && "ptr"
                    }`}
                    onClick={() =>
                      activateChartOnToggle("totalSpends", activeDuration.label)
                    }
                  >
                    <div className="labelname">
                      <FormattedMessage id="dashboard.totalspend" />
                    </div>
                    <div className="tileQty">
                      <sup>
                        <FormattedMessage id="dashboard.doller" />
                      </sup>
                      <FormattedNumber
                        value={overViewData?.totalSpends?.total}
                        notation="compact"
                      />
                    </div>
                    <div className="percentage row">
                      <div
                        className={`${
                          isTablet
                            ? "col-sm-3 col-md-3 col-lg-3 percelineheight"
                            : "col-sm-4 col-md-4 col-lg-4 percelineheight"
                        }`}
                      >
                        {" "}
                        <FormattedNumber
                          value={overViewData?.totalSpends?.perc}
                          style="percent"
                        />
                        <span className="dashbaord-asterik d-none">
                          <FormattedMessage id="dashboard.asterik" />
                        </span>
                        <span className="arrows">
                          <TrendArrow
                            trend={overViewData?.totalSpends?.trend}
                          />
                        </span>
                      </div>

                      <div
                        className={`${
                          isTablet
                            ? "col-sm-9 col-md-9 col-lg-9  minigraph rescale text-center bar"
                            : "col-sm-8 col-md-8 col-lg-8  minigraph rescale text-center bar"
                        }`}
                      >
                        <BarChartLite
                          data={overViewData?.totalSpends?.tileChart}
                          height={75}
                          config={{
                            x: "day",
                            y: { key: "cur", label: "Current Week" },
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className={`arrow-downsec ${
                        is("totalSpends") ? "d-block" : "d-none"
                      }`}
                    ></div>
                  </div>
                </div>
              )}
              {!ScheduledStopsHidden && (
                <div className="col-sm-3 col-md-3 col-lg-3 pr-2 pl-0">
                  <div
                    className={`tile two pl-3 ${
                      activeDuration?.label == "weekly" && "ptr"
                    }`}
                    onClick={() =>
                      activateChartOnToggle(
                        "serviceStops",
                        activeDuration.label
                      )
                    }
                  >
                    <div className="labelname">
                      <FormattedMessage id="dashboard.servicestops" />
                    </div>
                    <div className="tileQty">
                      <FormattedNumber
                        value={overViewData?.serviceStops?.total}
                      />
                      <sup></sup>{" "}
                    </div>
                    <div className="percentage row">
                      <div
                        className={`${
                          isTablet
                            ? "col-sm-2 col-md-2 col-lg-2 percelineheight"
                            : "col-sm-3 col-md-3 col-lg-3 percelineheight"
                        }`}
                      >
                        {" "}
                        <FormattedNumber
                          value={overViewData?.serviceStops?.perc}
                          style="percent"
                        />
                        <span className="dashbaord-asterik d-none">
                          <FormattedMessage id="dashboard.asterik" />
                        </span>
                        {overViewData?.serviceStops?.perc === 0 ? (
                          ""
                        ) : (
                          <span className="arrows">
                            <TrendArrow
                              trend={overViewData?.serviceStops?.trend}
                            />
                          </span>
                        )}
                      </div>
                      <div
                        className={`${
                          isTablet
                            ? "col-sm-10 col-md-10 col-lg-10  minigraph rescale text-center ml-25"
                            : "col-sm-9 col-md-9 col-lg-9  minigraph rescale text-center"
                        }`}
                      >
                        <AreaChartLite
                          data={overViewData?.serviceStops?.tileChart}
                          height={75}
                          type="monotone"
                          config={{
                            x: "day",
                            y: { key: "cur", label: "Current Week" },
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className={`arrow-downth ${
                        is("serviceStops") ? "d-block" : "d-none"
                      }`}
                    ></div>
                  </div>
                </div>
              )}
              <div className="col-sm-3 col-md-3 col-lg-3 p-0 pr-2 ">
                <div
                  className={`tile three pl-3 ${
                    activeDuration?.label == "weekly" && "ptr"
                  }`}
                  onClick={() =>
                    activateChartOnToggle(
                      "stopsCompleted",
                      activeDuration.label
                    )
                  }
                >
                  <div className="labelname">
                    <FormattedMessage id="dashboard.stopscompelet" />
                  </div>
                  <div className="tileQty">
                    <FormattedNumber
                      value={overViewData?.stopsCompleted?.total}
                    />
                    <sup></sup>{" "}
                  </div>
                  <div className="percentage row ">
                    <div className="col-sm-7 col-md-7 col-lg-7 pr-0 percelineheight ">
                      {" "}
                      <FormattedNumber
                        value={overViewData?.stopsCompleted?.perc}
                        style="percent"
                      />{" "}
                      <FormattedMessage id="dashboard.completion" />
                    </div>
                    <div className="col-sm-5 col-md-5 col-lg-5 p-0 minigraph radial-lite text-left">
                      <RadialChartLite
                        percentage={overViewData?.stopsCompleted?.perc * 100}
                        height={75}
                      />
                    </div>
                  </div>
                  <div
                    className={`arrow-downfourth 
                          ${is("stopsCompleted") ? "d-block" : "d-none"}`}
                  ></div>
                </div>
              </div>
            </div>
            <div
              className={`row ml-0 mr-0  mb-3 mt-2  
              ${
                is("weightPickedUp")
                  ? "showgraph"
                  : is("stopsCompleted")
                  ? " showgraph fourth"
                  : is("serviceStops")
                  ? " showgraph three"
                  : is("totalSpends")
                  ? "showgraph second"
                  : ""
              }`}
            >
              <div
                className={`col-sm-12 col-md-12 col-lg-12 
              ${is("weightPickedUp") ? "d-block" : "d-none"}`}
              >
                <div className="">
                  <div className="row heading">
                    <div className="col-sm-11 col-md-11 col-lg-11 text-left pr-0">
                      <div className="">
                        <span className="lname">
                          {" "}
                          <FormattedMessage id="dashboard.totalwaste" />
                        </span>
                        <span className="larrow d-none"> |</span>
                        <span className="lper">
                          <span className="lpervalue">
                            <FormattedNumber
                              value={overViewData?.weightPickedUp?.perc}
                              style="percent"
                            />
                          </span>
                          <span className="dashbaord-asterik d-none">
                            <FormattedMessage id="dashboard.asterik" />
                          </span>
                        </span>
                        <span className="tilearrow">
                          <TrendArrows
                            trend={overViewData?.weightPickedUp?.trend}
                          />
                        </span>
                        <span className="ldesc">
                          {activeDuration.label === "weekly" && (
                            <FormattedMessage
                              id="dashboard.previousweek"
                              values={weekDates}
                            />
                          )}
                          {activeDuration.label !== "weekly" && (
                            <FormattedMessage
                              id="dashboard.previous30Or90Days"
                              values={weekDates}
                            />
                          )}
                        </span>
                        <span
                          className="lviewdeatails tooltips d-none"
                          onClick={onViewDetails("weightPickedUp")}
                        >
                          <FormattedMessage id="dashboard.viewdetails" />
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-1 col-md-1 col-lg-1 text-right">
                      <span className="closed" onClick={resetActiveState}>
                        <Image
                          className=""
                          src="ic_close.svg"
                          height="24px"
                          width="24"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                  {is("weightPickedUp") &&
                    activeDuration?.label == "weekly" && (
                      <AreaChartNFC
                        data={overViewData?.weightPickedUp?.chart}
                        height={250}
                        config={{
                          x: xKey,
                          y: [
                            { key: "cur", label: "Current Week" },
                            { key: "prev", label: "Previous Week" },
                          ],
                          yUnit: "lbs",
                          yDomain: [0, 100],
                        }}
                      />
                    )}
                </div>
              </div>

              <div
                className={`col-sm-12 col-md-12 col-lg-12 
                   ${is("totalSpends") ? "d-block" : "d-none"}`}
              >
                <div className="">
                  <div className="row heading">
                    <div className="col-sm-11 col-md-11 col-lg-11 text-left pr-0">
                      <div className="">
                        <span className="lname">
                          {" "}
                          <FormattedMessage id="dashboard.totalspendoverview" />
                        </span>
                        <span className="larrow d-none"> |</span>
                        <span className="lper">
                          <span className="lpervalue">
                            <FormattedNumber
                              value={overViewData?.totalSpends?.perc}
                              style="percent"
                            />
                          </span>
                          <span className="dashbaord-asterik d-none">
                            <FormattedMessage id="dashboard.asterik" />
                          </span>
                        </span>
                        <span className="tilearrow">
                          <TrendArrows
                            trend={overViewData?.totalSpends?.trend}
                          />
                        </span>
                        <span className="ldesc">
                          {activeDuration.label === "weekly" && (
                            <FormattedMessage
                              id="dashboard.previousweek"
                              values={weekDates}
                            />
                          )}
                          {activeDuration.label !== "weekly" && (
                            <FormattedMessage
                              id="dashboard.previous30Or90Days"
                              values={weekDates}
                            />
                          )}
                        </span>
                        <span className="lviewdeatails  d-none">
                          <FormattedMessage id="dashboard.viewdetails" />
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-1 col-md-1 col-lg-1 text-right">
                      <span className="closed" onClick={resetActiveState}>
                        <Image
                          className=""
                          src="ic_close.svg"
                          height="24px"
                          width="24"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                  {is("totalSpends") && activeDuration?.label == "weekly" && (
                    <BarChart
                      data={overViewData?.totalSpends?.chart}
                      height={250}
                      config={{
                        x: xKey,
                        isXDay: true,
                        y: { key: "cur", label: "Current Week" },
                        z: { key: "prev", label: "Previous Week" },
                        yUnit: "$",
                        yDomain: [0, 250],
                      }}
                    />
                  )}
                </div>
              </div>

              <div
                className={`col-sm-12 col-md-12 col-lg-12 
                    ${is("serviceStops") ? "d-block" : "d-none"}`}
              >
                <div className="">
                  <div className="row heading">
                    <div className="col-sm-11 col-md-11 col-lg-11 text-left pr-0">
                      <div className="">
                        <span className="lname">
                          {" "}
                          <FormattedMessage id="dashboard.servicestopsoverview" />
                        </span>
                        <span className="larrow d-none"> |</span>
                        <span className="lper">
                          <span className="lpervalue">
                            <FormattedNumber
                              value={overViewData?.serviceStops?.perc}
                              style="percent"
                            />
                          </span>
                          <span className="dashbaord-asterik d-none">
                            <FormattedMessage id="dashboard.asterik" />
                          </span>
                        </span>

                        {overViewData?.serviceStops?.perc === 0 ? (
                          ""
                        ) : (
                          <span className="tilearrow">
                            <TrendArrows
                              trend={overViewData?.serviceStops?.trend}
                            />
                          </span>
                        )}
                        <span className="ldesc">
                          {activeDuration.label === "weekly" && (
                            <FormattedMessage
                              id="dashboard.previousweek"
                              values={weekDates}
                            />
                          )}
                          {activeDuration.label !== "weekly" && (
                            <FormattedMessage
                              id="dashboard.previous30Or90Days"
                              values={weekDates}
                            />
                          )}
                        </span>
                        <span className="lviewdeatails tooltips d-none">
                          <ComingSoon />
                          <FormattedMessage id="dashboard.viewdetails" />
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-1 col-md-1 col-lg-1 text-right">
                      <span className="closed" onClick={resetActiveState}>
                        <Image
                          className=""
                          src="ic_close.svg"
                          height="24px"
                          width="24"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>

                  {is("serviceStops") && activeDuration?.label == "weekly" && (
                    <AreaChartNFC
                      data={overViewData?.serviceStops?.chart}
                      height={250}
                      type="monotone"
                      config={{
                        x: xKey,
                        y: [
                          { key: "cur", label: "Current Week" },
                          { key: "prev", label: "Previous Week" },
                        ],
                        yUnit: "",
                        // yDomain: [0, 100],
                      }}
                    />
                  )}
                </div>
              </div>

              <div
                className={`col-sm-12 col-md-12 col-lg-12 
              ${is("stopsCompleted") ? "d-block" : "d-none"}`}
              >
                <div className="">
                  <div className="row heading">
                    <div className="col-sm-11 col-md-11 col-lg-11 text-left pr-0">
                      <div className="">
                        <span className="lname">
                          <FormattedMessage id="dashboard.stopscompleteoverview" />
                        </span>
                        <span className="larrow d-none"> |</span>
                        <span className="lper">
                          <span className="lpervalue">
                            <FormattedNumber
                              value={overViewData?.stopsCompleted?.perc}
                              style="percent"
                            />
                          </span>
                          <span className="dashbaord-asterik d-none">
                            <FormattedMessage id="dashboard.asterik" />
                          </span>
                          <FormattedMessage id="dashboard.percentagecomplete" />
                        </span>

                        <span className="ldesc">
                          {activeDuration.label === "weekly" && (
                            <FormattedMessage
                              id="dashboard.previousweek"
                              values={weekDates}
                            />
                          )}
                          {activeDuration.label !== "weekly" && (
                            <FormattedMessage
                              id="dashboard.previous30Or90Days"
                              values={weekDates}
                            />
                          )}
                        </span>
                        <span className="lviewdeatails tooltips d-none">
                          <ComingSoon />
                          <FormattedMessage id="dashboard.viewdetails" />
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-1 col-md-1 col-lg-1 text-right">
                      <span className="closed" onClick={resetActiveState}>
                        <Image
                          className=""
                          src="ic_close.svg"
                          height="24px"
                          width="24"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                  {is("stopsCompleted") &&
                    activeDuration?.label == "weekly" && (
                      <BarChart
                        data={overViewData?.stopsCompleted?.chart}
                        height={250}
                        config={{
                          x: "day",
                          isXDay: true,
                          y: { key: "cur", label: "Current Week" },
                          z: { key: "prev", label: "Previous Week" },
                          // yDomain: [0, 250],
                          yUnit: "",
                        }}
                      />
                    )}
                </div>
              </div>

              <div
                className={`col-sm-12 col-md-12 col-lg-12 
                ${
                  is("stopsCompleted") ||
                  is("serviceStops") ||
                  is("totalSpends") ||
                  is("weightPickedUp")
                    ? "d-none"
                    : "d-block"
                }`}
              >
                <div className="row d-none">
                  <div className="col-sm-3 col-md-3 col-lg-3">
                    <span className="previous-week-msg">
                      <FormattedMessage id="dashboard.asterikmessage" />
                    </span>
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3 pl-4">
                    <span className="previous-week-msg">
                      {/* <FormattedMessage id="dashboard.asterikmessage" /> */}
                    </span>
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3 pl-4 ">
                    <span className="previous-week-msg">
                      {/* <FormattedMessage id="dashboard.asterikmessage" /> */}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12 text-left mb-3"></div>
          </div>
        </div>
      ) : (
        <div className="col pr-0 height-150">
          <div className="loader"></div>
        </div>
      )}{" "}
    </Loading>
  )
}

export default enhancer(WeeklyOverview)
