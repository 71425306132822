import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import { roles } from "config"

function UserCheckout(props) {
  let {
    onReturnToUserAdminClick,
    onCreateAnotherNewUserClick,
    createUserService,
    createFlag,
  } = props

  return (
    <Fragment>
      <div className="requestServiceBlock requestSuccessBlock">
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left mt-4 mb-3">
          <div className="m-0 labelgenerator">
            <FormattedMessage
              id={
                createFlag
                  ? "usercheckout.createnewuser"
                  : "usercheckout.edituser"
              }
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left">
          <div className="success-block">
            <div className="m-0 title">
              <FormattedMessage id="usercheckout.success" />
            </div>
            <div className="m-0 subTitle">
              <div className="subTxtMsg">
                <FormattedMessage
                  id={
                    createFlag
                      ? "usercheckout.subsuccess1"
                      : "usercheckout.editsubsuccess1"
                  }
                  values={{
                    email: createUserService.email,
                    update: createUserService.isActive
                      ? "changed"
                      : "Deactivated",
                  }}
                />
                <br />
                {createFlag && (
                  <FormattedMessage
                    id="usercheckout.subsuccess2"
                    values={{ userType: createUserService.roleName }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left">
          <div className="m-0 contact-title">
            <FormattedMessage id="usercheckout.userinfo" />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="usercheckout.username" />
            </div>
            <div className="m-0 value col-10 p-0">
              {createUserService.firstName} {createUserService.lastName}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="usercheckout.email" />
            </div>
            <div className="m-0 value col-10 p-0">
              {createUserService.email}{" "}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="usercheckout.customername" />
            </div>
            <div className="m-0 value col-10 p-0">
              {createUserService.customerName}{" "}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="usercheckout.usertype" />
            </div>
            <div className="m-0 value col-10 p-0">
              {createUserService.roleName}
            </div>
          </div>
        </div>
        {createUserService.roleId == roles.CE_ADMIN && (
          <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
            <div className="row m-0">
              <div className="m-0 col-2 p-0 label">
                <FormattedMessage id="usercheckout.business" />
              </div>
              <div className="m-0 value col-10 p-0">
                {createUserService.businessSegment}
              </div>
            </div>
          </div>
        )}
        {createUserService.division != "" &&
          createUserService.roleId === roles.Brand_User &&
          createUserService.roleName === "Brand User" && (
            <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
              <div className="row m-0">
                <div className="m-0 col-2 p-0 label">
                  <FormattedMessage id="usercheckout.brandName" />
                </div>
                <div className="m-0 value col-10 p-0">
                  {createUserService.division}
                </div>
              </div>
            </div>
          )}
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServicebutton text-center">
          {!createFlag && (
            <div className="row m-0">
              <div className="m-0 col-12 p-0">
                <button
                  className="green-button"
                  onClick={() => {
                    onReturnToUserAdminClick()
                  }}
                >
                  <FormattedMessage id="OK" />
                </button>
              </div>
            </div>
          )}
          {createFlag && (
            <div className="row m-0">
              <div className="m-0 col-12 p-0">
                <button
                  className="green-button"
                  onClick={() => {
                    onReturnToUserAdminClick()
                  }}
                >
                  <FormattedMessage id="usercheckout.returntoadmin" />
                </button>
              </div>
              <div className="m-0 col-12 margin-top-1 mb-5">
                <span
                  className="green-label create-new-text"
                  onClick={() => {
                    onCreateAnotherNewUserClick()
                  }}
                >
                  <FormattedMessage id="usercheckout.createanothernewuser" />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}
export default enhancer(UserCheckout)
