import React, { useState } from "react"
import enhancer from "./enhancer"
import Image from "shared/Image"
import { FormattedMessage } from "react-intl"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Loading from "shared/Loading"
import OutsideClickHandler from "react-outside-click-handler"
import { roles } from "config"
import Editor from "../Components/Editor.js"
import Modal from "shared/Modal"
import { useSelector } from "react-redux"

const ManageAnnouncements = (props) => {
  const {
    goToUserManagement,
    state,
    openCalender,
    onSelectField,
    dateStatus,
    onSubmit,
    onSelectCheckBox,
    submit,
    BusinessSegmentId,
    viewState = false,
    editState = false,
  } = props
  const [value, setValue] = useState("")
  const [showModal, setShowModal] = useState(false)
  const { changeCustomer } = useSelector((state) => state)
  return (
    <Loading id={"create_announcement"}>
      <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-3">
        <div className="col-sm-6 col-md-6 col-lg-6 mt-4 mb-3">
          <div className={`row m-0`}>
            <div
              className="dp-al-ctr back-link"
              onClick={() => goToUserManagement()}
            >
              <Image
                className=""
                src={"back-button.svg"}
                height="14px"
                width="14px"
                alt=""
              />
              <div className="p-14th underline mr-f">{`BACK`}</div>
            </div>
          </div>
          <div className="row m-0 admin-home pd-b-0">
            {viewState ? (
              "View Announcement"
            ) : editState ? (
              "Edit Announcement"
            ) : (
              <FormattedMessage id="admintilemanagement" />
            )}
          </div>
        </div>
        <div className="row m-0 px-4 new-row-block">
          <div
            className={`col-sm-3 col-md-4 col-lg-4 text-left p-0 pd-2r  ${
              viewState && "prevent-clics"
            }`}
          >
            <OutsideClickHandler
              onOutsideClick={() => openCalender("begining", false)}
            >
              <div className="row m-0">
                <div
                  className="col-sm-10 col-md-12 col-lg-12 p-0  date-picker-style"
                  onClick={() => openCalender("begining", true)}
                >
                  <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0 ">
                    <div className="form-input-block">
                      <span className="label">
                        <FormattedMessage id="manage.beginnerdate" />
                      </span>
                      <DatePicker
                        className="date-input-box"
                        open={dateStatus.begining}
                        onChange={(val, event) => {
                          onSelectField("startDate", val)
                          openCalender("begining", false)
                          event.stopPropagation()
                        }}
                        minDate={new Date()}
                        dateFormat="MMM dd, yyyy"
                        selected={state.startDate || ""}
                      />
                    </div>
                  </div>
                  <div className="col-sm-10 col-md-2 col-lg-2 text-center p-0 ">
                    <Image
                      className=""
                      src={"ic_calendar.svg"}
                      height="14px"
                      width="14px"
                      alt=""
                    />
                  </div>
                </div>
                {!(state.startDate instanceof Date) && submit && (
                  <span className="field-error">
                    <FormattedMessage id="manageerror.startdate" />
                  </span>
                )}
              </div>
            </OutsideClickHandler>
          </div>
          <div
            className={`col-sm-3 col-md-4 col-lg-4 text-left p-0 pd-2l  ${
              viewState && "prevent-clics"
            } `}
          >
            <OutsideClickHandler
              onOutsideClick={() => openCalender("ending", false)}
            >
              <div className="row m-0">
                <div
                  className="col-sm-10 col-md-12 col-lg-12 p-0  date-picker-style"
                  onClick={() => openCalender("ending", true)}
                >
                  <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0 ">
                    <div className="form-input-block">
                      <span className="label">
                        <FormattedMessage id="manage.endingdate" />
                      </span>
                      <DatePicker
                        className="date-input-box"
                        open={dateStatus.ending}
                        onChange={(val, event) => {
                          onSelectField("endDate", val)
                          openCalender("ending", false)
                          event.stopPropagation()
                        }}
                        minDate={state.startDate || new Date()}
                        selected={state.endDate || ""}
                        dateFormat="MMM dd, yyyy"
                      />
                    </div>
                  </div>
                  <div className="col-sm-10 col-md-2 col-lg-2 text-center p-0 ">
                    <Image
                      className=""
                      src={"ic_calendar.svg"}
                      height="14px"
                      width="14px"
                      alt=""
                    />
                  </div>
                </div>
                {!(state.endDate instanceof Date) && submit && (
                  <span className="field-error">
                    <FormattedMessage id="manageerror.enddate" />
                  </span>
                )}
              </div>
            </OutsideClickHandler>
          </div>
        </div>
        <div className="row m-0 px-4 new-row-block mt-4 mb-3 manage-heading-fields">
          <FormattedMessage id="announcement.businessSegment" />
        </div>
        <div className="row m-0 px-4 new-row-block mt-4 mb-3">
          <div className="col-sm-3 col-md-4 col-lg-4 text-left p-0">
            <div className="row m-0">
              <div
                className={`col-sm-10 col-md-12 col-lg-12 text-left p-0  ${
                  viewState && "prevent-clics"
                }`}
              >
                <label className="check-container" style={{ top: "5px" }}>
                  <input
                    type="checkbox"
                    name={"All"}
                    onClick={(e) => onSelectCheckBox(e, "businessSegmentIds")}
                    checked={state.businessSegmentAll}
                  />
                  <span className={"checkmark"} />
                </label>
                <span
                  className="px-4 text-description"
                  style={{ marginLeft: "16PX" }}
                >
                  <FormattedMessage id="announcement.all" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-md-4 col-lg-4 text-left p-0">
            <div className="row m-0">
              <div
                className={`col-sm-10 col-md-12 col-lg-12 text-left p-0  ${
                  viewState && "prevent-clics"
                }`}
              >
                <label className="check-container" style={{ top: "5px" }}>
                  <input
                    type="checkbox"
                    name={"FULL_CIRCLE"}
                    onClick={(e) => onSelectCheckBox(e, "businessSegmentIds")}
                    checked={state.businessSegmentIds.includes(
                      BusinessSegmentId.FULL_CIRCLE
                    )}
                  />
                  <span className={"checkmark"} />
                </label>
                <span
                  className="px-4 text-description"
                  style={{ marginLeft: "16PX" }}
                >
                  <FormattedMessage id="announcement.fullcircle" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 px-4 new-row-block mt-4 mb-3">
          <div className="col-sm-3 col-md-4 col-lg-4 text-left p-0">
            <div className="row m-0">
              <div
                className={`col-sm-10 col-md-12 col-lg-12 text-left p-0  ${
                  viewState && "prevent-clics"
                }`}
              >
                <label className="check-container" style={{ top: "5px" }}>
                  <input
                    type="checkbox"
                    name={"M&I"}
                    onClick={(e) => onSelectCheckBox(e, "businessSegmentIds")}
                    checked={state.businessSegmentIds.includes(
                      BusinessSegmentId["M&I"]
                    )}
                  />
                  <span className={"checkmark"} />
                </label>
                <span
                  className="px-4 text-description"
                  style={{ marginLeft: "16PX" }}
                >
                  <FormattedMessage id="announcement.MandI" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-md-4 col-lg-4 text-left p-0">
            <div className="row m-0">
              <div
                className={`col-sm-10 col-md-12 col-lg-12 text-left p-0  ${
                  viewState && "prevent-clics"
                }`}
              >
                <label className="check-container" style={{ top: "5px" }}>
                  <input
                    type="checkbox"
                    name={"RETAIL"}
                    onClick={(e) => onSelectCheckBox(e, "businessSegmentIds")}
                    checked={state.businessSegmentIds.includes(
                      BusinessSegmentId.RETAIL
                    )}
                  />
                  <span className={"checkmark"} />
                </label>
                <span
                  className="px-4 text-description"
                  style={{ marginLeft: "16PX" }}
                >
                  <FormattedMessage id="announcement.retail" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 px-4 new-row-block mt-4 mb-3">
          {state.businessSegmentIds.length === 0 && submit && (
            <span className="field-error">
              <FormattedMessage id="manageerror.businessSegmentsids" />
            </span>
          )}
        </div>
        <div className="row m-0 px-4 new-row-block mt-4 mb-3 manage-heading-fields">
          <FormattedMessage id="manage.managetext" />
        </div>
        <div className="row m-0 px-4 new-row-block mt-4 mb-3">
          <div className="col-sm-3 col-md-4 col-lg-4 text-left p-0">
            <div className="row m-0">
              <div
                className={`col-sm-10 col-md-12 col-lg-12 text-left p-0  ${
                  viewState && "prevent-clics"
                }`}
              >
                <label className="check-container" style={{ top: "5px" }}>
                  <input
                    type="checkbox"
                    name={"All"}
                    onClick={(e) => onSelectCheckBox(e, "roleIds")}
                    checked={state.rolesAll}
                  />
                  <span className={"checkmark"} />
                </label>
                <span
                  className="px-4 text-description"
                  style={{ marginLeft: "16PX" }}
                >
                  <FormattedMessage id="announcement.all" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-md-4 col-lg-4 text-left p-0">
            <div className="row m-0">
              <div
                className={`col-sm-10 col-md-12 col-lg-12 text-left p-0  ${
                  viewState && "prevent-clics"
                }`}
              >
                <label className="check-container" style={{ top: "5px" }}>
                  <input
                    type="checkbox"
                    name={"CORPORATE_USER"}
                    onClick={(e) => onSelectCheckBox(e, "roleIds")}
                    checked={state.roleIds.includes(roles.CORPORATE_USER)}
                  />
                  <span className={"checkmark"} />
                </label>
                <span
                  className="px-4 text-description"
                  style={{ marginLeft: "16PX" }}
                >
                  <FormattedMessage id="manage.corporatoruser" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-md-4 col-lg-4 text-left p-0">
            <div className="row m-0">
              <div
                className={`col-sm-10 col-md-12 col-lg-12 text-left p-0  ${
                  viewState && "prevent-clics"
                }`}
              >
                <label className="check-container" style={{ top: "5px" }}>
                  <input
                    type="checkbox"
                    name={"CUSTOMER_ADMIN"}
                    onClick={(e) => onSelectCheckBox(e, "roleIds")}
                    checked={state.roleIds.includes(roles.CUSTOMER_ADMIN)}
                  />
                  <span className={"checkmark"} />
                </label>
                <span
                  className="px-4 text-description"
                  style={{ marginLeft: "16PX" }}
                >
                  <FormattedMessage id="manage.Customeradmin" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 px-4 new-row-block mt-4 mb-3">
          <div className="col-sm-3 col-md-4 col-lg-4 text-left p-0">
            <div className="row m-0">
              <div
                className={`col-sm-10 col-md-12 col-lg-12 text-left p-0  ${
                  viewState && "prevent-clics"
                }`}
              >
                <label className="check-container" style={{ top: "5px" }}>
                  <input
                    type="checkbox"
                    name={"CE_ADMIN"}
                    onClick={(e) => onSelectCheckBox(e, "roleIds")}
                    checked={state.roleIds.includes(roles.CE_ADMIN)}
                  />
                  <span className={"checkmark"} />
                </label>
                <span
                  className="px-4 text-description"
                  style={{ marginLeft: "16PX" }}
                >
                  <FormattedMessage id="manage.cleanearthadmin" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-md-4 col-lg-4 text-left p-0">
            <div className="row m-0">
              <div
                className={`col-sm-10 col-md-12 col-lg-12 text-left p-0  ${
                  viewState && "prevent-clics"
                }`}
              >
                <label className="check-container" style={{ top: "5px" }}>
                  <input
                    type="checkbox"
                    name={"SITE_USER"}
                    onClick={(e) => onSelectCheckBox(e, "roleIds")}
                    checked={state.roleIds.includes(roles.SITE_USER)}
                  />
                  <span className={"checkmark"} />
                </label>
                <span
                  className="px-4 text-description"
                  style={{ marginLeft: "16PX" }}
                >
                  <FormattedMessage id="manage.siteuser" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-md-4 col-lg-4 text-left p-0">
            <div className="row m-0">
              <div
                className={`col-sm-10 col-md-12 col-lg-12 text-left p-0  ${
                  viewState && "prevent-clics"
                }`}
              >
                <label className="check-container" style={{ top: "5px" }}>
                  <input
                    type="checkbox"
                    name={"CE_GENERIC_USER"}
                    onClick={(e) => onSelectCheckBox(e, "roleIds")}
                    checked={state.roleIds.includes(roles.CE_GENERIC_USER)}
                  />
                  <span className={"checkmark"} />
                </label>
                <span
                  className="px-4 text-description"
                  style={{ marginLeft: "16PX" }}
                >
                  <FormattedMessage id="manager.cegenericuser" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 px-4 new-row-block mt-4 mb-3">
          <div className="col-sm-3 col-md-4 col-lg-4 text-left p-0">
            <div className="row m-0">
              <div
                className={`col-sm-10 col-md-12 col-lg-12 text-left p-0  ${
                  viewState && "prevent-clics"
                }`}
              >
                <label className="check-container" style={{ top: "5px" }}>
                  <input
                    type="checkbox"
                    name={"ENTERPRISE_USER"}
                    onClick={(e) => onSelectCheckBox(e, "roleIds")}
                    checked={state.roleIds.includes(roles.ENTERPRISE_USER)}
                  />
                  <span className={"checkmark"} />
                </label>
                <span
                  className="px-4 text-description"
                  style={{ marginLeft: "16PX" }}
                >
                  <FormattedMessage id="manage.enterpriseuser" />
                </span>
              </div>
            </div>
          </div>

          <div className="col-sm-3 col-md-4 col-lg-4 text-left p-0">
            <div className="row m-0">
              <div
                className={`col-sm-10 col-md-12 col-lg-12 text-left p-0  ${
                  viewState && "prevent-clics"
                }`}
              >
                <label className="check-container" style={{ top: "5px" }}>
                  <input
                    type="checkbox"
                    name={"Brand_User"}
                    onClick={(e) => onSelectCheckBox(e, "roleIds")}
                    checked={state.roleIds.includes(roles.Brand_User)}
                  />
                  <span className={"checkmark"} />
                </label>
                <span
                  className="px-4 text-description"
                  style={{ marginLeft: "16PX" }}
                >
                  <FormattedMessage id="manage.branduser" />
                </span>
              </div>
            </div>
          </div>
          {changeCustomer?.customer?.businessSegmentId == 3 && (
            <div className="col-sm-3 col-md-4 col-lg-4 text-left p-0">
              <div className="row m-0">
                <div
                  className={`col-sm-10 col-md-12 col-lg-12 text-left p-0  ${
                    viewState && "prevent-clics"
                  }`}
                >
                  <label className="check-container" style={{ top: "5px" }}>
                    <input
                      type="checkbox"
                      name={"CE_COMMERCIAL_USER"}
                      onClick={(e) => onSelectCheckBox(e, "roleIds")}
                      checked={state.roleIds.includes(roles.CE_COMMERCIAL_USER)}
                    />
                    <span className={"checkmark"} />
                  </label>
                  <span
                    className="px-4 text-description"
                    style={{ marginLeft: "16PX" }}
                  >
                    <FormattedMessage id="manager.cecommercialuser" />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row m-0 px-4 new-row-block mt-4 mb-3">
          {state.roleIds.length === 0 && submit && (
            <span className="field-error">
              <FormattedMessage id="manageerror.roles" />
            </span>
          )}
        </div>
        <div className="row m-0 px-4 new-row-block mt-4 mb-3 manage-heading-fields">
          <FormattedMessage id={"manage.textannouncement"} />
        </div>
        <div className="row m-0 px-4 new-row-block ">
          <Editor
            onValue={setValue}
            onDescription={onSelectField}
            value={state.formattedDescription}
            readOnly={viewState ? true : false}
            border={viewState ? true : false}
            error={state.description.trim().length > 1500}
          />
        </div>
        <div className="row m-0 px-4 new-row-block mb-3 text-description error-align paad-10-rem">
          <span
            className={`character-limit ${
              state.description.trim().length > 1500 && "field-error"
            }`}
          >{`${state.description.trim().length} / ${1500}`}</span>
          <span className="field-error padd-180">
            {state.description.trim().length === 0 && submit && (
              <FormattedMessage id="manageerror.description" />
            )}
          </span>
          {state.description.trim().length > 1500 && (
            <span className="field-error padd-180">
              <FormattedMessage id="manageerror.descriptionExceed" />
            </span>
          )}
        </div>
        <Modal
          show={showModal}
          handleClose={() => {}}
          wrapperclass={"delete-layout"}
        >
          <div className="position-relative delete_popup">
            <div className="h-60 delete-description">
              Are you sure do you want to delete announcement?
            </div>

            <div className="delete-footer">
              <button
                className="delete-button-cancel"
                onClick={() => setShowModal(false)}
              >
                cancel
              </button>
              <button
                className="delete-button-ok"
                onClick={() => {
                  setShowModal(false)
                  onSubmit({ value: value, isdelete: true })
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </Modal>
        {!viewState && (
          <div className="row m-0 new-row-block mar-top-3-rem">
            <div className="col-sm-10 col-md-12 col-lg-12 text-left pr-4">
              <div className="row m-0 float-right m-r-2r">
                <button
                  type="submit"
                  className="green-button"
                  onClick={() => onSubmit({ value: value, isdelete: false })}
                >
                  <FormattedMessage id={"manage.submit"} />
                </button>
              </div>
              {editState && (
                <div className="row m-0  m-r-2r">
                  <button
                    type="submit"
                    className="cancel-button"
                    // onClick={() => onSubmit({ value: value, isdelete: true })}
                    onClick={() => setShowModal(true)}
                  >
                    {"DELETE"}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        {viewState && (
          <div className="row m-0 new-row-block ">
            <div className="col-sm-10 col-md-12 col-lg-12 text-left pr-4">
              <div className="row m-0 float-right m-r-2r">
                <button
                  type="submit"
                  className="green-button"
                  onClick={() => goToUserManagement()}
                >
                  {"Close"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Loading>
  )
}
export default enhancer(ManageAnnouncements)
