import React, { useEffect, useState } from "react"
import { download, openDocInNewWindow, downloadZip, useHarsco } from "shared"
import api from "api"
import { host } from "api/urls"

let enhancer =
  (Component) =>
  ({ history, ...props }) => {
    const [filterData, setFilterData] = useState({})
    const [docData, setDocData] = useState([])
    const [storeNum, setStoreNum] = useState("")
    let [submitted, setSubmitted] = useState(false)
    const { isStoreManager, storeId } = useHarsco()

    useEffect(() => {
      if (filterData?.stores || isStoreManager) fetchData()
    }, [filterData])

    let fetchData = async () => {
      setSubmitted(false)
      let { stores } = filterData
      if (isStoreManager) stores = storeId

      let req = {
        loading_key: "documents",
        storeId: stores,
      }

      try {
        let res = await api.g_historicalLDRs(req)
        if (!res) return
        let hostUrl = host()
        res.forEach((d) => {
          let { fileName, filePath } = d
          let path = `${hostUrl}/api/portal/document?uri=${filePath}${fileName}`
          d.viewUrl = `${path}&type=view`
          d.downloadUrl = `${path}&type=download`
        })
        setStoreNum(stores)
        setDocData(res)
        setSubmitted(true)
      } catch (error) {
        setDocData({})
        setSubmitted(false)
      }
    }

    const updateFilterData = (data) => {
      setFilterData(data)
    }

    const resetFilter = () => {
      setDocData([])
      setFilterData({})
    }

    const viewDoc = (d) => () => openDocInNewWindow(d.viewUrl, d.fileName)

    const downloadPDF = (data) => () => {
      const { downloadUrl, fileName } = data
      download(downloadUrl, fileName)
    }

    let downLoadAsZip = () => {
      const urls = docData.map((dt) => ({
        url: dt.downloadUrl,
        name: dt.fileName,
      }))
      downloadZip(urls, `${storeNum} LDRs`)
    }

    return (
      <Component
        {...props}
        {...{
          updateFilterData,
          docData,
          filterData,
          resetFilter,
          downloadPDF,
          viewDoc,
          storeNum,
          isStoreManager,
          submitted,
          downLoadAsZip,
        }}
      />
    )
  }

export default enhancer
