import React from "react"
import Image from "shared/HelpImage/index"

function MyHistory() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">My History</h3>

      <Image src="MyHistory_Fc.png" />

      <p className="text-justify pl-3 pt-2">
        The My History page provides the user with the list of historical order
        data. The My History menu provides the user filter options such as:
        Generator State, Generator City and Generator Name.
      </p>
      <p className="text-justify pl-3 pt-2">
        There are also Predefined searches or date ranges for the user to
        choose. Once the user has applied the filters.
      </p>
    </div>
  )
}
export default MyHistory
