import React, { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"

let enhancer = (Component) => (props) => {
  let history = useHistory()
  let location = useLocation()

  let createUserService = location.state
  let createFlag = location?.createFlag

  const onReturnToUserAdminClick = (e) => {
    history.push("/admin")
  }
  const onCreateAnotherNewUserClick = (e) => {
    history.push("/admin/createuser")
  }

  useEffect(() => {
    if (
      createFlag &&
      createUserService?.res === "Unable to create user in Azure B2C"
    ) {
      toast.error(createUserService?.res, { autoClose: 5000 })
    }
  }, [])

  return (
    <Component
      {...props}
      {...{
        onReturnToUserAdminClick,
        onCreateAnotherNewUserClick,
        createUserService,
        createFlag,
      }}
    />
  )
}

export default enhancer
