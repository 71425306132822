import React, { useState, useEffect } from "react"
import api from "api"
import { host } from "api/urls"
import { openDocInNewWindow, downloadZip, download } from "shared"
import _ from "lodash"

let enchancer =
  (Component) =>
  ({ ...props }) => {
    let [docData, setDocData] = useState({})
    let [allDocsCheck, setAllDocsCheck] = useState(false)
    let [pageNo, gotoPage] = useState(1)
    let [submitted, setSubmitted] = useState(false)
    let [filterData, setFilterData] = useState({})

    let fetchData = async () => {
      setSubmitted(false)
      if (!filterData.dates) return

      let { dates, customers } = filterData
      let req = {
        loading_key: "documents",
        index: pageNo,
        size: 20,
        generator: customers,
      }
      req.dates = [dates?.from, dates?.to]

      let res = await api.p_documentLibrary(req)
      if (!res) return
      let hostUrl = host()

      res.items.forEach((d) => {
        let { fileName, filePath } = d
        let path = `${hostUrl}/api/portal/document?uri=${filePath}${fileName}`
        d.viewUrl = `${path}&type=view`
        d.downloadUrl = `${path}&type=download`
        d.selected = false
      })
      setDocData(res)
      setAllDocsCheck(false)
      setSubmitted(true)
    }

    useEffect(() => {
      if (pageNo == -1) return
      fetchData()
    }, [pageNo])

    let updateDocData = (index) => (e) => {
      let docDataCopy = _.cloneDeep(docData)
      let { items } = docDataCopy
      items[index].selected = e.currentTarget.checked
      setDocData(docDataCopy)

      setAllDocsCheck(items.filter((d) => d.selected).length == items.length)
    }
    let changeAllDocs = (e) => {
      let { checked } = e.currentTarget
      setAllDocsCheck(checked)
      let docDataCopy = _.cloneDeep(docData)
      let { items = [] } = docDataCopy
      items.forEach((d) => (d.selected = checked))
      setDocData(docDataCopy)
    }

    let reset = () => {
      setDocData([])
    }

    let viewDoc = (d) => openDocInNewWindow(d.downloadUrl, d.fileName)

    let downLoadDoc = () => {
      const urls = docData.items
        .filter((d) => d.selected)
        .map((dt) => ({ url: dt.downloadUrl, name: dt.fileName }))

      downloadZip(urls, "DocumentLibrary")
    }

    let updateFilterData = (data) => {
      setFilterData(data)
      gotoPage(-1)
      setTimeout(() => {
        gotoPage(1)
      }, 10)
    }

    return (
      <Component
        {...props}
        {...{
          docData,
          viewDoc,
          downLoadDoc,
          changeAllDocs,
          updateDocData,
          allDocsCheck,
          pageNo,
          gotoPage,
          download,
          filterData,
          updateFilterData,
          submitted,
          reset,
        }}
      />
    )
  }

export default enchancer
