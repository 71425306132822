import React, { Fragment } from "react"
import enchancer from "./enchancer"
import { FormattedMessage } from "react-intl"
import classnames from "classnames"
import Filter from "shared/Filter"
import Loading from "shared/Loading"
import Image from "shared/Image"
import "./style.scss"
import DocumentLibraryTableGrid from "./Components/DocumentLibraryTableGrid"
import { documentLibraryHeader } from "./Components/Constants"
import Pagination from "shared/Pagination"

function docLibrary(props) {
  let {
    docData,
    viewDoc,
    changeAllDocs,
    updateDocData,
    allDocsCheck,
    downLoadDoc,
    pageNo,
    gotoPage,
    download,
    updateFilterData,
    reset,
    submitted,
  } = props

  let { totalRecords = 0 } = docData

  let rows = []
  docData?.items?.forEach((ele, index) => {
    let data = [
      {
        value: (
          <div className="alignCenter">
            <span className="p-b-10">
              <label className="check-container ">
                <input
                  type="checkbox"
                  onChange={updateDocData(index)}
                  checked={ele.selected}
                />
                <span className="checkmark"></span>
              </label>
            </span>
            <Image
              style={{ cursor: "pointer" }}
              className="pdf-icon pad-r-10"
              src="ic_pdf.svg"
              onClick={(e) => download(ele.downloadUrl, ele.fileName)}
              alt=""
            />
          </div>
        ),
        className: "documentlibrary-table-row",
      },
      {
        value: ele.orderNo,
        className: "documentlibrary-table-row",
      },
      {
        value: ele.address,
        className: "documentlibrary-table-row",
      },
      {
        value: ele.city,
        className: "documentlibrary-table-row text-uppercase alignleft ",
      },
      {
        value: ele.state,
        className: "documentlibrary-table-row",
      },
      {
        value: (
          <div>
            <Image
              className="view-icon"
              src="ic_doclibraryview.svg"
              height="24px"
              width="24px"
              alt=""
              onClick={(e) => viewDoc(ele)}
            />
          </div>
        ),
        className: "documentlibrary-table-row",
      },
    ]
    rows.push({ data: data })
  })

  documentLibraryHeader[0].value = (
    <div className="alignCenter">
      <span className="pad-heading-all-table">
        {submitted && totalRecords > 0 && (
          <label className="check-container all-checkbox">
            <input
              type="checkbox"
              onChange={changeAllDocs}
              checked={allDocsCheck}
            />
            <span className="checkmark allcheck"></span>
          </label>
        )}
      </span>
      <span className="pad-r-10">
        <FormattedMessage id="documentlibrary.all" />
      </span>
    </div>
  )
  return (
    <Fragment>
      <div>
        <Filter onSubmit={updateFilterData} Onreset={reset} />
        <div className="col-sm-12 col-md-12 col-lg-12 generatorstatus text-left my-4">
          <div className="m-0 labelgenerator document-label">
            <FormattedMessage id="documentlibrary.heading" />
          </div>
          <div className="downloadsec download-label">
            {submitted && totalRecords > 0 && (
              <button
                className={classnames("downloadbtn", {
                  disabled:
                    docData?.items?.filter((d) => d.selected).length == 0,
                })}
                onClick={downLoadDoc}
              >
                <FormattedMessage id="documentlibrary.download" />
              </button>
            )}
          </div>
        </div>
        <Loading id="documents">
          <div className="col-sm-12 col-md-12 col-lg-12 m-0 px-3">
            <DocumentLibraryTableGrid
              headers={documentLibraryHeader}
              rows={rows}
            />
            {submitted && totalRecords == 0 && (
              <div className="col-sm-12 col-md-12 col-lg-12 text-center pt-2 nodata">
                <FormattedMessage id="nodata.documents" />
              </div>
            )}
            {totalRecords > 0 && (
              <Pagination
                {...{
                  pageNo,
                  totalRecords,
                  gotoPage,
                  data: docData,
                  pageSize: 20,
                }}
              />
            )}
          </div>
        </Loading>
      </div>
    </Fragment>
  )
}

export default enchancer(docLibrary)
