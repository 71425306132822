import MockAdapter from "axios-mock-adapter"
import axios from "./axios"

const dashboardData = {
  overviewData: [
    {
      fromDate: "2020-07-01T00:00:00",
      todate: "2021-06-30T00:00:00",
      treatementTypeTiles: [
        {
          treatmentType: "Incineration",
          netWeight: 103.776195231,
          percentage: 2.8194162563917855,
        },
        {
          treatmentType: "Landfill",
          netWeight: 295.923977138,
          percentage: 8.0397327146347,
        },
        {
          treatmentType: "Recycle/Reuse",
          netWeight: 1886.912365043,
          percentage: 51.264082138942385,
        },
        {
          treatmentType: "Waste to Energy",
          netWeight: 1394.156309534,
          percentage: 37.87676889003113,
        },
      ],
      treatementTypeChart: [
        {
          treatmentType: "Incineration",
          quarterName: "Q1",
          year: 2021,
          netWeight: 45.014515105,
        },
        {
          treatmentType: "Landfill",
          quarterName: "Q1",
          year: 2021,
          netWeight: 7.654449786,
        },
        {
          treatmentType: "Recycle/Reuse",
          quarterName: "Q1",
          year: 2021,
          netWeight: 288.374308266,
        },
        {
          treatmentType: "Waste to Energy",
          quarterName: "Q1",
          year: 2021,
          netWeight: 152.150503493,
        },
        {
          treatmentType: "Incineration",
          quarterName: "Q2",
          year: 2021,
          netWeight: 58.761680126,
        },
        {
          treatmentType: "Landfill",
          quarterName: "Q2",
          year: 2021,
          netWeight: 288.269527352,
        },
        {
          treatmentType: "Recycle/Reuse",
          quarterName: "Q2",
          year: 2021,
          netWeight: 1598.538056777,
        },
        {
          treatmentType: "Waste to Energy",
          quarterName: "Q2",
          year: 2021,
          netWeight: 1242.005806041,
        },
      ],
    },
  ],
  barChartData: [
    {
      name: "Q1",
      RECYCLE: 5,
      WTE: 20,
      INCINERATION: 30,
      LANDFILL: 10,
    },
    {
      name: "Q2",
      RECYCLE: 10,
      WTE: 20,
      INCINERATION: 30,
      LANDFILL: 10,
    },
    {
      name: "Q3",
      RECYCLE: 20,
      WTE: 30,
      INCINERATION: 20,
      LANDFILL: 10,
    },
    {
      name: "Q4",
      RECYCLE: 10,
      WTE: 20,
      INCINERATION: 30,
      LANDFILL: 10,
    },
  ],
  areaChartData: [
    {
      name: "Q1",
      RECYCLE: 10,
      WTE: 10,
      INCINERATION: 6,
      LANDFILL: 5,
    },
    {
      name: "Q2",
      RECYCLE: 10,
      WTE: 10,
      INCINERATION: 25,
      LANDFILL: 50,
    },
    {
      name: "Q3",
      RECYCLE: 10,
      WTE: 5,
      INCINERATION: 55,
      LANDFILL: 25,
    },
    {
      name: "Q4",
      RECYCLE: 2,
      WTE: 5,
      INCINERATION: 6,
      LANDFILL: 5,
    },
  ],
  myDocuments: [
    {
      name: "shipping_doc",
      label: "Shipping Document",
      count: 100,
    },
  ],
  invoiceReportData: [
    {
      customerNumber: "11341412423",
      salesOrderNumber: "134123412",
      customerPONumber: "AD1241",
      generatorCompany: "Harsco",
      generatorCity: "AY",
      generatorStateProvinceCode: "123",
      description: "tesing currently with grid.",
      quantityOrdered: "4",
      priceUOMCode: "UJ912",
      dateRequired: Date.now(),
    },
    {
      customerNumber: "45215321324",
      salesOrderNumber: "134123412",
      customerPONumber: "AD1241",
      generatorCompany: "Harsco",
      generatorCity: "AY",
      generatorStateProvinceCode: "123",
      description: "tesing currently with grid.",
      quantityOrdered: "4",
      priceUOMCode: "UJ912",
      dateRequired: Date.now(),
    },
    {
      customerNumber: "11341412423",
      salesOrderNumber: "134123412",
      customerPONumber: "AD1241",
      generatorCompany: "Harsco",
      generatorCity: "AY",
      generatorStateProvinceCode: "123",
      description: "tesing currently grid.",
      quantityOrdered: "4",
      priceUOMCode: "UJ912",
      dateRequired: Date.now(),
    },
    {
      customerNumber: "45215321324",
      salesOrderNumber: "134123412",
      customerPONumber: "AD1241",
      generatorCompany: "Harsco",
      generatorCity: "AY",
      generatorStateProvinceCode: "123",
      description: "tesing currently with grid.",
      quantityOrdered: "4",
      priceUOMCode: "UJ912",
      dateRequired: Date.now(),
    },
    {
      customerNumber: "11341412423",
      salesOrderNumber: "134123412",
      customerPONumber: "AD1241",
      generatorCompany: "Harsco",
      generatorCity: "AY",
      generatorStateProvinceCode: "123",
      description: "tesing currently grid.",
      quantityOrdered: "4",
      priceUOMCode: "UJ912",
      dateRequired: Date.now(),
    },
    {
      customerNumber: "45215321324",
      salesOrderNumber: "134123412",
      customerPONumber: "AD1241",
      generatorCompany: "Harsco",
      generatorCity: "AY",
      generatorStateProvinceCode: "123",
      description: "tesing currently with grid.",
      quantityOrdered: "4",
      priceUOMCode: "UJ912",
      dateRequired: Date.now(),
    },
  ],
}

const BootMockServer = () => {
  var mock = new MockAdapter(axios, {
    delayResponse: 3000,
    onNoMatch: "passthrough",
  })
  mock.onGet("/getDashboardData").reply(function (config) {
    return [200, dashboardData]
  })
}

BootMockServer()
