import React from "react"
import Image from "shared/HelpImage/index"
import { useHarsco } from "shared"

function MyOrdersMenu() {
  const { isStoreManager } = useHarsco()
  return (
    <>
      {isStoreManager ? (
        <div>
          <h3 className="pt-4 text-left pl-3">My Orders Menu option</h3>
          <div className="img-section pt-4">
            <Image className="" src="myorderstore.png" alt="My Orders Image" />
          </div>
          <p className="text-justify pl-3 pt-2">
            <p>
              The My orders menu option brings the user to the “my order” page.
              This page gives the user filter to search orders based on service
              date.
            </p>
            <p>
              There are also Predefined searches or date ranges for the user to
              choose.
            </p>
          </p>
          <div className="img-section pt-4">
            <Image
              className=""
              src="MyOrder_filters.png"
              alt="My Orders Image"
            />
          </div>
          <p className="text-justify pl-3 pt-4">
            <p>
              My Order overview provides a detailed view of all the schedules
              and service status for selected stores.
            </p>
            <p>
              The list of orders from the search results is shown in a tabular
              format.
            </p>
            <p>
              User can click on the ‘View Details’ button on each row to see
              more details about the order.
            </p>
          </p>
          <div>{/* <Image className="" src="workorderinfo" alt="" /> */}</div>
          <p className="text-justify pl-3 pt-4">
            <p>
              The user can click on the service details button to see more
              details on their specific pick up.
            </p>
            <p>
              This screen will show the user the Work order information. From
              waste composition to document lines of the shipping document and
              supporting documents like copies of manifests.
            </p>
          </p>
        </div>
      ) : (
        <div>
          <h3 className="pt-4 text-left pl-3">My Orders</h3>

          <p className="text-justify pl-3 pt-2">
            <p>
              The My orders screen shows a summary of your Service Orders for
              the areas and time period you select. You can also choose to see
              the details of each order.
            </p>
            <p>
              This page gives the user options to choose the list of orders to
              be displayed by: Divisions/Regions (if applicable), States,
              Stores, Service Types and Schedule Types, and for a specific time
              period.
            </p>
            <p>
              When choosing Divisions/Regions you can choose any number or
              “All.”
            </p>
            <p>When choosing States you can choose any number, or “All.”</p>
            <p>
              When choosing Stores you can choose any number up to a maximum of
              100, or “All.”
            </p>
            <p>
              A red star (*) indicates that Service Types and Schedule Types are
              required and must be chosen when searching for orders. You choose
              from a list by clicking on the down arrow, then choosing one or
              more items from the list. The number in parentheses indicates the
              number of Divisions/Regions, Stores, Service Types or Schedule
              Types you have chosen from the list.
            </p>
            <p>
              You can also choose a date range or one of the predefined queries
              to further limit the number of orders displayed.
            </p>
          </p>
          <div className="img-section pt-4">
            <Image
              className=""
              src="my_Order_menu_options.png"
              alt="My Orders Image"
            />
          </div>
        </div>
      )}
    </>
  )
}
export default MyOrdersMenu
