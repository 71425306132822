const documentLibraryHeader = [
  {
    id: 1,
    headerTitle: "First Name",
    className: "header-title",
    sort: false,
    apiKey: "firstName",
  },
  {
    id: 2,
    headerTitle: "Last Name",
    className: "header-title",
    sort: false,
    apiKey: "lastName",
  },
  {
    id: 5,
    headerTitle: "Work Email",
    className: "header-title",
    sort: false,
    apiKey: "email",
  },
  {
    id: 3,
    headerTitle: "Account Number",
    className: "header-title",
    sort: false,
    apiKey: "accountNumber",
  },
  {
    id: 4,
    headerTitle: "Account Name",
    className: "header-title",
    sort: false,
    apiKey: "accountName",
  },
  {
    id: 11,
    headerTitle: "Brand Name",
    className: "header-title",
    sort: false,
    apiKey: "division",
  },
  {
    id: 6,
    headerTitle: "User Type",
    className: "header-title",
    sort: false,
    apiKey: "userType",
  },
  {
    id: 7,
    headerTitle: "Status",
    className: "header-title",
  },
  {
    id: 8,
    headerTitle: "Created By",
    className: "header-title",
    sort: false,
    apiKey: "createdBy",
  },
  {
    id: 9,
    headerTitle: "Last Logged In",
    className: "header-title",
    sort: false,
    apiKey: "lastLogin",
  },
  {
    id: 10,
    headerTitle: "Options",
    className: "header-title",
  },
]

const AnnouncementsHeader = [
  {
    id: 1,
    headerTitle: "Announcements",
    className: "header-title",
    apiKey: "description",
    sort: false,
  },
  {
    id: 2,
    headerTitle: "Start Date",
    className: "header-title",
    sort: false,
    apiKey: "startdate",
  },
  {
    id: 3,
    headerTitle: "End Date",
    className: "header-title",
    sort: false,
    apiKey: "enddate",
  },
  {
    id: 4,
    headerTitle: "Status",
    className: "header-title",
    apiKey: "status",
    sort: false,
  },
  {
    id: 5,
    headerTitle: "Duration",
    className: "header-title",
    apiKey: "duration",
    sort: false,
  },
  {
    id: 6,
    headerTitle: "Options",
    className: "header-title",
  },
]

export { documentLibraryHeader, AnnouncementsHeader }
