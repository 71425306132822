import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import api from "api"
import useDebounce from "shared/debounce"
import { useHarsco, setCustomerData } from "shared"
import { roles, BusinessSegmentId } from "config"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

let enhancer = (Component) => (props) => {
  let history = useHistory()
  const { profile } = useHarsco()
  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    email: false,
    customerName: false,
    userType: false,
    businessSegmentId: false,
    division: false,
    enterpriseID: false,
  })
  const [open, setOpen] = useState({
    customerName: false,
    userType: false,
    businessSegmentId: false,
    enterpriseID: false,
    division: false,
  })
  const [fields, setField] = useState({
    firstName: "",
    lastName: "",
    email: "",
    customerName: "",
    userType: "",
    businessSegmentId: "",
    enterpriseID: "",
    division: "",
  })

  const { customerList } = useSelector((state) => state)
  const [userTypes, setUserTypes] = useState([])
  const [customers, setCustomers] = useState([])
  const [division, setDivision] = useState([])
  const [enterpriseCustomers, setEnterpriseCustomers] = useState([])
  const [businessSegmentId, setBusinessSegmentId] = useState([])
  const [enterpriseIDs, setenterpriseIDs] = useState([])
  const [enterpriseSearch, setenterpriseSearch] = useState()
  const [customerSearch, setSearch] = useState()
  const [divisionSearch, setDivisionSearch] = useState()
  const [deActivate, setDeActivate] = useState(false)
  const [isEdit, setEdit] = useState(false)
  const [divisionData, setDivisionData] = useState([])

  let { pathname, state = {} } = useLocation()

  const checkForEdit = () => {
    return pathname.includes("edituser")
  }

  useEffect(() => {
    ;(async () => {
      if (state?.userData) {
        await setEditData(state?.userData, roles)
      }
    })()
  }, [state])

  useEffect(() => {
    ;(async () => {
      await getUserTypes()
      if (profile.roleId !== roles.PORTAL_ADMIN && !checkForEdit())
        getInitialCustomer()
    })()
  }, [])

  const getDivision = async (custId, roleId) => {
    if (roleId === roles.Brand_User && custId != "") {
      const division = await api.g_getBrands({
        customerId: custId,
        loading_key: "division_search",
      })
      var filteredData = division
        .filter((d) => d !== null || "")
        .sort()
        .map((d) => ({
          l: d.toUpperCase(),
          v: d.toUpperCase(),
        }))
      setDivision(filteredData)
      setDivisionData(filteredData)
    }
  }

  const getDivisionData = async (searchtext = "") => {
    if (divisionData.length > 0 && searchtext != "") {
      var divi = divisionData
      var div = divi.filter(
        (d) =>
          d.l.slice(0, searchtext.length).trim().toUpperCase() ===
          searchtext.trim().toUpperCase()
      )
      var filteredData = div.sort().map((d) => ({
        l: d.l.toUpperCase(),
        v: d.l.toUpperCase(),
      }))
      setDivision(filteredData)
    }
  }

  useEffect(() => {
    //checking for portal admin to load customers pertaining to business segment
    if (
      fields.businessSegmentId.v &&
      profile.roleId === roles.PORTAL_ADMIN &&
      !checkForEdit()
    ) {
      ;(async () => {
        await getCustomersonBusinessSegmentIds()
      })()
    }
  }, [fields.businessSegmentId.v])

  useEffect(() => {
    //checking for portal admin to load customers pertaining to business segment
    if (
      fields.userType.v === roles.ENTERPRISE_USER &&
      profile.roleId === roles.PORTAL_ADMIN &&
      !checkForEdit()
    ) {
      ;(async () => {
        await getEnterpriseCustomersonBusinessSegmentIds()
      })()
    }
  }, [fields?.enterpriseID?.v])

  useEffect(() => {
    if (
      fields.userType.v &&
      fields.userType.v !== roles.CE_ADMIN &&
      fields.userType.v !== roles.ENTERPRISE_USER &&
      !checkForEdit()
    ) {
      getInitialCustomer()
      //checking for portal admin to load business segments
    } else if (
      profile.roleId === roles.PORTAL_ADMIN &&
      fields.userType.v === roles.CE_ADMIN &&
      !checkForEdit()
    ) {
      getBusinessSegmentIds()
    } else if (
      profile.roleId === roles.PORTAL_ADMIN &&
      fields.userType.v === roles.ENTERPRISE_USER &&
      !checkForEdit()
    ) {
      getEnterPriseData()
    }
  }, [fields.userType.v])
  // g_customerBySearchText
  useEffect(() => {
    ;(async () => {
      if (customerSearch?.length > 2) {
        //checking for portal admin and CE admin role
        if (
          profile.roleId === roles.PORTAL_ADMIN &&
          fields.userType.v === roles.CE_ADMIN
        ) {
          getCustomersonBusinessSegmentIds()
        } else if (
          profile.roleId === roles.PORTAL_ADMIN &&
          fields.userType.v === roles.Brand_User
        ) {
          let res = await api.g_onboardedcustomers({
            searchText: customerSearch,
            loading_key: "customer_search",
          })
          let customer = setCustomerData(res)
          setCustomers(customer)
        } else {
          let res = await api.g_onboardedcustomers({
            searchText: customerSearch,
            loading_key: "customer_search",
          })
          let customer = setCustomerData(res)
          setCustomers(customer)
        }
      } else if (customerSearch?.length == 0) {
        //checking for portal admin and CE admin role
        if (
          profile.roleId === roles.PORTAL_ADMIN &&
          fields.userType.v === roles.CE_ADMIN
        ) {
          getCustomersonBusinessSegmentIds()
        } else {
          getInitialCustomer()
        }
      }
    })()
  }, [useDebounce(customerSearch, 1000)])

  useEffect(() => {
    ;(async () => {
      if (
        profile.roleId === roles.PORTAL_ADMIN &&
        fields.userType.v === roles.ENTERPRISE_USER
      ) {
        if (enterpriseSearch?.length > 2) {
          getEnterPriseData(enterpriseSearch)
        } else {
          if (!checkForEdit()) {
            getEnterPriseData()
          }
        }
      }
    })()
  }, [useDebounce(enterpriseSearch, 1000)])

  useEffect(() => {
    if (
      profile.roleId === roles.PORTAL_ADMIN &&
      fields.userType.v === roles.Brand_User
    ) {
      if (divisionSearch?.length > 2) {
        getDivisionData(divisionSearch)
      } else {
        if (!checkForEdit()) {
          var divi = divisionData
          var filteredData = divi.sort().map((d) => ({
            l: d.l.toUpperCase(),
            v: d.l.toUpperCase(),
          }))
          setDivision(filteredData)
        }
      }
    }
  }, [useDebounce(divisionSearch, 1000)])
  const setEditData = async (data, roles) => {
    let enterdata = ""
    let customerdata = ""
    if (data.roleId === roles.ENTERPRISE_USER) {
      enterdata = await getselectedenterprisedata(data.email)
      customerdata = await getselectedCustomerdata(data.email)
      setEnterpriseCustomers(customerdata)
    } else {
      customerdata = {
        l: `${data?.accountName} - ${data?.accountNumber}`,
        v: `${data?.accountNumber}`,
      }
    }

    let obj = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      userType: { l: data?.userType, v: data?.roleId },
      customerName: customerdata,
      enterpriseID: enterdata,
      businessSegmentId: data?.businessSegmentId
        ? { l: `${data?.businessSegmentName}`, v: `${data?.businessSegmentId}` }
        : "",
      division: data?.division
        ? { l: `${data?.division}`, v: `${data?.division}` }
        : "",
    }
    setField(obj)
    await getUserTypes()
  }
  const getselectedenterprisedata = async (email) => {
    let res = await api.g_getenterpriselist({
      emailid: email,
    })
    let enterpriseData = ""
    if (res) {
      return {
        l: res[0].name,
        v: res[0].customer_azid,
        entCustomer_ID: res[0].enterprise_Customer_ID,
      }
    }
    return enterpriseData
  }
  const getselectedCustomerdata = async (email) => {
    let res = await api.g_getbusinesssegmententerpriseforselectedmail({
      emailId: email,
    })
    if (res) {
      return res.map((ele) => {
        return {
          l: `${ele?.customerName} - ${
            ele?.customerId
          } ${getBusinessSegmentName(ele?.businessSegmentId)}`,
          v: ele?.customerId,
          sourceSystem: ele?.sourceSystem,
          businessSegmentId: ele?.businessSegmentId,
          default: ele?.isDefaultCustomer,
          check: ele?.isSelected,
        }
      })
    }
    return ""
  }

  const getUserTypes = async () => {
    let res = await api.g_userTypes({ source: "userCreate" })
    if (res) {
      let rolesId = []
      rolesId = res.map((ele) => {
        return { l: ele?.description, v: ele?.roleId }
      })

      if (checkForEdit()) {
        if (
          profile?.roleId === roles.PORTAL_ADMIN &&
          state?.userData?.roleId != roles.CE_ADMIN &&
          state?.userData?.roleId != roles.CE_GENERIC_USER
        )
          rolesId = rolesId.filter(
            (ele) =>
              ele.v !== roles.CE_ADMIN &&
              ele.v != roles.CE_GENERIC_USER &&
              ele.v != roles.ENTERPRISE_USER
          )
        else if (
          state?.userData?.roleId == roles.CE_ADMIN ||
          state?.userData?.roleId == roles.CE_GENERIC_USER
        )
          rolesId = rolesId.filter(
            (ele) => ele.v == roles.CE_ADMIN || ele.v == roles.CE_GENERIC_USER
          )
      }
      setUserTypes(rolesId)
    }
  }

  const getInitialCustomer = async () => {
    let customer = [...customerList]
    setCustomers(customer)
    if (fields.userType.v === roles.Brand_User) {
      let initialValue = { ...fields }
      await getDivision(initialValue.customerName.v, fields.userType.v)
    }
    if (profile?.roleId === roles.CUSTOMER_ADMIN) {
      let initialValue = { ...fields }
      setField({
        ...initialValue,
        customerName: customer[0],
      })
    }
  }

  const getBusinessSegmentIds = async () => {
    let businessSegmentId = await api.g_getbusinesssegment()
    if (businessSegmentId) {
      let businessSegmentIds = []
      businessSegmentIds = businessSegmentId
        ?.filter((val) => val?.businessSegmentId != 3)
        .map((ele) => {
          return { l: ele.name, v: ele.businessSegmentId }
        })
      setBusinessSegmentId(businessSegmentIds)
    }
  }
  const getEnterPriseData = async (searchtext = "") => {
    //Need to get this data from api

    let res = await api.g_getenterpriselist({
      roleId: profile.roleId,
      businessSegmentId: 1,
      searchText: searchtext,
      loading_key: "enterpriseID",
    })
    let enterpriseData = []
    if (res) {
      enterpriseData = res.map((ent) => {
        return {
          l: ent.name,
          v: ent.customer_azid,
          entCustomer_ID: ent.enterprise_Customer_ID,
        }
      })
      setenterpriseIDs(enterpriseData)
    }
    setenterpriseIDs(enterpriseData)
  }

  const getCustomersonBusinessSegmentIds = async () => {
    let res = await api.g_getbusinesssegmentcustomers({
      searchText: customerSearch,
      businessSegmentId: fields.businessSegmentId.v,
      loading_key: "customer_search",
    })
    if (res) {
      let customer = setCustomerData(res)
      setCustomers(customer)
    }
  }
  const setEntCustomerData = (data) => {
    return data.map((ele) => {
      return {
        l: `${ele?.customerName} - ${ele?.customerId} ${getBusinessSegmentName(
          ele?.businessSegmentId
        )}`,
        v: ele?.customerId,
        sourceSystem: ele?.sourceSystem,
        businessSegmentId: ele?.businessSegmentId,
      }
    })
  }
  function getBusinessSegmentName(businessSegmentId) {
    if (businessSegmentId > 0) {
      // eslint-disable-next-line
      for (const businessSegment in BusinessSegmentId) {
        if (BusinessSegmentId[businessSegment] === businessSegmentId) {
          return " - " + businessSegment
        }
      }
    }
    return ""
  }
  const getEnterpriseCustomersonBusinessSegmentIds = async () => {
    let res = await api.g_getbusinesssegmententerprisecustomers({
      roleId: profile.roleId,
      customerAzId: fields.enterpriseID.v,
      businessSegmentId: 1,
    })
    if (res) {
      let customer = setEntCustomerData(res)
      setEnterpriseCustomers(customer)
    }
  }

  const handleSearchChange = (e) => {
    if (e.target.name === "customerSearch") setSearch(e.target.value)
    if (e.target.name === "enterpriseID") setenterpriseSearch(e.target.value)
    if (e.target.name === "divisionSearch") setDivisionSearch(e.target.value)
  }

  const goToUserManagement = () => {
    if (checkForEdit()) {
      history.goBack()
    } else {
      history.push("/admin")
    }
  }
  const onSelectField = (title, value) => {
    let initialValue = { ...open }
    setSearch("")
    if (!checkForEdit()) {
      setenterpriseSearch("")
    }
    setOpen({
      ...initialValue,
      [title]: value,
    })
    if (!open.customerName && profile?.roleId == roles.CE_ADMIN) {
      getInitialCustomer()
    }
  }

  const onInputChange = (event) => {
    let initialValue = { ...fields, [event.target.name]: event.target.value }
    setField(initialValue)
    if (error[event.target.name]) {
      checkForErrors(initialValue)
    }
  }

  const compareInitialValues = (userData, initialValue, active = true) => {
    //existing values
    let previousValues = {
      userType: userData.userType,
      division: userData.division,
      status: true,
    }

    //changed values
    let values = {
      userType: initialValue.userType.l,
      division: initialValue.division.l,
      status: active,
    }
    //comparing both values

    if (
      Object.values(previousValues).join() === Object.values(values).join() &&
      !checkifenterpriseupdated()
    ) {
      return setEdit(false)
    } else {
      return setEdit(true)
    }
  }

  function checkifenterpriseupdated() {
    if (fields.userType.v === roles.ENTERPRISE_USER) {
      return true
    }
    return false
  }

  const onSelectValue = async (name, value) => {
    let initialValue = { ...fields, [name]: value }
    if (name === "businessSegmentId") initialValue.customerName = ""
    if (name === "enterpriseID") {
      initialValue.customerName = ""
    }
    if (name === "userType") {
      if (checkForEdit() && value.v == roles.Brand_User) {
        await getDivision(initialValue.customerName.v, value.v)
        initialValue.division = ""
      } else if (!checkForEdit()) {
        initialValue.businessSegmentId = ""
        initialValue.customerName = ""
        initialValue.enterpriseID = ""
        if (name == "userType" && value.v == roles.Brand_User) {
          if (division.length !== divisionData.length)
            fields.division = divisionData
        }
      }
    }
    if (name === "division") {
      setDivision(divisionData)
      fields.division = divisionData
    }
    if (name === "customerName") {
      setDivision([])
      await getDivision(value.v, fields.userType.v)
      initialValue.division = ""
    }

    setField({ ...initialValue })
    if (checkForEdit()) {
      compareInitialValues(state?.userData, initialValue)
    }
    if (error[name]) {
      if (
        name === "customerName" &&
        open.customerName &&
        fields.userType.v === roles.ENTERPRISE_USER
      ) {
        error.customerName = false
        return
      } else if (name === "enterpriseID" && open.enterpriseID) {
        error.enterpriseID = false
        return
      }
      checkForErrors(initialValue)
    }
  }

  const checkForErrors = (values = fields) => {
    let initialValue = { ...error }
    Object.keys(values).forEach((ele) => {
      if (values[ele]?.length === 0) {
        initialValue[ele] = true
      } else {
        initialValue[ele] = false
      }
      if (
        fields["userType"]?.v === roles.CE_ADMIN &&
        values["businessSegmentId"]?.length === 0
      ) {
        initialValue["businessSegmentId"] = true
      } else if (
        fields["userType"].v &&
        fields["userType"]?.v !== roles.CE_ADMIN
      ) {
        initialValue["businessSegmentId"] = false
      }
      if (
        values["email"].match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        initialValue["email"] = false
      } else {
        initialValue["email"] = true
      }
      if (
        fields["userType"]?.v === roles.ENTERPRISE_USER &&
        !fields["enterpriseID"].v
      ) {
        initialValue["enterpriseID"] = true
      } else {
        initialValue["enterpriseID"] = false
      }
      if (fields["userType"]?.v === roles.ENTERPRISE_USER) {
        if (fields["enterpriseID"].v) {
          let customerValidation = checkforentcustval()
          initialValue["customerName"] = customerValidation
        } else {
          initialValue["customerName"] = false
        }
      }
      if (
        fields["userType"]?.v === roles.Brand_User &&
        values["division"]?.length === 0
      ) {
        initialValue["division"] = true
      } else initialValue["division"] = false
    })
    setError(initialValue)
    if (Object.values(initialValue).includes(true)) {
      return true
    } else {
      return false
    }
  }

  const checkforentcustval = () => {
    let isCustomerValid = true
    if (fields["customerName"].length > 0) {
      fields["customerName"]?.forEach((ele) => {
        if (ele.default === true) {
          isCustomerValid = false
        }
      })
    }
    return isCustomerValid
  }
  const onUpdateActive = () => {
    setDeActivate(!deActivate)
    compareInitialValues(state?.userData, fields, deActivate)
  }

  const submit = async () => {
    if (!checkForErrors()) {
      let data = {
        email: "",
        firstName: "",
        lastName: "",
        roleId: 6,
        customers: [],
        enterpriseID: "",
        isActive: !deActivate,
      }
      let formData = {
        ...data,
      }
      formData.email = fields["email"]
      formData.firstName = fields["firstName"]
      formData.lastName = fields["lastName"]
      formData.roleId = fields["userType"]?.v
      formData.enterpriseID = 0
      if (formData.roleId === roles.ENTERPRISE_USER) {
        var customerdata = []
        customerdata = fields["customerName"]?.map((ele) => {
          return { id: ele?.v, isDefault: ele?.default }
        })
        formData.enterpriseID = fields["enterpriseID"]?.entCustomer_ID
        formData.customers = customerdata
      } else {
        formData.customers = [
          { id: fields["customerName"]?.v, isDefault: true },
        ]
      }
      formData.customerId = fields["customerName"]?.v
      if (fields["userType"]?.v === roles.Brand_User) {
        formData.division = fields["division"]?.v
      } else {
        formData.division = ""
      }

      if (state?.userData) {
        let res = await api.pa_edituser({
          loading_key: "edit_user",
          ...formData,
        })
        if (res) {
          let defaultcutomerName = ""
          if (fields["userType"]?.v === roles.ENTERPRISE_USER) {
            defaultcutomerName = fields.customerName.filter(
              (x) => x.default == true
            )[0].l
          } else {
            defaultcutomerName = fields["customerName"]?.l
          }
          history.push({
            pathname: "/admin/usercheckout",
            state: {
              ...formData,
              customerName: defaultcutomerName,
              roleName: fields["userType"]?.l,
              businessSegment: fields["businessSegmentId"]?.l,
            },
            createFlag: false,
          })
        }
      } else {
        let res = await api.p_createUser({
          loading_key: "create_user",
          ...formData,
        })
        if (res) {
          let defaultcutomerName = ""
          if (fields["userType"]?.v === roles.ENTERPRISE_USER) {
            defaultcutomerName = fields.customerName.filter(
              (x) => x.default == true
            )[0].l
          } else {
            defaultcutomerName = fields["customerName"]?.l
          }
          history.push({
            pathname: "/admin/usercheckout",
            state: {
              ...formData,
              customerName: defaultcutomerName,
              roleName: fields["userType"]?.l,
              businessSegment: fields["businessSegmentId"]?.l,
              res: res,
            },
            createFlag: true,
          })
        }
      }
    }
  }
  return (
    <Component
      {...props}
      {...{
        goToUserManagement,
        onSelectField,
        error,
        fields,
        open,
        setOpen,
        onInputChange,
        onSelectValue,
        userTypes,
        customers,
        enterpriseCustomers,
        handleSearchChange,
        submit,
        profile,
        checkForEdit,
        onUpdateActive,
        isEdit,
        roles,
        businessSegmentId,
        enterpriseIDs,
        division,
      }}
    />
  )
}

export default enhancer
