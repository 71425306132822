import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import api from "api"
import { format, isPast, isToday } from "date-fns"
import { SortByService, roles } from "config"
import { formattedDate } from "shared"
import { useSelector } from "react-redux"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    let [activeService, setActiveService] = useState(0)
    let [sortBy, updateSortBy] = useState(SortByService[0])
    let [pageNo, gotoPage] = useState(-1)
    let [workOrderFilters, setWorkOrderFilters] = useState({
      NoShip: false,
      Shipped: false,
      partialNoShip: false,
    })
    let history = useHistory()
    const changeRequest = () => {
      history.push("/requestservice/change_request", {
        service: activeService,
        from: "myschedule",
      })
    }

    //this flag is used for workOrderType filters
    let flag = true

    const serviceHistory = (workOrderAzId) => {
      /* Passing workOrderAzId  to use in servicedetailspage */
      const newWindow = window.open(
        `myschedule/serviceorder/${workOrderAzId}`,
        "_blank",
        "noopener,noreferrer"
      )
      if (newWindow) newWindow.opener = null
    }
    let { filters, resetMyschedule, isPreviewCustomer, isSPAKCustomer } = props
    let [serviceData, setServiceData] = useState([])
    const [showfilterhint, setshowfilterhint] = useState(false)
    const [IsFilterHintshown, setIsFilterHintshown] = useState(false)
    const user = useSelector((state) => state.profile)

    let fetchServices = async (workOrderType, pgNo = false) => {
      let n_dates = [filters.fiscal_span.from, filters.fiscal_span.to]
      let myscheduleData = {
        scheduleTypes: filters.schedule_type,
        serviceTypes: filters.service_type,
        regions: filters.divisions_regions,
        divisions: filters.brands,
        states: filters.states,
        stores: filters.stores,
      }
      if (user?.roleId == roles.Brand_User) {
        myscheduleData.divisions = []
        myscheduleData.divisions.push(user.division)
      }

      try {
        let scheduleData = await api.p_schedulesServices({
          dates: n_dates,
          loading_key: "schedules-services",
          sort: sortBy.v,
          index: pgNo ? 1 : pageNo,
          size: 10,
          workOrderType: workOrderType ? workOrderType : "",
          ...myscheduleData,
        })

        if (!scheduleData) return
        scheduleData.items.forEach((d) => {
          // d.formatDate = format(new Date(d.serviceDatetime), "dd LLL")
          let date = new Date(d.serviceDatetime)
          let { storeAddress: addr } = d
          d.formatTime = format(date, "dd LLL yyyy")
          d.canChange = !(isPast(date) || isToday(date))
          d.canServiceHistory = isPast(date)
          // d.workOrderAzId= d.workOrderAzId
          d.title = `${d.storeNumber}, ${addr.address}, \n ${addr.city}, ${addr.state}`
          d.serviceTypes.forEach((d2) => {
            if (d2.estimatedServiceDate)
              d2.estimatedServiceDate = formattedDate(d2.estimatedServiceDate)
          })
        })
        setActiveService(scheduleData.items[0])
        setServiceData(scheduleData)
        if (!IsFilterHintshown) setIsFilterHintshown(true)
      } catch (error) {
        setActiveService(0)
        setServiceData([])
      }
    }
    useEffect(() => {
      if (IsFilterHintshown) {
        setshowfilterhint(true)
        setTimeout(() => {
          setshowfilterhint(false)
        }, 120000)
      }
    }, [IsFilterHintshown])
    useEffect(() => {
      if (!filters.fiscal_span) return
      gotoPage(-1)

      //this useEffect will execute when you click on apply
      //below lines ensure if any workorderType Icon are checked then
      //to make them uncheck
      let initalValue = {
        NoShip: false,
        Shipped: false,
        partialNoShip: false,
      }
      if (props?.filterStatus?.length > 0)
        setWorkOrderFilters({
          ...initalValue,
          [props?.filterStatus]: !initalValue[props?.filterStatus],
        })
      else
        setWorkOrderFilters({
          ...initalValue,
        })
      setTimeout(() => {
        gotoPage(1)
      }, 10)
    }, [filters])

    useEffect(() => {
      let workOrderType = checkFilter()
      //filters are already selected
      if (workOrderType?.length > 0) {
        //when page index not 1
        if (pageNo !== 1) {
          flag = workOrderType
          gotoPage(1)
        } else {
          fetchServices(workOrderType[0][0])
        }
      } else {
        //else will when filter are not selected
        if (pageNo !== 1) {
          setTimeout(() => {
            gotoPage(1)
          }, 10)
        } else {
          fetchServices()
        }
      }
    }, [sortBy])

    useEffect(() => {
      if (pageNo == -1) return
      let workOrderType = checkFilter()
      //this condition executes when flag contains workordertype
      if (typeof flag === "string") {
        fetchServices(flag)
        flag = false
      } else if (workOrderType?.length > 0) {
        // this condition executes when filter of workordertype is checked
        fetchServices(workOrderType[0][0])
      } else {
        fetchServices()
      }
    }, [pageNo])

    useEffect(() => {
      setServiceData([])
    }, [resetMyschedule])

    const updatedupdateSortByOption = (v) => {
      updateSortBy({ l: v.l, v: v.v })
    }

    /**
     *  We are accepting two parameters
     * @param workOrderType is filter types
     * @param pagination is a boolean value
     * if it is true then it is invoked when users hit pagination buttons
     */
    const fetchServicesWithShips = (workOrderType, pagination = false) => {
      //this is executed when we click on filter(fullShip,noship and partialNoship) icons
      if (!pagination) {
        let initalValue = {
          NoShip: false,
          Shipped: false,
          partialNoShip: false,
        }
        if (workOrderType?.length > 0)
          setWorkOrderFilters({
            ...initalValue,
            [workOrderType]: !workOrderFilters[workOrderType],
          })

        //if we the same filter again then it will be unselected
        if (workOrderFilters[workOrderType]) {
          if (pageNo !== 1) {
            gotoPage(1)
          } else {
            fetchServices()
          }
        } else {
          if (pageNo !== 1) {
            flag = workOrderType
            gotoPage(1)
          } else {
            fetchServices(workOrderType, true)
          }
        }
      } else {
        fetchServices(workOrderType)
      }
    }

    const checkFilter = () => {
      return Object?.entries(workOrderFilters).filter((ele) => !!ele[1])
    }

    return (
      <Component
        {...props}
        {...{
          changeRequest,
          serviceData,
          setActiveService,
          activeService,
          sortBy,
          updateSortBy,
          SortByService,
          pageNo,
          gotoPage,
          updatedupdateSortByOption,
          serviceHistory,
          isPreviewCustomer,
          isSPAKCustomer,
          fetchServicesWithShips,
          workOrderFilters,
          checkFilter,
          showfilterhint,
        }}
      />
    )
  }

export default enhancer
