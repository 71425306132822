import React from "react"
import Image from "shared/HelpImage/index"

function RetailDashboard() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Retail Dashboard: Header Bar</h3>

      <p className="text-justify pl-3 pt-2">
        Once logged in, the dashboard is in view. The header bar on the top of
        screen will stay the same throughout the entire site. The{" "}
        <Image src="logo.png" alt="Logo" height="23" /> Logo will take you back
        to the dashboard from any screen you are on in the site. The{" "}
        <Image src="news.png" alt="Logo" height="20" /> will take you to a new
        webpage, cleanearthinc.com/news where the user can get the lasts
        information about Clean Earth.
      </p>
      <p className="text-justify pl-3 pt-2">
        The <Image src="requestservicebtn.png" alt="Logo" height="20" /> button
        takes you to the “Request Service page. The user can request service,
        report and issue, request supplies, or change a request.
      </p>
      <div className="img-section">
        <Image src="headerBar.png" alt="Header Bar" />
      </div>
      <p className="text-justify pl-3 pt-3">
        The <Image src="helpBtn.png" alt="Logo" height="25" /> button will take
        the user to a Client Portal Help Guide.
      </p>
      <p className="text-justify pl-3">
        The <Image src="alertIcon.png" alt="Logo" height="25" /> icon will show
        any Alerts that have not been read. The user can also click on the bell
        to see all their alerts.
      </p>
      <p className="text-justify pl-3">
        The <Image src="logoutIcon.png" alt="Logo" height="25" /> icon is
        another way for the user to logout of the site.
      </p>

      <h3 className="pt-4 text-left pl-3">Retail Dashboard: My Account</h3>
      <p className="text-justify pl-3">
        Once logged in, the dashboard is in view.
      </p>
      <p className="text-justify pl-3">
        One thing to note is your name or company name appears in the top left
        hand side of your screen. You can change this by clicking on the Pencil
        icon under your initials.
      </p>
      <div className="img-section">
        <Image src="editProfile.png" alt="" />
      </div>
      <p className="text-justify pl-3 pt-4">
        Then click save changes and go back to the dashboard
      </p>
      <div className="img-section">
        <Image src="myAccount.png" alt="" />
      </div>

      <h3 className="pt-4 text-left pl-3">Retail Dashboard: Weekly Overview</h3>
      <p className="text-justify pl-3">
        The weekly overview dashboard shows informational tiles that run across
        the top of the screen, waste process summary in the middle, “My
        Schedule” on the right side, and “My Documents” at the bottom.
      </p>
      <div className="img-section">
        <Image src="dashboard.png" alt="" />
      </div>
    </div>
  )
}
export default RetailDashboard
