import React from "react"
import { useHistory, useLocation } from "react-router-dom"

let enhancer = (Component) => (props) => {
  let history = useHistory()
  let location = useLocation()

  let requestService = location.state.data
  const onDashboardClick = (e) => {
    history.push("/")
  }
  const onNewServiceRequestClick = (e) => {
    history.push("/requestservice")
  }

  return (
    <Component
      {...props}
      {...{
        onDashboardClick,
        onNewServiceRequestClick,
        requestService,
      }}
    />
  )
}

export default enhancer
