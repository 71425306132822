import React from "react"
import Image from "shared/HelpImage/index"

function FiveInformationTiles() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">
        Full Circle Dashboard: Five information Tiles{" "}
      </h3>

      <p className="text-justify pl-3 pt-2">
        The Five tiles in this section displays the measure of their respective
        metric for the last four available quarters. Each tile displays the
        numeric value illustrating the last four available quarter. On the top
        right-hand corner of Recycle/Reuse, Waste to energy, Landfill and
        Incineration tile is a percentage.
      </p>
      <Image src="fiveInformationTiles.png" />
      <p className="text-justify pl-3 pt-2">
        <b>Total Weight Processed tile – </b> This is the combined weight of all
        the hazardous and non- hazardous waste processed during the specified
        quarter. <i> Note: that the weight has been converted into Tons. </i>
      </p>
      <p className="text-justify pl-3 pt-2">
        <b>Recycle/ Reuse tile – </b>This is the amount of waste that was
        processed by the recycle/ reuse method for that date range.
      </p>
      <p className="text-justify pl-3 pt-2">
        <b>Waste To Energy tile – </b>This is the amount of waste that was
        processed by the waste to energy method for that date range
      </p>
      <p className="text-justify pl-3 pt-2">
        <b>Landfill tile – </b>This is the amount of waste that was processed by
        the Landfill method for that date range
      </p>
      <p className="text-justify pl-3 pt-2">
        <b>Incineration tile – </b> This is the amount of waste that was
        processed by the Incineration method for that date range
      </p>
    </div>
  )
}
export default FiveInformationTiles
