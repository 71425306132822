import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import api from "api"
import { toast } from "react-toastify"
import { syncUserProfile, getProfileAlertObject } from "shared"
import { alerts, alertsCOMMUser } from "./constants"
import { roles } from "config"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    const { alerts_preference } = useSelector((state) => state.profile)
    const profile = useSelector((state) => state.profile)
    const isCECommercialUser = profile?.roleId == roles?.CE_COMMERCIAL_USER
    const alertsSource = isCECommercialUser ? alertsCOMMUser : alerts
    const mergeALerts =
      alerts_preference && !alerts_preference.includes("profile_expiring")
        ? JSON.parse(alerts_preference)
        : alerts_preference && alerts_preference.includes("profile_expiring")
        ? getProfileAlertObject(JSON.parse(alerts_preference))
        : null
    const parseAlerts = JSON.parse(alerts_preference) || {
      profile_expiring: {
        notification: false,
        email: false,
      },
      profile_creation: {
        notification: false,
        email: false,
      },
      profile_approved: {
        notification: false,
        email: false,
      },
      generator_creation: {
        notification: false,
        email: false,
      },
    }
    const [allEmailChecked, setAllEmailChecked] = useState(false)
    const [success, setsuccess] = useState(false)
    const [data, setData] = useState(parseAlerts)
    const [allNotificationsChecked, setAllNotificationsChecked] =
      useState(false)

    const updateSendMeAllUpdate = async (e, key) => {
      let values = {}
      if (e.currentTarget.checked) {
        alertsSource.forEach((val) => {
          values[val] = {
            ...data?.[val],
            [key]: true,
          }
        })
        if (key === "email") {
          setAllEmailChecked(true)
        } else {
          setAllNotificationsChecked(true)
        }
      } else {
        alertsSource.forEach((val) => {
          values[val] = {
            ...data?.[val],
            [key]: false,
          }
        })
        if (key === "email") {
          setAllEmailChecked(false)
        } else {
          setAllNotificationsChecked(false)
        }
      }
      setData(values)
    }

    const cancelAction = async () => {
      const info = {
        profile_expiring: {
          notification: true,
          email: true,
        },
        profile_creation: {
          notification: true,
          email: true,
        },
        profile_approved: {
          notification: true,
          email: true,
        },
        generator_creation: {
          notification: true,
          email: true,
        },
      }
      setData(info)
      let notificationCount = 0
      let emailCount = 0
      alertsSource.forEach((val) => {
        if (parseAlerts?.[val]?.email) {
          emailCount = emailCount + 1
        }
        if (parseAlerts?.[val]?.notification) {
          notificationCount = notificationCount + 1
        }
      })
      setAllEmailChecked(true)
      setAllNotificationsChecked(true)
    }

    const onSubmit = async () => {
      const combinedData = mergeALerts ? { ...data, ...mergeALerts } : data
      const res = await api.pa_user({
        alerts_preference: JSON.stringify(combinedData),
        loading_key: "alertsettings",
      })

      if (!res) return
      syncUserProfile()
      toast.success("Your settings has been updated")
    }

    const onClickSavePreferences = async (val, key) => {
      let values = {
        ...data,
        [val]: {
          ...data?.[val],
          [key]: !data[val][key],
        },
      }
      let notificationCount = 0
      let emailCount = 0
      alertsSource.forEach((val) => {
        if (values?.[val]?.email) {
          emailCount = emailCount + 1
        }
        if (values?.[val]?.notification) {
          notificationCount = notificationCount + 1
        }
      })
      setAllEmailChecked(
        isCECommercialUser ? emailCount === 1 : emailCount === 4
      )
      setAllNotificationsChecked(
        isCECommercialUser ? notificationCount === 1 : notificationCount === 4
      )
      setData(values)
    }

    useEffect(() => {
      allChecked()
      syncUserProfile()
    }, [])

    const onCloseSuccess = () => {
      setsuccess(!success)
    }

    const allChecked = () => {
      let notificationCount = 0
      let emailCount = 0
      alertsSource.forEach((val) => {
        if (data?.[val]?.email) {
          emailCount = emailCount + 1
        }
        if (data?.[val]?.notification) {
          notificationCount = notificationCount + 1
        }
      })
      setAllEmailChecked(
        isCECommercialUser ? emailCount === 1 : emailCount === 4
      )
      setAllNotificationsChecked(
        isCECommercialUser ? notificationCount === 1 : notificationCount === 4
      )
    }

    return (
      <Component
        {...props}
        {...{
          onCloseSuccess,
          allChecked,
          updateSendMeAllUpdate,
          onClickSavePreferences,
          allEmailChecked,
          allNotificationsChecked,
          alerts_preference,
          onSubmit,
          data,
          cancelAction,
        }}
      />
    )
  }

export default enhancer
