import React from "react"
import classnames from "classnames"
import Image from "shared/Image"

const Pagination = (props) => {
  let { pageNo, pageSize = 10, totalRecords, gotoPage, hastrue } = props
  let maxPages = hastrue ? 9 : 5
  let lastPage = Math.ceil(totalRecords / pageSize)

  let hasPreviousPage = pageNo == 1
  let hasNextPage = pageNo == lastPage

  let startPage, endPage
  if (lastPage <= maxPages) {
    startPage = 1
    endPage = lastPage
  } else {
    let maxPagesBeforepageNo = Math.floor(maxPages / 2)
    let maxPagesAfterpageNo = Math.ceil(maxPages / 2) - 1
    if (pageNo <= maxPagesBeforepageNo) {
      startPage = 1
      endPage = maxPages
    } else if (pageNo + maxPagesAfterpageNo >= lastPage) {
      startPage = lastPage - maxPages + 1
      endPage = lastPage
    } else {
      startPage = pageNo - maxPagesBeforepageNo
      endPage = pageNo + maxPagesAfterpageNo
    }
  }

  let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
    (i) => startPage + i
  )

  let toSize = pageNo * pageSize
  if (toSize > totalRecords) toSize = totalRecords

  return (
    <div className={`pagination bottom  ${hastrue ? "col-11" : "col-12"}`}>
      <div className={`float-left ${hastrue ? "d-none" : ""}`}>
        {`Showing ${
          (pageNo - 1) * pageSize + 1
        }  to ${toSize}  of ${totalRecords} entries`}
      </div>
      {totalRecords >= pageSize + 1 && (
        <div className="float-right">
          <button
            onClick={() => gotoPage(1)}
            disabled={hasPreviousPage}
            className={`btn-link page ${hastrue ? "d-none" : ""}`}
            style={{
              backgroundColor: "transparent",
              border: "1px solid transparent",
            }}
          >
            First
          </button>{" "}
          <button
            onClick={() => gotoPage(pageNo - 1)}
            disabled={hasPreviousPage}
            className={`btn-link page ${hastrue ? "arrow" : ""}`}
            style={{
              backgroundColor: "transparent",
              border: "1px solid transparent",
            }}
          >
            {hastrue ? (
              <Image
                className="left view-icon"
                src="ic_up arrow.svg"
                height="10px"
                width="10px"
                alt=""
              />
            ) : (
              "Previous"
            )}
          </button>
          {pages.map((d) => (
            <button
              key={d}
              onClick={() => gotoPage(d)}
              className={classnames("btn-link page", { active: d == pageNo })}
            >
              {d}
            </button>
          ))}
          <button
            onClick={() => gotoPage(pageNo + 1)}
            disabled={hasNextPage}
            className={` btn-link ${hastrue ? "arrow" : ""}`}
            style={{
              backgroundColor: "transparent",
              border: "1px solid transparent",
            }}
          >
            {hastrue ? (
              <Image
                className="right view-icon"
                src="ic_up arrow.svg"
                height="10px"
                width="10px"
                alt=""
              />
            ) : (
              "Next"
            )}
          </button>{" "}
          <button
            className={` btn-link ${hastrue ? "d-none" : ""}`}
            onClick={() => gotoPage(lastPage)}
            disabled={hasNextPage}
            style={{
              backgroundColor: "transparent",
              border: "1px solid transparent",
            }}
          >
            Last
          </button>{" "}
        </div>
      )}
    </div>
  )
}
export default Pagination
