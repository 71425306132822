import React from "react"
import OutsideClickHandler from "react-outside-click-handler"
import Loading from "shared/Loading"

const Select = (props) => {
  const {
    open,
    onSelectField,
    name,
    search,
    placeholder,
    options,
    onSelectValue,
    value = {},
    handleSearchChange,
    loadingId,
    searchField,
    parentCls = "",
    searchClass = "",
    cls = "",
    hasId = false,
    disabled = false,
    clear = false,
  } = props

  return (
    <OutsideClickHandler onOutsideClick={() => onSelectField(name, false)}>
      <div
        className={`col-sm-12 col-md-12 col-lg-12 input-text dp-flx p-0 ${
          open && parentCls.length > 0 ? "open open-border-label" : ""
        } ${cls} ${disabled ? "disabledBg" : ""}`}
        onClick={() => onSelectField(name, !open)}
        style={{
          pointerEvents: disabled ? "none" : "auto",
          borderRadius: disabled ? 20 : "",
        }}
      >
        <div
          className={`col-sm-12 col-md-12 col-lg-12 input-box ${
            open ? (parentCls.length > 0 ? "" : "open open-border-label") : ""
          }`}
        >
          <div
            className={`text-truncate-dropdown ${
              !value ? "place" : "place-select "
            } ${searchClass && "place-label"}`}
            title={value?.hasOwnProperty("l") ? value["l"] : ""}
          >
            {value?.hasOwnProperty("l") ? value["l"] : placeholder}
          </div>
        </div>
        <div className={"drop-arrows create-down"}></div>
      </div>
      {open && (
        <div
          className={`drop-option controls-dropdown ${
            open && "open-border-options"
          }`}
        >
          {search && (
            <div className="search-box search-box-padding">
              <input
                className="search-field"
                type="text"
                name={searchField}
                placeholder="search"
                onChange={(e) => handleSearchChange(e)}
              />
            </div>
          )}
          <Loading id={loadingId} height={110}>
            <div className="drop-down-options">
              {clear && (
                <div className="clearallspace">
                  <span onClick={clear} className="clearall">
                    {" "}
                    Clear{" "}
                  </span>
                </div>
              )}
              {options?.map((ele, i) => {
                return (
                  <div
                    className={`opt ${cls}`}
                    key={hasId ? ele.v : i}
                    onClick={() => {
                      onSelectValue(name, ele)
                      onSelectField(name, false)
                    }}
                  >
                    {ele.l}
                  </div>
                )
              })}
            </div>
          </Loading>
        </div>
      )}
    </OutsideClickHandler>
  )
}
export default Select
