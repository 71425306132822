import { SET_STORE, DELETE_STORE } from "./actionType"

const stores = (state = [], action) => {
  let newState = []
  switch (action.type) {
    case SET_STORE:
      newState = [...action.store]
      return newState
    case DELETE_STORE:
      return newState
    default:
      return state
  }
}

export default stores
