import { login_slider_config } from "config"
import React, { useEffect, useState } from "react"
import Slider from "react-slick"
import { importAll } from "shared"
import Image from "shared/Image"

let audio = new Audio()

function PPT() {
  const images = importAll(
    require.context("assets/help/PPT/images", false, /\.(png|PNG|JPG)$/)
  )

  const audios = importAll(
    require.context("assets/help/PPT/audio", false, /\.(mp3)$/)
  )

  const [slides, setSlides] = useState([])
  const [currentIndex, setCurrentIdx] = useState(0)

  useEffect(() => {
    window.scroll(0, 0)
    let data = []
    Object.keys(images).map((src) =>
      data.push({
        src: images[src],
        audio: audios[`media${src.split(".")[0]}.mp3`]?.default,
      })
    )
    setSlides(data)
    return () => {
      audio.pause()
      audio = new Audio()
    }
  }, [])

  const onSlideChange = (index) => {
    audio.src = slides[index].audio
    setCurrentIdx(index)
  }

  const playAudio = () => {
    if (!audio.src) audio.src = slides[0].audio
    audio.play()
  }

  return (
    <div className="row ppt-slides">
      <div className="col-12">
        <Slider
          {...login_slider_config}
          autoplay={false}
          arrows={true}
          speed={100}
          afterChange={onSlideChange}
        >
          {slides.map((d, i) => (
            <img src={d.src} className="ppt-slide" key={i} />
          ))}
        </Slider>
        <div className="text-left mt-2">
          <span>Total Slides: {slides.length}</span>
          <span className="ml-4">Current Slide: {currentIndex + 1}</span>
        </div>
        <Image
          src="audio_icon.png"
          className="audio-icon"
          onClick={playAudio}
        />
      </div>
    </div>
  )
}
export default PPT
