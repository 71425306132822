import React from "react"
import Image from "shared/HelpImage/index"

function MyOrdersMenu() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">My Orders Menu option</h3>
      <div className="img-section pt-4">
        <Image className="" src="myorderstore.png" alt="My Orders Image" />
      </div>
      <p className="text-justify pl-3 pt-2">
        <p>
          The My orders menu option brings the user to the “my order” page. This
          page gives the user filter to search orders based on service date.
        </p>
        <p>
          There are also Predefined searches or date ranges for the user to
          choose.
        </p>
      </p>
      <div className="img-section pt-4">
        <Image className="" src="myordergridstore.png" alt="My Orders Image" />
      </div>
      <p className="text-justify pl-3 pt-4">
        <p>
          My Order overview provides a detailed view of all the schedules and
          service status for selected stores.
        </p>
        <p>
          The list of orders from the search results is shown in a tabular
          format.
        </p>
        <p>
          User can click on the ‘View Details’ button on each row to see more
          details about the order.
        </p>
      </p>
      <div>{/* <Image className="" src="workorderinfo" alt="" /> */}</div>
      <p className="text-justify pl-3 pt-4">
        <p>
          The user can click on the service details button to see more details
          on their specific pick up.
        </p>
        <p>
          This screen will show the user the Work order information. From waste
          composition to document lines of the shipping document and supporting
          documents like copies of manifests.
        </p>
      </p>
    </div>
  )
}
export default MyOrdersMenu
