import React from "react"
import Loading from "shared/Loading"
import Image from "shared/Image"
import { FormattedMessage } from "react-intl"
import classnames from "classnames"

function SupportingDocument(props) {
  let {
    supportingDocs,
    downloadDoc,
    viewDoc,
    openFolder,
    selectFolder,
    selectAllFolder,
    isAllSelected,
    downloadSelectedFolder,
    isSomeSelected,
  } = props
  const extensionToIconMap = {
    pdf: "ic_pdfservice.svg",
    xls: "ic_excel.svg",
  }
  return (
    <div className="row m-0 borders mb-3 supportingDocs">
      <Loading id="documents">
        {supportingDocs?.length === 0 ? (
          <div className="col-12 text-center mt-5">
            <div className="nodata">
              <div>
                <Image
                  className="norecords"
                  src="no_records_bg.svg"
                  height="118px"
                  width="118px"
                  alt=""
                />
              </div>
              <FormattedMessage id="nodata.supportingDocument" />
            </div>
          </div>
        ) : (
          <div className={`col-12 p-0 m-0 `}>
            <div
              className={`col-12 p-0 m-0  ${
                supportingDocs?.length > 0 ? "" : "d-none"
              }`}
            >
              <div className="row pl-0 m-0">
                <div className="col-8  m-0 text-left">
                  <div className="serviceheading">
                    <label className="check-container selectedAll">
                      <input
                        type="checkbox"
                        onChange={selectAllFolder}
                        checked={isAllSelected()}
                      />
                      <FormattedMessage id="serviceorder.selectall" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="col-4  m-0 text-left">
                  <div
                    className={classnames("serviceheading download", {
                      "none-edit": !isSomeSelected(),
                    })}
                    onClick={downloadSelectedFolder}
                  >
                    <FormattedMessage id="serviceorder.download" />
                  </div>
                </div>
              </div>
            </div>
            <div className="scrollbar">
              {supportingDocs.length > 0 &&
                supportingDocs.map((d, i) => (
                  <div className="row pl-0 m-0" key={i}>
                    <div className="col-12 p-0 m-0">
                      <div className="row pl-0 m-0 bc-color">
                        <div className="col-8  m-0 text-left">
                          <div className="serviceheading">
                            <label className="check-container selectEach">
                              <input
                                type="checkbox"
                                onChange={() => selectFolder(i)}
                                checked={d.selected}
                              />
                              {d.manifestTrackingNo}
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>

                        <div className="col-3  m-0 text-right">
                          <div
                            className="serviceheading text-right cursor-pointer"
                            onClick={() => openFolder(i)}
                          >
                            {d.open ? (
                              <Image
                                className="view-icon"
                                src="ic_up arrow.svg"
                                height="8px"
                                width="14px"
                                alt=""
                              />
                            ) : (
                              <Image
                                className="view-icon rotate"
                                src="ic_up arrow.svg"
                                height="8px"
                                width="14px"
                                alt=""
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {d.open && (
                      <div className="col-12 p-0 m-0">
                        {d.supportingDocLists.map((d1, k) => (
                          <div className="row p-0 m-0" key={k}>
                            <div className="col-8 file m-0 text-left">
                              <div className="serviceheading">
                                <Image
                                  className=""
                                  src={extensionToIconMap[d1.extension]}
                                  height="16px"
                                  width="16px"
                                  alt=""
                                />
                                <span
                                  className={classnames({
                                    filetitle: d1.extension === "pdf",
                                  })}
                                  title={d1.fileName}
                                >
                                  {d1.documentType}
                                </span>
                                {/* <span>{`${"."}` + d1.extension}</span> */}
                              </div>
                            </div>

                            <div className="col-3  m-0 d-flex imagesection">
                              {d1.extension == "pdf" && (
                                <div className="serviceheading text-right w-50">
                                  <Image
                                    className="view-icon"
                                    src="ic_viewservice.svg"
                                    height="20px"
                                    width="20px"
                                    alt=""
                                    onClick={(e) => viewDoc(d1)}
                                  />
                                </div>
                              )}
                              <div className="serviceheading text-left w-50 pl-3">
                                <Image
                                  className="view-icon"
                                  src="ic_downloadservice.svg"
                                  height="16px"
                                  width="16px"
                                  alt=""
                                  onClick={(e) => downloadDoc(d1)}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
      </Loading>
    </div>
  )
}
export default SupportingDocument
