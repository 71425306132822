import {
  REACT_APP_INDIVIDUAL_CLIENT_ID as clientId,
  REACT_APP_INDIVIDUAL_TENANT_ID as tenantID,
  REACT_APP_INDIVIDUAL_AUTHORITY_DOMAIN as authorityDomain,
} from "config"

let { protocol, host } = window.location
let redirectUri = `${protocol}//${host}`

const signIn = "B2C_1A_SIGNUP_SIGNIN"

export const individualConfig = {
  auth: {
    clientId,
    redirectUri,
    authority: `https://${authorityDomain}/${tenantID}/${signIn}`,
    knownAuthorities: [authorityDomain],
  },
  cache: {
    cacheLocation: `localStorage`,
    storeAuthStateInCookie: false,
  },
}
