import React, { Fragment } from "react"
import enhancer from "./enhancer"
import Administrator from "./Admin"

function Corporate(props) {
  return (
    <Fragment>
      <Administrator />
    </Fragment>
  )
}
export default enhancer(Corporate)
