import * as dfns from "date-fns"
import _, { isNil } from "lodash"
import { toast as toast_c } from "react-toastify"
import { useState, useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import { useIntl } from "react-intl"
import axios from "api/axios"
import JSZip from "jszip"
import { saveAs } from "file-saver"
import { createIntl, createIntlCache } from "react-intl"
import messages from "local/en.json"
import store from "store"
import api from "api"
import { useSelector } from "react-redux"
import { STORE_MANAGER } from "config"
import { ADMIN, BusinessSegmentId, roles } from "config"

const cache = createIntlCache()
const intl = createIntl({ locale: "en-US", messages }, cache)

export let formatMessage = (id) => {
  let msg = intl.formatMessage({ id, defaultMessage: "" })
  if (msg == id) return ""
  return msg
}

export let dateForApi = (date, format) => {
  if (isNil(format)) format = "yyyy-MM-dd"
  return dfns.format(date, format)
}

const NOT_AVAILABLE = "Not Available"
export let formattedDate = (dt, showYr = true) => {
  try {
    const date = new Date(dt)
    if (date.getFullYear() < 2000) {
      return NOT_AVAILABLE
    }
    return dfns.format(date, showYr ? "MMM dd , yyyy" : "MMM dd")
  } catch (e) {
    return NOT_AVAILABLE
  }
}

export let toast = (msg) => {
  toast_c.dismiss()
  toast_c(msg)
}

export const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

export const weeks = ["W0", "W1", "W2", "W3", "W4", "W5", "W6", "W7"]

export const monthNames = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
]

export const fixMissingDays = (arr, key) => {
  let newArr = []
  weekDays.forEach((d) => {
    let id = _.findIndex(arr, { day: d })
    if (id == -1) newArr.push({ day: d, [key]: 0 })
    else newArr.push(arr[id])
  })
  return newArr
}

export const capitalize = (str) =>
  str.length
    ? str[0].toUpperCase() + str.slice(1).replace(/_/g, " ").toLowerCase()
    : ""

export const toPascal = (str) => str.toLowerCase().replace(/ /g, "_")

// export const tabletDim = { minWidth: 768, maxWidth: 991 }
const tabletDim = {
  query: "(max-device-width: 1224px)",
}

export function useIsTablet() {
  return useMediaQuery(tabletDim)
}

export function useWindowDimension() {
  const [dimension, setDimension] = useState([
    window.innerWidth,
    window.innerHeight,
  ])
  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      setDimension([window.innerWidth, window.innerHeight])
    }, 100) // 100ms
    window.addEventListener("resize", debouncedResizeHandler)
    return () => window.removeEventListener("resize", debouncedResizeHandler)
  }, []) // Note this empty array. this effect should run only on mount and unmount
  return dimension
}

function debounce(fn, ms) {
  let timer
  return (_) => {
    clearTimeout(timer)
    timer = setTimeout((_) => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

export const useToday = () => {
  const params = new URLSearchParams(window.location.search)
  let today = params.get("today")
  if (today) {
    try {
      let date = dfns.parse(today, "dd-MM-yyyy", new Date())
      return date
    } catch (error) {}
  }
  return new Date()
}

export const round = (x) => _.round(x, 2)

export const pdfToObject = async (url, fileName) => {
  let res = await axios({
    method: "get",
    url,
    responseType: "blob",
    loading_key: "documents",
  })

  if (!res.data) return
  let blobby = res.data

  return window.URL.createObjectURL(blobby)
}

export const download = async (url, fileName) => {
  let anchor = document.createElement("a")
  document.body.appendChild(anchor)

  let objectUrl = await pdfToObject(url, fileName)
  anchor.href = objectUrl
  anchor.download = fileName
  anchor.click()

  window.URL.revokeObjectURL(objectUrl)
}

export const openDocInNewWindow = async (url, fileName, title) => {
  const win = window.open(
    `/pdfviewer?url=${encodeURIComponent(url)}&name=${encodeURIComponent(
      fileName
    )}`,
    "_blank"
  )
  win.focus()
}

export const isEmail = (email) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
}

export const downloadZip = async (urls, folderName, checkPath = true, dt) => {
  let zip = new JSZip()
  const urlsArr = urls.map((d, i) =>
    axios({
      method: "get",
      url: d.url,
      responseType: "blob",
      loading_key: i == 0 && "documents",
    })
  )

  try {
    const resp = await Promise.all(urlsArr)
    const data = resp.map((d, i) => ({ file: d.data, name: urls[i].name }))

    data.forEach((d) => {
      let name = d.name
      if (checkPath && name.includes("/")) {
        const path = name.split("/")
        name = path[path.length - 1]
      }
      zip.file(name, d.file)
    })

    const date = dfns.format(dt ? new Date(dt) : new Date(), "MMddyyyy")
    const fileName = `${folderName}-${date}.zip`

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, fileName)
    })
  } catch (e) {
    // eslint-disable-next-line
    console.log(e)
  }
}

export const useDocumentTitle = (title) => {
  let { formatMessage } = useIntl()
  const defaultTitle = formatMessage({ id: "title" })

  useEffect(() => {
    if (!title || title == "") document.title = defaultTitle
    else document.title = `${title} - ${defaultTitle}`
  }, [title])

  useEffect(
    () => () => {
      document.title = defaultTitle
    },
    []
  )
}

export const syncUserProfile = async (loading_key = "loading_profile") => {
  const profile = await api.g_user({ loading_key })
  if (profile) {
    profile.isLoading = false
    store.dispatch({ type: "SET_PROFILE", profile })
  }
  return profile
}

export const isMnIUser = () => {
  let {
    changeCustomer: { customer },
    profile,
  } = store.getState()
  let isMnIUser = false
  if (customer.businessSegmentId === 3 || profile.businessSegmentId === 3) {
    isMnIUser = true
  }
  return isMnIUser
}

export const isHomeDepot = () => {
  let {
    profile: { isHomeDepotUser },
    changeCustomer: { customer },
  } = store.getState()
  let isHomeDepot = isHomeDepotUser

  if (customer) {
    isHomeDepot = customer?.v?.indexOf("35922") !== -1
  }
  return isHomeDepot
}

export const isHomeDepotSiteUser = () => {
  let {
    profile: { isHomeDepotUser },
    changeCustomer: { customer },
    profile,
  } = store.getState()
  let isHomeDepotSiteUser = false
  if (
    customer &&
    profile?.roleId == roles.SITE_USER &&
    profile?.isStoreLevelUser === true &&
    isHomeDepotUser
  ) {
    isHomeDepotSiteUser = customer?.v?.indexOf("35922") !== -1
  }
  return isHomeDepotSiteUser
}

export const isRbsCorporateUser = () => {
  let {
    changeCustomer: { customer },
    profile,
  } = store.getState()
  let isRbsCorporateUser = false
  if (customer && profile?.roleId != roles.SITE_USER) {
    isRbsCorporateUser =
      customer?.v?.indexOf("8315839") !== -1 &&
      profile.roleId != roles.Brand_User
  }
  return isRbsCorporateUser
}

export const isSiteUserSpecific = () => {
  let {
    changeCustomer: { customer },
    profile,
  } = store.getState()
  let isRbsSiteUser = false
  if (customer && profile?.roleId == roles.SITE_USER) {
    isRbsSiteUser = customer?.v?.indexOf("8315839") !== -1
  }
  return isRbsSiteUser
}

export const useDocsTypes = () => {
  const { docTypes } = useSelector((state) => state.config)
  const docsTypes = docTypes?.reduce((acc, d) => {
    if (!acc[d.id]) {
      acc[d.id] = d
    }
    return acc
  }, {})
  return docsTypes || {}
}

export const importAll = (require) =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next)
    return acc
  }, {})

export const useHarsco = () => {
  const stores = useSelector((state) => state.stores)
  const profile = useSelector((state) => state.profile)
  const {
    defaultGenId,
    isStoreLevelUser,
    sourceSystem,
    customer_name,
    businessSegmentId,
    division,
    roleId,
  } = profile

  const { changeCustomer } = useSelector((state) => state)

  let isPreviewCustomer = sourceSystem?.toUpperCase().includes("PREVIEW")
  let isSPAKCustomer = sourceSystem?.toUpperCase().includes("SPAK")

  let isFullCircle =
    businessSegmentId === BusinessSegmentId.FULL_CIRCLE ? true : false

  let isMandI = businessSegmentId === BusinessSegmentId?.["M&I"] ? true : false
  //when user switch customer from header drop down
  if (changeCustomer?.customer) {
    isPreviewCustomer = changeCustomer?.customer?.sourceSystem
      ?.toUpperCase()
      .includes("PREVIEW")
    isSPAKCustomer = changeCustomer?.customer?.sourceSystem
      ?.toUpperCase()
      .includes("SPAK")
    isFullCircle =
      changeCustomer?.customer?.businessSegmentId ===
      BusinessSegmentId.FULL_CIRCLE
        ? true
        : false
    isMandI =
      changeCustomer?.customer?.businessSegmentId === BusinessSegmentId?.["M&I"]
        ? true
        : false
  }
  const isStoreManager = isStoreLevelUser
  const userRole = isMandI
    ? profile?.roleId
    : isStoreManager
    ? STORE_MANAGER
    : ADMIN
  const storesData = stores?.[0] || {}
  const storeId = stores?.[0]?.storeNumber || ""

  const isCustomer = (customer) => {
    return customer_name == customer
  }

  return {
    profile,
    isPreviewCustomer,
    isStoreManager,
    userRole,
    defaultGenId,
    storesData,
    storeId,
    customer_name,
    division,
    roleId,
    isCustomer,
    changeCustomer,
    isFullCircle,
    isMandI,
    isSPAKCustomer,
  }
}

export const updateUserLastLogin = async (emailId) => {
  const profile = await api.pa_updateLastLogin({
    email: emailId,
    loading_key: "updateuser",
  })
  return profile
}

export const getCompanyInitial = (customer_name) => {
  const names = customer_name.split(" ")
  const nameInitail = names.map((n, i) => (i < 2 ? n[0] : "")).join("")
  return nameInitail
}

export const DownloadAlertList = () => {
  const alertListD = useSelector((state) => state.doAlertList)
  return { alertListD }
}
export const useQuarterDates = () => {
  let startDate = useToday()
  let today = useToday()
  let endDate = dfns.startOfQuarter(today)
  endDate = dfns.subDays(endDate, 1)
  startDate = dfns.subQuarters(endDate, 4)
  startDate = dfns.addDays(startDate, 1)
  let periodText = `(${formattedDate(startDate)} - ${formattedDate(endDate)})`
  return { startDate, endDate, periodText }
}

export const formatNumber = (number) => {
  if (!number.toString().includes(".")) {
    number = number + ".00"
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const setCustomerData = (data) => {
  return data.map((ele) => {
    return {
      l: `${ele?.customerName} - ${ele?.customerId}`,
      v: ele?.customerId,
      sourceSystem: ele?.sourceSystem,
      businessSegmentId: ele?.businessSegmentId,
    }
  })
}

//method to fill missing dates and days in the list
export const fixMissingDaysOrDates = (arr, key, duration) => {
  let newArr = []

  if (duration == "weekly")
    weekDays.forEach((d) => {
      let id = _.findIndex(arr, { day: d })
      if (id == -1) newArr.push({ day: d, [key]: 0 })
      else newArr.push(arr[id])
    })
  else {
    let iniArr = [...arr].sort((a, b) =>
      new Date(a.date) > new Date(b.date) ? 1 : -1
    )

    iniArr.forEach((d, i) => {
      if (!(iniArr.length - 1 == i)) {
        let d1 = new Date(d.date)
        let d2 = new Date(iniArr[i + 1].date)
        let nd

        while (d1 < d2) {
          nd = getDate(d1, 1)
          let formattedDate = dfns.format(nd, "yyyy-MM-dd") + "T00:00:00"
          if (
            !(dfns.format(d1, "yyyy-MM-dd") === dfns.format(d2, "yyyy-MM-dd"))
          ) {
            let arrV = {
              date: formattedDate,
              day: new Date(nd).toLocaleString("default", { weekday: "long" }),
              [key]: 0,
            }
            if (!arr.some((item) => formattedDate === item.date))
              arr = [...arr, arrV]
          }
          d1 = nd
        }
      }
    })
    newArr = arr.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))
  }

  return newArr
}

export const getDate = (date, duration = 0) => {
  if (duration !== 0) {
    //this conditions executes when we have duration of days
    //adds or substracts 'days' to the given date
    return dfns.add(date, { days: 1 * duration })
  }
  return date
}

export let checkArrayHasDate = (array, val) => {
  return array.some(
    (i) =>
      dfns.format(new Date(i.date), "yyyy-MM-dd") ===
      dfns.format(new Date(val), "yyyy-MM-dd")
  )
}

export const getProfileAlertObject = (data) => {
  const keysToExclude = [
    "profile_expiring",
    "profile_creation",
    "profile_approved",
    "generator_creation",
  ]
  const excludedObject = {}
  //eslint-disable-next-line
  for (const key in data) {
    if (!keysToExclude.includes(key)) {
      excludedObject[key] = data[key]
    }
  }
  return excludedObject
}

export const getNonProfileAlertObject = (data) => {
  const keysToInclude = [
    "profile_expiring",
    "profile_creation",
    "profile_approved",
    "generator_creation",
  ]
  const includedObject = {}
  //eslint-disable-next-line
  for (const key in data) {
    if (keysToInclude.includes(key)) {
      includedObject[key] = data[key]
    }
  }
  return includedObject
}
