import React, { Fragment } from "react"
import "./style.scss"
import Modal from "shared/Modal"
import { SET_PROGRESS_STEP_ONLY } from "store/reducer/actionType"
import { useDispatch } from "react-redux"

function AlertModal(props) {
  const dispatch = useDispatch()
  const setDispatchActionData = () => {
    props.submitAction()
    // props.setShowAlert(false);
  }

  return (
    <Fragment>
      <div className="mniAlertDiv">
        <Modal
          show={props.showAlert || props.showInvalidGenPopup}
          style={{ padding: 20 }}
        >
          <div style={{ padding: 20, fontSize: 12 }}>
            <div
              className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus text-left"
              style={{ flexDirection: "column" }}
            >
              <div
                className="m-0 labelgenerator myprofile mb-2"
                style={{ textAlign: "left" }}
              >
                ALERT
              </div>
              {!props.showError &&
                !props.showAppError &&
                !props.showInvalidGenPopup && (
                  <div className="mt-2 myprofile" style={{ marginRight: 5 }}>
                    {props?.text
                      ? props?.text
                      : "Do you want to save the changes before switching?"}
                  </div>
                )}
              {props.showInvalidGenPopup ? (
                <div className="mt-2 mb-4 myprofile">
                  <div className="mt-2">
                    The selected generator is inactive. Please select an active
                    generator <br />
                    to create a profile or Contact your CSR to reactivate this
                    generator
                  </div>
                </div>
              ) : (
                props.showError && (
                  <div className="mt-2 myprofile">
                    Please fill all the required information
                  </div>
                )
              )}
              {props.showAppError && (
                <div className="mt-2 myprofile">
                  Please fill all required fields in all section to proceed
                  submission
                </div>
              )}
              <div className="col-sm-10 col-md-12 col-lg-12 text-left pr-0 mt-4 ml-4">
                {!props.showError &&
                  !props.showAppError &&
                  !props.showInvalidGenPopup && (
                    <>
                      <div className="row m-0 float-right m-r-2r">
                        <button
                          type="button"
                          className={`green-button`}
                          onClick={() => setDispatchActionData()}
                        >
                          Yes
                        </button>
                      </div>
                      <div className="row m-0 float-right m-r-2r">
                        <button
                          type="submit"
                          className="cancel-button"
                          onClick={() => {
                            if (props?.noNavigate) {
                              props.setShowError(false)
                              if (props?.setShowAppError) {
                                props?.setShowAppError(false)
                              }
                            } else {
                              dispatch({
                                type: SET_PROGRESS_STEP_ONLY,
                                progressStep: props.seekingStep + 1,
                              })
                            }
                            props.setShowAlert(false)
                          }}
                        >
                          No
                        </button>
                      </div>
                    </>
                  )}
                {props.showInvalidGenPopup ? (
                  <div className="row mr-2 justify-content-center">
                    <button
                      type="submit"
                      className="cancel-button"
                      onClick={() => {
                        if (props?.showInvalidGenPopup) {
                          props?.setShowInvalidGenPopup(false)
                        }
                      }}
                    >
                      Ok
                    </button>
                  </div>
                ) : (
                  <>
                    {(props.showError || props.showAppError) && (
                      <div className="row mr-2 justify-content-center">
                        <button
                          type="submit"
                          className="cancel-button"
                          onClick={() => {
                            props.setShowAlert(false)
                            props.setShowError(false)
                            if (props?.setShowAppError) {
                              props?.setShowAppError(false)
                            }
                          }}
                        >
                          Ok
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </Fragment>
  )
}
export default AlertModal
