import React, { useEffect, useState } from "react"
import api from "api"
import { formattedDate, isRbsCorporateUser } from "shared"
import { useDispatch, useSelector } from "react-redux"
import { Workbook } from "exceljs"
import { format } from "date-fns"
import * as dfns from "date-fns"
import { saveAs } from "file-saver"

let enhancer =
  (Component) =>
  ({ history, ...props }) => {
    const dispatch = useDispatch()
    let [reqData, setReqData] = useState({})
    let [reqHistoryData, setReqHistoryData] = useState([])
    let [filterData, setFilterData] = useState({})
    let [pageNo, gotoPage] = useState(1)
    const { serviceReqHistory } = useSelector((state) => state.config)
    const pageSize = 10
    const user = useSelector((state) => state.profile)

    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
      if (filterData?.requestType?.length) {
        setLoading(true)
        fetchData()
      }
    }, [filterData])

    useEffect(() => {
      if (reqData.records) {
        const arrData = [...reqData.records]
        const res = arrData.slice((pageNo - 1) * pageSize, pageNo * pageSize)
        setReqHistoryData(res)
      }
    }, [pageNo])

    let fetchData = async () => {
      if (!filterData.dates) return
      let { dates, requestType, reqStatus } = filterData
      let req = {
        loading_key: "requestHistory",
        index: pageNo,
        requestType,
        status: reqStatus,
      }
      req.dates = [dates.from, dates.to]

      try {
        let res = await api.p_serviceRequestHistory(req)
        if (!res) return

        res.records.forEach((d) => {
          d.createdDate = formattedDate(d.createdDate)
        })
        setReqData(res)
        const arr = [...res.records]
        setReqHistoryData(arr.slice(0, pageSize))
        setLoading(false)
      } catch (error) {
        setReqData({})
        setReqHistoryData([])
        setLoading(false)
      }
    }

    let updateFilterData = (data) => {
      setFilterData(data)
    }

    const reset = () => {
      setReqData([])
    }

    const viewDetails = (item) => () => {
      dispatch({
        type: "SET_CONFIG",
        config: {
          serviceReqHistory: { [item.caseNumber]: item, ...serviceReqHistory },
        },
      })
      const newWindow = window.open(
        `/requestHistoryDetail/${item.caseNumber}`,
        "_blank",
        "noopener,noreferrer"
      )
      if (newWindow) newWindow.opener = null
    }

    const serviceIcons = {
      "Report a Service Issue": "ic_serviceissue.svg",
      "Change Request (Service Details)": "ic_changerequest.svg",
      "New Schedule Request": "ic_newschedulerequest.svg",
      "Request Supplies": "ic_requestsupplies.svg",
    }

    let xlsHeader = (header) => {
      header.eachCell({ includeEmpty: true }, (cell) => {
        let thinB = { style: "thin" }
        cell.alignment = { wrapText: true }
        cell.font = {
          bold: true,
        }

        cell.border = {
          bottom: thinB,
          top: thinB,
          right: thinB,
          left: thinB,
        }

        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "d9e8f4" },
        }
      })
    }

    const createOuterBorder = (
      worksheet,
      start = { row: 1, col: 1 },
      end = { row: 1, col: 1 },
      borderWidth = "medium"
    ) => {
      const borderStyle = {
        style: borderWidth,
      }
      for (let i = start.row; i <= end.row; i++) {
        const leftBorderCell = worksheet.getCell(i, start.col)
        const rightBorderCell = worksheet.getCell(i, end.col)
        leftBorderCell.border = {
          ...leftBorderCell.border,
          left: borderStyle,
        }
        rightBorderCell.border = {
          ...rightBorderCell.border,
          right: borderStyle,
        }
      }
      for (let i = start.col; i <= end.col; i++) {
        const topBorderCell = worksheet.getCell(start.row, i)
        const bottomBorderCell = worksheet.getCell(end.row, i)
        topBorderCell.border = {
          ...topBorderCell.border,
          top: borderStyle,
        }
        bottomBorderCell.border = {
          ...bottomBorderCell.border,
          bottom: borderStyle,
        }
      }
    }

    const getAppliedFilters = () => {
      let values = []
      const filterKeys = {
        dates: "Date Range",
        reqStatus: "Status",
        requestType: "Type of request",
      }

      // eslint-disable-next-line
      for (const key in filterData) {
        let obj = {}
        if (key === "dates") {
          obj = {
            label: "Date Range:",
            value: `From: ${formattedDate(filterData[key].from)}  
          To: ${formattedDate(filterData[key].to)}`,
          }
          obj.isNextColumn = true
          values.push(obj)
        } else if (filterKeys[key]) {
          obj = {
            label: `${filterKeys[key]}:`,
            value: Array.isArray(filterData[key])
              ? filterData[key].join(",")
              : filterData[key],
          }
          obj.isNextColumn = true
          values.push(obj)
        }
      }
      return values
    }

    const setRequestHistoryDetails = (workbook) => {
      const worksheet2 = workbook.addWorksheet("Details")
      worksheet2.properties.defaultColWidth = 17
      worksheet2.columns = [
        { accessor: "label", sticky: "left", key: "label" },
        { accessor: "value", sticky: "left", key: "value" },
      ]
      const { firstName, lastName } = user

      const appliedFilters = getAppliedFilters()
      const generated_dt = format(new Date(), "MMM dd, yyyy HH:mm z")

      const data = [
        { label: "REQUEST HISTORY", title: true },
        { label: "" },
        { label: "Filters Applied:" },
        ...appliedFilters,
        { label: "" },
        {
          label: "Generated By:",
          value: ` ${firstName} ${lastName}`,
          isNextColumn: true,
        },
        { label: "Generated Date: ", value: generated_dt, isNextColumn: true },
        { label: "" },
        { label: "Clean Earth Connect+" },
        { label: "" },
      ]
      data.forEach((d, i) => {
        const row = worksheet2.addRow(d)
        if (d.isNextColumn) worksheet2.mergeCells(`B${i + 1}:E${i + 1}`)
        else worksheet2.mergeCells(`A${i + 1}:E${i + 1}`)
        if (d.title) xlsHeader(row)
      })
      createOuterBorder(
        worksheet2,
        { row: 1, col: 1 },
        { row: data.length, col: 2 },
        "thin"
      )
    }

    const downloadRequestHistory = async () => {
      let filename = `${"Request_History_"}${dfns.format(
        new Date(),
        "MMddyyyy"
      )}.xlsx`

      let workbook = new Workbook()
      let worksheet = workbook.addWorksheet("REQUEST HISTORY")

      worksheet.properties.defaultColWidth = 40

      let headers = [
        "Case#",
        "Created Date",
        "Request Type",
        isRbsCorporateUser() ? "Brand Name" : "",
        "Store",
        "Status",
        "Description",
        "Created By",
      ]
      let row = worksheet.addRow(headers.filter((header) => header !== ""))
      xlsHeader(row)

      reqData.records.forEach((element) => {
        if (isRbsCorporateUser()) {
          let record = [
            element["caseNumber"],
            formattedDate(element["createdDate"]),
            element["serviceTypeFromPortal"],
            element["division"],
            element["storeNumber"],
            element["status"],
            element["description"],
            element["createdBy"],
          ]
          worksheet.addRow(record)
        } else {
          let record = [
            element["caseNumber"],
            formattedDate(element["createdDate"]),
            element["serviceTypeFromPortal"],
            element["storeNumber"],
            element["status"],
            element["description"],
            element["createdBy"],
          ]
          worksheet.addRow(record)
        }
      })

      setRequestHistoryDetails(workbook)
      const buffer = await workbook.xlsx.writeBuffer()
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      const blob = new Blob([buffer], { type: fileType })

      saveAs(blob, filename)
    }

    return (
      <Component
        {...props}
        {...{
          reqData,
          filterData,
          updateFilterData,
          reset,
          pageNo,
          gotoPage,
          pageSize,
          viewDetails,
          serviceIcons,
          reqHistoryData,
          downloadRequestHistory,
          isLoading,
        }}
      />
    )
  }

export default enhancer
