const alerts = [
  {
    l: "SQG to LQG",
    v: "haz_waste",
    desc: "Use this alert to track any shipments that may prompt a change in generator status",
    isFullCircle: false,
    isRetail: true,
  },
  {
    l: "VSQG to SQG",
    v: "vsqg_to_sqg",
    desc: "Use this alert to track any shipments that may prompt a change in generator status from VSQG to SQG",
    isFullCircle: false,
    isRetail: true,
  },
  {
    l: "New Data",
    v: "fc_alert",
    desc: "Use this alert to track new quarter data availability",
    isFullCircle: true,
    isRetail: false,
  },
  {
    l: "No-Ship Takes Place",
    v: "no_ship",
    desc: "Use this alert to track services where no waste was picked up across all service types on an order",
    isRetail: true,
    isFullCircle: false,
  },
  {
    l: "Partial No-Ship Takes Place",
    v: "partial_no_ship",
    desc: "Use this alert to track services where no waste was picked up across one or more but not all service types on an order",
    isRetail: true,
    isFullCircle: false,
  },
]

export { alerts }
