import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import { FormattedMessage } from "react-intl"

function EditProfileCheckout(props) {
  let { onReturnToMyAccountScreenClick, myAccountService } = props

  return (
    <Fragment>
      <div className="requestServiceBlock requestSuccessBlock">
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left mt-4 mb-3">
          <div className="m-0 labelgenerator">
            <FormattedMessage id={"myaccountcheckout.edituser"} />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left">
          <div className="success-block">
            <div className="m-0 title">
              <FormattedMessage id="myaccountcheckout.success" />
            </div>
            <div className="m-0 subTitle">
              <div className="subTxtMsg">
                <FormattedMessage
                  id={"myaccountcheckout.subsuccess1"}
                  values={{
                    email: myAccountService.email,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left">
          <div className="m-0 contact-title">
            <FormattedMessage id="myaccountcheckout.userinfo" />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="myaccountcheckout.username" />
            </div>
            <div className="m-0 value col-10 p-0">
              {myAccountService.firstName} {myAccountService.lastName}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="myaccountcheckout.email" />
            </div>
            <div className="m-0 value col-10 p-0">
              {myAccountService.communicationEmail}{" "}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="myaccountcheckout.phone" />
            </div>
            <div className="m-0 value col-10 p-0">
              {myAccountService.phone}{" "}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="myaccountcheckout.jobtitle" />
            </div>
            <div className="m-0 value col-10 p-0">{myAccountService.title}</div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="myaccountcheckout.accountName" />
            </div>
            <div className="m-0 value col-10 p-0">
              {myAccountService.accountName}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServicebutton text-center">
          <div className="row m-0">
            <div className="m-0 col-12 p-0">
              <button
                className="green-button"
                onClick={() => {
                  onReturnToMyAccountScreenClick()
                }}
              >
                <FormattedMessage id="OK" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default enhancer(EditProfileCheckout)
