import React from "react"
import "./style.scss"
import Image from "shared/Image"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom"

function DocumentsList({
  downloadDocument,
  filterById,
  deleteDocument,
  loader,
}) {
  const availDocuments = useSelector(
    (state) => state.generatorDetails.availabledocDocuments
  )
  const { id, type, version } = useParams()
  const current_profile = useSelector(
    (state) => state.profileDetails.currentProfile
  )

  const profileList = useSelector((state) => state.profileDetails.profilesList)
  const profileOverviewList = useSelector(
    (state) => state.profileDetails.profilesOverviewList
  )
  const getProfile =
    profileList.find(
      (item) =>
        (item.profileId == id || item.profile == id) && item.version == version
    ) ||
    profileOverviewList.find(
      (item) =>
        (item.profileId == id || item.profile == id) && item.version == version
    )

  const finalAvailDocs =
    getProfile?.status?.toLowerCase() !== "draft" && current_profile?.documents
      ? JSON.parse(current_profile?.documents)
      : filterById
      ? availDocuments?.filter((val) => val?.documentTypeId == filterById)
      : availDocuments?.filter(
          (val) => val?.documentTypeId !== 9 && val?.documentTypeId !== 27
        )

  const formatDate = (dateStr) => {
    if (!dateStr) {
      return
    }
    const dateObj = new Date(dateStr)

    const month = String(dateObj.getMonth() + 1).padStart(2, "0")
    const day = String(dateObj.getDate()).padStart(2, "0")
    const year = dateObj.getFullYear()

    // let hours = dateObj.getHours()
    // const minutes = String(dateObj.getMinutes()).padStart(2, "0")
    // const seconds = String(dateObj.getSeconds()).padStart(2, "0")

    // const ampm = hours >= 12 ? "PM" : "AM"
    // hours = hours % 12
    // hours = hours ? hours : 12 // the hour '0' should be '12'
    // const formattedHours = String(hours).padStart(2, "0")
    // return `${month}/${day}/${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`
    return `${month}/${day}/${year}`
  }

  const formatDateTime = (dateStr) => {
    if (!dateStr) {
      return
    }
    const dateObj = new Date(dateStr)

    const month = String(dateObj.getMonth() + 1).padStart(2, "0")
    const day = String(dateObj.getDate()).padStart(2, "0")
    const year = dateObj.getFullYear()

    let hours = dateObj.getHours()
    const minutes = String(dateObj.getMinutes()).padStart(2, "0")
    const seconds = String(dateObj.getSeconds()).padStart(2, "0")

    const ampm = hours >= 12 ? "PM" : "AM"
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    const formattedHours = String(hours).padStart(2, "0")
    return `${month}/${day}/${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`
  }

  return (
    <div
      className="col-sm-12 col-md-12 col-lg-12 m-4 p-0 table sticky isshipreport"
      style={{ maxHeight: 400, overflow: "auto" }}
    >
      <div className="row m-0 headers">
        <div className={`tr active`}>
          <div
            className={`th customsort inactive title-container display-ib ${
              filterById === 27 || filterById === 9 ? "width315" : "width200"
            }`}
          >
            <FormattedMessage id="profile.documents.documentname" />
          </div>
          {filterById !== 27 && filterById !== 9 && (
            <div className="th customsort inactive title-container display-ib width100">
              <FormattedMessage id="profile.documents.revisionnumber" />
            </div>
          )}
          {filterById !== 27 && filterById !== 9 && (
            <div className="th customsort inactive title-container display-ib width150">
              <FormattedMessage id="profile.documents.doctype" />
            </div>
          )}

          {filterById !== 27 && filterById !== 9 && (
            <div className="th customsort inactive title-container display-ib width167">
              <FormattedMessage id="profile.documents.signdate" />
            </div>
          )}
          {filterById !== 27 && filterById !== 9 && (
            <div className="th customsort inactive title-container display-ib width167">
              <FormattedMessage id="profile.documents.signname" />
            </div>
          )}

          {filterById !== 27 && filterById !== 9 && (
            <div className="th customsort inactive title-container display-ib  width167">
              <FormattedMessage id="profile.documents.signtitle" />
            </div>
          )}

          <div
            className={`th customsort inactive title-container display-ib ${
              filterById === 27 || filterById === 9 ? "width310" : "width167"
            }`}
          >
            <FormattedMessage id="profile.documents.uploaddatetime" />
          </div>

          <div
            className={`th customsort inactive title-container display-ib ${
              filterById === 27 || filterById === 9 ? "width310" : "width100"
            }`}
          >
            <FormattedMessage id="profile.documents.actions" />
          </div>
        </div>
      </div>
      {!loader && (
        <div className="row m-0 body">
          {finalAvailDocs?.map((data, i) => (
            <div
              className="tr active"
              key={i}
              style={{ display: "flex", minHeight: 32 }}
            >
              <div
                className={`td custom display-ib ${
                  filterById === 27 || filterById === 9
                    ? "width315"
                    : "width200"
                }`}
              >
                {getProfile?.status?.toLowerCase() !== "draft" &&
                current_profile?.documents
                  ? data?.display_name
                  : data?.displayName}
              </div>
              {filterById !== 27 && filterById !== 9 && (
                <div
                  className="td custom display-ib width100"
                  style={{ wordBreak: "break-all" }}
                >
                  {getProfile?.status?.toLowerCase() !== "draft" &&
                  current_profile?.documents
                    ? version
                    : data?.rivisonNumber}
                </div>
              )}
              {filterById !== 27 && filterById !== 9 && (
                <div
                  className="td custom display-ib width150"
                  style={{ wordBreak: "break-all" }}
                >
                  {getProfile?.status?.toLowerCase() !== "draft" &&
                  current_profile?.documents
                    ? data?.doc_type
                    : data?.documentTypeEntity}
                </div>
              )}

              {filterById !== 27 && filterById !== 9 && (
                <div
                  className="td custom display-ib width167"
                  style={{ wordBreak: "break-all" }}
                >
                  {getProfile?.status?.toLowerCase() !== "draft" &&
                  current_profile?.documents
                    ? formatDate(data?.generator_signature_datetime)
                    : formatDate(data?.customerSignatureDatetime)}
                </div>
              )}
              {filterById !== 27 && filterById !== 9 && (
                <div
                  className="td custom display-ib width167"
                  style={{ wordBreak: "break-all" }}
                >
                  {getProfile?.status?.toLowerCase() !== "draft" &&
                  current_profile?.documents
                    ? data?.generator_signature_name
                    : data?.customerSignatureName}
                </div>
              )}

              {filterById !== 27 && filterById !== 9 && (
                <div
                  className="td custom display-ib width167"
                  style={{ wordBreak: "break-all" }}
                >
                  {getProfile?.status?.toLowerCase() !== "draft" &&
                  current_profile?.documents
                    ? data?.generator_signature_title
                    : data?.customerSignatureTitle}
                </div>
              )}
              <div
                className={`td custom display-ib ${
                  filterById === 27 || filterById === 9
                    ? "width310"
                    : "width167"
                }`}
                style={{ wordBreak: "break-all" }}
              >
                {getProfile?.status?.toLowerCase() !== "draft" &&
                current_profile?.documents
                  ? formatDateTime(data?.created_timestamp)
                  : data?.createDatetime
                  ? formatDateTime(data?.createDatetime)
                  : ""}
              </div>
              <div
                className={`td custom display-ib ${
                  filterById === 27 || filterById === 9
                    ? "width310"
                    : "width100"
                }`}
              >
                {/* {data?.document ? ( */}
                <Image
                  title="Download"
                  className="view-icon mr-2"
                  src="DownloadIcon.svg"
                  height="20px"
                  width="20px"
                  alt=""
                  onClick={() =>
                    downloadDocument(data, current_profile.profileRequestId)
                  }
                />
                {type != "viewprofile" &&
                  (!getProfile?.status ||
                    getProfile?.status?.toLowerCase() == "draft") && (
                    <Image
                      title="Delete"
                      className="view-icon mr-2"
                      src="Delete.svg"
                      height="20px"
                      width="20px"
                      alt=""
                      onClick={() =>
                        deleteDocument(data, current_profile.profileRequestId)
                      }
                    />
                  )}
              </div>
            </div>
          ))}
        </div>
      )}
      {loader && (
        <div style={{ marginBottom: 100 }}>
          <div className="loader"></div>
        </div>
      )}
    </div>
  )
}

export default DocumentsList
