import React, { useEffect, useState } from "react"
import api from "api"
import { format } from "date-fns"
import { host } from "api/urls"
import {
  download,
  downloadZip,
  useDocsTypes,
  openDocInNewWindow,
  pdfToObject,
  useHarsco,
} from "shared"
import _ from "lodash"
import { toast } from "react-toastify"
import { roles } from "config"

let enhancer =
  (Component) =>
  ({ history, ...props }) => {
    let [docData, setDocData] = useState({})
    let [allDocsCheck, setAllDocsCheck] = useState(false)
    let [selectedDoc, setSelectedDoc] = useState({})
    let [filterData, setFilterData] = useState({})
    let [pageNo, gotoPage] = useState(1)
    let [pdfUrl, setPdfUrl] = useState("")
    let [submitted, setSubmitted] = useState(false)
    let [toggleHeadStatus, setToggleHeadStatus] = useState()
    const docsTypes = useDocsTypes()
    const { isStoreManager, storeId, division, roleId } = useHarsco()
    let fetchData = async () => {
      setSubmitted(false)
      if (!filterData.dates) return
      let {
        dates,
        states,
        stores,
        docTypes,
        docNumber = "",
        brands = [],
      } = filterData
      if (isStoreManager) {
        stores = [storeId]
        states = []
      }
      let req = {
        loading_key: "documents",
        index: pageNo,
        states,
        stores,
        divisions: brands,
        docTypes: getDocumentsTypes(docTypes),
        docNumber,
      }
      req.dates = [dates.from, dates.to]
      if (roleId == roles.Brand_User) {
        req.divisions.push(division)
      }
      try {
        let res = await api.p_documents(req)
        if (toggleHeadStatus === "Document#" && res?.items?.length == 0) {
          let errorMsg =
            "Please check the Document Type or Document Number or " +
            "Date Range and try again. If the issue persist please contact " +
            "your customer administrator for further assistance."
          toast.warn(errorMsg, { autoClose: 3000 })
        }
        if (!res) return
        let hostUrl = host()

        res.items.forEach((d) => {
          d.date = format(new Date(d.uploadDate), "d MMM yyyy")
          d.serviceDate = format(new Date(d.serviceDate), "d MMM yyyy")
          let { fileName, filePath } = d
          let path = `${hostUrl}/api/portal/document?uri=${filePath}${encodeURIComponent(
            fileName
          )}`
          d.viewUrl = `${path}&type=view`
          d.downloadUrl = `${path}&type=download`
          d.selected = false
        })

        setDocData(res)
        setAllDocsCheck(false)
        setSubmitted(true)
      } catch (err) {
        setSubmitted(false)
        setDocData({})
      }
    }

    const getDocumentsTypes = (documents) => {
      let docIds = []
      Object.keys(docsTypes).forEach((ele) => {
        if (documents.includes(ele)) {
          if (docsTypes[ele]?.docTypeId.length > 1) {
            docsTypes[ele]?.docTypeId.forEach((e) => {
              docIds = [...docIds, e]
            })
          } else docIds = [...docIds, docsTypes[ele]?.docTypeId[0]]
        }
      })
      return docIds
    }

    let updateFilterData = (data, toggleHeadStatus) => {
      setToggleHeadStatus(toggleHeadStatus)
      setFilterData(data)
      gotoPage(-1)
      setTimeout(() => {
        gotoPage(1)
      }, 10)
    }

    useEffect(() => {
      if (pageNo == -1) return
      fetchData()
    }, [pageNo])

    useEffect(() => {
      ;(async () => {
        if (!selectedDoc.fileName) return
        setPdfUrl("")
        let objectUrl = await pdfToObject(
          selectedDoc.downloadUrl,
          selectedDoc.fileName
        )
        setPdfUrl(objectUrl)
      })()
    }, [selectedDoc])

    let updateDocData = (index) => (e) => {
      let docDataCopy = _.cloneDeep(docData)
      let { items } = docDataCopy
      items[index].selected = e?.currentTarget?.checked
      setDocData(docDataCopy)

      setAllDocsCheck(items.filter((d) => d.selected).length == items.length)
    }
    let changeAllDocs = (e) => {
      let { checked } = e.currentTarget
      setAllDocsCheck(checked)
      let docDataCopy = _.cloneDeep(docData)
      let { items = [] } = docDataCopy
      items.forEach((d) => (d.selected = checked))
      setDocData(docDataCopy)
    }

    let downLoadDoc = () => {
      const urls = docData.items
        .filter((d) => d.selected)
        .map((dt) => ({ url: dt.downloadUrl, name: dt.fileName }))

      downloadZip(urls, "DocumentLibrary")
    }

    let reset = () => {
      setDocData([])
    }

    let headFilterChange = () => {
      setDocData([])
      setSubmitted(false)
    }

    let viewDoc = (d) => openDocInNewWindow(d.downloadUrl, d.fileName)

    const serviceHistory = (workOrderAzId) => {
      const newWindow = window.open(
        `/myschedule/serviceorder/${workOrderAzId}`,
        "_blank",
        "noopener,noreferrer"
      )
      if (newWindow) newWindow.opener = null
    }

    return (
      <Component
        {...props}
        {...{
          docData,
          updateDocData,
          downLoadDoc,
          changeAllDocs,
          filterData,
          updateFilterData,
          selectedDoc,
          setSelectedDoc,
          download,
          reset,
          allDocsCheck,
          pageNo,
          gotoPage,
          submitted,
          pdfUrl,
          viewDoc,
          docsTypes,
          isStoreManager,
          serviceHistory,
          toggleHeadStatus,
          headFilterChange,
        }}
      />
    )
  }

export default enhancer
