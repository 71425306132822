import axios from "./axios"
import _ from "lodash"
import urls from "./urls"
import "./mockApi"

let api = {}

Object.keys(urls).forEach((d) => {
  api[d] = async (body = {}) => {
    let url = urls[d](body)
    let { loading_key = "", cancelToken } = body
    let res
    if (d.startsWith("g_"))
      res = await axios.get(url, {
        loading_key,
      })
    if (d.startsWith("p_"))
      res = await axios.post(
        url,
        _.omit(body, ["loading_key", "cancelToken"]),
        {
          loading_key,
          cancelToken,
        }
      )
    if (d.startsWith("f_"))
      res = await axios.post(url, body, {
        headers: {
          "content-type": "multipart/form-data",
        },
        loading_key,
        cancelToken,
      })
    if (d.startsWith("pb_"))
      res = await axios.post(url, body, {
        responseType: "blob",
        loading_key,
        cancelToken,
      })
    if (d.startsWith("pu_"))
      res = await axios.put(url, _.omit(body, ["loading_key", "cancelToken"]), {
        loading_key,
        cancelToken,
      })
    if (d.startsWith("pa_"))
      res = await axios.patch(url, _.omit(body, "loading_key"), {
        loading_key,
      })
    if (res?.data) return res?.data
  }
})

export default api
