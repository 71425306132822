import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import enhancer from "./enhancer"
import _ from "lodash"
import {
  // AreaChart,
  // AreaChartLite,
  // BarChartLite,
  // RadialChartLite,
  // BarChart,
  PieChart,
} from "routes/Dashboard/components/charts"
import Loading from "shared/Loading"
import { formattedDate } from "shared"

function ProfileReport(props) {
  let { isTablet, chartData, profileReportPage } = props

  return (
    <Fragment>
      <div
        className={` ${
          isTablet
            ? "col-sm-12 col-md-12 col-lg-12 text-left mt-4 istablet"
            : "col-sm-5 col-md-5 col-lg-5 text-left pr-0"
        }`}
      >
        <Loading id="profile-report-donut-chart">
          <label className="weeklylabel">
            <FormattedMessage id="dashboard.profileReport" />
          </label>
          <span className="smallFont pl-2">{`(${formattedDate(
            chartData?.fromDate
          )} - ${formattedDate(chartData?.toDate)})`}</span>
          {chartData?.topProfileList.length > 0 &&
            chartData?.fromDate &&
            chartData?.toDate && (
              <span
                className="viewallservices mt-3 mb-2 pt-1 text-right"
                onClick={profileReportPage}
              >
                VIEW REPORT
              </span>
            )}
          <div
            className={` ${
              isTablet ? "row m-0 borderistablet" : "mySched mysectionsched"
            }`}
          >
            <div
              className={` ${
                isTablet ? "col-sm-6 col-md-6 col-lg-6 " : " position-relative"
              }`}
            >
              <div style={{ height: 400 }}>
                {_.isNil(chartData) ||
                  (chartData?.length == 0 && <h2>No Data</h2>)}
                {!_.isNil(chartData) && (
                  <Fragment>
                    <PieChart
                      data={chartData.topProfileList}
                      //value={1}
                      dataKey="ProfileReport"
                      label="Profile Report"
                      height={450}
                    />
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </Loading>
      </div>
    </Fragment>
  )
}
export default enhancer(ProfileReport)
