import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import api from "api"
import _ from "lodash"
import { toast } from "react-toastify"
import { syncUserProfile, getNonProfileAlertObject } from "shared"
import { useHarsco } from "shared"
import { alerts } from "./constants"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    const storeData = useSelector((state) => state.stores)
    const { alerts_preference } = useSelector((state) => state.profile)
    const [stores, setStoresOpts] = useState([])
    const [selectedstores, setSelectedStores] = useState([])
    const [storeselected, setstoreselected] = useState(false)
    const [success, setsuccess] = useState(false)
    const [categories, setCategories] = useState([])
    const { isFullCircle, isPreviewCustomer } = useHarsco()
    const mergeALerts =
      alerts_preference && alerts_preference.includes("profile_expiring")
        ? getNonProfileAlertObject(JSON.parse(alerts_preference))
        : null

    useEffect(() => {
      if (storeData) {
        let stores = storeData
          .sort((a, b) =>
            a.storeNumber.localeCompare(b.storeNumber, "en", { numeric: true })
          )
          .map((d) => ({ l: d.storeNumber, v: d.storeNumber }))
        setStoresOpts(stores)
      }
    }, [storeData])

    useEffect(() => {
      const {
        no_ship = false,
        haz_waste = false,
        vsqg_to_sqg = false,
        fc_alert = false,
        partial_no_ship = false,
      } = JSON.parse(alerts_preference)
      let categories = []

      alerts?.forEach((ele) => {
        if (ele.isFullCircle && isFullCircle) {
          ele.check = JSON.parse(fc_alert)
          categories.push(ele)
        } else if (ele.v === "no_ship" && !isFullCircle) {
          ele.check = JSON.parse(no_ship)
          categories.push(ele)
        } else if (ele.v === "haz_waste" && !isFullCircle) {
          ele.check = JSON.parse(haz_waste)
          categories.push(ele)
        } else if (ele.v === "vsqg_to_sqg" && !isFullCircle) {
          ele.check = JSON.parse(vsqg_to_sqg)
          categories.push(ele)
        } else if (ele.v === "partial_no_ship" && !isFullCircle) {
          ele.check = JSON.parse(partial_no_ship)
          categories.push(ele)
        }
      })
      setCategories(categories)
    }, [])

    const onSelectStore = (i) => {
      const found = selectedstores.some((el) => el.l === i.l)
      if (!found) setSelectedStores([...selectedstores, i])
    }

    const onCloseStore = (i) => {
      const temp = [...selectedstores]
      temp.splice(i, 1)
      setSelectedStores(temp)
    }
    const onClickUpdateSwitch = (e) => {
      if (e.currentTarget.name == "selectedstore") {
        if (e.currentTarget.checked) {
          setstoreselected(!storeselected)
        } else {
          setstoreselected(!storeselected)
        }
      }
    }

    const onCloseSuccess = () => {
      setsuccess(!success)
    }

    const onClickReset = () => {
      let categories2 = _.cloneDeep(categories)
      categories2.forEach((d) => (d.check = true))
      setCategories(categories2)
    }

    const updateSelectedCategories = (k, j) => {
      setCategories(
        categories.map((d, i) =>
          i === j
            ? { l: d.l, v: d.v, check: !k.check, desc: d.desc }
            : { l: d.l, v: d.v, check: d.check, desc: d.desc }
        )
      )
    }

    const updateSendMeAllUpdate = (e) => {
      let categories2 = _.cloneDeep(categories)
      categories2.forEach((d) => (d.check = e.currentTarget.checked))
      setCategories(categories2)
    }

    const onClickSavePreferences = async () => {
      let values = {}
      categories.forEach((d) => {
        if (!(d.v == "partial_no_ship" && isPreviewCustomer))
          values[d.v] = d.check
      })
      const combinedValues = mergeALerts
        ? { ...values, ...mergeALerts }
        : values
      const res = await api.pa_user({
        alerts_preference: JSON.stringify(combinedValues),
        loading_key: "accesssettings",
      })

      if (!res) return
      syncUserProfile()
      toast.success("Your settings has been updated")
    }

    let allChecked =
      categories.length == categories.filter((d) => d.check).length

    return (
      <Component
        {...props}
        {...{
          stores,
          onSelectStore,
          selectedstores,
          onCloseStore,
          onClickUpdateSwitch,
          storeselected,
          onCloseSuccess,
          allChecked,
          success,
          onClickReset,
          categories,
          updateSelectedCategories,
          updateSendMeAllUpdate,
          onClickSavePreferences,
          isFullCircle,
          isPreviewCustomer,
        }}
      />
    )
  }

export default enhancer
