import React, { Fragment, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import generatorinfoenhancer from "./GeneratorInfoEnhancer"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import Select from "shared/Select"
import Input from "routes/Admin/Components/Input"
import TextArea from "routes/Admin/Components/TextArea"
import { getOptions } from "routes/Utilities/util"
import { useSelector } from "react-redux"
import { Form, Formik } from "formik"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import GeneratorDetails from "routes/Generator/GeneratorDetails"
import AlertModal from "./AlertModal"

function GeneratorInfo(props) {
  let {
    formik,
    setGeneratorName,
    sourceCodeOpts,
    formCodeOpts,
    originCodeOpts,
    processCodeOpts,
    getGeneratorDetails,
    cancelAction,
    goToCreateGenerator,
    initialValues,
    setInitialValues,
    showAlert,
    setShowAlert,
    handleAlerts,
    seekingStep,
    showError,
    setShowError,
    showAlertInitial,
    onSubmitValidate,
    fetchAllProcessCodes,
    processNonCodeOpts,
    showInvalidGenPopup,
    setShowInvalidGenPopup,
    setInActiveGenId,
  } = props
  const [generatorInfo, setGeneratorInfo] = useState("")
  const [facilityCode, setFacilityCode] = useState("")
  const ref = useRef()
  const formRef = useRef()
  const { id, type } = useParams()
  const [generatorSuggestions, setGeneratorSuggestions] = useState([])
  const [facilitySuggestions, setFacilitySuggestions] = useState([])
  const minCharsForSuggestion = 3
  const vendorCodes = useSelector((state) => state.profileDetails.allVendors)
  const current_profile = useSelector(
    (state) => state.profileDetails.currentProfile
  )
  let facilityProcessCodeOpts = getOptions(
    vendorCodes,
    "vendorName",
    "vendorId"
  )
  const generatorOptions = useSelector(
    (state) => state.generatorDetails.generatorList
  )
  // const generatorOptions = generatorOptions1?.filter(data => data.isActive)
  const defaultGenerator = useSelector(
    (state) => state.profileDetails.defaultGenerator
  )
  const selectedGeneForProf = useSelector((state) => state.generatorDetails)
  const generatorDetails = useSelector(
    (state) => state.generatorDetails.generatorDetails
  )
  let generatorCodeOpts =
    generatorOptions?.length > 0
      ? getOptions(
          generatorOptions,
          "generatorName",
          "generatorId",
          "label",
          "value"
        )
      : []

  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQuery()
  const fromNewGenerator = query.get("from")

  useEffect(() => {
    if (facilityCode?.length > 2) {
      setFacilitySuggestions(
        facilityProcessCodeOpts?.filter((val) =>
          val?.vendorName?.toLowerCase()?.includes(facilityCode?.toLowerCase())
        )
      )
    } else {
      setFacilitySuggestions([])
    }
  }, [facilityCode])

  useEffect(() => {
    if (generatorInfo?.length > 2) {
      setGeneratorSuggestions(
        generatorCodeOpts?.filter((val) =>
          val?.generatorName
            ?.toLowerCase()
            ?.includes(generatorInfo?.toLowerCase())
        )
      )
    } else {
      setGeneratorSuggestions([])
    }
  }, [generatorInfo])

  const generatorExists = (geneId) => {
    return generatorCodeOpts?.some((val) => val?.generatorId == geneId)
  }

  useEffect(() => {
    if (
      fromNewGenerator === "newGenerator" &&
      selectedGeneForProf?.isProfileFromGenerator
    ) {
      const generator = generatorCodeOpts?.filter(
        (val) => val?.generatorId == current_profile?.generatorId
      )?.[0]
      setGeneratorInfo(generator?.generatorName)
      setGeneratorName(generator?.generatorName)
    }
    if (
      current_profile?.generatorId &&
      generatorExists(current_profile?.generatorId)
    ) {
      const generator = generatorCodeOpts?.filter(
        (val) => val?.generatorId == current_profile?.generatorId
      )?.[0]
      setGeneratorInfo(generator?.generatorName)
      setGeneratorName(generator?.generatorName)
      // getGeneratorDetails(generator?.generatorId)
      // setGeneratorSuggestions(
      //   generatorCodeOpts?.filter((val) => val?.generatorId == defaultGenerator)
      // )
      // setGeneratorData(generator)
    }
    if (current_profile?.vendorId) {
      const facility = facilityProcessCodeOpts?.filter(
        (val) => val?.v == current_profile?.vendorId
      )?.[0]
      setFacilityCode(facility?.l)
    }
  }, [id, current_profile])

  useEffect(() => {
    if (
      fromNewGenerator === "newGenerator" &&
      selectedGeneForProf?.isProfileFromGenerator
    ) {
      let generator = generatorCodeOpts?.filter(
        (val) =>
          val?.generatorId == selectedGeneForProf?.selectedProfileGenerator
      )?.[0]
      setGeneratorInfo(generator?.generatorName)
      setGeneratorName(generator?.generatorName)
      setInitialValues({
        ...initialValues,
        generatorId: generator?.generatorId,
      })
      // setFieldValue("generatorId", generator?.generatorId)
      getGeneratorDetails(generator?.generatorId)
      setGeneratorSuggestions(
        generatorCodeOpts?.filter((val) => val?.generatorId == defaultGenerator)
      )
    } else if (
      ((!current_profile?.generatorId ||
        (current_profile?.generatorId &&
          !generatorExists(current_profile?.generatorId))) &&
        defaultGenerator &&
        type !== "viewprofile" &&
        !selectedGeneForProf?.isProfileFromGenerator) ||
      selectedGeneForProf?.isProfileFromGenerator
    ) {
      let generator
      if (
        selectedGeneForProf?.isProfileFromGenerator &&
        generatorExists(selectedGeneForProf?.selectedProfileGenerator)
      ) {
        generator = generatorCodeOpts?.filter(
          (val) =>
            val?.generatorId == selectedGeneForProf?.selectedProfileGenerator
        )?.[0]
      } else {
        generator = generatorCodeOpts?.filter(
          (val) => val?.generatorId == defaultGenerator
        )?.[0]
      }
      setGeneratorInfo(generator?.generatorName)
      setGeneratorName(generator?.generatorName)
      setInitialValues({
        ...initialValues,
        generatorId: generator?.generatorId,
      })
      // setFieldValue("generatorId", generator?.generatorId)
      getGeneratorDetails(generator?.generatorId)
      setGeneratorSuggestions(
        generatorCodeOpts?.filter((val) => val?.generatorId == defaultGenerator)
      )
    }
  }, [defaultGenerator, selectedGeneForProf?.isProfileFromGenerator])

  useEffect(() => {
    if (showAlert || showAlertInitial) {
      onSubmitValidate(formRef.current.values)
      // ref.current.click()
    }
  }, [showAlert, showAlertInitial])

  return (
    <Fragment>
      {/* Generator Info */}
      <Formik
        enableReinitialize={true}
        initialValues={formik?.values}
        innerRef={formRef}
        {...formik}
      >
        {({ values, setValues, errors, touched, setFieldValue, isValid }) => {
          return (
            <Form>
              <div
                style={{
                  marginLeft: 35,
                  pointerEvents: type === "viewprofile" ? "none" : "auto",
                }}
              >
                <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 pl-0 generatorstatus text-left py-4 mb-4">
                  <div
                    className="m-0  labelgenerator myprofile"
                    // style={{
                    //   color: "#337AB7",
                    //   fontWeight: "bold",
                    // }}
                  >
                    <FormattedMessage id="profile.generatorinfo" />
                  </div>
                  <div
                    className="d-flex row col-sm-8"
                    style={{ justifyContent: "flex-end" }}
                  >
                    {/* <span
                      className="dsp-flx ml-2"
                      style={{
                        color: "#000",
                        cursor: "pointer",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        background: "#F0F1F2",
                        fontSize: 14,
                      }}
                      onClick={() => goToCreateGenerator()}
                    >
                      &nbsp;&nbsp;
                      <span
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        {" "}
                        CREATE GENERATOR
                      </span>
                      &nbsp;&nbsp;
                    </span> */}
                    {type !== "viewprofile" && (
                      <button
                        type="button"
                        className={`cancel-button col-sm-4`}
                        style={{ height: 40 }}
                        onClick={() => goToCreateGenerator()}
                      >
                        <FormattedMessage id="generator.createGenerator" />
                      </button>
                    )}
                    <div className="form-input-block ml-4 col-sm-7 search-icon">
                      <span className="label">
                        <FormattedMessage id="profile.generator" />
                      </span>
                      <Input
                        name="generatorId"
                        value={generatorInfo}
                        search={true}
                        keyName="generatorName"
                        suggestions={generatorSuggestions}
                        placeholder="Enter a Generator"
                        onFieldChange={(e) => {
                          setGeneratorInfo(e.target.value)
                          if (!e.target.value) {
                            setFieldValue("generatorId", e.target.value)
                          }
                        }}
                        onSelect={(e) => {
                          // setGeneratorData(e)
                          setGeneratorName(e?.generatorName)
                          setFieldValue("generatorId", e.generatorId)
                          getGeneratorDetails(e.generatorId)
                          setInActiveGenId(e.generatorId)
                          let filterInactiveGen = generatorOptions.filter(
                            (data) => data?.generatorId === e?.generatorId
                          )
                          if (
                            filterInactiveGen?.length > 0 &&
                            filterInactiveGen &&
                            filterInactiveGen[0].isActive === false
                          ) {
                            setShowInvalidGenPopup(true)
                          }
                        }}
                      />
                      <div
                        style={{
                          color: "red",
                          fontSize: 10,
                          marginLeft: 12,
                          marginTop: 5,
                        }}
                      >
                        {errors.generatorId && touched.generatorId ? (
                          <div>Generator Id is required.</div>
                        ) : null}
                      </div>
                      {
                        <div
                          style={{
                            display: "flex",
                            marginTop:
                              errors.generatorId && touched.generatorId
                                ? "-10px"
                                : 0,
                            justifyContent: "flex-end",
                            color: "#000",
                            fontSize: 11,
                          }}
                        >
                          {generatorInfo &&
                          generatorInfo.length < minCharsForSuggestion
                            ? `Enter ${
                                minCharsForSuggestion - generatorInfo.length
                              } more character${
                                generatorInfo.length < 2 ? "s" : ""
                              }`
                            : generatorInfo === ""
                            ? "Enter 3 characters"
                            : ""}
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div style={{ pointerEvents: "auto" }}>
                  <GeneratorDetails generatorDetails={generatorDetails} />
                </div>
              </div>
              {/* Waste Description */}
              <div
                className="gen-info"
                style={{
                  pointerEvents: type === "viewprofile" ? "none" : "auto",
                }}
              >
                <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus text-left ml-4 py-4">
                  <div
                    className=" myprofile"
                    style={{
                      color: "#337AB7",
                      fontWeight: "bold",
                      marginLeft: "1.25rem !important",
                    }}
                  >
                    <FormattedMessage id="profile.wastedescription" />
                  </div>
                </div>
                <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 mb-2 text-left py-2 position-relative">
                  <div className="col-sm-8">
                    <div className="form-input-block search-icon">
                      <span className="label">
                        <FormattedMessage id="profile.designatedfacility" />
                        <span style={{ color: "red" }}>{"* "}</span>
                      </span>
                      <Input
                        name="vendorId"
                        value={facilityCode}
                        search={true}
                        keyName="vendorName"
                        placeholder={"Enter a Designated Facility"}
                        suggestions={facilitySuggestions}
                        onFieldChange={(e) => {
                          setFacilityCode(e.target.value)
                          if (!e.target.value) {
                            setFieldValue("requestedProcessCodeId", {
                              l: "(None)",
                              v: 0,
                            })
                            setFieldValue("vendorId", e.target.value)
                            fetchAllProcessCodes(e.target.value)
                          }
                        }}
                        onSelect={(e) => {
                          setFieldValue("requestedProcessCodeId", {
                            l: "(None)",
                            v: 0,
                          })
                          fetchAllProcessCodes(e?.vendorId)
                          setFieldValue("vendorId", e?.vendorId)
                        }}
                        loadingId={"customer_search"}
                        searchField={"customerSearch"}
                      />
                      <div
                        style={{
                          color: "red",
                          fontSize: 10,
                          marginLeft: 12,
                          marginTop: 5,
                        }}
                      >
                        {errors.vendorId && touched.vendorId ? (
                          <div>Designated Facility is required.</div>
                        ) : null}
                      </div>
                      {
                        <div
                          style={{
                            display: "flex",
                            marginTop: errors.vendorId ? "0" : 0,
                            justifyContent: "flex-end",
                            color: "#000",
                            fontSize: 11,
                          }}
                        >
                          {facilityCode &&
                          facilityCode.length < minCharsForSuggestion
                            ? `Enter ${
                                minCharsForSuggestion - facilityCode.length
                              } more character${
                                facilityCode.length < 2 ? "s" : ""
                              }`
                            : facilityCode === ""
                            ? "Enter 3 characters"
                            : ""}
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left py-2 position-relative">
                  <div className="col-sm-8">
                    <div className="form-input-block">
                      <span className="label">
                        <FormattedMessage id="profile.wastename" />
                        <span style={{ color: "red" }}>{"* "}</span>
                      </span>
                      <Input
                        placeholder="Enter Waste Common Name"
                        name="profileName"
                        value={values?.profileName}
                        noMaxLength={true}
                        onFieldChange={(e) => {
                          // setProfileName(e.target.value)
                          setFieldValue("profileName", e.target.value)
                        }}
                      />
                      <div
                        style={{
                          color: "red",
                          fontSize: 10,
                          marginLeft: 12,
                          marginTop: 5,
                        }}
                      >
                        {errors.profileName && touched.profileName ? (
                          <div>{errors.profileName}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left py-2 position-relative">
                  <div className="col-sm-8">
                    <div className="form-input-block" style={{ height: 120 }}>
                      <span className="label">
                        <FormattedMessage id="profile.processgenewaste" />
                        <span style={{ color: "red" }}>{"* "}</span>
                      </span>
                      <TextArea
                        placeholder="Enter Process Generator Waste"
                        name="rcraProcessGenerating"
                        value={values?.rcraProcessGenerating}
                        onFieldChange={(e) => {
                          // setRCRAProcessGenerating(e.target.value)
                          setFieldValue("rcraProcessGenerating", e.target.value)
                        }}
                      />

                      <div
                        style={{
                          color: "red",
                          fontSize: 10,
                          marginLeft: 12,
                          marginTop: "-15px",
                        }}
                      >
                        {errors.rcraProcessGenerating &&
                        touched.rcraProcessGenerating ? (
                          <div>{errors.rcraProcessGenerating}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dsp-flx col-sm-12 col-md-12 col-lg-13 text-left py-2 position-relative">
                  <div className="col-sm-4">
                    <div className="form-input-block">
                      <span className="label">
                        <FormattedMessage id="profile.assignedprocess" />
                      </span>
                      <Input
                        // name="processCodeId"
                        value={
                          current_profile?.templateFlag
                            ? ""
                            : processNonCodeOpts?.find(
                                (val) =>
                                  val.processCodeId ==
                                  current_profile?.processCodeId
                              )?.description
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-input-block mb-dropdown mni-style">
                      <span className="label">
                        <FormattedMessage id="profile.requestedprocess" />
                      </span>
                      <Select
                        name="requestedProcessCodeId"
                        labelkey={"l"}
                        className={`sort-dropdown ${
                          values?.requestedProcessCodeId?.v == 0
                            ? "mni-selected-dropdown"
                            : ""
                        }`}
                        options={processCodeOpts}
                        disabled={type === "viewprofile"}
                        selected={values?.requestedProcessCodeId}
                        hasSearch={true}
                        onSelect={(e) => {
                          setFieldValue("requestedProcessCodeId", e)
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-5" style={{ marginLeft: "-20px" }}>
                    <div
                      className="col-sm-12 d-flex p-0"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="d-flex">
                        <label className="check-container">
                          <input
                            type="checkbox"
                            checked={values?.unusedCommercialProductFlag}
                            onChange={(e) => {
                              setFieldValue(
                                "unusedCommercialProductFlag",
                                e.target.checked
                              )
                            }}
                            name="unusedCommercialProductFlag"
                          />
                          <span className={"checkmark"} />
                        </label>
                        <span
                          className="px-4 text-description"
                          style={{ marginLeft: "16PX" }}
                        >
                          <FormattedMessage id={"profile.unusualcommprod"} />
                        </span>
                      </div>
                      <div className="d-flex">
                        <label className="check-container">
                          <input
                            type="checkbox"
                            checked={values?.spillResidueFlag}
                            onChange={(e) => {
                              setFieldValue(
                                "spillResidueFlag",
                                e.target.checked
                              )
                            }}
                            name="spillResidueFlag"
                          />
                          <span className={"checkmark"} />
                        </label>
                        <span
                          className="px-4 text-description"
                          style={{ marginLeft: "16PX" }}
                        >
                          <FormattedMessage id={"profile.spillresidue"} />
                        </span>
                      </div>
                    </div>
                    <div
                      className="col-sm-12 d-flex p-0"
                      style={{
                        justifyContent: "space-between",
                        marginTop: "5px",
                      }}
                    >
                      <div className="d-flex">
                        <label className="check-container">
                          <input
                            type="checkbox"
                            checked={values?.loosepackFlag}
                            onChange={(e) => {
                              setFieldValue("loosepackFlag", e.target.checked)
                            }}
                            name="loosepackFlag"
                          />
                          <span className={"checkmark"} />
                        </label>
                        <span
                          className="px-4 text-description"
                          style={{ marginLeft: "16PX" }}
                        >
                          <FormattedMessage id={"profile.loosepack"} />
                        </span>
                      </div>
                      <div className="d-flex" style={{ marginRight: "22px" }}>
                        <label className="check-container">
                          <input
                            type="checkbox"
                            checked={values?.labpackFlag}
                            onChange={(e) => {
                              setFieldValue("labpackFlag", e.target.checked)
                            }}
                            name="labpackFlag"
                          />
                          <span className={"checkmark"} />
                        </label>
                        <span
                          className="px-4 text-description"
                          style={{ marginLeft: "16PX" }}
                        >
                          <FormattedMessage id={"profile.labpack"} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left py-2 position-relative">
                  <div
                    className="col-sm-4"
                    style={{ marginLeft: 12, marginRight: -20 }}
                  >
                    <div className="form-input-block mb-dropdown mni-style">
                      <span className="label">
                        <FormattedMessage id="profile.form" />
                        <span style={{ color: "red" }}>{"* "}</span>
                      </span>
                      <Select
                        name="formCodeId"
                        labelkey={"l"}
                        className={`sort-dropdown ${
                          values?.formCodeId?.v == 0
                            ? "mni-selected-dropdown"
                            : ""
                        }`}
                        options={formCodeOpts}
                        disabled={type === "viewprofile"}
                        hasSearch={true}
                        selected={values?.formCodeId}
                        onSelect={(e) => {
                          // setSelectedFormCode(e)
                          setFieldValue("formCodeId", e)
                        }}
                      />
                    </div>

                    <div style={{ color: "red", fontSize: 10, paddingTop: 10 }}>
                      {errors.formCodeId && touched.formCodeId ? (
                        <div>Form Code is required</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-4 ml-2">
                    <div
                      className="form-input-block mb-dropdown mni-style"
                      style={{ zIndex: 1 }}
                    >
                      <span className="label">
                        <FormattedMessage id="profile.source" />
                        <span style={{ color: "red" }}>{"* "}</span>
                      </span>
                      <Select
                        name="sourceCodeId"
                        labelkey={"l"}
                        className={`sort-dropdown ${
                          values?.sourceCodeId?.v == 0
                            ? "mni-selected-dropdown"
                            : ""
                        }`}
                        options={sourceCodeOpts}
                        selected={values?.sourceCodeId}
                        disabled={type === "viewprofile"}
                        hasSearch={true}
                        onSelect={(e) => {
                          // setSelectedSourceCode(e)
                          setFieldValue("sourceCodeId", e)
                        }}
                      />
                    </div>

                    <div style={{ color: "red", fontSize: 10, paddingTop: 10 }}>
                      {errors.sourceCodeId && touched.sourceCodeId ? (
                        <div>Source Code is required</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-4 ml-2">
                    <div className="form-input-block mb-dropdown mni-style">
                      <span className="label">
                        <FormattedMessage id="profile.origin" />
                        <span style={{ color: "red" }}>{"* "}</span>
                      </span>
                      <Select
                        name="originCode"
                        labelkey={"l"}
                        className={`sort-dropdown ${
                          values?.originCode?.v == 0
                            ? "mni-selected-dropdown"
                            : ""
                        }`}
                        role={""}
                        hasSearch={true}
                        disabled={type === "viewprofile"}
                        options={originCodeOpts}
                        selected={values?.originCode}
                        onSelect={(e) => {
                          // setSelectedOriginCode(e)
                          setFieldValue("originCode", e)
                        }}
                      />
                    </div>

                    <div style={{ color: "red", fontSize: 10, paddingTop: 10 }}>
                      {errors.originCode && touched.originCode ? (
                        <div>Origin Code is required</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left py-2 position-relative">
                  <div className="col-sm-8">
                    <div className="form-input-block">
                      <span className="label" style={{ zIndex: 1 }}>
                        <FormattedMessage id="profile.disposalinstructioncomment" />
                      </span>
                      <TextArea
                        placeholder="Enter Disposal Instructions / Comments"
                        name="disposalInstructionsComments"
                        value={values?.disposalInstructionsComments}
                        onFieldChange={(e) => {
                          setFieldValue(
                            "disposalInstructionsComments",
                            e.target.value
                          )
                        }}
                        // noMaxLength={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Back, Save, Cancel Actions */}
              {type !== "viewprofile" && (
                <div className="row m-0 new-row-block">
                  <div className="col-sm-10 col-md-12 col-lg-12 text-left pr-0 mt-2 ml-4">
                    <div className="row m-0 float-right m-r-2r">
                      <button
                        ref={ref}
                        type="submit"
                        className={`green-button`}
                        // onClick={() => submit()}
                      >
                        Save & Continue
                      </button>
                    </div>
                    <div className="row m-0 float-right m-r-2r">
                      <button
                        type="button"
                        className="cancel-button"
                        onClick={() => cancelAction()}
                      >
                        Cancel
                      </button>
                    </div>
                    <span className="float-right text-red m-r-2r mt-2">
                      {`*Required Fields`}
                    </span>
                  </div>
                  {showInvalidGenPopup && (
                    <AlertModal
                      showError={showError}
                      setShowError={setShowError}
                      seekingStep={seekingStep}
                      submitAction={() =>
                        handleAlerts(values, errors, isValid, seekingStep)
                      }
                      showInvalidGenPopup={showInvalidGenPopup}
                      setShowInvalidGenPopup={setShowInvalidGenPopup}
                      noNavigate={true}
                    />
                  )}
                  {!showError && showAlert && (
                    <AlertModal
                      showError={showError}
                      setShowError={setShowError}
                      seekingStep={seekingStep}
                      submitAction={() =>
                        handleAlerts(values, errors, isValid, seekingStep)
                      }
                      showAlert={showAlert}
                      setShowAlert={setShowAlert}
                      text="Are we good to Save & Continue?"
                      noNavigate={true}
                    />
                  )}
                  {showError && (
                    <AlertModal
                      showError={showError}
                      setShowError={setShowError}
                      seekingStep={seekingStep}
                      submitAction={() =>
                        handleAlerts(values, errors, isValid, seekingStep)
                      }
                      showAlert={showAlert}
                      setShowAlert={setShowAlert}
                    />
                  )}
                </div>
              )}
            </Form>
          )
        }}
      </Formik>
    </Fragment>
  )
}
export default generatorinfoenhancer(GeneratorInfo)
