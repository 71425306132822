import React, { useEffect, useState } from "react"
import { useField, Field, useFormikContext } from "formik"
import InputMask from "react-input-mask"
import classnames from "classnames"

const Field2 = (props) => {
  let {
    name,
    type,
    onVChange,
    customShowAddSupplies = false,
    title = "",
  } = props
  const [field, meta, helpers] = useField(name)
  const { value } = meta
  let FFiled = Field
  if (props.mask) FFiled = InputMask
  let hasError = meta.touched && meta.error && type != "checkbox"
  let extra = {}
  if (type == "textarea") extra.component = type

  const [textAreaError, setTextAreaError] = useState(false)
  const [edit, setEdit] = useState(customShowAddSupplies)
  const { setTouched } = helpers
  const { isSubmitting, submitCount = 0 } = useFormikContext()

  let hasSubmitCount = 0

  useEffect(() => {
    if (isSubmitting) setTouched(true)
    if (submitCount > hasSubmitCount) {
      setTextAreaError(true)
      hasSubmitCount = submitCount
    }
  }, [isSubmitting, submitCount])

  useEffect(() => {
    if (onVChange) onVChange()
    if (value?.length <= 500) {
      if (textAreaError) {
        setTextAreaError(false)
      }
    } else if (edit) {
      setTextAreaError(false)
      setEdit(false)
    }
  }, [value])

  return (
    <div
      className={classnames({
        hasError:
          (name !== "details" && hasError) ||
          (name === "details" && value?.length > 500) ||
          (hasError && submitCount > 0),
      })}
      title={title}
    >
      <FFiled {...field} {...props} {...extra} />
      {type == "checkbox" && <span className="checkmark" />}
      {hasError && name !== "details" && (
        <div className="error">{meta.error}</div>
      )}
      <div className="dsp-flx">
        {name === "details" && (
          <div
            className={classnames("character-limit", {
              error: value?.length > 500,
            })}
          >{`${value?.length ? value?.length : 0}/500`}</div>
        )}
        {name === "details" && textAreaError && (
          <div className="error mr-rgt">
            {value?.length > 500
              ? "You have exceeded maximum character limit(500)"
              : meta.error}
          </div>
        )}
      </div>
    </div>
  )
}
export default Field2
