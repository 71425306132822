import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import api from "api"
import { BrowserList, OSList, DeviceList } from "config"
import { useIntl } from "react-intl"
import { dateForApi } from "shared"
import { useHarsco } from "shared"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { roles } from "config"

let enhancer = (Component) => (props) => {
  let { formatMessage } = useIntl()
  const { storeId, profile, isStoreManager, isFullCircle } = useHarsco()
  const { firstName, lastName, phone, customer_name, email, roleId } = profile

  const rolesList = useSelector((state) => state?.roles)
  const customerDetails = useSelector((state) => state?.changeCustomer)
  const accountName =
    roleId === roles.PORTAL_ADMIN ||
    roleId === roles.CE_ADMIN ||
    roleId === roles.CUSTOMER_ADMIN
      ? customerDetails?.customer?.l
      : customer_name

  const role = Object.entries(roles).filter((ele) => ele[1] === roleId)[0]

  const name = `${lastName} ${firstName}`
  const [initialValues, setInitialValues] = useState({
    Name: name,
    CompanyName: accountName,
    Phone: phone,
    alternetPhone: "",
    QueryInDetail: "",
    BrowserName: "",
    OperatingSystem: "",
    DeviceName: "",
    URLAddress: "",
    IssueDate: new Date(),
    storeNumber: isStoreManager ? storeId : "",
    isDirectContacted: [],
    directNumber: "",
    phoneExtension: "",
    roleName: role[0]?.replace(/_/g, " "),
  })

  let history = useHistory()

  useEffect(() => {
    setInitialValues({ ...initialValues })
  }, [customerDetails])

  const contactOption = [
    { l: formatMessage({ id: "support.checkMarkLable" }), v: "true" },
  ]
  const [browserList] = useState(BrowserList.map((d) => ({ l: d, v: d })))
  const [osList] = useState(OSList.map((d) => ({ l: d, v: d })))
  const [deviceList] = useState(DeviceList.map((d) => ({ l: d, v: d })))
  let formik = {
    enableReinitialize: true,
  }

  let valSchema = {
    isDirectContacted: Yup.array(),
    directNumber: Yup.string().when("isDirectContacted", (val) => {
      if (val[0] == "true")
        return Yup.string()
          .required("Please enter contact number")
          .test("len", "Please enter valid contact number", (val) => {
            const val_length_without_dashes = val?.replace(/-|_/g, "").length
            return val_length_without_dashes === 13
          })
    }),
    BrowserName: Yup.string().required("Please select browser"),
  }
  formik.validationSchema = Yup.object().shape(valSchema)

  formik.onSubmit = async (data, { resetForm }) => {
    const formData = { ...data }
    formData.CustomerEmail = email
    if (formData.IssueDate) formData.IssueDate = dateForApi(formData.IssueDate)
    formData.isDirectContacted = Boolean(formData.isDirectContacted?.length)

    let res = await api.p_supportForm({
      loading_key: "support_form",
      ...formData,
    })
    if (res) {
      resetForm()
      history.push({
        pathname: "/supportCheckOut",
        state: {
          ...formData,
        },
      })
    }
  }
  const resetPhoneFeilds = (formik) => {
    formik.setFieldValue("directNumber", "")
    formik.setFieldValue("phoneExtension", "")
  }

  return (
    <Component
      {...props}
      {...{
        formik,
        initialValues,
        browserList,
        osList,
        deviceList,
        isStoreManager,
        contactOption,
        resetPhoneFeilds,
        isFullCircle,
        rolesList,
      }}
    />
  )
}

export default enhancer
