import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    let history = useHistory()

    useEffect(() => {}, [])

    const navigate = (document_type) => {
      history.push("/documentlibrary", {
        document_type,
      })
    }

    return (
      <Component
        {...props}
        {...{
          navigate,
        }}
      />
    )
  }

export default enhancer
