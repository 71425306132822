import React from "react"
import enhancer from "./enhancer"
import { carouselSetting } from "config"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import { FormattedMessage } from "react-intl"
// import MyDocuments from "../MyDocuments"
import Loading from "shared/Loading"
import { AreaChart, StackedBarChart } from "routes/Dashboard/components/charts"
import Image from "shared/Image"

function WasteDispositionReport(props) {
  let { isTablet, currentSlide, setCurrentSlide, areaChartData, PeriodText } =
    props

  return (
    <div
      className={`${
        isTablet
          ? "col-sm-12 col-md-12 col-lg-12 text-left total-waste-graph"
          : "col-sm-7 col-md-7 col-lg-7 text-left total-waste-graph"
      }`}
    >
      <Loading id="quarter-overview">
        <label className=" w-60 weeklylabel ">
          <FormattedMessage id="dashboard.wasteDispositionReport" />
        </label>
        <span className="small my-3 pl-2 dt_upper">{PeriodText}</span>
        <div className="waste-disposition-chart">
          <div className="btn-group chart-toggle-btn mt-1 mr-3">
            <Image
              className="chart_icon"
              onClick={() => setCurrentSlide(0)}
              src={currentSlide == 0 ? "BarChart.svg" : "BarChart_Inactive.svg"}
            />
            <Image
              className="chart_icon"
              onClick={() => setCurrentSlide(1)}
              src={
                currentSlide == 0 ? "AreaChart.svg" : "AreaChar_Inactive.svg"
              }
            />
          </div>
          <Carousel
            showThumbs={false}
            autoPlay={carouselSetting.infiniteLoop}
            interval={carouselSetting.autoPlay}
            infiniteLoop={carouselSetting.infiniteLoop}
            showIndicators={false}
            selectedItem={currentSlide}
            onChange={setCurrentSlide}
          >
            <div>
              <StackedBarChart
                data={areaChartData}
                config={{
                  x: "name",
                  y: [
                    { key: "RECYCLE", name: "Q1" },
                    { key: "WTE", name: "Q2" },
                    { key: "INCINERATION", name: "Q3" },
                    { key: "LANDFILL", name: "Q4" },
                  ],
                  yDomain: [0, 100],
                  yUnit: " TONS",
                }}
                color={["#96B4E4", "#D46D80", "#66C4CA", "#9F9F9F"]}
              />
            </div>
            <div>
              <AreaChart
                data={areaChartData}
                height={240}
                config={{
                  x: "name",
                  y: [
                    { key: "RECYCLE", name: "Q1" },
                    { key: "WTE", name: "Q2" },
                    { key: "INCINERATION", name: "Q3" },
                    { key: "LANDFILL", name: "Q4" },
                  ],
                  yDomain: [0, 100],
                  yUnit: " TONS",
                }}
                color={["#96B4E4", "#D46D80", "#66C4CA", "#9F9F9F"]}
              />
            </div>
          </Carousel>
        </div>

        {/* //commented for the future reference
        {!isTablet ? <MyDocuments /> : ""} 
        */}
      </Loading>
    </div>
  )
}
export default enhancer(WasteDispositionReport)
