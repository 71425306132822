import { SET_ROLES, DELETE_ROLES } from "./actionType"

const roles = (state = [], action) => {
  let newState = []
  switch (action.type) {
    case SET_ROLES:
      newState = [...action.roles]
      return newState
    case DELETE_ROLES:
      return newState
    default:
      return state
  }
}

export default roles
