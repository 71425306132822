import React, { useState } from "react"
import opts from "./Contents"
import { help, helpStore, mnihelp, fullCircleHelp } from "./config"
import { useHarsco, isHomeDepot } from "shared"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { roles } from "config"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    let [activeParentTopic, setActiveParentTopic] = useState(false)
    let [activeSubTopic, setActiveSubTopic] = useState(false)
    let [activeContentKey, setActiveContentKey] = useState("login")
    let [activeNestedTopic, setActiveNestedTopic] = useState(false)
    let [activeNestedTopicSubTopic, setActiveNestedTopicSubTopic] =
      useState(false)
    let { profile, isStoreManager, isFullCircle } = useHarsco()

    const [helpDetails, setHelpDetails] = useState([])
    const { changeCustomer } = useSelector((state) => state)

    useEffect(() => {
      //Checking whether user for Home Depot
      //if not, removing historical LDR details subtopics
      if (isStoreManager) {
        setHelpDetails(helpStore)
      } else if (isFullCircle) {
        setHelpDetails(fullCircleHelp)
      } else if (changeCustomer?.customer?.businessSegmentId === 3) {
        let helpConfig = []
        mnihelp.forEach((ele) => {
          if (ele?.label === "Retail") {
            let obj = {},
              subTopics = []
            ele?.subTopics?.forEach((element) => {
              //if it is not a home depot customer then we are removing historical ldr by emptying subTopics
              // else we are keeping historical ldr in subTopics
              if (element?.contentKey === "documentlibrary" && !isHomeDepot()) {
                obj = { ...element, subTopics: [] }
                subTopics = [...subTopics, obj]
              } else if (
                profile?.roleId !== roles.CUSTOMER_ADMIN &&
                element.contentKey === "administrator"
              ) {
                subTopics = [...subTopics]
              } else {
                obj = { ...element }
                subTopics = [...subTopics, obj]
              }
            })
            obj = { ...ele, subTopics: subTopics }
            helpConfig = [...helpConfig, obj]
          } else {
            helpConfig = [...helpConfig, ele]
          }
        })
        setHelpDetails(helpConfig)
      } else {
        let helpConfig = []
        help.forEach((ele) => {
          if (ele?.label === "Retail") {
            let obj = {},
              subTopics = []
            ele?.subTopics?.forEach((element) => {
              //if it is not a home depot customer then we are removing historical ldr by emptying subTopics
              // else we are keeping historical ldr in subTopics
              if (element?.contentKey === "documentlibrary" && !isHomeDepot()) {
                obj = { ...element, subTopics: [] }
                subTopics = [...subTopics, obj]
              } else if (
                profile?.roleId !== roles.CUSTOMER_ADMIN &&
                element.contentKey === "administrator"
              ) {
                subTopics = [...subTopics]
              } else {
                obj = { ...element }
                subTopics = [...subTopics, obj]
              }
            })
            obj = { ...ele, subTopics: subTopics }
            helpConfig = [...helpConfig, obj]
          } else {
            helpConfig = [...helpConfig, ele]
          }
        })
        setHelpDetails(helpConfig)
      }
    }, [changeCustomer])

    let HelpC
    HelpC = opts[activeContentKey]
    return (
      <Component
        {...props}
        {...{
          activeParentTopic,
          setActiveParentTopic,
          activeSubTopic,
          setActiveSubTopic,
          activeContentKey,
          setActiveContentKey,
          HelpC,
          activeNestedTopic,
          setActiveNestedTopic,
          activeNestedTopicSubTopic,
          setActiveNestedTopicSubTopic,
          helpDetails,
        }}
      />
    )
  }

export default enhancer
