import React, { Fragment } from "react"
import Image from "shared/Image"
import classnames from "classnames"
import { FormattedMessage } from "react-intl"
import { useIsTablet } from "shared"
import MyApp from "../../../../CorporateAdmin/Component/Services/Components/Map"
import _ from "lodash"

function RightServiceSection(props) {
  let { changeRequest, activeService: service } = props

  let sch_type = {
    "Urgent 3 Day": "yellow",
    ER: "red",
    Rework: "red",
    Urgent: "yellow",
  }

  let isTablet = useIsTablet()

  if (!service) return null

  let address = `${service?.address}, ${service?.city}, ${service?.state} ${service?.zip}`

  return (
    <Fragment>
      <div
        className={` ${
          isTablet
            ? "col-sm-7 col-md-7 col-lg-7 text-left p-0 service-details "
            : "col-sm-8 col-md-8 col-lg-8 text-left p-0 service-details"
        }`}
      >
        <div className="row m-0 service-title-block">
          <div className={`col-sm-7 col-md-7 col-lg-7 text-left p-0 `}>
            <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0 locationtitle">
              <div className="site-location-map">
                <FormattedMessage id="myschedule.sitelocation" />
              </div>
              <div className="site-location-map-address">
                <FormattedMessage id="requestservicecheckout.addresslabel" />
                <span className="site-location-map-address-details">
                  {" " + service?.address}, {service?.city}, {service?.state}
                  {/* ,{service?.country} */}
                </span>
              </div>
            </div>
            {/* <label className="service-title">{service.title}</label> */}
          </div>

          <div className={`col-sm-5 col-md-5 col-lg-5 text-right p-0 `}>
            {/* <label className="service-date">
              {" "}
              <span>
                <Image
                  className=""
                  src="ic_calendar.svg"
                  height="12px"
                  width="14px"
                  alt=""
                />
              </span>
              <span className="">{formattedDate(service.formatTime)}</span>
            </label> */}
          </div>
        </div>
        {service.canChange && (
          <div className="text-right schedulelink ">
            <span className="c-p" onClick={changeRequest}>
              <FormattedMessage id="myschedule.link" />
            </span>
          </div>
        )}
        <div className="row m-0 middle-section-block">
          <div
            className={`col-sm-3 col-md-3 col-lg-3 text-center border-rights`}
          >
            <div className="site-order-map">
              <FormattedMessage id="myschedule.scheduletypeHistory" />
            </div>

            <div
              className={classnames(
                "site-order-number",
                sch_type[service.scheduleType] || "blue"
              )}
            >
              {service.workOrderId}
            </div>
          </div>
          <div
            className={`col-sm-3 col-md-3 col-lg-3 text-center border-rights`}
          >
            <div className="site-order-map">
              <FormattedMessage id="myschedule.serviceOrderHistory" />
            </div>
            <div className="site-order-number">
              {service?.purchaseOrder || "-"}
            </div>
          </div>
          <div
            className={`col-sm-3 col-md-3 col-lg-3 text-center border-rights`}
          >
            <div className="site-order-map">
              <FormattedMessage id="myschedule.storeHistory" />
            </div>
            <div className="site-order-number"> {service?.status || "-"}</div>
          </div>
          <div
            className={`col-sm-3 col-md-3 col-lg-3 text-center statusHeading`}
          >
            <div className="site-order-map">
              <FormattedMessage id="myschedule.serviceHistory" />
            </div>
            <div className="site-order-number">
              {/* <FormattedMessage id="myschedule.services03" /> */}
              {service?.formatTime || "-"}
            </div>
          </div>
        </div>

        {/* <div className="row m-0 middle-block">
          <div className={`col-sm-3 col-md-3 col-lg-3 `}>
            <div className="schedule-label-heading">
              <FormattedMessage id="myschedule.servicetypes" />
            </div>
          </div>
          <div className={`col-sm-3 col-md-3 col-lg-3 pl-0 `}>
            <div className="schedule-label-heading">
              <FormattedMessage id="myschedule.servicefreqeuncy" />
            </div>
          </div>
          <div className={`col-sm-4 col-md-4 col-lg-4 pl-0 `}>
            <div className="schedule-label-heading">
              <FormattedMessage id="myschedule.estimateservicedate" />
            </div>
          </div>
        </div> */}
        {/* {!_.isEmpty(service.serviceTypes) &&
          service?.serviceTypes.map((d, i) => (
            <div className="row servicetype-row" key={i}>
              <div
                className={` ${
                  isTablet
                    ? "col-sm-3 col-md-3 col-lg-3 text-left"
                    : "col-sm-3 col-md-3 col-lg-3 text-left"
                }`}
              >
                <div className="labelname-service">{d.serviceType}</div>
              </div>
              <div
                className={` ${
                  isTablet
                    ? "col-sm-3 col-md-3 col-lg-3 p-0 text-left "
                    : "col-sm-3 col-md-3 col-lg-3 p-0 text-left"
                }`}
              >
                <div className="frequency-service frequency-service-block">
                  {d.frequency == -1
                    ? "On Call"
                    : d.frequency
                    ? `${d.frequency} days`
                    : "NA"}
                </div>
              </div>
              <div
                className={` ${
                  isTablet
                    ? "col-sm-4 col-md-4 col-lg-4 p-0 text-left "
                    : "col-sm-4 col-md-4 col-lg-4 p-0 text-left"
                }`}
              >
                <div className="frequency-service-block">
                  {d.frequency == -1 ? "" : d.estimatedServiceDate || "NA"}
                </div>
              </div>
              <div
                className={` ${
                  isTablet
                    ? "col-sm-2 col-md-2 col-lg-2 p-0 text-left "
                    : "col-sm-2 col-md-2 col-lg-2 p-0 text-left"
                }`}
              >
                <div
                  className={
                    d.noShip ? "frequency-service-block no-ship-message" : ""
                  }
                >
                  {d.noShip ? "no ship" : ""}
                </div>
              </div>
            </div>
          ))} */}

        <div className="store-details">
          <div className="map-location">
            <FormattedMessage id="myschedule.storeaddressHistory" />
          </div>
          <div className="icon-address">
            <span className="landmark-icon">
              <Image
                className=""
                src="ic_location.svg"
                height="12px"
                width="8px"
                alt=""
              />
            </span>
            <label className="map-location-address">
              {`${service?.address}, ${service?.city}, ${service?.state} ${
                !_.isNil(service?.country) ? "," + service?.country : ""
              }`}
            </label>
          </div>
          <div className="store-location">
            <MyApp address={address} />
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default RightServiceSection
