import React, { useEffect, useState } from "react"
// import {
//   useHarsco,
// } from "shared"
import { useDispatch, useSelector } from "react-redux"
import {
  getBooleanValues,
  getDateValues,
  getObjectValues,
  getOptions,
  getValues,
} from "routes/Utilities/util"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import {
  CURRENT_PROFILE,
  GET_PRINT_STATUS,
  GET_PROGRESS_STEP,
  RECERTIFY_PROFILE,
  SET_PROFILE_NAME,
  SET_PROGRESS_STEP_ONLY,
  GET_PROFILE_FOR_PRINT_LABEL,
} from "store/reducer/actionType"
import api from "api"
import { toast } from "react-toastify"

let enhancer =
  (Component) =>
  ({ history, ...props }) => {
    const { id, version } = useParams()
    const current_profile = useSelector(
      (state) => state.profileDetails?.currentProfile
    )

    const profileList = useSelector(
      (state) => state.profileDetails.profilesList
    )
    const [isLoading, setLoading] = useState(false)
    const [printLoader, setPrintLoader] = useState(false)
    const initGeneInfoValues = {
      generatorId: "",
      processCodeId: null,
      sourceCodeId: { l: "", v: null },
      formCodeId: { l: "", v: null },
      originCode: { l: "", v: null },
      requestedProcessCodeId: { l: "", v: null },
      rcraProcessGenerating: "",
      profileName: "",
      unusedCommercialProductFlag: false,
      spillResidueFlag: false,
      loosepackFlag: false,
      labpackFlag: false,
      disposalInstructionsComments: "",
    }
    const initWasteCharValues = {
      layered: { l: "", v: null },
      viscosity: { l: "", v: "" },
      specificGravity: { l: "", v: null },
      specificGravityActual: "",
      flashPointFlag: { l: "", v: null },
      flashpointActual: "",
      flashpointScale: "",
      phFlag: { l: "", v: null },
      phExactValue: "",
      btuPerLbs: { l: "", v: null },
      btuActual: "",
      boilingPointFlag: { l: "", v: null },
      boilingPtActual: "",
      boilingScale: "",
      solidPct: "",
      sludgesPct: "",
      freeLiquidsPct: "",
      powderFlag: false,
      monolithFlag: false,
      debris: false,
      compressedGas: false,
      waterPercentage: 0,
      pumpableWasteFlag: false,
      odorFlag: { l: "", v: "" },
      colorDesc: "",
      halogensPct: "",
      noneFlag: false,
      odorDesc: "",
      wasteCharNoSelectionFlag: false,
      voc100Ppm: false,
      voc500Ppm: false,
      subjectToSubpartCcFlag: false,
      tocPercentage: { l: "", v: null },
    }
    const initShipValues = {
      bulkLiquidFlag: false,
      bulkSolidFlag: false,
      containerTypesizeFlag: false,
      containerTypesize: "",
      containerTypeId: { l: "", v: "", id: null },
      frequencyId: { l: "", v: "", id: null },
      quantity: "",
      searchAnywhereFlag: false,
      dotId: { l: "", v: "", id: null },
      hazardousConstituents: "",
      rq: false,
      limitedQty: false,
      rqDescription: "",
      shipmentsOverSeaFlashpoint: null,
      marinePollutants: false,
      specialPermitsCaLetter: "",
    }

    const initAddiInfoValues = {
      additionalInfoNoneFlag: false,
      ammoniaFlag: false,
      asbestosFriableFlag: false,
      asbestosNonFriableFlag: false,
      aphisWasteFlag: false,
      cerclaFlag: false,
      dioxinsFlag: false,
      dustHazardFlag: false,
      flammableSolidFlag: false,
      medicalSharpsNeedlesFlag: false,
      organicPeroxideFlag: false,
      oxidizerFlag: false,
      pesticideHerbicideFlag: false,
      pfasPfoaFlag: false,
      phenolicsFlag: false,
      polimerizesFlag: false,
      pusoFlag: false,
      rcraDebrisFlag: false,
      subpartPFlag: false,
      noReactivityFlag: false,
      cyanidesReactivityFlag: false,
      sulfidesReactivityFlag: false,
      waterReactivityFlag: false,
      reactiveTempSensFlag: false,
      metalsFlag: false,
      dotExplosiveFlag: false,
      polymerizableFlag: false,
      pyrophoricReactivityFlag: false,
      shockReactivityFlag: false,
      otherReactivity: "",
    }

    const initStateRegValues = {
      federalUniversalWaste: false,
      generatorStateUniversalWaste: false,
      rcraNonHazExempt: false,
      epaStateExemption: "",
      epaWasteCode: "",
      epaUhc: "",
      epaStateCode: "",
      txInStateCode: "",
      waDesignation: "",
      ldrFlag: false,
      ldrRcraCode: "",
      ldrUhcs: "",
      noUhcsFlag: false,
      wasteWaterFlag: null,
      ldrClassId: null,
      benzeneWasteFlag: false,
      hazardousWasteNeshapFlag: false,
      naicsCodes: "",
      naicsCodes1Flag: false,
      naicsCodes2Flag: false,
      annualBenzene: "",
      benzeneConcentration: "",
      yearTabCalculates: "",
      maxBenzene: "",
      benzeneWater: false,
      maxBenzeneFlag: false,
      benzeneSectionFlag: false,
    }

    // const initRegConstValues = {
    //   analytical: false,
    //   generatorKnowledge: false,
    //   sds: false,
    // }

    const initGeneCertValues = {
      customerSignatureName: "",
      customerSignatureTitle: "",
      customerSignatureDatetime: null,
      electronicSybmissionFlag: false,
      letterOfConcentFlag: false,
      letterOfConcent: "",
      presignedstatus: false,
    }
    const dispatch = useDispatch()

    const processCodes = useSelector(
      (state) => state.profileDetails.allProcessCodes
    )
    let processCodeOpts = getOptions(
      processCodes,
      "description",
      "processCodeId"
    )
    const sourceCodes = useSelector(
      (state) => state.profileDetails.allSourceCodes
    )
    let sourceCodeOpts = getOptions(sourceCodes, "display", "id")
    const formCodes = useSelector((state) => state.profileDetails.allFormCodes)
    let formCodeOpts = getOptions(formCodes, "display", "id")
    const originCodes = useSelector(
      (state) => state.profileDetails.allOriginCodes
    )
    let originCodeOpts = getOptions(originCodes, "description", "value")

    const layersOpts = {
      id: 1,
      options: [
        {
          l: "Single Layered",
          v: 0,
        },
        {
          l: "Bi-Layered",
          v: 1,
        },
        {
          l: "Multi-Layered",
          v: 2,
        },
      ],
    }
    const viscosityOpts = {
      id: 2,
      options: [
        {
          l: "N/A",
          v: "notapplicable",
        },
        {
          l: "Low(Water)",
          v: "low",
        },
        {
          l: "Medium(Oil)",
          v: "medium",
        },
        {
          l: "High(Tar/Honey)",
          v: "high",
        },
      ],
    }
    let specificGravityOpts = {
      id: 3,
      options: [
        {
          l: "N/A",
          v: 0,
        },
        {
          l: "<0.8",
          v: 1,
        },
        {
          l: ">=0.8, <1",
          v: 2,
        },
        {
          l: ">=1, <1.2",
          v: 3,
        },
        {
          l: ">=1.2",
          v: 4,
        },
      ],
    }
    let flashPointOpts = {
      id: 4,
      options: [
        {
          l: "N/A",
          v: 0,
        },
        {
          l: "<73F",
          v: 1,
        },
        {
          l: "73-100F",
          v: 2,
        },
        {
          l: "101-139F",
          v: 3,
        },
        {
          l: "140-200F",
          v: 4,
        },
        {
          l: ">200F",
          v: 5,
        },
      ],
    }
    let corrosivityOpts = {
      id: 5,
      options: [
        {
          l: "N/A",
          v: 0,
        },
        {
          l: "<=2.0",
          v: 1,
        },
        {
          l: "2.01-4.00",
          v: 2,
        },
        {
          l: "4.1-10.00",
          v: 3,
        },
        {
          l: "10.01-12.49",
          v: 4,
        },
        {
          l: ">12.5",
          v: 5,
        },
      ],
    }
    let btulbOpts = {
      id: 6,
      options: [
        {
          l: "N/A",
          v: 0,
        },
        {
          l: "<2000",
          v: 1,
        },
        {
          l: "2000-4999",
          v: 2,
        },
        {
          l: "5000-10000",
          v: 3,
        },
        {
          l: ">10000",
          v: 4,
        },
      ],
    }
    let boilingPointOpts = {
      id: 7,
      options: [
        {
          l: "N/A",
          v: 0,
        },
        {
          l: "<=95F",
          v: 1,
        },
        {
          l: ">95F",
          v: 2,
        },
      ],
    }
    let odorOpts = {
      id: 8,
      options: [
        {
          l: "None",
          v: "none",
        },
        {
          l: "Mild",
          v: "mild",
        },
        {
          l: "Strong",
          v: "strong",
        },
      ],
    }
    let tocOpts = {
      id: 9,
      options: [
        {
          l: "N/A",
          v: 0,
        },
        {
          l: "<1%",
          v: 1,
        },
        {
          l: "1-10%",
          v: 2,
        },
        {
          l: ">10%",
          v: 3,
        },
      ],
    }
    let uomOpts = {
      id: 102,
      options: [
        {
          l: "None",
          v: "0",
        },
        {
          l: "TCLP(MG/L) ",
          v: "1",
        },
        {
          l: "Total(PPM)",
          v: "2",
        },
        {
          l: "ppb (ug/L) ",
          v: "3",
        },
      ],
    }
    const getCodeOptions = (keys) => {
      switch (keys) {
        case "layered":
          return layersOpts?.options
        case "viscosity":
          return viscosityOpts?.options
        case "specificGravity":
          return specificGravityOpts?.options
        case "flashPointFlag":
          return flashPointOpts?.options
        case "phFlag":
          return corrosivityOpts?.options
        case "btuPerLbs":
          return btulbOpts?.options
        case "boilingPointFlag":
          return boilingPointOpts?.options
        case "odorFlag":
          return odorOpts?.options
        case "tocPercentage":
          return tocOpts?.options

        default:
          return []
      }
    }
    const calcProgress = async (count, leng, step, progStep) => {
      await dispatch({
        type: GET_PROGRESS_STEP,
        step: step,
        progressStep: progStep,
        percentage: (count / leng) * 100,
      })
    }

    const getGeneInfoProgress = () => {
      let data = {}
      let count = 0
      for (var prop in initGeneInfoValues) {
        if (
          prop == "unusedCommercialProductFlag" ||
          prop == "spillResidueFlag" ||
          prop == "loosepackFlag" ||
          prop == "labpackFlag"
        ) {
          data[prop] = getBooleanValues(
            current_profile,
            prop,
            initGeneInfoValues?.[prop]
          )
          if (data[prop]) {
            count = count + 1
          }
        } else if (prop == "sourceCodeId") {
          data[prop] = getObjectValues(
            sourceCodeOpts,
            current_profile,
            prop,
            initGeneInfoValues?.[prop]
          )
          if (data[prop]?.v) {
            count = count + 1
          }
        } else if (prop == "formCodeId") {
          data[prop] = getObjectValues(
            formCodeOpts,
            current_profile,
            prop,
            initGeneInfoValues?.[prop]
          )
          if (data[prop]?.v) {
            count = count + 1
          }
        } else if (prop == "originCode") {
          data[prop] = getObjectValues(
            originCodeOpts,
            current_profile,
            prop,
            initGeneInfoValues?.[prop]
          )
          if (data[prop]?.v) {
            count = count + 1
          }
        } else if (prop == "requestedProcessCodeId") {
          data[prop] = getObjectValues(
            processCodeOpts,
            current_profile,
            prop,
            initGeneInfoValues?.[prop]
          )
          if (data[prop]?.v) {
            count = count + 1
          }
        } else {
          data[prop] = getValues(
            current_profile,
            prop,
            initGeneInfoValues?.[prop]
          )
          if (data[prop]) {
            count = count + 1
          }
        }
      }
      calcProgress(count, 13, 1, 1)
    }
    const getWasteDescProgress = () => {
      let data = {}
      let count = 0
      for (var prop in initWasteCharValues) {
        if (
          prop == "powderFlag" ||
          prop == "monolithFlag" ||
          prop == "debris" ||
          prop == "compressedGas" ||
          prop == "pumpableWasteFlag" ||
          prop == "noneFlag" ||
          prop == "wasteCharNoSelectionFlag" ||
          prop == "subjectToSubpartCcFlag"
        ) {
          data[prop] = getBooleanValues(
            current_profile,
            prop,
            initWasteCharValues?.[prop]
          )
          if (data[prop]) {
            count = count + 1
          }
        } else if (
          prop == "layered" ||
          prop == "viscosity" ||
          prop == "specificGravity" ||
          prop == "flashPointFlag" ||
          prop == "phFlag" ||
          prop == "btuPerLbs" ||
          prop == "boilingPointFlag" ||
          prop == "odorFlag" ||
          prop == "tocPercentage"
        ) {
          data[prop] = getObjectValues(
            getCodeOptions(prop),
            current_profile,
            prop,
            initWasteCharValues?.[prop]
          )
          if (data[prop]?.v) {
            count = count + 1
          }
        } else if (
          prop === "freeLiquidsPct" ||
          prop === "solidPct" ||
          prop === "sludgesPct" ||
          prop === "odorDesc" ||
          prop === "specificGravityActual" ||
          prop === "flashpointActual" ||
          prop === "phExactValue" ||
          prop === "btuActual" ||
          prop === "boilingPtActual"
        ) {
          let str = ""
          str = getValues(current_profile, prop, initWasteCharValues?.[prop])
          data[prop] = str.length > 0 ? str.trim() : str
          if (data[prop]) {
            count = count + 1
          }
        } else {
          data[prop] = getValues(
            current_profile,
            prop,
            initWasteCharValues?.[prop]
          )
          if (data[prop]) {
            count = count + 1
          }
        }
      }
      calcProgress(count, 33, 2, 1)
    }

    const getChemCompProgress = () => {
      if (
        current_profile?.profileConstituents &&
        JSON.parse(current_profile?.profileConstituents)?.length > 0
      ) {
        calcProgress(1, 1, 3, 1)
      }
    }

    const getAddiInfoProgress = () => {
      let data = {}
      let count = 0
      for (var prop in initAddiInfoValues) {
        if (prop == "otherReactivity") {
          data[prop] = getValues(
            current_profile,
            prop,
            initAddiInfoValues?.[prop]
          )
          if (data[prop]) {
            count = count + 1
          }
        } else {
          data[prop] = getBooleanValues(
            current_profile,
            prop,
            initAddiInfoValues?.[prop]
          )
          if (data[prop]) {
            count = count + 1
          }
        }
      }
      calcProgress(count, 30, 4, 1)
    }

    const getStateRegInfoProgress = () => {
      let data = {}
      let count = 0
      for (var prop in initStateRegValues) {
        if (
          prop == "federalUniversalWaste" ||
          prop == "generatorStateUniversalWaste" ||
          prop == "rcraNonHazExempt" ||
          prop == "ldrFlag" ||
          prop == "benzeneWasteFlag" ||
          prop == "hazardousWasteNeshapFlag" ||
          prop == "naicsCodes1Flag" ||
          prop == "naicsCodes2Flag" ||
          prop == "benzeneWater" ||
          prop == "maxBenzeneFlag" ||
          prop == "benzeneSectionFlag" ||
          prop == "noUhcsFlag"
          // prop == "wasteWaterFlag" ||
          // prop == "ldrClassId"
        ) {
          data[prop] = getBooleanValues(
            current_profile,
            prop,
            initStateRegValues?.[prop]
          )
          if (data[prop]) count = count + 1
        } else if (prop == "wasteWaterFlag") {
          data[prop] = current_profile[prop]
            ? { l: "Non-Waste Water", v: 1 }
            : { l: "Waste Water", v: 0 }
          if (data[prop]?.v !== null) count = count + 1
        } else if (prop == "ldrClassId") {
          data[prop] = getObjectValues(
            getCodeOptions(prop),
            current_profile,
            prop,
            initStateRegValues?.[prop]
          )
          if (data[prop]?.v) count = count + 1
        } else {
          data[prop] = getValues(
            current_profile,
            prop,
            initStateRegValues?.[prop]
          )
          if (data[prop]) count = count + 1
        }
      }
      calcProgress(count, 26, 5, 1)
    }

    const setProfileNameAction = async (data) => {
      await dispatch({ type: SET_PROFILE_NAME, payload: data })
    }

    const toCamelCase = (str) => {
      return str.charAt(0).toLowerCase() + str.slice(1)
    }

    const transformKeys = (obj) => {
      if (obj?.UnitOfMeasure) {
        if (obj?.NotPresent === 1) {
          obj.UnitOfMeasure = {
            l: "None",
            v: "0",
          }
        } else {
          obj.UnitOfMeasure = {
            l: uomOpts.options[obj?.UnitOfMeasure]?.l,
            v: obj?.UnitOfMeasure,
          }
        }
      }
      return Object.keys(obj).reduce((acc, key) => {
        const camelCaseKey = toCamelCase(key)
        acc[camelCaseKey] = obj[key]
        return acc
      }, {})
    }

    const getRegConstProgress = () => {
      // let data = {}
      // let count = 0
      let rescount = 0
      // (type === "editProfile" || type === "viewProfile")
      const profileMetals = current_profile?.profileMetals
        ? JSON.parse(current_profile?.profileMetals)
        : null
      // if (dataFlag) {
      if (profileMetals) {
        if (
          profileMetals?.Analytical ||
          profileMetals?.GeneratorKnowledge ||
          profileMetals?.SDS
        )
          rescount += 1
        const mappedMetals = profileMetals?.Metals?.map(transformKeys)
        const mappedVolatiles =
          profileMetals?.VolatileCompounds?.map(transformKeys)
        const mappedSemiVolatiles =
          profileMetals?.SemiVolatiles?.map(transformKeys)
        const mappedPestHerbs =
          profileMetals?.PesticidesHerbicides?.map(transformKeys)
        let totalCount =
          (mappedMetals?.length || 0) +
          (mappedVolatiles?.length || 0) +
          (mappedSemiVolatiles?.length || 0) +
          (mappedPestHerbs?.length || 0)
        if (mappedMetals?.length > 0) {
          mappedMetals?.map((metalitem) => {
            if (
              metalitem?.notPresent ||
              metalitem?.belowTclpLimit ||
              metalitem?.aboveTclpLimit
            )
              rescount = rescount + 1
          })
        }
        if (mappedVolatiles?.length > 0) {
          mappedVolatiles?.map((volatileItem) => {
            if (
              volatileItem?.notPresent ||
              volatileItem?.belowTclpLimit ||
              volatileItem?.aboveTclpLimit
            )
              rescount = rescount + 1
          })
        }
        if (mappedSemiVolatiles?.length > 0) {
          mappedSemiVolatiles?.map((semiVolatileItem) => {
            if (
              semiVolatileItem?.notPresent ||
              semiVolatileItem?.belowTclpLimit ||
              semiVolatileItem?.aboveTclpLimit
            )
              rescount = rescount + 1
          })
        }
        if (mappedPestHerbs?.length > 0) {
          mappedPestHerbs?.map((pestItem) => {
            if (
              pestItem?.notPresent ||
              pestItem?.belowTclpLimit ||
              pestItem?.aboveTclpLimit
            )
              rescount = rescount + 1
          })
        }
        calcProgress(totalCount > 0 ? totalCount : 1, rescount, 6, 1)
      } else {
        calcProgress(0, 1, 6, 1)
      }
    }

    const getShippingProgress = () => {
      let data = {}
      let count = 0
      for (var prop in initShipValues) {
        if (
          prop == "bulkLiquidFlag" ||
          prop == "bulkSolidFlag" ||
          prop == "containerTypesizeFlag" ||
          prop == "searchAnywhereFlag" ||
          prop == "rq" ||
          prop == "limitedQty" ||
          prop == "marinePollutants"
        ) {
          data[prop] = getBooleanValues(
            current_profile,
            prop,
            initShipValues?.[prop]
          )
          if (data[prop]) {
            count = count + 1
          }
        } else if (
          prop == "containerTypeId" ||
          prop == "frequencyId" ||
          prop == "dotId"
        ) {
          data[prop] = getObjectValues(
            getCodeOptions(prop),
            current_profile,
            prop,
            initShipValues?.[prop],
            "id"
          )
          if (data[prop]?.v) {
            count = count + 1
          }
        } else {
          data[prop] = getValues(current_profile, prop, initShipValues?.[prop])
          if (data[prop]) {
            count = count + 1
          }
        }
      }
      calcProgress(count, 16, 7, 1)
    }

    const getDocumentValues = async (reqId) => {
      const getProfile = profileList.find(
        (item) => item.profileId == id || item.profile == id
      )

      if (getProfile?.status?.toLowerCase() === "draft") {
        const req = {
          profileRequestId: reqId,
          documentTypeEntity: "Profile",
          documentDesc: "",
        }
        try {
          let res = await api.p_getDocuments(req)
          let data = res?.filter(
            (val) => val.documentTypeId !== 9 && val.documentTypeId !== 27
          )
          if (!data.length) return
          dispatch({
            type: GET_PROGRESS_STEP,
            step: 8,
            progressStep: 1,
            percentage: 100,
          })
        } catch (error) {}
      } else {
        if (
          current_profile?.documents &&
          JSON.parse(current_profile?.documents)?.length > 0
        ) {
          dispatch({
            type: GET_PROGRESS_STEP,
            step: 8,
            progressStep: 1,
            percentage: 100,
          })
        }
      }
    }

    const getGeneCertProgress = async () => {
      let data = {}
      let count = 0
      for (var prop in initGeneCertValues) {
        if (
          prop == "electronicSybmissionFlag" ||
          prop == "letterOfConcentFlag" ||
          prop == "presignedstatus"
        ) {
          data[prop] = getBooleanValues(
            current_profile,
            prop,
            initGeneCertValues?.[prop]
          )
          if (data[prop]) {
            count = count + 1
          }
        } else if (prop == "customerSignatureDatetime") {
          data[prop] = getDateValues(
            current_profile,
            prop,
            initGeneCertValues?.[prop]
          )
          if (data[prop]) {
            count = count + 1
          }
        } else {
          data[prop] = getValues(
            current_profile,
            prop,
            initGeneCertValues?.[prop]
          )
          if (data[prop]) {
            count = count + 1
          }
        }
      }
      calcProgress(count, 7, 9, 1)
    }

    const getProfileData = async (reqId, template, isNonDraft) => {
      let req
      if (isNonDraft || reqId == 0) {
        req = {
          profileRequestId: 0,
          webProfileId: "",
          coreProfileNumber: reqId?.profile,
          coreVersionNumber: version || Number(reqId?.version),
        }
      } else {
        req = {
          profileRequestId: reqId,
        }
      }
      try {
        let res = await api.p_getProfileById(req)
        if (!res) return
        if (template) {
          dispatch({
            type: CURRENT_PROFILE,
            payload: { ...res, templateFlag: true, profileName: "" },
          })
        } else {
          dispatch({ type: CURRENT_PROFILE, payload: res })
        }
      } catch (error) {}
    }
    const goToEditProfile = async (page, id) => {
      // await dispatch({
      //   type: SET_NEW_PROGRESS_STEP,
      // })
      // await dispatch({
      //   type: SET_PROGRESS_STEP,
      //   step: 0,
      //   percentage: 100,
      // })
      history.push(`/profiles/${page}/${id}`)
    }

    const goToCloneProfile = async (page, profileId) => {
      const getProfile = profileList.find(
        (item) => item.profileId == profileId || item.profile == profileId
      )

      if (getProfile !== undefined) {
        try {
          let res = await api.p_getCloneProfile({
            profileId: getProfile?.profileId || 0,
            profileNumber: getProfile?.profile,
            revisionNum: getProfile?.version,
          })
          if (!res) return
          await getProfileData(res?.data?.profileRequestId, true)
          await dispatch({ type: SET_PROGRESS_STEP_ONLY, progressStep: 1 })
          let pId = res?.data?.profileRequestId
          await goToEditProfile(page, pId)
        } catch (error) {}
      }
    }

    const deleteProfile = async (id) => {
      let req = {
        profileRequestId: id,
      }
      try {
        let res = await api.pu_deleteProfile(req)
        if (!res) return
        history.push(`/profiles`)
      } catch (error) {}
    }
    useEffect(() => {
      if (
        id &&
        (current_profile?.generatorId || current_profile?.templateFlag) &&
        !isLoading
      ) {
        getGeneInfoProgress()
        getWasteDescProgress()
        getChemCompProgress()
        getAddiInfoProgress()
        getStateRegInfoProgress()
        // if (type === "editProfile" || type === "viewProfile") {
        getRegConstProgress()
        // }
        getShippingProgress()
        getDocumentValues(current_profile?.profileRequestId)
        getGeneCertProgress()
        setLoading(true)
      }
    }, [current_profile])

    const goToRecertifyProfile = async (data) => {
      dispatch({ type: RECERTIFY_PROFILE, payload: data })
      getProfileData(data, false, true)
      history.push(`/profiles/recertify`)
    }

    const handlePrintState = (print, data) => {
      const isNonDraft = data.status?.toLowerCase() != "draft"
      getProfileData(
        isNonDraft || data?.profileId == 0 ? data : data?.profileId,
        false,
        isNonDraft
      )
      // getProfileData(isNonDraft ? data : data?.profileId, false, isNonDraft)
      dispatch({ type: GET_PROFILE_FOR_PRINT_LABEL, payload: data })
      dispatch({ type: GET_PRINT_STATUS, payload: print })
    }

    const downloadDocument = async (id, status, revsNo) => {
      let payload

      if (status?.toLowerCase() == "draft") {
        payload = {
          profileId: id,
        }
      } else {
        payload = {
          profileId: 0,
          profileNumber: id,
          coreRevisionNumber: revsNo,
        }
      }
      try {
        let res = await api.pb_downloadProfileDocuments(payload)
        if (res?.type === "application/pdf") {
          let url = window.URL.createObjectURL(res)
          let a = document.createElement("a")
          a.href = url
          a.download = `profile${id}.pdf`
          a.click()
          a.remove()
          setTimeout(() => window.URL.revokeObjectURL(url), 100)
        } else {
          toast.error("Waste profile form not found.")
        }
      } catch (error) {}
    }

    const printDocument = async (id, status, revsNo) => {
      let payload

      if (status?.toLowerCase() == "draft") {
        payload = {
          profileId: id,
        }
      } else {
        payload = {
          profileId: 0,
          profileNumber: id,
          coreRevisionNumber: revsNo,
        }
      }
      try {
        let res = await api.pb_downloadProfileDocuments(payload)
        let url = window.URL.createObjectURL(res)
        const iframe = document.querySelector("#pdf-frame")
        iframe.src = ""
        iframe.src = url
        iframe.style.display = "none"
        setTimeout(() => window.URL.revokeObjectURL(url), 100)
        setTimeout(function () {
          document.querySelector("#pdf-frame").contentWindow.print()
        }, 1000)
      } catch (error) {
      } finally {
        setPrintLoader(false)
      }
    }

    return (
      <Component
        {...props}
        {...{
          goToCloneProfile,
          goToEditProfile,
          deleteProfile,
          goToRecertifyProfile,
          handlePrintState,
          downloadDocument,
          printDocument,
          setProfileNameAction,
          printLoader,
          setPrintLoader,
          // processCodeOpts,
          // sourceCodeOpts,
          // formCodeOpts,
          // originCodeOpts,
          // setGeneratorName,
          // getGeneratorDetails,
        }}
      />
    )
  }

export default enhancer
