import React from "react"
import Image from "shared/HelpImage/index"

function QuaterlyOverview() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">
        Full Circle Dashboard: Quarterly Overview{" "}
      </h3>

      <p className="text-justify pl-3 pt-2">
        The quarterly overview dashboard shows informational tiles that run
        across the top of the screen. Quarterly waste disposition report summary
        in the middle and Top 10 Profile Report on the right side.
      </p>
      <Image src="DashboardMyAccount_Fc.png" />
    </div>
  )
}
export default QuaterlyOverview
