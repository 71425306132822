import React from "react"
import "./App.scss"
import "balloon-css"
import "bootstrap/dist/css/bootstrap.css"
import "react-calendar/dist/Calendar.css"
import "react-tabs/style/react-tabs.css"
import "react-toastify/dist/ReactToastify.css"
import { BrowserRouter as Router } from "react-router-dom"
import { IntlProvider } from "react-intl"
import messages_en from "local/en.json"
import store, { persistor } from "./store"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import MainWrapper from "layout"
import { initializeGTag } from "shared/gtag"

initializeGTag()

const messages = {
  en: messages_en,
}
const language = navigator.language.split(/[-_]/)[0]

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <IntlProvider locale={language} messages={messages[language]}>
            <MainWrapper />
          </IntlProvider>
        </Router>
      </PersistGate>
    </Provider>
  )
}

export default App
