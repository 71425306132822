import { logOut } from "msal"
import React from "react"
import { FormattedMessage } from "react-intl"

const Unauthorised = () => {
  const logout = () => {
    logOut()
  }

  return (
    <div className="container h-100 font-weight-bold">
      <div className="row text-center p-5">
        <div className="col-12">
          <FormattedMessage id="unauthorised.error.title" />
        </div>
        <div className="col-12">
          <FormattedMessage id="unauthorised.error.message" />
        </div>
        <br />
        <br />
        <div className="col-12">
          <button onClick={logout} className="logout-button btn-link">
            <FormattedMessage id="unauthorised.error.logout" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Unauthorised
