import React from "react"
function Purpose() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Purpose</h3>
      <p className="text-justify pl-3 pt-2">
        The purpose of this document is to provideguidance on the dashboard for
        Clean EarthHome Depotservices. Included in this reference document is
        guidance on how to utilize the report generator, schedule view, profile
        center, document library, service request information and notification
        settings
      </p>
    </div>
  )
}
export default Purpose
