import React from "react"
import Image from "shared/HelpImage/index"
import { useSelector } from "react-redux"

function ViewAllAnnouncements() {
  const { changeCustomer } = useSelector((state) => state)
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">View All Announcements</h3>
      <p className="text-justify pl-3 pt-3">
        After each announcement scrolling across the banner you will see the
        word <u style={{ color: "#61b2ec" }}>more...</u>
      </p>
      <div className="img-section">
        <Image
          src={
            changeCustomer?.customer?.businessSegmentId === 3
              ? "mi_announcement_more1.png"
              : "announcement_banner_6.png"
          }
          alt=""
        />
      </div>
      <p className="text-justify pl-3 pt-3">
        Clicking on the word <u style={{ color: "#61b2ec" }}>more...</u> will
        display the complete text of the message you have selected.
      </p>
      <div className="img-section">
        <Image
          src={
            changeCustomer?.customer?.businessSegmentId === 3
              ? "mi_announcement_more2.png"
              : "viewing_announcements.png"
          }
          alt=""
        />
      </div>
      <p className="text-justify pl-3 pt-3">
        In the upper-right corner of the display, you will see an indication of
        how many messages you have to view. In this example, there are five.
        Clicking on the right-pointing and left-pointing arrows will scroll
        among however many messages you have to view.
      </p>
      <p className="text-justify pl-3 pt-3">
        In the lower-left corner, you will see a series of dots also indicating
        how many messages you have to view. The highlighted dot indicates which
        message you are looking at. Clicking on any of the dots will take you
        directly to the selected message.
      </p>
      <p className="text-justify pl-3 pt-3">
        Clicking on OK <Image src="announcement_ok.svg" /> will remove the
        message display and return you to the Dashboard.
      </p>
      <div className="img-section">
        <Image
          src={
            changeCustomer?.customer?.businessSegmentId === 3
              ? "mi_announcement_banner1.png"
              : "announcement_banner_1.png"
          }
          alt=""
        />
      </div>
      <p className="text-justify pl-3 pt-3">
        Every announcement has an expiration date set by the portal
        administrator. The announcement will continue to be displayed in the
        banner every time you login until it reaches its expiration date. Then
        it will be removed from the banner.
      </p>
    </div>
  )
}
export default ViewAllAnnouncements
