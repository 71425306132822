import React from "react"
import Image from "shared/HelpImage/index"

function Wasteprocesssummary() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">
        Full Circle Dashboard: Waste Process Summary.{" "}
      </h3>

      <p className="text-justify pl-3 pt-2">
        The Quarterly Waste Disposition Report Summary shows the waste that was
        processed by treatment type, grouped by each quarter in the last four
        available quarters
      </p>
      <Image src="WasteProcessSummaryBar_Fc.png" />
      <p className="text-justify pl-3 pt-2">
        The trend line chart is available using the toggle icon, a trend line of
        the weights processed by treatment type, grouped by each quarter in the
        last four available quarters.
      </p>
      <Image src="WasteProcessSummaryLine_Fc.png" />
    </div>
  )
}
export default Wasteprocesssummary
