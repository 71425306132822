import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import { Document, Page } from "react-pdf"
import Image from "shared/Image"

function PdfViewer(props) {
  let {
    file,
    fileName,
    numPages,
    onDocumentLoadSuccess,
    loading,
    download,
    zoomIn,
    zoomOut,
    zoomScale,
    config,
  } = props

  if (loading) {
    return (
      <div className="loader-parent">
        <div className="loader"></div>
      </div>
    )
  }

  return (
    <div className="pdf-viewer">
      <div className="page-header">
        <div>{fileName}</div>
        <div className="header-right-sec">
          <div>
            <button
              className="pointer"
              onClick={zoomOut}
              disabled={zoomScale == config.min}
            >
              -
            </button>
            <span className="px-2 fs-13">{zoomScale}%</span>
            <button
              className="pointer"
              onClick={zoomIn}
              disabled={zoomScale == config.max}
            >
              +
            </button>
          </div>
          <div onClick={download} className="pointer">
            <Image src="ic_download.svg" alt="" />
          </div>
        </div>
      </div>
      <div
        className="pages pt-1"
        id="pdf_pages"
        style={{
          zoom: `${zoomScale / 100}`,
        }}
      >
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Fragment key={`page_${index + 1}`}>
              <Page pageNumber={index + 1} />
              <div className="pb-2"></div>
            </Fragment>
          ))}
        </Document>
      </div>
    </div>
  )
}

export default enhancer(PdfViewer)
