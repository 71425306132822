import axios from "axios"
import _ from "lodash"
import { setupCache } from "axios-cache-adapter"
import { toast } from "react-toastify"
import store from "store"
import { formatMessage } from "shared"
import { SET_LOADING_STATUS } from "store/reducer/actionType"
import localforage from "localforage"
import memoryDriver from "localforage-memoryStorageDriver"
import { acquireToken, logOut } from "msal"
import { trackEvent } from "config/appInsights"

localforage.defineDriver(memoryDriver)

// Create `localforage` instance
const forageStore = localforage.createInstance({
  // List of drivers used
  driver: [
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
  // Prefix all storage keys to prevent conflicts
  name: "clean-earth",
})

const cache = setupCache({
  maxAge: 60 * 60 * 1000,
  store: forageStore,
  exclude: {
    query: false,
    paths: [
      "/api/user",
      "/api/MniProfile",
      "/api/MniGenerator",
      "/sapi/user",
      "/api/MniProfile",
      "/api/MniGenerator",
      "/api/alert",
      "api/dataload-alert",
      "/api/report",
      "api/gmap/geocode",
      "/api/user/onBoardedCustomers",
      "/api/dashboard/manifest/tile/weightpickedup",
      "/api/dashboard/order/tile/totalspends",
      "/api/dashboard/order/tile/scheduled-stops",
      "/api/dashboard/order/tile/stops-completed",
      "/api/user/getBusinessSegmentCustomers",
      "/api/location/generatorsInfo",
      "/api/location",
      "/api/announcements",
      "/api/user/onBoardedEnterpriseCustomers",
      "/api/dashboard/treatment-type",
      "/api/fullcircle/top-profiles",
    ],
  },
})

const axios_c = axios.create({
  adapter: cache.adapter,
})

// let host = "/"

// if (process && process.env.REACT_APP_API) {
//   host = process.env.REACT_APP_API
//   axios.defaults.baseURL = host
// }

axios_c.defaults.headers.Accept = "application/json"

axios_c.interceptors.request.use(
  function (config) {
    let { loading_key } = config
    if (loading_key) {
      store.dispatch({ type: SET_LOADING_STATUS, loading_key, status: true })
      // window.scroll({ top: 0, left: 0 })
    }
    if (localStorage.idToken) {
      config.headers.Authorization = `Bearer ${localStorage.idToken}`
    }
    config.metadata = { startTime: new Date() }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
let loggedOut = false

axios_c.interceptors.response.use(
  function (response) {
    let { loading_key } = response.config
    if (loading_key) {
      store.dispatch({ type: SET_LOADING_STATUS, loading_key, status: false })
    }

    // For app insights
    response.config.metadata.endTime = new Date()
    const { endTime, startTime } = response.config.metadata
    response.duration = (endTime - startTime) / 1000
    if (!response.request.fromCache) {
      const {
        config: { url, method, data },
        duration,
        status,
      } = response
      const {
        profile: { customer_name, email },
      } = store.getState()
      trackEvent({
        url,
        duration: `${duration.toFixed(2)}s`,
        httpMethod: method.toUpperCase(),
        httpStatusCode: status,
        payload: data || null,
        response: JSON.stringify(response.data),
        success: true,
        customer_email: email,
        customer_name,
      })
    }

    return response
  },
  async function (error) {
    if (loggedOut) return
    if (error?.response?.status === 404) return
    if (error?.response?.status === 403) {
      loggedOut = true
      let { user } = store.getState()
      let u2 = _.cloneDeep(user)
      u2.noAccess = true
      store.dispatch({ type: "SET_USER", user: u2 })
      return
    }
    if (error?.response?.status === 401) {
      let { user } = store.getState()
      let u2 = _.cloneDeep(user)
      let { account } = u2

      try {
        let response = await acquireToken(account)
        let { accessToken, idToken } = response

        u2.accessToken = accessToken
        u2.account = response.account
        u2.idToken = idToken
        localStorage.idToken = idToken

        store.dispatch({ type: "SET_USER", user: u2 })
        const { config } = error
        config.headers.Authorization = `Bearer ${idToken}`

        return axios_c(config)
      } catch (error) {
        return logOut("/")
      }
    }
    let { loading_key } = error.config || {}
    let { message } = error || {}

    if (message.includes("cancel;")) {
      loading_key = message.split(";")[1]
    } else {
      let en_msg = formatMessage(`global.error.${loading_key}`)
      let sorry = "Sorry something went wrong."
      let msg = `${sorry} \n ${en_msg}`
      if (navigator.onLine == false || message == "Network Error") {
        //eslint-disable-next-line
        console.log("networkerroronline", navigator.onLine)
        //eslint-disable-next-line
        console.log("networkerrormessage", message)
        //eslint-disable-next-line
        console.log("networkerrorresponse", error?.response)
        //eslint-disable-next-line
        console.log("networkerrorobject", error)
        msg = formatMessage("global.error.notInternet")
      }
      if (
        loading_key === "create_user" ||
        loading_key === "create_announcement"
      )
        msg = error?.response?.data
      if (error?.response?.status === 500 && loading_key === "create_user") {
        msg = "unable to create user"
      }
      if (
        error?.response?.status === 400 &&
        loading_key === "updateuser_customer"
      ) {
        return
      }
      if (error?.response?.status === 404) {
        return
      }
      // toast.dismiss()
      // toast.clearWaitingQueue()
      toast.error(msg, { autoClose: 3000 })
    }

    store.dispatch({ type: SET_LOADING_STATUS, loading_key, status: false })

    error.config.metadata.endTime = new Date()
    const { endTime, startTime } = error.config.metadata
    error.duration = (endTime - startTime) / 1000
    const { config = {}, response = {}, duration } = error
    const {
      profile: { customer_name, email },
    } = store.getState()
    trackEvent({
      url: config.url,
      duration: `${duration.toFixed(2)}s`,
      httpMethod: config.method.toUpperCase(),
      payload: config.data,
      message,
      httpStatusCode: response.status || 0,
      response: response.data ? JSON.stringify(response.data) : null,
      success: false,
      customer_email: email,
      customer_name,
    })

    return Promise.reject(error)
  }
)

window.badToken = () => {
  localStorage.idToken = "xxx"
}

export default axios_c
