import React from "react"
import Image from "shared/HelpImage/index"

function Administrator() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Administration</h3>
      <div className="img-section">
        <Image
          src="Adminstration_Home.png"
          alt="Change Request (Service Details) Image"
        />
      </div>
      <p className="text-justify pl-3 pt-2">
        <p>
          The Administration Feature is available to users appointed with
          Administrator rights within your organization. You have the capability
          to a Create a New User and Edit existing Users Access.
        </p>
      </p>
    </div>
  )
}
export default Administrator
