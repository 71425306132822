import {
  SET_ALERTS,
  UPDATE_IS_READ_ALERT,
  SET_DOWNLOAD_ALERTS,
  UPDATE_IS_READ_DOWNLOAD_ALERT,
  DELETE_ALTERS,
} from "./actionType"

const alerts = (state = [], action) => {
  let newState = []
  switch (action.type) {
    case SET_ALERTS:
      newState = {
        alerts: [...action.alertList],
        doAlertList: [],
      }
      return newState

    case UPDATE_IS_READ_ALERT:
      newState = { ...state }
      newState.alerts[action.index].isRead = true
      return newState

    case UPDATE_IS_READ_DOWNLOAD_ALERT:
      newState = state
      newState.doAlertList = [...action.doAlertList]
      return newState

    case SET_DOWNLOAD_ALERTS:
      newState = {
        ...state,
        doAlertList: [...action.doAlertList],
      }
      return newState
    case DELETE_ALTERS:
      return newState
    default:
      return state
  }
}

export default alerts
