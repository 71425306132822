import React, { useEffect, useState } from "react"
import "./style.scss"
import Marquee from "react-fast-marquee"
import { useSelector } from "react-redux"
import Image from "shared/Image"

function Ticker(props) {
  const { setShowAnnouncements, setTickerAnnouncements, showAnnouncements } =
    props
  const { changeCustomer, profile } = useSelector((state) => state)
  const [Ticker, showTicker] = useState(false)

  const [direction, setDirection] = useState("left")

  let arrowDirection = (direction) => {
    setDirection(direction)
  }

  useEffect(() => {
    if (Ticker != !showAnnouncements) showTicker(!showAnnouncements)
  }, [showAnnouncements])

  return profile?.announcements?.length > 0 && Ticker ? (
    <div className="base-div-ticker">
      <div className="row">
        <div className="col-2 header-div-ticker">
          <span className="header-span">ANNOUNCEMENTS</span>
        </div>

        <div className="col-9 pull-1">
          <Marquee speed={55} pauseOnHover={true} direction={direction}>
            {profile?.announcements?.map((element, i) => (
              <div key={i} className="announcement-text">
                <div>
                  {element?.description?.substr(0, 50 - 1) +
                    (element?.description?.length > 50 ? "..." : "")}
                </div>
                <div
                  className="viewallticker"
                  onClick={() => {
                    setShowAnnouncements(true)
                    setTickerAnnouncements(i)
                    showTicker(false)
                  }}
                >
                  more ...
                </div>
                <div style={{ paddingLeft: 15, paddingRight: 15 }}>|</div>
              </div>
            ))}
          </Marquee>
        </div>
        <div className="col-1 arrows-div">
          <span>
            <Image
              className="arrow-left-btn"
              src={"ic_back_arrow.svg"}
              height="15px"
              width="15px"
              alt=""
              onClick={() => arrowDirection("right")}
            />
          </span>
          <span>
            <Image
              className="arrow-right-btn"
              src={"ic_forward_arrow.svg"}
              height="15px"
              width="15px"
              alt=""
              onClick={() => arrowDirection("left")}
            />
          </span>
          <span style={{ paddingTop: 5 }}>
            <Image
              className="close-btn"
              src={"ic_close_btn.svg"}
              height="15px"
              width="15px"
              alt=""
              onClick={() => showTicker(false)}
            />
          </span>
        </div>
      </div>
    </div>
  ) : (
    <div>
      {profile?.announcements?.length > 0 && !Ticker && (
        <div
          className={
            changeCustomer?.customer?.businessSegmentId === 3 ? "pb-4" : ""
          }
        >
          <span className="open-announcement-arrow-text">Announcements</span>
          <Image
            className="open-announcement-arrow"
            src={"ic_back_arrow_open.svg"}
            height="15px"
            width="15px"
            alt=""
            onClick={() => showTicker(true)}
          />
        </div>
      )}
    </div>
  )
}

export default Ticker
