import React, { useState, useEffect } from "react"
import _ from "lodash"
import * as dfns from "date-fns"
import api from "api"
import { fixMissingDays, formattedDate } from "shared"
import {
  useToday,
  getDate,
  checkArrayHasDate,
  monthNames,
  fixMissingDaysOrDates,
} from "shared"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    let today = useToday()
    let monthText = dfns.format(today, "LLLL")
    let date_format = "dd LLL"
    let graphSelectOpts = [
      { l: "Current Week", value: "week" },
      { l: monthText, value: "month" },
    ]
    let graphOptionWeightPickedUp = [
      {
        l: monthText,
        value: { from: dfns.startOfMonth(today), to: today },
        id: "currentMonth",
      },
      {
        l: "Last 180 Days",
        value: { from: getDate(today, -179), to: today },
        id: "last6Months",
      },
      {
        l: "Last 365 Days",
        value: { from: getDate(today, -364), to: today },
        id: "last12Months",
      },
    ]
    let n_dates = {
      month: [dfns.startOfMonth(today), dfns.endOfMonth(today)],
      week: [dfns.startOfWeek(today), dfns.endOfWeek(today)],
    }

    let [weightPickedUpData, setWeightPickedUpData] = useState(false)
    let [optsWeightPickedUp, setOptsWeightPickedUp] = useState(
      graphOptionWeightPickedUp[0]
    )

    let updateValD = (val) => {
      setOptsWeightPickedUp(val)
    }

    let [opts, setOpts] = useState({
      hazWaste: graphSelectOpts[0],
      wasteStream: graphSelectOpts[0],
    })

    let [data, setData] = useState({
      hazWaste: [],
      wasteStream: [],
    })

    const updateOpts = (type) => (opt) => {
      let opts2 = _.cloneDeep(opts)
      // opts2[type] = opt
      opts2["wasteStream"] = opt
      opts2["hazWaste"] = opt
      setOpts(opts2)
    }

    /* calculation for the total month data and 
                      also making the array for th bar graph*/
    let dataForTotalWeightGraph = async (value) => {
      let dataArr = []
      let weight = 0
      let month90Days = 0
      let vKeys = "weight"
      let n_dates_w = [value?.from, value?.to]

      if (n_dates_w[0] !== undefined) {
        let data = await api.g_weightPickedUp({
          dates: n_dates_w,
          loading_key: "waste-process-totalWeight",
        })

        let nData = data["week"]

        let fromDateExsits = checkArrayHasDate(nData, value?.from)
        let toDateExsits = checkArrayHasDate(nData, value?.to)

        if (nData.length === 0 || !fromDateExsits || !toDateExsits) {
          if (nData.length === 0 || !fromDateExsits) {
            let curDate = value?.from //getDate(today, -90)
            let curDay = new Date(curDate).toLocaleString("default", {
              weekday: "long",
            })
            nData.push({
              date: dfns.format(curDate, "yyyy-MM-dd") + "T00:00:00",
              day: curDay,
              weight: 0,
            })
          }
          if (nData.length === 0 || !toDateExsits) {
            nData.push({
              date: dfns.format(value?.to, "yyyy-MM-dd") + "T00:00:00",
              day: new Date(value?.to).toLocaleString("default", {
                weekday: "long",
              }),
              weight: 0,
            })
          }
        }

        nData = fixMissingDaysOrDates(data["week"], vKeys, "monthly")

        nData.forEach((d3, i) => {
          let { date } = d3
          let month = new Date(date).getMonth()
          if (i == 0 || month != month90Days) {
            weight = 0
            weight += d3[vKeys]
            month90Days = month
            let wN = {
              day: _.upperFirst(_.camelCase(monthNames[month])),
              cur: weight,
            }
            dataArr = [...dataArr, wN]
          } else {
            weight += d3[vKeys]
            dataArr[dataArr.length - 1].cur = weight
          }
        })
        setWeightPickedUpData(dataArr)
      }
    }

    let datesForTitle = (type) => {
      let [from, to] = n_dates[opts[type]?.value]
      return {
        from: formattedDate(from, false),
        to: formattedDate(to, false),
      }
    }

    let dataForTitle = (type) => data[type][opts[type].value] || []

    useEffect(() => {
      dataForTotalWeightGraph(optsWeightPickedUp?.value)
    }, [optsWeightPickedUp])

    useEffect(() => {
      ;(async () => {
        let n_data = { hazWaste: {}, wasteStream: {} }
        let resWeek = await api.g_wasteProcess({
          dates: n_dates.week,
          loading_key: "waste-process-week",
          type: "week",
        })
        if (!resWeek) return
        let resMonth = await api.g_wasteProcess({
          dates: n_dates.month,
          type: "month",
          loading_key: "waste-process-month",
        })
        if (!resMonth) return

        resMonth.wasteStream = resMonth?.wasteStream?.filter(
          (d) => d?.weight > 0
        )
        // if (resMonth.hazWaste)
        //   resMonth.hazWaste = resMonth.hazWaste.filter((d) => d.weight > 0)

        n_data.hazWaste.week = fixMissingDays(resWeek.hazWaste, "weight")
        let hazWasteMonth = resMonth.hazWaste || []

        hazWasteMonth.forEach((d) => {
          d.date = new Date(d.weekStart)
          d.day = dfns.format(d.date, date_format)
        })

        let dates = dfns.eachWeekOfInterval({
          start: n_dates.month[0],
          end: n_dates.month[1],
        })

        dates[0] = n_dates.month[0]
        // if (!dfns.isEqual(_.last(dates), n_dates.month[1]))
        //   dates.push(n_dates.month[1])

        dates = dates.map((d) => ({
          weight: 0,
          day: dfns.format(d, date_format),
          date: d,
        }))

        hazWasteMonth = _.unionBy(hazWasteMonth, dates, "day")
        hazWasteMonth.forEach((d, i) => {
          let dW = dfns.endOfWeek(d.date)
          let dM = dfns.endOfMonth(d.date)
          let dD = dfns.isBefore(dW, dM) ? dW : dM
          d.tooltip = `Week ${i + 1} (${formattedDate(
            d.date,
            false
          )}-${formattedDate(dD, false)})`
          d.day = `Week ${i + 1}`
        })

        resWeek.wasteStream = resWeek?.wasteStream?.filter(
          (d) => d?.weight != 0
        )
        resMonth.wasteStream = resMonth?.wasteStream?.filter(
          (d) => d?.weight != 0
        )

        n_data.hazWaste.month = hazWasteMonth
        n_data.wasteStream.week = resWeek?.wasteStream
        n_data.wasteStream.month = resMonth?.wasteStream
        setData(n_data)
      })()
    }, [])

    return (
      <Component
        {...props}
        {...{
          opts,
          updateOpts,
          graphSelectOpts,
          n_dates,
          datesForTitle,
          dataForTitle,
          dataForTotalWeightGraph,
          graphOptionWeightPickedUp,
          weightPickedUpData,
          optsWeightPickedUp,
          setOptsWeightPickedUp,
          updateValD,
        }}
      />
    )
  }

export default enhancer
