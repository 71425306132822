import { FormattedMessage } from "react-intl"
import Image from "shared/Image"
import video1 from "../../assets/videos/video1080p1.mp4"
import video2 from "../../assets/videos/video1080p2.mp4"
import quickTour from "../../assets/videos/Quick_Tour_Store_level.mp4"

const adminQuickTour = [
  {
    selector: "#profile",
    heading: <FormattedMessage id="quicktour.profileHeader" />,
    description: (
      <div>
        <p>
          <FormattedMessage id="quicktour.profileDescription" />
        </p>
      </div>
    ),
    finishFlag: false,
    position: "right",
  },
  {
    selector: "#headerSection",
    heading: <FormattedMessage id="quicktour.cleanHeader" />,
    description: (
      <div>
        <p className="fn-14">
          <b>
            <FormattedMessage id="quicktour.cleanearthnews" />
          </b>{" "}
          <FormattedMessage id="quicktour.cleanearthnewsdescription" />
        </p>
        <p className="fn-14">
          <b>NEW REQUEST</b>{" "}
          <FormattedMessage id="quicktour.requestservicedescription" />
        </p>
        <p className="fn-14">
          <Image src="user_guide.svg" alt="logout" height="26px" width="26px" />{" "}
          <FormattedMessage id="quicktour.helpdescription" />
        </p>
        <p className="fn-14">
          <Image
            src="ic_alerts_white.svg"
            alt="logout"
            height="26px"
            width="26px"
          />
          <FormattedMessage id="quicktour.alertdescription" />
        </p>
        <p className="fn-14">
          <Image
            src="ic_log_out_white.svg"
            alt="logout"
            height="26px"
            width="26px"
          />
          <FormattedMessage id="quicktour.logoutdescription" />
        </p>
      </div>
    ),
    finishFlag: false,
    position: "bottom",
  },
  {
    selector: "#sideNav",
    heading: <FormattedMessage id="quicktour.sidenavheader" />,
    description: (
      <div>
        <p className="fn-14">
          <b>
            <Image className="h-w-34" src={"ic_dashboard.svg"} alt="" />
          </b>
          <FormattedMessage id="quicktour.subdashboarddescription" />
        </p>
        <p className="fn-14">
          <b>
            {" "}
            <Image className="h-w-34" src={"ic_report_generator.svg"} alt="" />
          </b>{" "}
          <FormattedMessage id="quicktour.subreportgeneratordescription" />
        </p>
        <p className="fn-14">
          <b>
            <Image className="h-w-34" src={"ic_schedules.svg"} alt="" />
          </b>{" "}
          <FormattedMessage id="quicktour.submyorderdescription" />
        </p>
        <p className="fn-14">
          <b>
            <Image className="h-w-34" src={"ic_profile_center.svg"} alt="" />
          </b>
          <FormattedMessage id="quicktour.submyprofilesdescription" />
        </p>
        <p className="fn-14">
          <b>
            <Image className="h-w-34" src={"ic_document_library.svg"} alt="" />
          </b>
          <FormattedMessage id="quicktour.subdocumentlibrarydescription" />
        </p>
        <p className="fn-14">
          <b>
            <Image className="h-w-34" src={"ic_truck.svg"} alt="" />{" "}
          </b>
          <FormattedMessage id="quicktour.subrequestservicedescription" />
        </p>
        <p className="fn-14">
          <b>
            <Image
              className="h-w-34"
              src={"ic_notification_settings.svg"}
              alt=""
            />{" "}
          </b>
          <FormattedMessage id="quicktour.submyalertsdescription" />
        </p>
        <p className="fn-14">
          <b>
            <Image className="h-w-34" src={"ic_support.svg"} alt="" />{" "}
          </b>
          <FormattedMessage id="quicktour.subsupportdescription" />
        </p>
        <p className="fn-14">
          <b>
            <Image className="h-w-34" src={"ic_log_out.svg"} alt="" />
          </b>{" "}
          <FormattedMessage id="quicktour.sublogoutdescription" />
        </p>
      </div>
    ),
    finishFlag: false,
    position: "right",
  },
  {
    selector: "#support",
    heading: <FormattedMessage id="quicktour.supportHeader" />,
    description: (
      <p className="fn-14">
        <FormattedMessage id="quicktour.supportexplaination" />
      </p>
    ),
    finishFlag: true,
    position: "right",
  },
]
const storeQuickTour = [
  {
    selector: "#headerSection",
    heading: <FormattedMessage id="quicktour.cleanHeader" />,
    description: (
      <div>
        <p className="fn-14">
          <b>
            <FormattedMessage id="quicktour.cleanearthnews" />
          </b>{" "}
          <FormattedMessage id="quicktour.cleanearthnewsdescription" />
        </p>
        <p className="fn-14">
          <Image src="user_guide.svg" alt="logout" height="26px" width="26px" />{" "}
          <FormattedMessage id="quicktour.helpdescription" />
        </p>
        <p className="fn-14">
          <Image
            src="ic_log_out_white.svg"
            alt="logout"
            height="26px"
            width="26px"
          />{" "}
          <FormattedMessage id="quicktour.logoutdescription" />
        </p>
      </div>
    ),
    finishFlag: false,
    position: "bottom",
  },
  {
    selector: "#sideNav",
    heading: <FormattedMessage id="quicktour.sidenavheader" />,
    description: (
      <div>
        <p className="fn-14">
          <b>
            <Image className="h-w-34" src={"ic_dashboard.svg"} alt="" />
          </b>
          <FormattedMessage id="quicktour.subHomedescription" />
        </p>
        <p className="fn-14">
          <b>
            <Image className="h-w-34" src={"ic_schedules.svg"} alt="" />
          </b>{" "}
          <FormattedMessage id="quicktour.submyorderdescription" />
        </p>
        <p className="fn-14">
          <b>
            <Image className="h-w-34" src={"ic_document_library.svg"} alt="" />
          </b>
          <FormattedMessage id="quicktour.subdocumentlibrarydescription" />
        </p>
        <p className="fn-14">
          <b>
            <Image className="h-w-34" src={"ic_support.svg"} alt="" />
          </b>
          <FormattedMessage id="quicktour.subsupportdescription" />
        </p>
        <p className="fn-14">
          <b>
            <Image className="h-w-34" src={"ic_log_out.svg"} alt="" />
          </b>{" "}
          <FormattedMessage id="quicktour.sublogoutdescription" />
        </p>
      </div>
    ),
    finishFlag: false,
    position: "right",
  },
  {
    selector: "#support",
    heading: <FormattedMessage id="quicktour.supportHeader" />,
    description: (
      <p className="fn-14">
        <FormattedMessage id="quicktour.supportexplaination" />
      </p>
    ),
    finishFlag: true,
    position: "right",
  },
]
const Popover = [
  {
    selector: "#popover",
    heading: <FormattedMessage id="quicktour.popovercoursel1" />,
    description: (
      <div>
        <p>
          <FormattedMessage id="quicktour.popovercoursel1p1" />
        </p>
        <p>
          <FormattedMessage id="quicktour.popovercoursel1p2" />
        </p>
        <p>
          <FormattedMessage id="quicktour.popovercoursel1p3" />
        </p>
        <p>
          <FormattedMessage id="quicktour.popovercoursel1p4" />
        </p>
        <p>
          <FormattedMessage id="quicktour.popovercoursel1p5" />
        </p>
      </div>
    ),
    finishFlag: false,
    video: (
      <div key={1}>
        <video className="h-w-100" src={video2} autoPlay loop muted />
      </div>
    ),
    title: "video1",
  },
  {
    selector: "#nini",
    heading: <FormattedMessage id="quicktour.popovercoursel2" />,
    description: (
      <div>
        <p>
          <FormattedMessage id="quicktour.popovercoursel2p1" />
        </p>
        <p>
          <FormattedMessage id="quicktour.popovercoursel2p2" />
        </p>
        <p>
          <FormattedMessage id="quicktour.popovercoursel2p3" />
        </p>
        <p>
          <FormattedMessage id="quicktour.popovercoursel2p4" />
        </p>
      </div>
    ),
    finishFlag: false,
    video: (
      <div key={2}>
        <video className="h-w-100" src={video1} autoPlay loop muted />
      </div>
    ),
    title: "video2",
  },
]
const Popoverstore = [
  {
    selector: "#popover",
    heading: <FormattedMessage id="quicktour.popovercoursel1" />,
    description: (
      <div>
        <p>
          <FormattedMessage id="quicktour.popovercoursel1p1" />
        </p>
        <p>
          <FormattedMessage id="quicktour.popovercoursel1p2" />
        </p>
        <p>
          <FormattedMessage id="quicktour.popovercoursel1p3" />
        </p>
        <p>
          <FormattedMessage id="quicktour.popovercoursel1p4" />
        </p>
        <p>
          <FormattedMessage id="quicktour.popovercoursel1p5" />
        </p>
      </div>
    ),
    finishFlag: false,
    video: (
      <div key={1}>
        <video className="h-w-100" src={quickTour} autoPlay loop muted />
      </div>
    ),
    title: "video1",
  },
  {
    selector: "#nini",
    heading: <FormattedMessage id="quicktour.popovercoursel2" />,
    description: (
      <div>
        <p>
          <FormattedMessage id="quicktour.popovercoursel2p1" />
        </p>
        <p>
          <FormattedMessage id="quicktour.popovercoursel2p2" />
        </p>
        <p>
          <FormattedMessage id="quicktour.popovercoursel2p3" />
        </p>
        <p>
          <FormattedMessage id="quicktour.popovercoursel2p4" />
        </p>
      </div>
    ),
    finishFlag: false,
    video: (
      <div key={2}>
        <video className="h-w-100" src={video1} autoPlay loop muted />
      </div>
    ),
    title: "video2",
  },
]
export { adminQuickTour, storeQuickTour, Popover, Popoverstore }
