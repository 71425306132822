import React, { useState, Fragment, forwardRef, useEffect } from "react"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import Modal from "shared/Modal"
import { useSelector } from "react-redux"

const GeneratorDetails = forwardRef((props, ref) => {
  let { generatorDetails } = props
  const [showNAICSModal, setShowNAICSModal] = useState(false)
  const naicsDetails = useSelector(
    (state) => state.generatorDetails.naicsDetails
  )

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: "smooth" })
  }, [])

  return (
    <Fragment>
      {/* Generator Info NAICS popup*/}
      <div className="naicsmodal">
        <Modal
          show={showNAICSModal}
          style={{ padding: 20 }}
          wrapperclass="width60"
        >
          <div
            style={{ padding: 20, fontSize: 12 }}
            onMouseLeave={() => setShowNAICSModal(false)}
          >
            <div
              className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus text-left pb-4"
              style={{ flexDirection: "column" }}
            >
              <div className="m-0 labelgenerator myprofile pb-2">
                <FormattedMessage id="generator.naicscode" />
              </div>
              <div className="m-0 labelgenerator myprofile">
                {naicsDetails?.[0]?.naicsCodeKey}
              </div>
            </div>
            <div
              className="p-3 disabledBg"
              style={{ textAlign: "left", borderRadius: 15 }}
            >
              <div className="labelgenerator">
                <FormattedMessage id="generator.naicscodedetail" />
              </div>
              <div>
                <div className="row mt-2">
                  <div className="col-sm-4 mt-2">
                    <div style={{ fontWeight: "bold" }}>
                      <FormattedMessage id="generator.naicscodes" />
                    </div>
                    <div>{naicsDetails?.[0]?.naicsCodeKey}</div>
                  </div>
                  <div className="col-sm-4 mt-2">
                    <div style={{ fontWeight: "bold" }}>
                      <FormattedMessage id="generator.naicsname" />
                    </div>
                    <div>{naicsDetails?.[0]?.naicsName}</div>
                  </div>
                  <div className="col-sm-4 mt-2">
                    <div style={{ fontWeight: "bold" }}>
                      <FormattedMessage id="generator.naicsrecordid" />
                    </div>
                    <div style={{ wordBreak: "break-all" }}>
                      {naicsDetails?.[0]?.sfdcId}
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-4 mt-2">
                    <div style={{ fontWeight: "bold" }}>
                      <FormattedMessage id="generator.healthcare" />
                    </div>
                    <div>{naicsDetails?.[0]?.healthcare}</div>
                  </div>
                  <div className="col-sm-4 mt-2">
                    <div style={{ fontWeight: "bold" }}>
                      <FormattedMessage id="generator.createdby" />
                    </div>
                    <div>
                      {naicsDetails?.[0]?.createUser},{" "}
                      {naicsDetails?.[0]?.createdDatetime}
                    </div>
                  </div>
                  <div className="col-sm-4 mt-2">
                    <div style={{ fontWeight: "bold" }}>
                      <FormattedMessage id="generator.owner" />
                    </div>
                    <div>{naicsDetails?.[0]?.createUser}</div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-4 mt-2">
                    <div style={{ fontWeight: "bold" }}>
                      <FormattedMessage id="generator.active" />
                    </div>
                    <div>{naicsDetails?.[0]?.active ? "true" : "false"}</div>
                  </div>
                  <div className="col-sm-4 mt-2">
                    <div style={{ fontWeight: "bold" }}>
                      <FormattedMessage id="generator.benzene" />
                    </div>
                    <div>{naicsDetails?.[0]?.benzene}</div>
                  </div>
                  <div className="col-sm-4 mt-2">
                    <div style={{ fontWeight: "bold" }}>
                      <FormattedMessage id="generator.neshap" />
                    </div>
                    <div>{naicsDetails?.[0]?.neshap}</div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-4 mt-2">
                    <div style={{ fontWeight: "bold" }}>
                      <FormattedMessage id="generator.lastmodifiedby" />
                    </div>
                    <div>
                      {naicsDetails?.[0]?.updateUser},{" "}
                      {naicsDetails?.[0]?.updateDatetime}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 px-0 text-left py-2 position-relative">
        <div className="col-sm-4 px-0">
          <div
            className="mb-2"
            style={{
              color: "#337AB7",
              fontWeight: "bold",
            }}
            ref={ref}
          >
            <FormattedMessage id="generator.generalInfo" />
          </div>
          <div
            className="form-input-block pr-4"
            style={{ borderRight: "1px solid #d2d2d2" }}
          >
            <div
              className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left input-box disabledBg"
              style={{
                justifyContent: "flex-start",
                minHeight: 280,
                padding: 16,
                alignItems: "flex-start",
                overflowY: "scroll",
                wordWrap: "break-word",
              }}
            >
              <div
                className="col-sm-12"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "space-between",
                  padding: 0,
                }}
              >
                <div>
                  <FormattedMessage id="generator.name" />:{" "}
                  <span>{generatorDetails?.generatorName}</span>
                </div>
                {/* <div>
                  <FormattedMessage id="generator.exemstatus" />:{" "}
                  <span>{generatorDetails?.generatorExemptionStatus}</span>
                </div> */}
                <div>
                  <FormattedMessage id="generator.eriprovider" />:{" "}
                  <span>
                    {generatorDetails?.emergencyResponseInfo
                      ? generatorDetails?.emergencyResponseInfo
                      : "Clean Earth"}
                  </span>
                </div>
                <div>
                  <FormattedMessage id="generator.eriphoneno" />:{" "}
                  <span>{generatorDetails?.emergencyResponsePhoneNo}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.webnumber" />:{" "}
                  <span>{generatorDetails?.generatorId}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.naics" />:{" "}
                  <span
                    style={{ textDecoration: "underline" }}
                    onMouseEnter={() => setShowNAICSModal(true)}
                  >
                    {generatorDetails?.naics}
                  </span>
                </div>
                <div>
                  <FormattedMessage id="generator.geneidstatus" />:{" "}
                  <span>{generatorDetails?.generatorIdStatus}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.epaid" />:{" "}
                  <span>{generatorDetails?.epaId}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.stateid" />:{" "}
                  <span>{generatorDetails?.stateId}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.subpartp" />:{" "}
                  <span>{generatorDetails?.subpartP}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.tsdf" />:{" "}
                  <span>
                    {generatorDetails?.isTsdfApprovalFile === true
                      ? "On File"
                      : ""}
                  </span>
                </div>
                <div>
                  <FormattedMessage id="generator.disprest" />:{" "}
                  <span>
                    {generatorDetails?.disposalRestrictions
                      ? generatorDetails?.disposalRestrictions
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4 px-0">
          <div
            className="mb-2 px-4"
            style={{
              color: "#337AB7",
              fontWeight: "bold",
            }}
          >
            <FormattedMessage id="generator.shippingAddress" />
          </div>
          <div
            className="form-input-block px-4"
            style={{ borderRight: "1px solid #d2d2d2" }}
          >
            <div
              className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left input-box disabledBg"
              style={{
                justifyContent: "flex-start",
                minHeight: 280,
                padding: 16,
                alignItems: "flex-start",
                overflowY: "scroll",
                wordWrap: "break-word",
              }}
            >
              <div
                className="col-sm-12"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "space-between",
                  padding: 0,
                }}
              >
                <div>
                  <FormattedMessage id="generator.shipcountry" />:{" "}
                  <span>{generatorDetails?.shippingCountry}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.shipstreet" />:{" "}
                  <span>{generatorDetails?.manifestAddressLine1}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.shipcity" />:{" "}
                  <span>{generatorDetails?.shippingCity}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.shipstate" />:{" "}
                  <span>{generatorDetails?.shippingState}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.shipzip" />:{" "}
                  <span>{generatorDetails?.shippingZip}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.shipcounty" />:{" "}
                  <span>{generatorDetails?.shippingCounty}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.shipphone" />:{" "}
                  <span>{generatorDetails?.shippingPhoneNo}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.shipfax" />:{" "}
                  <span>{generatorDetails?.shippingFax}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.shipemail" />:{" "}
                  <span>{generatorDetails?.shippingEmail}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.shipattn" />:{" "}
                  <span>{generatorDetails?.shippingAttention}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4 px-0">
          <div
            className="mb-2  pl-4"
            style={{
              color: "#337AB7",
              fontWeight: "bold",
            }}
          >
            <FormattedMessage id="generator.manifestAddress" />
          </div>
          <div className="form-input-block pl-4">
            <div
              className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left input-box disabledBg"
              style={{
                justifyContent: "flex-start",
                minHeight: 280,
                padding: 16,
                alignItems: "flex-start",
                overflowY: "scroll",
                wordWrap: "break-word",
              }}
            >
              <div
                className="col-sm-12"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "space-between",
                  padding: 0,
                }}
              >
                <div>
                  <FormattedMessage id="generator.manifestname" />:{" "}
                  <span>{generatorDetails?.manifestName}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.manifeststreet" />: :{" "}
                  <span>{generatorDetails?.manifestAddressLine1}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.manifestcity" />:{" "}
                  <span>{generatorDetails?.manifestCity}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.manifeststate" />:{" "}
                  <span>{generatorDetails?.manifestState}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.manifestzip" />:{" "}
                  <span>{generatorDetails?.manifestZip}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.manifestcountry" />:{" "}
                  <span>{generatorDetails?.manifestCountry}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.manifestphone" />:{" "}
                  <span>{generatorDetails?.manifestPhoneNo}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.manifestfax" />:{" "}
                  <span>{generatorDetails?.manifestFax}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.manifestemail" />:{" "}
                  <span>{generatorDetails?.manifestEmail}</span>
                </div>
                <div>
                  <FormattedMessage id="generator.manifestattn" />:{" "}
                  <span>{generatorDetails?.manifestAttention}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
})
export default GeneratorDetails
