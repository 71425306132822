import React from "react"
import "./style.scss"
import enhancer from "./enhancer"
import { FormattedMessage } from "react-intl"
import HeaderBar from "shared/HeaderBar"
import Loading from "shared/Loading"

function LegalAgreement(props) {
  let { onAcceptClick, onDeclineClick } = props

  return (
    <>
      <div className="clean-earth-container legalaggrement">
        <HeaderBar />
        <Loading id="terms-conditions">
          <div className="row">
            <div className="col-10 offset-1 text-left main-text">
              <div>
                <b>
                  CLEAN EARTH CONNECT+&nbsp;PORTAL TERMS AND CONDITIONS OF USE
                </b>
                <p>
                  Please read these Terms and Conditions of Use (the
                  &quot;Terms&quot;) carefully to ensure that you understand
                  each provision. These Terms and Conditions contain a class
                  action/jury trial waiver provision in which you and Clean
                  Earth,&nbsp;Inc.LLC&nbsp;are each waiving the right to a trial
                  by jury and to participate in a class action, collective
                  action, private attorney general action, or other
                  representative proceeding of any kind.
                </p>
                <p>1. Acceptance of Terms</p>
                <p>
                  Clean Earth,&nbsp;Inc.LLC&nbsp;provides the Site and also
                  makes available, through this Site and otherwise, certain
                  information and services (collectively, &quot;Features&quot;),
                  subject to your compliance with the Terms. The Terms govern
                  your use of Clean Earth Connect and any software available
                  through the portal that are owned, operated, or provided by
                  Clean Earth (&quot;Clean Earth,&quot; &quot;Licensor,&quot;
                  &quot;we,&quot; &quot;us&quot; or &quot;our&quot;), and which
                  contain a link to the Terms (collectively, and together with
                  all services and products available through such websites, the
                  &quot;Site&quot;).
                </p>
                <p>
                  We reserve the right, in our sole discretion, to change or
                  modify, add or remove any portion of the Site or the Terms, at
                  any time with or without notice to you. You acknowledge and
                  agree that it is your responsibility to periodically review
                  this Site and these Terms. Your continued use of this Site
                  after any changes to the Terms will constitute acknowledgement
                  and acceptance of those changes, whether or not you actually
                  reviewed the modified Terms.
                </p>
                <p>
                  Please consult the&nbsp;
                  <a
                    href="https://protect-us.mimecast.com/s/JlT8C1wDYQI32XNfYeZO9?domain=nam12.safelinks.protection.outlook.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Clean Earth Connect</span>
                    <span>+</span>
                    <span>&nbsp;</span>
                    <span>Privacy Policy</span>
                  </a>
                  &nbsp;for a description of our privacy practices and policies
                  on the Site.
                </p>
                <p>
                  As used in these Terms, references to our
                  &quot;Affiliates&quot; include our owners, licensees, assigns,
                  subsidiaries, affiliated companies, officers, directors, and
                  includes (without limitation) all parties involved in
                  creating, producing, and/or delivering this Site and/or
                  contents and features available on this Site.
                </p>
                <p>
                  BY ACCESSING AND USING THIS SITE, YOU AGREE TO BE BOUND BY
                  THESE TERMS. IF YOU DO NOT WISH TO BE BOUND BY THE THESE
                  TERMS, PLEASE EXIT THE SITE. YOUR SOLE REMEDY FOR
                  DISSATISFACTION WITH THIS SITE, ITS FEATURES OR OFFERINGS, OR
                  THESE TERMS IS TO CEASE USING THE SITE AND/OR THOSE PARTICULAR
                  FEATURES OR OFFERINGS. THESE TERMS ARE EFFECTIVE ONCE YOU
                  CLICK THE &quot;I AGREE&quot; BUTTON BELOW OR OTHERWISE
                  INDICATE YOUR AGREEMENT TO THESE TERMS IN CONNECTION WITH YOUR
                  REGISTRATION OR OTHERWISE ACCESS A FEATURE.
                </p>
                <p>2. Site Features</p>
                <p>
                  Clean Earth provides a centralized location for its customers
                  and related third parties to access and track information
                  about job status, build profile reports, download weight
                  tickets and manifests, and access and download invoice
                  information.
                </p>
                <p>3. Site Conduct</p>
                <p>
                  Your use of the Site is subject to all applicable laws and
                  regulations, and while using this Site you agree not to:
                </p>
                <ol start="1" type="i">
                  <li>
                    use the Site in any way that violates any applicable
                    federal, state, local or international law or regulation
                    (including, without limitation, any laws regarding the
                    export of data or software to and from the United States or
                    other countries);
                  </li>
                  <li>
                    threaten, abuse, harass, defame, deceive, defraud, or invade
                    another&rsquo;s privacy;
                  </li>
                  <li>
                    discriminate against anyone on the basis of religion,
                    gender, sexual orientation, race, ethnicity, age, or
                    disability;
                  </li>
                  <li>
                    infringe on any patent, trademark, trade secret, copyright,
                    right of publicity, or other proprietary right of any party;
                  </li>
                  <li>
                    use any form of malicious code,&nbsp;viruses, Trojan horses,
                    works, logic bombs,&nbsp;files,&nbsp;robot,
                    spider,&nbsp;or&nbsp;other automatic device, process, means
                    of access or&nbsp;programs that are designed or intended to
                    disrupt, damage, or limit the functionality of any software,
                    hardware, or telecommunications equipment or otherwise
                    causes damage, or allows you to obtain unauthorized access
                    to any data or other information of any third party;
                  </li>
                  <li>
                    breach the security of, compromise or otherwise allows
                    access to secured, protected or inaccessible areas of this
                    Site;
                  </li>
                  <li>
                    use the Site to transmit, or procure the sending of, any
                    advertising or promotional material, including any
                    &quot;junk mail&quot;, &quot;chain letter&quot;,
                    &quot;spam&quot; or nay similar solicitation;
                  </li>
                  <li>
                    impersonate any person, or misrepresent your identity or
                    affiliation with any person or organization;
                  </li>
                  <li>
                    use this Site for any purpose, including commercial purpose
                    of any kind, for which it was not intended; or
                  </li>
                  <li>
                    provide to us any Submissions (as defined below) that you do
                    not have the right to provide to us.
                  </li>
                </ol>
                <p>Third-Party Sites and Information.</p>
                <p>
                  This Site and the Features may redirect or link to other
                  websites on the Internet, or may otherwise include references
                  to information or services made available by unaffiliated
                  third parties. While we make every effort to work with
                  trusted, reputable providers, from time to time such sites may
                  contain information, material or policies that some may find
                  inappropriate or offensive. You understand that we are not
                  responsible for the accuracy, completeness, decency or
                  legality of content hosted by third party websites, nor are we
                  responsible for errors or omissions in any references made on
                  those websites. The inclusion of such a link or reference is
                  provided merely as a convenience and does not imply
                  endorsement of, or association with the Site or party by us,
                  or any warranty of any kind, either express or implied.
                </p>
                <p>4. Company Intellectual Property</p>
                <p>
                  Content.&nbsp;For purposes of these Terms, &quot;content&quot;
                  is defined as any information, communications, software,
                  published works, photos, video, graphics, sounds, or other
                  material that can be viewed by users on our Site and is owned
                  or licensed by Clean Earth or its Affiliates.
                </p>
                <p>
                  Ownership of Content.&nbsp;By accepting these Terms, you agree
                  that the Site, the Features and all content presented to you
                  on this Site is protected by any and all intellectual property
                  and/or other proprietary rights available within the United
                  States, and is the sole property of Clean Earth or its
                  Affiliates or their suppliers. Neither the Terms nor your
                  access to the Site or the Features transfers to you or any
                  third party any rights, title or interest in or to such
                  intellectual property rights. We and our suppliers reserve all
                  rights not granted in these Terms. There are no implied
                  licenses granted under these Terms.
                </p>
                <p>
                  All custom graphics, icons, logos and service names are
                  registered trademarks, or service marks of Company or its
                  Affiliates, or of the party that created and/or licensed the
                  content to Company, and are proprietary to us. Nothing in
                  these Terms of Use grants you any right to use any trademark,
                  service mark, logo, and/or the name of Company or its
                  Affiliates.
                </p>
                <p>
                  Limitations on Use of Content.&nbsp;You may not copy,
                  reproduce, modify, republish, upload, post, transmit, or
                  distribute any content from this Site, or disclose to any
                  third party any content, information or materials we make
                  available to you pursuant to your access to the Site (and that
                  we do not otherwise make generally available to the public),
                  in any form or by any means whatsoever without prior written
                  permission from us. Any unauthorized use of this Site or its
                  content violates our intellectual property interests.
                </p>
                <p>
                  Software.&nbsp;The Company and its licensors retain full and
                  complete title to all software used in the operation of this
                  Site and the Features and to all intellectual property rights
                  therein. You may not redistribute, sell, de-compile, reverse
                  engineer, disassemble, or otherwise reduce any such software
                  to a human-readable form.
                </p>
                <p>5. Submissions.</p>
                <p>
                  You hereby grant to Clean Earth and its Affiliates a
                  royalty-free, fully-paid, perpetual, irrevocable, worldwide,
                  non-exclusive and fully-sublicensable right (including any
                  moral rights) and license to use, sell, reproduce, modify,
                  adapt, publish, translate, create derivative works from,
                  distribute, perform, and display, in whole or in part, all
                  content, remarks, financial information and data, ideas,
                  graphics, and other information and materials furnished or
                  communicated by you to us through this Site or in connection
                  with your use of the Features (hereinafter,
                  &quot;Submissions&quot;), and to incorporate any Submissions
                  in other works in any form, media, or technology now known or
                  later developed. You agree that Clean Earth and its Affiliates
                  may use any Submissions in its business without incurring any
                  liability for royalties or any other consideration of any
                  kind, and will not incur any liability as a result of any
                  similarities that may appear in future Company operations or
                  business. When you provide any Submissions to us, you
                  represent that you have the authority to grant the foregoing
                  license to us. You also acknowledge and agree that other users
                  of the Site and its Features and other third parties to whom,
                  we may, in our sole discretion, furnish your Submissions, may
                  search for, see, access, use, download, reproduce, modify,
                  distribute and display your Submissions. You warrant
                  that&nbsp;you own or control all rights in and to the
                  Submissions and have the right to grant the license granted
                  above to Clean Earth and its Affiliates, and that&nbsp;the
                  holder of any worldwide intellectual property right, including
                  moral rights, in your Submissions has completely and
                  effectively waived all such rights and validly and irrevocably
                  granted to you the right to grant the license stated
                  above.&nbsp;You understand and acknowledge that you are
                  responsible for any Submissions you submit or contribute, and
                  you, not Clean Earth, have full responsibility for such
                  content, including its legality, reliability, accuracy, and
                  appropriateness. We are not responsible or liable to any third
                  party for the content or accuracy of any Submissions posted by
                  you or any other user of the Site.
                </p>
                <p>6. Privacy &amp; Security</p>
                <p>
                  Login Required.&nbsp;In order to access the Site, you will be
                  asked to register for a Clean Earth Connect+&nbsp;account by
                  submitting relevant information, including your email address.
                  You will be asked to log in with your email and a password
                  that Clean Earth assigns to you&nbsp;or you can opt for single
                  sign-in credentials (if applicable). By registering for a
                  Clean Earth Connect+&nbsp;account, you agree that all
                  information provided by you for account registration or in
                  your Submissions are true and accurate and that you will
                  maintain and update this information and your Submissions as
                  required in order to keep it current, complete and accurate.
                </p>
                <p>
                  Passwords &amp; Security.&nbsp;If you register for an account
                  with us, you agree that you are responsible for maintaining
                  the security and confidentiality of your password&nbsp;and
                  credentials, and that you are fully responsible for all
                  activities or charges that are incurred under your account.
                  Therefore, you must take reasonable steps to ensure that
                  others do not gain access to your password,&nbsp;or single
                  sign-in credentials, and account. Clean Earth employees will
                  never ask you for your password&nbsp;and credentials. You
                  agree to immediately notify us of any unauthorized use or
                  suspected unauthorized use of your password,
                  credentials&nbsp;or account or any other breach of security.
                </p>
                <p>
                  While we take commercially reasonable steps to safeguard and
                  to prevent unauthorized access to personal information,
                  credentials&nbsp;and financial information included in your
                  Submissions, we are not responsible for the acts of those who
                  gain unauthorized access, and we make no warranty, express,
                  implied, or otherwise, that we will prevent unauthorized
                  access to such information. We may share your Submissions,
                  including personal information and financial information, as
                  described in our Privacy Policy.
                </p>
                <p>7. Disclaimer</p>
                <p>
                  ALL CONTENT AND OFFERINGS ARE PROVIDED ON AN &quot;AS IS&quot;
                  AND &quot;AS AVAILABLE&quot; BASIS WITHOUT WARRANTY OF ANY
                  KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
                  TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
                  PARTICULAR PURPOSE, OR THE WARRANTY OF NON-INFRINGEMENT.
                  WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT (A)
                  THE CONTENT, FEATURES OR SITE WILL MEET YOUR REQUIREMENTS, (B)
                  THE CONTENT, OFFERINGS OR SITE WILL BE UNINTERRUPTED, TIMELY,
                  SECURE, OR ERROR-FREE, (C) THE RESULTS THAT MAY BE OBTAINED
                  FROM THE USE OF THE CONTENT OR OFFERINGS OFFERED WILL BE
                  EFFECTIVE, ACCURATE OR RELIABLE, OR (D) THE QUALITY OF ANY
                  CONTENT OR FEATURES ACCESSED OR OBTAINED BY YOU FROM THE SITE
                  OR FROM US OR OUR AFFILIATES WILL MEET YOUR EXPECTATIONS OR BE
                  FREE FROM MISTAKES, ERRORS OR DEFECTS.
                </p>
                <p>
                  YOU UNDERSTAND THAT WE CANNOT AND DO NOT GUARANTEE OR WARRANT
                  THAT FILES AVAILABLE FOR DOWNLOADING FROM THE INTERNET OR THE
                  SITE WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU
                  ARE RESPONSIBLE FOR IMPLEMENTING SUFFICIENT PROCEDURES AND
                  CHECKPOINTS TO SATISFY YOUR PARTICULAR REQUIREMENTS FOR
                  ANTI-VIRUS PROTECTION AND ACCURACY OF DATA INPUT AND OUTPUT,
                  AND FOR MAINTAINING A MEANS EXTERNAL TO THE SITE FOR ANY
                  RECONSTRUCTION OF ANY LOST DATA.&nbsp;TO THE FULLEST EXTENT
                  PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE
                  CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR
                  OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
                  COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER
                  PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE OR ANY
                  SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR TO YOUR
                  DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE
                  LINKED TO IT.
                </p>
                <p>
                  THIS SITE AND ITS CONTENT COULD INCLUDE TECHNICAL OR OTHER
                  MISTAKES, INACCURACIES OR TYPOGRAPHICAL ERRORS. WE MAY MAKE
                  CHANGES TO THE CONTENT AND FEATURES AT THIS SITE, INCLUDING
                  ANY JOB STATUS OR FINANCIAL INFORMATION PROVIDED HEREIN, AT
                  ANY TIME WITHOUT NOTICE. THE CONTENT, PRODUCTS AND OFFERINGS
                  AVAILABLE AT THIS SITE MAY BE OUT OF DATE, AND WHILE WE UPDATE
                  THE SITE PERIODICALLY, WE MAKE NO GUARANTEE AS TO THE ACCURACY
                  OR TIMELINESS OF ANY UPDATES OF SUCH CONTENT OR FEATURES.
                </p>
                <p>
                  THE USE OF THE FEATURES OR THE DOWNLOADING OR OTHER
                  ACQUISITION OF ANY CONTENT THROUGH THIS SITE IS DONE AT YOUR
                  OWN DISCRETION AND RISK AND WITH YOUR AGREEMENT THAT YOU WILL
                  BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM
                  OR LOSS OF DATA THAT RESULTS FROM SUCH ACTIVITIES.
                </p>
                <p>
                  WE MAKE NO WARRANTY REGARDING ANY TRANSACTIONS EXECUTED
                  THROUGH A THIRD PARTY, OR IN CONNECTION WITH THIS SITE, OR
                  BETWEEN YOU AND ANY THIRD PARTY, AND YOU UNDERSTAND AND AGREE
                  THAT SUCH TRANSACTIONS ARE CONDUCTED ENTIRELY AT YOUR OWN
                  RISK. ANY WARRANTY THAT IS PROVIDED IN CONNECTION WITH ANY
                  CONTENT AVAILABLE ON OR THROUGH THIS SITE FROM A THIRD PARTY
                  IS PROVIDED SOLELY BY SUCH THIRD PARTY, AND NOT BY US OR ANY
                  OTHER OF OUR AFFILIATES.
                </p>
                <p>
                  WE RESERVE THE SOLE RIGHT TO EITHER MODIFY OR DISCONTINUE THE
                  SITE AND ANY FEATURE, AT ANY TIME WITH OR WITHOUT NOTICE TO
                  YOU. WE SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY SHOULD
                  WE EXERCISE SUCH RIGHT. MODIFICATIONS MAY INCLUDE, BUT ARE NOT
                  LIMITED TO, THE ADDITION OF FREE OR FEE-BASED SERVICES, OR
                  CHANGES TO LIMITATIONS ON ALLOWABLE CONTENT, FILE SIZES OR
                  FILE TYPES. ANY NEW FEATURES THAT AUGMENT OR ENHANCE THE
                  THEN-CURRENT CONTENT OR FEATURES ON THIS SITE SHALL ALSO BE
                  SUBJECT TO THESE TERMS OF USE.
                </p>
                <p>
                  SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
                  CERTAIN WARRANTIES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT
                  APPLY TO YOU. PLEASE CONSULT THE LAWS IN YOUR JURISDICTION.
                </p>
                <p>8. Limitation of Liability &amp; Indemnification</p>
                <p>
                  TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL CLEAN
                  EARTH OR ITS AFFILIATES BE LIABLE FOR DAMAGES OF ANY KIND,
                  UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH
                  YOUR USE, OR INABILITY TO USE, THE SITE, ANY WEBSITES LINKED
                  TO IT, ANY CONTENT ON THE SITE OR SUCH OTHER WEBSITES,
                  INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                  CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED
                  TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
                  LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
                  ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
                  DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
                  BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.IN NO
                  EVENT SHALL WE OR OUR AFFILIATES BE LIABLE TO YOU OR ANY THIRD
                  PARTY FOR ANY SPECIAL, PUNITIVE, INCIDENTAL, INDIRECT OR
                  CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER,
                  INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOSS OF
                  USE, DATA OR PROFIT LOSS, WHETHER OR NOT WE HAVE BEEN ADVISED
                  OF THE POSSIBILITY OF SUCH DAMAGES, AND ON ANY THEORY OF
                  LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE USE OF
                  THIS SITE OR OF ANY WEBSITE REFERENCED OR LINKED TO FROM THIS
                  SITE.
                </p>
                <p>
                  FURTHER, WE SHALL NOT BE LIABLE IN ANY WAY FOR (A) THIRD PARTY
                  PROMISES REGARDING OUR CONTENT OR FEATURES OR FOR ASSISTANCE
                  IN CONDUCTING TRANSACTIONS, INCLUDING COMMERCIAL TRANSACTIONS,
                  WITH THE THIRD PARTY THROUGH THIS SITE; OR (B) THE JOB STATUS
                  OR FINANCIAL INFORMATION THAT WE PROVIDE TO YOU OR OTHER THIRD
                  PARTIES INVITED BY YOU.
                </p>
                <p>
                  SOME JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF
                  LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE
                  ABOVE LIMITATIONS MAY NOT APPLY TO YOU. PLEASE CONSULT THE
                  LAWS IN YOUR JURISDICTION.
                </p>
                <p>
                  You agree to defend, indemnify, and hold us and our Affiliates
                  harmless from all losses, liabilities, claims, suits, and
                  expenses, including attorneys fees and costs, that may arise
                  from (a) your use or misuse of this Site or its Features or
                  from your Submissions, (b) your violation of these Terms or
                  (c) your violation of applicable laws or regulations. We
                  reserve the right, at our own expense, to assume the exclusive
                  defense and control of any claim or matter otherwise subject
                  to indemnification by you, in which event you will cooperate
                  with us in defending such claim.
                </p>
                <p>9. Termination of Use</p>
                <p>
                  Grounds for Termination.&nbsp;You agree that we may, at our
                  sole discretion, terminate or suspend your access to all or
                  part of the Site with or without notice and for any reason,
                  including, without limitation, breach of these Terms. Any
                  suspected fraudulent, abusive or illegal activity may be
                  grounds for barring your access to this Site, and reporting
                  you to the proper authorities, if necessary.
                </p>
                <p>
                  No Right to Submissions Upon Termination.&nbsp;Upon
                  termination and regardless of the reason(s) motivating such
                  termination, your right to use the Submissions available on
                  this Site will immediately cease, and we may, at our option at
                  any time thereafter, remove from the Site any Submission you
                  have furnished to us. We shall not be liable to you or any
                  third party for any claims for damages arising out of any
                  termination or suspension or any other actions taken by us in
                  connection therewith. Sections 1 and 3
                  through&nbsp;110&nbsp;of these Terms shall survive any
                  termination.
                </p>
                <p>10.&nbsp;Terms of Sale</p>
                <p>
                  All purchases through our site or other transactions for the
                  sale of goods or services formed through the Site, or
                  resulting from visits made by you to the Site, are governed by
                  our&nbsp;Terms of Sale, which are hereby incorporated into
                  these Terms.
                </p>
                <p>11.&nbsp;Miscellaneous Provisions</p>
                <p>
                  Automated Devices.&nbsp;You may not use any automatic device,
                  program or technology,(other than those used for single-sign
                  ins)&nbsp;or any equivalent manual process, to access,
                  acquire, copy, probe, test or monitor any portion of the Site
                  or its content, or in any way reproduce or circumvent the
                  navigational structure or presentation of the Site or its
                  content, to obtain or attempt to obtain any materials,
                  documents or information through any means not purposely made
                  available through the Site. Without limiting the foregoing,
                  you shall not use any automated software, devices, or other
                  processes (including but not limited to spiders, robots,
                  scrapers, crawlers, avatars, data mining tools, or the like)
                  or equivalent manual process to &ldquo;scrape&rdquo; or
                  download data from any web pages contained in the Site. You
                  agree that you will not take any action that imposes an
                  unreasonable or disproportionate load on the infrastructure of
                  the Site.
                </p>
                <p>
                  No Use By Children.&nbsp;You must be at least 18 years old to
                  use the Site.
                </p>
                <p>
                  International Use.&nbsp;Although this Site may be accessible
                  worldwide, we make no representation that materials on this
                  Site are appropriate or available for use in locations outside
                  the United States. Those who choose to access this Site from
                  other locations do so on their own initiative and at their own
                  risk. If you choose to access this Site from outside the
                  United States, you are responsible for compliance with local
                  laws in your jurisdiction. Any offer for any service and/or
                  information made in connection with this Site is void where
                  prohibited.
                </p>
                <p>
                  Governing Law.&nbsp;The statutes and laws of the Commonwealth
                  of Pennsylvania shall be controlling, without regard to the
                  conflicts of laws principles thereof. You agree and hereby
                  submit to the exclusive personal jurisdiction and venue of the
                  courts of the Commonwealth of Pennsylvania, and federal courts
                  located within the Commonwealth of
                  Pennsylvania,&nbsp;including the United States District Court
                  of Western Pennsylvania andspecifically&nbsp;the United States
                  District Court of Eastern Pennsylvania, in connection with any
                  actions, suits or proceedings arising out of or relating to
                  the Terms. THE PARTIES HEREBY KNOWINGLY, VOLUNTARILY, AND
                  INTENTIONALLY WAIVE ANY RIGHTS EITHER OF THEM MAY HAVE TO
                  CONTEST JURISDICTION OR VENUE. THE PARTIES HEREBY KNOWINGLY,
                  VOLUNTARILY, AND INTENTIONALLY WAIVE ANY RIGHTS EITHER OF THEM
                  MAY HAVE TO A TRIAL BY JURY IN RESPECT OF ANY LITIGATION BASED
                  ON, ARISING OUT OF, OR IN CONNECTION WITH THESE TERMS OR ANY
                  OFFERING. ALL CLAIMS MUST BE BROUGHT SOLELY IN A PARTY&rsquo;S
                  INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
                  ANY PURPORTED CLASS ACTION, COLLECTIVE ACTION, PRIVATE
                  ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE PROCEEDING.
                  Access to or use of this Site shall not be construed as the
                  purposeful availment by Clean Earth of the privilege or
                  benefits of doing business in any state or legal jurisdiction
                  other than the Commonwealth of Pennsylvania.
                </p>
                <p>
                  Notices.&nbsp;All notices to a party shall be in writing and
                  made personally or by overnight delivery service. Notices to
                  us must be sent to the attention of &ldquo;Clean Earth
                  Connect+&nbsp;Administrator&rdquo; at to our address
                  at&nbsp;344 South Warminster Rd, Hatboro933 First Avenue,
                  Suite 200, King Of Prussia, PA 190406. You agree to allow
                  Clean Earth to submit notices to you either through the email
                  address provided, or to the address we have on record. Any
                  notices or communication under these Terms will be deemed
                  delivered to the party receiving such communication (1) on the
                  delivery date if delivered personally or via email to the
                  party; (2) two business days after deposit with a commercial
                  overnight carrier, with written verification of receipt.
                </p>
                <p>
                  No Resale Right.&nbsp;You agree not to sell, resell,
                  reproduce, duplicate, distribute, prepare derivative works of,
                  copy or use for any commercial purposes any portion of this
                  Site or the content thereof, or use of or access to this Site,
                  Features or Content provided to you.
                </p>
                <p>
                  Force Majeure.&nbsp;In addition to any excuse provided by
                  applicable law, we shall be excused from liability for
                  non-delivery or delay in delivery of Features or Content
                  arising from any event beyond our reasonable control, whether
                  or not foreseeable by either party, including but not limited
                  to: labor disturbance, war, fire, accident, adverse weather,
                  inability to secure transportation, governmental act or
                  regulation, and other causes or events beyond our reasonable
                  control, whether or not similar to those which are enumerated
                  above.
                </p>
                <p>
                  Savings Clause.&nbsp;If any part of these Terms is held
                  invalid or unenforceable, that portion shall be construed in a
                  manner consistent with applicable law to reflect, as nearly as
                  possible, the original intentions of the parties, and the
                  remaining portions shall remain in full force and effect.
                </p>
                <p>
                  No Waiver.&nbsp;Any failure by us to enforce or exercise any
                  provision of these Terms or related rights shall not
                  constitute a waiver of that right or provision.
                </p>
                <p>
                  Entire Agreement.&nbsp;These terms and conditions constitute
                  the entire agreement and understanding between the parties
                  concerning the subject matter hereof and supersedes all prior
                  agreements and understandings of the parties with respect
                  thereto. No modification to these Terms by any party other
                  than Company shall be valid or enforceable against Company
                  unless expressly agreed to by Company in writing signed by a
                  duly authorized officer of Company. To the extent that
                  anything in or associated with this Site is in conflict or
                  inconsistent with these Terms, these Terms shall take
                  precedence.
                </p>
              </div>
              <hr />
              <div>
                <p>
                  <b>Clean Earth Connect + Policy</b>
                </p>
                <p>
                  Thank you for visiting Clean Earth Connect+, Clean
                  Earth&rsquo;s customer portal (&quot;Portal&quot;).
                </p>
                <p>
                  This Privacy Policy applies to how we collect, use and share
                  information, including personal information, about you as you
                  use Clean Earth Connect+. This policy does not apply to
                  information collected by us outside of the Portal or through
                  any other means, including any other website or portal
                  operated by us or any third party, or by any third party,
                  including through any application or content that may link to
                  or be accessible from the Portal.
                </p>
                <p>
                  We invite you to read this policy carefully to understand our
                  policies and practices regarding your information and how we
                  will treat it. Please read this policy carefully to understand
                  our policies and practices regarding your information and how
                  we will treat it. If you do not agree with our policies and
                  practices, your choice is not to use our Portal. By accessing
                  or using this Portal or otherwise providing your information
                  to us, you agree to this policy.
                </p>
                <p>
                  <b>Information We Collect</b>
                </p>
                <p>
                  We collect business information, personally identifiable
                  information, and anonymous information from you in a variety
                  of ways. We collect personally identifiable information
                  directly from you when you interact with us directly. We also
                  collect non-identifiable, anonymous information from you as
                  you visit our site, view our online content or use our
                  services.
                </p>
                <ol>
                  <li>
                    <em>Information from you.</em>We collect information
                    directly from you when you choose to create a user or
                    customer account on our Portal, or provide information as
                    you participate in our offers, surveys or questionnaire, or
                    when you contact us or otherwise directly provide
                    information to us. Examples of personally identifiable
                    information we may collect directly from you include:
                  </li>
                </ol>
                <ul>
                  <li>Name</li>
                  <li>Email address</li>
                  <li>Business address</li>
                  <li>Postal address</li>
                  <li>Telephone number</li>
                  <li>Fax number</li>
                  <li>User name and password</li>
                </ul>
                <ol start="2">
                  <li>
                    <em>Information of third parties supplied by you.</em>Our
                    Portal provides a feature that allows you to invite your
                    contract partners to join the Portal. Clean Earth will use
                    the contact information you provided to facilitate the
                    invitation. By inviting your contract partners to join the
                    Portal, you consent to the sharing of your business
                    information and personally identifiable information with
                    your contract partners.
                  </li>
                  <li>
                    <em>Information from your visits to the site.</em>We also
                    collect statistical data that is not personally identifiable
                    to you. We use cookies and other automatic data collection
                    technology to collect non-personally identifiable
                    information. Examples of this kind of information we collect
                    include:
                  </li>
                </ol>
                <ul>
                  <li>
                    Information about your device operating system and browser
                  </li>
                  <li>Your IP address</li>
                  <li>
                    Usage information about your visits to Clean Earth Connect+,
                    including login times, page views, traffic data, location
                    data, logs, and other communication data and the resources
                    that you access and use on the Portal
                  </li>
                </ul>
                <p>
                  The information that we collect helps us to improve our
                  Portal, to understand which parts of our Portal and features
                  are of most interest and to deliver a better and more
                  personalized service, including by enabling us to:
                </p>
                <ul>
                  <li>Recognize you when you return to Clean Earth Connect.</li>
                  <li>
                    Store information about your preferences, including site and
                    alert preferences, allowing us to customize our site
                    according to your individual interests.
                  </li>
                </ul>
                <p>
                  <b>How we use the information that we collect</b>
                </p>
                <p>
                  We use information that we collect about you or that you
                  provide to us, including any business information or personal
                  information, to fulfill the limited purposes for which you
                  provided the information, including:
                </p>
                <ul>
                  <li>
                    To present Clean Earth Connect+ and its content to you.
                  </li>
                  <li>
                    To provide you with information about our services that you
                    request from us.
                  </li>
                  <li>To provide invoice information.</li>
                  <li>To provide customer support.</li>
                  <li>To conduct marketing analysis.</li>
                  <li>To manage customer experience.</li>
                  <li>
                    To market our services to you, including by the use of
                    direct mail and e-mail.
                  </li>
                  <li>
                    To fulfill any other purpose for which you provide it.
                  </li>
                  <li>
                    To carry out our obligations and enforce our rights arising
                    from any contracts entered into between you and us,
                    including for billing and collection.
                  </li>
                  <li>
                    To notify you about changes to our Portal or any services we
                    offer or provide through the Portal.
                  </li>
                  <li>
                    To allow you to participate in interactive features on our
                    Portal.
                  </li>
                  <li>
                    In any other way we may describe when you provide the
                    information.
                  </li>
                  <li>
                    To invite your contract partners to join the Portal. We may
                    use your contact information to identify you as the
                    originator of the invitation.
                  </li>
                  <li>For any other purpose with your consent.</li>
                </ul>
                <p>
                  <b>How we share or disclose your information</b>
                </p>
                <p>
                  We do not disclose, share, or sell the information we collect
                  about you to third parties for their commercial marketing
                  purposes. In some cases, Clean Earth may use third party
                  service providers to collect, use, analyze, and otherwise
                  process information on its behalf. It is our practice to
                  require such service providers to handle information in a
                  manner consistent with Clean Earth&rsquo;s policies.
                </p>
                <p>
                  We may disclose information that does not identify any
                  individual user without restriction.
                </p>
                <p>
                  We may disclose personal information that we collect or that
                  you provide as described in this Privacy Policy:
                </p>
                <ul>
                  <li>To fulfill the purpose for which you provide it.</li>
                  <li>
                    For any other purpose disclosed by us when you provide the
                    information.
                  </li>
                  <li>
                    To contractors, service providers, and other third parties
                    we use to support or develop our business and who are bound
                    by contractual obligations to keep personal information
                    confidential and use it only for the purposes for which we
                    disclose it to them.
                  </li>
                  <li>
                    To a buyer or other successor in the event of a merger,
                    divestiture, restructuring, reorganization, dissolution, or
                    other sale or transfer of some or all of Clean Earth&rsquo;s
                    assets, whether as a going concern or as part of bankruptcy,
                    liquidation, or similar proceeding, in which personal
                    information held by us about our websites&rsquo; users is
                    among the assets transferred.
                  </li>
                  <li>
                    To comply with any court order, law, or legal process,
                    including to respond to any government or regulatory
                    request.
                  </li>
                  <li>
                    To enforce or apply our Terms of Use and/or other
                    agreements, including for billing and collection purposes.
                  </li>
                  <li>
                    If we believe disclosure is necessary or appropriate to
                    protect the rights, property, or safety of Clean Earth, our
                    customers, or others. This includes exchanging information
                    with other companies and organizations for the purposes of
                    fraud protection and credit risk reduction.
                  </li>
                  <li>With your consent.</li>
                </ul>
                <p>
                  <b>Privacy for Children</b>
                </p>
                <p>
                  Our Portal is not intended for children under 18 years of age.
                  No one under age 18 may provide any information to or on our
                  websites. We do not knowingly collect personal information
                  from those under 18. If you are under 18, do not use or
                  provide any information on our websites or on or through any
                  of their features/register on our websites, make any purchases
                  through the websites, use any of the interactive features of
                  our website or provide any information about yourself to us,
                  including your name, address, telephone number, email address,
                  or any screen name or user name you may use. If we learn we
                  have collected or received personal information from someone
                  under 18 without verification of parental consent, we will
                  delete that information. If you believe we might have any
                  information from or about someone under 18, please contact us
                  at CEIUS_CSupport@cleanearthinc.com or this address: 933 First
                  Avenue, Suite 200, King Of Prussia, PA 19406, Attn. Connect+
                  Administrator.
                </p>
                <p>
                  <b>Information Retention</b>
                </p>
                <p>
                  We keep personal information only for so long as deemed
                  reasonably necessary. We will retain your information during
                  the time when your account is active or as needed to provide
                  you with services. We will also retain your information as
                  necessary to comply with applicable legal obligations, to
                  resolve disputes, and to enforce our agreements.
                </p>
                <p>
                  <b>Securing your information</b>
                </p>
                <p>
                  We are committed to keeping your personal information secure.
                  We have implemented technical, administrative, and physical
                  procedures that are designed to protect personal information
                  from loss or misuse. We use Transport Layer Security (TLS) and
                  Secure Socket Layer (SSL) to transmit your personal
                  information securely. We implement access control to limit
                  access to personal information to those who have a business
                  need. All information transmitted to and from the Portal
                  occurs over a secured connection.
                </p>
                <p>
                  No method of transmission or electronic storage can provide
                  absolute certainty of security, and as such we cannot
                  guarantee the absolute security of the information that we
                  collect.
                </p>
                <p>
                  <b>California Residents&rsquo; Privacy Rights</b>
                </p>
                <p>
                  California Civil Code Section &sect; 1798.83 permits users of
                  our Portal that are California residents to request certain
                  information, including the categories of personal information
                  disclosed to third parties for their marketing purposes and
                  the names and addresses of those third parties, regarding our
                  disclosure of personal information to third parties for their
                  direct marketing purposes. To make such a request, please send
                  an email to CEIUS_CSupport@cleanearthinc.com or this address:
                  933 First Avenue, Suite 200, King Of Prussia, PA 19406,
                  Attention: Connect+ Administrator.
                </p>
                <p>
                  <b>Clean Earth Connect+ Privacy Policy Updates</b>
                </p>
                <p>
                  By using this Portal, you consent to the collection and use of
                  your information by Clean Earth, Inc. We may update this
                  Portal Privacy Policy from time to time. The updated Privacy
                  Policy is effective when posted to Clean Earth Connect+. Your
                  continued use of our Portal after we make changes to the
                  Privacy Policy is deemed to be acceptance of the changes in
                  the Policy, so please check the Policy periodically for
                  updates.
                </p>
                <p>
                  <b>Contact Us</b>
                </p>
                <p>
                  If you have any questions regarding our Privacy Policy, or if
                  you have any concerns about your personal information and is
                  accuracy, you may contact us using the information below:
                </p>
                <p>
                  CEIUS_CSupport@cleanearthinc.com
                  <br /> 215-734-1400
                  <br /> 933 First Avenue, Suite 200
                  <br /> King Of Prussia, PA 19406
                  <br /> Attn. Connect+ Administrator
                </p>
              </div>
            </div>

            <div className="col-10 offset-1  legalbuttons text-center">
              <button onClick={onDeclineClick} className="greybutton">
                <FormattedMessage id="agreement.declinebtn" />
              </button>
              <button onClick={onAcceptClick} className="greenbutton">
                <FormattedMessage id="agreement.acceptbtn" />
              </button>
            </div>
          </div>
        </Loading>
      </div>
    </>
  )
}

export default enhancer(LegalAgreement)
