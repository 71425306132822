import { SET_USER, DESTORY_USER, SET_SR_CONID } from "./actionType"

const user = (state = {}, action) => {
  let newState = {}
  switch (action.type) {
    case SET_USER:
      newState = {
        ...state,
        ...action.user,
      }
      return newState
    case DESTORY_USER:
      return newState
    case SET_SR_CONID:
      newState = {
        ...state,
        sRConId: action.sRConId,
      }
      return newState

    default:
      return state
  }
}

export default user
