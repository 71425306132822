import React from "react"
import Image from "shared/HelpImage/index"

function InvoiceReport() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Report Generator: Invoice Report</h3>

      <Image src="Invoice_Report_Fc_table.png" />

      <p className="text-justify pl-3 pt-2">
        The invoice report has multiple columns and informational items. This
        report has customer order number, date required, PO number, generator
        company, generator address, description , order quantity, unit price,
        extended price and price UOM.
      </p>
      <p className="text-justify pl-3 pt-2">
        Once the user has applied the filters, the download button displays and
        then the user can click on the download button and pull all this
        information into an excel spreadsheet.
      </p>
      <Image src="Invoice_Report_Fc.png" />
    </div>
  )
}
export default InvoiceReport
