import React from "react"
import Image from "shared/HelpImage/index"

function TermsAndConditions() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Terms and conditions</h3>
      <p className="text-justify pl-3 pt-3">
        Once the user has logged in, the Portal terms and conditions page will
        come up. This will happen on the first time login. The user must read
        and scroll to the end of the document and accept terms and conditions
        that have been outlined. Then fill out the next screen with their name,
        phone number, and title.
      </p>
      <div className="img-section">
        <Image src="legalAgreement.png" alt="Login Image" />
      </div>

      <p className="text-justify pl-3 pt-4">
        This is still part of the terms and conditions, agreement. The user will
        need to fill in all the fields that are not already pre-populated, and
        then hit the continue button. This screen and the terms and conditions
        page will not show up again. Just the first time using the portal.
      </p>
      <div className="img-section">
        <Image src="updateProfile.png" alt="Login Image" />
      </div>
    </div>
  )
}
export default TermsAndConditions
