import { applyMiddleware, createStore } from "redux"
import { createLogger } from "redux-logger"
import { persistReducer, persistStore } from "redux-persist"
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync"
import storage from "redux-persist/lib/storage"
import reducers from "./reducer"

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "stores",
    "user",
    "config",
    "alerts",
    "profile",
    "changeCustomer",
    "customerList",
    "roles",
  ],
}

const persistedReducer = persistReducer(persistConfig, reducers)
let logger = createLogger({ collapsed: true })

const config = {
  // TOGGLE_TODO will not be triggered in other tabs
  // blacklist: ["SET_LOADING_STATUS", "SET_FILTERS", "REVOKE_USER"],
  whitelist: [
    "SET_USER",
    "SET_PROFILE",
    "UPDATE_IS_READ_ALERT",
    "SET_ALERTS",
    "SET_SR_CONID",
    "SET_DOWNLOAD_ALERTS",
    "UPDATE_IS_READ_DOWNLOAD_ALERT",
    "SET_CHANGE_CUSTOMER",
    "SET_CUSTOMERS",
    "SET_ROLES",
  ],
}
const middlewares = [createStateSyncMiddleware(config), logger]

// if (window.__REDUX_DEVTOOLS_EXTENSION__)
// middlewares.push ( window.__REDUX_DEVTOOLS_EXTENSION__())

let store = createStore(persistedReducer, applyMiddleware(...middlewares))

initMessageListener(store)

let persistor = persistStore(store)

export default store
export { persistor }
