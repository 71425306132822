import React from "react"
import Loading from "shared/Loading"
import { FormattedMessage, FormattedNumber } from "react-intl"
import classnames from "classnames"
import Image from "shared/Image"

function ShipmentDetails(props) {
  let { shipDetails = [], totals, workOrderType } = props

  return (
    <div className="row m-0 borders mb-3 ">
      <div className="documentlines-data">
        <Loading id="get_shipmentDetails">
          {workOrderType === "NoShip" ? (
            <div className="col-12 my-5 text-center nodata shipment">
              <div className="image">
                <Image
                  className="norecords"
                  src="Full_No_Ship_Icon.svg"
                  height="118px"
                  width="118px"
                  alt=""
                />
              </div>

              <FormattedMessage id="nodata.noship" />
            </div>
          ) : shipDetails.length === 0 || totals.totalWeight == 0 ? (
            <div className="col-12 my-5 text-center nodata shipment">
              <div className="image">
                <Image
                  className="norecords"
                  src="no_records_bg.svg"
                  height="118px"
                  width="118px"
                  alt=""
                />
              </div>

              <FormattedMessage id="nodata.shipmentDetails" />
            </div>
          ) : (
            totals.totalWeight != 0 && (
              <div>
                <div
                  className={classnames("col-12 p-0 m-0", {
                    "d-none": !shipDetails[0],
                  })}
                >
                  <div className="row pl-3 mr-3">
                    <div className="col-2   text-left">
                      <div className="serviceheading">
                        <FormattedMessage id="serviceorder.documentno" />
                      </div>
                    </div>
                    <div className="col-1">
                      <div className="serviceheading">
                        <FormattedMessage id="serviceorder.line" />
                      </div>
                    </div>
                    <div className="col-1 p-0">
                      <div className="serviceheading">
                        <FormattedMessage id="serviceorder.epahaz" />
                      </div>
                    </div>
                    <div className="col-5 p-0">
                      <div className="serviceheading">
                        <FormattedMessage id="serviceorder.description" />
                      </div>
                    </div>
                    <div className="col-1 p-0">
                      <div className="serviceheading">
                        <FormattedMessage id="serviceorder.conttype" />
                      </div>
                    </div>
                    <div className="col-1">
                      <div className="serviceheading">
                        <FormattedMessage id="serviceorder.qty" />
                      </div>
                    </div>
                    <div className="col-1 p-0">
                      <div className="serviceheading">
                        <FormattedMessage id="serviceorder.weight" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 p-0 m-0">
                  <div className={`row pl-3 mr-3 shipmentdeatils  "}`}>
                    {shipDetails &&
                      shipDetails.map((el, i) => (
                        <div className="col-12 p-0 m-0" key={i}>
                          <div className="row p-0 m-0 ">
                            <div className="col-2  text-left ">
                              <div className="servicerow">
                                {el.manifestTrackingNo}
                              </div>
                            </div>
                            <div className="col-1 pl-4">
                              <div className="servicerow">{el.line}</div>
                            </div>
                            <div className="col-1 pl-4">
                              <div className="servicerow">{el.epaHaz}</div>
                            </div>
                            <div className="col-5 p-0">
                              <div className="servicerow">{el.description}</div>
                            </div>
                            <div className="col-1 pl-4">
                              <div className="servicerow">
                                {el.containerType}
                              </div>
                            </div>
                            <div className="col-1 pl-4">
                              <div className="servicerow">{el.quantity}</div>
                            </div>
                            <div className="col-1 ">
                              <div className="servicerow">
                                <FormattedNumber
                                  value={el.weight}
                                  maximumFractionDigits={1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )
          )}
        </Loading>
      </div>
    </div>
  )
}
export default ShipmentDetails
