import React, { useState, useEffect, useRef } from "react"
import { RadialBarChart, RadialBar, PolarAngleAxis } from "recharts"
import { useWindowDimension } from "shared"
import colors from "./colors"
import "./style.scss"

const Chart = ({ config, height, percentage }) => {
  const [width, setWidth] = useState(0)
  const elementRef = useRef(null)

  const data = [
    {
      uv: percentage,
      fill: colors.radial,
    },
  ]

  let dim = useWindowDimension()

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width)
  }, dim)

  return (
    <div ref={elementRef}>
      <RadialBarChart
        width={width}
        height={height}
        data={data}
        innerRadius="70%"
        outerRadius="100%"
        endAngle={0}
        startAngle={360}
      >
        <PolarAngleAxis
          type="number"
          domain={[0, 100]}
          angleAxisId={0}
          tick={false}
        />
        <RadialBar dataKey="uv" background />
      </RadialBarChart>
    </div>
  )
}

export default Chart
