import * as dfns from "date-fns"
let timePresetOptions = [
  // "7 Days Ago",
  // "Last Week",
  // "Last Month",
  // "Two Months Ago",
  // "Last 90 Days",
  // "Last 180 Days",
  // "Last Quarter",
  // "This Quarter",
  // "Year To Date",
  // "Last Year",
  // "None",
]
// let today = dfns.parse("07/15/2021", "MM/dd/yyyy", new Date())

let push = () => {
  timePresetOptions.push({
    l,
    v: { from, to, skipMonth, skipyear, skippresent },
  })
  skipMonth = false
  skipyear = false
  skippresent = false
}

let today = dfns.startOfToday()

let l, from, to, day, extra, skipMonth, skipyear, skippresent

l = "7 Days Ago"
from = dfns.sub(today, { days: 7 })
to = today
skipMonth = true
skippresent = true
push()

l = "Last Week"
day = dfns.sub(today, { weeks: 1 })
extra = { weekStartsOn: 1 }
from = dfns.startOfWeek(day, extra)
to = dfns.endOfWeek(day, extra)
skipMonth = true
skippresent = true
push()

l = "Last Month"
day = dfns.sub(today, { months: 1 })
from = dfns.startOfMonth(day)
to = dfns.endOfMonth(day)
skippresent = true
push()

l = "Two Months Ago"
day = dfns.sub(today, { months: 2 })
from = dfns.startOfMonth(day)
to = dfns.endOfMonth(day)
skippresent = true
push()

l = "Last 90 Days"
from = dfns.sub(today, { days: 90 })
to = today
skipMonth = true
skippresent = true
push()

l = "Last 180 Days"
from = dfns.sub(today, { days: 180 })
to = today
skipMonth = true
skippresent = true
push()

l = "This Quarter"
from = dfns.startOfQuarter(today)
to = dfns.endOfQuarter(today)
skipMonth = true
skippresent = true
push()

l = "Last Quarter"
day = dfns.subQuarters(today, 1)
from = dfns.startOfQuarter(day)
to = dfns.endOfQuarter(day)
push()

l = "Year To Date"
from = dfns.startOfYear(today)
skipMonth = true
to = today
skipyear = true
push()

l = "Last Year"
day = dfns.sub(today, { years: 1 })
from = dfns.startOfYear(day)
to = dfns.endOfYear(day)
push()

l = "Custom"
from = today
to = today
push()

export default timePresetOptions
