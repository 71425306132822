import React from "react"
import Image from "shared/HelpImage/index"

function WasteShipmentReport() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Waste Shipment Summary Report</h3>
      <div className="img-section pt-4">
        <Image
          className=""
          src="Wssr_filters_report.png"
          alt="Waste Shipment Image"
        />
      </div>

      <p className="text-justify pl-3 pt-2">
        To create the Waste Shipment Summary Report you can first choose among
        multiple filters to tailor the report you want. You can filter by:
        “Divisions, Regions” (if applicable), “States”, “Stores” and among
        different Predefined dates, or select your own Date Range.
      </p>
      <p className="text-justify pl-3 pt-2">
        <ul>
          <li>
            {" "}
            If you filter on “States” you can choose one or more of the states
            you want to include.
          </li>
          <li>
            {" "}
            If you filter on “Stores” you can choose up to 100 stores, or choose
            “All.”
          </li>
        </ul>

        <div className="img-section pt-4">
          <Image
            className=""
            src="Wssr_report_table.png"
            alt="Waste Shipment Image"
          />
        </div>
      </p>
      <p className="text-justify pl-3 pt-2">
        Once you have selected the filters you want, press the Apply button. The
        report you have defined will be displayed. The report shows information
        including: customer name, location number and address, all reporting
        profile information, shipment information such as manifest,
        transportation and TSDF information, management codes and final codes.
      </p>
      <p className="text-justify pl-3 pt-2">
        The green scroll bar at the bottom of the display will help you see all
        the information on the line. 20 lines will be displayed per page of the
        report. If there are more than 20 lines in the report you can also move
        from page to page.
      </p>
      <p className="text-justify pl-3 pt-2">
        You can also choose to download the report to your computer by pressing
        the Download button.
        <ul>
          <li>
            {" "}
            If the report is up to 40,000 records long it will download as an
            Excel file.
          </li>
          <li>
            {" "}
            If the report is over 40,000 records and up to 200,000 records long
            it will download as a .CSV file.
          </li>
          <li>
            Pop up will be shown if accepts the system acccepts the download
            request
          </li>
          <div className="img-section pt-4">
            <Image
              className=""
              src="large_download_pop_up.png"
              alt="Waste Shipment Image"
            />
          </div>
          <li>
            Once the download process is completed, alert notification is
            triggered in the bell icon where a notification is shown click here
            to download the file{" "}
          </li>
          <div className="img-section pt-4">
            <Image
              className=""
              src="large_download_alert_notification.png"
              alt="Waste Shipment Image"
            />
          </div>
          <li> Reports greater than 200,000 records cannot be downloaded.</li>
        </ul>
      </p>
    </div>
  )
}
export default WasteShipmentReport
