import React, { Fragment, useEffect, useState } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import classnames from "classnames"
import Image from "shared/Image"
import { FormattedMessage } from "react-intl"
import Loading from "shared/Loading"
import { getDateFormat, getOptions } from "routes/Utilities/util"
import DateRangePicker from "shared/DateRangePicker"
import Input from "routes/Admin/Components/Input"
import { useSelector } from "react-redux"
import Select from "shared/Select"
import MultiSelectComponent from "shared/Select/CustomMultiSelect"
import PrintLabelModal from "routes/Print/PrintLabelModal"
import Modal from "shared/Modal"
import { useHarsco } from "shared"
import { roles } from "config"

function Profiles(props) {
  let {
    goToCreateProfile,
    goToCloneProfile,
    goToRecertifyProfile,
    viewProfile,
    editProfile,
    showDelete,
    handlePrintState,
    downloadDocument,
    printDocument,
    createProfileFromTemplate,
    setProfileNameAction,
    mapDefaultGenerator,
    setLoading,
    loading,
    printLoader,
    setPrintLoader,
    downloadLoader,
    setDownloadLoader,
  } = props
  const { profile } = useHarsco()
  const allProfileStatus = useSelector(
    (state) => state.profileDetails.allProfileStatus
  )
  const defaultGenerator = useSelector(
    (state) => state.profileDetails.defaultGenerator
  )

  const printState = useSelector((state) => state.profileDetails.printStatus)

  const allProfileTemplates = useSelector(
    (state) => state.profileDetails.allProfileTemplates
  )

  const showdeleteMessage = useSelector(
    (state) => state.generatorDetails.showdeleteMessage
  )
  const [copyModal, setCopyModal] = useState(false)
  const [activeProfile, setActiveProfile] = useState("")
  const [profileName, setProfileName] = useState("")
  const [invalidDateRange, setInvalidDateRange] = useState(false)
  const [currentPayload, setCurrentPayload] = useState(null)
  const [expiryDateRange, setExpiryDateRange] = useState({
    from: new Date(),
    to: new Date(),
  })
  const allProfileStatusOptions = getOptions(
    allProfileStatus,
    "description",
    "id",
    "label",
    "value"
  )

  const allProfileTemplatesOptions = getOptions(
    allProfileTemplates,
    "profileName",
    "profileNumber"
  )

  const [showModal, setShowModal] = useState(false)
  const [currentData, setCurrentData] = useState(null)
  const [showOptions, setShowOptions] = useState(false)
  const [universalWaste, setUniversalWaste] = useState({ l: "All", v: "All" })
  const [expiryType, setExpiryType] = useState({ l: "90 Days", v: "90 Days" })
  const [wasteName, setWasteName] = useState("")
  const [template, setTemplate] = useState({ l: "", v: "" })
  // const [revisionNumber, setRevisionNumber] = useState()
  // const [profileNumber, setProfileNumber] = useState()
  const reviNumber = allProfileTemplatesOptions.find(
    (p) => p.profileNumber === template.v
  )
  const [selectedStatus, setSelectedStatus] = useState(
    getOptions(
      allProfileStatus?.filter(
        (val) =>
          val.id === 1 ||
          val.id === 4 ||
          val.id === 7 ||
          val.id === 8 ||
          val.id === 5
      ),
      "description",
      "id",
      "label",
      "value"
    )
  )
  const [profileId, setProfileId] = useState(null)
  const expiryTypeOptions = [
    {
      v: "Weekly",
      l: "Weekly",
    },
    {
      v: "Monthly",
      l: "Monthly",
    },
    {
      v: "90 Days",
      l: "90 Days",
    },
    {
      v: "Custom Date",
      l: "Custom Date",
    },
  ]

  const [popup, setPopup] = useState(false)
  //eslint-disable-next-line
  const [showInfo, setShowInfo] = useState(false)
  const generatorOptions = useSelector(
    (state) => state.generatorDetails.generatorList
  )
  const profileList = useSelector((state) => state.profileDetails.profilesList)
  const sortInfo = useSelector((state) => state.profileDetails.sort)

  let generatorCodeOpts =
    generatorOptions?.length > 0
      ? getOptions(
          generatorOptions,
          "generatorName",
          "generatorId",
          "label",
          "value"
        )
      : [{ value: "", label: "", generatorId: "", generatorName: "" }]
  const [selectedGenerator, setSelectedGenerator] = useState([
    { generatorId: "", generatorName: "" },
  ])
  const [loadDefault, setLoadDefault] = useState(false)

  // useEffect(() => {
  // const reviNumber = allProfileTemplatesOptions.find(
  //   (p) => p.profileNumber === template.v
  // )
  // setRevisionNumber(reviNumber?.revisionNumber)
  // setProfileNumber(reviNumber?.profileNumber)
  // }, [allProfileTemplatesOptions, template])

  useEffect(() => {
    if (generatorCodeOpts?.length > 0 && !loadDefault && defaultGenerator) {
      setSelectedGenerator([
        generatorCodeOpts?.filter(
          (val) => val.generatorId === defaultGenerator
        )?.[0],
      ])
      setLoadDefault(true)
    }
  }, [selectedGenerator, defaultGenerator])

  useEffect(() => {
    if (selectedStatus?.length === 0) {
      setSelectedStatus(
        getOptions(
          allProfileStatus.filter(
            (val) =>
              val.id === 1 ||
              val.id === 4 ||
              val.id === 7 ||
              val.id === 8 ||
              val.id === 5
          ),
          "description",
          "id",
          "label",
          "value"
        )
      )
    }
  }, [allProfileStatus])

  // const onSelectValue = () => {}
  // const onSelectField = () => {}

  const loadInitialData = () => {
    const payload = {
      generatorIdList: defaultGenerator || null,
      profileId: null,
      wasteName: null,
      universalWaste: "All",
      expiryType: "90 Days",
      expiryFromDate: null,
      expiryToDate: null,
      profileStatusGroupIdList:
        selectedStatus?.length > 0
          ? selectedStatus?.map((val) => val.value)?.join(",")
          : "1,4,8,5",
    }
    props?.searchSubmit(payload)
  }

  useEffect(() => {
    if (generatorOptions?.length > 0 && allProfileStatus?.length > 0) {
      loadInitialData()
    }
  }, [allProfileStatus, generatorOptions])

  const resetFilter = () => {
    setSelectedGenerator([
      generatorCodeOpts?.filter(
        (val) => val.generatorId === defaultGenerator
      )?.[0],
    ])
    setSelectedStatus(
      getOptions(
        allProfileStatus.filter(
          (val) =>
            val.id === 1 ||
            val.id === 4 ||
            val.id === 7 ||
            val.id === 8 ||
            val.id === 5
        ),
        "description",
        "id",
        "label",
        "value"
      )
    )
    setExpiryType({ l: "90 Days", v: "90 Days" })
    setUniversalWaste({ l: "All", v: "All" })
    setProfileId("")
    setWasteName("")
    loadInitialData()
  }

  useEffect(() => {
    setLoading(true)
  }, [])

  const searchFilter = () => {
    let match = selectedGenerator?.length === generatorCodeOpts?.length
    let genIdList = ""
    if (match) {
      genIdList = ""
    } else {
      genIdList = selectedGenerator
        ?.filter((val) => val.generatorId)
        ?.map((val) => val.generatorId)
        ?.join(",")
    }
    setLoading(true)
    const payload = {
      generatorIdList: genIdList,
      profileId: profileId ? profileId : null,
      wasteName: wasteName,
      universalWaste: universalWaste?.v,
      expiryType: expiryType?.v,
      expiryFromDate:
        expiryType?.v === "Custom Date"
          ? getDateFormat(expiryDateRange?.from)
          : null,
      expiryToDate:
        expiryType?.v === "Custom Date"
          ? getDateFormat(expiryDateRange?.to)
          : null,
      profileStatusGroupIdList: selectedStatus
        ?.map((val) => val.value)
        ?.join(","),
    }
    setCurrentPayload(payload)
    if (
      expiryType?.v === "Custom Date" &&
      expiryDateRange?.from &&
      expiryDateRange?.to &&
      expiryDateRange?.from > expiryDateRange?.to
    ) {
      setInvalidDateRange(true)
    } else {
      props?.searchSubmit(payload)
      setInvalidDateRange(false)
    }
  }

  const editProfileUser = (x) => {
    editProfile(x.profileId, x.generatorId)
  }

  // const recertifyProfile = (data) => {

  // }

  const deleteProfileUser = (x) => {
    const payload = {
      generatorIdList: selectedGenerator
        ?.filter((val) => val.generatorId)
        ?.map((val) => val.generatorId)
        ?.join(","),
      profileId: profileId ? profileId : null,
      wasteName: wasteName,
      universalWaste: universalWaste?.v,
      expiryType: expiryType?.v,
      expiryFromDate:
        expiryType?.v === "Custom Date"
          ? getDateFormat(expiryDateRange?.from)
          : null,
      expiryToDate:
        expiryType?.v === "Custom Date"
          ? getDateFormat(expiryDateRange?.to)
          : null,
      profileStatusGroupIdList: selectedStatus
        ?.map((val) => val.value)
        ?.join(","),
    }
    if (currentPayload) {
      props?.deleteProfile(
        x.status?.toLowerCase() == "draft" ? x.profileId : x.profile,
        currentPayload
      )
    } else {
      props?.deleteProfile(
        x.status?.toLowerCase() == "draft" ? x.profileId : x.profile,
        payload
      )
    }
  }

  const sortArrows = (field) => {
    return (
      <>
        {((sortInfo?.sortField === field && sortInfo.dir === "asc") ||
          sortInfo?.sortField != field) && <Image src="asc_icon_table.svg" />}
        {sortInfo?.sortField === field && sortInfo.dir === "desc" && (
          <Image src="desc_icon_table.svg" />
        )}
      </>
    )
  }

  return (
    <Fragment>
      <iframe
        id="pdf-frame"
        title="Print PDF"
        style={{ display: "none" }}
      ></iframe>
      {printLoader || downloadLoader ? (
        <div>
          <div className="loader" style={{ marginTop: 70 }}></div>
        </div>
      ) : (
        <>
          {printState ? <PrintLabelModal /> : ""}
          {showOptions && (
            <div
              className="overlay"
              onClick={() => setShowOptions(false)}
            ></div>
          )}
          {popup && (
            <div
              className="overlaypopup"
              onClick={() => setShowOptions(false)}
            ></div>
          )}
          {popup && (
            <div
              className="popup p-2 template-popup"
              style={{
                marginLeft: "6%",
                marginRight: "35%",
                borderRadius: "20px",
                background: "#F0F1F2",
              }}
            >
              <div className="dsp-flx col-sm-12 mt-4">
                <h3
                  className="label p-2"
                  style={{
                    fontSize: 16,
                    // marginLeft: 4,
                    // float: "left",
                    // marginTop: 15,
                    // color: "#0092bc",
                    // fontWeight: "bold",
                  }}
                >
                  <FormattedMessage id="profile.selecttemplate" />
                </h3>
                <button className="circle-btn" onClick={() => setPopup(false)}>
                  <span
                    style={{
                      color: "#CCCCCC",
                      cursor: "pointer",
                      position: "relative",
                      bottom: 3,
                    }}
                  >
                    x
                  </span>
                </button>
              </div>
              <div
                className="label pl-4"
                style={{ float: "left", fontSize: 14 }}
              >
                <FormattedMessage id="profile.predefinedtemplates" />
              </div>
              <div className="mt-4" style={{ textAlign: "left" }}>
                <div
                  className="form-input-block ml-2 col-sm-6 mt-2 mni-style"
                  // style={{ minWidth: 85 }}
                >
                  <span className="label pl-0" style={{ fontSize: 12 }}>
                    <FormattedMessage id="profile.select" />
                  </span>
                  <Select
                    name="choose template"
                    label=""
                    className={"sort-dropdown w-90 button-clickable"}
                    labelkey={"l"}
                    options={allProfileTemplatesOptions}
                    selected={template}
                    hasSearch={true}
                    onSelect={(e) => {
                      setTemplate(e)
                    }}
                  />
                </div>
              </div>
              <div
                className="pl-4 mt-4"
                style={{ textAlign: "left", paddingTop: 80, marginBottom: 15 }}
              >
                <button
                  className={`${classnames("downloadbtn")} ${
                    !template?.v ? "disabledBgBtn" : ""
                  }`}
                  onClick={() =>
                    createProfileFromTemplate(
                      "editprofile",
                      reviNumber?.profileNumber,
                      reviNumber?.revisionNumber
                    )
                  }
                  disabled={!template?.v}
                  style={{ padding: "6px 20px" }}
                >
                  <FormattedMessage id="profile.confirm" />
                </button>
              </div>
            </div>
          )}
          <div className="mniDashBoardDiv">
            <Modal show={copyModal} style={{ padding: 20 }}>
              <div className="p-4" style={{ width: "40VW" }}>
                <div className="dsp-flx col-sm-12 mt-2">
                  {/* <h4 className="p-2" style={{ textAlign: "left" }}>
              <FormattedMessage id="profile.enterprofilename" />
            </h4> */}
                  <div className="label" style={{ textAlign: "left" }}>
                    <FormattedMessage id="profile.enterprofilename" />
                  </div>
                  {/* <span
              style={{ color: "#CCCCCC", cursor: "pointer" }}
              onClick={() => setPopup(false)}
            >
              x
            </span> */}
                </div>
                <div className="mt-4" style={{ textAlign: "left" }}>
                  <div
                    className="form-input-block col-sm-8 mt-2"
                    style={{ minWidth: 85 }}
                  >
                    <span className="label pl-0" style={{ fontSize: 12 }}>
                      <FormattedMessage id="profile.name" />
                    </span>
                    <Input
                      name="profileName"
                      value={profileName}
                      onFieldChange={(e) => {
                        setProfileName(e.target.value)
                      }}
                    />
                  </div>
                </div>
                <div className="pl-4 mt-4" style={{ textAlign: "right" }}>
                  <button
                    className={`cancel-button mr-2`}
                    onClick={() => {
                      setProfileName("")
                      setProfileNameAction("")
                      setCopyModal(false)
                      setActiveProfile("")
                    }}
                    style={{ padding: "6px 20px" }}
                  >
                    <FormattedMessage id="profile.cancelbtn" />
                  </button>
                  <button
                    className={`${classnames("downloadbtn")} ${
                      profileName?.trim()?.length === 0 ? "disabledBgBtn" : ""
                    }`}
                    onClick={() => {
                      setProfileNameAction(profileName)
                      goToCloneProfile(
                        "editprofile",
                        activeProfile.status?.toLowerCase() == "draft"
                          ? activeProfile?.profileId
                          : activeProfile?.profile
                      )
                      setProfileName("")
                      setCopyModal(false)
                    }}
                    disabled={profileName?.trim()?.length === 0}
                    style={{ padding: "6px 20px" }}
                  >
                    <FormattedMessage id="profile.okaybtn" />
                  </button>
                </div>
              </div>
            </Modal>
            {/* Generator Info Delete popup*/}
            <Modal show={showModal} style={{ padding: 20 }}>
              <div style={{ padding: 20, fontSize: 12 }}>
                {!showdeleteMessage && (
                  <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus text-left py-4">
                    <div className="m-0 labelgenerator myprofile">
                      <FormattedMessage id="profile.generatorinfo.deleteprofile" />
                    </div>
                  </div>
                )}
                {!showdeleteMessage && (
                  <div className="ml-3 mb-4" style={{ textAlign: "left" }}>
                    <FormattedMessage id="profile.generatorinfo.suredelete" />{" "}
                    {currentData?.profileId}?
                  </div>
                )}
                {showdeleteMessage && (
                  <div className="ml-3 mb-4" style={{ textAlign: "left" }}>
                    <FormattedMessage id="profile.generatorinfo.profiledeleted" />
                  </div>
                )}

                {!showdeleteMessage && (
                  <div
                    className="dsp-flx col-sm-12 mt-2"
                    style={{ flexDirection: "row" }}
                  >
                    <button
                      className="green-button"
                      style={{
                        background: "#ffffff",
                        color: "#0092BC",
                        border: "1px solid #0092BC",
                      }}
                      type="button"
                      onClick={() => {
                        setShowModal(false)
                      }}
                    >
                      <FormattedMessage id="profile.generatorinfo.cancel" />
                    </button>
                    <button
                      className="green-button"
                      type="button"
                      onClick={() => {
                        deleteProfileUser(currentData)
                        setTimeout(() => {
                          showDelete(false)
                          setShowModal(false)
                        }, 3000)
                      }}
                    >
                      <FormattedMessage id="profile.generatorinfo.confirm" />
                    </button>
                  </div>
                )}
              </div>
            </Modal>
          </div>
          <div>
            <div className="col-sm-12 col-md-12 col-lg-12 generatorstatus text-left my-4">
              <div className="m-0 labelgenerator myprofile">
                <FormattedMessage id="profile.search" />
              </div>
              <div className="downloadsec">
                {profile?.roleId !== roles.CE_GENERIC_USER && (
                  <button
                    className={classnames("downloadbtn")}
                    onClick={() => setShowOptions((x) => !x)}
                    style={{ padding: "6px 10px" }}
                    // onBlur={() => setShowOptions(false)}
                  >
                    <Image
                      className="mr-1"
                      src={"Add_Icon_MI.svg"}
                      height="16px"
                      width="16px"
                      alt=""
                    />
                    <FormattedMessage id="profile.create" />
                    <Image
                      className="ml-1"
                      src={"ArrowDown.svg"}
                      height="16px"
                      width="16px"
                      alt=""
                    />
                  </button>
                )}
                {showOptions && (
                  <div
                    className="dsp-flx"
                    style={{
                      flexDirection: "column",
                      cursor: "pointer",
                      justifyContent: "center",
                      background: "#FFFFFF",
                    }}
                  >
                    <span
                      className="py-1 px-2"
                      style={{
                        zIndex: 15,
                        minWidth: 150,
                        background: "#FFFFFF",
                        cursor: "pointer",
                        borderBottom: "1px solid #CCCCCC",
                      }}
                      onClick={() => {
                        mapDefaultGenerator()
                        goToCreateProfile("createprofile")
                      }}
                    >
                      From Blank
                    </span>
                    <span
                      className="py-1 px-2"
                      style={{
                        zIndex: 15,
                        minWidth: 150,
                        background: "#FFFFFF",
                      }}
                      onClick={() => setPopup(true)}
                    >
                      From Template
                    </span>
                  </div>
                )}
              </div>
            </div>
            {generatorCodeOpts?.length > 0 && allProfileStatus?.length > 0 && (
              <div className="col-sm-12 col-md-12 col-lg-12 d-flex">
                <div
                  className="form-input-block col-sm-4"
                  style={{ textAlign: "left" }}
                >
                  <span className="label" style={{ zIndex: 1 }}>
                    <FormattedMessage id="profile.generator" />
                    <span style={{ color: "red" }}>{" *"} </span>
                  </span>
                  <MultiSelectComponent
                    className="custom-generator"
                    name="generatorIdList"
                    options={generatorCodeOpts}
                    selected={selectedGenerator?.filter(
                      (val) => val?.generatorId
                    )}
                    handleOnChange={(val) => setSelectedGenerator(val)}
                    isAllReqd={true}
                    disableSearch={false}
                  />
                  {/* <div style={{ color: "red", fontSize: 10, padding: 8 }}>
                {selectedGenerator?.filter((val) => val?.generatorId)
                  ?.length === 0 ? (
                  <>Please select Generator</>
                ) : null}
              </div> */}
                  {/* <Input
              name="generatorIdList"
              value={generatorInfo}
              search={true}
              keyName="name"
              suggestions={generatorSuggestions}
              onFieldChange={(e) => {
                setGeneratorInfo(e.target.value)
              }}
              onSelect={(e) => {
                setGeneratorData(e)
              }}
            /> */}
                </div>
                <div
                  className="form-input-block col-sm-4 mni-style expire-date"
                  style={{ marginLeft: -3 }}
                >
                  <span className="label" style={{ zIndex: popup ? 1 : 11 }}>
                    <FormattedMessage id="profile.expiry" />
                    <span style={{ color: "red" }}>{" *"} </span>
                  </span>
                  <Select
                    name="expiryType"
                    label=""
                    labelkey={"l"}
                    className={"sort-dropdown w-90"}
                    options={expiryTypeOptions}
                    selected={expiryType}
                    onSelect={(e) => {
                      setExpiryType(e)
                    }}
                  />
                </div>
                <div
                  className="form-input-block col-sm-4"
                  style={{ textAlign: "left" }}
                >
                  <span className="label" style={{ zIndex: 1 }}>
                    <FormattedMessage id="profile.status" />
                    <span style={{ color: "red" }}>{" *"} </span>
                  </span>
                  <MultiSelectComponent
                    className="custom-generator"
                    options={allProfileStatusOptions}
                    selected={selectedStatus}
                    handleOnChange={(val) => setSelectedStatus(val)}
                    isAllReqd={true}
                    disableSearch={false}
                  />
                  <div style={{ color: "red", fontSize: 10, padding: 8 }}>
                    {selectedStatus?.length === 0 ? (
                      <>Please select Status</>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
            {expiryType?.v === "Custom Date" && (
              <div className="col-sm-12 col-md-12 col-lg-12 d-flex">
                <div className="form-input-block col-sm-4"></div>
                <div
                  className="form-input-block col-sm-4 mni-date"
                  style={{ fontSize: 14 }}
                >
                  <DateRangePicker
                    type="date"
                    value={expiryDateRange}
                    additionalYears={2}
                    cb={(d, startDate) => {
                      if (
                        expiryType?.v === "Custom Date" &&
                        expiryDateRange?.from &&
                        expiryDateRange?.to &&
                        expiryDateRange?.from > expiryDateRange?.to
                      ) {
                        setInvalidDateRange(true)
                      } else {
                        setInvalidDateRange(false)
                      }
                    }}
                    onChange={(d) => {
                      let { from, to } = d
                      if (from && to) {
                        if (
                          new Date(from).getMilliseconds() >
                          new Date(to).getMilliseconds()
                        ) {
                          from = to
                        }
                      }
                      setExpiryDateRange({ from, to })
                      if (
                        expiryType?.v === "Custom Date" &&
                        from &&
                        to &&
                        from > to
                      ) {
                        setInvalidDateRange(true)
                      } else {
                        setInvalidDateRange(false)
                      }
                    }}
                    showCurrentYr={true}
                  />
                  <div style={{ color: "red", fontSize: 10, padding: 8 }}>
                    {invalidDateRange && (
                      <>From Date Should not exceed To Date</>
                    )}
                  </div>
                </div>
                <div className="form-input-block col-sm-4"></div>
              </div>
            )}
            <div className="col-sm-12 col-md-12 col-lg-12 d-flex my-4">
              <div className="form-input-block col-sm-4">
                <span className="label" style={{ zIndex: 1 }}>
                  <FormattedMessage id="profile.waste" />
                </span>
                <Input
                  name="wasteName"
                  value={wasteName}
                  onFieldChange={(e) => {
                    setWasteName(e.target.value)
                  }}
                />
              </div>
              <div className="form-input-block col-sm-4">
                <span className="label" style={{ zIndex: 1 }}>
                  <FormattedMessage id="profile.profile" />
                </span>
                <Input
                  name="profileId"
                  value={profileId}
                  onFieldChange={(e) => {
                    setProfileId(e.target.value)
                  }}
                />
              </div>
              <div className="form-input-block col-sm-4 mni-style univ-waste">
                <span className="label">
                  <FormattedMessage id="profile.universal" />
                  <span style={{ color: "red" }}>{" *"} </span>
                </span>
                <Select
                  name="universalWaste"
                  label=""
                  labelkey={"l"}
                  className={"sort-dropdown w-90"}
                  options={[
                    { l: "All", v: "All" },
                    { l: "Yes", v: "Yes" },
                    { l: "No", v: "No" },
                  ]}
                  selected={universalWaste}
                  onSelect={(e) => {
                    setUniversalWaste(e)
                  }}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 generatorstatus text-left my-4">
              <div className="downloadsec">
                <button
                  className={classnames("downloadbtn")}
                  onClick={resetFilter}
                  style={{
                    padding: "6px 10px",
                    minWidth: 140,
                    background: "#FFFFFF",
                    color: "#337AB7",
                    marginRight: 15,
                  }}
                >
                  <FormattedMessage id="profile.cancelbtn" />
                </button>
                <button
                  className={`green-button ${
                    selectedGenerator?.filter((val) => val?.generatorId)
                      .length === 0 ||
                    selectedStatus.length === 0 ||
                    invalidDateRange
                      ? "disabledBg"
                      : ""
                  }`}
                  onClick={searchFilter}
                  style={{ padding: "6px 10px", minWidth: 140 }}
                  disabled={
                    selectedGenerator?.filter((val) => val?.generatorId)
                      ?.length === 0 ||
                    selectedStatus.length === 0 ||
                    invalidDateRange
                  }
                >
                  <FormattedMessage id="profile.searchbtn" />
                </button>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "4rem" }}>
            <div className="col-sm-12 col-md-12 col-lg-12 generatorstatus text-left my-4">
              <div className="m-0 labelgenerator myprofile mt-4">
                <FormattedMessage id="generator.searchresults" />
              </div>
            </div>
            <Loading id="documents">
              <div
                className="col-sm-12 col-md-12 col-lg-12 mx-3 p-0 table sticky isshipreport"
                style={{
                  maxHeight: 400,
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <div className="row m-0 headers" style={{ cursor: "pointer" }}>
                  <div className={`tr active`}>
                    <div
                      className={`th customsort inactive title-container`}
                      colSpan={1}
                      style={{
                        display: "inline-block",
                        boxSizing: "border-box",
                        width: 85,
                      }}
                    >
                      <span className="title-text">
                        <FormattedMessage id="profile.profile" />
                      </span>
                      <span
                        className="sorter"
                        onClick={() =>
                          props?.sortData(profileList, {
                            fieldName: "profile",
                            type: "string",
                          })
                        }
                      >
                        {sortArrows("profile")}
                      </span>
                    </div>
                    <div
                      className={`th customsort inactive title-container`}
                      colSpan={1}
                      style={{
                        display: "inline-block",
                        boxSizing: "border-box",
                        width: 70,
                      }}
                    >
                      <span className="title-text">
                        <FormattedMessage id="profile.version" />
                      </span>
                      <span
                        onClick={() =>
                          props?.sortData(profileList, {
                            fieldName: "version",
                            type: "number",
                          })
                        }
                      >
                        {sortArrows("version")}
                      </span>
                    </div>
                    <div
                      className={`th customsort inactive title-container`}
                      colSpan={1}
                      style={{
                        display: "inline-block",
                        boxSizing: "border-box",
                        width: 175,
                      }}
                    >
                      <span className="title-text">
                        <FormattedMessage id="profile.waste" />
                      </span>
                      <span
                        onClick={() =>
                          props?.sortData(profileList, {
                            fieldName: "wasteName",
                            type: "string",
                          })
                        }
                      >
                        {sortArrows("wasteName")}
                      </span>
                    </div>
                    <div
                      className={`th customsort inactive title-container`}
                      colSpan={2}
                      style={{
                        display: "inline-block",
                        boxSizing: "border-box",
                        width: 210,
                      }}
                    >
                      <span className="title-text">
                        <FormattedMessage id="profile.generatorname" />
                      </span>
                      <span
                        onClick={() =>
                          props?.sortData(profileList, {
                            fieldName: "generatorName",
                            type: "string",
                          })
                        }
                      >
                        {sortArrows("generatorName")}
                      </span>
                    </div>
                    <div
                      className={`th customsort inactive title-container`}
                      colSpan={1}
                      style={{
                        display: "inline-block",
                        boxSizing: "border-box",
                        width: 75,
                      }}
                    >
                      <span className="title-text">
                        <FormattedMessage id="profile.status" />
                      </span>
                      <span
                        onClick={() =>
                          props?.sortData(profileList, {
                            fieldName: "status",
                            type: "string",
                          })
                        }
                      >
                        {sortArrows("status")}
                      </span>
                    </div>
                    <div
                      className={`th customsort inactive title-container`}
                      colSpan={1}
                      style={{
                        display: "inline-block",
                        boxSizing: "border-box",
                        width: 100,
                      }}
                    >
                      <span className="title-text">
                        <FormattedMessage id="profile.approveDate" />
                      </span>
                      <span
                        onClick={() =>
                          props?.sortData(profileList, {
                            fieldName: "approvedDate",
                            type: "date",
                          })
                        }
                      >
                        {sortArrows("approvedDate")}
                      </span>
                    </div>
                    <div
                      className={`th customsort inactive title-container`}
                      colSpan={1}
                      style={{
                        display: "inline-block",
                        boxSizing: "border-box",
                        width: 90,
                      }}
                    >
                      <span className="title-text">
                        <FormattedMessage id="profile.expiry" />
                      </span>
                      <span
                        onClick={() =>
                          props?.sortData(profileList, {
                            fieldName: "expiryDate",
                            type: "date",
                          })
                        }
                      >
                        {sortArrows("expiryDate")}
                      </span>
                    </div>
                    <div
                      className={`th custom inactive title-container`}
                      colSpan={1}
                      style={{
                        display: "inline-block",
                        boxSizing: "border-box",
                        width: 180,
                        overflow: "visible",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FormattedMessage id="profile.actions" />
                        {/* <Image
                      style={{ cursor: "default" }}
                      className={`view-icon ml-1`}
                      src="Info_Icon.svg"
                      height="14px"
                      width="14px"
                      alt=""
                      onMouseEnter={() => setShowInfo(true)}
                      onMouseLeave={() => setShowInfo(false)}
                      onClick={() => setShowInfo((prev) => !prev)}
                    /> */}
                      </div>
                      {/* {showInfo && (
                    <Image
                      style={{ cursor: "default" }}
                      className={`view-icon ml-1 hover-image`}
                      src="profile_info.png"
                      height="230px"
                      width="150px"
                      alt=""
                      onClick={() => {}}
                    />
                  )} */}
                    </div>
                  </div>
                </div>
                {!loading && (
                  <div
                    className="row m-0 body"
                    // style={{
                    //   maxHeight: 400,
                    //   overflowY: "scroll",
                    //   minHeight: profileList?.length == 1 ? 70 : 0,
                    // }}
                  >
                    {profileList?.map((data, i) => (
                      <div
                        className="tr active"
                        key={i}
                        style={{ display: "flex", minHeight: 32 }}
                      >
                        <div
                          className="td custom"
                          colSpan={1}
                          style={{
                            display: "inline-block",
                            boxSizing: "border-box",
                            width: 85,
                          }}
                        >
                          <span
                            style={{
                              color: "#337AB7",
                              cursor: "pointer",
                              textDecoration: "únderline",
                              fontWeight: "bold",
                            }}
                            onClick={() => {
                              mapDefaultGenerator(data?.generatorId)
                              viewProfile(
                                data.status?.toLowerCase() == "draft" &&
                                  data?.profileId != 0
                                  ? data?.profileId
                                  : data?.profile,
                                Number(data?.version)
                              )
                            }}
                          >
                            {data?.profile}
                          </span>
                        </div>
                        <div
                          className="td custom"
                          colSpan={1}
                          style={{
                            display: "inline-block",
                            boxSizing: "border-box",
                            width: 70,
                          }}
                        >
                          {data?.version}
                        </div>
                        <div
                          className="td custom"
                          colSpan={1}
                          style={{
                            display: "inline-block",
                            boxSizing: "border-box",
                            width: 175,
                          }}
                        >
                          {data?.wasteName}
                        </div>
                        <div
                          className="td custom info-tooltip"
                          colSpan={2}
                          style={{
                            display: "inline-block",
                            boxSizing: "border-box",
                            width: 210,
                            overflow: "visible",
                          }}
                        >
                          <div className="ellipsis">{data?.generatorName}</div>
                          <div
                            className="tooltipI px-1"
                            style={{ wordBreak: "break-word" }}
                          >
                            <div className="">{data?.generatorDetails}</div>
                          </div>
                        </div>

                        <div
                          className="td custom"
                          colSpan={1}
                          style={{
                            display: "inline-block",
                            boxSizing: "border-box",
                            width: 75,
                          }}
                        >
                          {data?.status}
                        </div>
                        <div
                          className="td custom"
                          colSpan={1}
                          style={{
                            display: "inline-block",
                            boxSizing: "border-box",
                            width: 100,
                          }}
                        >
                          {data?.approvedDate}
                        </div>

                        <div
                          className={`td custom ${
                            data?.expiryDate != "N/A" &&
                            new Date().getTime() >
                              new Date(data?.expiryDate).getTime()
                              ? "color-red"
                              : ""
                          }`}
                          colSpan={1}
                          style={{
                            display: "inline-block",
                            boxSizing: "border-box",
                            width: 90,
                          }}
                        >
                          {data?.expiryDate}
                        </div>
                        <div
                          className="td custom"
                          colSpan={1}
                          style={{
                            display: "inline-block",
                            boxSizing: "border-box",
                            width: 155,
                          }}
                        >
                          <div>
                            {data?.canCopy === 1 && (
                              <Image
                                title="Copy"
                                className="view-icon mr-2"
                                src="Copy.svg"
                                height="16px"
                                width="16px"
                                alt=""
                                onClick={() => {
                                  setCopyModal(true)
                                  setActiveProfile(data)
                                }}
                              />
                            )}
                            {data?.canPrint === 1 && (
                              <Image
                                title="Print"
                                className="view-icon mr-2"
                                src="Print.svg"
                                height="16px"
                                width="16px"
                                alt=""
                                onClick={() => {
                                  setPrintLoader(true)
                                  printDocument(
                                    data.status?.toLowerCase() == "draft"
                                      ? data.profileId
                                      : data.profile,
                                    data.status,
                                    data.version
                                  )
                                }}
                              />
                            )}
                            {data?.canDownload === 1 && (
                              <Image
                                title="Download"
                                className="view-icon mr-2"
                                src="DownloadIcon.svg"
                                height="16px"
                                width="16px"
                                alt=""
                                onClick={() => {
                                  setDownloadLoader(true)
                                  downloadDocument(
                                    data.status?.toLowerCase() == "draft"
                                      ? data.profileId
                                      : data.profile,
                                    data.status,
                                    data.version
                                  )
                                }}
                              />
                            )}
                            {data?.canEdit === 1 && (
                              <Image
                                title="Edit"
                                className="view-icon mr-2"
                                src="Edit.svg"
                                height="16px"
                                width="16px"
                                alt=""
                                onClick={() => editProfileUser(data)}
                              />
                            )}
                            {data?.canDelete === 1 && (
                              <Image
                                title="Delete"
                                className="view-icon mr-2"
                                src="Delete.svg"
                                height="16px"
                                width="16px"
                                alt=""
                                onClick={() => {
                                  setCurrentData(data)
                                  setShowModal(true)
                                }}
                              />
                            )}
                            {data?.canRecertify === 1 && (
                              <Image
                                title="Recertify"
                                className="view-icon mr-2"
                                src="Favourite.svg"
                                height="16px"
                                width="16px"
                                alt=""
                                onClick={() => goToRecertifyProfile(data)}
                              />
                            )}
                            {data?.canPrintLabel === 1 && (
                              <Image
                                title="Print Label"
                                className="view-icon mr-2"
                                src="PrintPDF.svg"
                                height="16px"
                                width="16px"
                                alt=""
                                onClick={() => {
                                  handlePrintState(true, data)
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* {profileList?.length == 0 && (
                  <div
                    className="td inactive"
                    style={{
                      background: "#ffffff",
                      padding: 10,
                      textAlign: "center",
                    }}
                  >
                    No Search Results for the filter.
                  </div>
                )} */}
                  </div>
                )}
              </div>
              {loading && (
                <div>
                  <div className="loader" style={{ marginTop: 100 }}></div>
                </div>
              )}
              {!loading && profileList?.length == 0 && (
                <div
                  className="td inactive"
                  style={{
                    background: "#ffffff",
                    padding: 10,
                    textAlign: "center",
                  }}
                >
                  No Search Results for the filter.
                </div>
              )}
              {/* {submitted && totalRecords == 0 && (
            <div className="col-sm-12 col-md-12 col-lg-12 text-center pt-2 nodata">
              <FormattedMessage id="nodata.documents" />
            </div>
          )}
          {totalRecords > 0 && (
            <div className="pl-3">
              <Pagination
                {...{
                  pageNo,
                  totalRecords,
                  gotoPage,
                  pageSize: 20,
                }}
              />
            </div>
          )} */}
            </Loading>
          </div>
        </>
      )}
    </Fragment>
  )
}
export default enhancer(Profiles)
