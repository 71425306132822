import React, { useState, useReducer, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import api from "api"
import { roles, BusinessSegmentId } from "config"
import { toast } from "react-toastify"
import * as dfns from "date-fns"
let enhancer = (Component) => (props) => {
  let history = useHistory()
  let location = useLocation()
  const goToUserManagement = () => {
    if (!location?.state?.viewState && !location?.state?.editState) {
      history.push("/admin")
    } else {
      history.goBack()
    }
  }
  const initialState = {
    startDate: "",
    endDate: "",
    roleIds: [],
    businessSegmentIds: [],
    description: "",
    formattedDescription: "",
    rolesAll: false,
    businessSegmentAll: false,
  }

  const ROLES_DETAILS = [
    roles.CE_ADMIN,
    roles.CUSTOMER_ADMIN,
    roles.CORPORATE_USER,
    roles.SITE_USER,
    roles.CE_GENERIC_USER,
    roles.ENTERPRISE_USER,
    roles.Brand_User,
  ]
  const BUSINESS_SEGMENTS = [
    BusinessSegmentId.RETAIL,
    BusinessSegmentId.FULL_CIRCLE,
    BusinessSegmentId["M&I"],
  ]

  const [initialStateError, setStateErrors] = useState({
    startDate: false,
    endDate: false,
    roleIds: false,
    description: false,
    businessSegmentIds: false,
  })

  const [submit, setSubmit] = useState(false)

  const reducer = (state, action) => {
    switch (action.type) {
      case "startDate":
        return { ...state, startDate: action.value }
      case "endDate":
        return { ...state, endDate: action.value }
      case "description":
        return { ...state, description: action.value }
      case "roleIds":
        return { ...state, roleIds: action.value }
      case "businessSegmentIds":
        return { ...state, businessSegmentIds: action.value }
      case "rolesAll":
        return { ...state, rolesAll: action.value }
      case "businessSegmentAll":
        return { ...state, businessSegmentAll: action.value }
      case "formattedDescription":
        return { ...state, formattedDescription: action.value }
      case "view":
        return { ...state, ...action.value }
      case "clearState":
        return { ...state, ...initialState }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const [dateStatus, setDateStatus] = useState({
    begining: false,
    ending: false,
  })

  const onSelectField = (title, value) => {
    dispatch({ type: title, value: value })
  }

  const openCalender = (title, value) => {
    setDateStatus({
      ...dateStatus,
      [title]: value,
    })
  }

  useEffect(() => {
    if (location?.state) {
      let roles = []
      location.state.value.roleIds.split(",").forEach((ele) => {
        roles = [...roles, parseInt(ele)]
      })
      let businessSegment = []
      location.state.value.businessSegmentIds.split(",").forEach((ele) => {
        businessSegment = [...businessSegment, parseInt(ele)]
      })

      let initialValue = {
        ...location?.state?.value,
        startDate: new Date(location?.state?.value?.startDate),
        endDate: new Date(location?.state?.value?.endDate),
        roleIds: roles,
        businessSegmentIds: businessSegment,
        rolesAll: ROLES_DETAILS.length === roles.length,
        businessSegmentAll: BUSINESS_SEGMENTS.length === businessSegment.length,
      }

      dispatch({ type: "view", value: initialValue })
    }
  }, [location?.state])

  const onSelectCheckBox = (event, title) => {
    let rolesIds, roleId
    //Checking for All selected
    if (event.target.name === "All") {
      if (title === "roleIds") {
        rolesIds = [...ROLES_DETAILS]
        dispatch({ type: "rolesAll", value: event.target.checked })
      } else {
        rolesIds = [...BUSINESS_SEGMENTS]
        dispatch({ type: "businessSegmentAll", value: event.target.checked })
      }
      dispatch({ type: title, value: event.target.checked ? rolesIds : [] })
    } else {
      if (title === "roleIds") {
        roleId = roles[event.target.name]
      } else {
        roleId = BusinessSegmentId[event.target.name]
      }
      rolesIds = [...state[title]]

      //removing the value if already selected
      if (rolesIds.includes(roleId)) {
        let index = rolesIds.indexOf(roleId)
        rolesIds.splice(index, 1)
      } else {
        rolesIds = [...rolesIds, roleId]
      }

      //all values selected then 'All' option is selected
      if (title === "roleIds") {
        dispatch({
          type: "rolesAll",
          value: rolesIds.length === ROLES_DETAILS.length,
        })
      } else {
        dispatch({
          type: "businessSegmentAll",
          value: rolesIds.length === BUSINESS_SEGMENTS.length,
        })
      }
      dispatch({ type: title, value: rolesIds })
    }
  }

  const validateDate = (value) => {
    if (!(value instanceof Date)) {
      return true
    } else {
      return false
    }
  }

  const checkForError = () => {
    let initialError = { ...initialStateError }
    Object.keys(state).forEach((ele) => {
      if (state[ele]?.length === 0) {
        initialError[ele] = true
      } else {
        initialError[ele] = false
      }
    })
    if (validateDate(state["startDate"])) {
      initialError["startDate"] = true
    } else {
      initialError["startDate"] = false
    }
    if (validateDate(state["endDate"])) {
      initialError["endDate"] = true
    } else {
      initialError["endDate"] = false
    }
    if (
      state["description"].trim().length > 1500 ||
      state["description"].trim().length === 0
    ) {
      initialError["description"] = true
    } else {
      initialError["description"] = false
    }
    setStateErrors(initialError)
    if (Object.values(initialError).includes(true)) {
      return false
    } else {
      return true
    }
  }

  const onSubmit = async (value) => {
    setSubmit(true)

    if (checkForError()) {
      let res
      try {
        let {
          rolesAll,
          businessSegmentAll,
          status,
          totalCount,
          duration,
          ...body
        } = state
        body = {
          ...body,
          startDate: dfns.format(state.startDate, "yyyy-MM-dd"),
          endDate: dfns.format(state.endDate, "yyyy-MM-dd"),
          description: state.description.trim(),
          roleIds: state.roleIds.join(),
          businessSegmentIds: state.businessSegmentIds.join(),
        }
        if (location?.state?.editState) {
          body["id"] = location?.state?.value?.id
          res = await api.pa_updateAnnouncemet({
            loading_key: "create_announcement",
            deleteAnnouncement: value?.isdelete,
            ...body,
          })

          if (res === "Success") {
            dispatch({ type: "clearState" })
            setSubmit(false)
            history.push({
              pathname: "/admin/announcementcheckout",
              state: {
                ...state,
                deleteAnnouncement: value?.isdelete,
              },
            })
          }
        } else {
          res = await api.p_createannouncements({
            loading_key: "create_announcement",
            ...body,
          })

          if (res === "Created Announcement Successfully.") {
            dispatch({ type: "clearState" })
            setSubmit(false)
            history.push({
              pathname: "/admin/announcementcheckout",
              state: {
                ...state,
              },
            })
          } else {
            toast.error(res, { autoClose: 3000 })
            setSubmit(false)
          }
        }
      } catch (error) {
        toast.error(res, { autoClose: 3000 })
        setSubmit(false)
      }
    }
  }
  return (
    <Component
      {...props}
      {...{
        goToUserManagement,
        state,
        onSelectField,
        openCalender,
        dateStatus,
        onSubmit,
        onSelectCheckBox,
        initialStateError,
        submit,
        BusinessSegmentId,
        viewState: location?.state?.viewState,
        editState: location?.state?.editState,
      }}
    />
  )
}
export default enhancer
