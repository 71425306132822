import { SET_FILTERS, DELETE_FILTERS } from "./actionType"
import _ from "lodash"

const filters = (state = {}, action) => {
  let newState = {}
  switch (action.type) {
    case SET_FILTERS:
      const st = _.cloneDeep(action.filters)
      newState = { ...state, ...st }
      return newState
    case DELETE_FILTERS:
      return newState
    default:
      return state
  }
}

export default filters
