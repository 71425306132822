import {
  ALL_CITIES,
  ALL_GENERATORS,
  ALL_STATES,
  GENERATORS_LIST,
  GENERATOR_DETAILS,
  RETRIEVE_DOCUMENTS,
  KNOW_YOUR_CSR,
  PROFILE_LIST_REPORT,
  ALL_ER_SERVICE_NAMES,
  PHONE_NUMBER_BY_PROVIDER_NAME,
  GENERATOR_ID_STATUS,
  SHIP_COUNTRY_LIST,
  SHIP_STATE_LIST,
  NAICS_CODE,
  SET_SHOW_DELETE,
  SET_NAICS_CDDETAILS,
  PROFILE_OVERVIEW_LIST,
  PROFILE_OVERVIEW_STATUS,
  MODULE_OVERVIEW_LIST,
  PROFILE_CRETAE_FROM_GENE,
} from "../actionType"
const initialState = {
  generatorList: [],
  searchGeneratorsList: [],
  states: [],
  cities: [],
  generatorDetails: {},
  availabledocDocuments: [],
  showdeleteMessage: false,
  naicsDetails: {},
  moduleOverList: [],
  isProfileFromGenerator: false,
  selectedProfileGenerator: null,
}

const generatorDetails = (state = initialState, action) => {
  let newState = { ...state }
  switch (action.type) {
    case ALL_GENERATORS:
      return {
        ...newState,
        generatorList: action.payload,
      }
    case GENERATORS_LIST:
      return {
        ...newState,
        searchGeneratorsList: action.payload,
      }
    case ALL_STATES:
      return {
        ...newState,
        states: action.payload,
      }
    case ALL_CITIES:
      return {
        ...newState,
        cities: action.payload,
      }
    case GENERATOR_DETAILS:
      return {
        ...newState,
        generatorDetails: action.payload,
      }
    case SET_NAICS_CDDETAILS:
      return {
        ...newState,
        naicsDetails: action.payload,
      }
    case ALL_ER_SERVICE_NAMES:
      return {
        ...newState,
        allErServiceNames: action.payload,
      }
    case SHIP_COUNTRY_LIST:
      return {
        ...newState,
        shipCountryList: action.payload,
      }
    case SHIP_STATE_LIST:
      return {
        ...newState,
        shipStateList: action.payload,
      }
    case PHONE_NUMBER_BY_PROVIDER_NAME:
      return {
        ...newState,
        phoneNumberByProviderName: action.payload,
      }
    case GENERATOR_ID_STATUS:
      return {
        ...newState,
        generatorIdStatus: action.payload,
      }
    case NAICS_CODE:
      return {
        ...newState,
        naicsCode: action.payload,
      }
    case RETRIEVE_DOCUMENTS:
      return {
        ...newState,
        availabledocDocuments: action.payload,
      }
    case KNOW_YOUR_CSR:
      return {
        ...newState,
        knowyourcsr: action.payload,
      }
    case PROFILE_LIST_REPORT:
      return {
        ...newState,
        profilelistreport: action.payload,
      }
    case SET_SHOW_DELETE:
      return {
        ...newState,
        showdeleteMessage: action.payload,
      }
    case PROFILE_OVERVIEW_LIST:
      return {
        ...newState,
        profileOverviewList: action.payload,
      }
    case PROFILE_OVERVIEW_STATUS:
      return {
        ...newState,
        profileOverviewStatus: action.payload,
      }
    case MODULE_OVERVIEW_LIST:
      return {
        ...newState,
        moduleOverList: action.payload,
      }
    case PROFILE_CRETAE_FROM_GENE:
      return {
        ...newState,
        isProfileFromGenerator: action.payload.isProfileFromGenerator,
        selectedProfileGenerator: action.payload.selectedProfileGenerator,
      }
    default:
      return state
  }
}

export default generatorDetails
