// let prev_cur = ["#D56E81", "#519DD9"]
let prev_curs = ["#50A684", "#77C5D4"]

const colors = {
  area_linear_l: prev_curs,
  area_linear: "#ffffff",
  area_monotone: "#ffffff",
  bar_lite: "#ffffff",
  radial: "#ffffff",
  bar: prev_curs,
  area_monotone_l: prev_curs,
  pie: [
    "#BB9E0D",
    "#94969A",
    "#117DA5",
    "#D56E81",
    "#97B5E5",
    "#009CA6",
    "#CEA089",
    "#659E27",
    "#50535A",
  ],
  bar_chart_colors: [
    "#BB9E0D",
    "#94969A",
    "#117DA5",
    "#D56E81",
    "#97B5E5",
    "#009CA6",
    "#CEA089",
    "#659E27",
    "#50535A",
    "#91D6AC",
    "#50A684",
    "#77C5D4",
  ],
}

export default colors
