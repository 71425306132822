import React from "react"
import Image from "shared/HelpImage/index"

function TopProfileReport() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">
        Full Circle Dashboard: Top 10 Profile Report
      </h3>

      <p className="text-justify pl-3 pt-2">
        The Pie chart displays the top 10 profile report by weight for the last
        four available quarter
      </p>
      <Image src="TopProfileReport_Fc.png" />
      <p className="text-justify pl-3 pt-2">
        Click on different shades of the pie chart to display the percentage of
        waste.
      </p>
    </div>
  )
}
export default TopProfileReport
