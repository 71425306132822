import React, { Fragment } from "react"
import enhancer from "./enhancer"
import { FormattedMessage } from "react-intl"
import DocumentsTab from "../DocumentsTab"

function Docprofile(props) {
  let { isTablet } = props
  return (
    <Fragment>
      <div
        className={` ${
          isTablet
            ? "col-sm-12 col-md-12 col-lg-12 pt-3 text-left "
            : "profileDocument pt-3"
        }`}
      >
        <div className="weeklylabel">
          <FormattedMessage id="dashboard.mydocumet" />
        </div>
        <DocumentsTab {...props} />
      </div>
    </Fragment>
  )
}
export default enhancer(Docprofile)
