import {
  SORT_PROFILE_LIST,
  PROFILE_LIST_REPORT,
  ALL_GENERATORS,
  ALL_VENDORS,
  GET_DEFAULT_GENE,
} from "store/reducer/actionType"
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import "./style.scss"
import { getOptions } from "routes/Utilities/util"
import api from "api"
import excel from "exceljs"
import { saveAs } from "file-saver"
import { useFormik } from "formik"
import {
  ProfileListReportsHeaderMapping,
  ProfileListReportsHeaders,
} from "../List/Lists"
import Image from "shared/Image"

let enhancer = (Component) => () => {
  const dispatch = useDispatch()
  let [pageNo, gotoPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const { changeCustomer } = useSelector((state) => state)
  const [firstLoad, setFirstLoad] = useState(true)
  const [isApply, setIsApply] = useState(true)

  const getAllGenerators = async () => {
    try {
      let res = await api.g_getAllGenerators()
      if (!res) return

      dispatch({ type: ALL_GENERATORS, payload: res })
    } catch (error) {}
  }
  const getDefaultGenerator = async () => {
    try {
      let res = await api.g_getDefaultGenerator()
      if (!res) {
        dispatch({ type: GET_DEFAULT_GENE, payload: "" })
      } else {
        dispatch({ type: GET_DEFAULT_GENE, payload: res })
      }
    } catch (error) {}
  }
  const generatorOptions = useSelector(
    (state) => state.generatorDetails.generatorList
  )
  const profilelistreport = useSelector(
    (state) => state.generatorDetails.profilelistreport
  )
  const defaultGenerator = useSelector(
    (state) => state.profileDetails.defaultGenerator
  )

  const vendorCodes = useSelector((state) => state.profileDetails.allVendors)

  useEffect(() => {
    if (!firstLoad) {
      profilelistingreport(pageNo)
    }
  }, [pageNo])

  const profilelistingreport = async (page, isSubmit) => {
    setLoading(true)
    // if (finalvalues.generator === "" || finalvalues.designatedFacility === "") {
    //   setShowErrors(true)
    //   setLoading(false)
    //   return
    // } else {
    //   setShowErrors(false)
    // }
    let req
    if (isSubmit) {
      setIsApply(true)
      req = {
        Status:
          (finalvalues && finalvalues.status && finalvalues.status.v) ||
          "Any Status",
        GeneratorIds: matchGenLength ? "" : finalvalues.generator,
        DesignatedFacility: matchFaciLength
          ? ""
          : finalvalues.designatedFacility,
        UniversalWaste: finalvalues.universalwasteonly,
        index: page || pageNo,
        size: 20,
      }
    } else {
      setIsApply(false)
      req = {
        Status: (payload && payload.status && payload.status.v) || "Any Status",
        GeneratorIds: payload.generator,
        DesignatedFacility: payload.designatedFacility,
        UniversalWaste: payload.universalwasteonly,
        index: page || pageNo,
        size: 20,
      }
    }

    try {
      let res = await api.p_profilelistingreport(req)
      if (!res) return
      dispatch({ type: PROFILE_LIST_REPORT, payload: res })
      setgridView(profilelistreport)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const searchSubmitReport = async () => {
    if (
      selectedGenerator?.map((val) => val.generatorId)?.join(",") === "" ||
      selectedFacility?.map((val) => val.vendorId)?.join(",") === ""
    ) {
      setShowErrors(true)
      setLoading(false)
      return
    } else {
      const finalvalues = {
        status: status,
        generator: matchGenLength
          ? ""
          : selectedGenerator[0] !== undefined
          ? selectedGenerator?.map((val) => val.generatorId)?.join(",")
          : "",
        designatedFacility: matchFaciLength
          ? ""
          : selectedFacility[0] !== undefined
          ? selectedFacility?.map((val) => val.vendorId)?.join(",")
          : "",
        universalwasteonly: fieldValue,
      }
      setPayload(finalvalues)
      setShowErrors(false)
      setFirstLoad(false)
      profilelistingreport(1, true)
    }
  }
  const fetchAllVendorsData = async () => {
    try {
      let res = await api.g_getAllVendors()
      if (!res) return

      dispatch({ type: ALL_VENDORS, payload: res })
    } catch (error) {}
  }

  useEffect(() => {
    fetchAllVendorsData()
  }, [])
  let generatorCodeOpts =
    generatorOptions?.length > 0
      ? getOptions(
          generatorOptions,
          "generatorName",
          "generatorId",
          "label",
          "value"
        )
      : []
  let facilityProcessCodeOpts =
    vendorCodes?.length > 0
      ? getOptions(vendorCodes, "vendorName", "vendorId", "label", "value")
      : []

  // const defaultGen =
  //   generatorOptions?.length !== 0
  //     ? generatorOptions.filter((data) => data.isDefaultGenerator === true)
  //     : []
  // const defaultGenName =
  //   defaultGen.length !== 0 ? defaultGen[0].generatorName : ""
  // const defaultGenId = defaultGen.length !== 0 ? defaultGen[0].generatorId : ""
  // const [selectedGenerator, setSelectedGenerator] = useState([
  //   { label: defaultGenName, value: defaultGenId },
  // ])
  const [selectedGenerator, setSelectedGenerator] = useState([])
  const [loadDefault, setLoadDefault] = useState(false)
  const [selectedFacility, setSelectedFacility] = useState([])
  const [gridView, setgridView] = useState([])
  const sortInfo = useSelector((state) => state.profileDetails.sort)
  const [status, setStatus] = useState({ l: "Any Status", v: "Any Status" })
  const [fieldValue, setFieldValue] = useState(true)
  const [showErrors, setShowErrors] = useState(false)
  const [matchGenLength, setMatchGenLength] = useState(false)
  const [matchFaciLength, setMatchFaciLength] = useState(false)
  const initialsValues = {
    status: "",
    generator: "",
    designatedFacility: "",
    universalwasteonly: true,
  }
  const [payload, setPayload] = useState(initialsValues)
  const finalvalues = {
    status: status,
    generator:
      selectedGenerator[0] !== undefined
        ? selectedGenerator?.map((val) => val.generatorId)?.join(",")
        : "",
    designatedFacility:
      selectedFacility[0] !== undefined
        ? selectedFacility?.map((val) => val.vendorId)?.join(",")
        : "",
    universalwasteonly: fieldValue,
  }
  const { values, handleSubmit } = useFormik({
    initialValues: initialsValues,
    finalvalues: finalvalues,
    onSubmit: (finalvalues) => {},
  })

  const [reportData, setReportData] = useState([])

  const [showSucessModal, setShowSuccessModal] = useState(false)

  useEffect(() => {
    if (generatorCodeOpts?.length > 0 && !loadDefault && defaultGenerator) {
      setSelectedGenerator([
        generatorCodeOpts?.filter(
          (val) => val.generatorId === defaultGenerator
        )?.[0],
      ])
      setLoadDefault(true)
    }
  }, [generatorOptions, selectedGenerator, defaultGenerator])

  const mappedApiData = (data) => {
    if (profilelistreport) {
      return profilelistreport.items.map((item) => {
        let transformedItem = {}
        // eslint-disable-next-line
        for (let apiHeader in data) {
          transformedItem[data[apiHeader]] = item[apiHeader]
        }
        return transformedItem
      })
    }
  }

  useEffect(() => {
    setReportData(mappedApiData(ProfileListReportsHeaderMapping))
  }, [gridView, profilelistreport])

  useEffect(() => {
    setReportData([])
  }, [changeCustomer?.customer?.v])

  const clearFilters = () => {
    gotoPage(1)
    setStatus({ l: "Any Status", v: "Any Status" })
    setSelectedFacility(facilityProcessCodeOpts[0])
    setSelectedGenerator([])
    setFieldValue(true)
    setPayload(initialsValues)
  }

  const getNames = (val) => {
    switch (val) {
      case "customerName":
        return "CUSTOMER NAME"
      case "generatorName":
        return "GENERATOR NAME"
      case "shippingAddressLine1":
        return "ADDRESS1"
      case "address2":
        return "ADDRESS2"
      case "profile":
        return "PROFILE"
      case "profileWasteName":
        return "PROFILE WASTE NAME"
      case "profileStatus":
        return "PROFILE STATUS"
      case "wasteCategory":
        return "WASTE CATEGORY"
      case "profileExpiration":
        return "PROFILE EXPIRATION"
      case "city":
        return "CITY"
      case "state":
        return "STATE"
      case "zip":
        return "ZIP"
      case "profileStartDate":
        return "PROFILE START DATE"
      case "epaId":
        return "EPA ID"
      case "dotId":
        return "DOT ID"
      case "shippingName":
        return "SHIPPING NAME"
      case "nosDescription":
        return "NOS DESCRIPTION"
      case "hazClass":
        return "HAZ CLASS"
      case "packingGroup":
        return "PACKING GROUP"
      case "epaCodes":
        return "EPA CODES"
      case "stateCodes":
        return "STATE CODES"
      case "designatedFacility":
        return "DESIGNATED FACILITY"
      default:
        return ""
    }
  }

  const exportToExcel = async () => {
    const req = {
      Status: (finalvalues && finalvalues.status && finalvalues.status.v) || "",
      GeneratorIds: matchGenLength ? "" : finalvalues.generator,
      DesignatedFacility: matchFaciLength ? "" : finalvalues.designatedFacility,
      UniversalWaste: finalvalues.universalwasteonly,
      index: 0,
      size: 0,
    }
    let res = await api.p_profilelistingreport(req)
    const mappedApiValues = res?.items
    const workbook = new excel.Workbook()
    const worksheet = workbook.addWorksheet("Report")

    if (mappedApiValues.length === 0) {
      return
    }

    const columns = ProfileListReportsHeaders.map((header) => {
      const mappedKey = Object.keys(ProfileListReportsHeaderMapping).find(
        (key) => ProfileListReportsHeaderMapping[key] === header
      )

      return {
        header: getNames(header),
        key: mappedKey || header,
        width: getNames(header).length + 10,
      }
    })

    worksheet.columns = columns

    mappedApiValues.forEach((data) => {
      const rowData = {}
      columns.forEach((column) => {
        let dataValue =
          data[column.key] !== undefined
            ? data[column.key]
            : data[ProfileListReportsHeaderMapping[column.key]]
        if (
          (column.header === "PROFILE START DATE" ||
            column.header === "PROFILE EXPIRATION") &&
          dataValue !== "N/A" &&
          dataValue !== "" &&
          dataValue !== null
        ) {
          dataValue = new Date(dataValue).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
        }

        rowData[column.key] = dataValue ?? ""
      })
      worksheet.addRow(rowData)
    })

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: "application/octet-stream" })
      saveAs(blob, `ProfileListingReport.xlsx`)
    })

    setShowSuccessModal(true)
  }

  const sortArrows = (field) => {
    return (
      <>
        {((sortInfo?.sortField === field && sortInfo.dir === "asc") ||
          sortInfo?.sortField != field) && <Image src="asc_icon_table.svg" />}
        {sortInfo?.sortField === field && sortInfo.dir === "desc" && (
          <Image src="desc_icon_table.svg" />
        )}
      </>
    )
  }

  const sortData = (options, data) => {
    dispatch({
      type: SORT_PROFILE_LIST,
      payload: options,
      data: {
        type: data?.type,
        fieldName: data?.fieldName,
      },
    })
  }

  useEffect(() => {
    getDefaultGenerator()
    getAllGenerators()
  }, [])

  return (
    <Component
      {...{
        selectedGenerator,
        generatorCodeOpts,
        facilityProcessCodeOpts,
        sortData,
        //profilelistreport,
        // profilelistingreport,
        setSelectedGenerator,
        sortArrows,
        exportToExcel,
        handleSubmit,
        values,
        setStatus,
        setFieldValue,
        fieldValue,
        status,
        gridView,
        selectedFacility,
        setSelectedFacility,
        clearFilters,
        reportData,
        showErrors,
        showSucessModal,
        setShowSuccessModal,
        searchSubmitReport,
        loading,
        setLoading,
        setMatchGenLength,
        setMatchFaciLength,
        gotoPage,
        pageNo,
        profilelistreport,
        isApply,
      }}
    />
  )
}

export default enhancer
