import React from "react"
import Loading from "shared/Loading"
import { FormattedMessage } from "react-intl"
import { formattedDate, isRbsCorporateUser } from "shared"

function ServiceDetails(props) {
  let { serviceDetails } = props
  const getColumnSpan =
    isRbsCorporateUser() && serviceDetails.division?.length > 0
      ? "col-3"
      : "col-4"
  return (
    <div className="col-12 ">
      <Loading id="get_serviceDetails">
        <div className="row  headingservice m-0">
          {isRbsCorporateUser() && (
            <div className={`${getColumnSpan} eachservice p-0 `}>
              <label className="m-0">
                Brand Name: {` ` + serviceDetails.division}
              </label>
            </div>
          )}
          <div className={`${getColumnSpan} eachservice p-0 `}>
            <label className="m-0">
              Store Number: {` ` + serviceDetails.storeNumber}
            </label>
          </div>
          <div className={`${getColumnSpan} eachservice p-0 `}>
            <label className="m-0">
              <FormattedMessage id="serviceorder.address" />
              {` ` +
                serviceDetails.storeAddress?.address +
                `, ` +
                serviceDetails.storeAddress?.city +
                `, ` +
                serviceDetails.storeAddress?.state}
            </label>
          </div>
          <div className={`${getColumnSpan} eachservice p-0 `}>
            <label className="m-0">
              <FormattedMessage id="serviceorder.epaid" />
              {` ` + serviceDetails.epaId}
            </label>
          </div>
        </div>

        <div className="row  headingservice m-0 mt-4 secondSection">
          <Loading id="get_serviceDetails">
            <div className="col-3 eachservice">
              <label className="m-0">
                <FormattedMessage id="serviceorder.workorder" />
                {` ` + serviceDetails.workOrderId}
              </label>
            </div>

            <div className="col-3 p-0 eachservice">
              <label className="m-0">
                <FormattedMessage id="serviceorder.servicedate" />
                {` ` + formattedDate(serviceDetails.serviceDate)}
              </label>
            </div>

            <div className="col-3 eachservice">
              <label className="m-0">
                <FormattedMessage id="serviceorder.servicestatus" />
                {` ` + serviceDetails.status}
              </label>
            </div>
            <div className="col-3 p-0 eachserviceLast">
              <label className="m-0">
                <FormattedMessage id="serviceorder.purchaseorder" />
                {` ` + serviceDetails.purchaseOrder == null
                  ? ""
                  : serviceDetails.purchaseOrder}
              </label>
            </div>
          </Loading>
        </div>
      </Loading>
    </div>
  )
}
export default ServiceDetails
