import React from "react"
import Image from "shared/HelpImage/index"
import { isHomeDepot } from "shared"

function HomeOver() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Home Over View</h3>
      {isHomeDepot() ? (
        <div className="img-section">
          <Image
            src="HomeDepotStoreUserHomePageOverView.png"
            alt="Header Bar"
          />
        </div>
      ) : (
        <div className="img-section">
          <Image src="HomePageOverView.png" alt="Header Bar" />
        </div>
      )}

      <p className="text-justify pl-3 pt-2">
        Once logged in, the dashboard is in view.
      </p>
      <p className="text-justify pl-3 pt-2">
        One thing to note is your store number and company name appears in the
        top left-hand side of your screen.
      </p>
      <p className="text-justify pl-3 pt-2">
        The EPA ID and address of your store appears in the blue header.
      </p>
      <div className="img-section">
        <Image src="HomePageServiceDetails.png" alt="Header Bar" />
      </div>
      <p className="text-justify pl-3 pt-3">
        The service details section shows the last service date and the service
        types serviced within the white container.
      </p>
      <p className="text-justify pl-3">
        The section also shows the Service Type, Service Frequency and Next
        Service Date for all service types that will be performed at the store.
      </p>
      <div className="img-section">
        <Image src="HomePageServiceHistory.png" alt="Header Bar" />
      </div>
      <p className="text-justify pl-3">
        Service History section shows all the services that were performed in
        reverse chronological order (last service first).
      </p>
    </div>
  )
}
export default HomeOver
