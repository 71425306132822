import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import Filter from "shared/Filter"
import classnames from "classnames"
import { FormattedMessage } from "react-intl"
import Loading from "shared/Loading"
import Pagination from "shared/Pagination"
import Modal from "shared/Modal"
import Image from "shared/Image"
import CommonTableGrid from "shared/Table"
import { format } from "date-fns"
import "./style.scss"
import documentLibraryHeader from "./constants"
import { formatNumber } from "shared"
function invoices(props) {
  let {
    docData,
    updateFilterData,
    reset,
    pageNo,
    gotoPage,
    submitted,
    isStoreManager,
    downloadData,
  } = props

  let { totalRecords = 0 } = docData

  let dataColumns = []
  docData?.items?.forEach((ele) => {
    let row = [
      {
        className: "invoice-grid-row",
        value: ele.invoiceNumber,
        excelHeading: "Invoice Number",
      },
      {
        className: "invoice-grid-row",
        value: ele.generatorName,
        excelHeading: "Generator Number",
      },
      {
        className: "invoice-grid-row",
        value: ele.orderNumber,
        excelHeading: "Order Number",
      },
      {
        className: "invoice-grid-row",
        value: `${format(new Date(ele.invoiceDate), "LL/dd/yyyy") || "-"}`,
        excelHeading: "Invoice Date",
      },
      {
        className: "invoice-grid-row",
        value: `$ ${formatNumber(ele.invoiceTotal.toFixed(2))}`,
        excelHeading: "Invoice Amount",
      },
      {
        className: "invoice-grid-row",
        value: (
          <div>
            <span>{`${ele.address},`}</span>
            <br />
            {`${ele.city}, ${ele.state}`}
          </div>
        ),
        excelHeading: "Generator Address",
      },
    ]
    dataColumns.push({ data: row })
  })

  return (
    <Fragment>
      <div className="invoice-page">
        <div className={classnames({ storeDocumentLibrary: isStoreManager })}>
          <Filter onSubmit={updateFilterData} Onreset={reset} />
          <div className="col-sm-12 col-md-12 col-lg-12 generatorstatus text-left my-4 heading-download-alignment">
            <div className="m-0 page-heading-label">
              <FormattedMessage id="invoices.heading" />
            </div>
            {/* For future reference */}
            <div className="downloadsec download-label">
              {submitted && (
                <button
                  className={classnames("downloadbtn", {
                    disabled: docData?.items?.length === 0,
                  })}
                  onClick={downloadData}
                >
                  <FormattedMessage id="documentlibrary.download" />
                </button>
              )}
            </div>
          </div>
          <Loading id="invoicies">
            <div className="col-sm-12 col-md-12 col-lg-12 m-0 px-3">
              <CommonTableGrid
                headers={documentLibraryHeader}
                rows={dataColumns}
              />
              {docData?.items?.length === 0 && (
                <div className="pt-2 nodata">
                  {" "}
                  <FormattedMessage id="nodata.records" />
                </div>
              )}
              {totalRecords > 0 && (
                <Pagination
                  {...{
                    pageNo,
                    totalRecords,
                    gotoPage,
                    data: docData,
                    pageSize: 20,
                  }}
                />
              )}
            </div>
          </Loading>
        </div>
        <Modal
          show={false}
          handleClose={() => {}}
          wrapperclass={"glossaryModel"}
        >
          <div className="mt-3 mb-4 ml-2 mr-2 position-relative">
            <header className="glossaryHeading ml-4 mr-4">
              <FormattedMessage id="serviceorder.exceptionglossary" />
            </header>
            <span className="position-absolute closebtnglossary">
              <Image
                className="closeicon"
                src="ic_cancel_black.svg"
                height="14px"
                width="20px"
                // onClick={onClickOK}
              />
            </span>
            <div className="row invoice-header__modal">
              <div className="col col-lg-2">1 of 3</div>
            </div>
            <div className="summarydiv ml-4 mr-4"></div>
          </div>
        </Modal>
      </div>
    </Fragment>
  )
}
export default enhancer(invoices)
