import React from "react"
import enhancer from "./enhancer"
import Loading from "shared/Loading"
import { FormattedMessage } from "react-intl"
import "./style.scss"
import Select from "shared/Select"
import Image from "shared/Image"
import _ from "lodash"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import ComingSoon from "shared/ComingSoon"

function AccessSettings(props) {
  let {
    stores,
    onSelectStore,
    selectedstores,
    onCloseStore,
    onClickUpdateSwitch,
    storeselected,
    onCloseSuccess,
    success,
    onClickReset,
    allChecked,
    categories,
    updateSelectedCategories,
    updateSendMeAllUpdate,
    onClickSavePreferences,
    isPreviewCustomer,
  } = props

  const history = useHistory()

  return (
    <div className="row m-0">
      <div className="col-12  p-0">
        <Loading id="accesssettings">
          <div className="text-left alertsettings">
            <div className="heading">
              <span
                className="alerts c-pointer"
                onClick={() => history.push("/alerts")}
              >
                <FormattedMessage id="back" />
              </span>{" "}
              | <span className="myalerts"> My Alerts</span>
            </div>
            <div className="text-left myalertsection">
              <FormattedMessage id="alertview.myAlerts" />
            </div>
            <div className={`row successful ${!success ? "d-none" : ""}`}>
              <div className="tick col-1 pl-4">
                <Image
                  className="ticks"
                  src="ic_whitetick.svg"
                  height="30px"
                  width="30px"
                  alt=""
                />
              </div>
              <div className="text col-10 pl-0">
                Your alert preferences have been saved successfully!
              </div>

              <div className="closes col-1 pr-0 text-right pl-5">
                <Image
                  className="closeds"
                  src="ic_close_white.svg"
                  height="14px"
                  width="14px"
                  alt=""
                  onClick={() => onCloseSuccess()}
                />
              </div>
            </div>
            <div className="notifytext">
              Let us know your notification preference
            </div>
            {categories.length > 1 && (
              <div className="text-left ">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={allChecked}
                    onChange={updateSendMeAllUpdate}
                  />
                  <span className="slider round"></span>
                </label>
                <span className="switchlabel">Send me all updates</span>
              </div>
            )}
            {categories.length > 1 && (
              <div className="text-left notifytext ">
                Send updates only for selected categories
              </div>
            )}
            <div className="row mb-3">
              {categories.map((d, i) => (
                <div className="col-12 mb-3" key={i}>
                  <label
                    className={classnames("check-container", {
                      "c_gray ": d.v === "partial_no_ship" && isPreviewCustomer,
                    })}
                  >
                    <span className="tooltips">
                      <ComingSoon />
                    </span>
                    <input
                      type="checkbox"
                      onChange={() => updateSelectedCategories(d, i)}
                      checked={
                        d.v === "partial_no_ship" && isPreviewCustomer
                          ? false
                          : d.check
                      }
                      value={d.v}
                      disabled={d.v === "partial_no_ship" && isPreviewCustomer}
                    />
                    <span
                      className={classnames("checkmark", {
                        "not-spak-alert":
                          d.v === "partial_no_ship" && isPreviewCustomer,
                      })}
                    />
                  </label>
                  <div
                    className={classnames("category-desc", {
                      c_gray: d.v === "partial_no_ship" && isPreviewCustomer,
                    })}
                  >
                    <span>{d.l}</span>
                    <br />
                    {d.desc}
                  </div>
                </div>
              ))}
            </div>
            <div className="text-left pt-1 d-none">
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              <span className="switchlabel">All store updates</span>
            </div>

            <div className="text-left pt-2 d-none">
              <label className="switch">
                <input
                  type="checkbox"
                  onClick={(e) => onClickUpdateSwitch(e)}
                  name="selectedstore"
                />
                <span className="slider round"></span>
              </label>
              <span className="switchlabel">
                Updates for only selected stores
              </span>
            </div>
            <div
              className={`row alertpreferredstore ml-0 mt-2  ${
                storeselected ? "d-block" : "d-none"
              }`}
            >
              <Select
                label="Add preferred store"
                options={stores}
                onSelect={(i) => onSelectStore(i)}
                labelKey="l"
                className=""
              />
            </div>

            <div
              className={classnames("selectedstore", {
                empty: _.isEmpty(selectedstores),
                "d-block": storeselected,
                "d-none": !storeselected,
              })}
            >
              {!_.isEmpty(selectedstores) &&
                selectedstores.map((d, index) => (
                  <span className="store" key={index}>
                    {d.l}
                    <Image
                      className="cancel"
                      src="ic_cancel_black.svg"
                      height="10px"
                      width="10px"
                      alt=""
                      onClick={() => onCloseStore(index)}
                    />
                  </span>
                ))}
            </div>
            <div className="alertsettingssavebtn pt-4 save-preferences">
              <button
                onClick={() => {
                  onClickSavePreferences()
                }}
                className="savebtn"
              >
                Save preferences
              </button>
              <p
                className="resetbtn"
                onClick={() => {
                  onClickReset()
                }}
              >
                Reset to default
              </p>
            </div>
          </div>
        </Loading>
      </div>
    </div>
  )
}
export default enhancer(AccessSettings)
