import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useHarsco, setCustomerData, isRbsCorporateUser } from "shared"
import api from "api"
import useDebounce from "shared/debounce"
import Image from "shared/Image"
import { format } from "date-fns"
import { documentLibraryHeader } from "../Components/constants"
import { useSelector } from "react-redux"
import { roles } from "config"
import { Workbook } from "exceljs"
import { saveAs } from "file-saver"

let enhancer = (Component) => (props) => {
  let history = useHistory()
  const { profile } = useHarsco()

  const [error, setError] = useState({
    customerId: false,
  })
  const [open, setOpen] = useState({
    customerId: false,
    userType: false,
  })
  const [headers, setHeaders] = useState([])

  const [fields, setFields] = useState({
    searchText: "",
    customerId: "",
    userType: 0,
    sortColumn: "",
    sortOrder: "",
    page: {
      pageIndex: 1,
      pageSize: 20,
    },
  })
  const { customerList } = useSelector((state) => state)
  const [userType, setUserTypes] = useState([])
  const [customers, setCustomers] = useState([])

  const [customerSearch, setSearch] = useState()

  const [submitted, setSubmit] = useState(false)
  const [submitLink, setSubmitLink] = useState(false)
  let [pageNo, gotoPage] = useState(1)
  const [searchData, setSearchData] = useState({})

  const [columnData, setColumnData] = useState([])
  const [headerData, setHeaderData] = useState([])
  const [sortName, setSortName] = useState("")
  const [cleanEarthStatus, setCleanEarthStatus] = useState(false)
  const [customerDetails, setCustomerDetails] = useState([])

  const user = useSelector((state) => state.profile)

  const backToUserManagement = () => {
    if (!cleanEarthStatus && !submitted) {
      history.push("/admin")
    } else {
      let initialValue = {
        searchText: "",
        customerId: "",
        userType: 0,
        sortColumn: "",
        sortOrder: "",
        page: {
          pageIndex: 1,
          pageSize: 20,
        },
      }

      //setting all default values
      setFields({ ...initialValue })
      setCleanEarthStatus(false)
      setSubmit(false)
      setSubmitLink(false)
      setSearchData({})
      gotoPage(1)
    }
  }

  let columnHeader = (data, clsName = "") => {
    return (
      <div>
        <span>{data.headerTitle}</span>
        <Image
          className={clsName}
          src={data.sort ? "asc_icon_table.svg" : "desc_icon_table.svg"}
          onClick={() => changeSortColum(data)}
        />
      </div>
    )
  }

  useEffect(() => {
    ;(async () => {
      getInitialCustomer()
      await getUserTypes()
    })()
  }, [])

  useEffect(() => {
    if (submitLink) {
      searchAllCEAdmins()
    } else if (submitted) {
      searchUser()
    }
  }, [pageNo])

  useEffect(() => {
    ;(async () => {
      if (customerSearch?.length > 2) {
        let res = await api.g_onboardedcustomers({
          searchText: customerSearch,
          loading_key: "customer_search",
        })
        let customer = []
        customer = setCustomerData(res)
        setCustomers(customer)
      } else if (customerSearch?.length == 0) {
        getInitialCustomer()
      }
    })()
  }, [useDebounce(customerSearch, 1000)])

  const getInitialCustomer = async () => {
    let customer = [...customerList]
    setCustomers(customer)
  }

  const getUserTypes = async () => {
    let res = await api.g_userTypes()
    if (res) {
      let roles = []
      roles = res.map((ele) => {
        return { l: ele?.description, v: ele?.roleId }
      })
      setUserTypes(roles)
    }
  }

  const onInputChange = (event) => {
    let initialValue = { ...fields, [event.target.name]: event.target.value }
    setFields(initialValue)
    if (Object.values(error).includes(true)) {
      checkForErrors(initialValue)
    }
  }

  const onSelectField = (title, value) => {
    setOpen((initialValue) => ({
      ...initialValue,
      [title]: value,
    }))
    if (!open?.customerId) {
      getInitialCustomer()
    }
  }

  const handleSearchChange = (e) => {
    if (e.target.name === "customerSearch") setSearch(e.target.value)
  }

  const onSelectValue = (name, value) => {
    let initialValue = { ...fields, [name]: value }
    setFields(initialValue)
    if (Object.values(error).includes(true)) {
      checkForErrors(initialValue)
    }
  }

  const navigateToEditUser = (user) => {
    history.push("/admin/edituser", {
      userData: user,
    })
  }

  const checkEdit = (rowData) => {
    if (
      user.roleId === roles.PORTAL_ADMIN &&
      rowData.roleId === roles.PORTAL_ADMIN
    ) {
      return true
    } else if (
      user.roleId !== roles.PORTAL_ADMIN &&
      (rowData.roleId === roles.CE_ADMIN ||
        rowData.roleId === roles.CUSTOMER_ADMIN)
    ) {
      return true
    }
    return false
  }
  const setRows = (data) => {
    let columnData = []
    const emailCounts = {}
    const accountNames = {}
    data.items.forEach((item) => {
      const { email, accountNumber, accountName } = item
      emailCounts[email] = (emailCounts[email] || 0) + 1
      if (accountNames[email] === undefined) accountNames[email] = ""
      accountNames[email] += `${accountName} - ${accountNumber}\n`
    })
    const customerDetails =
      fields.userType?.v !== undefined && fields.customerId.l != undefined
        ? data.items
        : (fields.userType?.v !== undefined &&
            data.items.filter((item) => item.userType === "Enterprise User")
              .length > 0) ||
          (fields.searchText !== "" &&
            data.items.filter((item) => item.userType === "Enterprise User")
              .length > 0)
        ? data.items.filter((item) => item.isEntDefaultCustomer)
        : data.items
    setCustomerDetails(customerDetails)
    customerDetails.forEach((ele) => {
      let row = {
        data: [
          {
            value: ele?.firstName || "",
            className: "user-grid-cell",
          },
          {
            value: ele?.lastName || "",
            className: "user-grid-cell",
          },
          {
            value: (
              <div className="word-break-tabs" title={ele?.email}>
                {ele?.email || ""}
              </div>
            ),
            className: "user-grid-cell",
          },
          {
            value: ele?.accountNumber || "",
            className: "user-grid-cell",
          },
          {
            value: ele?.accountName || "",
            className: "user-grid-cell",
          },
          {
            value:
              isRbsCorporateUser() ||
              fields.userType?.v == 8 ||
              fields.customerId.v == "8315839"
                ? ele?.division
                : "",
            className: "user-grid-cell",
          },
          {
            value:
              ele?.userType == "Enterprise User" && emailCounts[ele.email] > 1
                ? ele?.userType + emailCounts[ele.email]
                : ele?.userType || "",
            className: "user-grid-cell",
          },
          {
            value:
              ele.status.charAt(0).toUpperCase() +
              ele.status.substring(1, ele.status.length),
            className: "user-grid-cell",
          },
          {
            value: (
              <div
                className="word-break-tabs"
                title={ele?.createdBy ? ele?.createdBy : "System Created"}
              >
                {ele?.createdBy ? ele?.createdBy : "System Created"}
              </div>
            ),
            className: "user-grid-cell",
          },
          {
            value: ele?.lastLogin
              ? `${format(new Date(ele?.lastLogin), "LL/dd/yyyy") || "-"}`
              : "N/A",
            className: "user-grid-cell",
          },
          {
            value: (
              <div>
                <Image
                  className={`view-icon ${checkEdit(ele) && "prevent-clics"}`}
                  src="ic_user_edit_icon.svg"
                  height="26px"
                  width="53px"
                  alt=""
                  onClick={() => navigateToEditUser(ele)}
                />
              </div>
            ),
            className: "user-grid-cell",
          },
          {
            value: accountNames[ele.email],
            className: "New",
          },
        ],
      }
      let removedDivisionrow = {
        data: [
          {
            value: ele?.firstName || "",
            className: "user-grid-cell",
          },
          {
            value: ele?.lastName || "",
            className: "user-grid-cell",
          },
          {
            value: (
              <div className="word-break-tabs" title={ele?.email}>
                {ele?.email || ""}
              </div>
            ),
            className: "user-grid-cell",
          },
          {
            value: ele?.accountNumber || "",
            className: "user-grid-cell",
          },
          {
            value: ele?.accountName || "",
            className: "user-grid-cell",
          },
          {
            value:
              ele?.userType == "Enterprise User" && emailCounts[ele.email] > 1
                ? ele?.userType + emailCounts[ele.email]
                : ele?.userType || "",
            className: "user-grid-cell",
          },
          {
            value:
              ele.status.charAt(0).toUpperCase() +
              ele.status.substring(1, ele.status.length),
            className: "user-grid-cell",
          },
          {
            value: (
              <div
                className="word-break-tabs"
                title={ele?.createdBy ? ele?.createdBy : "System Created"}
              >
                {ele?.createdBy ? ele?.createdBy : "System Created"}
              </div>
            ),
            className: "user-grid-cell",
          },
          {
            value: ele?.lastLogin
              ? `${format(new Date(ele?.lastLogin), "LL/dd/yyyy") || "-"}`
              : "N/A",
            className: "user-grid-cell",
          },
          {
            value: (
              <div>
                <Image
                  className={`view-icon ${checkEdit(ele) && "prevent-clics"}`}
                  src="ic_user_edit_icon.svg"
                  height="26px"
                  width="53px"
                  alt=""
                  onClick={() => navigateToEditUser(ele)}
                />
              </div>
            ),
            className: "user-grid-cell",
          },
          {
            value: accountNames[ele.email],
            className: "New",
          },
        ],
      }

      let val =
        isRbsCorporateUser() ||
        fields.userType?.v == 8 ||
        fields.customerId.v == "8315839"
          ? row
          : removedDivisionrow
      columnData = [...columnData, val]
    })
    setColumnData(columnData)

    setSearchData(data)
  }

  let xlsHeader = (header) => {
    header.eachCell({ includeEmpty: true }, (cell) => {
      let thinB = { style: "thin" }
      cell.alignment = { wrapText: true }
      cell.font = {
        bold: true,
      }
      cell.border = {
        bottom: thinB,
        top: thinB,
        right: thinB,
        left: thinB,
      }
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "d9e8f4" },
      }
    })
  }

  const createOuterBorder = (
    worksheet,
    start = { row: 1, col: 1 },
    end = { row: 1, col: 1 },
    borderWidth = "medium"
  ) => {
    const borderStyle = {
      style: borderWidth,
    }
    for (let i = start.row; i <= end.row; i++) {
      const leftBorderCell = worksheet.getCell(i, start.col)
      const rightBorderCell = worksheet.getCell(i, end.col)
      leftBorderCell.border = {
        ...leftBorderCell.border,
        left: borderStyle,
      }
      rightBorderCell.border = {
        ...rightBorderCell.border,
        right: borderStyle,
      }
    }
    for (let i = start.col; i <= end.col; i++) {
      const topBorderCell = worksheet.getCell(start.row, i)
      const bottomBorderCell = worksheet.getCell(end.row, i)
      topBorderCell.border = {
        ...topBorderCell.border,
        top: borderStyle,
      }
      bottomBorderCell.border = {
        ...bottomBorderCell.border,
        bottom: borderStyle,
      }
    }
  }

  const getAppliedFields = (data) => {
    let values = []
    const filterKeys = {
      searchText: "Last Name or Work Email",
      customerId: "Account",
      roleId: "User Type",
    }

    // eslint-disable-next-line
    for (const key in data) {
      let obj = {}
      if (filterKeys[key]) {
        obj = {
          label: `${filterKeys[key]}:`,
          value: Array.isArray(data[key])
            ? data[key].join(",")
            : key === "customerId"
            ? fields.customerId.l
            : key === "roleId"
            ? data["roleId"] === 0
              ? "CE ADMIN"
              : fields.userType.l
            : data[key],
        }
        obj.isNextColumn = true
        values.push(obj)
      }
    }
    return values
  }

  const setRequestHistoryDetails = (workbook, dataFields) => {
    const worksheet2 = workbook.addWorksheet("Details")
    worksheet2.properties.defaultColWidth = 40
    worksheet2.columns = [
      { accessor: "label", sticky: "left", key: "label" },
      { accessor: "value", sticky: "left", key: "value" },
    ]
    const { firstName, lastName } = user

    const appliedFilters = getAppliedFields(dataFields)
    const generated_dt = format(new Date(), "MMM dd, yyyy HH:mm z")

    const data = [
      { label: "USER LIST", title: true },
      { label: "" },
      { label: "Filters Applied:" },
      ...appliedFilters,
      { label: "" },
      {
        label: "Generated By:",
        value: ` ${firstName} ${lastName}`,
        isNextColumn: true,
      },
      { label: "Generated Date: ", value: generated_dt, isNextColumn: true },
      { label: "" },
      { label: "Clean Earth Connect+" },
      { label: "" },
    ]
    data.forEach((d, i) => {
      const row = worksheet2.addRow(d)
      if (d.isNextColumn) worksheet2.mergeCells(`B${i + 1}:E${i + 1}`)
      else worksheet2.mergeCells(`A${i + 1}:E${i + 1}`)
      if (d.title) xlsHeader(row)
    })
    createOuterBorder(
      worksheet2,
      { row: 1, col: 1 },
      { row: data.length, col: 2 },
      "thin"
    )
  }

  const downloadUsers = async () => {
    let filename = `${"User_List"}.xlsx`

    let workbook = new Workbook()
    let worksheet = workbook.addWorksheet("USER LIST")

    let data = {
      searchText: fields.searchText,
      customerId: fields.customerId.v,
      roleId: fields.userType?.v ? fields.userType?.v : 0,
      page: {
        pageIndex: 1,
        pageSize: searchData?.totalRecords,
      },
      sortColumn: "",
      sortOrder: "",
      loading_key: "custom_search",
    }

    let seachDownload = await api.p_searchUsers({
      ...data,
      loading_key: "custom_search",
    })

    if (submitLink) {
      seachDownload = await api.p_getAllCEUsers({
        ...data,
        loading_key: "custom_search",
      })
    } else {
      seachDownload = await api.p_searchUsers({
        ...data,
        loading_key: "custom_search",
      })
    }

    worksheet.properties.defaultColWidth = 40

    let headers = [
      "First Name",
      "Last Name",
      "Work Email",
      "Account Number",
      "Account Name",
      "Brand Name",
      "User Type",
      "Status",
      "Created By",
      "Last Logged In",
    ]

    headers =
      isRbsCorporateUser() ||
      fields.userType?.v == 8 ||
      fields.customerId.v == "8315839"
        ? headers
        : headers.filter((d) => d != "Brand Name")
    let row = worksheet.addRow(headers)
    xlsHeader(row)

    seachDownload.items.forEach((element) => {
      let record = [
        element["firstName"],
        element["lastName"],
        element["email"],
        element["accountNumber"],
        element["accountName"],
        element["division"],
        element["userType"],
        element.status.charAt(0).toUpperCase() +
          element.status.substring(1, element.status.length),
        element?.["createdBy"] ? element?.createdBy : "System Created",
        element?.["lastLogin"]
          ? `${format(new Date(element?.lastLogin), "LL/dd/yyyy") || "-"}`
          : "N/A",
      ]
      record =
        isRbsCorporateUser() ||
        fields.userType?.v == 8 ||
        fields.customerId.v == "8315839"
          ? record
          : record.filter((d) => d != element["division"])
      worksheet.addRow(record)
    })

    setRequestHistoryDetails(workbook, data)
    const buffer = await workbook.xlsx.writeBuffer()
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    const blob = new Blob([buffer], { type: fileType })

    saveAs(blob, filename)
  }

  const checkForErrors = (value) => {
    let initialValue = { ...error }
    if (!value) value = { ...fields }
    if (value?.["customerId"] || value?.["searchText"] || value?.["userType"]) {
      initialValue["customerId"] = false
    } else {
      initialValue["customerId"] = true
    }
    setError(initialValue)
    if (Object.values(initialValue).includes(true)) {
      return true
    } else {
      return false
    }
  }

  const searchUser = async (info) => {
    if (
      fields.searchText === "" &&
      fields.customerId.v === undefined &&
      fields.userType?.v === undefined
    ) {
      let initialValue = {
        searchText: "",
        customerId: "",
        userType: 0,
      }

      //setting all default values
      setFields({ ...initialValue })
      setCleanEarthStatus(false)
      setSubmit(false)
      setSubmitLink(false)
      setSearchData({})
    }
    if (!checkForErrors()) {
      setSubmit(true)
      let data = {
        searchText: fields.searchText,
        customerId: fields.customerId.v,
        roleId: fields.userType?.v ? fields.userType?.v : 0,
        page: {
          pageIndex: pageNo,
          pageSize: 20,
        },
        sortColumn: info?.apiKey ? info.apiKey : fields?.sortColumn,
        sortOrder: info?.hasOwnProperty("sort")
          ? info?.sort
            ? "asc"
            : "desc"
          : fields?.sortOrder,
        loading_key: "custom_search",
      }
      let documentLibraryvalues =
        isRbsCorporateUser() ||
        fields.userType?.v == 8 ||
        fields.customerId.v == "8315839"
          ? documentLibraryHeader
          : documentLibraryHeader.filter((d) => d.headerTitle != "Brand Name")

      let headers = []
      documentLibraryvalues.forEach((ele) => {
        if (ele.hasOwnProperty("sort")) {
          ele.value = columnHeader(
            ele,
            sortName === ele.headerTitle
              ? "selected-sort-column"
              : "un-select-sort"
          )
        } else {
          ele.value = (
            <div>
              <span>{ele.headerTitle}</span>
            </div>
          )
        }
        headers = [...headers, ele]
      })
      setHeaders(headers)

      let searchData
      if (submitLink) {
        searchData = await api.p_getAllCEUsers({
          ...data,
          loading_key: "custom_search",
        })
      } else {
        searchData = await api.p_searchUsers({
          ...data,
          loading_key: "custom_search",
        })
      }
      if (searchData) {
        setRows(searchData)
      }
    }
  }

  const clearAllData = () => {
    setFields({
      searchText: "",
      customerId: "",
      userType: 0,
      sortColumn: "",
      sortOrder: "",
      page: {
        pageIndex: pageNo,
        pageSize: 20,
      },
    })
    setError({
      customerId: false,
    })
    setCleanEarthStatus(true)
    searchAllCEAdmins()
  }

  const searchAllCEAdmins = async (info) => {
    let documentLibraryvalues = documentLibraryHeader.filter(
      (d) => d.headerTitle != "Brand Name"
    )

    let headers = []
    documentLibraryvalues.forEach((ele) => {
      if (ele.hasOwnProperty("sort")) {
        ele.value = columnHeader(
          ele,
          sortName === ele.headerTitle
            ? "selected-sort-column"
            : "un-select-sort"
        )
      } else {
        ele.value = (
          <div>
            <span>{ele.headerTitle}</span>
          </div>
        )
      }
      headers = [...headers, ele]
    })
    setHeaders(headers)
    setSubmit(true)
    setSubmitLink(true)
    let data = {
      searchText: "",
      customerId: "",
      roleId: 0,
      page: { pageIndex: pageNo, pageSize: 20 },
      sortColumn: info?.apiKey ? info.apiKey : fields?.sortColumn,
      sortOrder: info?.hasOwnProperty("sort")
        ? info?.sort
          ? "asc"
          : "desc"
        : fields?.sortOrder,
    }

    let searchData = await api.p_getAllCEUsers({
      ...data,
      loading_key: "custom_search",
    })
    if (searchData) {
      setRows(searchData)
    }
  }

  const changeSortColum = (info) => {
    let header = [],
      sortColumn = ""
    documentLibraryHeader.forEach((ele) => {
      if (ele.hasOwnProperty("sort")) {
        if (ele.headerTitle === info.headerTitle) {
          ele.sort = !ele.sort
          sortColumn = info.headerTitle
          setSortName(sortColumn)
        } else {
          ele.sort = false
        }
        ele.value = columnHeader("un-select-sort")
      }
      header = [...header, ele]
    })
    setHeaderData(header)
    let initialValue = {
      ...fields,
      sortColumn: info?.apiKey,
      sortOrder: info?.hasOwnProperty("sort")
        ? info?.sort
          ? "asc"
          : "desc"
        : "",
    }
    setFields(initialValue)
    if (
      initialValue.customerId === "" &&
      initialValue.searchText === "" &&
      initialValue.sortColumn !== "" &&
      initialValue.userType === 0 &&
      (initialValue.sortOrder === "asc" || initialValue.sortOrder === "desc") &&
      !submitLink &&
      initialValue.page != []
    ) {
      setSubmitLink(true)
    }
    if (submitLink) {
      searchAllCEAdmins(info)
    } else {
      searchUser(info)
    }
  }

  const resetCustomerId = () => {
    setFields((prevFields) => ({
      ...prevFields,
      customerId: "",
    }))
  }

  const resetUserType = () => {
    setFields((prevFields) => ({
      ...prevFields,
      userType: 0,
    }))
  }

  const resetOnSearch = () => {
    let header = []
    let initialValue = {
      ...fields,
      sortColumn: "",
      sortOrder: "",
    }
    setSubmitLink(false)
    setFields(initialValue)
    setSortName("")
    documentLibraryHeader.forEach((ele) => {
      if (ele.hasOwnProperty("sort")) {
        ele.sort = false
        ele.value = columnHeader("un-select-sort")
      }
      header = [...header, ele]
    })
    setHeaderData(header)
  }

  return (
    <Component
      {...props}
      {...{
        backToUserManagement,
        profile,
        customers,
        onInputChange,
        fields,
        onSelectField,
        onSelectValue,
        open,
        setOpen,
        handleSearchChange,
        searchUser,
        columnData,
        searchData,
        gotoPage,
        error,
        headerData,
        headers,
        submitted,
        userType,
        downloadUsers,
        resetOnSearch,
        clearAllData,
        roles,
        submitLink,
        user,
        cleanEarthStatus,
        resetCustomerId,
        resetUserType,
        customerDetails,
      }}
    />
  )
}

export default enhancer
