import { SET_CHANGE_CUSTOMER } from "./actionType"

const changeCustomer = (state = {}, action) => {
  switch (action.type) {
    case SET_CHANGE_CUSTOMER:
      let newState = {
        ...state,
        status: action.status,
        customer: action.customer_name,
        source: action.source,
        store: action.store,
      }
      return newState
    default:
      return state
  }
}

export default changeCustomer
