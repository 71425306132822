/* eslint react/style-prop-object: "off" */
import enhancer from "./enhancer"
import Loading from "shared/Loading"
import { FormattedMessage, FormattedNumber } from "react-intl"
import { TonsToMetricConverter } from "shared/TonsToMetricConverter"

let QuartersOverview = (props) => {
  let { overViewData, PeriodText, is_tonsConversion_updated, tResult } = props

  return (
    <Loading id="quarter-overview">
      <div className="row weekly-overview">
        <div className="col-sm-12 col-md-12 col-lg-12 text-left pr-0 mb-2">
          <label className=" w-60 weeklylabel ">
            <FormattedMessage id="dashboard.qaurtersOverview" />
          </label>
          <span className="small weekly-sublabel pl-2 dt_upper">
            {PeriodText}
          </span>
          {tResult?.totalWeight && overViewData ? (
            <div className="row justify-content-between mr-0">
              {tResult?.totalWeight && (
                <div className="col pr-0" key={0}>
                  <div
                    className={`tile new-${0} pl-2 d-flex flex-column justify-content-around noCursor`}
                  >
                    <div className="labelname d-flex h-100">
                      <div className="w-60">{"Total Weights Processed"}</div>
                      {/* {<div className="w-40">{0}%</div>} */}
                    </div>
                    <div className="tileQty nomax-height">
                      {!isNaN(tResult?.totalWeight) ? (
                        <FormattedNumber
                          value={
                            is_tonsConversion_updated
                              ? Math.trunc(
                                  TonsToMetricConverter(tResult?.totalWeight)
                                )
                              : Math.trunc(tResult?.totalWeight)
                          }
                          notation="standard"
                        />
                      ) : (
                        "0"
                      )}
                      <sup className="ml-2">
                        {is_tonsConversion_updated ? "mt" : "tons"}
                      </sup>
                    </div>
                  </div>
                </div>
              )}
              {overViewData &&
                overViewData.map((d, i) => (
                  <div className="col pr-0" key={i + 1}>
                    <div
                      className={`tile new-${
                        i + 1
                      } pl-2 d-flex flex-column justify-content-around noCursor`}
                    >
                      <div className="labelname d-flex h-100">
                        <div className="w-60">{d?.treatmentType}</div>
                        {i + 1 > 0 && (
                          <div className="w-40">
                            {!isNaN(d?.percentage)
                              ? Number(d?.percentage).toFixed(2)
                              : "0.00"}
                            %
                          </div>
                        )}
                      </div>
                      <div className="tileQty nomax-height">
                        {!isNaN(d?.netWeight) ? (
                          <FormattedNumber
                            value={
                              is_tonsConversion_updated
                                ? Math.trunc(
                                    TonsToMetricConverter(d?.netWeight)
                                  )
                                : Math.trunc(d?.netWeight)
                            }
                            notation="standard"
                          />
                        ) : (
                          "0"
                        )}
                        <sup className="ml-2">
                          {is_tonsConversion_updated ? "mt" : "tons"}
                        </sup>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            tResult?.totalWeight &&
            tResult?.totalWeight !== 0 && (
              <div className="col pr-0 height-150">
                <div className="loader"></div>
              </div>
            )
          )}
        </div>
      </div>
    </Loading>
  )
}

export default enhancer(QuartersOverview)
