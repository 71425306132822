import React, { Fragment, useEffect, useState, useRef } from "react"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import Select from "shared/Select"
import { getOptions } from "routes/Utilities/util"
import documentsenhancer from "./documentsenhancer"
import { useSelector } from "react-redux"
import { Form, Formik } from "formik"
import DocumentsList from "./DocumentsList"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import AlertModal from "./AlertModal"
import Input from "routes/Admin/Components/Input"
import { toast } from "react-toastify"

function Documents(props) {
  let {
    files,
    setFiles,
    onSubmit,
    formik,
    downloadDocument,
    showAlert,
    setShowAlert,
    handleAlerts,
    seekingStep,
    showError,
    setShowError,
    backAction,
    cancelAction,
    onSubmitOnly,
    deleteDocument,
    loader,
    selectedDocType,
    setSelectedDocType,
    genNameforView,
  } = props
  const ref = useRef()
  const refupload = useRef()

  const { type } = useParams()
  // const fileInputRef = useRef(null)

  const docTypeCodes = useSelector(
    (state) => state.profileDetails.allDocumentTypes
  )

  const [fileName, setFileName] = useState(files && files.name)

  useEffect(() => {
    setFiles(files)
    setFileName(files && files.name)
  }, [files])

  // useEffect(() => {
  //   if (availDocuments?.length > 0) {
  //     setUploadedDocs(availDocuments);
  //   }
  // }, [availDocuments]);

  let docTypeOpts = getOptions(
    docTypeCodes,
    "documentTypeDescription",
    "documentTypeId"
  )

  docTypeOpts?.sort((a, b) =>
    a.documentTypeDescription.localeCompare(b.documentTypeDescription)
  )

  const handleFileUpload = () => {
    const input = refupload.current
    if (input) {
      input.click()
    }
    // console.log("button click: ", input)
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    const maxFileSize = 10 * 1024 * 1024
    if (file && file.size > maxFileSize) {
      setFiles(null)
      const errmsg = "File size exceeds 10MB. Please select a smaller file."
      toast.error(errmsg, { autoClose: 3000 })
    } else {
      setFiles(file)
      setFileName(file && file.name)
    }
  }

  const handleUpload = () => {
    if (selectedDocType?.documentTypeId) {
      onSubmit(selectedDocType)
    }
    setSelectedDocType({ l: "Select", v: 0 })
    setFiles(null)
    refupload.current.value = ""
  }
  const currentProfile = useSelector(
    (state) => state.profileDetails?.currentProfile
  )
  const generatorOptions = useSelector(
    (state) => state.generatorDetails.generatorList
  )

  const getDefaultGeneratorName = (id) => {
    return (
      generatorOptions?.filter((val) => val.generatorId === id)?.[0]
        ?.generatorName || ""
    )
  }

  useEffect(() => {
    if (showAlert) {
      ref.current.click()
    }
  }, [showAlert])

  return (
    <Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={formik?.values}
        {...formik}
      >
        {({ values, setValues, errors, touched, setFieldValue, isValid }) => {
          return (
            <Form>
              <div
                className="dsp-flx col-sm-12 col-md-12 col-lg-12 addi-info doc-info generatorstatus text-left ml-4 py-4"
                style={{
                  pointerEvents: type === "viewprofile" ? "none" : "auto",
                }}
              >
                <div className="m-0 labelgenerator myprofile">
                  <FormattedMessage id="profile.documentsTitle" />
                </div>
                <div className="form-input-block col-sm-4">
                  <span className="label">
                    <FormattedMessage id="profile.generator" />
                  </span>
                  <Input
                    value={
                      getDefaultGeneratorName(currentProfile?.generatorId) ||
                      genNameforView
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div
                className="ml-4"
                style={{
                  textAlign: "left",
                  pointerEvents: type === "viewprofile" ? "none" : "auto",
                }}
              >
                {"What type of Files are you uploading?"}
              </div>
              {type !== "viewprofile" && (
                <>
                  <div className="col-sm-12 col-md-12 col-lg-12 text-left py-2 m-b-2 ml-4 position-relative">
                    <div className="row m-0" style={{ alignItems: "center" }}>
                      <div className="col-sm-4 ">
                        <div className="form-input-block doc-info mb-dropdown mni-style">
                          <span className="label">
                            <FormattedMessage id="profile.documents.selecttype" />
                          </span>
                          <Select
                            name={"documentType"}
                            labelkey={"l"}
                            className={`sort-dropdown ${
                              selectedDocType?.v == 0
                                ? "mni-selected-dropdown"
                                : ""
                            }`}
                            options={docTypeOpts?.filter(
                              (val) =>
                                val.documentTypeId != 9 &&
                                val.documentTypeId != 27
                            )}
                            selected={selectedDocType}
                            disabled={type === "viewprofile"}
                            onSelect={setSelectedDocType}
                          />
                        </div>
                      </div>
                      {/* {selectedDocType?.documentTypeId && ( */}
                      <div className="col-sm-6">
                        <div className="col-sm-12 col-md-12 col-lg-12 text-left py-2 m-b-2 position-relative ">
                          <div className="form-input-block">
                            {/* <span className="label">
                              <FormattedMessage id="profile.generatorCertification.chooseFile" />
                              <span style={{ color: "red" }}>{" *"}</span>
                            </span> */}
                            <input
                              className="cancel-button"
                              ref={refupload}
                              type="file"
                              accept=".xls, .xlsx, image/png, image/jpeg, .pdf,.doc,.docx,application/msword,image/gif,.tif"
                              placeholder="profile.documents.selectbutton"
                              id="inputFile"
                              // value={files}
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                              disabled={!selectedDocType?.documentTypeId}
                            />
                            <div className="d-flex">
                              <div>
                                <button
                                  type="button"
                                  className={`${
                                    !selectedDocType?.documentTypeId
                                      ? "green-button disabledBg"
                                      : "cancel-button"
                                  }`}
                                  //className="cancel-button"
                                  htmlFor={"inputFile"}
                                  disabled={!selectedDocType?.documentTypeId}
                                  onClick={(e) => handleFileUpload(e)}
                                >
                                  <FormattedMessage id="generator.createGenerator.browse" />
                                </button>
                              </div>
                              <div
                                className="ml-4 mt-2"
                                style={{
                                  color: "#0092BC",
                                }}
                              >
                                {fileName ? fileName : "NO FILE CHOSEN"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* )} */}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 text-left ml-4 position-relative">
                    <div className="row m-0" style={{ alignItems: "center" }}>
                      <div className="col-sm-4 "></div>
                      <div className="col-sm-6">
                        <div className="col-sm-12 col-md-12 col-lg-12 text-left py-0 m-b-2 position-relative ">
                          <div
                            className="px-2 text-justify"
                            style={{ fontSize: "small" }}
                          >
                            {
                              "(Upload .Pdf, .doc, .jpg, .gif, .tif, .xls file types only)"
                            }
                          </div>
                          <div
                            className="px-2 text-justify"
                            style={{ fontSize: "small" }}
                          >
                            {"Choose your file to upload (max size 10 MB)"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {type !== "viewprofile" && (
                <div className="col-sm-12 col-md-12 col-lg-12 text-left px-4 ml-4 position-relative">
                  <button
                    type="button"
                    ref={ref}
                    className={`green-button ${!files ? "disabledBg" : ""}`}
                    //className="green-button"
                    disabled={!files}
                    onClick={handleUpload}
                  >
                    <FormattedMessage id="profile.documents.upload" />
                  </button>
                </div>
              )}
              {/* <Loading id="documents"> */}
              <DocumentsList
                downloadDocument={downloadDocument}
                deleteDocument={deleteDocument}
                loader={loader}
              />
              {/* Back, Save, Cancel Actions */}
              {type !== "viewprofile" && (
                <div className="row m-0 new-row-block mt-4">
                  <div className="col-sm-10 col-md-12 col-lg-12 text-left pr-0 mt-4 ml-4">
                    <div className="row m-0 float-left">
                      <button
                        type="button"
                        className={`cancel-button`}
                        onClick={() => backAction()}
                      >
                        Back
                      </button>
                    </div>
                    <div className="row m-0 float-right m-r-2r">
                      <button
                        type="button"
                        // ref={ref}
                        className={`green-button`}
                        onClick={() => onSubmitOnly()}
                      >
                        Save & Continue
                      </button>
                    </div>
                    <div className="row m-0 float-right m-r-2r">
                      <button
                        type="button"
                        className="cancel-button"
                        onClick={() => cancelAction()}
                      >
                        Cancel
                      </button>
                    </div>
                    <span className="float-right text-red m-r-2r mt-2">
                      {`*Required Fields`}
                    </span>
                  </div>
                </div>
              )}
              <AlertModal
                showError={showError}
                setShowError={setShowError}
                seekingStep={seekingStep}
                submitAction={() =>
                  handleAlerts(values, errors, isValid, seekingStep)
                }
                showAlert={showAlert}
                setShowAlert={setShowAlert}
              />
            </Form>
          )
        }}
      </Formik>
      {/* </Loading> */}
    </Fragment>
  )
}

export default documentsenhancer(Documents)
