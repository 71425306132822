import React, { Fragment } from "react"
import enhancer from "./enhancer"
import classnames from "classnames"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import { useHistory } from "react-router-dom"
import Calender from "shared/Calender"
import Modal from "shared/Modal"
import _ from "lodash"
import Loading from "shared/Loading"
import { Formik, Form, FieldArray } from "formik"
import { Field, Select, Error } from "shared/Formik"
import Image from "shared/Image"
import { isRbsCorporateUser } from "shared"

function NewScheduleRequest(props) {
  let {
    formik,
    initialValues,
    stores,
    brand,
    requestTypeOpts,
    requestServiceTypeOpts,
    serviceIssueOpts,
    addsuppliesOpts,
    updateCalenderConfig,
    dates,
    dateFormat,
    reportServices,
    requestType,
    storeAddress,
    toggleModalAddSupplies,
    customShowAddSupplies,
    confirmAddSupplies,
    editRequestSupplies,
    isActive,
    type,
    suppliesQty,
    confirmSupplies,
    validateCC,
    isSuppliesText,
    isEstimatedDt,
    navigateToEdit,
    getupdatedStores,
    resetstore,
    selectedStore,
    isBrandSelected,
    handleCheckboxChange,
  } = props
  let history = useHistory()
  return (
    <Fragment>
      <div className=" requestServiceBlock">
        <Loading id="requestservice">
          <Formik {...formik} initialValues={initialValues}>
            {({ values: { store, supplies, division } }) => (
              <Form>
                <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left mt-4">
                  {history.location?.state?.from == "myschedule" ? (
                    <div
                      className="sub-title-link"
                      onClick={() => history.push("/myschedule")}
                    >
                      <i className="arrow left"></i>
                      <FormattedMessage id="back" />
                    </div>
                  ) : (
                    <div
                      className="sub-title-link"
                      onClick={() => history.push("/requestservice")}
                    >
                      <i className="arrow left"></i>
                      <FormattedMessage id="back" />
                    </div>
                  )}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left  mb-3">
                  <div className="m-0 labelgenerator">
                    <FormattedMessage id={`requestservice.${type}`} />
                    {type == "new_schedule_request" && (
                      <span className="noErRequest">
                        <FormattedMessage id="requestservicenewschedule.noERrequest" />
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left">
                  <div className="m-0 main-title">
                    <FormattedMessage id="requestservicenewschedule.contactinfo" />
                    <span>
                      <FormattedMessage id="requestservicenewschedule.allfield" />
                    </span>
                  </div>
                </div>
                <div className="row m-0 px-4 new-row-block">
                  <div className="col-sm-3 col-md-3 col-lg-3 text-left p-0">
                    <div className="row m-0">
                      <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0">
                        <div className="form-input-block">
                          <span className="label">
                            <FormattedMessage id="requestservicenewschedule.contactname" />
                          </span>
                          <Field
                            type="text"
                            className="input-box contact-name"
                            name="name"
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3 text-left p-0">
                    <div className="row m-0">
                      <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0">
                        <div className="form-input-block">
                          <span className="label">
                            <FormattedMessage id="requestservicenewschedule.contactphone" />
                          </span>
                          <Field
                            mask="+1 999-999-9999"
                            className="input-box"
                            name="phone"
                            cols="3"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3 text-left p-0">
                    <div className="row m-0">
                      <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0">
                        <div className="form-input-block">
                          <span className="label">
                            <FormattedMessage id="requestservicenewschedule.email" />
                          </span>
                          <Field
                            type="email"
                            className="input-box email"
                            name="email"
                            cols="3"
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 px-4 new-row-block">
                  <div className="col-5 text-left p-0">
                    <div className="row m-0">
                      <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0">
                        <div className="form-input-block">
                          <span className="label">
                            <FormattedMessage id="requestservicenewschedule.cc" />
                          </span>
                          <Field
                            validate={validateCC}
                            placeholder="person@mail.com; person2@mail.com"
                            type="text"
                            className="input-box"
                            name="additional_email"
                            title="Please add any additional recipient’s email to be cced"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left">
                  <div className="m-0 main-title">
                    <FormattedMessage id="requestservicenewschedule.requestdetails" />
                    <span>
                      <FormattedMessage id="requestservicenewschedule.allmandatoryfield" />
                    </span>
                  </div>
                </div>
                {isRbsCorporateUser() && (
                  <div className="row m-0 px-4 store-selection pd-top">
                    <div className={`col-sm-4 col-md-4 col-lg-4 text-left p-0`}>
                      <Select
                        options={brand}
                        name="division"
                        className="store-dropdown"
                        labelKey="l"
                        label="Brands*"
                        onselectchanged={getupdatedStores}
                        hasSearch
                      />
                    </div>
                  </div>
                )}

                <div className="row m-0 px-4 store-selection pd-top">
                  <div
                    className={`col-sm-4 col-md-4 col-lg-4 text-left p-0 mb-3 ${
                      history.location?.state?.from == "myschedule" ||
                      (isRbsCorporateUser() &&
                        !isBrandSelected &&
                        history.location?.state?.from != "myschedule")
                        ? "storedisable"
                        : ""
                    }`}
                  >
                    <Select
                      options={stores}
                      name="store"
                      className="store-dropdown"
                      labelKey="l"
                      label="Store*"
                      onselectchanged={getupdatedStores}
                      resetstore={resetstore}
                      hasSearch
                    />
                  </div>

                  <div className="col-sm-4 col-md-4 col-lg-4 text-left p-0">
                    {selectedStore && (
                      <div className="store-title">
                        <FormattedMessage id="requestservicenewschedule.storelabel" />
                        <div className="store-subtitle">
                          <FormattedMessage
                            id="requestservicenewschedule.storeaddress"
                            values={{ address: storeAddress(selectedStore) }}
                          />{" "}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {isActive("report_issue") && (
                  <div>
                    <div className="row m-0 px-4 mb-4 new-row-block">
                      <div className="col-sm-4 col-md-4 col-lg-4 text-left p-0">
                        <div className="row ml-0 mr-0 pd-top">
                          <Select
                            options={serviceIssueOpts}
                            name="service_issue"
                            className="store-dropdown supplies"
                            labelKey="l"
                            label="Service Issue*"
                            selected={reportServices}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row m-0 px-4 new-row-block">
                      <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0">
                        <div className="row m-0">
                          <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0">
                            <div className="label-title">
                              <FormattedMessage id="requestservicenewschedule.details" />
                            </div>
                            <Field
                              type="textarea"
                              className="textAreaMultiline instruction detail"
                              placeholder="Please add details for your service request in maximum 500 characters…"
                              rows="8"
                              cols="65"
                              name="details"
                              customShowAddSupplies={navigateToEdit}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isActive("change_request") && (
                  <div className="editorder-sec">
                    <div className="row m-0 px-4 new-row-block pd-top">
                      <div className="col-sm-5 col-md-5 col-lg-5 text-left p-0 show-content">
                        <div className="label-title">
                          <span className="main-title">
                            <FormattedMessage id="requestservicenewschedule.datetimeduration" />{" "}
                          </span>

                          {dateFormat()}
                        </div>
                        <div className="row m-0">
                          <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0 service-checkbox">
                            <Calender
                              {...{
                                dates,
                                updateCalenderConfig,
                                isEstimatedDt,
                              }}
                              disable="past"
                              persist="true"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-7 col-md-7 col-lg-7 text-left p-0">
                        <div className="row m-0">
                          <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0">
                            <div className="ml-3 label-title">
                              <FormattedMessage id="requestservicenewschedule.details" />
                            </div>
                            <Field
                              type="textarea"
                              className="textAreaMultiline instruction detail"
                              placeholder="Please add details for your service request in maximum 500 characters…"
                              rows="8"
                              cols="65"
                              name="details"
                              customShowAddSupplies={navigateToEdit}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isActive("new_schedule_request") && (
                  <div>
                    <div className="row m-0 px-4 mb-4 new-row-block">
                      <div className="col-sm-4 col-md-4 col-lg-4 text-left p-0">
                        <div className="row ml-0 mr-0 requestType pd-top">
                          <Select
                            options={requestTypeOpts}
                            name="request_type"
                            className="store-dropdown newrequest"
                            labelKey="l"
                            label="Request type*"
                            selected={requestType}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row m-0 px-4 new-row-block">
                      <div className="col-sm-4 col-md-4 col-lg-4 text-left p-0 show-content">
                        <div className="row m-0">
                          <div className="col-sm-8 col-md-8 col-lg-8 text-left p-0 service-checkbox">
                            <div className="label-title">
                              <FormattedMessage id="requestservicenewschedule.servicetype" />
                            </div>
                            <div className="label-title">
                              {requestServiceTypeOpts.length == 0 &&
                              brand.length != 0 ? (
                                <FormattedMessage id="requestservicenewschedule.Noservices" />
                              ) : (
                                <FormattedMessage id="requestservicenewschedule.checkallservices" />
                              )}{" "}
                            </div>
                            <Loading id="servicetype_search">
                              <div className="service-type-list">
                                {requestServiceTypeOpts.map((d, i) => (
                                  <label className="check-container" key={d.l}>
                                    {d.l}
                                    <Field
                                      type="checkbox"
                                      name="service_type"
                                      className="check-container"
                                      id={i}
                                      value={d.v}
                                      checked={d.checked}
                                      onChange={handleCheckboxChange}
                                    />
                                  </label>
                                ))}
                              </div>
                            </Loading>
                            <Error name="service_type" />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-8 col-md-8 col-lg-8 text-left p-0">
                        <div className="row m-0">
                          <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0">
                            <div className="label-title">
                              <FormattedMessage id="requestservicenewschedule.instruction" />
                            </div>
                            <Field
                              type="textarea"
                              className="textAreaMultiline instruction detail"
                              placeholder="Please add details for your service request in maximum 500 characters…"
                              rows="8"
                              cols="65"
                              name="details"
                              customShowAddSupplies={navigateToEdit}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isActive("request_supplies") && (
                  <div>
                    <div className="row m-0 px-4 new-row-block pd-top">
                      <div className="col-sm-4 col-md-4 col-lg-4 text-left p-0 show-content">
                        <div className="label-title">
                          <FormattedMessage id="requestservicenewschedule.supplies" />
                        </div>
                        <FieldArray
                          name="supplies"
                          render={(ah) => {
                            let opts = addsuppliesOpts
                            return (
                              <div className="row m-0">
                                <div className="col-sm-12 col-md-12 col-lg-12 mt-2 p-0">
                                  <Error name="supplies" />

                                  {supplies &&
                                    supplies.map((d, index) => (
                                      <div
                                        className="add-item mt-2 mb-3"
                                        key={d.ts}
                                      >
                                        {isSuppliesText && (
                                          <span className="inputs input-supplies">
                                            <Field
                                              name={`supplies[${index}].key`}
                                              id={`supplies_${index}`}
                                              type="text"
                                              className="supplies-qty"
                                              placeholder="Item"
                                              autocomplete="off"
                                            />
                                          </span>
                                        )}
                                        {!isSuppliesText && (
                                          <span className="select-supplies">
                                            <Select
                                              options={opts}
                                              className="store-dropdown supplies ellipsis"
                                              labelKey="l"
                                              label="Select"
                                              name={`supplies[${index}].key`}
                                              id={`supplies_${index}`}
                                              short={true}
                                            />
                                          </span>
                                        )}
                                        <span className="inputs">
                                          <Field
                                            type="text"
                                            className="supplies-qty"
                                            placeholder="Qty"
                                            name={`supplies[${index}].value`}
                                            id={`supplies_${index}`}
                                            autocomplete="off"
                                          />
                                        </span>

                                        <span
                                          className="btn-padding"
                                          onClick={() => {
                                            if (supplies.length == 1) {
                                              ah.remove(index)
                                              ah.push({
                                                key: "",
                                                value: "",
                                                ts: _.now(),
                                              })
                                            } else ah.remove(index)
                                          }}
                                        >
                                          <Image
                                            className=""
                                            src="ic_remove.svg"
                                            height="20px"
                                            width="20px"
                                            alt=""
                                          />
                                        </span>
                                      </div>
                                    ))}
                                  <div className="text-right col-12">
                                    <button
                                      // className="green-button mr-1"
                                      className="add-more-button add-more-link mr-1"
                                      type="button"
                                      onClick={() => {
                                        ah.push({
                                          key: "",
                                          value: "",
                                          ts: _.now(),
                                        })
                                      }}
                                    >
                                      <Image
                                        className="mb-1"
                                        src="ic_add.svg"
                                        height="20px"
                                        width="20px"
                                        alt=""
                                      />{" "}
                                      Add more
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )
                          }}
                        />
                      </div>
                      <div className="col-sm-8 col-md-8 col-lg-8 text-left p-0">
                        <div className="row m-0">
                          <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0">
                            <div className="label-title">
                              <FormattedMessage id="requestservicenewschedule.details" />
                            </div>
                            <Field
                              type="textarea"
                              className="textAreaMultiline instruction detail"
                              placeholder="Please add details for your service request in maximum 500 characters…"
                              rows="8"
                              cols="65"
                              name="details"
                              customShowAddSupplies={navigateToEdit}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className={`row m-0 px-4 new-row-block ${
                    isActive("/editorder") ? "submitbtn" : ""
                  }`}
                >
                  <div className="col-sm-10 col-md-10 col-lg-1- text-left pr-4">
                    <div className="row m-0 float-right">
                      <button
                        className={classnames("green-button", {
                          disabled: false,
                        })}
                      >
                        <FormattedMessage id="requestservicenewschedule.submitrequest" />
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Loading>

        <Modal
          show={customShowAddSupplies}
          handleClose={toggleModalAddSupplies}
          wrapperclass={"addsuppliesconfirmation"}
        >
          <div className="">
            <div className="addsupplies-heading">
              <FormattedMessage id="requestserviceaddsupplies.confirm" />
            </div>
            <div className="bodyaddsupplies">
              <div className="bodyheading">
                You have selected below requests:
              </div>
              <div className="suppliesitem row">
                <div className=" col-6 border-rb border-t">Name</div>
                <div className=" col-2 border-b border-t">Qty</div>
                {!_.isEmpty(suppliesQty) &&
                  suppliesQty.map((a, i) => (
                    <>
                      <div className=" col-6 border-rb" key={i}>
                        {a.key}
                      </div>
                      <div className=" col-2 border-b" key={i}>
                        {a.value}
                      </div>
                    </>
                  ))}
              </div>
              <div className="supplies-store">For store:</div>
              <div className="">
                {confirmSupplies && (
                  <div className="store-subtitle">
                    <FormattedMessage
                      id="requestservicenewschedule.storeaddress"
                      values={{
                        address: `${confirmSupplies.store}, ${storeAddress(
                          confirmSupplies.store
                        )}`,
                      }}
                    />{" "}
                  </div>
                )}
              </div>
            </div>
            <div className="addsupplies-button">
              <button className="confirmRequest" onClick={confirmAddSupplies}>
                <FormattedMessage id="requestserviceaddsupplies.confirbtn" />
              </button>
            </div>
            <div className="addsupplies-editrequest">
              <span onClick={editRequestSupplies}>
                <FormattedMessage id="requestserviceaddsupplies.editrequest" />
              </span>
            </div>
          </div>
        </Modal>
      </div>
    </Fragment>
  )
}
export default enhancer(NewScheduleRequest)
