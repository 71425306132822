import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import Loading from "shared/Loading"
import { Formik, Form } from "formik"
import { Field, Select } from "shared/Formik"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// import DateRangePicker from "shared/DateRangePicker"

function Support(props) {
  let {
    formik,
    initialValues,
    browserList,
    osList,
    deviceList,
    isStoreManager,
    contactOption,
    resetPhoneFeilds,
  } = props

  return (
    <Fragment>
      <div className="requestServiceBlock">
        <Loading id="support_form">
          <Formik {...formik} initialValues={initialValues}>
            {({ values: { isDirectContacted }, ...form }) => (
              <Form>
                <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left mt-3">
                  <div className="m-0 labelgenerator mb-3">
                    <FormattedMessage id="support.title" />
                    <span className="noErRequest">
                      <FormattedMessage id="support.allFields" />
                    </span>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left">
                    <div className="m-0 main-title">
                      <FormattedMessage id="support.contactDetails" /> Phone:{" "}
                      <FormattedMessage id="support.phoneNumber" />
                    </div>
                  </div>
                </div>
                {
                  <div className="row m-0 px-4 new-row-block">
                    <div className="col-sm-3 col-md-3 col-lg-3 text-left p-0">
                      <div className="row m-0">
                        <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0">
                          <div className="form-input-block">
                            <span className="label">
                              <FormattedMessage id="support.contactname" />
                            </span>
                            <Field
                              type="text"
                              className="input-box contact-name"
                              name="Name"
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 text-left p-0">
                      <div className="row m-0">
                        <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0">
                          <div className="form-input-block">
                            <span className="label">
                              <FormattedMessage id="support.company" />
                            </span>
                            <Field
                              type="text"
                              className="input-box contact-name"
                              name="CompanyName"
                              readOnly
                              title={initialValues["CompanyName"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {isStoreManager && (
                      <div className="col-sm-3 col-md-3 col-lg-3 text-left p-0">
                        <div className="row m-0">
                          <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0">
                            <div className="form-input-block">
                              <span className="label">
                                <FormattedMessage id="support.store" />
                              </span>
                              <Field
                                type="text"
                                className="input-box contact-name"
                                name="storeNumber"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-sm-3 col-md-3 col-lg-3 text-left p-0">
                      <div className="row m-0">
                        <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0">
                          <div className="form-input-block">
                            <span className="label">
                              <FormattedMessage id="support.roleName" />
                            </span>
                            <Field
                              type="text"
                              className="input-box contact-name"
                              name="roleName"
                              readOnly
                              title={initialValues["roleName"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {
                  <div className="row m-0 px-4 new-row-block">
                    <div className="col-sm-3 col-md-3 col-lg-3 text-left p-0">
                      <div className="row m-0">
                        <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0">
                          <div className="form-input-block">
                            <span className="label">
                              <FormattedMessage id="support.phone" />
                            </span>
                            <Field
                              mask="+1 999-999-9999"
                              className="input-box contact-name"
                              name="Phone"
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 text-left p-0">
                      <div className="row m-0">
                        <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0">
                          <div className="form-input-block">
                            <span className="label">
                              <FormattedMessage id="support.extension" />
                            </span>
                            <Field
                              mask="999999"
                              className="input-box "
                              name="phoneExtension"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {
                  <div className="row m-0 px-4 new-row-block">
                    <div className="col-sm-3 col-md-3 col-lg-3 text-left p-0">
                      <div className="row m-0">
                        <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0">
                          <div className="form-input-block tooltips">
                            <span className="label tooltips">
                              <FormattedMessage id="support.date" />
                            </span>
                            <Field name="IssueDate">
                              {({ field, form: { setFieldValue } }) => {
                                return (
                                  <DatePicker
                                    {...field}
                                    selected={field.value || null}
                                    onChange={(val) => {
                                      setFieldValue(field.name, val)
                                    }}
                                    onKeyDown={(e) => {
                                      e.preventDefault()
                                    }}
                                    maxDate={new Date()}
                                    dateFormat="MMM dd, yyyy"
                                    className="input-box"
                                  />
                                )
                              }}
                            </Field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <div className="row m-0 px-4 new-row-block">
                  <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0">
                    <div className="row m-0">
                      <div className="col-sm-4 col-md-4 col-lg-4 text-left p-0 mt-3">
                        <div className="pad-left-2">
                          {contactOption.map((d, i) => (
                            <label
                              className="check-container remove-cursor left-negative font-size-13"
                              key={i}
                            >
                              {d.l}
                              <Field
                                type="checkbox"
                                name="isDirectContacted"
                                value={d.v}
                                onVChange={() => resetPhoneFeilds(form)}
                              />
                            </label>
                          ))}
                        </div>
                      </div>
                      <div
                        className="col-sm-4 col-md-4 col-lg-4 text-left p-0 mt-3"
                        style={{ marginLeft: -10 }}
                      >
                        <div className="form-input-block">
                          <span className="label ">
                            <FormattedMessage id="support.directcontactno" />
                            {isDirectContacted?.length > 0 && <span>*</span>}
                          </span>
                          <Field
                            mask="+1 999-999-9999"
                            className={`input-box ${
                              !isDirectContacted?.length ? "contact-name" : ""
                            }`}
                            name="directNumber"
                            disabled={isDirectContacted?.length == 0}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  <div className="row m-0 px-4 new-row-block">
                    <div className="col-sm-6 col-md-6 col-lg-6 text-left p-0">
                      <div className="row m-0">
                        <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0">
                          <div className="form-input-block">
                            <span className="label">
                              <FormattedMessage id="support.issueDescription" />
                            </span>
                            <Field
                              type="textarea"
                              className="inputbox support"
                              name="QueryInDetail"
                              rows="7"
                              cols="7"
                              maxLength="550"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {
                  <div className="row m-0 px-4 new-row-block">
                    <div className="col-sm-6 col-md-6 col-lg-6 text-left p-0">
                      <div className="row m-0">
                        <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0">
                          <div className="form-input-block">
                            <span className="label">
                              <FormattedMessage id="support.urlOfIssue" />
                            </span>
                            <Field
                              type="text"
                              className="input-box support"
                              name="URLAddress"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {
                  <div className="row m-0 px-4 new-row-block">
                    <div className="col-sm-4 col-md-4 col-lg-4 text-left p-0">
                      <div className="row m-0">
                        <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0">
                          <div className="form-input-block">
                            <span className="label">
                              <FormattedMessage id="support.webBrowserName" />
                            </span>
                            <Select
                              options={browserList}
                              name="BrowserName"
                              className="store-dropdown"
                              labelKey="l"
                              label="Browser"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-4 text-left p-0">
                      <div className="row m-0">
                        <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0">
                          <div className="form-input-block">
                            <span className="label">
                              <FormattedMessage id="support.operatingSystemName" />
                            </span>
                            <Select
                              options={osList}
                              name="OperatingSystem"
                              className="store-dropdown"
                              labelKey="l"
                              label="OS"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-4 text-left p-0">
                      <div className="row m-0">
                        <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0">
                          <div className="form-input-block">
                            <span className="label">
                              <FormattedMessage id="support.yourDeviceName" />
                            </span>
                            <Select
                              options={deviceList}
                              name="DeviceName"
                              className="store-dropdown"
                              labelKey="l"
                              label="Device"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row m-0 px-4 new-row-block submit_request">
                      <div className="col-sm-10 col-md-10 col-lg-12 text-left pr-4">
                        <div className="row m-0 float-right">
                          <button type="submit" className="green-button">
                            <FormattedMessage id="support.submit" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </Form>
            )}
          </Formik>
        </Loading>
      </div>
    </Fragment>
  )
}
export default enhancer(Support)
