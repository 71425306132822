import { useEffect, useState, useRef, useMemo } from "react"
import * as msal from "@azure/msal-browser"
import { useDispatch } from "react-redux"
import { persistor } from "store"
import { individualConfig } from "./config/individual"
import { corporateConfig } from "./config/corporate"
import { syncUserProfile, updateUserLastLogin } from "shared"
import { sendGtagEvent } from "shared/gtag"
import { trackCustomEvent } from "config/appInsights"

const individualInstance = new msal.PublicClientApplication(individualConfig)
const corporateInstance = new msal.PublicClientApplication(corporateConfig)

const { EventType, InteractionStatus, EventMessageUtils } = msal

export const getInstance = () => {
  if (localStorage.userType == "b2b") return corporateInstance
  return individualInstance
}

export const useMsal = () => {
  const [accounts, setAccounts] = useState([])
  const [inProgress, setInProgress] = useState(InteractionStatus.Startup)
  const inProgressRef = useRef(inProgress)
  const dispatch = useDispatch()

  const logger = useMemo(() => {
    return getInstance().getLogger()
  }, [getInstance()])

  useEffect(() => {
    const callbackId = getInstance().addEventCallback((message) => {
      const status = EventMessageUtils.getInteractionStatusFromEvent(
        message,
        inProgressRef.current
      )
      if (status !== null) {
        setInProgress(status)
        inProgressRef.current = status
      }

      switch (message.eventType) {
        case EventType.ACCOUNT_ADDED:
        case EventType.ACCOUNT_REMOVED:
        case EventType.LOGIN_SUCCESS:
        case EventType.SSO_SILENT_SUCCESS:
        case EventType.HANDLE_REDIRECT_END:
        case EventType.LOGIN_FAILURE:
        case EventType.SSO_SILENT_FAILURE:
        case EventType.LOGOUT_END:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_FAILURE:
          const currentAccounts = getInstance().getAllAccounts()
          if (!accountArraysAreEqual(currentAccounts, accounts)) {
            setAccounts(currentAccounts)
            logger.info("MsalProvider - updating account state")
          } else {
            logger.info("MsalProvider - no account changes")
          }
          break

        default:
          console.log("") // eslint-disable-line
      }
    })

    logger.verbose(
      `MsalProvider - Registered event callback with id: ${callbackId}`
    )

    return () => {
      if (callbackId) {
        logger.verbose(`MsalProvider - Removing event callback ${callbackId}`)
        getInstance().removeEventCallback(callbackId)
      }
    }
  }, [getInstance(), accounts, logger])

  useEffect(() => {
    ;(async () => {
      if (!accounts[0]) return
      let response = await acquireToken(accounts[0])
      let { accessToken, idToken, account } = response
      localStorage.idToken = idToken
      const data = await syncUserProfile()
      if (data) {
        const { email, customer_name, isStoreLevelUser } = data
        const role = isStoreLevelUser ? "StoreManager" : "Admin"
        window.gtag("set", { user_id: email, customer: customer_name, role })
        sendGtagEvent("login")
      }
      let user = { accessToken, idToken, account }
      dispatch({ type: "SET_USER", user })

      const loading_key = "login_status"
      dispatch({ type: "SET_LOADING_STATUS", loading_key, status: false })

      await updateUserLastLogin(data.email)

      trackCustomizeEvent()
    })()
  }, [accounts])
}

const trackCustomizeEvent = () => {
  const idTokenClaim = JSON.parse(JSON.parse(localStorage["persist:root"]).user)
  trackCustomEvent({
    name: "UserLoginInfo",
    properties: idTokenClaim,
  })
}

export const loginRequest = {
  scopes: [`openid`, `profile`],
}

export const handleRedirectPromise = async () => {
  return getInstance()
    .handleRedirectPromise()
    .then()
    .catch((error) => {
      console.log(error) // eslint-disable-line
    })
}
export const individualSSOLogin = async (brandname) => {
  var domainhint = brandname + ".com"
  localStorage.userType = "b2c"
  return getInstance().loginRedirect({
    ...loginRequest,
    loginHint: "",
    extraQueryParameters: { domain_hint: domainhint },
  })
}
export const individualLogin = async (email) => {
  let emailContents = email.split("@")
  localStorage.userType = "b2c"
  return getInstance().loginRedirect({
    ...loginRequest,
    loginHint: email,
    extraQueryParameters: { domain_hint: emailContents[1] },
  })
}

export const corporateLogin = async () => {
  localStorage.userType = "b2b"
  return getInstance().loginRedirect(loginRequest)
}

export const acquireToken = (account) => {
  return getInstance().acquireTokenSilent({
    ...loginRequest,
    account: account,
  })
}

export const logOut = async (redirectUri = "/logout") => {
  persistor.purge()
  delete localStorage.idToken
  localStorage.clear()
  sessionStorage.clear()
  await clearIndexedDB()

  return getInstance().logoutRedirect({
    postLogoutRedirectUri: redirectUri,
  })
}

export function accountArraysAreEqual(arrayA, arrayB) {
  if (arrayA.length !== arrayB.length) {
    return false
  }

  const comparisonArray = [...arrayB]

  return arrayA.every((elementA) => {
    const elementB = comparisonArray.shift()
    if (!elementA || !elementB) {
      return false
    }

    return (
      elementA.homeAccountId === elementB.homeAccountId &&
      elementA.localAccountId === elementB.localAccountId &&
      elementA.username === elementB.username
    )
  })
}

export const clearIndexedDB = async () => {
  const { REACT_APP_UI_VERSION, REACT_APP_API_VERSION } = process.env
  await indexedDB.deleteDatabase("clean-earth")
  localStorage.ui_version = REACT_APP_UI_VERSION
  localStorage.api_version = REACT_APP_API_VERSION
}
