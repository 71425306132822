import React from "react"
import Image from "shared/HelpImage/index"

function ReportGenerator() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Report Generator</h3>
      <p className="text-justify pl-3 pt-2">
        The report Generator “tab” will take you to three report options.
        Generator Status Report, Waste Shipment Summary Report, and the
        Exception Report
      </p>
      <p className="text-justify pl-3 pt-2">
        <p>
          <b>The Generator Status Report</b> – shows the total hazardous waste
          received, and the waste is converted into pounds.{" "}
        </p>
        <p>
          <b>The Waste Shipment Summary Report</b> – shows the information from
          pick ups. The waste information such as profiles, codes, and weight
          information converted into pounds, transportation information, and
          TSDF information.{" "}
        </p>
        <p>
          <b>The Exception report</b> – shows the exceptions that were reported
          while performing any pick ups.{" "}
        </p>
      </p>

      <div className="img-section">
        <Image className="" src="reportGenerator.png" alt="Report Generator" />
      </div>
    </div>
  )
}
export default ReportGenerator
