import React from "react"
import "./Modal.scss"
// import { RoundButton } from "../Formik"

const Modal = ({
  // handleClose,
  show,
  children,
  // closeIcon,
  wrapperclass,
  // showCloseIcon,
}) => {
  document.body.style.overflowY = show ? "hidden" : "auto"
  const showHideClassName = show ? "modal display-block" : "modal display-none"
  // const times = <FontAwesomeIcon size={"1x"} icon="times" />
  // const designModeOff = <FontAwesomeIcon size={"2x"} icon="compress" />

  return (
    <div className={`${showHideClassName} zIndextop`}>
      <section className={`modal-main ${wrapperclass}`}>
        <div className="close-btn-position">
          {/* {showCloseIcon ? null : (
            <RoundButton
              onClick={() => {
                handleClose()
              }}
              btnClass={"btn btn-button-secondary-normal shadow"}
              icon={closeIcon ? designModeOff : times}
            />
          )} */}
        </div>

        <div style={{ overflowY: "auto", height: "100%", overflowX: "hidden" }}>
          {show && children}
        </div>
      </section>
    </div>
  )
}

export default Modal
