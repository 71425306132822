import React from "react"
import { useHarsco } from "shared"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    const { isStoreManager } = useHarsco()

    return (
      <Component
        {...props}
        {...{
          isStoreManager,
        }}
      />
    )
  }

export default enhancer
