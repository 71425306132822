// import React from "react"
// import classnames from "classnames"
// import _ from "lodash"
import "./style.scss"

let Radio = (props) => {
  let { name, value, label, isChecked, onChange } = props
  return (
    <div>
      <input
        id="radioCustomColor"
        type="radio"
        name={name}
        value={value}
        label={label}
        checked={isChecked}
        onChange={onChange}
      />
    </div>
  )
}

export default Radio
