import React from "react"
import Image from "shared/HelpImage/index"

function Logout() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Log Out</h3>
      <p className="text-justify pl-3 pt-2">
        <p>
          There are two ways to log out of the site. One is to click on the{" "}
          <Image src="logoutIcon.png" alt="Logout" /> icon on the header bar, or
          click on the Log out menu option on the left hand side of the screen.{" "}
        </p>
        <p>
          Once the user has logged out. A Message will be displayed to show the
          user they have logged out all the way. The option to log back in is
          conveniently located on the Login Again button.
        </p>
      </p>
      <Image src="logout.png" alt="logout image" />
    </div>
  )
}
export default Logout
