import React from "react"
import { useHistory, useLocation } from "react-router-dom"

let enhancer = (Component) => (props) => {
  let history = useHistory()
  let location = useLocation()

  let createUserService = location.state
  let createFlag = location?.createFlag

  const onReturnToUserAdminClick = (e) => {
    history.push("/admin")
  }
  const onCreateAnotherNewUserClick = (e) => {
    history.push("/admin/manageannouncements")
  }
  const formatDescription = createUserService.formattedDescription.replace(
    /(<([^>]+)>)/gi,
    ""
  )

  return (
    <Component
      {...props}
      {...{
        onReturnToUserAdminClick,
        onCreateAnotherNewUserClick,
        createUserService,
        formatDescription,
        createFlag,
      }}
    />
  )
}

export default enhancer
