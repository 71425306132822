import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import classnames from "classnames"
import Image from "shared/Image"
import { FormattedMessage, useIntl } from "react-intl"
import Loading from "shared/Loading"
import Pagination from "shared/Pagination"

function Myprofile(props) {
  let {
    docData,
    updateDocData,
    downLoadDoc,
    downloadPDF,
    changeAllDocs,
    allDocsCheck,
    pageNo,
    gotoPage,
    submitted,
    viewDoc,
    handleSearchChange,
  } = props

  let { totalRecords = 0 } = docData
  const { formatMessage } = useIntl()

  return (
    <Fragment>
      <div>
        <div className="col-sm-12 col-md-12 col-lg-12 generatorstatus text-left my-4">
          <div className="m-0 labelgenerator myprofile">
            <FormattedMessage id="myprofile.heading" />
          </div>
          <div className="downloadsec">
            <input
              type="text"
              className="mr-3 px-2 search-profile"
              placeholder={formatMessage({
                id: "myprofile.search.placeholder",
              })}
              onChange={handleSearchChange}
            />
            <button
              className={classnames("downloadbtn", {
                disabled:
                  !totalRecords ||
                  docData?.items?.filter((d) => d.selected).length == 0,
              })}
              disabled={
                !totalRecords ||
                docData?.items?.filter((d) => d.selected).length == 0
              }
              onClick={downLoadDoc}
            >
              <FormattedMessage id="myprofile.download" />
            </button>
          </div>
        </div>
        <Loading id="documents">
          <div className="col-sm-12 col-md-12 col-lg-12 m-0 px-3">
            <div className="row tabelHeader m-0">
              <div className="col-sm-2 col-md-2 col-lg-2 p-0 py-2 report-border-right">
                <FormattedMessage id="myprofile.all" />
                {totalRecords > 0 && (
                  <label className="check-container">
                    <input
                      type="checkbox"
                      onChange={changeAllDocs}
                      checked={allDocsCheck}
                    />
                    <span className="checkmark allcheck"></span>
                  </label>
                )}
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 p-0 py-2 report-border-right">
                <FormattedMessage id="myprofile.profileNumber" />
              </div>
              <div className="col-sm-5 col-md-5 col-lg-5 py-2 report-border-right">
                <FormattedMessage id="myprofile.wasteName" />
              </div>

              <div className="col-sm-2 col-md-2 col-lg-2 p-0 py-2 report-border-right">
                <FormattedMessage id="myprofile.view" />
              </div>
            </div>
            {docData?.items?.map((data, i) => (
              <div className="row datatab m-0" key={i}>
                <div
                  className={classnames(
                    "col-sm-2 col-md-2 col-lg-2 m-0 p-0 py-3 report-border-right report-border-left",
                    { pending: !data.document }
                  )}
                >
                  <Image
                    style={{ cursor: "pointer" }}
                    className="pdf-icon"
                    src="ic_pdf.svg"
                    alt=""
                    onClick={() => downloadPDF(data)}
                  />
                  <label className="check-container">
                    <input
                      type="checkbox"
                      onChange={updateDocData(i)}
                      checked={data.selected}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="col-sm-3 col-md-3 col-lg-3 m-0 p-0 py-3 report-border-right">
                  {data?.profileNumber}
                </div>
                <div className="col-sm-5 col-md-5 col-lg-5 py-3 report-border-right">
                  {data?.wasteName}
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2 m-0 p-0 py-3 report-border-right">
                  {data?.document ? (
                    <Image
                      className="view-icon"
                      src="ic_doclibraryview.svg"
                      height="24px"
                      width="24px"
                      alt=""
                      onClick={() => viewDoc(data)}
                    />
                  ) : (
                    <Image
                      className="norecords"
                      src="no_records_bg.svg"
                      height="30px"
                      alt=""
                      title="Document Pending"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          {submitted && totalRecords == 0 && (
            <div className="col-sm-12 col-md-12 col-lg-12 text-center pt-2 nodata">
              <FormattedMessage id="nodata.documents" />
            </div>
          )}
          {totalRecords > 0 && (
            <div className="pl-3">
              <Pagination
                {...{
                  pageNo,
                  totalRecords,
                  gotoPage,
                  pageSize: 20,
                }}
              />
            </div>
          )}
        </Loading>
      </div>
    </Fragment>
  )
}
export default enhancer(Myprofile)
