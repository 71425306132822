import React from "react"
import Image from "shared/HelpImage/index"

function GeneratorStatusReport() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Gen Status Report</h3>
      <div className="img-section pt-4">
        <Image
          className=""
          src="gen_status_report_filters.png"
          alt="GenStatus Image"
        />
      </div>

      <p className="text-justify pl-3 pt-2">
        <p>
          Once in the generator status report, the user can filter by different
          options that are displayed at the top of the page. There are also
          options for the user to select a predefined date criteria or a
          specific date range. Once the user has made their selection click on
          the apply button.
        </p>
        <p>
          This will generate the report based on the selection and show
          information such as the customer&apos;s name, total amount of
          hazardous waste received, the generators EPA id, etc. in a given time
          frame, and broken out by month. The report shows totals per state and
          grand totals depending on the selection the user chose.
        </p>
        <p>
          When choosing States you can choose any number of states, or “All.”
        </p>
        <p>
          When choosing Stores you can choose any number of stores up to a
          maximum of 100, or “All.”{" "}
        </p>
        <p>
          The number in parentheses indicates the number of Divisions or
          Regions, Stores or States there are in the list.
        </p>
        <p>
          You can also choose a date range or one of the predefined queries to
          further limit the number of orders displayed.
        </p>
        <p>
          <i>
            {" "}
            *Please note the green scroll bar on the side and bottom of the
            screens will show the user more fields as well.{" "}
          </i>
        </p>
      </p>
      <div className="img-section pt-4">
        <Image
          className=""
          src="gen_status_report_filters_table.png"
          alt="GenStatus Image"
        />
      </div>
      <p className="text-justify pl-3 pt-2">
        <p>
          When using the green scroll bars, the user moves to the right of the
          spreadsheet to show more columns within the report. The pound
          column(s) have color coding based on the converted pounds generated.
        </p>
        <p>
          If a generator ships in any other weight besides pounds, this report
          converts the weight to pounds and displays the weight in pounds.
        </p>
        <p>
          If a shipment is equal to or over 2200lbs than that pickup will be
          highlighted as <span style={{ backgroundColor: "red" }}>RED</span>{" "}
        </p>
        <p>
          If a shipment is equal to or over 220lbs than that pickup will be
          highlighted as{" "}
          <span style={{ backgroundColor: "yellow" }}>YELLOW</span>{" "}
        </p>
      </p>
      <p className="text-justify pl-3 pt-2">
        <p>
          You can also choose to download the report to your computer by
          pressing the Download button.
        </p>
        <li>
          If the report is up to 40,000 records long it will download as an
          Excel file.
        </li>
        <li>
          If the report is over 40,000 records and up to 200,000 records long it
          will download as a .CSV file.
        </li>
        <li>
          Pop up will be shown if accepts the system acccepts the download
          request
        </li>
        <div className="img-section pt-4">
          <Image
            className=""
            src="large_download_pop_up.png"
            alt="Waste Shipment Image"
          />
        </div>
        <li>
          Once the download process is completed, alert notification is
          triggered in the bell icon where a notification is shown click here to
          download the file{" "}
        </li>
        <div className="img-section pt-4">
          <Image
            className=""
            src="large_download_alert_notification.png"
            alt="Waste Shipment Image"
          />
        </div>
      </p>
    </div>
  )
}
export default GeneratorStatusReport
