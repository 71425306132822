import React from "react"
import Image from "shared/HelpImage/index"

function ProfileReport() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Report Generator: Profile Report</h3>

      <Image src="Profile_Report_Fc_table.png" />

      <p className="text-justify pl-3 pt-2">
        The profile report has multiple columns and informational items. This
        report has customer order number, PO number, generator company,
        generator city, generator address, generator state province code,
        description, order quantity, description and price UOM code.
      </p>
      <p className="text-justify pl-3 pt-2">
        Once the user has applied the filters, the download button displays and
        then the user can click on the download button and pull all this
        information into an excel spreadsheet.
      </p>
      <Image src="Profile_Report_Fc.png" />
    </div>
  )
}
export default ProfileReport
