import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import api from "api"
import { format, isPast, isToday } from "date-fns"
import { MyStoreSortByService } from "config"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    let [activeService, setActiveService] = useState(0)
    let [sortBy, updateSortBy] = useState(MyStoreSortByService[0])
    let [pageNo, gotoPage] = useState(1)

    let history = useHistory()
    const changeRequest = () => {
      history.push("/requestservice/change_request", {
        service: activeService,
        from: "myschedule",
      })
    }

    const serviceHistory = (workOrderAzId, workOrderType) => {
      /* Passing workOrderAzId and workOrderType to use in servicedetailspage */
      const newWindow = window.open(
        `myschedule/serviceorder/${workOrderAzId}/${workOrderType}`,
        "_blank",
        "noopener,noreferrer"
      )
      if (newWindow) newWindow.opener = null
    }
    let { filters, resetMyschedule } = props
    let [serviceData, setServiceData] = useState([])

    let fetchServices = async () => {
      let n_dates = [filters.fiscal_span.from, filters.fiscal_span.to]
      const { states, cities, customers } = filters
      const city = cities
      const myscheduleData = {
        states,
        city,
        generator: customers,
      }
      let scheduleData = await api.p_orderHistory({
        dates: n_dates,
        loading_key: "schedules-services",
        sort: sortBy.v,
        index: pageNo,
        size: 10,
        ...myscheduleData,
      })
      if (!scheduleData) return
      scheduleData.items.forEach((d) => {
        let date = new Date(d.serviceDateTime)
        let { address: addr } = d
        d.formatTime = format(date, "MM/dd/yyyy")
        d.canChange = !(isPast(date) || isToday(date))
        d.canServiceHistory = isPast(date)
        d.title = `${d.storeNumber}, ${addr.address}, \n ${addr.city}, ${addr.state}`
      })
      setActiveService(scheduleData.items[0])
      setServiceData(scheduleData)
    }

    useEffect(() => {
      if (!filters.fiscal_span) return
      gotoPage(-1)
      setTimeout(() => {
        gotoPage(1)
      }, 10)
    }, [filters, sortBy])

    useEffect(() => {
      if (pageNo == -1) return
      fetchServices()
    }, [pageNo])

    useEffect(() => {
      setServiceData([])
    }, [resetMyschedule])

    const updatedupdateSortByOption = (v) => {
      updateSortBy({ l: v.l, v: v.v })
    }
    return (
      <Component
        {...props}
        {...{
          changeRequest,
          serviceData,
          setActiveService,
          activeService,
          sortBy,
          updateSortBy,
          MyStoreSortByService,
          pageNo,
          gotoPage,
          updatedupdateSortByOption,
          serviceHistory,
        }}
      />
    )
  }

export default enhancer
