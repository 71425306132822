import { FormattedMessage, FormattedNumber } from "react-intl"
import enhancer from "./enhancer"
import Loading from "shared/Loading"
import classnames from "classnames"
import ComingSoon from "shared/ComingSoon"

let Cell = (props) => (
  <div
    className={classnames("profileRow mydocument-row colorChange")}
    onClick={() => props?.navigate(props?.name)}
  >
    <span className="number">
      <FormattedNumber value={props?.count} />
    </span>
    {/* text-truncate css class to handle if character length is more*/}
    <label className="days c-p text-truncate" title={props?.name}>
      {props?.name}
    </label>
    <div className="arrow"></div>
    <span className="tooltips coming_soon_style">
      <ComingSoon />
    </span>
  </div>
)

let DocumentsTab = (props) => {
  let { navigate, PeriodText, type, setType, docs } = props
  return (
    <div className="row documentTab">
      <Loading id="documents-tab" height="200">
        <div className="col-5 document-block-left text-left">{PeriodText}</div>
        <div
          className="col-7 document-block-right text-right document-month-tab"
          style={{ display: "flex" }}
        >
          <div className="">
            <button
              onClick={() => setType("month")}
              className={classnames("calender-btn-month", {
                active_btn: type == "month",
              })}
            >
              <FormattedMessage id="dashboard.month" />
            </button>
            <button
              onClick={() => setType("week")}
              className={classnames("calender-btn-week", {
                active_btn: type == "week",
              })}
            >
              <FormattedMessage id="dashboard.week" />
            </button>
            <button
              onClick={() => setType("day")}
              className={classnames("calender-btn-today", {
                active_btn: type == "day",
              })}
            >
              <FormattedMessage id="dashboard.today" />
            </button>
          </div>
          <label
            className="viewallservices m-0 text-right"
            onClick={() => navigate("all")}
          >
            <FormattedMessage id="dashboard.viewall" />
          </label>
        </div>

        {docs?.map((row, i) => (
          <div className="col-sm-6 col-md-6 col-lg-6 text-left" key={i}>
            {row && <Cell {...row} {...{ navigate }} />}
          </div>
        ))}
        {docs[0] && (
          <div className="col-sm-12 col-md-12 col-lg-12 text-center pt-2 d-none">
            <div className="doc-comingSoon">
              <div className="more-doc text-left">
                <FormattedMessage id="dashboard.more_docs_coming_soon" />
              </div>
            </div>
          </div>
        )}
        {!docs[0] && (
          <div className="col-sm-12 col-md-12 col-lg-12 text-center pt-2 nodata fs13">
            <FormattedMessage id="nodata.documents" />
          </div>
        )}
      </Loading>
    </div>
  )
}

export default enhancer(DocumentsTab)
