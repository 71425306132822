import React, { useEffect } from "react"
import { useField, useFormikContext } from "formik"
import classnames from "classnames"
import _ from "lodash"

const Error = (props) => {
  let { name } = props
  const [, meta, helpers] = useField(name)
  const { setTouched } = helpers
  const { isSubmitting } = useFormikContext()

  useEffect(() => {
    if (isSubmitting) setTouched(true)
  }, [isSubmitting])

  let hasError = meta.touched && meta.error && !_.isArray(meta.error)

  return (
    <div className={classnames({ hasError: hasError })}>
      {hasError && <div className="error">{meta.error}</div>}
    </div>
  )
}

export default Error
