import React from "react"
import Image from "shared/HelpImage/index"

function DocumentLibrary() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3 ">Document Library</h3>
      <p className="text-justify pl-3 pt-2">
        <Image
          className="mt-4"
          src="Document_library_filters.png"
          alt="Document Library Image"
        />
        <p>
          The document library lets the user find different types of documents
          (i.e. manifests, job sheets, bill of ladings, etc). The user can
          refine their search or use predefined options. Just click on the down
          arrow in the Predefined Queries field.
        </p>
        <p>
          The library has a quick view option or the user can download the pdf
          document(s).
        </p>
        <p>
          The screen will show the document type, document #, service date, EPA
          id related to the document, the store, city and state.
        </p>
      </p>
      <Image
        className="mt-4 mb-4"
        src="documentLibraryCorporate.png"
        alt="Document Library Image"
      />
      <h3 className="pt-4 text-left pl-3 ">
        Document Library - Search By Filters
      </h3>
      <Image
        className="mt-4"
        src="Document_library_filtertoggle.png"
        alt="Document Library Image"
      />
      <p className="text-justify pl-3 pt-2">
        <p>
          There is an option to search for documents by ‘Filters’. Select from
          the filter fields what options you would like to search by.
        </p>
        <p>
          The Document Library will show results with the following information
          relating to your search: Document #, Document Type, Order #, Service
          Date, EPA ID (related to the document), Store, City and State.{" "}
        </p>
      </p>
      <h3 className="pt-4 text-left pl-3 ">
        Document Library - Search By Document Number
      </h3>
      <Image
        className="mt-4"
        src="Document_library_documentnumber_filtertoggle.png"
        alt="Document Library Image"
      />
      <p className="text-justify pl-3 pt-2">
        There is an option to search for documents by ‘Document number’.
        <br />
        The screen will show the document type and document # options for making
        selection.
      </p>
      <p className="text-justify pl-3 pt-2">
        <p>
          {" "}
          When choosing States you can choose any number of states, or “All.”
        </p>
        <p>
          {" "}
          When choosing Stores you can choose any number of stores up to a
          maximum of 100, or “All.”{" "}
        </p>
        <p>
          {" "}
          When choosing Document Types you can select any number of document
          types, or “All”.
        </p>
        <p>
          {" "}
          The number in parentheses indicates the number of Stores, States or
          Document Types there are in the list.
        </p>
        <p>
          {" "}
          You can also choose a date range or one of the predefined queries to
          further limit the number of orders displayed.
        </p>
      </p>

      <Image
        className="mt-4"
        src="document_library_table_data.png"
        alt="Document Library Image"
      />
      <p className="text-justify pl-3 pt-2">
        <p>
          When displaying the list of chosen documents you can Display a “Quick
          View” of any document by clicking on the “Quick View” icon{" "}
          <Image className="p-l-10" src="ic_doclibraryview.svg" alt="" /> on the
          right or by clicking on the “PDF” icon{" "}
          <Image className="p-l-10" src="ic_pdf.svg" alt="Logo" height="20" />{" "}
          on the left.
        </p>
        <p>
          You can also view details on the order for that document by clicking
          on its Order Number.
          <br />
          <br />
          <span
            className="documentLibraryOrder border"
            style={{ "pointer-events": "none" }}
          >
            {"123456"}
          </span>
        </p>
        <p>
          From there the Order Detail screen will be displayed in a new browser
          tab for the order you chose.
        </p>
        <Image
          className="mt-4"
          src="download_document_order_details.png"
          alt="Document Library Image"
        />
        <p>
          You can close the tab when you are done viewing the screen. You will
          be taken to where you left off in the Document Library screen.
        </p>
        <p>
          You can download documents by selecting one or more using the checkbox
          on the left and then pressing the “Download”{" "}
          <button className="downloadbtn" style={{ "pointer-events": "none" }}>
            {"DOWNLOAD"}
          </button>
        </p>
      </p>
    </div>
  )
}
export default DocumentLibrary
