import React from "react"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts"
import "./style.scss"
import { useIntl } from "react-intl"

const Chart = ({ data, config, height, color }) => {
  const intl = useIntl()
  let { yUnit = "" } = config

  let formatNumber = (n) =>
    `${intl.formatNumber(n, {
      notation: "standard",
    })} ${yUnit}`

  return (
    <BarChart
      width={500}
      height={240}
      data={data}
      margin={{
        top: 10,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="0" vertical={false} />
      <XAxis dataKey="name" />
      <YAxis unit="" tickCount={6} tickFormatter={formatNumber} />
      <Tooltip formatter={formatNumber} />
      <Legend verticalAlign="bottom" align="right" iconType="circle" />
      {config.y.map((d, i) => (
        <Bar
          dataKey={d.key}
          key={d.key}
          name={d.label}
          //stroke={metaColors[i]}
          stackId="a"
          fill={color[i]}
          barSize={25}
        />
      ))}{" "}
      {/* <Bar dataKey="WTE" stackId="a" fill="#D46D80" barSize={25} />
      <Bar dataKey="INCINERATION" stackId="a" fill="#66C4CA" barSize={25} />
      <Bar dataKey="LANDFILL" stackId="a" fill="#BA9D0A" barSize={25} /> */}
    </BarChart>
  )
}

export default Chart
