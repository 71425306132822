import React from "react"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import Image from "shared/Image"
import Filter from "shared/Filter"
import enhancer from "./enhancer"
import Loading from "shared/Loading"
import _ from "lodash"

function Ldrs(props) {
  let {
    updateFilterData,
    resetFilter,
    docData,
    downloadPDF,
    viewDoc,
    storeNum,
    isStoreManager,
    submitted,
    downLoadAsZip,
  } = props

  return (
    <div className="historicalLdr">
      <div className="box ">
        {!isStoreManager && (
          <Filter onSubmit={updateFilterData} Onreset={resetFilter} />
        )}
      </div>
      <div className="row mx-0">
        <div className="col-12 generatorstatus my-4 heading">
          <FormattedMessage id="ldrs.heading" />
        </div>
      </div>

      <Loading id="documents">
        {!_.isEmpty(docData) && (
          <>
            <div className="row mx-0 mt-4">
              <div className="col-12">
                <div className="store-block">
                  <div className="row justify-content-between">
                    <div className="col">Store: {storeNum}</div>
                    <div className="col text-right pr-4">
                      <Image
                        className="download-icon"
                        src="download-icon.png"
                        onClick={downLoadAsZip}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-0">
              {docData.map((d, i) => (
                <div className="col-4 mt-5" key={i}>
                  <div className="ldr-block">
                    <div className="ldr-block__name">{d.serviceYear} LDR</div>
                    <div className="ldr-block__icons">
                      <Image
                        className="eye-icon mr-2"
                        src="eye-icon.png"
                        onClick={viewDoc(d)}
                      />
                      <Image
                        className="downloadblack-icon"
                        src="download-black-icon.png"
                        onClick={downloadPDF(d)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        {submitted && docData.length == 0 && (
          <div className="row mt-3">
            <div className="col-sm-12 col-md-12 col-lg-12 text-center pt-2 nodata">
              <FormattedMessage id="nodata.documents" />
            </div>
          </div>
        )}
      </Loading>
    </div>
  )
}
export default enhancer(Ldrs)
