import React, { Fragment } from "react"
import Image from "shared/Image"
import { FormattedMessage } from "react-intl"
import { capitalize } from "shared"
import Loading from "shared/Loading"
import enhancer from "./enhancer"
import _ from "lodash"

function Schedule(props) {
  let {
    serviceData,
    setScheduleMode,
    filters,
    resetMyschedule,
    workOrderFilters,
    fetchSchedulesFilter,
    setFilterStatus,
    checkFilter,
  } = props

  return (
    <Fragment>
      <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0 ">
        <Loading id="my-schedule" height="400">
          <div className="row heading-label">
            <div className="col-2 p-eight">
              <div>
                <FormattedMessage id="myschedule.schedules" />
              </div>
            </div>
            {(serviceData?.[0] || checkFilter()?.length > 0) && (
              <div
                className={`col-2 label-name curs-ptr icon-alignment  p-shipped ${
                  workOrderFilters.Shipped && "select-background-filter"
                }`}
                title="Click on an icon to filter"
                onClick={() => {
                  fetchSchedulesFilter("Shipped")
                }}
              >
                {workOrderFilters?.Shipped && (
                  <div className="selected-filter">
                    <Image
                      className=""
                      src={"filtercheck.svg"}
                      width="12px"
                      alt=""
                    />
                  </div>
                )}
                <div>
                  <span className="icon-img">
                    <Image
                      className=""
                      src={"All_ServiceTypes_PickedUp_Icon.svg"}
                      width="30px"
                      alt=""
                    />
                  </span>
                  <span
                    className={`servicetype-icons ${
                      workOrderFilters?.Shipped && "font-weight"
                    }`}
                  >
                    <FormattedMessage id="myschedule.allservicetypes" />
                  </span>
                </div>
              </div>
            )}
            {(serviceData?.[0] || checkFilter()?.length > 0) && (
              <div
                className={`col-2 label-name curs-ptr icon-alignment  ${
                  workOrderFilters.PartialNoShip && "select-background-filter"
                }`}
                title="Click on an icon to filter"
                onClick={() => {
                  fetchSchedulesFilter("PartialNoShip")
                }}
              >
                {workOrderFilters?.PartialNoShip && (
                  <div className="selected-filter">
                    <Image
                      className=""
                      src={"filtercheck.svg"}
                      width="12px"
                      alt=""
                    />
                  </div>
                )}

                <div>
                  {" "}
                  <span className="icon-img">
                    <Image
                      className=""
                      src={"Partial_No_Ship_Icon.svg"}
                      width="30px"
                      alt=""
                    />
                  </span>
                  <span
                    className={`servicetype-icons ${
                      workOrderFilters?.PartialNoShip && "font-weight"
                    }`}
                  >
                    <FormattedMessage id="myschedule.partialnoship" />
                  </span>
                </div>
              </div>
            )}
            {(serviceData?.[0] || checkFilter()?.length > 0) && (
              <div
                className={`col-2 label-name curs-ptr icon-alignment  ${
                  workOrderFilters.NoShip && "select-background-filter"
                }`}
                title="Click on an icon to filter"
                onClick={() => {
                  fetchSchedulesFilter("NoShip")
                }}
              >
                {workOrderFilters?.NoShip && (
                  <div className="selected-filter">
                    <Image
                      className=""
                      src={"filtercheck.svg"}
                      width="12px"
                      alt=""
                    />
                  </div>
                )}
                <div
                  onClick={() => {
                    // fetchServicesWithShips("NoShip")
                  }}
                >
                  <span className="icon-img">
                    <Image
                      className=""
                      src={"Full_No_Ship_Icon.svg"}
                      width="30px"
                      alt=""
                    />
                  </span>
                  <span
                    className={`servicetype-icons ${
                      workOrderFilters?.NoShip && "font-weight"
                    }`}
                  >
                    <FormattedMessage id="myschedule.fullnoship" />
                  </span>
                </div>
              </div>
            )}
            <div className="col-4  text-right pt-1">
              {!resetMyschedule && !_.isEmpty(serviceData) && (
                <span
                  onClick={() => {
                    setScheduleMode(false)
                    if (checkFilter().length > 0) {
                      setFilterStatus(checkFilter()[0][0])
                    } else {
                      setFilterStatus("")
                    }
                  }}
                  className="switch-label"
                >
                  <FormattedMessage id="myschedule.switchtoservice" />
                </span>
              )}
            </div>
          </div>
          <div className="row p-r-hlf">
            <div className="col-12"></div>
            <span className="scheduleLabel">
              <Image
                className="info-icon"
                src="ic_info.svg"
                height="15px"
                width="15px"
                alt=""
              />

              <FormattedMessage id="myschedule.selecrstore" />
            </span>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 text-left pl-0 ">
            <p className="scheduleservice">
              <FormattedMessage id="myschedule.service" />
            </p>
            {!_.isEmpty(filters) && !serviceData[0] && (
              <div className="col-sm-12 col-md-12 col-lg-12 text-center pt-2 nodata">
                <FormattedMessage id="nodata.services" />
              </div>
            )}

            {serviceData?.[0] &&
              serviceData.map((d) => (
                <div className="rec servicerow" key={d.scheduleType}>
                  <div
                    className={`spans keynameService  ${
                      d.scheduleType && d.scheduleType.length == 2
                        ? "text-uppercase"
                        : ""
                    }`}
                  >
                    <span className="type">{capitalize(d.scheduleType)}</span>
                  </div>
                  <div className="badge-parent d-inline-block p-2">
                    {d.services.map((v) => (
                      <div
                        className="badge badge-pill badge-secondary bages mb-1"
                        key={v.l}
                      >
                        <span className="type subser">{v.count}</span>{" "}
                        <span className="count numserv">
                          {capitalize(v.itemCategory)}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </Loading>
      </div>
    </Fragment>
  )
}
export default enhancer(Schedule)
