import React, { useState, useEffect, useRef } from "react"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
  LabelList,
  Cell,
  ResponsiveContainer,
} from "recharts"
import "./style.scss"
import { useIntl } from "react-intl"
import { useWindowDimension } from "shared"
import colors from "./colors"

const Chart = ({ data, config, height }) => {
  const [width, setWidth] = useState(0)
  const elementRef = useRef(null)

  let dim = useWindowDimension()

  useEffect(() => {
    setWidth(elementRef?.current?.getBoundingClientRect()?.width)
  }, dim)

  let { yUnit = "", isXDay, fat, verticleLines } = config

  if (verticleLines === undefined) verticleLines = true
  const intl = useIntl()

  let formatNumber = (n) => {
    let extra = {}
    let str = ""
    if (yUnit == "$")
      extra = {
        style: "currency",
        currency: "USD",
      }
    else str = yUnit

    return `${intl.formatNumber(n, {
      ...extra,
      notation: "compact",
    })}${str}`
  }

  let formatNumberTitle = (n) => {
    let extra = {}

    return `${intl.formatNumber(n, {
      ...extra,
      notation: "compact",
    })}`
  }

  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props
    const radius = 10
    if (value !== 0)
      return (
        <g fontSize={"12px"}>
          <text
            x={x + width / 2}
            y={y - radius}
            fill="#fff"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {formatNumberTitle(value)}
          </text>
        </g>
      )
    return ""
  }

  return (
    <div ref={elementRef} className="bar-chart-wrapper">
      <ResponsiveContainer width={width} height={height}>
        <BarChart
          width={width}
          height={height}
          data={data}
          margin={{
            top: 20,
            right: 20,
            left: 10,
            bottom: 0,
          }}
          barGap={0}
        >
          <CartesianGrid vertical={verticleLines} />

          <YAxis tickFormatter={formatNumber} domain={config.yDomain} />
          <XAxis
            dy={5}
            axisLine={true}
            tickLine={true}
            dataKey={config.x}
            interval={0}
            angle={verticleLines ? 0 : -45}
            tickFormatter={(d) => (isXDay ? d.match(/^.{0,3}/, " ")[0] : d)}
          />
          <Tooltip
            formatter={formatNumber}
            labelFormatter={(name) => {
              let tooltip = data.find((d) => d.tooltip?.includes(name))?.tooltip
              return tooltip ? tooltip : name
            }}
          />

          {verticleLines && (
            <Bar
              dataKey={config.y.key}
              name={config.y.label}
              fill={colors.bar[0]}
              barSize={fat ? 60 : 10}
            />
          )}
          {!verticleLines && (
            <Bar
              dataKey={config.y.key}
              name={config.y.label}
              fill={colors.bar[0]}
              barSize={fat ? 60 : 10}
            >
              <LabelList dataKey="cur" content={renderCustomizedLabel} />
              {data.map((entry, index) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors.bar_chart_colors[index]}
                  />
                )
              })}
            </Bar>
          )}
          {config.z && (
            <Bar
              dataKey={config.z.key}
              name={config.z.label}
              fill={colors.bar[1]}
              barSize={10}
            />
          )}

          {verticleLines && <Legend verticalAlign="bottom" iconType="circle" />}
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default Chart
