import React, { Fragment, useEffect, useState } from "react"
import content from "./content"
import contentStore from "./contentStore"
import { useHarsco } from "shared"

function TermsAndTerminology() {
  let [searchTerm, setSearchTerm] = useState("")
  let [searchedContent, setSearchedContent] = useState([])

  let { isStoreManager } = useHarsco()

  useEffect(() => {
    let result = []
    if (!searchTerm.trim().length)
      result = isStoreManager ? contentStore : content
    else {
      let terms = isStoreManager ? contentStore : content
      result = terms.filter((val) => {
        return val.label.toLowerCase().includes(searchTerm.toLowerCase())
      })
    }
    setSearchedContent(result)
  }, [searchTerm])

  return (
    <div>
      <div className="text-justify pl-3 pt-2 mb-0 terminology-sec">
        <h5>Terms and Terminologies</h5>
        <div className="form-input-block py-2 pb-4 px-3 row">
          <input
            className="input-box col-4"
            placeholder="Search by title"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {searchedContent.map((d) => (
          <Fragment key={d.id}>
            <h6>
              {"1." + d.id} &nbsp;&nbsp; {d.label}
            </h6>
            <p>
              {d.containHTML ? (
                <span dangerouslySetInnerHTML={{ __html: d.desc }} />
              ) : (
                d.desc
              )}
            </p>

            <p>&nbsp;</p>
            {d.isTable && (
              <table width="695" className="mb-4">
                <tbody>
                  {d.data.map((dt, i) => (
                    <tr className={`${dt.isHeader ? "tr-head" : ""}`} key={i}>
                      <td width="143">
                        {i == 0 ? <strong>{dt.l1}</strong> : dt.l1}
                      </td>
                      <td width="552">
                        {i == 0 ? <strong>{dt.l2}</strong> : dt.l2}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default TermsAndTerminology
