import React from "react"
import Image from "shared/HelpImage/index"

function EditUserDetails() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Edit User Details</h3>
      <div className="img-section">
        <Image
          src="user_management.png"
          alt="Change Request (Service Details) Image"
        />
      </div>
      <p className="text-justify pl-3 pt-2">
        <p>
          Customer Account name and number will be listed in the first search
          bar. Type in the last name or email address of the User whose access
          you wish to update in the second search bar and select search. You can
          also leave this field blank and search specifically by User Type by
          making your selection from the dropdown menu in the third search bar
          and selecting search.
        </p>
        <p>
          You will receive search results with user details. You have the option
          to download this list of users into an excel file by selecting
          Download. Make your selection by clicking on edit in the options field
          next to the account you wish to make changes to.
        </p>
      </p>
    </div>
  )
}

export default EditUserDetails
