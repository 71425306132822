import React from "react"
import Image from "shared/HelpImage/index"

function MyDocuments() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3 ">Dashboard: My Documents</h3>

      <Image
        className=""
        src="MyDocuments_Dashboard.png"
        alt="My Documents Image"
      />
      <p className="text-justify pl-3 pt-3">
        <p>
          My Documents shows 4 bars of different documents types the user can
          click into. The user can choose a preselected date range of month,
          week, or today. This will sort the documents by that date range. The
          blue dots will show how many of those documents are in each tab for
          that date range.{" "}
        </p>
        <p>The user can click into any documents they would like to see. </p>
        <p>
          If the user clicks on “View All”, it jump the user to the Documents
          Library where the user has a wider search criteria.
        </p>
      </p>
    </div>
  )
}
export default MyDocuments
