import { SET_CUSTOMERS } from "./actionType"

const customerList = (state = [], action) => {
  let newState = []
  switch (action.type) {
    case SET_CUSTOMERS:
      newState = [...action.customers]
      return newState
    default:
      return state
  }
}

export default customerList
