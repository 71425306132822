import React from "react"
import Tour from "reactour"
import enhancer from "./enchancer"

function QuickTour(props) {
  let {
    completedQuickTour,
    startFeatures,
    isPopOverTour,
    isTourOpen,
    quickTourSteps,
    popoverSteps,
  } = props

  const dotsRenderQuickTour = (goTo, step, steps, clsName) => {
    return (
      <div className="dots pad-tb-10">
        {steps.map((ele, index) => {
          return (
            <div key={ele.selector} className="pad-lr-10">
              <div
                className={
                  index === step - 1
                    ? `${clsName}dot ${clsName}dotactive`
                    : `${clsName}dot ${clsName}dotinactive`
                }
                onClick={() => goTo(index)}
              />
            </div>
          )
        })}
      </div>
    )
  }

  const buttonRenderQuickTour = (goTo, step, finishFlag) => {
    if (!finishFlag) {
      return (
        <div className="tourbuttons">
          <button className="nextButton" onClick={() => goTo(step)}>
            Next
          </button>
        </div>
      )
    } else {
      return (
        <div className="tourdone">
          <button className="nextButton" onClick={() => completedQuickTour()}>
            Get Started
          </button>
        </div>
      )
    }
  }

  const getTourSteps = () => {
    let stepsTour = []
    quickTourSteps.forEach((ele) => {
      stepsTour.push({
        selector: ele.selector,
        content: function DemoHelperComponent({ goTo, step }) {
          return (
            <div className="tourdescription">
              <span className="tourHeadingText">{ele.heading}</span>
              <div>{ele.description}</div>
              {dotsRenderQuickTour(goTo, step, quickTourSteps, "")}
              {buttonRenderQuickTour(goTo, step, ele.finishFlag)}
            </div>
          )
        },
        position: ele.position,
        stepInteraction: false,
      })
    })
    return stepsTour
  }

  const getPopOverSteps = () => {
    let stepsTour = []
    popoverSteps.forEach((ele) => {
      stepsTour.push({
        selector: ele.selector,
        content: function DemoHelperComponent({ goTo, step }) {
          return (
            <div style={{ height: "100%" }}>
              <div className="disp-flex ">
                <div className="w-50 alignCerter" style={{ height: "100%" }}>
                  {ele.video}
                </div>
                <div className="w-50 p-l-15">
                  <div className="tourHeadingPopoverText p-t-b-10">
                    {ele.heading}
                  </div>
                  <div className="fn-12">{ele.description}</div>
                </div>
              </div>
              {dotsRenderQuickTour(goTo, step, popoverSteps, "popover")}
              {
                <div className="popovertourbuttons">
                  {step !== 1 && (
                    <button
                      className="backButton "
                      onClick={() => goTo(step - 2)}
                    >
                      Back
                    </button>
                  )}
                  <button
                    className="nextButtonPopover m-l-10"
                    onClick={() => (step !== 1 ? startFeatures() : goTo(step))}
                  >
                    {step !== 1 ? "Start the Tour" : "Next"}
                  </button>
                </div>
              }
            </div>
          )
        },
        position: ele.position,
      })
    })
    return stepsTour
  }

  const clsName = isPopOverTour ? "TourModalpopover" : "tourCard"
  const steps = isPopOverTour ? getPopOverSteps() : getTourSteps()

  return (
    <div>
      <Tour
        steps={steps}
        isOpen={isPopOverTour}
        disableFocusLock={true}
        showBadge={false}
        badgeContent={undefined}
        rounded={10}
        nextButton={<button>Next</button>}
        showButtons={false}
        showNumber={false}
        showCloseButton={false}
        showNavigationNumber={false}
        accentColor={"green"}
        showNavigation={false}
        className={clsName}
      />
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        disableFocusLock={true}
        showBadge={false}
        badgeContent={undefined}
        rounded={10}
        nextButton={<button>Next</button>}
        showButtons={false}
        showNumber={false}
        showCloseButton={false}
        showNavigationNumber={false}
        accentColor={"green"}
        showNavigation={false}
        className={clsName}
      />
    </div>
  )
}

export default enhancer(QuickTour)
