import React, { useState, useEffect, useRef } from "react"
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts"
import { useIntl } from "react-intl"
import colors from "./colors"
import "./style.scss"
import _ from "lodash"

const Chart = ({ data, height, label, value, yUnit = "lbs" }) => {
  const [width, setWidth] = useState(0)
  const elementRef = useRef(null)
  const intl = useIntl()
  data = data.filter((d) => d[value] > 0)

  data.forEach((d, i) => (d.color = colors.pie[i]))

  let formatNumber = (n) =>
    `${intl.formatNumber(n, {
      notation: "compact",
    })} ${yUnit}`

  let tEllipses = (text, max = 25) => {
    let t = _.isNil(text)
      ? ""
      : text?.substr(0, max - 1) + (text?.length > max ? "..." : "")
    return (
      <span className="color-blk" title={text}>
        {t}
      </span>
    )
  }

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width)
  }, [])

  return (
    <div className="piechart" ref={elementRef}>
      <PieChart width={width} height={height}>
        <Pie
          data={data}
          dataKey={value}
          nameKey={label}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
        >
          {data.map((d, index) => (
            <Cell key={`cell-${index}`} fill={d.color} stroke="" />
          ))}
        </Pie>
        <Tooltip formatter={formatNumber} />
        <Legend
          layout="vertical"
          verticalAlign="middle"
          align="right"
          wrapperStyle={{ right: 50 }}
          formatter={(v, e) => (
            <span className="color-blk">
              {tEllipses(e.payload[label])} {formatNumber(e.payload.value)}{" "}
            </span>
          )}
        />
      </PieChart>
    </div>
  )
}

export default Chart
