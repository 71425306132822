import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import Filter from "shared/Filter"
import { FormattedMessage } from "react-intl"
import Image from "shared/Image"
import Loading from "shared/Loading"
import Pagination from "shared/Pagination"
import Select from "shared/Select"
import classnames from "classnames"
import _ from "lodash"

function Mystoreschedule(props) {
  let {
    serviceData,
    MyStoreSortByService,
    sortBy,
    pageNo,
    gotoPage,
    updatedupdateSortByOption,
    updateFilters,
    reset,
    serviceTypeData,
    serviceHistory,
    submitted,
    fetchServicesWithFilter,
    checkFilter,
    workOrderFilters,
    showfilterhint,
  } = props

  let { totalRecords = 0 } = serviceData
  /** Below function is used to render respective icons on workOrderType */
  function getIconSource(d) {
    if (d?.workOrderType === "Shipped") {
      return "All_ServiceTypes_PickedUp_Icon.svg"
    } else if (d?.workOrderType === "NoShip") {
      return "Full_No_Ship_Icon.svg"
    } else {
      return "Partial_No_Ship_Icon.svg"
    }
  }
  return (
    <Fragment>
      <div className="storemanager-myorders">
        <Filter onSubmit={updateFilters} Onreset={reset} />
        <Loading id="schedules-services" height="400">
          <div className="col-12 mb-5">
            <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0 ">
              <div
                className={classnames("row heading-label", {
                  headingchanges: totalRecords > 0,
                })}
              >
                <div
                  className={classnames(
                    submitted && (totalRecords > 0 || checkFilter()?.length > 0)
                      ? "col-lg-3 label-name"
                      : "col-lg-9 label-name"
                  )}
                >
                  <div className="pageHeading">
                    <FormattedMessage id="myschedule.schedules" />{" "}
                    {submitted && (
                      <span className="pageSubHeading">
                        {!!totalRecords && totalRecords > 1 ? (
                          <FormattedMessage
                            id="myschedule.schedules_counts"
                            values={{ count: totalRecords }}
                          />
                        ) : (
                          <FormattedMessage
                            id="myschedule.schedules_count"
                            values={{ count: totalRecords }}
                          />
                        )}
                      </span>
                    )}
                    {/* )} */}
                  </div>
                  {!submitted && (
                    <div>
                      <span className="scheduleLabel">
                        <Image
                          className="info-icon"
                          src="ic_info.svg"
                          height="15px"
                          width="15px"
                          alt=""
                        />
                        <FormattedMessage id="mystoreorder.selecrfilter" />
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-lg-3 displayBox">
                  <div
                    className={`col-lg-7 label-name p-0 ${
                      workOrderFilters?.Shipped && "selected-background"
                    }`}
                  >
                    {(totalRecords > 0 || checkFilter()?.length > 0) &&
                      submitted && (
                        <div
                          className={`row ptr`}
                          title="Click on an icon to filter"
                          onClick={() => fetchServicesWithFilter("Shipped")}
                        >
                          {workOrderFilters?.Shipped && (
                            <div className="selected-filter">
                              <Image
                                className=""
                                src={"filtercheck.svg"}
                                width="12px"
                                alt=""
                              />
                            </div>
                          )}
                          <div className="icon-img col-3">
                            <Image
                              className={"ic_truck"}
                              width="30px"
                              src="All_ServiceTypes_PickedUp_Icon.svg"
                              alt=""
                            />
                          </div>
                          <div className="servicetype-icons col-9 allservicetype">
                            <FormattedMessage id="myschedule.allservicetypes" />
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="col-lg-5 label-name p-0">
                    {(totalRecords > 0 || checkFilter()?.length > 0) &&
                      submitted && (
                        <div
                          className={`textDisplay ptr ${
                            workOrderFilters?.partialNoShip &&
                            "selected-background"
                          }`}
                          title="Click on an icon to filter"
                          onClick={() =>
                            fetchServicesWithFilter("partialNoShip")
                          }
                        >
                          {workOrderFilters?.partialNoShip && (
                            <div className="selected-filter">
                              <Image
                                className=""
                                src={"filtercheck.svg"}
                                width="12px"
                                alt=""
                              />
                            </div>
                          )}{" "}
                          <span className="icon-img">
                            <Image
                              className=""
                              src={"Partial_No_Ship_Icon.svg"}
                              width="30px"
                              alt=""
                            />
                          </span>
                          <span className="servicetype-icons">
                            <FormattedMessage id="myschedule.partialnoship" />
                          </span>
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-lg-6 displayBox displayItems">
                  <div
                    className={`col-lg-3 label-name ${
                      workOrderFilters?.NoShip && "selected-background"
                    }`}
                  >
                    {(totalRecords > 0 || checkFilter()?.length > 0) &&
                      submitted && (
                        <div
                          className={`textDisplay ptr`}
                          title="Click on an icon to filter"
                          onClick={() => fetchServicesWithFilter("NoShip")}
                        >
                          {workOrderFilters?.NoShip && (
                            <div className="selected-filter">
                              <Image
                                className=""
                                src={"filtercheck.svg"}
                                width="12px"
                                alt=""
                              />
                            </div>
                          )}
                          <span className="icon-img">
                            <Image
                              className={"ic_truck"}
                              width="30px"
                              src="Full_No_Ship_Icon.svg"
                              alt=""
                            />
                          </span>
                          <span className="servicetype-icons">
                            <FormattedMessage id="myschedule.fullnoship" />
                          </span>
                        </div>
                      )}
                  </div>
                  <div className="col-lg-4 label-name  ">
                    {(totalRecords > 0 || checkFilter()?.length > 0) &&
                      submitted && (
                        <div>
                          <div className="serviceOrderType">
                            <span className="fullNoShipIcon">+&nbsp;</span>
                            <span>
                              <FormattedMessage id="mystoreorder.servicetypepickedup" />
                            </span>
                          </div>
                          <div className="serviceOrderType">
                            <span className="noWasteIcon">_&nbsp;</span>
                            <span>
                              <FormattedMessage id="mystoreorder.nowastepicked" />
                            </span>
                          </div>
                        </div>
                      )}
                  </div>

                  {(totalRecords > 0 || checkFilter()?.length > 0) && (
                    <div className="col-lg-5 ">
                      <div
                        className={
                          "col-sm-10 col-md-10 col-lg-9 sortybysection sort-alignment"
                        }
                      >
                        <span className={"myorderlabel"}>
                          <FormattedMessage id="myschedule.sortby" />
                        </span>
                        <label className="myOrder">
                          {
                            <Select
                              label="Please select"
                              options={MyStoreSortByService}
                              onSelect={(i) => updatedupdateSortByOption(i)}
                              labelKey="l"
                              className="sort-dropdown"
                              selected={sortBy}
                            />
                          }
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {totalRecords > 0 && showfilterhint && (
              <div>
                <div className="col-3 blue_color_text">
                  <center>
                    <Image className="" src={"myorders_filters_uparrow.svg"} />
                    <FormattedMessage id="myschedule.iconsfilter" />
                  </center>
                </div>
              </div>
            )}
            <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0 ">
              <div className="row m-0 service-section">
                {submitted != false && totalRecords == 0 && (
                  <div className="col-sm-12 col-md-12 col-lg-12 text-center pt-2 nodatamsg">
                    <FormattedMessage id="nodata.schedules" />
                  </div>
                )}
                {totalRecords > 0 && (
                  <>
                    <div className="col-sm-12 col-md-12 col-lg-12 m-0">
                      <div className="row tabelHeader_New">
                        <div className="col-sm-6 col-md-3 col-lg-3 m-0 displayBox height-100">
                          <div
                            className={
                              "col-sm-6 col-md-6 col-lg-6 m-0 tableHeadingFont aligncenter borderline"
                            }
                          >
                            <FormattedMessage id="mystoreorder.soNumber" />
                          </div>
                          <div
                            className={
                              "col-sm-6 col-md-6 col-lg-6 m-0 tableHeadingFont aligncenter borderline"
                            }
                          >
                            <FormattedMessage id="mystoreorder.poNumber" />
                          </div>
                        </div>
                        <div className="col-sm-3 col-md-3 col-lg-3 m-0 displayBox height-100">
                          <div
                            className={
                              "col-sm-6 col-md-6 col-lg-6 m-0 tableHeadingFont aligncenter borderline"
                            }
                          >
                            <FormattedMessage id="mystoreorder.dateOfService" />
                          </div>
                          <div
                            className={
                              "col-sm-6 col-md-6 col-lg-6 m-0 tableHeadingFont aligncenter borderline"
                            }
                          >
                            <FormattedMessage id="mystoreorder.status" />
                          </div>
                        </div>
                        <div className="col-sm-3 col-md-3 col-lg-3 m-0 displayBox height-100">
                          <div
                            className={
                              "col-sm-6 col-md-6 col-lg-6 m-0 tableHeadingFont aligncenter borderline"
                            }
                          >
                            <FormattedMessage id="mystoreorder.scheduleType" />
                          </div>
                          <div
                            className={
                              "col-sm-6 col-md-6 col-lg-6 m-0 tableHeadingFont aligncenter borderline"
                            }
                          >
                            <FormattedMessage id="mystoreorder.serviceType" />
                          </div>
                        </div>
                        <div className="col-sm-3 col-md-3 col-lg-3 m-0 displayBox height-100">
                          <div
                            className={
                              "col-sm-5 col-md-5 col-lg-5 m-0 tableHeadingFont aligncenter borderline"
                            }
                          >
                            <FormattedMessage id="mystoreorder.totalWaste" />
                          </div>
                          <div
                            className={
                              "col-sm-7 col-md-7 col-lg-7 m-0 tableHeadingFont aligncenter borderline"
                            }
                          ></div>
                        </div>
                      </div>
                      {serviceData?.items?.map((data, i) => (
                        <div className="row datatabMystore " key={i}>
                          <div className="col-sm-3 col-md-3 col-lg-3 m-0 displayBox">
                            <div
                              className={
                                "col-sm-6 col-md-6 col-lg-6 tablecolumncell aligncenter f-20"
                              }
                            >
                              <Image
                                className={"ic_truck"}
                                width="30px"
                                src={getIconSource(data)}
                                alt=""
                              />
                              <span className="p-l-10"></span>
                              {data.workOrderId}
                            </div>
                            <div
                              className={
                                "col-sm-6 col-md-6 col-lg-6 tablecolumncell aligncenter f-20"
                              }
                            >
                              {data.poNumber}
                            </div>
                          </div>
                          <div className="col-sm-3 col-md-3 col-lg-3 m-0 displayBox">
                            <div
                              className={
                                "col-sm-6 col-md-6 col-lg-6 tablecolumncell aligncenter f-20"
                              }
                            >
                              {data.formatTime}
                            </div>
                            <div
                              className={
                                "col-sm-6 col-md-6 col-lg-6 tablecolumncell aligncenter f-20"
                              }
                            >
                              {data.scheduleStatus}
                            </div>
                          </div>
                          <div className="col-sm-3 col-md-3 col-lg-3 m-0 displayBox">
                            <div
                              className={
                                "col-sm-6 col-md-6 col-lg-6 tablecolumncell aligncenter f-20"
                              }
                            >
                              {data.scheduleType}
                            </div>
                            <div
                              className={
                                "col-sm-6 col-md-6 col-lg-6 tablecolumncell aligncenter f-20"
                              }
                            >
                              {serviceTypeData(data.serviceTypes)}
                            </div>
                          </div>
                          <div className="col-sm-3 col-md-3 col-lg-3 m-0 displayBox">
                            <div
                              className={
                                "col-sm-5 col-md-5 col-lg-5 tablecolumncell aligncenter f-20"
                              }
                            >
                              {data?.totalWeight !== null &&
                              data?.totalWeight !== 0
                                ? _.round(data.totalWeight, 1)
                                : "--"}
                            </div>
                            <div
                              className={
                                "col-sm-7 col-md-7 col-lg-7 tablecolumncell aligncenter f-20"
                              }
                            >
                              <button
                                className="viewDetails w-2 f-20"
                                onClick={() => {
                                  serviceHistory(data.workOrderAzId)
                                }}
                              >
                                <FormattedMessage id="mystoreorder.buttontext" />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <Pagination
                      {...{
                        pageNo,
                        totalRecords,
                        pageSize: 20,
                        gotoPage,
                        data: serviceData,
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </Loading>
      </div>
    </Fragment>
  )
}
export default enhancer(Mystoreschedule)
