const documentLibraryHeader = [
  {
    id: 1,
    value: "",
    className: "documentlibrary-table-header",
  },
  {
    id: 2,
    value: "Order Number",
    className: "documentlibrary-table-header",
  },
  {
    id: 5,
    value: "Address",
    className: "documentlibrary-table-header",
  },
  {
    id: 3,
    value: "City",
    className: "documentlibrary-table-header alignleft",
  },
  {
    id: 4,
    value: "State",
    className: "documentlibrary-table-header",
  },
  {
    id: 6,
    value: "View",
    className: "documentlibrary-table-header",
  },
]

export { documentLibraryHeader }
