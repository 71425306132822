import React from "react"
import Image from "shared/HelpImage/index"

function EditUserAccount() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Edit User Account</h3>
      <div className="img-section">
        <Image
          src="edit_user_account.png"
          alt="Change Request (Service Details) Image"
        />
      </div>
      <p className="text-justify pl-3 pt-2">
        <p>
          You have the capability to change the role of the Connect+ User you
          selected here by selecting a new User type in that field from the
          drop-down menu.
        </p>
        <p>
          You can also check the box if you want to deactivate a user if they
          are no longer with your company and should not have access.
        </p>
        <p>
          The save and close button will change to green once you have made any
          changes. Please confirm your changes and select Save and Close.
        </p>
      </p>
    </div>
  )
}
export default EditUserAccount
