import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import Filter from "shared/Filter"
import classnames from "classnames"
import Image from "shared/Image"
import { FormattedMessage } from "react-intl"
import { isHomeDepot, isRbsCorporateUser } from "shared"
import Loading from "shared/Loading"
import Pagination from "shared/Pagination"

function docLibrary(props) {
  let {
    docData,
    updateDocData,
    downLoadDoc,
    download,
    changeAllDocs,
    updateFilterData,
    reset,
    allDocsCheck,
    pageNo,
    gotoPage,
    submitted,
    viewDoc,
    docsTypes,
    isStoreManager,
    toggleHeadStatus,
    headFilterChange,
    serviceHistory,
  } = props

  let { totalRecords = 0 } = docData

  let isHD = isHomeDepot()
  let isRBS = isRbsCorporateUser()
  // let colSpan = isHD ? 1 : 2

  return (
    <Fragment>
      <div className={classnames({ storeDocumentLibrary: isStoreManager })}>
        <Filter
          onSubmit={updateFilterData}
          Onreset={reset}
          OnHeadFilterChange={headFilterChange}
        />
        <div className="col-sm-12 col-md-12 col-lg-12 generatorstatus text-left my-4">
          <div className="m-0 labelgenerator">
            <FormattedMessage id="documentlibrary.heading" />
          </div>
          <div className="downloadsec">
            {toggleHeadStatus == "Filters" && submitted && totalRecords > 0 && (
              <button
                className={classnames("downloadbtn", {
                  disabled:
                    docData?.items?.filter((d) => d.selected).length == 0,
                })}
                disabled={docData?.items?.filter((d) => d.selected).length == 0}
                onClick={downLoadDoc}
              >
                <FormattedMessage id="documentlibrary.download" />
              </button>
            )}
          </div>
        </div>
        <Loading id="documents">
          <div className="col-sm-12 col-md-12 col-lg-12 m-0 px-3">
            <div className="row tabelHeader m-0">
              <div className="col-sm-1 col-md-1 col-lg-1 p-0 py-2 report-border-right">
                {toggleHeadStatus != "Filters" ? (
                  <FormattedMessage id="documentlibrary.downloadColumnHeader" />
                ) : (
                  <FormattedMessage id="documentlibrary.all" />
                )}
                {toggleHeadStatus == "Filters" && totalRecords > 0 && (
                  <label className="check-container">
                    <input
                      type="checkbox"
                      onChange={changeAllDocs}
                      checked={allDocsCheck}
                    />
                    <span className="checkmark allcheck"></span>
                  </label>
                )}
              </div>
              <div
                className={`col-sm-${isHD || isRBS ? 1 : 2} col-md-${
                  isHD || isRBS ? 1 : 2
                } col-lg-${isHD || isRBS ? 1 : 2} p-0 py-2 report-border-right`}
              >
                <FormattedMessage id="documentlibrary.#" />
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 p-0 py-2 report-border-right">
                <FormattedMessage id="documentlibrary.type" />
              </div>

              <div className="col-sm-1 col-md-1 col-lg-1 p-0 py-2 report-border-right">
                <FormattedMessage id="documentlibrary.orderNumber" />
              </div>
              <div className="col-sm-1 col-md-1 col-lg-1 p-0 py-2 report-border-right">
                <FormattedMessage id="documentlibrary.serviceDate" />
              </div>
              {isHD && (
                <div className="col-sm-1 col-md-1 col-lg-1 py-2 report-border-right">
                  <FormattedMessage id="documentlibrary.region" />
                </div>
              )}
              {isRBS && (
                <div className="col-sm-1 col-md-1 col-lg-1 p-0 py-2 report-border-right">
                  <FormattedMessage id="documentlibrary.brandName" />
                </div>
              )}
              <div
                className={`col-sm-1 col-md-1 col-lg-1 p-0 py-2 report-border-right`}
              >
                <FormattedMessage id="documentlibrary.epa" />
              </div>
              <div className="col-sm-1 col-md-1 col-lg-1 p-0 py-2 report-border-right">
                <FormattedMessage id="documentlibrary.store" />
              </div>
              <div className="col-sm-1 col-md-1 col-lg-1 p-0 py-2 report-border-right">
                <FormattedMessage id="documentlibrary.city" />
              </div>
              <div className="col-sm-1 col-md-1 col-lg-1 p-0 py-2 report-border-right">
                <FormattedMessage id="documentlibrary.state" />
              </div>
              <div className="col-sm-1 col-md-1 col-lg-1 p-0 py-2 report-border-right">
                <FormattedMessage id="documentlibrary.view" />
              </div>
            </div>
            {docData?.items?.map((data, i) => (
              <div className="row datatab m-0" key={i}>
                <div className="col-sm-1 col-md-1 col-lg-1 m-0 p-0 py-3 report-border-right report-border-left">
                  <Image
                    style={{ cursor: "pointer" }}
                    className="pdf-icon"
                    src="ic_pdf.svg"
                    alt=""
                    onClick={() => download(data.downloadUrl, data.fileName)}
                  />
                  {toggleHeadStatus == "Filters" && (
                    <label className="check-container">
                      <input
                        type="checkbox"
                        onChange={updateDocData(i)}
                        checked={data.selected}
                      />
                      <span className="checkmark"></span>
                    </label>
                  )}
                </div>
                <div
                  className={`col-sm-${isHD || isRBS ? 1 : 2} col-md-${
                    isHD || isRBS ? 1 : 2
                  } col-lg-${
                    isHD || isRBS ? 1 : 2
                  } m-0 p-0 py-3 report-border-right`}
                >
                  {data.documentNo}
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2 m-0 p-0 py-3 report-border-right">
                  {docsTypes[data.docType]?.label || data.docType}
                </div>

                <div className="col-sm-1 col-md-1 col-lg-1 m-0 p-0 py-3 report-border-right">
                  <span
                    className="documentLibraryOrder border"
                    onClick={() => serviceHistory(data.workOrderAzid)}
                  >
                    {data.orderNumber}
                  </span>
                </div>
                <div className="col-sm-1 col-md-1 col-lg-1 m-0 p-0 py-3 report-border-right">
                  {data.serviceDate}
                </div>
                {isHD && (
                  <div className="col-sm-1 col-md-1 col-lg-1 py-3 report-border-right">
                    {data.region}
                  </div>
                )}
                {isRBS && (
                  <div
                    className="col-sm-1 col-md-1 col-lg-1 m-0 p-0 py-3 p-0   report-border-right ellipsis"
                    title={data.division}
                  >
                    {data.division}
                  </div>
                )}
                <div
                  className={`col-sm-1 col-md-1 col-lg-1 m-0 p-0 py-3 report-border-right`}
                >
                  {data.epaId}
                </div>
                <div className="col-sm-1 col-md-1 col-lg-1 m-0 p-0 py-3 report-border-right">
                  {data.store}
                </div>
                <div className="col-sm-1 col-md-1 col-lg-1 m-0 p-0 py-3 report-border-right">
                  {data.city}
                </div>
                <div className="col-sm-1 col-md-1 col-lg-1 m-0 p-0 py-3 report-border-right">
                  {data.state}
                </div>
                <div className="col-sm-1 col-md-1 col-lg-1 m-0 p-0 py-3 report-border-right">
                  <Image
                    className="view-icon"
                    src="ic_doclibraryview.svg"
                    height="24px"
                    width="24px"
                    alt=""
                    onClick={(e) => viewDoc(data)}
                  />
                </div>
              </div>
            ))}
          </div>
          {submitted && totalRecords == 0 && (
            <div className="col-sm-12 col-md-12 col-lg-12 text-center pt-2 nodata">
              <FormattedMessage id="nodata.documents" />
            </div>
          )}
          {totalRecords > 0 && (
            <Pagination
              {...{
                pageNo,
                totalRecords,
                gotoPage,
                data: docData,
                pageSize: 20,
              }}
            />
          )}
        </Loading>
      </div>
    </Fragment>
  )
}
export default enhancer(docLibrary)
