import React from "react"
import Image from "shared/HelpImage/index"

function ScheduleRequest() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">New Request: New Schedule Request</h3>
      <p className="text-justify pl-3 pt-2">
        <p>You can submit a request for a new service using this form.</p>
        <p>
          CONTACT INFORMATION:
          <ul>
            <li>
              Your Contact Name and Email address are already entered for you
              and cannot be changed.
            </li>
            <li>You can enter a different Contact Phone if you want.</li>
            <li>
              Optionally, you can enter an additional email address which will
              receive notification after this request is submitted.
            </li>
          </ul>
        </p>
        <p>
          REQUEST DETAILS:
          <ul>
            <li>
              Select a Store Number from the dropdown list. This is required.
            </li>
            <li>
              Select a Request Type from the dropdown list. This is required.
            </li>
            <li>
              Check one or more boxes to choose Service Types for this request.
              If necessary, the green scroll bar on the right of the list will
              show more options. You will only see Service Types that are valid
              for your customer. Please choose all the Service Types that apply.
              At least one Service Type is required.
            </li>
            <li>
              Enter any special instructions in the Instructions box. There is a
              500 character limit.
            </li>
          </ul>
        </p>
        <p>When done, press the SUBMIT REQUEST button.</p>
        <p>
          You will receive notification, along with a confirmation number, on
          your screen that your request has been submitted.
        </p>
      </p>
      <div className="img-section">
        <Image src="New_Service_request.png" alt="Report Service Issue Image" />
      </div>
    </div>
  )
}
export default ScheduleRequest
