import React from "react"

const CommonTableGrid = (props) => {
  const { headers, rows } = props
  return (
    <div style={{ width: "100%" }}>
      <table style={{ width: "100%" }}>
        <tr>
          {headers?.map((ele, ind) => {
            return (
              <th key={ind} className={ele.className}>
                {ele.value}
              </th>
            )
          })}
        </tr>
        {rows?.map((ele, ind) => {
          return (
            <tr
              key={ind}
              className={
                rows.length === ind + 1
                  ? `${ele.className} border-bottom-1`
                  : `${ele.className}`
              }
            >
              {ele.data.map((element, index) => {
                return (
                  <td key={index} className={`${element.className}`}>
                    {element.value}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </table>
    </div>
  )
}

export default CommonTableGrid
