import React, { Fragment, useState, useEffect, useRef } from "react"
// import enhancer from "./enhancer"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import Input from "routes/Admin/Components/Input"
import additionalinfoenhancer from "./additionalinfoenhancer"
import { Form, Formik } from "formik"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import AlertModal from "./AlertModal"

function AdditionalInfo(props) {
  let {
    formik,
    check_all,
    reactive_check,
    backAction,
    cancelAction,
    showAlert,
    setShowAlert,
    handleAlerts,
    seekingStep,
    showError,
    setShowError,
    genNameforView,
  } = props
  const [submit, setSubmit] = useState(false)
  const ref = useRef()

  useEffect(() => {
    if (showAlert) {
      ref.current.click()
    }
  }, [showAlert])
  const checkOneof = (fields, values) => {
    const fieldsUpdated = fields.map((val) => val.id)

    return !fieldsUpdated?.some(
      (val) => values[val] === true || values[val] === 1
    )
  }
  const currentProfile = useSelector(
    (state) => state.profileDetails?.currentProfile
  )
  const { type } = useParams()
  const generatorOptions = useSelector(
    (state) => state.generatorDetails.generatorList
  )

  const getDefaultGeneratorName = (id) => {
    return (
      generatorOptions?.filter((val) => val.generatorId === id)?.[0]
        ?.generatorName || ""
    )
  }

  return (
    <Fragment>
      <div>
        {/* Header */}
        <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 addi-info generatorstatus text-left ml-4 py-4">
          <div className="m-0 labelgenerator myprofile">
            <FormattedMessage id="profile.additionalinfotitle" />
          </div>
          <div className="form-input-block col-sm-4">
            <span className="label">
              <FormattedMessage id="profile.generator" />
            </span>
            <Input
              value={
                getDefaultGeneratorName(currentProfile?.generatorId) ||
                genNameforView
              }
              disabled={true}
            />
          </div>
        </div>
        {/* all that applies */}
        <Formik
          enableReinitialize={true}
          initialValues={formik.values}
          {...formik}
        >
          {({ values, errors, touched, setFieldValue, isValid }) => {
            const handleChange = (e, checkItem) => {
              const { checked } = e.target
              setFieldValue(checkItem, checked)
              if (checkItem !== "additionalInfoNoneFlag") {
                if (checked) {
                  const filteredCheckAll = check_all.filter(
                    (item) => item.id !== "additionalInfoNoneFlag"
                  )
                  const allChecked = filteredCheckAll.every(
                    (item) => values[item.id] || item.id === checkItem
                  )
                  setFieldValue("selectAllCheck", allChecked)
                } else {
                  setFieldValue("selectAllCheck", false)
                }
              }
              if (checkItem === "additionalInfoNoneFlag") {
                if (checked) {
                  setFieldValue("selectAllCheck", false)
                  check_all.forEach((item) => {
                    if (item.id !== "additionalInfoNoneFlag") {
                      setFieldValue(item.id, false)
                    }
                  })
                }
              } else {
                if (checked) {
                  setFieldValue("additionalInfoNoneFlag", false)
                }
              }
            }
            const handleReactiveChange = (e, checkItem) => {
              const { checked } = e.target
              setFieldValue(checkItem, checked)
              if (checkItem !== "noReactivityFlag") {
                if (checked) {
                  const filteredCheckAll1 = reactive_check.filter(
                    (item) => item.id !== "noReactivityFlag"
                  )
                  const allChecked = filteredCheckAll1.every(
                    (item) => values[item.id] || item.id === checkItem
                  )
                  setFieldValue("selectAllReactive", allChecked)
                } else {
                  setFieldValue("selectAllReactive", false)
                }
              }
              if (checkItem === "noReactivityFlag") {
                if (checked) {
                  setFieldValue("selectAllReactive", false)
                  reactive_check.forEach((item) => {
                    if (item.id !== "noReactivityFlag") {
                      setFieldValue(item.id, false)
                    }
                  })
                }
              } else {
                if (checked) {
                  setFieldValue("noReactivityFlag", false)
                }
              }
            }
            const isAdditionalInfoNoneDisabled = () => {
              return check_all.some(
                (item) =>
                  item.id !== "additionalInfoNoneFlag" && values[item.id]
              )
            }
            const isReactiveInfoNoneDisabled = () => {
              return reactive_check.some(
                (item) => item.id !== "noReactivityFlag" && values[item.id]
              )
            }
            const handleSelectAllChange = (e) => {
              const { checked } = e.target
              setFieldValue("selectAllCheck", checked)
              check_all.forEach((item) => {
                if (item.id !== "additionalInfoNoneFlag") {
                  setFieldValue(item.id, checked)
                }
                if (item.id === "additionalInfoNoneFlag") {
                  setFieldValue(item.id, false)
                }
              })
            }
            const handleSelectAllReactive = (e) => {
              const { checked } = e.target
              setFieldValue("selectAllReactive", checked)
              reactive_check.forEach((item) => {
                if (item.id !== "noReactivityFlag") {
                  setFieldValue(item.id, checked)
                }
                if (item.id === "noReactivityFlag") {
                  setFieldValue(item.id, false)
                }
              })
            }
            return (
              <Form>
                <div
                  className="ml-4"
                  style={{
                    pointerEvents: type === "viewprofile" ? "none" : "auto",
                  }}
                >
                  <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left pt-2 position-relative">
                    <div className="col-sm-12">
                      <div className="d-flex label fs-14">
                        <FormattedMessage id="profile.addnlinfo.checkall" />
                        <span style={{ color: "red" }}> *</span>
                        <div className="additinalinfo check-container mt-2">
                          <label className="additinalinfo check-container">
                            <input
                              name={values?.selectAllCheck}
                              type="checkbox"
                              checked={values?.selectAllCheck}
                              onChange={(e) => handleSelectAllChange(e)}
                              //disabled={values?.additionalInfoNoneFlag}
                            />
                            <span className={"checkmark"} />
                          </label>
                          <span
                            className="px-4 text-descriptiona"
                            style={{
                              marginLeft: "16PX",
                              fontSize: 12,
                              whiteSpace: "nowrap",
                            }}
                          >
                            Select All
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left py-2 position-relative">
                    <div className="col-sm-12">
                      <div
                        className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left p-0"
                        style={{
                          justifyContent: "flex-start",
                          flexWrap: "wrap",
                        }}
                      >
                        {check_all.map((checkItem, index) => (
                          <div
                            className="additinalinfo check-container d-flex col-sm-2 mb-1"
                            key={index + 1}
                            style={{ marginRight: 25, padding: 6 }}
                          >
                            <label
                              className={`additinalinfo check-container ${
                                isAdditionalInfoNoneDisabled() ||
                                (values.additionalInfoNoneFlag &&
                                  checkItem.id !== "additionalInfoNoneFlag")
                                  ? "text-disabled"
                                  : ""
                              }`}
                            >
                              <input
                                name={checkItem?.id}
                                type="checkbox"
                                checked={values?.[checkItem?.id]}
                                onChange={(e) => handleChange(e, checkItem?.id)}
                                disabled={
                                  checkItem.id === "additionalInfoNoneFlag"
                                    ? false
                                    : values.additionalInfoNoneFlag
                                }
                              />
                              <span className={"checkmark"} />
                            </label>
                            <span
                              className="px-4 text-descriptiona"
                              style={{
                                marginLeft: "16PX",
                                fontSize: 12,
                                whiteSpace: "nowrap",
                              }}
                            >
                              <FormattedMessage
                                id={`profile.addnlinfo.${checkItem?.value}`}
                              />
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* <span style={{ color: "red", fontSize: 10 }}>
                    {submit && checkOneof(check_all, values) ? (
                      <div>Select none, if nothing is applicable</div>
                    ) : null}
                  </span> */}
                  {/* reactivity check */}
                  <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left pt-2 position-relative">
                    <div className="col-sm-12">
                      <div className="d-flex label fs-14">
                        <FormattedMessage id="profile.addnlinfo.reactivitycheck" />
                        <span style={{ color: "red" }}> *</span>
                        <div className="additinalinfo check-container mt-2">
                          <label className="additinalinfo check-container">
                            <input
                              name={values?.selectAllReactive}
                              type="checkbox"
                              checked={values?.selectAllReactive}
                              onChange={(e) => handleSelectAllReactive(e)}
                              // disabled={values?.noReactivityFlag}
                            />
                            <span className={"checkmark"} />
                          </label>
                          <span
                            className="px-4 text-descriptiona"
                            style={{
                              marginLeft: "16PX",
                              fontSize: 12,
                              whiteSpace: "nowrap",
                            }}
                          >
                            Select All
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left py-2 position-relative">
                    <div className="col-sm-12">
                      <div
                        className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left p-0"
                        style={{
                          justifyContent: "flex-start",
                          flexWrap: "wrap",
                        }}
                      >
                        {reactive_check.map((checkItem, index) => (
                          <div
                            className="additinalinfo check-container d-flex col-sm-2 mb-1"
                            key={index + 1}
                            style={{ marginRight: 25, padding: 6 }}
                          >
                            <label
                              className={`additinalinfo check-container ${
                                isReactiveInfoNoneDisabled() ||
                                (values.noReactivityFlag &&
                                  checkItem.id !== "noReactivityFlag")
                                  ? "text-disabled"
                                  : ""
                              }`}
                            >
                              <input
                                name={checkItem?.id}
                                type="checkbox"
                                checked={values?.[checkItem?.id]}
                                onChange={(e) =>
                                  handleReactiveChange(e, checkItem?.id)
                                }
                                disabled={
                                  checkItem.id === "noReactivityFlag"
                                    ? false
                                    : values.noReactivityFlag
                                }
                              />
                              <span className={"checkmark"} />
                            </label>
                            <span
                              className="px-4 text-descriptiona"
                              style={{
                                marginLeft: "16PX",
                                fontSize: 12,
                                whiteSpace: "nowrap",
                              }}
                            >
                              <FormattedMessage
                                id={`profile.addnlinfo.${checkItem?.value}`}
                              />
                            </span>
                          </div>
                        ))}
                        <div className="d-flex m-2 col-sm-4">
                          <div
                            className="form-input-block col-sm-12"
                            style={{ marginLeft: -20 }}
                          >
                            <span className="label">
                              <FormattedMessage id="profile.addnlinfo.otherInput" />
                            </span>
                            <Input
                              name="otherReactivity"
                              value={values?.otherReactivity}
                              onFieldChange={(e) => {
                                setFieldValue("otherReactivity", e.target.value)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <span style={{ color: "red", fontSize: 10 }}>
                      {submit &&
                      (checkOneof(check_all, values) ||
                        (checkOneof(reactive_check, values) &&
                          values?.otherReactivity?.trim()?.length === 0)) ? (
                        <div>Select atleast one in each section</div>
                      ) : null}
                    </span>
                  </div>
                  {/*Back, Save and Cancel actions*/}
                  {type !== "viewprofile" && (
                    <div className="row m-0 new-row-block mt-4">
                      <div className="col-sm-10 col-md-12 col-lg-12 text-left pr-0 mt-2 ml-4">
                        <div className="row m-0 float-left">
                          <button
                            type="button"
                            className={`cancel-button`}
                            onClick={() => backAction()}
                          >
                            Back
                          </button>
                        </div>
                        <div className="row m-0 float-right m-r-2r">
                          <button
                            type="submit"
                            ref={ref}
                            className={`green-button`}
                            onClick={() => setSubmit(true)}
                          >
                            Save & Continue
                          </button>
                        </div>
                        <div className="row m-0 float-right m-r-2r">
                          <button
                            type="button"
                            className="cancel-button"
                            onClick={() => cancelAction()}
                          >
                            Cancel
                          </button>
                        </div>
                        <span className="float-right text-red m-r-2r mt-2">
                          {`*Required Fields`}
                        </span>
                      </div>
                      <AlertModal
                        showError={showError}
                        seekingStep={seekingStep}
                        setShowError={setShowError}
                        submitAction={() =>
                          handleAlerts(values, errors, isValid, seekingStep)
                        }
                        showAlert={showAlert}
                        setShowAlert={setShowAlert}
                      />
                    </div>
                  )}
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </Fragment>
  )
}

export default additionalinfoenhancer(AdditionalInfo)
