import React from "react"
import enhancer from "./enhancer"
import { carouselSetting } from "config"
import {
  AreaChartNFC,
  BarChart,
  PieChartNFC,
  BarChartT,
} from "routes/Dashboard/components/charts"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import Select from "shared/Select"
import { FormattedMessage } from "react-intl"
import DocProfile from "../DocProfile"
import Loading from "shared/Loading"

function WasteProcess(props) {
  let {
    opts,
    updateOpts,
    graphSelectOpts,
    dataForTitle,
    isTablet,
    datesForTitle,
    graphOptionWeightPickedUp,
    weightPickedUpData,
    optsWeightPickedUp,
    updateValD,
  } = props
  let pie_data = dataForTitle("wasteStream")

  return (
    <div
      className={`${
        isTablet
          ? "col-sm-12 col-md-12 col-lg-12 text-left total-waste-graph"
          : "col-sm-7 col-md-7 col-lg-7 text-left total-waste-graph"
      }`}
    >
      <Loading id="waste-process-month">
        <Loading id="waste-process-week">
          <label className="weeklylabel ">
            <FormattedMessage id="dashboard.waste" />
          </label>
          <Carousel
            showThumbs={false}
            autoPlay={carouselSetting.infiniteLoop}
            interval={carouselSetting.autoPlay}
            infiniteLoop={carouselSetting.infiniteLoop}
          >
            <div>
              <div className="text-left hazards">
                <label className="hazarLabel">
                  <FormattedMessage
                    id="dashboard.haz_waste"
                    values={datesForTitle("hazWaste")}
                  />
                </label>
                <Select
                  options={graphSelectOpts}
                  labelKey="l"
                  selected={opts.hazWaste}
                  onSelect={updateOpts("hazWaste")}
                />
              </div>
              {opts.hazWaste.value == "week" && (
                <AreaChartNFC
                  data={dataForTitle("hazWaste")}
                  height={200}
                  config={{
                    x: "day",
                    y: [{ key: "weight", label: "Hazardous Waste" }],
                    yUnit: "lbs",
                    // yDomain: [0, 50],
                  }}
                />
              )}
              {opts.hazWaste.value == "month" && (
                <BarChart
                  data={dataForTitle("hazWaste")}
                  height={200}
                  config={{
                    x: "day",
                    y: { key: "weight", label: "Hazardous Waste" },
                    yUnit: "lbs",
                    fat: true,
                    // yDomain: [0, 50],
                  }}
                />
              )}
            </div>
            <div>
              <div className="text-left hazards">
                <label className="hazarLabel">
                  <FormattedMessage
                    id="dashboard.waste_stream"
                    values={datesForTitle("wasteStream")}
                  />
                </label>
                <Select
                  options={graphSelectOpts}
                  labelKey="l"
                  selected={opts.wasteStream}
                  onSelect={updateOpts("wasteStream")}
                />
              </div>
              {pie_data[0] && (
                <PieChartNFC
                  data={dataForTitle("wasteStream")}
                  height={200}
                  label="wasteDescription"
                  value="weight"
                  yUnit="lbs"
                />
              )}
              {!pie_data[0] && (
                <div className="col-sm-12 col-md-12 col-lg-12 text-center pt-5 nodata fs13">
                  <FormattedMessage id="nodata.wastestreams" />
                </div>
              )}
            </div>
            <div>
              <div className="text-left hazards">
                <label className="hazarLabel">
                  <FormattedMessage id="dashboard.total_weight_trending" />
                </label>
                {/* <div className="wd-sp-2"> */}
                <Select
                  options={graphOptionWeightPickedUp}
                  labelKey="l"
                  selected={optsWeightPickedUp}
                  onSelect={updateValD}
                  className={"scroll-dropdown"}
                />
                {/* </div> */}
                <Loading id="waste-process-totalWeight">
                  {weightPickedUpData && (
                    <BarChartT
                      data={weightPickedUpData}
                      height={200}
                      config={{
                        x: "day",
                        y: { key: "cur", label: "Total Weight" },
                        yUnit: " lbs",
                        fat: true,
                        verticleLines: false,
                      }}
                    />
                  )}
                </Loading>
              </div>
            </div>
          </Carousel>
          {!isTablet ? <DocProfile /> : ""}
        </Loading>
      </Loading>
    </div>
  )
}
export default enhancer(WasteProcess)
