import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import { FormattedMessage } from "react-intl"

function EditProfileCheckout(props) {
  let { onReturnToMyAccountScreenClick, myAccountService } = props
  return (
    <Fragment>
      <div className="requestServiceBlock requestSuccessBlock">
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left mt-4 mb-3">
          <div className="m-0 labelgenerator">
            <FormattedMessage id={"supportform.support"} />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left">
          <div className="success-block">
            <div className="m-0 title">
              <FormattedMessage id="myaccountcheckout.success" />
            </div>
            <div className="m-0 subTitle">
              <div className="subTxtMsg">
                <FormattedMessage
                  id={"supportform.subsuccess1"}
                  values={{
                    email: myAccountService.email,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left">
          <div className="m-0 contact-title">
            <FormattedMessage id="myaccountcheckout.userinfo" />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="supportform.name" />
            </div>
            <div className="m-0 value col-10 p-0">{myAccountService.Name}</div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="supportform.account" />
            </div>
            <div className="m-0 value col-10 p-0">
              {myAccountService.CompanyName}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="supportform.role" />
            </div>
            <div className="m-0 value col-10 p-0">
              {myAccountService.roleName}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="supportform.phone" />
            </div>
            <div className="m-0 value col-10 p-0">{myAccountService.Phone}</div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="supportform.extension" />
            </div>
            <div className="m-0 value col-10 p-0">
              {myAccountService.phoneExtension}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="supportform.date" />
            </div>
            <div className="m-0 value col-10 p-0">
              {myAccountService.IssueDate}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="supportform.contactdirectly" />
            </div>
            <div className="m-0 value col-10 p-0">
              {myAccountService.isDirectContacted.toString()}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="supportform.directcontactno" />
            </div>
            <div className="m-0 value col-10 p-0">
              {myAccountService.directNumber}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              {"URL(address) of the page with issues"}
            </div>
            <div className="m-0 value col-10 p-0">
              {myAccountService.URLAddress}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="supportform.queryInDetails" />
            </div>
            <div className="m-0 value col-10 p-0">
              {myAccountService.QueryInDetail}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="supportform.webbrowser" />
            </div>
            <div className="m-0 value col-10 p-0">
              {myAccountService.BrowserName}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="supportform.operationgSystem" />
            </div>
            <div className="m-0 value col-10 p-0">
              {myAccountService.OperatingSystem}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServiceDetail text-left">
          <div className="row m-0">
            <div className="m-0 col-2 p-0 label">
              <FormattedMessage id="supportform.device" />
            </div>
            <div className="m-0 value col-10 p-0">
              {myAccountService.DeviceName}{" "}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle requestServicebutton text-center">
          <div className="row m-0">
            <div className="m-0 col-12 p-0">
              <button
                className="green-button"
                onClick={() => {
                  onReturnToMyAccountScreenClick()
                }}
              >
                <FormattedMessage id="OK" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default enhancer(EditProfileCheckout)
