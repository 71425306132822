import React from "react"
import Image from "shared/HelpImage/index"

function CreateNewUser() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Create a New User</h3>
      <div className="img-section">
        <Image
          src="Create_New_User.png"
          alt="Change Request (Service Details) Image"
        />
      </div>
      <p className="text-justify pl-3 pt-2">
        <p>
          Enter the new user’s first name, last name, and email address into the
          appropriate fields as shown. Select User Type from the drop-down menu.
          Company Account will be listed and will not be able to be changed.
          Confirm your entries and then select Create New User.
        </p>
      </p>
    </div>
  )
}

export default CreateNewUser
