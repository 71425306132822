import React, { Fragment } from "react"
import Select from "shared/Select"
import Image from "shared/Image"
import classnames from "classnames"
import { FormattedMessage } from "react-intl"
import { formattedDate, useIsTablet } from "shared"
import _ from "lodash"

function LeftServiceSection(props) {
  let {
    setActiveService,
    activeService,
    serviceData,
    sortBy,
    SortByService,
    updatedupdateSortByOption,
  } = props
  let { items = [] } = serviceData
  /** Below function is used to render respective icons on workOrderType */
  function getIconSource(d) {
    if (d?.workOrderType === "Shipped") {
      return "All_ServiceTypes_PickedUp_Icon.svg"
    } else if (d?.workOrderType === "NoShip") {
      return "Full_No_Ship_Icon.svg"
    } else {
      return "Partial_No_Ship_Icon.svg"
    }
  }
  let isTablet = useIsTablet()
  return (
    <Fragment>
      <div
        className={` ${
          isTablet
            ? "col-sm-5 col-md-5 col-lg-5 text-left service-tab "
            : "col-sm-4 col-md-4 col-lg-4 text-left service-tab "
        }`}
      >
        <div className="row m-0">
          <div className={`col-sm-4 col-md-4 col-lg-4 text-left p-0 `}>
            <label className="service-label">
              <FormattedMessage id="myschedule.services" />
            </label>
          </div>

          <div
            className={` ${
              isTablet
                ? "col-sm-8 col-md-8 col-lg-8 sortybysection p-0  "
                : "col-sm-8 col-md-8 col-lg-8 text-left p-0 "
            }`}
          >
            <label className="sort-label">
              <FormattedMessage id="myschedule.sortby" />
            </label>
            <label>
              {SortByService && (
                <Select
                  label="Please select"
                  options={SortByService}
                  // onSelect={updateSortBy}
                  onSelect={(i) => updatedupdateSortByOption(i)}
                  labelKey="l"
                  className="sort-dropdown"
                  selected={sortBy}
                />
              )}
            </label>
          </div>
          <div className="each-services-block w-100">
            {items.map((d, i) => (
              <div
                key={d.workOrderId}
                className={classnames("each-service", {
                  active: d.workOrderId == activeService?.workOrderId,
                })}
                onClick={() => setActiveService(d)}
              >
                <div className="row m-0">
                  <div className="col-sm-2 col-md-2 col-lg-2 text-left p-0">
                    <div className="calendar-icon">
                      <span className="icon-img">
                        <Image
                          className=""
                          src={getIconSource(d)}
                          width="30px"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 text-left pr-0">
                    <div className="service-titlea">
                      <FormattedMessage id="myschedule.locationNo" />
                      {d.storeNumber || "NA"}
                    </div>
                    <div className="service-title">
                      <FormattedMessage id="requestservicecheckout.addresslabel" />
                      <span className="service-titles">
                        {d.storeAddress?.address}
                      </span>
                    </div>
                    <div className="service-titles">
                      {d.storeAddress?.city}, {d.storeAddress?.state},{" "}
                      {d.storeAddress?.country}
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4 text-center right-block p-0">
                    <div className="service-label">
                      {formattedDate(d.formatTime)}
                    </div>
                    <div className="service-date">
                      <label className="service-button">
                        {!_.isEmpty(d.serviceTypes) &&
                          d?.serviceTypes.map((d, i) => (
                            <span className="" key={i}>
                              {d.serviceType}
                            </span>
                          ))}
                      </label>
                    </div>
                  </div>
                </div>
                {d?.workOrderType !== "NoShip" && (
                  <div className="row spacing-div">
                    <div className="col-3 myorderWaste_box">
                      <FormattedMessage id="requestservicecheckout.hasWaste" />
                      <span className="service-titles">
                        {d?.hazWeight + " lbs"}
                      </span>
                    </div>
                    <div className="col-3 myorderWaste_box ml-point-5">
                      <FormattedMessage id="requestservicecheckout.totalWaste" />
                      <span className="service-titles">
                        {d?.totalWeight + " lbs"}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default LeftServiceSection
