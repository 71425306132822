import React from "react"
import { useHistory } from "react-router"

let enhancer = (Component) => () => {
  const history = useHistory()

  const onLoginClick = () => {
    history.replace("/")
  }

  return (
    <Component
      {...{
        onLoginClick,
      }}
    />
  )
}

export default enhancer
