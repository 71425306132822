import React from "react"

function ExceptionGlossary() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Exceptions Glossary</h3>
      <p className="text-justify pl-3 pt-2">
        <p>
          <b>Summary:</b> Exceptions (as defined and requested by client)
          reported during site services by our technicians. Exceptions are
          presented in descending order, from most frequent exception to least.
          If exceptions listing exceeds page size, additional page number
          selections will be found at the bottom of the display area.
        </p>
        <p>
          <b>
            NOTE: Not all exceptions will apply to every Clean Earth customer.
          </b>
        </p>
        <p>
          <b>Specific Exceptions</b>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              Accumulation Tote Missing Label Pouch – Accumulation totes are
              missing required label pouches.{" "}
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              Accumulation Totes Not Utilized – Materials are being accumulated,
              but not in the program-designated containers. Waste must be
              segregated into totes with lids.
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              Accumulation Labels Not Utilized – Containers of waste are stored
              in the ESIM area without labels. This could constitute a violation
              on inspection and potentially create an unsafe condition.
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              DEA Left Behind (R/X Order Only) – DEA waste was left behind and
              should have been removed.
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              Did Not Ship Pharmacy – There is no accumulation of pharmacy waste
              (excluding controlled substances which has a different exception).
              No accumulation of pharma waste could be an indication of possible
              improper management of potential pharmacy waste.
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              Did Not Ship Recall – The location is part of a possible Recall or
              Project and did not present any waste to ship.
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              Did Not Ship Retail – No retail waste was accumulated at the time
              of service, indicating a lack of compliance with the program, and
              potential improper disposal of waste.{" "}
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              {" "}
              Exceeded 2.2 LB EPA Acute per Month – The location manifested more
              than 2.2 lbs. of EPA P-listed waste, potentially resulting in a
              change in generator status.
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              Exceeded 220 lbs EPA Waste – The location’s manifest exceeded 220
              lbs. EPA hazardous waste, potentially resulting in a change in
              generator status.
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              No Photo Accumulation – No filters from the photo department
              accumulated for disposal. This could indicate improper disposal of
              photo filters or a severe lack of maintenance of the photo
              equipment.
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              No RX Accumulation – No controlled pharmaceutical substances or
              empty bottles were accumulated for the service period. This could
              indicate that expired and/or non-credit value controls management
              is not in compliance.
            </div>
          </div>
        </p>
        <p>
          {" "}
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              No Ship-Store Closed – We were unable to access the location
              because it was closed. We no-shipped the order.{" "}
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              Non-leaking DEA waste – Non-leaking DEA waste was presented to the
              technician.
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              {" "}
              Not Adding Absorbent – Materials were accumulated having free
              liquids without the addition of absorbent to prevent leakage.{" "}
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              {" "}
              Not Participating in Program (Refusal) - One of the designated
              contacts at the location has informed the technician that their
              location is refusing participation in the program.
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              Not Participating in Program (Unaware) – One of the designated
              contacts at the location has informed the technician that they are
              unaware of the program.{" "}
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              Not Using Bags – Location is not using self-sealing bags to
              provide segregation of accumulated materials.
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              Pallets left behind – We were not able to remove all the waste and
              pallets were left behind.
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              Photo Waste Found Onsite – Accumulated wet photo waste was found
              onsite.
            </div>
          </div>
        </p>
        <p>
          <div className="disp-flex">
            <div className="disp-flex"> {" •"}</div>
            <div className="p-8">
              RCRA Haz DEA Left Behind – Accumulated RCRA Hazardous DEA waste
              was left behind. Unable to ship it due to lack of a witness.
            </div>
          </div>
        </p>
      </p>
    </div>
  )
}
export default ExceptionGlossary
