import React from "react"
import Image from "shared/HelpImage/index"

function ChangeRequest() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">
        New Request: Change Request (Service Details)
      </h3>
      <p className="text-justify pl-3 pt-2">
        <p>Request changes be made for a scheduled request using this form.</p>
        <p>
          The contact information of the user is pre-populated. There is an
          option to cc other email addresses to receive confirmation of your
          request once it is submitted.
        </p>
        <p>Under Request details, select the Store from the drop-down menu.</p>
        <p>
          Select a date on the calendar for when you would like this service
          rescheduled to.
        </p>
        <p>
          Provide additional details for rescheduling this service in the space
          provided and hit submit request button.
        </p>
        <p>
          You will receive notification on your screen that your request was
          submitted successfully along with a confirmation number
        </p>
      </p>
      <div className="img-section">
        <Image
          src="Request_Service_Change_Request.png"
          alt="Change Request (Service Details) Image"
        />
      </div>
    </div>
  )
}
export default ChangeRequest
