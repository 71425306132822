import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import {
  getCurrentConfig,
  getReduxStates,
  hasToggleButton,
  getToggleButtons,
} from "./config"
import _, { isNil } from "lodash"
import { toast } from "react-toastify"
import { sub, startOfMonth, endOfMonth, isBefore } from "date-fns"
import { isHomeDepotSiteUser, isRbsCorporateUser, useHarsco } from "shared"
import { format } from "date-fns"

let enhancer = (Component) => (props) => {
  let { onSubmit, Onreset, OnHeadFilterChange } = props
  const [config, setConfing] = useState([])
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [stateType, setStateType] = useState("")
  const [shallSubmit, setShallSubmit] = useState(false)
  let [isToggleButton, setToggleButton] = useState(false)
  let [isToggleHeadButton, setToggleHeadButton] = useState(false)
  let [toggleButtons, setToggleButtons] = useState([])
  let [toggleHeadButtons, setToggleHeadButtons] = useState([])
  let [toggleStatus, setToggleStatus] = useState("")
  let [toggleHeadStatus, setToggleHeadStatus] = useState("Filters")
  const reduxstore = useSelector((state) => state.stores)
  const configTypes = useSelector((state) => state.config)
  const savedFilters = useSelector((state) => state.filters)
  const dispatch = useDispatch()
  const { isFullCircle } = useHarsco()
  const reduxGenerator = useSelector((state) => state.generators)

  let toggleButton, toggleHeadButton

  let { type } = useParams()
  let { pathname, state = {} } = useLocation()
  // let { scheduleTypes = [], serviceTypes = [] } = configTypes
  let today = new Date()

  const { stores, isHomeDepot } = getReduxStates()
  let storePairs = {}
  stores?.forEach((d) => {
    if (!storePairs[d.storeNumber])
      storePairs[d.storeNumber] = { states: [], divisions_regions: [] }
    let store = storePairs[d.storeNumber]
    if (!store.states.includes(d.state)) store.states.push(d.state)
    if (isHomeDepot && !store.divisions_regions.includes(d.region))
      store.divisions_regions.push(d.region)
  })
  let selecedStores = stores
    ?.sort((a, b) =>
      a.storeNumber?.localeCompare(b.storeNumber, "en", { numeric: true })
    )
    .map((d) => d.storeNumber)
  selecedStores = selecedStores?.map((d3, i) => {
    let obj = { l: d3, v: d3, index: i }
    obj.storePair = storePairs[d3]
    return obj
  })

  let updateStoreFilters = (config) =>
    dispatch({ type: "SET_FILTERS", filters: config })

  useEffect(() => {
    if (
      pathname.includes("requestserviceHistory") ||
      pathname.includes("requestserviceView")
    )
      setShallSubmit(true)
  }, [])

  useEffect(() => {
    if (shallSubmit && config[0]) {
      submit()
      setShallSubmit(false)
    }
  }, [config, shallSubmit])

  useEffect(() => {
    let configC = []
    pathname = pathname.replace("/", "")
    if (pathname == "documentlibrary/ldr") type = "historicalldrs"
    else if (
      [
        "myschedule",
        "documentlibrary",
        "historicalldrs",
        "myprofile",
        "mystoreschedule",
        "invoices",
        "requestserviceView",
        "requestserviceHistory",
        "myhistory",
        "documentlibrarystores",
        "annual_schedule_report",
      ].includes(pathname)
    ) {
      type = pathname
    }
    setStateType(type)

    if (
      ["exception_report", "exception_report_summary"].includes(type) &&
      savedFilters?.["exception_report"]?.length
    ) {
      setFilterToggle(type)
      setFilterHeadToggle(type)
      return setConfing(savedFilters["exception_report"])
    }

    if (savedFilters?.[type]?.length && !state.fiscal_span) {
      setFilterToggle(type)
      setFilterHeadToggle(type)
      return setConfing(savedFilters[type])
    }

    // let { scheduleTypes = [], serviceTypes = [] } = configTypes
    // if (reduxstore?.[0] && serviceTypes?.[0] && scheduleTypes?.[0]) {
    configC = getCurrentConfig(type)
    toggleButton = hasToggleButton(type)
    toggleHeadButton = hasToggleButton(type)

    if (state.document_type) {
      setFilterToggle(type)
      setFilterHeadToggle(type)
      setShallSubmit(true)
      let { document_type, documentTypes } = state
      let docTypes = configC.find((d) => d.key == "docTypes").options
      if (document_type == "all")
        docTypes?.forEach((d) => {
          if (documentTypes.includes(d?.l)) d.selected = true
        })
      else
        docTypes.some((d) => {
          if (d?.l.includes(document_type)) {
            d.selected = true
            return true
          }
          return false
        })
      if (state.fiscal_span)
        configC.forEach((d) => {
          if (d.key == "dates") d.value = state.fiscal_span
        })

      if (type === "documentlibrary") {
        configC = configC.filter((ele) => ele.isFullCircle === isFullCircle)
      }

      return setConfing(configC)
    }
    if (type == "myschedule") {
      configC.forEach((d) => {
        if (["service_type", "schedule_type"].includes(d.key))
          d.options.forEach((d2) => (d2.selected = true))
        if (state.fiscal_span && d.key == "fiscal_span")
          d.value = state.fiscal_span
      })
      if (state.fiscal_span) {
        setShallSubmit(true)
      }
    }
    if (type == "myprofile") {
      configC.forEach((d) => {
        if (state.fiscal_span && d.key == "fiscal_span")
          d.value = state.fiscal_span
      })
      if (state.fiscal_span) {
        setShallSubmit(true)
      }
    }
    if (type === "profile_report" || type === "invoice_report") {
      configC.forEach((d) => {
        if (state.fiscal_span && d.key == "fiscal_span")
          d.value = state.fiscal_span
      })
      if (state.fiscal_span) {
        setShallSubmit(true)
      }
    }

    // const isDivisionRegion = configC.some((d) => d.key == "divisions_regions")
    configC?.forEach((d, i) => {
      if (d.key === "divisions_regions") {
        d.value = Object.values(d?.options).flat()
      }
      if (["docTypes"].includes(d.key) && d.type != "single") {
        d.options.forEach((d2) => (d2.selected = true))
      }
      if (
        ["docTypes"].includes(d.key) &&
        d.type == "single" &&
        d.isDocumentOption
      ) {
        // d.value = "Null"//d.options.find((d1) => d1.l === "None")//d.options[0]
      }
      if (d.key === "exception_type") {
        d.options.forEach((d2) => {
          d2.selected = true
        })
      }
      if (
        [
          "waste_summary_shipment_report",
          "exception_report",
          "exception_report_summary",
          "annual_schedule_report",
        ].includes(type)
      ) {
        if (d.key.includes("time_presets")) {
          d.value = d.options.find((d1) => d1.l === "Last 90 Days")
        }
        if (d.type == "date") {
          d.value = { from: sub(today, { days: 90 }), to: today }
        }
      }
      if (type.includes("annual_schedule_report")) {
        if (["service_type"].includes(d.key))
          d.options.forEach((d2) => (d2.selected = true))
      }
      if (type.includes("general_status")) {
        if (d.key.includes("time_presets")) {
          d.value = d.options.find((d1) => d1.l === "Last Month")
        }
        if (d.type == "month_year") {
          const day = sub(today, { months: 1 })

          let pathNameArr = pathname.split("/")
          if (
            pathname?.includes("general_status") &&
            !(pathNameArr.length > 2)
          ) {
            configC[i - 1].value = configC[i - 1].options.find(
              (d1) => d1.l === "Last Month"
            )
            d.value = { from: startOfMonth(day), to: endOfMonth(day) }
          } else configC[i - 1].value = false
          //to do for future reference
          //configC[i - 1].options.find((d1) => d1.l === "None")
        }
      }
      if (type == "requestserviceHistory" || type == "requestserviceView") {
        if (d.key == "requestType" || d.key == "reqStatus") {
          d.options.forEach((d1) => {
            d1.selected = true
            if (d.key == "reqStatus" && d1.v == "Closed") d1.selected = false
          })
        }
        if (d.key == "time_presets_quarter") {
          d.value = d.options.find((d1) => d1.l === "Custom")
          d.options = d.options.filter((d1) => d1.l != "Last Year")
          d.options.forEach((o) => {
            if (isBefore(o.v.from, new Date("12-01-2021")))
              o.v.from = new Date("12-01-2021")
          })
        }
        if (d.key == "dates") {
          if (d.value?.from) d.value.from = new Date("12-01-2021")
        }
        if (isFullCircle) {
          if (d.key == "time_presets")
            d.value = d?.options.find((d1) => d1.l === "Year To Date")
          if (d.key == "dates")
            d.value = configC
              .find((d1) => d1.key === "time_presets")
              ?.options.find((d2) => d2.l == "Year To Date").v
        }
      }
    })
    if (type === "documentlibrary") {
      configC = configC.filter((ele) => ele.isFullCircle === isFullCircle)
    }

    setFilterToggle(type)
    setFilterHeadToggle(type)
    setConfing(configC)
    // }
  }, [reduxstore, configTypes])

  const setFilterToggle = (type) => {
    if (!isHomeDepotSiteUser()) {
      toggleButton = hasToggleButton(type)
      setToggleButton(toggleButton)
      let buttons = getToggleButtons(type)
      setToggleButtons(buttons)
    }
  }

  const setFilterHeadToggle = (type) => {
    toggleHeadButton = hasToggleButton(type)
    setToggleHeadButton(toggleHeadButton)
    let buttonsHead = getToggleButtons(type, "Head")
    setToggleHeadButtons(buttonsHead)
  }

  const updateToggleStatus = (toggleStatus) => {
    setToggleStatus(toggleStatus)
  }

  const updateToggleHeadStatus = (toggleHeadStatus) => {
    if (toggleHeadStatus === "Document#") headReset()
    OnHeadFilterChange?.()
    clearDocNumber(toggleHeadStatus)
    // clear()
    setToggleHeadStatus(toggleHeadStatus)
  }

  const checkPostError = (values, restrictions) => {
    let {
      divisions_regions,
      time_present_future_year,
      brands,
      states,
      stores,
      orgStores,
      reqStatus,
      customers,
      cities,
      // dates,
      docTypes,
      docNumber,
    } = values

    const { storeId } = getReduxStates()
    // if (!orgStores && stores.length > 200) {
    //   toast.warn("Selected stores should be less than 200")
    //   return false
    // }
    if (
      (time_present_future_year === undefined ||
        _.isEmpty(time_present_future_year)) &&
      type === "annual_schedule_report"
    ) {
      let errorMsg = "Please select at least one year"
      toast.warn(errorMsg, { autoClose: 3000 })
      return false
    }
    if (storeId) return true
    if (stateType == "historicalldrs" && _.isEmpty(stores) && !isFullCircle)
      return toast.warn("Please select store Id")

    if (
      !reqStatus &&
      _.isEmpty(stores) &&
      !orgStores &&
      toggleStatus === "Stores" &&
      toggleHeadStatus !== "Document#" &&
      state.fiscal_span === undefined &&
      !values["allSelect"] &&
      !isFullCircle
    ) {
      let errorMsg = "Please select at least one store to proceed"
      toast.warn(errorMsg, { autoClose: 3000 })
      return false
    }

    if (
      toggleHeadStatus === "Document#" &&
      docTypes.length == 0 &&
      !isFullCircle
      // && !reqStatus
    ) {
      let errorMsg = "Please select atleast one document type."
      toast.warn(errorMsg, { autoClose: 3000 })
      return false
    }

    if (
      toggleHeadStatus === "Document#" &&
      docNumber.length < 4 &&
      !isFullCircle
      // && !reqStatus
    ) {
      // if (stateType == "documentlibrary" &&
      //   toggleHeadStatus === "Document#" && d?.value.length < 4) {
      //   toast.warn("")
      //   return
      // }
      let errorMsg = "Document No should be of atleast 4 digits."
      toast.warn(errorMsg, { autoClose: 3000 })
      return false
    }

    if (
      !reqStatus &&
      _.isEmpty(states) &&
      !orgStores &&
      toggleStatus === "States" &&
      toggleHeadStatus !== "Document#" &&
      !values["allSelect states"] &&
      !isFullCircle
    ) {
      let errorMsg = "Please select at least one state to proceed"
      toast.warn(errorMsg, { autoClose: 3000 })
      return false
    }

    if (
      !reqStatus &&
      _.isEmpty(divisions_regions) &&
      !orgStores &&
      toggleStatus === "Divisions, Regions" &&
      !values["allSelect divisions_regions"] &&
      isHomeDepot &&
      !isFullCircle
    ) {
      let errorMsg = "Please select at least one Division/ Region to proceed"
      toast.warn(errorMsg, { autoClose: 3000 })
      return false
    }
    if (
      !reqStatus &&
      _.isEmpty(brands) &&
      !orgStores &&
      toggleStatus === "Brands" &&
      toggleHeadStatus !== "Document#" &&
      !values["allSelect"] &&
      isRbsCorporateUser() &&
      !isFullCircle
    ) {
      let errorMsg = "Please select at least one brand to proceed"
      toast.warn(errorMsg, { autoClose: 3000 })
      return false
    }
    if (
      _.isEmpty(stores) &&
      toggleStatus === "Stores" &&
      !isFullCircle &&
      !values["allSelect stores"]
    ) {
      let errorMsg = "Please select at least one store to proceed"
      toast.warn(errorMsg, { autoClose: 3000 })
      return false
    }

    if (
      !reqStatus &&
      _.isEmpty(stores) &&
      !toggleStatus &&
      !state.fiscal_span &&
      !isFullCircle &&
      stateType !== "requestserviceHistory" &&
      toggleStatus === "Stores"
    ) {
      let errorMsg = "Please select at least one store to proceed"
      toast.warn(errorMsg, { autoClose: 3000 })
      return false
    }

    if (
      stateType === "documentlibrary" &&
      _.isEmpty(customers) &&
      isFullCircle
    ) {
      let errorMsg = "Please select at least one generator name to proceed"
      toast.warn(errorMsg, { autoClose: 3000 })
      return false
    }

    if (
      (stateType === "myhistory" || stateType === "invoices") &&
      !values["allSelect"] &&
      _.isEmpty(states) &&
      _.isEmpty(cities) &&
      _.isEmpty(customers) &&
      isFullCircle
    ) {
      let errorMsg =
        "Please select either of the Generator state, city or name to proceed"
      toast.warn(errorMsg, { autoClose: 3000 })
      return false
    }

    return true
  }
  const hasError = () => {
    return config.find((d) => {
      if (d.required) {
        if (d.type == "multiple" && d.options.find((d1) => d1.selected))
          return false
        if (
          isHomeDepotSiteUser() &&
          d.key == "service_type" &&
          d.options.length === 0
        ) {
          let errorMsg = "No service types are available for this store"
          return toast.warn(errorMsg, { autoClose: 3000 })
        } else if (
          isHomeDepotSiteUser() &&
          d.key == "service_type" &&
          d.options.length >= 0
        ) {
          let errorMsg = "Please select the service types"
          return toast.warn(errorMsg, { autoClose: 3000 })
        }
        if (
          d.type == "multiple" &&
          d.key == "docTypes" &&
          toggleHeadStatus === "Document#"
          // && d.options.find((d1) => d1.selected)
        )
          return false

        if (d.type == "single" && d?.value) return false
        if (
          d.type == "single" &&
          d.key == "docTypes" &&
          toggleHeadStatus !== "Document#"
        )
          return false
        if (d.type == "grouped" && d?.value?.[0]) return false
        if (d.type == "text") {
          if (isNil(d?.value)) d.value = ""
          if (toggleHeadStatus !== "Document#" && d?.value == "") return false
          else if (toggleHeadStatus === "Document#" && d?.value != "")
            return false
        }
        return true
      }
      if (d.type == "date" && toggleHeadStatus === "Document#") {
        let toDate = format(new Date(d?.value?.to), "yyyy-MM-dd")
        let fromDate = format(new Date(d?.value?.from), "yyyy-MM-dd")
        const daysBetween = Math.floor(
          Math.abs(new Date(toDate) - new Date(fromDate)) /
            (1000 * 60 * 60 * 24)
        )
        if (daysBetween > 365) return true
      }
      return false
    })
  }
  const divisionsCount = (obj) => {
    let totalCount = 0

    // eslint-disable-next-line
    for (const key in obj) {
      const array = obj[key]
      // eslint-disable-next-line
      for (const item of array) {
        if (item !== "") {
          totalCount++
        }
      }
    }

    return totalCount
  }

  const submit = (stateType = null) => {
    setHasSubmitted(true)
    if (hasError()) return
    let values = {},
      restrictions = {}
    config.forEach((d) => {
      if (["month_year", "grouped", "date"].includes(d.type))
        values[d.key] = d.value
      if (
        (stateType == "documentlibrary" || state?.document_type) &&
        // && toggleHeadStatus !== "Document#"
        d.key == "docTypes"
      ) {
        if (d.type == "multiple" && toggleHeadStatus !== "Document#")
          values[d.key] = d.options.reduce((selected, d2) => {
            if (d2.selected) selected.push(d2.v)
            return selected
          }, [])
        if (d.type == "single" && toggleHeadStatus === "Document#")
          values[d.key] = [d.value?.v]
      } else {
        if (d.type == "multiple")
          values[d.key] = d.options.reduce((selected, d2) => {
            if (d2.selected) selected.push(d2.v)
            return selected
          }, [])
        if (d.type == "single") values[d.key] = d.value?.v
      }
      if (d?.limitoptions) restrictions[d.key] = d.limitoptions

      if (d?.type == "text") {
        values[d?.key] = d?.value || ""
      }

      if (
        d.key === "states" ||
        d.key === "cities" ||
        d.key === "customers" ||
        d.key === "brands" ||
        d.key === "stores"
      ) {
        if (
          toggleStatus === d?.toggleButtonLabel &&
          values[d.key].length === d.options.length
        ) {
          values[d.key] = []
          values["allSelect"] = true
          values[`allSelect ${d.key}`] = true
        }
      } else if (d.key === "divisions_regions") {
        if (
          toggleStatus === d?.toggleButtonLabel &&
          divisionsCount(d.options) === d.value.length
        ) {
          values[d.key] = []
          values["allSelect"] = true
          values[`allSelect ${d.key}`] = true
        }
      } else if (d.key === "service_type") {
        if (values[d.key].length === d.options.length) {
          values["allSelect"] = true
          values[`allSelect ${d.key}`] = true
        }
      }
      if (toggleStatus && toggleButtons.includes(d?.toggleButtonLabel)) {
        if (
          d?.toggleButtonLabel !== toggleStatus &&
          toggleHeadStatus !== "Document#"
        ) {
          values[d.key] = []
        }
      }
    })

    if (checkPostError(values, restrictions, config))
      onSubmit?.(values, toggleHeadStatus)
    // eslint-disable-next-line
    console.log(values)

    if (state.fiscal_span) {
      state.document_type = ""
      state.fiscal_span = ""
      return
    }
    if (["exception_report", "exception_report_summary"].includes(type))
      updateStoreFilters({ exception_report: config })
    else updateStoreFilters({ [stateType]: config })
  }

  const reset = () => {
    setHasSubmitted(false)
    let c2 = _.cloneDeep(config)
    c2.forEach((d) => {
      if (d.type == "multiple") d.options.forEach((d) => (d.selected = false))
      if (d.type == "single") d.value = false
      if (d.type == "text") d.value = ""
      if (d.type == "grouped") d.value = []
      if (["month_year", "date"].includes(d.type))
        d.value = { from: new Date(), to: new Date() }
      if (d.key == "stores" && d.type != "single") d.options = selecedStores
    })
    setConfing(c2)
    Onreset?.()
    updateStoreFilters({ [stateType]: [] })
  }

  const headReset = () => {
    setHasSubmitted(false)
    let c2 = _.cloneDeep(config)
    c2.forEach((d) => {
      if (d.type === "multiple") d.options.forEach((d) => (d.selected = false))
      if (d.type === "single") d.value = false
      if (d.type === "text") d.value = ""
      if (d.type === "grouped") d.value = []
      if (["month_year", "date"].includes(d.type))
        d.value = { from: new Date(), to: new Date() }
      if (d.key === "stores" && d.type != "single") d.options = selecedStores
    })
    setConfing(c2)
    Onreset?.()
    updateStoreFilters({ [stateType]: [] })
  }

  const UpdateAlreadySelectedStores = (
    currentStores,
    allreadySelectedStores
  ) => {
    if (currentStores.length > 0) {
      currentStores.forEach((ele) => {
        let obj = {}
        if (allreadySelectedStores?.length > 0) {
          let found = allreadySelectedStores.some((x) => x.l === ele.l)
          if (found) {
            obj = allreadySelectedStores.find((x) => x.l === ele.l)
            ele.selected = obj.selected
          }
        }
      })
    }
    return currentStores
  }

  const checkStoresCount = (headfilter, value) => {
    let isMaxLimit = false
    headfilter.forEach((d1) => {
      let maxlimit = 0
      if (d1.key == "stores") {
        if (value?.length === d1?.options.length) {
          return false
        }
        if (d1.options?.length > 0) {
          d1.options.forEach((ele) => {
            if (ele.selected == true) {
              maxlimit = maxlimit + 1
            }
          })
          if (
            maxlimit > d1.limitoptions &&
            !pathname?.includes("documentlibrary/ldr")
          ) {
            isMaxLimit = false
          }
        }
      }
    })
    return isMaxLimit
  }
  const checkStatesSelectedOrNot = (headfilter, d) => {
    let isStateSelected = false
    if (d.key == "states") {
      headfilter.forEach((d1) => {
        if (d1.key == "states")
          for (let i = 0; i < d1.options.length; i++) {
            if (d1.options[i].selected) {
              isStateSelected = true
              break
            }
          }
      })
    }
    return isStateSelected
  }

  const update = (d, i) => (val) => {
    let c2 = _.cloneDeep(config)
    let fval = val

    if (d.type == "multiple") {
      c2[i].options = val
      fval = val.filter((d) => d.selected).map((d) => d.l)
    }

    if (["single", "grouped", "month_year", "date"].includes(d.type))
      c2[i].value = val

    if (["month_year", "date"].includes(d.type)) {
      if (stateType == "documentlibrary" && toggleHeadStatus !== "Document#") {
        let ele = c2[i - 2]
        ele.value = _.last(ele.options)
      } else {
        let ele = c2[i - 1]
        ele.value = _.last(ele.options)
      }
    }

    if (d.key.includes("time_presets") && d?.headLabel == "Filters") {
      c2[i + 2].value = val.v
    } else if (d.key.includes("time_presets")) c2[i + 1].value = val.v

    if (d.type === "text") c2[i].value = val?.currentTarget?.value || ""
    c2.forEach((d1) => {
      if (d.key == "divisions_regions" || d.key == "states") {
        if (d1.key == "stores" && !d.toggletype) {
          let allreadySelectedStores = []

          if (d1.options?.length > 0) {
            allreadySelectedStores = d1.options
          }
          d1.options = []
          selecedStores.forEach((d3) => {
            d3.selected = false
            if (!_.isEmpty(_.intersection(d3.storePair[d.key], fval)[0])) {
              d1.options.push(d3)
            }
          })
          let arr = UpdateAlreadySelectedStores(
            d1.options,
            allreadySelectedStores
          )
          d1.options = arr
        }
        if (d1.key === "stores" && d.toggletype) {
          d1.options.forEach((d3) => {
            d3.selected = false
          })
        }
      }
      if (d.key == "stores" || d.key == "states") {
        if (d1.key == "divisions_regions") d1.value = []
      }
    })

    if (!checkStatesSelectedOrNot(c2, d)) {
      if (d.key == "states") {
        c2.forEach((d4) => {
          if (d4.key == "stores") {
            d4.options = selecedStores
          }
        })
      }
    }

    if (d.key === "divisions_regions") {
      c2.forEach((d4) => {
        if (d4.key == "stores") {
          d4.options = selecedStores
        }
      })
    }

    if (d.key === "stores") {
      //restricting user to select more than 10 stores
      if (checkStoresCount(c2, fval)) {
        return
      } else {
        setConfing(c2)
      }
    } else {
      setConfing(c2)
    }
  }

  const clearDocNumber = (toggleLabel) => {
    let c2 = _.cloneDeep(config)
    c2.forEach((d, i) => {
      if (d.type == "text") c2[i].value = ""
      if (d.type == "single") c2[i].value = false
      //to do for future reference
      // if (toggleLabel === "Document#")
      if (["month_year", "date"].includes(d.type))
        d.value = { from: new Date(), to: new Date() }
    })
    setConfing(c2)
  }

  const clear = (d, i) => () => {
    let c2 = _.cloneDeep(config)

    if (d.type == "multiple") c2[i].options.forEach((d) => (d.selected = false))
    if (d.type == "single") c2[i].value = false
    if (d.type == "grouped") {
      c2[i].value = []
    }
    if (d.type == "text") c2[i].value = ""

    if (d.key == "divisions_regions" || d.key == "states") {
      c2.forEach((d1) => {
        if (d1.key == "stores") d1.options = selecedStores
      })
    }

    setConfing(c2)
  }

  return (
    <Component
      {...props}
      {...{
        update,
        reset,
        headReset,
        config,
        clear,
        submit,
        hasError,
        hasSubmitted,
        loaded: reduxstore?.[0] || reduxGenerator?.[0], //&& serviceTypes?.[0] && scheduleTypes?.[0],
        stateType,
        isToggleButton,
        toggleButtons,
        updateToggleStatus,
        isToggleHeadButton,
        toggleHeadButtons,
        updateToggleHeadStatus,
        toggleHeadStatus,
        // serviceHistory
      }}
    />
  )
}

export default enhancer
