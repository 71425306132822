import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { ReactPlugin } from "@microsoft/applicationinsights-react-js"

const { REACT_APP_INSTRUMENTATION_KEY } = process.env

var reactPlugin = new ReactPlugin()
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: REACT_APP_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    extensionConfig: {
      [reactPlugin.identifier]: { React: reactPlugin },
    },
    ignoreHeaders: ["Authorization"],
  },
})
appInsights.loadAppInsights()

const trackEvent = (properties) => {
  if (REACT_APP_INSTRUMENTATION_KEY != "none") {
    appInsights.trackEvent({ name: "Track API", properties })
  }
}

const trackCustomEvent = (name) => {
  if (REACT_APP_INSTRUMENTATION_KEY != "none" && name.properties != [])
    appInsights.trackEvent({ name: name.name, properties: name.properties })
}

export { reactPlugin, trackEvent, trackCustomEvent }
