import React from "react"
import Image from "shared/HelpImage/index"

function ServiceRequest() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Service Requests</h3>
      <div className="img-section">
        <Image src="servicerequests.png" alt="Report Service Issue Image" />
      </div>
      <p className="text-justify pl-3 pt-2">
        <p>Under the Service Requests menu.</p>
        <p>The user can:</p>
        <p> - Create new requests</p>
        <p>- Track historical requests</p>
      </p>
    </div>
  )
}

export default ServiceRequest
