import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import { FormattedMessage } from "react-intl"

function Footer(props) {
  return (
    <Fragment>
      <footer className="text-center">
        <p className="content">
          &#169; <FormattedMessage id="footer.message" />
        </p>
      </footer>
    </Fragment>
  )
}
export default enhancer(Footer)
