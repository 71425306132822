import React from "react"
import Image from "shared/HelpImage/index"

function HistoricalLDR() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3 ">Historical LDRs</h3>
      <p className="text-justify pl-3 pt-2">
        <p>From this screen you can search and display historical LDRs.</p>
        <div className="img-section">
          <Image className="" src="LDR_home_depot.png" alt="GenStatus Image" />
        </div>
        <p>
          Press the down arrow next to Stores, then select the store number for
          which you want to see historical LDRs. Then press the Apply button.
        </p>
        <div className="img-section ">
          <Image
            className=""
            src="Home_depot_store_selection.png"
            alt="GenStatus Image"
          />
        </div>
        <p>You will see a screen for the store you chose.</p>
        <div className="img-section ">
          <Image
            className=""
            src="LDR_home_depot_result.png"
            alt="GenStatus Image"
          />
        </div>
        <p>From this screen you can quick view a historical LDR.</p>
        <div className="img-section ">
          <Image
            className=""
            src="LDR_home_depot_card.png"
            alt="GenStatus Image"
          />
        </div>
        <p>You can download a historical LDR to your computer.</p>
        <div className="img-section ">
          <Image
            className=""
            src="LDR_home_depot_cards.png"
            alt="GenStatus Image"
          />
        </div>
        <p>
          Or you can download all historical LDRs for this store to your
          computer. Choosing this option will download a .zip file containing
          all historical LDRs.
        </p>
        <div className="img-section ">
          <Image
            className=""
            src="LDR_home_depot_result_store.png"
            alt="GenStatus Image"
          />
        </div>
      </p>
    </div>
  )
}

export default HistoricalLDR
