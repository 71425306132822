export const maskValue = (val) => {
  if (val) {
    return val.replaceAll(/\w/g, "X")
  }
  return val
}

export const getOptions = (
  codes,
  label,
  id,
  lkey = "l",
  vkey = "v",
  isIdReqd
) => {
  if (!codes?.length) return codes
  if (isIdReqd) {
    return codes.map((code) => ({
      ...code,
      ...{
        [lkey]: code?.[label]?.trim(),
        [vkey]: code?.[id]?.toString(),
        [isIdReqd]: code?.[id],
      },
    }))
  } else {
    return codes.map((code) => ({
      ...code,
      ...{
        [lkey]: code?.[label]?.trim(),
        [vkey]: code?.[id],
      },
    }))
  }
}

export const sortOptions = (options, type, fieldName, dir = "asc") => {
  if (!options?.length) return options

  switch (type) {
    case "number":
      if (dir === "asc") {
        return options.sort((a, b) => a[fieldName] - b[fieldName])
      }
      return options.sort((a, b) => b[fieldName] - a[fieldName])
    case "string":
      if (dir === "asc") {
        return options.sort((a, b) =>
          a[fieldName]
            ?.toLowerCase()
            ?.localeCompare(b[fieldName]?.toLowerCase())
        )
      }
      return options.sort((a, b) =>
        b[fieldName]?.toLowerCase()?.localeCompare(a[fieldName]?.toLowerCase())
      )
    case "date":
      const nonDateOptions = options?.filter((val) => val[fieldName] === "N/A")
      const dateOptions = options?.filter((val) => val[fieldName] !== "N/A")
      if (dir === "asc") {
        return [
          ...dateOptions.sort(
            (a, b) =>
              new Date(a[fieldName]).getTime() -
              new Date(b[fieldName]).getTime()
          ),
          ...nonDateOptions,
        ]
      }
      return [
        ...dateOptions.sort(
          (a, b) =>
            new Date(b[fieldName]).getTime() - new Date(a[fieldName]).getTime()
        ),
        ...nonDateOptions,
      ]
    default:
      return options
  }
}

export const getDateFormat = (dt) => {
  const dateVl = dt
  const yyyy = dateVl.getFullYear()
  let mm = dateVl.getMonth() + 1
  let dd = dateVl.getDate()

  if (dd < 10) dd = "0" + dd
  if (mm < 10) mm = "0" + mm

  return mm + "/" + dd + "/" + yyyy
}

export const getValues = (profileObj, profileKey, defaultValue) => {
  return profileObj?.[profileKey] || defaultValue
}

export const getDateValues = (profileObj, profileKey, defaultValue) => {
  return profileObj?.[profileKey]
    ? new Date(profileObj?.[profileKey])
    : defaultValue
}

export const getBooleanValues = (profileObj, profileKey, defaultValue) => {
  return profileObj?.[profileKey] ? true : false || defaultValue
}

export const getObjectValues = (
  options,
  profileObj,
  profileKey,
  defaultValue,
  iKey = "v"
) => {
  return (
    options?.filter((val) => val?.[iKey] == profileObj?.[profileKey])?.[0] ||
    defaultValue
  )
}

export function debounce(func, wait) {
  let timeout
  return function (...args) {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), wait)
  }
}

export const getBackgrounColor = (res) => {
  switch (res?.value) {
    case "Expired":
      return "#85C1A9"
    case "Approved":
      return "#91D5AC"
    case "Expiring Soon":
      return "#A3DBE8"
    case "Pending Customer Response":
      return "#4DBAC1"
    default:
      return "#77C5D4"
  }
}
