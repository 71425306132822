import React from "react"
import "./style.scss"

const Headermodal = ({
  handleClose,
  show,
  children,
  noClose,
  wrapperclass,
}) => {
  const showHideClassName = show ? " display-block " : " display-none"
  const showhelp = wrapperclass == "help" ? "help" : ""
  return (
    <div className={`${showHideClassName} ${showhelp}`}>
      {/* <div class="arrow-up"></div> */}
      {show && children}
    </div>
  )
}

export default Headermodal
