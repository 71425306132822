import React from "react"

function Overview() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Overview</h3>
      <div className="text-justify pl-3 pt-2">
        The Clean Earth Client Portal is a web-based application that you can
        use to accomplish the following:
        <ul>
          <li>manage your waste</li>
          <li>Manage, request, and schedule your services</li>
          <li>Mange your schedule</li>
          <li>Access to your document library</li>
          <li>Create Alerts</li>
          <li>Create and manage your profiles</li>
          <li>Run various reports</li>
        </ul>
      </div>
    </div>
  )
}
export default Overview
