import React from "react"
import Image from "shared/HelpImage/index"

function WasteProcess() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Dashboard: Waste Process Summary</h3>
      <p className="text-justify pl-3 pt-2">
        The Waste Process Summary shows one of three charts displaying the waste
        that was picked up in the current week.
      </p>
      <div className="img-section">
        <Image src="waste_summary_chart.png" alt="" />
      </div>
      <p className="text-justify pl-3">
        To switch the view just click on the down arrow next to the “Current
        View” button.
      </p>
      <p className="text-justify pl-3">
        Clicking on the first dot will display the Hazardous Waste Report for
        the time period you chose. If you hover your mouse cursor over any point
        on the chart you will see the weight of hazardous waste picked up for
        that time period
      </p>

      <p className="text-justify pl-3">
        Clicking on the second dot will display a donut chart of the top 10
        waste streams by weight picked up. Click on the different colors of the
        donut to display details for that waste stream. You can also choose the
        time period to display by clicking on the down arrow next to the Current
        View button.
      </p>
      <div className="img-section">
        <Image src="waste_stream_by_weight.png" alt="" />
      </div>
      <p className="text-justify pl-3">
        Clicking on the third dot displays the Total Weight Trending chart. This
        shows the total weight picked up for the time period selected, Current
        Month, Last 6 Months or Last 12 Months. You can choose the time period
        to display by clicking on the down arrow next to the Current Display
        button.
      </p>
      <div className="img-section">
        <Image src="total_weight_trending.png" alt="" />
      </div>
    </div>
  )
}
export default WasteProcess
