import React from "react"
import Image from "shared/HelpImage/index"

function ExceptionReport() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Exception Report</h3>
      <div className="img-section pt-4">
        <Image
          className=""
          src="expect_report_filters.png"
          alt="Exception Report Image"
        />
      </div>

      <p className="text-justify pl-3 pt-2">
        <p>
          The Exception Report will generate a report that shows any pick ups
          that had any exceptions listed.
        </p>
        <p>
          An exception is a customer specific, customizable observations input
          by Clean Earth technicians during a Service Order that indicate an
          unexpected or non-optimal event. Customers use this presence of
          Exceptions as a retraining tool and risk mitigation opportunity for
          instances where processes or outcomes from a Service Order do not
          align with expectations.
        </p>
        <p>
          The exception types field is defaulted to ALL in the drop down. If
          there are any exceptions, they will all show up when the user clicks
          the apply button.
        </p>
      </p>

      <Image
        className=""
        src="exceptionTypes.png"
        alt="Exception Report Image"
      />
    </div>
  )
}
export default ExceptionReport
