import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import enhancer from "./enhancer"
import Calender from "shared/Calender"
import Loading from "shared/Loading"
import EllipsisText from "react-ellipsis-text"
import { capitalize } from "shared"

function MyscheduleSec(props) {
  let {
    onMySchedule,
    dates,
    updateCalenderConfig,
    ServiceData,
    isTablet,
    isEstimatedDt,
    isEstimatedDate,
    isload,
  } = props

  return (
    <Fragment>
      <div
        className={` ${
          isTablet
            ? "col-sm-12 col-md-12 col-lg-12 text-left mt-4 istablet"
            : "col-sm-5 col-md-5 col-lg-5 text-left pr-0"
        }`}
      >
        <label className="weeklylabel   ">
          <FormattedMessage id="dashboard.myschedule" />
        </label>
        <div
          className={` ${
            isTablet ? "row m-0 borderistablet" : "mySched mysectionsched"
          }`}
        >
          <div
            className={` ${
              isTablet
                ? "col-sm-6 col-md-6 col-lg-6 "
                : "card position-relative"
            }`}
          >
            {/* <Loading id="service-dates"  /> */}
            <Calender
              {...{ dates, updateCalenderConfig, isEstimatedDt, isload }}
            />
          </div>

          <div
            className={` ${
              isTablet
                ? "col-sm-6 col-md-6 col-lg-6 istabletservice"
                : "servicesbadge p-3 pt-0"
            }`}
          >
            <div className={` ${isTablet ? "mb-3 " : ""}`}>
              <label className="weeklylabel m-0">
                <FormattedMessage id="dashboard.service" />
              </label>

              <label className="viewallservices m-0" onClick={onMySchedule}>
                <FormattedMessage id="dashboard.viewall" />
              </label>
            </div>
            <Loading id="schedules-summary" height={50}>
              {!ServiceData[0] && (
                <div className="weeklylabel text-center">
                  {isEstimatedDate ? (
                    <FormattedMessage id="nodata.services_estimated" />
                  ) : (
                    <FormattedMessage id="nodata.services" />
                  )}
                </div>
              )}
              {ServiceData[0] &&
                ServiceData.map((d, i) => {
                  // if (!ServiceData[d][0]) return null
                  return (
                    <div className="rec" key={i}>
                      <span
                        className={`spans  ${
                          d.scheduleType && d.scheduleType.length == 2
                            ? "text-uppercase"
                            : "text-capitalize"
                        }`}
                      >
                        {capitalize(d.scheduleType)}
                      </span>
                      {d.scheduleType == "Urgent"}
                      {d.services.slice(0, 2).map((v, j) => (
                        <div
                          className="badge badge-pill badge-secondary bages text-uppercase"
                          key={j}
                        >
                          <b className="numserv text-capitalize">
                            <EllipsisText text={v.itemCategory} length={15} />
                          </b>{" "}
                          <span className="subser">{v.count}</span>
                        </div>
                      ))}
                      {d.services.slice(2)[0] && (
                        <div className="badge badge-pill badge-secondary bages">
                          <b className="numserv">
                            {" "}
                            +{d.services.slice(2).length}
                          </b>{" "}
                          <span className="subser">
                            <FormattedMessage id="dashboard.more" />
                          </span>
                        </div>
                      )}
                    </div>
                  )
                })}
            </Loading>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default enhancer(MyscheduleSec)
