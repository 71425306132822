import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import Retail from "./Retail"
import Corporate from "./Corporate"

function Dashboard(props) {
  const { isFullCircle } = props
  return <Fragment>{!isFullCircle ? <Retail /> : <Corporate />}</Fragment>
}
export default enhancer(Dashboard)
