import { useSelector } from "react-redux"
// import { useParams } from "react-router-dom/cjs/react-router-dom"
import {
  // CURRENT_PROFILE,
  // RECERTIFY_PROFILE,
  // CERTIFIED_PROFILE,
  GET_PROFILE,
} from "store/reducer/actionType"
import { useDispatch } from "react-redux"
import React, { useState, useEffect } from "react"
import api from "api"

let enhancer =
  (Component) =>
  ({ history, ...props }) => {
    // const [newProfileId, setNewProfileId] = useState(null)
    const [submit, setSubmit] = useState(false)
    const [recertRes, setRecertRes] = useState({
      newProfileNumber: "",
      message: "",
    })
    // const { id } = useParams()
    const dispatch = useDispatch()
    // debugger

    const recertifyProfile = useSelector(
      (state) => state.profileDetails?.recertifyProfile
    )
    const getProfileData = async (reqId) => {
      if (reqId) {
        try {
          let res = await api.g_getProfileById(reqId)
          if (!res) return
          dispatch({ type: GET_PROFILE, payload: res })
        } catch (error) {}
      }
    }

    useEffect(() => {
      getProfileData(recertifyProfile?.profileId)
    }, [])

    const knowyourc = useSelector(
      (state) => state.generatorDetails?.knowyourcsr
    )

    const goToProfile = () => {
      history.push(`/profiles`)
    }
    const goToEditProfile = async (page, id) => {
      history.push(`/profiles/${page}/${id}`)
    }

    const viewProfile = async (profileId) => {
      goToEditProfile("viewprofile", profileId)
    }

    const getRecertifiedProfile = async (flag) => {
      let req = {
        ProfileNumber: recertifyProfile?.profile,
        RevisionNum: Number(recertifyProfile?.version),
        isRecertifyWithChange: flag,
        CSREmail: knowyourc?.email,
      }
      try {
        let res = await api.p_recertifyProfile(req)
        if (!res) return
        setRecertRes({
          ...recertRes,
          newProfileNumber: res?.newProfileNumber,
          message: res?.message,
        })
        setSubmit(true)
      } catch (error) {}
    }
    // const handleNewProfile = async () => {
    //   goToEditProfile(newProfileId || "467")
    // }
    return (
      <Component
        {...props}
        {...{
          // newProfileId,
          goToEditProfile,
          getProfileData,
          goToProfile,
          getRecertifiedProfile,
          submit,
          setSubmit,
          // handleNewProfile,
          viewProfile,
          // setNewProfileId,
          recertRes,
        }}
      />
    )
  }

export default enhancer
