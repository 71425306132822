import React, { useState, useEffect } from "react"
import api from "api"
import { format, isPast, isToday } from "date-fns"
import { MyStoreSortByService } from "config"
import { formattedDate } from "shared"
import { useSelector } from "react-redux"
import { useHarsco } from "shared"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    let [scheduleMode, setScheduleMode] = useState(true)
    let [resetMyschedule, setResetMyschedule] = useState(false)
    let [serviceData, setServiceData] = useState([])
    let [filters, setFilters] = useState({})
    let [activeService, setActiveService] = useState(0)
    let [sortBy, updateSortBy] = useState(MyStoreSortByService[0])
    let [pageNo, gotoPage] = useState(1)
    const [showfilterhint, setshowfilterhint] = useState(false)
    const [IsFilterHintshown, setIsFilterHintshown] = useState(false)
    let [submitted, setSubmitted] = useState(false)
    let { isPreviewCustomer } = useHarsco()
    let [workOrderFilters, setWorkOrderFilters] = useState({
      NoShip: false,
      Shipped: false,
      partialNoShip: false,
    })

    const stores = useSelector((state) => state.stores)

    const serviceTypeData = (serviceTypes) => {
      let serviceTypesList = []
      serviceTypes.forEach((d) => {
        serviceTypesList = [
          ...serviceTypesList,
          /**Below logic is add respectice icons based on noShip Flag */
          d.noShip
            ? `<span class="noWrap">${d.serviceType} <sup class="noShip">-</sup> </span>`
            : `<span class="noWrap"> ${d.serviceType} <sup class="shipped">+</sup></span>`,
        ]
      })
      return (
        <div
          dangerouslySetInnerHTML={{ __html: serviceTypesList.join(", ") }}
        />
      )
    }

    const serviceHistory = (workOrderAzId) => {
      /* Passing workOrderAzId  to use in servicedetailspage */
      const newWindow = window.open(
        `myschedule/serviceorder/${workOrderAzId}`,
        "_blank",
        "noopener,noreferrer"
      )
      if (newWindow) newWindow.opener = null
    }

    let fetchServices = async (filter = "") => {
      setSubmitted(false)
      if (!filters.fiscal_span) return
      let n_dates = [filters?.fiscal_span?.from, filters?.fiscal_span?.to]

      try {
        let scheduleData = await api.p_schedulesServices({
          dates: n_dates,
          loading_key: "schedules-services",
          sort: sortBy.v,
          index: pageNo,
          size: 20,
          stores: [stores?.[0]?.storeNumber],
          workOrderType: filter ? filter : "",
        })

        if (!scheduleData) return
        scheduleData.items.forEach((d) => {
          let date = new Date(d.serviceDatetime)
          d.formatTime = format(date, "LLL dd, yyyy")
          d.canChange = !(isPast(date) || isToday(date))
          d.canServiceHistory = isPast(date)
          d.serviceTypes.forEach((d2) => {
            if (d2.estimatedServiceDate)
              d2.estimatedServiceDate = formattedDate(d2.estimatedServiceDate)
          })
        })
        setActiveService(scheduleData.items[0])
        setServiceData(scheduleData)
        setSubmitted(true)
        if (!IsFilterHintshown) setIsFilterHintshown(true)
      } catch (error) {
        setActiveService(0)
        setServiceData([])
        setSubmitted(false)
      }
    }
    useEffect(() => {
      if (IsFilterHintshown) {
        setshowfilterhint(true)
        setTimeout(() => {
          setshowfilterhint(false)
        }, 120000)
      }
    }, [IsFilterHintshown])

    let updateFilters = async (fData) => {
      setResetMyschedule(false)
      setFilters(fData)
      setScheduleMode(false)
    }
    const reset = () => {
      setResetMyschedule(true)
    }

    useEffect(() => {
      if (!filters?.fiscal_span) return
      gotoPage(-1)
      let initialValue = {
        NoShip: false,
        Shipped: false,
        partialNoShip: false,
      }
      setWorkOrderFilters({
        ...initialValue,
      })
      setTimeout(() => {
        gotoPage(1)
      }, 10)
    }, [filters, sortBy])

    useEffect(() => {
      if (pageNo == -1) return
      fetchServices()
    }, [pageNo])

    useEffect(() => {
      setServiceData([])
    }, [resetMyschedule])

    const updatedupdateSortByOption = (v) => {
      updateSortBy({ l: v.l, v: v.v })
    }
    const fetchServicesWithFilter = (workOrderType) => {
      let initialValue = {
        NoShip: false,
        Shipped: false,
        partialNoShip: false,
      }
      if (workOrderFilters[workOrderType]) {
        setWorkOrderFilters({
          ...initialValue,
        })
        fetchServices()
      } else {
        setWorkOrderFilters({
          ...initialValue,
          [workOrderType]: !initialValue[workOrderType],
        })
        fetchServices(workOrderType)
      }
    }
    const checkFilter = () => {
      return Object?.entries(workOrderFilters).filter((ele) => !!ele[1])
    }

    return (
      <Component
        {...props}
        {...{
          scheduleMode,
          setScheduleMode,
          updateFilters,
          filters,
          reset,
          resetMyschedule,
          //added
          serviceData,
          setActiveService,
          activeService,
          sortBy,
          updateSortBy,
          MyStoreSortByService,
          pageNo,
          gotoPage,
          updatedupdateSortByOption,
          serviceHistory,
          serviceTypeData,
          submitted,
          isPreviewCustomer,
          fetchServicesWithFilter,
          checkFilter,
          workOrderFilters,
          showfilterhint,
        }}
      />
    )
  }

export default enhancer
