import React, { useEffect } from "react"
let enhancer = (Component) => ({ history, ...props }) => {
  useEffect(() => {}, [])

  const onNewScheduleRequestClick = (type) => {
    history.push(`/requestservice/${type}`)
  }

  return (
    <Component
      {...props}
      {...{
        onNewScheduleRequestClick,
      }}
    />
  )
}

export default enhancer
