import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import Select from "shared/Select"
import Loading from "shared/Loading"
import Image from "shared/Image"
import _ from "lodash"
import classNames from "classnames"

function Alerts(props) {
  let {
    filterBy,
    setFilterBy,
    sortBy,
    setSortBy,
    sortByOpts,
    filterByOpts,
    gotoReport,
    onClickAccessSettings,
    alertTypeToAlertText,
    iconSrc,
    isHeaderAlerts,
    onClickViewall,
    alerts,
    alertsD,
    downloadFile,
  } = props

  const isHeaderAlert = isHeaderAlerts ? true : false
  let alertsF = _.clone(alerts)

  if (filterBy.v != "all")
    alertsF = alertsF?.filter((d) => d.isRead == filterBy.v)
  if (sortBy.v != "desc") alertsF = alertsF.reverse()

  let alertsDo = _.clone(alertsD)
  alertsDo = alertsDo?.filter((d) => d.isRead == false)

  let getAlertComponent = (d, i, alertsDo) => {
    if (d.status == 1) {
      let desc = d.alertDescription.split("{0}")
      return (
        <div className="headingalerts text-break">
          {desc[0]}
          <span
            className="dPointer"
            onClick={() => downloadFile(d, i, alertsDo)}
          >
            {" "}
            <u>click here</u>{" "}
          </span>
          {/* )} */}
          {desc[1]}
        </div>
      )
    } else if (d.status == -1) {
      return (
        <div className="headingalerts text-break">
          {d.alertDescription}
          <span
            className="dPointer"
            onClick={() => downloadFile(d, i, alertsDo)}
          >
            {" "}
            <Image
              className="info-icon"
              src="ic_info.svg"
              height="15px"
              width="15px"
              alt=""
              title={d.errorInfo}
            />{" "}
          </span>
        </div>
      )
    }
  }

  return (
    <div className={classNames({ "row m-0": !isHeaderAlert })}>
      <div className={classNames({ "col-9 ": !isHeaderAlert })}>
        <Loading id="get_alerts">
          <Fragment>
            <div
              className={classNames("row mx-0 my-3", {
                headingalert: !isHeaderAlert,
                "modelsection m-0": isHeaderAlert,
              })}
            >
              {isHeaderAlert && (
                <div className="col-12 mb-2">
                  <div className="float-left alerts">Alerts</div>
                  <div
                    className="float-right viewall"
                    onClick={() => {
                      onClickViewall()
                    }}
                  >
                    View all
                  </div>
                </div>
              )}
              {!isHeaderAlert && (
                <>
                  <div className="col-6 text-left alerttext">
                    <FormattedMessage id="alertview.alerts" />
                  </div>
                  <div className="col-1 labeltext p-0 line-height">
                    <FormattedMessage id="alertview.sortby" />
                  </div>
                  <div className="col-2 text-left ">
                    <div className="sortby">
                      <Select
                        options={sortByOpts}
                        labelKey="l"
                        className="sort-dropdown"
                        selected={sortBy}
                        onSelect={setSortBy}
                      />
                    </div>
                  </div>
                  <div className="col-1 labeltext p-0 line-height">
                    <FormattedMessage id="alertview.filterby" />
                  </div>
                  <div className="col-2 text-left ">
                    <div className="filterby">
                      <Select
                        options={filterByOpts}
                        labelKey="l"
                        className="sort-dropdown"
                        selected={filterBy}
                        onSelect={setFilterBy}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            <Loading id="alert_notification">
              <div
                className={classNames({
                  datarender: !isHeaderAlert,
                  "col-12": isHeaderAlert,
                })}
              >
                {!alertsF[0] && !isHeaderAlert && (
                  <div className="col-sm-12 col-md-12 col-lg-12 text-center nodata">
                    <FormattedMessage id="nodata.alerts" />
                  </div>
                )}
                {isHeaderAlert &&
                  alertsDo?.map(
                    (d, i) =>
                      //excluding the alerts which are downloading(status code-2)
                      d.status != 2 && (
                        <div
                          key={i}
                          className={classNames("row eachrow", {
                            "m-0": !isHeaderAlert,
                            read: d.isRead,
                            unread: !d.isRead,
                          })}
                        >
                          <div
                            className={classNames("imges", {
                              "col-1 padding pr-0": !isHeaderAlert,
                              "col-sm-2 p-0": isHeaderAlert,
                            })}
                          >
                            <div className="circle">
                              <Image
                                className={classNames("circleimg", {
                                  popup: isHeaderAlert,
                                })}
                                src={"CE_Portal_over_capacity.svg"}
                                alt=""
                                width="35"
                                height="35"
                              />
                            </div>
                          </div>
                          <div
                            className={classNames("pl-0 ", {
                              "col-11": !isHeaderAlert,
                              "col-sm-10": isHeaderAlert,
                            })}
                          >
                            {getAlertComponent(d, i, alertsDo)}
                          </div>
                        </div>
                      )
                  )}
                {alertsF.map((d, i) => (
                  <div
                    key={i}
                    onClick={() => gotoReport(d, i)}
                    className={classNames("row eachrow", {
                      "m-0": !isHeaderAlert,
                      read: d.isRead,
                      unread: !d.isRead,
                      "no-pointer":
                        d.isRead &&
                        (d.alertType == "no_ship" ||
                          d.alertType == "partial_no_ship"),
                    })}
                  >
                    <div
                      className={classNames("imges", {
                        "col-1 padding pr-0": !isHeaderAlert,
                        "col-sm-2 p-0": isHeaderAlert,
                      })}
                    >
                      <div className="circle">
                        <Image
                          className={classNames("circleimg", {
                            popup: isHeaderAlert,
                          })}
                          src={iconSrc[d.alertType]}
                          alt=""
                          width="35"
                          height="35"
                        />
                      </div>
                    </div>
                    <div
                      className={classNames("pl-0", {
                        "col-11 padding": !isHeaderAlert,
                        "col-sm-10": isHeaderAlert,
                      })}
                    >
                      <div className="headingalerts text-break">
                        <FormattedMessage
                          id={alertTypeToAlertText[d.alertType]}
                          values={d}
                        />
                      </div>
                      <div
                        className={classNames({
                          subtextalert: !isHeaderAlert,
                          subtext: isHeaderAlert,
                        })}
                      >
                        <div className="datetime">{d.date}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Loading>
          </Fragment>
        </Loading>
      </div>
      {!isHeaderAlert && (
        <div className="col-3 alert-setting">
          <div className="text-right">
            <span className="closed mr-0"></span>
          </div>
          <div className="alert-wrap">
            <div className="">
              <Image
                className="alertimg"
                src="clock1.svg"
                alt=""
                width="120"
                height="120"
              />
            </div>
          </div>
          <div className="alert-content d-none">
            <FormattedMessage id="alertview.notification" />
          </div>
          <div className="alert-content d-none">
            <FormattedMessage id="alertview.alertsetting" />
          </div>
          <div className="alert-subcontent d-none">
            Stay connected,<div> the feature will be live soon.</div>
          </div>
          <div className="alert-content name">
            <FormattedMessage id="alertview.alertsettingname" />
          </div>
          <div className="alert-content text">
            <FormattedMessage id="alertview.dummytext" />
          </div>
          <div className="alert-btn ">
            <button
              onClick={() => {
                onClickAccessSettings()
              }}
            >
              Access settings
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
export default enhancer(Alerts)
