import React, { Fragment } from "react"
import enhancer from "./enhancer"
import Retail from "./Retails/index"
import FullCircle from "./FullCircle/index"

function Dashboard(props) {
  const { isFullCircle } = props
  return <Fragment>{!isFullCircle ? <Retail /> : <FullCircle />}</Fragment>
}
export default enhancer(Dashboard)
