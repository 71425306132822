import React, { useState, useEffect } from "react"
import { useLocation, useHistory, useParams } from "react-router-dom"
import { RequestTypeOpts, ServiceIssueOpts, requestServices } from "config"
import { useSelector } from "react-redux"
import api from "api"
import { toast } from "react-toastify"
import * as Yup from "yup"
import { format } from "date-fns"
import _ from "lodash"
import { formattedDate, isEmail, isRbsCorporateUser } from "shared"
import { roles } from "config"

let monthData = {}
let enhancer = (Component) => (props) => {
  const [stores, setStoresOpts] = useState([])
  const [brand, setBrandsOpts] = useState([])
  let [isEstimatedDate, setIsEstimatedDate] = useState(false)
  const [requestTypeOpts, setRequestTypeOpts] = useState([])
  const [serviceIssueOpts, setServiceIssueOpts] = useState([])
  const [addsuppliesOpts, setAddsuppliesOpts] = useState([])
  let [dates, setDates] = useState([])
  let [currentDate, setCurrentDate] = useState(new Date())
  let [ServiceData, setServiceData] = useState({})
  let [customShowAddSupplies, setCustomShowAddSupplies] = useState(false)
  const [suppliesQty, setsuppliesQty] = useState([])
  const [confirmSupplies, setconfirmSupplies] = useState()
  const [navigateToEdit, setEdit] = useState(false)
  const [selectedStore, setselectedStore] = useState(false)
  const [resetstore, setresetStore] = useState(false)
  const [isBrandSelected, setisBrandSelected] = useState(false)
  const profile = useSelector((state) => state.profile)
  const [requestServiceTypeOpts, setrequestServiceTypeOpts] = useState([])
  const [updatedSeviceTypes, setUpdatedSeviceTypes] = useState([])

  const getupdatedStores = async (
    title,
    selectedValue,
    resetValueCompleted = false
  ) => {
    if (title === "division") {
      setresetStore(true)
      setStoresOpts([])
      if (!isBrandSelected) setisBrandSelected(true)
      let selectedservicetypes = await api.g_serviceTypes({
        division: selectedValue.v,
        loading_key: "servicetype_search",
      })
      setUpdatedSeviceTypes(selectedservicetypes)
      let storesbydivision = generatorData.filter(
        (x) => x.division.toUpperCase() === selectedValue.v
      )
      let filteringStoreValues = storesbydivision.filter(
        (x) => x.division != null || "null" || ""
      )
      let stores = filteringStoreValues
        .sort((a, b) =>
          a.storeNumber.localeCompare(b.storeNumber, "en", { numeric: true })
        )
        .map((d) => ({ l: d.storeNumber, v: d.storeNumber }))
      setStoresOpts(stores)
    } else if (title === "store") {
      if (resetValueCompleted) {
        setresetStore(false)
        setselectedStore(undefined)
      } else {
        setselectedStore(selectedValue.v)
      }
    }
  }

  let location = useLocation()
  let history = useHistory()
  let params = useParams()

  let { service } = location.state || {}
  let { type = "new_schedule_request" } = params || {}

  let isActive = (statename) => type == statename

  const user = useSelector((state) => state.profile)
  let { serviceReqSupplies = [], serviceTypes = [] } = useSelector(
    (state) => state.config
  )

  useEffect(() => {
    if (isBrandSelected) {
      if (updatedSeviceTypes.length > 0) {
        const mappedOptions = updatedSeviceTypes.map((d) => ({
          l: d,
          v: d,
          checked: false,
        }))
        setrequestServiceTypeOpts(mappedOptions)
      } else {
        setrequestServiceTypeOpts([])
      }
    }
  }, [updatedSeviceTypes])

  let isSuppliesText = _.isEmpty(serviceReqSupplies)

  const { firstName, lastName, phone, email } = user
  let name = `${lastName} ${firstName}`

  const [initialValues, setInitialValues] = useState({
    phone,
    supplies: [{ key: "", value: "" }],
    service_type: [],
    name,
    email,
    additional_email: "",
  })

  const storeData = useSelector((state) => state.stores)
  const generatorData = useSelector((state) => state.generators)

  let formik = {
    enableReinitialize: true,
  }

  const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
  const qtyRegExp = /^((?!(0))[0-9]{1,5})$/

  let valSchema = {
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Please enter your phone number"),
    email: Yup.string()
      .email("Invalid email")
      .required("Please enter your email"),
    store: Yup.string().required("Please select a store"),
    details: Yup.string()
      .min(0)
      .max(500, "you have exceeded maximum character limit")
      .required("Please enter the details"),
  }
  if (isRbsCorporateUser()) {
    valSchema.division = Yup.string().required("Please select a brand")
  }
  if (type == "new_schedule_request") {
    valSchema.request_type = Yup.string().required(
      "Please select your Request type"
    )
    if (requestServiceTypeOpts.length > 0) {
      let checked = requestServiceTypeOpts.filter((x) => x.checked)
      valSchema.service_type =
        checked.length == 0 &&
        Yup.array().min(1, "Please select atleast one Service type")
    }
  }
  if (type == "report_issue")
    valSchema.service_issue = Yup.string().required(
      "Please select your Service issue"
    )
  if (type == "request_supplies") {
    valSchema.supplies = Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string()
            .matches(qtyRegExp, "Qty is not valid")
            .required("Please enter qty"),
          key: Yup.string()
            .required("Please select item")
            .test("unique", "You have already added this", function (value) {
              let array = this.from[1].value.supplies.map((d) => d.key)
              const hash = Object.fromEntries([
                ...array.reduce(
                  (map, key) => map.set(key, (map.get(key) || 0) + 1),
                  new Map()
                ),
              ])
              return !(hash[value] > 1)
            }),
        })
      )
      .required("Please add atleast 1 supply")
      .min(1, "Please add atleast 1 supply")
  }

  formik.validationSchema = Yup.object().shape(valSchema)

  const [formData] = useState({})

  let callRequest = async (data) => {
    if (data.supplies) data.supplies.forEach((d) => delete d.ts)
    data.additional_email = data.additional_email.replace(/\r?\n|\r/g, "")

    let res = await api.p_requestService({
      loading_key: "requestservice",
      ...data,
    })
    if (!res) return
    if (data.supplies) {
      data.supplies = data.supplies.filter((o) =>
        addsuppliesOpts.every((o1) => (o.key === o1.v ? (o.key = o1.l) : o1.l))
      )
    }
    data.caseNumber = res.caseNumber
    history.push({
      pathname: "/postrequestservice",
      state: { data },
    })
  }

  const formatData = (data) => {
    return data.join(" | ")
  }

  formik.onSubmit = async (formData) => {
    if (requestServiceTypeOpts.length > 0) {
      let serviceType = requestServiceTypeOpts.filter((x) => x.checked)
      formData.service_type = formatData(serviceType.map((d) => d.v))
    } else if (
      requestServiceTypeOpts.length == 0 &&
      type === "new_schedule_request"
    ) {
      toast.error("Cannot Create New Schedules Requests", { autoClose: 3000 })
      delete formData.service_type
      return
    } else delete formData.service_type
    if (!selectedStore && isRbsCorporateUser()) {
      toast.error("please select a store", { autoClose: 3000 })
      return
    }
    formData.address = storeAddress(formData.store)
    formData.type = type
    formData.name = name
    formData.date_requested = format(currentDate, "yyyy-MM-dd")
    formData.api_type = requestServices[type].api_type
    if (profile.roleId == roles.Brand_User) {
      formData.division = profile.division
    }
    if (formData?.supplies[0].key != "") {
      formData.supplies = formData.supplies.filter((o) =>
        addsuppliesOpts.every((o1) => (o.key === o1.v ? (o.key = o1.l) : o.key))
      )
      formData.supplies.forEach((d) => {
        delete d.v
        d.value = _.parseInt(d.value)
      })
      const uniqueValues = new Set(formData.supplies.map((v) => v.key))
      if (uniqueValues.size < formData.supplies.length) return //added check for unique values
      setsuppliesQty(formData.supplies)
      delete formData.date_requested
      delete formData.date
      setconfirmSupplies(formData)
      await toggleModalAddSupplies()
    } else {
      formData.date = dateFormat()
      delete formData.supplies
      callRequest(formData)
    }
  }

  useEffect(() => {
    if (service) {
      let { storeNumber, scheduleDate } = service
      scheduleDate = new Date(scheduleDate)
      setCurrentDate(scheduleDate)
      let i2 = { ...initialValues }
      i2.store = storeNumber
      setInitialValues(i2)
    }
  }, [service])

  useEffect(() => {
    if (!isRbsCorporateUser()) {
      if (storeData) {
        let stores = storeData
          .sort((a, b) =>
            a.storeNumber.localeCompare(b.storeNumber, "en", { numeric: true })
          )
          .map((d) => ({ l: d.storeNumber, v: d.storeNumber }))
        setStoresOpts(stores)
      }
    }
  }, [storeData])

  useEffect(() => {
    setRequestTypeOpts(RequestTypeOpts.map((d) => ({ l: d, v: d })))
    setServiceIssueOpts(ServiceIssueOpts.map((d) => ({ l: d, v: d })))
    if (!isRbsCorporateUser()) {
      const mappedOptions = serviceTypes.map((d) => ({
        l: d,
        v: d,
        checked: false,
      }))
      setrequestServiceTypeOpts(mappedOptions)
    }
    setAddsuppliesOpts(
      serviceReqSupplies.map((d) => ({
        l: d.supply_description,
        v: d.supply_code,
      }))
    )
  }, [])

  useEffect(() => {
    if (generatorData.length > 0) {
      let divisions = generatorData
        .filter((d) => d.division)
        .map((d) => d.division?.toUpperCase())
        .sort()
      var ud = _.uniq(divisions)
      var uniqueDivisions = ud.map((d) => ({
        l: d,
        v: d,
      }))
      setBrandsOpts(uniqueDivisions)
      if (isBrandSelected && stores.length > 0) {
        setStoresOpts([])
      }
    }
  }, [generatorData])
  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target
    const updatedItems = requestServiceTypeOpts.map((item) =>
      item.l == value ? { ...item, checked } : item
    )
    setrequestServiceTypeOpts(updatedItems)
  }

  let storeAddress = (store) => {
    if (!store) return
    let num = `${store}`.toLowerCase()
    store = storeData.find((d) => d.storeNumber?.toLowerCase() == num) || {}
    return `${store.address}, ${store.city}, ${store.state}, ${store.postalCode}, United States`
  }

  let updateCalenderConfig = async (config) => {
    let { start, end, selectedDate } = config
    setServiceData({})
    let nDates = [start, end]
    let key = `${start.getMonth()}-${end.getMonth()}`
    if (!monthData[key]) {
      let res = await api.g_serviceDates({
        dates: nDates,
        loading_key: "service-dates",
      })
      if (!res) return
      // eslint-disable-next-line
      for (const k in res) {
        res[k] = res[k].map((d) => new Date(d))
      }
      monthData[key] = res
    }
    setDates(monthData[key])
    setCurrentDate(selectedDate)
  }

  const isEstimatedDt = (val) => {
    setIsEstimatedDate(val)
  }

  const toggleModalAddSupplies = () => {
    setCustomShowAddSupplies(!customShowAddSupplies)
  }

  const editRequestSupplies = () => {
    setEdit(true)
    setCustomShowAddSupplies(!customShowAddSupplies)
  }

  const confirmAddSupplies = async () => {
    confirmSupplies.supplies = confirmSupplies.supplies.filter((o) =>
      addsuppliesOpts.every((o1) => (o.key === o1.l ? (o.key = o1.v) : o.key))
    )
    setCustomShowAddSupplies(!customShowAddSupplies)
    await callRequest(confirmSupplies)
  }

  const validateCC = (value) => {
    let error
    if (_.isEmpty(value)) return

    const emails = value.split(";").map((v) => v.trim())

    if (!emails.every(isEmail)) {
      error = "Emails should be semicolon separated"
    } else if (checkIfDuplicateExists(emails)) {
      error = "Emails should be unique"
    } else if (emails.includes(email)) {
      error = "CC schould not include primary email"
    }
    return error
  }

  const checkIfDuplicateExists = (emails) => {
    return new Set(emails).size !== emails.length
  }

  let dateFormat = () => formattedDate(currentDate)

  return (
    <Component
      {...props}
      {...{
        stores,
        brand,
        requestTypeOpts,
        requestServiceTypeOpts,
        formData,
        serviceIssueOpts,
        addsuppliesOpts,
        dateFormat,
        updateCalenderConfig,
        dates,
        ServiceData,
        user,
        storeAddress,
        toggleModalAddSupplies,
        customShowAddSupplies,
        editRequestSupplies,
        isActive,
        type,
        formik,
        suppliesQty,
        confirmAddSupplies,
        confirmSupplies,
        initialValues,
        validateCC,
        isSuppliesText,
        isEstimatedDt,
        isEstimatedDate,
        navigateToEdit,
        getupdatedStores,
        resetstore,
        selectedStore,
        isBrandSelected,
        handleCheckboxChange,
      }}
    />
  )
}

export default enhancer
