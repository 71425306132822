import React from "react"
import Image from "shared/HelpImage/index"
import { useSelector } from "react-redux"

function AnnouncementsBanner() {
  const { changeCustomer } = useSelector((state) => state)
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Announcements Banner</h3>
      <p className="text-justify pl-3 pt-3">
        Under the Client Portal Header Bar, you will see a banner displaying any
        announcements directed at you. If there is no banner displayed there are
        no announcements. If there are multiple announcements directed at you
        will see them scrolling across the banner from right-to-left.
      </p>

      <div className="img-section">
        <Image
          src={
            changeCustomer?.customer?.businessSegmentId === 3
              ? "mi_announcement_banner1.png"
              : "announcement_banner_3.png"
          }
          alt=""
        />
      </div>
      <p className="text-justify pl-3 pt-3">
        Clicking on the left-pointing double-arrow will reverse the direction of
        the scroll to left-to-right.
      </p>
      <div className="img-section">
        <Image
          src={
            changeCustomer?.customer?.businessSegmentId === 3
              ? "mi_announcement_banner2.png"
              : "announcement_banner_4.png"
          }
          alt=""
        />
      </div>
      <p className="text-justify pl-3 pt-3">
        Clicking on the right-pointing double-arrow will return the direction of
        the scrolling back to right-to-left.
      </p>
      <div className="img-section">
        <Image
          src={
            changeCustomer?.customer?.businessSegmentId === 3
              ? "mi_announcement_banner3.png"
              : "announcement_banner_5.png"
          }
          alt=""
        />
      </div>
      <p className="text-justify pl-3 pt-3">
        Clicking on the <Image src="announcement_cancel.svg" alt="" /> will
        remove the banner altogether.
      </p>
      <div className="img-section">
        <Image src="announcement_ticker.png" alt="" />
      </div>
      <div className="img-section">
        <Image
          src={
            changeCustomer?.customer?.businessSegmentId === 3
              ? "mi_announcement_banner4.png"
              : "announcement_banner_2.png"
          }
          alt=""
        />
      </div>
      <p className="text-justify pl-3 pt-3">
        Clicking on the left-pointing arrow{" "}
        <Image src="open_announcement.svg" alt="" /> will re-display the banner.
      </p>
      <div className="img-section">
        <Image
          src={
            changeCustomer?.customer?.businessSegmentId === 3
              ? "mi_announcement_banner1.png"
              : "announcement_banner_3.png"
          }
          alt=""
        />
      </div>
    </div>
  )
}
export default AnnouncementsBanner
