import React, { useEffect, useState } from "react"
import { alertsOpts, locale } from "config"
import * as dfns from "date-fns"
import { useHistory } from "react-router-dom"
import api from "api"
import { useToday, formattedDate } from "shared"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import { download } from "shared"
import { host } from "api/urls"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    let history = useHistory()
    const dispatch = useDispatch()
    let today = useToday()
    let alertList = useSelector((state) => state?.alerts)
    let alertListD = useSelector((state) => state?.doAlertList)
    let { sortByOpts, filterByOpts } = alertsOpts
    let [filterBy, setFilterBy] = useState(filterByOpts[0])
    let [sortBy, setSortBy] = useState(sortByOpts[0])
    let [alerts, setAlerts] = useState([])

    let [alertsD, setAlertsD] = useState([])
    const alertTypeToAlertText = {
      no_ship: "alertview.noShiptitle",
      haz_waste: "alertview.title",
      partial_no_ship: "alertview.partialShiptitle",
      vsqg_to_sqg: "alertview.vsqg",
      fc_alert: "alert.fullcircle",
      full_circle: "alert.fullcircle",
    }
    const iconSrc = {
      no_ship: "Full_No_Ship_Icon.svg",
      haz_waste: "CE_Portal_over_capacity.svg",
      partial_no_ship: "Partial_No_Ship_Icon.svg",
      vsqg_to_sqg: "CE_Portal_over_capacity.svg",
      fc_alert: "ic_profile_center.svg",
      full_circle: "ic_profile_center.svg",
    }

    useEffect(() => {
      let alrts = _.cloneDeep(alertList?.alerts)
      if (props.isHeaderAlerts) {
        alrts = alrts?.filter((d) => !d?.isRead)
      }

      alrts.forEach((d, i) => {
        let month = dfns.format(new Date(d.alertDate), "MMMM")
        let storesD = [...new Set(d.stores)]
        d.stores = `${storesD.join(",")}`
        d.month = month
        d.dateF = new Date(d.alertDate)
        d.date = formattedDate(d.dateF, today, { locale })
        d.index = i
      })
      alrts = alrts.sort(function (a, b) {
        return new Date(b.dateF) - new Date(a.dateF)
      })
      setAlerts(alrts)
    }, [alertList])

    useEffect(() => {
      let alrtsD = _.cloneDeep(alertList.doAlertList)
      alrtsD = alrtsD?.filter((d) => !d.isRead)
      setAlertsD(alrtsD)
    }, [alertListD])

    const gotoReport = async (d, index) => {
      if (!d.isRead) {
        index = d?.index
        await api.p_markAlertRead({
          notificationId:
            d.alertType === "full_circle"
              ? [d.notificationId]
              : d.notificationId,
          loading_key: "alert_notification",
        })
        dispatch({ type: "UPDATE_IS_READ_ALERT", index })
      }
      if (d.alertType === "no_ship") return onClickViewall()
      if (d.alertType === "partial_no_ship") return onClickViewall()
      if (d.alertType === "full_circle") {
        history.push(`/`)
      } else {
        let month = dfns.format(new Date(d.alertDate), "MMMM")
        history.push(`/reportgenerator/general_status/${d.stores}/${month}`, {
          from: "alert",
        })
      }

      if (props?.setShowModal) props?.setShowModal(false)
    }

    const getAlertsAll = async () => {
      // setLoader(true)
      try {
        let alerts = await api.g_alertsAll({
          dates: [dfns.sub(today, { days: 30 }), today],
          loading_key: "get_alerts",
        })
        if (!alerts) return
        dispatch({ type: "SET_ALERTS", alertList: alerts })
      } catch {
      } finally {
        // setLoader(false)
      }
    }

    useEffect(() => {
      getAlertsAll()
    }, [])

    const onClickAccessSettings = () => {
      history.push(`/accesssettings`)
    }

    const onClickViewall = () => {
      history.push("/alerts")
      if (props?.setShowModal) props?.setShowModal(false)
    }

    let downloadFile = async (d, index, oldLst) => {
      //status 1 indidcates success in file generation
      if (d.status == 1) {
        let hostUrl = host()
        let uri = d.fileSharePath + d.fileName
        let path = `${hostUrl}/api/portal/document?uri=${uri}&type=download`
        download(path, uri.substr(uri.lastIndexOf("/") + 1))
      }

      if (!d.isRead) {
        let req = {
          AlertDescription: d.alertDescription,
          CreatedOn: d.createdOn,
          FileName: d.fileName,
          FileSharePath: d.fileSharePath,
          Id: d.id,
          IsRead: true,
          Status: d.status,
          updatedOn: d.updatedOn,
          UserId: d.userId,
          ErrorInfo: d.errorInfo,
          TotalRecords: d.totalRecords,
          RetrievedRecords: d.retrievedRecords,
        }
        await api.p_updateShipmentDownload(req)
        let alrtlst = oldLst.filter((item) => item.id !== d.id)
        dispatch({
          type: "UPDATE_IS_READ_DOWNLOAD_ALERT",
          doAlertList: alrtlst,
        })
        props.updateCount("sub")
      }
      if (props.setShowModal) props.setShowModal(false)
    }

    return (
      <Component
        {...props}
        {...{
          filterBy,
          setFilterBy,
          sortBy,
          setSortBy,
          alerts,
          sortByOpts,
          filterByOpts,
          gotoReport,
          onClickAccessSettings,
          alertTypeToAlertText,
          iconSrc,
          onClickViewall,
          alertList,
          alertListD,
          alertsD,
          downloadFile,
        }}
      />
    )
  }

export default enhancer
