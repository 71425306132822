import React, { Fragment } from "react"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import Image from "shared/Image"
import { useHistory } from "react-router-dom"
import { ReportConfig } from "shared/Filter/config"
import { useHarsco, useIsTablet } from "shared"
import ComingSoon from "shared/ComingSoon"
import { AnnualScheduleReportServiceTypesByHomeDepotCustomer } from "config"

function ReportGeneratorType(props) {
  let history = useHistory()
  let isTablet = useIsTablet()
  const { isPreviewCustomer, isFullCircle, changeCustomer, profile } =
    useHarsco()
  var reportConfig = []
  if (
    profile.isStoreLevelUser &&
    profile.isHomeDepotUser &&
    AnnualScheduleReportServiceTypesByHomeDepotCustomer.CustomerId.includes(
      changeCustomer?.customer?.v
    )
  )
    reportConfig = Object.keys(ReportConfig).filter(
      (d2) => d2 == "annual_schedule_report"
    )
  else if (
    AnnualScheduleReportServiceTypesByHomeDepotCustomer.CustomerId.includes(
      changeCustomer?.customer?.v
    )
  )
    reportConfig = Object.keys(ReportConfig)
  else
    reportConfig = Object.keys(ReportConfig).filter(
      (d2) => d2 != "annual_schedule_report"
    )

  return (
    <Fragment>
      <div className=" requestServiceBlock">
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left mt-4 mb-3">
          <div className="m-0 labelgenerator">
            <FormattedMessage id="report.title" />
          </div>
        </div>
        <div className="row m-0 px-4 ">
          {reportConfig.map((k) => {
            let d = ReportConfig[k]
            if (d.type != "report" || d.disabled) return null
            if (isFullCircle && d.type === "report" && !d.isFullCircle)
              return null
            if (!isFullCircle && d.isFullCircle) return null
            return (
              <div
                className={`${
                  isTablet
                    ? "col-sm-12 col-md-12 col-lg-12 text-left p-0 ml-4 request-title-block eachrow mr-5 mt-3 mb-3 tooltips reportgen"
                    : "col-sm-6 col-md-6 col-lg-6 text-left p-0 ml-4 request-title-block mr-5 mt-3 mb-3 tooltips reportgen"
                }`}
                key={k}
                onClick={() =>
                  d.coming_soon ||
                  (isPreviewCustomer && k == "exception_report")
                    ? ""
                    : history.push(`/reportgenerator/` + k)
                }
              >
                {d.coming_soon}
                <div className="row m-0">
                  <div className="col-sm-2 col-md-2 col-lg-2 reportgen">
                    <div className="img">
                      <Image
                        className=""
                        src={"ic_report_bg.svg"}
                        height="60px"
                        width="60px"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0 request-title">
                    <div className="service-title">{d.title}</div>
                  </div>
                  {isPreviewCustomer && k == "exception_report" && (
                    <span className="tooltips p_inherit">
                      <ComingSoon />
                    </span>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Fragment>
  )
}
export default ReportGeneratorType
