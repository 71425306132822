import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import api from "api"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    let history = useHistory()
    let [chartData, setChartData] = useState()
    let dates = []

    useEffect(() => {
      ;(async () => {
        let res = await api.g_topProfiles({
          loading_key: "profile-report-donut-chart",
        })
        setChartData(res)
      })()
    }, [])

    let profileReportPage = () => {
      dates = [new Date(chartData?.fromDate), new Date(chartData?.toDate)]
      let [from, to] = dates
      return history.push({
        pathname: "/reportgenerator/profile_report",
        state: { fiscal_span: { from, to } },
      })
    }

    return (
      <Component
        {...props}
        {...{
          chartData,
          profileReportPage,
        }}
      />
    )
  }

export default enhancer
