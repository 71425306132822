import React from "react"

function ProcessRelatedInformation() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Process Related Information</h3>
      <p className="text-justify pl-3 pt-2">
        This document provides guidance to the user community on how to utilize
        the portal.
      </p>
    </div>
  )
}
export default ProcessRelatedInformation
