import React from "react"
import Image from "shared/HelpImage/index"

function DocumentLibrary() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3 ">Document Library</h3>
      <Image
        className="mt-4"
        src="documentlibrarystore.png"
        alt="Document Library Image"
      />
      <p className="text-justify pl-3 pt-2">
        <p>
          The document library lets the user find different types of documents
          (i.e. manifests, job sheets, bill of ladings, etc). The user can
          refine their search or use predefined options. Just click on the down
          arrow in the Predefined Queries field.
        </p>
        <p>
          The library has a quick view option or the user can download the pdf
          document(s).
        </p>
        <p>
          The screen will show the document type, document #, service date, EPA
          id related to the document, the store, city and state.
        </p>
      </p>
      <Image
        className="mt-4 mb-4"
        src="documentlibrarygridstore.png"
        alt="Document Library Image"
      />
    </div>
  )
}
export default DocumentLibrary
