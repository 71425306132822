import React from "react"

function TableView(props) {
  let { columns, type } = props
  return (
    <div className="col-sm-12 col-md-12 col-lg-12 padding-lefts m-0 table-content position-relative tableview">
      <div className="reportgen">
        <div
          className={`table sticky viewtable ${
            ["waste_summary_shipment_report", "exception_report"].includes(type)
              ? "isshipreport "
              : ""
          }`}
        >
          <div className="headers">
            <div className="tr">
              {columns.map((d, i) => (
                <div className="th" key={`${d}${i}`}>
                  {" "}
                  {d}{" "}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TableView
