import React from "react"
import Image from "shared/Image"

const HeaderBar = () => {
  return (
    <div className="header">
      <div className="col-10 offset-1 logo">
        <Image className="logoimg mt-2" src="Connect_Plus_Logo.svg" alt="" />
      </div>
    </div>
  )
}

export default HeaderBar
