import React, { useEffect, useState } from "react"
import api from "api"
import { host } from "api/urls"
import {
  download,
  downloadZip,
  formattedDate,
  openDocInNewWindow,
} from "shared"
import _ from "lodash"
import useDebounce from "shared/debounce"

let enhancer =
  (Component) =>
  ({ history, ...props }) => {
    let [docData, setDocData] = useState({})
    let [allDocsCheck, setAllDocsCheck] = useState(false)
    let [pageNo, gotoPage] = useState(1)
    let [submitted, setSubmitted] = useState(false)
    let [searchTxt, setSearchTxt] = useState(null)

    let fetchData = async () => {
      setSubmitted(false)
      let req = {
        loading_key: "documents",
        index: pageNo,
        wasteName: searchTxt,
      }

      try {
        let res = await api.p_getProfileData(req)
        if (!res) return
        let hostUrl = host()

        res.items.forEach((d) => {
          d.createdDate = formattedDate(d.createdDate)
          d.expiredDate = formattedDate(d.expiredDate)
          d.document = false
          let { fileName, filePath } = d
          if (fileName && filePath) {
            let path = `${hostUrl}/api/portal/document?uri=${filePath}${fileName}`
            d.viewUrl = `${path}&type=view`
            d.downloadUrl = `${path}&type=download`
            d.document = true
          }
        })

        setDocData(res)
        setAllDocsCheck(false)
        setSubmitted(true)
      } catch (error) {
        setDocData({})
        setSubmitted(false)
      }
    }

    useEffect(() => {
      if (pageNo == -1) return
      fetchData()
    }, [pageNo])

    useEffect(() => {
      if (searchTxt != null) {
        if (pageNo > 1) gotoPage(1)
        else fetchData()
      }
    }, [useDebounce(searchTxt, 1000)])

    let updateDocData = (index) => (e) => {
      let docDataCopy = _.cloneDeep(docData)
      let { items } = docDataCopy
      items[index].selected = e.currentTarget.checked
      setDocData(docDataCopy)

      setAllDocsCheck(items.filter((d) => d.selected).length == items.length)
    }

    let changeAllDocs = (e) => {
      let { checked } = e.currentTarget
      setAllDocsCheck(checked)
      let docDataCopy = _.cloneDeep(docData)
      let { items = [] } = docDataCopy
      items.forEach((d) => {
        if (d.document) d.selected = checked
      })
      setDocData(docDataCopy)
    }

    let downLoadDoc = () => {
      const urls = docData.items
        .filter((d) => d.selected)
        .map((dt, i) => ({ url: dt.downloadUrl, name: getFileName(dt, i + 1) }))

      downloadZip(urls, "MyProfile", false)
    }

    const getFileName = (d, i) => {
      const name = `${d.profileNumber.trim()}-${d.wasteName}-${i}.pdf`
      return name.replace("/", "_")
    }

    let viewDoc = (d) =>
      openDocInNewWindow(d.downloadUrl, d.fileName, d.wasteName)

    const handleSearchChange = (e) => {
      setSearchTxt(e.target.value)
    }

    const downloadPDF = (data) => {
      const { downloadUrl, profileNumber, wasteName } = data
      const fileName = `${profileNumber.trim()}-${wasteName}.pdf`
      download(downloadUrl, fileName)
    }

    return (
      <Component
        {...props}
        {...{
          docData,
          updateDocData,
          downLoadDoc,
          changeAllDocs,
          downloadPDF,
          allDocsCheck,
          pageNo,
          gotoPage,
          submitted,
          viewDoc,
          handleSearchChange,
        }}
      />
    )
  }

export default enhancer
