const content = [
  {
    id: 1,
    label: "Service Order:",
    desc: `The order record that captureS all aspects of a service such as date,
        location, what was or is to be serviced (Service Types), status, waste
        streams picked up and documents that are generated as part of the
        service (e.g. BOLs, LDR, Manifests, Job Sheet etc.)`,
  },
  {
    id: 2,
    label: "Service Types",
    desc: `Indicates the order specific scope of work to be performed during each
      Service Order (e.g. Oil Drum, Pharmacy, Sump, etc.)`,
  },
  {
    id: 3,
    label: "Service Schedule",
    desc: `Shows the frequency of the Service Type that will be performed, if it
      is a scheduled service.`,
  },
  {
    id: 4,
    label: "Schedule Type",
    desc: `Example schedule types are: Emergency Response, Urgent, On-Call, etc.`,
  },
  {
    id: 5,
    label: "Service Status",
    desc: `Shows the status of the Service Order as it passes through the stages
      of a Service Order lifecycle. Following are the definitions of Service
      Statuses in Connect+.`,
    isTable: true,
    data: [
      { l1: "Connect+ Status", l2: "Definition", isHeader: true },
      { l1: "New", l2: "Order Created" },
      { l1: "Scheduled", l2: "Order Scheduled/Available for Service" },
      {
        l1: "Serviced",
        l2: `Order Serviced on Truck - Button 'Service Details'
            shows up during and after this status`,
      },
      { l1: "Billable", l2: "Order Reviewed and/or Ready to be Invoiced" },
      { l1: "Invoiced", l2: "Order Invoiced" },
    ],
  },
  {
    id: 6,
    label: "Service Details",
    desc: `The key details from each service performed including but not limited
            to the profile number, waste name, how much was picked up, any
            supplies delivered, manifest number, and generator
            information.`,
  },
  {
    id: 7,
    label: "Service History",
    desc: `A list view of historical orders for a specific location.`,
  },
  {
    id: 8,
    label: "Supporting Documents",
    desc: `Key Documents related to the Service Order performed. Documents can
            include manifest, bill of lading, job sheet, LDR, etc.`,
  },
  {
    id: 9,
    label: "Exception Records",
    desc: `Customer specific, customizable observations input by Clean Earth
            technicians during a Service Order that indicate an unexpected or
            non-optimal event. Customers use this presence of Exceptions as a
            retraining tool and risk mitigation opportunity for instances where
            processes or outcomes from a Service Order do not align with
            expectations.`,
  },
  {
    id: 10,
    label: "Exceptions Glossary",
    desc: `See list of exceptions`,
  },
  {
    id: 11,
    label: "Location number",
    desc: `Indicates a generator store number or physical site number`,
  },
  {
    id: 12,
    label: "Pending Service",
    desc: `Quick view showing upcoming Service Types and the expected month for
            service based on pre-set frequencies.`,
  },
  {
    id: 13,
    label: "Estimated Service Date",
    desc: `This is the week ending (Friday) of the week of the calculated service
            date`,
  },
  {
    id: 14,
    label: "Generator Status Report",
    desc: `Specialized report that highlights instances where, based on waste
            generated during a Service Order, a generator may need to perform a
            change in generator status from Small Quantity Generator (SQG) to
            Large Quantity Generator (LQG) based on RCRA regulations.`,
  },
  {
    id: 15,
    label: "Exception Report",
    desc: `Shows a list of exceptions for each order or set of orders. Viewable
            in Exception by Service Order level detail or in Summary detail using
            total counts by Exception.`,
  },
  {
    id: 16,
    label: "Waste Shipment Summary Report",
    desc: `This report shows all the waste picked up for a specifically defined
            set of criteria. This report converts all waste to pounds for
            reporting purposes.`,
  },
  {
    id: 17,
    label: "Certificate of Treatment",
    desc: `A specialized, customer specific form that provides chain of custody
            acknowledgement after Clean Earth receipt and processing.`,
  },
  {
    id: 18,
    label: "Certificate of Destruction",
    desc: `A specialized, customer specific form that provides chain of custody
            acknowledgement of waste disposal either at a Clean Earth facility or
            other end disposal site.`,
  },
  {
    id: 19,
    label: "(SDS) Safety Data Sheet",
    desc: `Safety data sheet is generated by the manufacturer of the product, and
            is a detailed document explaining what the product is composed
            of, before it is used and considered a waste. (aka MSDS- Material
            safety data sheet)`,
  },
  {
    id: 20,
    label: "LDR- Land disposal Restriction",
    desc: `An LDR indicates concentration levels and/or methods of treatment for
            hazardous constituents (i.e.,&nbsp;
            <a
                href="https://www.epa.gov/hw/treatment-standards-hazardous-wastes-subject-land-disposal-restrictions"
                target="_blank"
                rel="noreferrer"
            >
                treatment standards
            </a>
            ) to meet before land disposal.`,
    containHTML: true,
  },
  {
    id: 21,
    label: "UHC – Underlying Hazardous Constituents form",
    desc: `Underlying Hazardous Constituent means any constituent listed in
            268.48, Table UTS-Universal Treatment Standard which can reasonably be
            expected to be present at the point of generation of the hazardous
            waste, at a concentration above the constituent-specific UTS treatment
            Standard. This form identifies those underlying constituents.`,
  },
]

export default content
