import React from "react"
import Filter from "shared/Filter"
import { FormattedMessage } from "react-intl"
import enhancer from "./enhancer"
import "./style.scss"
import Image from "shared/Image"
import Loading from "shared/Loading"
import Pagination from "shared/Pagination"
import EllipsisText from "react-ellipsis-text"
import classNames from "classnames"
import { isRbsCorporateUser } from "shared"

function ReqHistory(props) {
  let {
    updateFilterData,
    reset,
    reqData,
    viewDetails,
    serviceIcons,
    pageNo,
    gotoPage,
    pageSize,
    reqHistoryData,
    downloadRequestHistory,
    isLoading,
  } = props
  const { totalSize } = reqData
  let isRBS = isRbsCorporateUser()

  return (
    <>
      <Filter onSubmit={updateFilterData} Onreset={reset} />
      <div className="row ml-1 mt-4 text-left">
        <div className="col-md-12 page-title">
          <p className="results-title">
            <FormattedMessage id="requestService.heading" />
          </p>
          <button
            className={classNames("downloadbtn dwn-ld-btn", {
              disabled:
                (reqHistoryData && reqHistoryData?.length === 0) || isLoading,
            })}
            onClick={() => downloadRequestHistory()}
          >
            DOWNLOAD
          </button>
        </div>
      </div>
      <Loading id="requestHistory">
        <div className="row ml-1">
          <div className="col-md-12">
            <table className="table table-borderless table-results text-left">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <FormattedMessage id="requestService.case" />
                  </th>
                  <th>
                    <FormattedMessage id="requestService.date" />
                  </th>
                  <th>
                    <FormattedMessage id="requestService.type" />
                  </th>
                  {isRBS && (
                    <th>
                      <FormattedMessage id="requestService.Brand" />
                    </th>
                  )}
                  <th>
                    <FormattedMessage id="requestService.store" />
                  </th>
                  <th>
                    <FormattedMessage id="requestService.status" />
                  </th>
                  <th>
                    <FormattedMessage id="requestService.description" />
                  </th>
                  <th>
                    <FormattedMessage id="requestService.createdBy" />
                  </th>
                  <th>
                    <FormattedMessage id="requestService.details" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {reqHistoryData &&
                  reqHistoryData.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Image
                            className="results-img"
                            src={serviceIcons[item.serviceTypeFromPortal]}
                          />
                        </td>
                        <td>
                          <u onClick={viewDetails(item)}>{item.caseNumber}</u>
                        </td>
                        <td>{item.createdDate}</td>
                        <td>{item.serviceTypeFromPortal}</td>
                        {isRBS && (
                          <td>
                            <EllipsisText
                              text={
                                item.division?.length > 0 ? item.division : ""
                              }
                              length={10}
                            />
                          </td>
                        )}
                        <td>{item.storeNumber}</td>
                        <td>{item.status}</td>
                        <td>
                          <EllipsisText
                            text={
                              item.description?.length > 0
                                ? item.description
                                : ""
                            }
                            length={20}
                          />
                        </td>
                        <td>{item.createdBy}</td>
                        <td>
                          <button
                            onClick={viewDetails(item)}
                            className=""
                            style={{
                              background: "#65b230",
                              border: "1px solid #65b230",
                              borderRadius: "26px",
                              color: "#fff",
                              fontFamily: "OpenSansSemiBold",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                            }}
                          >
                            VIEW
                          </button>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
            {totalSize == 0 && (
              <div className="col-sm-12 col-md-12 col-lg-12 text-center pt-2 nodata">
                <FormattedMessage id="nodata.records" />
              </div>
            )}
          </div>
        </div>
        {totalSize > 0 && (
          <div className="pl-3">
            <Pagination
              {...{
                pageNo,
                totalRecords: totalSize,
                gotoPage,
                pageSize,
              }}
            />
          </div>
        )}
      </Loading>
    </>
  )
}
export default enhancer(ReqHistory)
