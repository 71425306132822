import React from "react"
import enhancer from "./enhancer"
import "./style.scss"
import Filter from "shared/Filter"
import Image from "shared/Image"
import Loading from "shared/Loading"
import { FormattedMessage } from "react-intl"
import Table from "./Components/Table"
import Graphs from "./Components/Graphs"
import _ from "lodash"
import { useHistory, useLocation } from "react-router-dom"
import DefaultView from "./Components/DefaultView"
import Pagination from "shared/Pagination"
import classnames from "classnames"
import Modal from "shared/Modal"

function ReportGeneratorsList(props) {
  let {
    reportData,
    config,
    download,
    submit,
    graphTitle,
    reset,
    title,
    pageNo,
    gotoPage,
    submitted,
    summary,
    updateDownload,
    toggleExceptionReport,
    type,
    showModel,
    showErrorModel,
    showDownloadModel,
    showErrorDownloadModel,
  } = props
  let history = useHistory()
  let location = useLocation()

  let { totalRecords } = reportData
  let noData = _.isEmpty(reportData.data)
  return (
    <Loading id="report-generator">
      <div className="  mb-3">
        <Filter onSubmit={submit} Onreset={reset} />
        <div className="col-sm-12 col-md-12 col-lg-12 padding-lefts reportgeneratorstatus text-left pt-1 ">
          <span className="regnelabel">
            <span className="regene">
              <span onClick={() => history.goBack()}>
                {" "}
                <FormattedMessage id={"back"} />
              </span>
            </span>
            <span className="subtitle">
              {" "}
              | {"  "}
              <b>{title}</b>
              {location.pathname.includes("general_status") && (
                <>
                  {" "}
                  | {"  "}
                  <b>Legend:{"  "}</b>
                  <span className="colorlegend legend-red"></span>{" "}
                  {">=2200 Lbs Generated (LQG)"}
                  <span className="colorlegend legend-yellow"></span>{" "}
                  {">=220 Lbs Generated <2200 (SQG)"}
                </>
              )}
            </span>
          </span>
        </div>
        {(title === "Invoice Report" || title === "Profile Report") && (
          <div className="padding-lefts heading-text pt-1">{title}</div>
        )}
        {noData && config.emptyHeaders && (
          <DefaultView columns={config.emptyHeaders} {...props} />
        )}
        <Modal show={showModel} wrapperclass={"download-error-popup"}>
          <div className="download-heading">
            <b className="title-heading">WARNING</b>
            <b className="close-button" onClick={() => showErrorModel(false)}>
              X
            </b>
          </div>
          <div className="error-pop-up-body">
            <span className="download-error-message">
              {`Download limit exceeds.`}
              <br />
              {`The maximum limit is 2,00,000 records.`}
            </span>
          </div>
        </Modal>
        <Modal
          show={showDownloadModel}
          wrapperclass={"download-error-popup-green"}
        >
          <div className="download-heading-green">
            <b className="title-heading">INFORMATION</b>
            <b
              className="close-button"
              onClick={() => showErrorDownloadModel(false)}
            >
              X
            </b>
          </div>
          <div className="error-pop-up-body">
            <span className="download-error-message">
              {`Download in-progress.`}
              <br />
              {`Check Bell Icon, when download is ready.`}
            </span>
          </div>
          <div>
            <button
              className="downloadbtn"
              onClick={() => showErrorDownloadModel(false)}
            >
              Ok
            </button>
          </div>
        </Modal>
        <Loading id="reports">
          {submitted && noData && (
            <div className="col-sm-12 col-md-12 col-lg-12 text-center pt-2 nodata">
              <FormattedMessage id="nodata.reports" />
            </div>
          )}
          {!noData && (
            <div className="col-sm-12 col-md-12 col-lg-12 padding-lefts m-0 table-content position-relative">
              {!noData && (
                <div className=" generatorstatus text-left mb-3 donloadbutton ">
                  {location.pathname.includes("exception_report") && (
                    <div className="exception-toggle lh-initial ">
                      <button
                        className={classnames("toggleButton toggleButtonLeft", {
                          toggleActive: type == "exception_report",
                        })}
                        disabled={type == "exception_report"}
                        onClick={() =>
                          toggleExceptionReport("exception_report")
                        }
                      >
                        Details
                      </button>
                      <button
                        className={classnames(
                          "toggleButton toggleButtonRight",
                          {
                            toggleActive: type == "exception_report_summary",
                          }
                        )}
                        disabled={type == "exception_report_summary"}
                        onClick={() =>
                          toggleExceptionReport("exception_report_summary")
                        }
                      >
                        Summary
                      </button>
                    </div>
                  )}
                  <button className="downloadbtn" onClick={updateDownload}>
                    <Image
                      className="downloadimgs d-none"
                      src="ic_download.svg"
                      height="10px"
                      width="28px"
                      alt=""
                      onClick={download}
                    />
                    DOWNLOAD
                  </button>
                </div>
              )}
              <div className="reportgen" id="reports">
                {config?.columns && !noData && (
                  <>
                    <Table
                      columns={config.columns}
                      data={reportData.data}
                      summary={summary}
                      extra={config.extra}
                      {...props}
                    />
                    <Pagination
                      {...{
                        pageNo,
                        totalRecords,
                        gotoPage,
                        pageSize: 20,
                        data: reportData.items,
                      }}
                    />
                  </>
                )}
                {config?.chart && (
                  <Graphs
                    chart={config.chart}
                    data={reportData}
                    title={graphTitle()}
                  />
                )}
              </div>
            </div>
          )}
        </Loading>
      </div>
    </Loading>
  )
}
export default enhancer(ReportGeneratorsList)
