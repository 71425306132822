import React from "react"
import Image from "shared/Image"
import Modal from "shared/Modal"
import { FormattedMessage } from "react-intl"

const SessionTimeOut = (props) => {
  const { sessionTimeOut, logout, setSessionTimeOut, startTimer } = props
  return (
    <Modal show={sessionTimeOut} wrapperclass={"timer-popover"}>
      <div>
        <Image className="mt-4" src={"timer.svg"} />
        <div className="session-expire-text mt-4">
          <FormattedMessage id={"sessionexpire.session"} />
        </div>
        <div className="session-expire-click mt-2">
          <FormattedMessage id={"sessionexpire.sessionextend"} />
        </div>
        <div className="session-expire-click session-expire-click-opacity mt-1">
          <FormattedMessage id={"sessionexpire.sessioncancel"} />
        </div>
        <div className="announcement-count-footer mt-4">
          <button
            className="announcements-cancel-button"
            onClick={() => logout()}
          >
            Cancel
          </button>
          <button
            className="announcements-ok-button"
            onClick={() => {
              startTimer()
              setSessionTimeOut(false)
              document.body.style.overflowY = "auto"
            }}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default SessionTimeOut
