import React from "react"
import Image from "shared/HelpImage/index"

function Support() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Support</h3>

      <Image src="Support_Fc.png" />

      <p className="text-justify pl-3 pt-2">
        Clean Earth is committed to providing you with excellent customer
        service and support.
      </p>
      <p className="text-justify pl-3 pt-2">
        Under the Support menu option, the user can fill out a form to contact
        Clean Earth. The form prepopulates the users name and the company name
        and phone number.
      </p>
      <p className="text-justify pl-3 pt-2">
        The user will need to fill in the fields supplied and hit the submit
        button.
      </p>

      <p className="text-justify pl-3 pt-2">
        Someone will get back the user shortly.
      </p>
    </div>
  )
}
export default Support
