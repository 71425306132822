import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { formattedDate } from "shared"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    let { res } = props
    let [overViewData, setOverViewData] = useState([])

    const user = useSelector((state) => state.profile)
    const { userPreferences } = user
    const { is_tonsConversion_updated } = JSON.parse(userPreferences)
    let [PeriodText, setPeriodText] = useState()

    let orderTiles = [
      "Recycle/Reuse",
      "Waste to Energy",
      "Landfill",
      "Incineration",
    ]
    let qauterView = []
    useEffect(() => {
      ;(async () => {
        if (!res) return
        setPeriodText(
          `(${formattedDate(res?.fromDate)} - ${formattedDate(res?.todate)})`
        )
        orderTiles.forEach((ele) => {
          let obj = res?.treatementTypeTiles?.filter(
            (d) => d.treatmentType === ele
          )

          if (obj?.length > 0) {
            qauterView = [...qauterView, obj[0]]
          } else {
            obj = [
              {
                netWeight: 0,
                percentage: 0,
                treatmentType: ele,
              },
            ]
            qauterView = [...qauterView, obj[0]]
          }
        })
        setOverViewData(qauterView)
      })()
    }, [res])

    return (
      <Component
        {...props}
        {...{ overViewData, PeriodText, is_tonsConversion_updated }}
      />
    )
  }

export default enhancer
