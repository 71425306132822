import React from "react"
import { Route, useLocation } from "react-router-dom"
import Dashboard from "./Dashboard"
import Reports from "./ReportGenerator/Reports"
import ReportGeneratorsList from "./ReportGenerator/List"
import MySchedule from "./MyOrders/CorporateAdmin/index"
import MyStoreSchedule from "./MyOrders/StoreManager/index"
import DocumentLibrary from "./DocumentLibrary"
import ListServices from "./RequestService/ListServices"
import NewScheduleRequest from "./RequestService/NewScheduleRequest"
import RequestCheckout from "./RequestService/RequestCheckout"
import { Switch } from "react-router-dom"
import Alerts from "./Alerts"
import Help from "./Help"
import NotFound from "./NotFound"
import Unauthorised from "./Unauthorised"
import ErrorBoundaries from "shared/ErrorBoundaries"
import ServiceOrder from "./ServiceOrder/index"
import AccessSettings from "./Alerts/AccessSettings"
import Myprofile from "./MyProfile"
import Profiles from "./Profile"
import Support from "./Support"
import Profile from "./EditProfile"
import HistoricalLdrs from "./DocumentLibrary/HistoricalLdrs"
import Invoices from "./Invoices"
import RequestHistory from "./RequestService/RequestHistory"
import RequestHistoryDetail from "./RequestService/RequestHistoryDetail"
import MyHistory from "./MyOrders/FullCircle/index"
import Admin from "./Admin/index"
import UserManagement from "./Admin/UserManagement"
import CreateUser from "./Admin/CreateUser"
import UserCheckout from "./Admin/UserCheckout"
import EditProfileCheckout from "./EditProfile/EditProfileCheckout"
import SupportCheckOut from "./Support/SupportCheckOut"
import ManageAnnouncements from "./Admin/ManageAnnouncements"
import AnnounceCheckout from "./Admin/AnnouncementCheckOut"
import { useSelector } from "react-redux"
import Ticker from "./Dashboard/Ticker"
import Loading from "shared/Loading"
import EditAnnouncements from "./Admin/EditAnnouncements"
import CreateProfile from "./CreateProfile"
import RecertifyProfile from "./RecertifyProfile"
import CreateGenerator from "./Generator/CreateGenerator"
import Generator from "./Generator"
import PdfViewer from "./Print/PdfViewer"
import MyAlerts from "./MyAlerts"
import AlertsSettings from "./MyAlerts/AlertsSettings"
import MIReports from "./Reports/Report"
import MIReportsList from "./Reports/List"
import MnIUserDashBoard from "./Dashboard/MnIUserDashBoard"
import { roles } from "config"

const Routes = (props) => {
  let {
    isTablet,
    hasSidebar,
    setShowAnnouncements,
    setTickerAnnouncements,
    showAnnouncements,
    announcementStatus = false,
  } = props
  let location = useLocation()
  const { changeCustomer, profile } = useSelector((state) => state)
  return (
    <div
      className={`${
        isTablet
          ? "isTablet col-sm-12  col-md-12 col-lg-12 p-0 "
          : location.pathname == "/help" ||
            location.pathname.includes("/requestHistoryDetail/") ||
            location.pathname.includes("/myschedule/serviceorder/")
          ? "col-sm-12  col-md-12 col-lg-12 p-0"
          : "col-sm-9  col-md-9 col-lg-9 p-0"
      } ${hasSidebar ? "open" : ""} ${
        location.pathname == "/help" ? "mb-0" : "mb-5"
      }`}
    >
      {announcementStatus || changeCustomer?.status ? (
        <div className="loader-parent">
          <div className="loader"></div>
        </div>
      ) : (
        <ErrorBoundaries>
          {location?.pathname === "/" ? (
            <Ticker
              setShowAnnouncements={setShowAnnouncements}
              setTickerAnnouncements={setTickerAnnouncements}
              showAnnouncements={showAnnouncements}
            />
          ) : (
            ""
          )}
          <Loading id={"announcement_loading"}>
            <Switch>
              <Route
                exact
                strict
                path="/"
                component={
                  changeCustomer?.customer?.businessSegmentId === 3
                    ? MnIUserDashBoard
                    : changeCustomer?.customer?.businessSegmentId === 1 ||
                      changeCustomer?.customer?.businessSegmentId === 2
                    ? Dashboard
                    : ""
                }
              />
              <Route path="/dashboard" component={Dashboard} />
              <Route
                path="/reportgenerator/:type/:id?/:date?"
                component={Reports}
              />
              <Route
                exact
                strict
                path="/reportgenerator"
                component={ReportGeneratorsList}
              />
              <Route exact strict path="/myschedule" component={MySchedule} />
              <Route
                exact
                strict
                path="/mystoreschedule"
                component={MyStoreSchedule}
              />
              <Route exact strict path="/myprofile" component={Myprofile} />
              {/* {profile?.roleId !== roles.CE_GENERIC_USER && ( */}
              <Route exact strict path="/profiles" component={Profiles} />
              {/* )} */}
              {/* {profile?.roleId !== roles.CE_GENERIC_USER && ( */}
              <Route exact strict path="/generator" component={Generator} />
              {/* )} */}
              {/* {profile?.roleId !== roles.CE_GENERIC_USER && ( */}
              <Route exact strict path="/pdf" component={PdfViewer} />
              {/* )} */}
              <Route
                exact
                strict
                path="/reports/report"
                component={MIReports}
              />
              <Route exact strict path="/reports" component={MIReportsList} />
              {profile?.roleId !== roles.CE_GENERIC_USER && (
                <Route
                  exact
                  strict
                  path="/profiles/createprofile/template/:id"
                  component={CreateProfile}
                />
              )}
              {/* <Route exact strict path="/" component={MnIUserDashBoard} /> */}
              <Route path="/MnIdashboard" component={MnIUserDashBoard} />
              {profile?.roleId !== roles.CE_GENERIC_USER && (
                <Route
                  exact
                  strict
                  path="/generator/createGenerator"
                  component={CreateGenerator}
                />
              )}
              {profile?.roleId !== roles.CE_GENERIC_USER && (
                <Route
                  exact
                  strict
                  path="/profiles/createprofile"
                  component={CreateProfile}
                />
              )}
              {(profile?.roleId !== roles.CE_GENERIC_USER ||
                location?.pathname?.includes("viewprofile")) && (
                <Route
                  exact
                  strict
                  path="/profiles/:type/:id/:version?"
                  component={CreateProfile}
                />
              )}
              {profile?.roleId !== roles.CE_GENERIC_USER && (
                <Route
                  exact
                  strict
                  path="/profiles/recertify"
                  component={RecertifyProfile}
                />
              )}
              <Route
                exact
                strict
                path="/documentlibrary"
                component={DocumentLibrary}
              />
              <Route
                exact
                strict
                path="/documentlibrary/ldr"
                component={HistoricalLdrs}
              />
              <Route
                exact
                strict
                path="/postrequestservice"
                component={RequestCheckout}
              />
              <Route
                exact
                strict
                path="/requestservice"
                component={ListServices}
              />
              <Route
                exact
                strict
                path="/requestservice/:type"
                component={NewScheduleRequest}
              />
              <Route
                exact
                strict
                path="/requestserviceView"
                component={RequestHistory}
              />
              <Route
                exact
                strict
                path="/requestserviceHistory"
                component={RequestHistory}
              />
              <Route exact strict path="/myhistory" component={MyHistory} />
              <Route
                exact
                strict
                path="/requestHistoryDetail/:caseId"
                component={RequestHistoryDetail}
              />
              <Route
                path="/myschedule/serviceorder/:workOrderAzId/:pageNo?"
                component={ServiceOrder}
              />
              <Route exact strict path="/alerts" component={Alerts} />
              {profile?.roleId !== roles.CE_GENERIC_USER && (
                <Route exact strict path="/myalerts" component={MyAlerts} />
              )}
              {profile?.roleId !== roles.CE_GENERIC_USER && (
                <Route
                  exact
                  strict
                  path="/alertsettings"
                  component={AlertsSettings}
                />
              )}
              <Route exact strict path="/support" component={Support} />
              <Route
                exact
                strict
                path="/accesssettings"
                component={AccessSettings}
              />
              <Route exact strict path="/invoices" component={Invoices} />
              <Route exact strict path="/profile" component={Profile} />
              <Route exact strict path="/help" component={Help} />
              <Route
                exact
                strict
                path="/documentlibrarystores"
                component={DocumentLibrary}
              />
              <Route
                exact
                strict
                path="/documentlibrary/ldr"
                component={HistoricalLdrs}
              />
              <Route
                exact
                strict
                path="/admin/editannouncement"
                component={EditAnnouncements}
              />
              <Route
                exact
                strict
                path="/admin/usermanagement"
                component={UserManagement}
              />

              <Route
                exact
                strict
                path="/admin/createuser"
                component={CreateUser}
              />
              <Route
                exact
                strict
                path="/admin/edituser"
                component={CreateUser}
              />
              <Route
                exact
                strict
                path="/admin/usercheckout"
                component={UserCheckout}
              />
              <Route
                exact
                strict
                path="/admin/manageannouncements"
                component={ManageAnnouncements}
              />
              <Route
                exact
                strict
                path="/admin/announcementcheckout"
                component={AnnounceCheckout}
              />
              <Route
                exact
                strict
                path="/admin/editannouncements"
                component={ManageAnnouncements}
              />
              <Route path="/admin" component={Admin} />
              <Route
                exact
                strict
                path="/editprofilecheckout"
                component={EditProfileCheckout}
              />
              <Route
                exact
                strict
                path="/supportCheckOut"
                component={SupportCheckOut}
              />

              <Route exact strict path="/404" component={NotFound} />
              <Route exact strict path="/403" component={Unauthorised} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Loading>
        </ErrorBoundaries>
      )}
    </div>
  )
}

export default Routes
