import React, { useEffect, useState } from "react"
import api from "api"
import { formattedDate, getCompanyInitial, useHarsco } from "shared"
import _ from "lodash"
import { useSelector, useDispatch } from "react-redux"
import useDebounce from "shared/debounce"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    const [historyData, setHistoryData] = useState({})
    const [pageNo, gotoPage] = useState(-1)
    const [generatorAZId, setGeneratoAZId] = useState()
    const [pendingservices, setPendingServices] = useState([])
    const [lastServiceDetails, setLastServiceDetails] = useState({})
    const [storeData, setStoreData] = useState({})
    const {
      storesData,
      defaultGenId,
      profile: { customer_name, customer_id },
    } = useHarsco()
    const companyInitail = getCompanyInitial(customer_name)
    const dispatch = useDispatch()
    const [generator, setGenerators] = useState([])
    const [generatorSearch, setStore] = useState()
    const [open, setOpen] = useState(false)
    const [store, setStoreNumber] = useState({})
    const { changeCustomer } = useSelector((state) => state)

    useEffect(() => {
      if (!_.isEmpty(storesData)) {
        const { storeNumber, epaId, address, city, state, postalCode } =
          storesData
        const storeInfo = {
          address: `${address}, ${city}, ${state}, ${postalCode}`,
          epaId: epaId || "",
          storeNumber: storeNumber || "",
        }
        setStoreData(storeInfo)
        setStoreNumber({
          l: storeNumber,
          v: storeNumber,
        })
      }

      setGeneratoAZId(defaultGenId)
      gotoPage(1)
    }, [storesData])

    useEffect(() => {
      if (pageNo == -1) return
      getHistory()
    }, [pageNo, generatorAZId])

    useEffect(() => {
      ;(async () => {
        if (generatorAZId) {
          let pendingservices = await api.g_pendingService({
            generatorAZId,
            loading_key: "store_dashboard",
          })
          setPendingServices(pendingservices)
          if (pendingservices && pendingservices?.length > 0) {
            pendingservices.forEach((e) => {
              e.scheduleDate = formattedDate(e.scheduleDate)
            })
          }
        }
      })()
    }, [generatorAZId])

    useEffect(() => {
      ;(async () => {
        let g_lastServiceDetails = await api.g_lastServiceDetails()

        if (!_.isNil(g_lastServiceDetails) && g_lastServiceDetails.length > 0) {
          g_lastServiceDetails[0].serviceDatetime = formattedDate(
            g_lastServiceDetails?.[0]?.serviceDatetime
          )
          setLastServiceDetails(g_lastServiceDetails[0])
        }
      })()
    }, [])

    const setGeneratorData = (data) => {
      return data.map((ele) => {
        return { l: ele.storeNumber, v: ele.storeNumber }
      })
    }

    const updateCustomer = async (value) => {
      //trigerring 'SET_CHANGE_CUSTOMER' change event
      // status is boolean flag to enable or disable global spinner
      dispatch({
        type: "SET_CHANGE_CUSTOMER",
        status: true,
        customer_name: changeCustomer?.customer,
        source: false,
      })
      try {
        let res = await api.pa_updateDefaultCustomer({
          customer_id: customer_id,
          generatorId: value.v ? `${encodeURIComponent(value.v)}` : "",
          shouldUpdateDefaultCustomer: false,
        })
        if (res) {
          customerUpdateSuccessful(value)
        } else {
          dispatch({
            type: "SET_CHANGE_CUSTOMER",
            status: false,
            customer_name: { l: customer_name, v: customer_id },
            source: false,
          })
        }
      } catch (err) {
        dispatch({
          type: "SET_CHANGE_CUSTOMER",
          status: false,
          customer_name: changeCustomer?.customer
            ? { l: changeCustomer?.customer?.l, v: changeCustomer?.customer?.v }
            : { l: customer_name, v: customer_id },
          source: false,
        })
      }
    }

    const customerUpdateSuccessful = async (value) => {
      //trigerring 'SET_CHANGE_CUSTOMER' change event
      dispatch({
        type: "SET_CHANGE_CUSTOMER",
        status: true,
        customer_name: changeCustomer?.customer,
        source: false,
        store: true,
      })
    }
    useEffect(() => {
      ;(async () => {
        let response = await api.g_generatorsbycustomer({
          customerId: customer_id,
          store_number: generatorSearch
            ? `${encodeURIComponent(generatorSearch)}`
            : "",
          loading_key: "generator_search",
        })
        let generatorCustomer = setGeneratorData(response)
        setGenerators(generatorCustomer)
      })()
    }, [useDebounce(generatorSearch, 1000)])

    const getHistory = async () => {
      let history = await api.g_serviceHistory({
        generatorAZId,
        date: new Date(),
        loading_key: "service_history",
        index: pageNo,
      })

      if (history.items) {
        history.items.forEach((e) => {
          e.serviceDatetime = formattedDate(e.serviceDatetime)
        })
      }
      setHistoryData(history)
    }

    const serviceHistory = (workOrderAzId) => {
      const newWindow = window.open(
        `/myschedule/serviceorder/${workOrderAzId}/${pageNo}`,
        "_blank",
        "noopener,noreferrer"
      )
      if (newWindow) newWindow.opener = null
    }

    const onSelectField = (title, value) => {
      setOpen(value)
    }

    const onSelectValue = (title, value) => {
      setStore("")
      setStoreNumber(value)
      updateCustomer(value)
    }

    const handleSearchChange = (e) => {
      setStore(e.target.value)
    }

    return (
      <Component
        {...props}
        {...{
          historyData,
          pageNo,
          gotoPage,
          serviceHistory,
          storeData,
          pendingservices,
          lastServiceDetails,
          companyInitail,
          onSelectField,
          onSelectValue,
          handleSearchChange,
          open,
          generator,
          store,
        }}
      />
    )
  }

export default enhancer
