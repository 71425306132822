import React, { useState, useEffect, useCallback, useRef } from "react"
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api"
import { REACT_APP_GOOGLE_MAP_API_KEY as MAP_API_KEY } from "config"
import api from "api"

const containerStyle = {
  width: "100%",
  height: "100%",
}

const options = {
  disableDefaultUI: true, // Disable default UI controls
  zoomControl: false, // Enable zoom control
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
}

const GoogleMapComponent = (props) => {
  const { address } = props
  let [position, setPosition] = useState({ lat: 40.854885, lng: -88.081807 })
  const componentMounted = useRef(true)
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: MAP_API_KEY, // Replace with your API key
  })

  const isEmptyOrWhitespace = (str) => {
    return !str || str.trim().length === 0
  }

  useEffect(() => {
    // Check if address is truthy and non-empty before proceeding
    if (address && !isEmptyOrWhitespace(address)) {
      // Add your validation function here if needed
      ;(async () => {
        try {
          let response = await api.g_getGeoMapLocation(address)
          if (response.results[0]) {
            setPosition(response.results[0].geometry.location)
          }
        } catch (error) {
          // console.error("Error fetching lat-long:", error)
        }
      })()
    }

    return () => {
      // Cleanup function
      componentMounted.current = false
    }
  }, [address])

  const mapRef = useRef(null)

  const onLoad = useCallback((map) => {
    mapRef.current = map
  }, [])

  const onUnmount = useCallback(() => {
    mapRef.current = null
  }, [])

  if (loadError) {
    return <div>Error loading maps</div>
  }

  return isLoaded ? (
    <div className="map-container">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={position}
        zoom={18}
        options={options}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker position={position} />
      </GoogleMap>
    </div>
  ) : (
    <div>Loading...</div>
  )
}

export default React.memo(GoogleMapComponent)
