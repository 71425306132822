import React, { useEffect, useState } from "react"
import api from "api"
import _ from "lodash"
import { useParams } from "react-router-dom"
import {
  useToday,
  download,
  openDocInNewWindow,
  formattedDate,
  downloadZip,
  useDocumentTitle,
} from "shared"
import { toast } from "react-toastify"
import { host } from "api/urls"
import { useHarsco } from "shared"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    const { isStoreManager, isPreviewCustomer, isSPAKCustomer } = useHarsco()
    let [pendingservice, setPendingservice] = useState([])
    let [shipDetails, setShipDetails] = useState([])
    let [supportingDocs, setSupportingDocs] = useState([])
    let [chartTotal, setchartTotal] = useState([])
    let [wasteCompositionChart, setWasteCompositionChart] = useState([])
    let [serviceDetails, setServiceDetails] = useState([])
    let [history, setHistory] = useState([])
    let [exceptionRecords, setExceptionRecords] = useState([])
    let [customShowGlossary, setCustomShowGlossary] = useState(false)
    let params = useParams()
    let [pageNo, gotoPage] = useState(params.pageNo - 0 || 1)
    let [generatorAZId, setGeneratoAZId] = useState(false)
    let [exceptionGlossaryData, setExceptionGlossaryData] = useState([])
    let [searchTerm, setSearchTerm] = useState("")
    let [title, setTitle] = useState("")
    let [workOrderType, setWorkOrderType] = useState("")
    let [totals, setTotals] = useState()

    useDocumentTitle(title)

    let hostUrl = host()

    let { workOrderAzId } = params
    let today = useToday()

    useEffect(() => {
      ;(async () => {
        let shipDetail = await api.g_shipmentDetails({
          workOrderAzId,
          loading_key: "get_shipmentDetails",
        })
        let {
          tableData = [],
          chartData = [],
          workOrderType,
          totals,
        } = shipDetail

        setTotals(totals)

        chartData.forEach((d) => {
          if (!_.isNumber(d.weight)) d.weight = 0
        })
        chartData = chartData.sort(function (a, b) {
          return b.weight - a.weight
        })

        let cdata2 = [],
          cdata3
        if (chartData.length > 6 && chartData.length != 7) {
          cdata2 = chartData.slice(0, 6)
          cdata3 = chartData.slice(6, chartData.length)
          let weight = 0
          cdata3.forEach((d) => (weight += d.weight))
          cdata2.push({ description: "Others", weight, arrr: cdata3 })
          chartData = cdata2
        }
        setWasteCompositionChart(chartData)

        setShipDetails(tableData)

        var totalDataDash = {
          epaAcuteToxic: _.sum(
            tableData.map((item) =>
              item?.wasteCodes
                ?.trim()
                .split(" ")
                .some((d) => d.includes("P")) &&
              !(
                item.weight > 0 &&
                _.includes(
                  _.toLower(item?.properShippingName),
                  _.toLower("Residue Last Contained")
                )
              )
                ? _.round(item?.weight, 2)
                : 0
            )
          ),
          epaHaz: _.sum(
            tableData.map((item) =>
              item.epaHaz === "Y" ? _.round(item.weight, 1) : 0
            )
          ),
          totalWeight: _.sum(tableData.map((item) => _.round(item.weight, 1))),
        }
        setWasteCompositionChart(chartData)

        setchartTotal(totalDataDash)
        setWorkOrderType(workOrderType)

        let serviceDetail = await api.g_serviceDetails({
          workOrderAzId,
          loading_key: "get_serviceDetails",
        })

        if (!serviceDetail) {
          toast.error("Sorry could not load this service order")
          return
        }
        serviceDetail.serviceDate = formattedDate(serviceDetail.serviceDate)
        let { workOrderId, serviceDate, generatorAzId } = serviceDetail

        setGeneratoAZId(generatorAzId)
        setServiceDetails(serviceDetail)
        setTitle(`${workOrderId} - ${serviceDate}`)

        let exceptionRecord = await api.g_exceptionDetails({
          workOrderAzId,
          loading_key: "get_exceptionRecords",
        })
        setExceptionRecords(exceptionRecord)
        let supportingDoc = await api.g_supportingDoc({
          workOrderAzId,
          loading_key: "documents",
        })

        const { REACT_APP_NO_SHIP_DOCUMENTS } = process.env
        const ext_re = /(?:\.([^.]+))?$/
        supportingDoc.forEach((d2) => {
          d2.open = true

          d2.supportingDocLists.forEach((d) => {
            d.fileName = d.fileName.trim()
            let { fileName, filePath } = d
            let path = `${hostUrl}/api/portal/document?uri=${filePath}${encodeURIComponent(
              fileName
            )}`
            d.viewUrl = `${path}&type=view`
            d.downloadUrl = `${path}&type=download`
            d.extension = "pdf"
            let extension = ext_re.exec(fileName)[1]
            if (extension) d.extension = extension.toLocaleLowerCase()
          })
        })

        if (workOrderType === "NoShip") {
          //Reading No ship documents from environment variables
          let documentIds = REACT_APP_NO_SHIP_DOCUMENTS.split(",").map((i) =>
            parseInt(i)
          )
          documentIds = documentIds.map((i) => parseInt(i))

          supportingDoc.map((ele) => {
            let supportDocumentLists = []
            ele.supportingDocLists.forEach((element) => {
              //checking for No ship documents to show
              if (documentIds.includes(element.documentTypeId)) {
                supportDocumentLists = [...supportDocumentLists, element]
              }
            })
            ele.supportingDocLists = [...supportDocumentLists]
            return { ...ele }
          })

          //filtering out if there are no ship documents
          supportingDoc = supportingDoc.filter(
            (element) => element?.supportingDocLists?.length > 0
          )
        }

        setSupportingDocs(supportingDoc)

        let exception_grossary = await api.g_exceptionGrossary()
        exception_grossary.forEach((d) => {
          d.exceptionName = d.exceptionName.replace(/\s+/g, " ").trim()
        })

        setExceptionGlossaryData(exception_grossary)
      })()
    }, [])

    const getHistory = async () => {
      if (!generatorAZId) return
      let historys = await api.g_serviceHistory({
        generatorAZId,
        loading_key: "get_history",
        index: pageNo,
        date: today,
      })
      historys.items.forEach((d) => {
        d.dateFormat = formattedDate(d.serviceDatetime)
      })

      setHistory(historys)
    }

    let downloadDoc = (d) => download(d.downloadUrl, d.fileName)
    let viewDoc = (d) => openDocInNewWindow(d.downloadUrl, d.fileName)

    let openFolder = (i) => {
      let docs2 = _.clone(supportingDocs)
      docs2[i].open = !docs2[i].open
      setSupportingDocs(docs2)
    }

    let selectFolder = (i) => {
      let docs2 = _.clone(supportingDocs)
      docs2[i].selected = !docs2[i].selected
      setSupportingDocs(docs2)
    }

    let selectAllFolder = (e) => {
      let docs2 = _.clone(supportingDocs)
      docs2.forEach((d) => (d.selected = e.target.checked))
      setSupportingDocs(docs2)
    }

    let isAllSelected = () =>
      supportingDocs.length == supportingDocs.filter((d) => d.selected).length
    let isSomeSelected = () => supportingDocs.filter((d) => d.selected)[0]

    let downloadSelectedFolder = () => {
      let urls = []
      supportingDocs.forEach((d2) => {
        if (d2.selected) {
          d2.supportingDocLists.forEach((ele) => {
            let name = ele.fileName
            if (name.includes("/")) {
              const path = name.split("/")
              name = path[path.length - 1]
            }
            const fileName = d2.manifestTrackingNo + "/" + name
            urls.push({ url: ele.downloadUrl, name: fileName })
          })
        }
      })
      const { workOrderId, serviceDate } = serviceDetails
      const folderName = `ServiceOrder-${workOrderId}`.trim()
      downloadZip(urls, folderName, false, serviceDate)
    }

    useEffect(() => {
      if (pageNo == -1) return
      getHistory()
    }, [pageNo, generatorAZId])

    useEffect(() => {
      ;(async () => {
        if (!generatorAZId) return
        let pendingservices = await api.g_pendingService({
          generatorAZId,
          loading_key: "get_pendingservice",
        })
        pendingservices.forEach((d) => {
          d.dateFormat = formattedDate(d.scheduleDate)
        })

        setPendingservice(pendingservices)
      })()
    }, [generatorAZId])

    const exceptionGlossary = () => {
      setSearchTerm("")
      setCustomShowGlossary(!customShowGlossary)
    }

    const toggleModalGlossary = () => {
      setCustomShowGlossary(!customShowGlossary)
    }

    const onClickOK = () => {
      setCustomShowGlossary(!customShowGlossary)
    }

    const serviceHistory = (workOrderAzId) => {
      const newWindow = window.open(
        `/myschedule/serviceorder/${workOrderAzId}/${pageNo}`,
        "_blank",
        "noopener,noreferrer"
      )
      if (newWindow) newWindow.opener = null
    }

    const searchGlossary = (term, glossaryData) => {
      if (!term.trim().length) return glossaryData

      return glossaryData.filter((glossary) => {
        return `${glossary.exceptionName}  ${glossary.explanation}`
          .toLowerCase()
          .includes(term.toLowerCase())
      })
    }

    return (
      <Component
        {...props}
        {...{
          pendingservice,
          shipDetails,
          supportingDocs,
          wasteCompositionChart,
          chartTotal,
          serviceDetails,
          history,
          exceptionRecords,
          exceptionGlossaryData,
          exceptionGlossary,
          toggleModalGlossary,
          onClickOK,
          customShowGlossary,
          gotoPage,
          pageNo,
          serviceHistory,
          downloadDoc,
          viewDoc,
          openFolder,
          selectFolder,
          selectAllFolder,
          isAllSelected,
          isSomeSelected,
          downloadSelectedFolder,
          searchTerm,
          setSearchTerm,
          searchGlossary,
          isStoreManager,
          workOrderType,
          isPreviewCustomer,
          totals,
          isSPAKCustomer,
        }}
      />
    )
  }

export default enhancer
