import React from "react"
import enhancer from "./enhancer"
import "./style.scss"

function Help(props) {
  let {
    activeParentTopic,
    setActiveParentTopic,
    activeSubTopic,
    setActiveSubTopic,
    setActiveContentKey,
    // activeContentKey,
    HelpC,
    activeNestedTopic,
    setActiveNestedTopic,
    activeNestedTopicSubTopic,
    setActiveNestedTopicSubTopic,
    helpDetails,
  } = props

  return (
    <div className="row help-section m-0">
      <div
        className="col-3 help-aside help-section-contents"
        style={{
          "background-color": "#F1F1F2",
          "box-shadow": "0px 3px 6px #00000029",
          height: "95%",
        }}
      >
        <div className="help-ul">
          {helpDetails.map((el, pi) => (
            <>
              <div
                onClick={() => {
                  setActiveParentTopic(el.label)
                  setActiveContentKey(el.contentKey)
                  setActiveSubTopic(el.label)
                }}
                key={pi}
                className={`parent pt-1 ${
                  activeParentTopic === el.label ? "active" : ""
                }`}
              >
                <div className="arrow-div">
                  <span className="help-arrow"> </span>
                </div>
                <div className="help-content">
                  {pi + 1}.{el.label}{" "}
                </div>
              </div>
              <div className="children">
                {el?.subTopics &&
                  el?.subTopics.map((el1, i) => (
                    <div key={i} className="subtopic">
                      <span
                        className={`cur-pointer ${
                          activeSubTopic === el1.label ? "active" : ""
                        } `}
                        onClick={() => {
                          setActiveSubTopic(el1.label)
                          setActiveParentTopic(el.label)
                          setActiveContentKey(el1.contentKey)
                          setActiveNestedTopic("")
                          setActiveNestedTopicSubTopic("")
                        }}
                      >
                        {pi + 1}.{i + 1} {` `} {el1.label}
                      </span>
                      <div className="children">
                        {el1?.subTopics &&
                          el1?.subTopics.map((el2, idx) => {
                            let index = i + Math.random()
                            return (
                              <div key={index} className={"subtopic"}>
                                <span
                                  className={`cur-pointer ${
                                    activeNestedTopic === el2.label
                                      ? "active"
                                      : ""
                                  } `}
                                  onClick={() => {
                                    setActiveSubTopic(el1.label)
                                    setActiveParentTopic(el.label)
                                    setActiveContentKey(el2.contentKey)
                                    setActiveNestedTopic(el2.label)
                                    setActiveNestedTopicSubTopic("")
                                  }}
                                >
                                  {pi + 1}.{i + 1}.{idx + 1} {` `} {el2.label}
                                </span>
                                <div className="children">
                                  {el2?.subTopics &&
                                    el2?.subTopics.map((el3, idy) => (
                                      <div
                                        key={index + Math.random()}
                                        className="subtopic"
                                      >
                                        <span
                                          className={`cur-pointer ${
                                            activeNestedTopicSubTopic ===
                                            el3.label
                                              ? "active"
                                              : ""
                                          } `}
                                          onClick={(e) => {
                                            e.cancelBubble = true
                                            if (e.stopPropagation)
                                              e.stopPropagation()
                                            setActiveSubTopic(el1.label)
                                            setActiveParentTopic(el.label)
                                            setActiveContentKey(el3.contentKey)
                                            setActiveNestedTopicSubTopic(
                                              el3.label
                                            )
                                            setActiveNestedTopic(el2.label)
                                          }}
                                        >
                                          {pi + 1}.{i + 1}.{idx + 1}.{idy + 1}{" "}
                                          {` `} {el3.label}
                                        </span>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  ))}
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="col-9 help-main pt-1 help-section-contents">
        {<HelpC />}
      </div>
    </div>
  )
}
export default enhancer(Help)
