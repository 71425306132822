import React, { useEffect, useState } from "react"
import api from "api"
import { format } from "date-fns"
import {
  download,
  useDocsTypes,
  openDocInNewWindow,
  pdfToObject,
  useHarsco,
  formatNumber,
} from "shared"
import * as dfns from "date-fns"
import _, { isNil } from "lodash"
import { Workbook } from "exceljs"
import { saveAs } from "file-saver"
import { useSelector } from "react-redux"

let enhancer =
  (Component) =>
  ({ history, ...props }) => {
    let [docData, setDocData] = useState({})
    let [allDocsCheck, setAllDocsCheck] = useState(false)
    let [selectedDoc, setSelectedDoc] = useState({})
    let [filterData, setFilterData] = useState({})
    let [pageNo, gotoPage] = useState(1)
    let [pdfUrl, setPdfUrl] = useState("")
    let [submitted, setSubmitted] = useState(false)
    const docsTypes = useDocsTypes()
    const { isStoreManager } = useHarsco()
    const user = useSelector((state) => state.profile)

    let fetchData = async () => {
      setSubmitted(false)
      if (!filterData.dates) return
      let { dates, states, cities, customers } = filterData
      let req = {
        loading_key: "invoicies",
        index: pageNo,
        state: states,
        city: cities,
        name: customers,
      }
      req.dates = [dates.from, dates.to]

      let res = await api.p_invoicesList(req)
      if (!res) return
      setDocData(res)
      setAllDocsCheck(false)
      setSubmitted(true)
    }

    let updateFilterData = (data) => {
      setFilterData(data)
      gotoPage(-1)
      setTimeout(() => {
        gotoPage(1)
      }, 10)
    }

    useEffect(() => {
      if (pageNo == -1) return
      fetchData()
    }, [pageNo])

    useEffect(() => {
      ;(async () => {
        if (!selectedDoc.fileName) return
        setPdfUrl("")
        let objectUrl = await pdfToObject(
          selectedDoc.downloadUrl,
          selectedDoc.fileName
        )
        setPdfUrl(objectUrl)
      })()
    }, [selectedDoc])

    let updateDocData = (index) => (e) => {
      let docDataCopy = _.cloneDeep(docData)
      let { items } = docDataCopy
      items[index].selected = e.currentTarget.checked
      setDocData(docDataCopy)

      setAllDocsCheck(items.filter((d) => d.selected).length == items.length)
    }
    let changeAllDocs = (e) => {
      let { checked } = e.currentTarget
      setAllDocsCheck(checked)
      let docDataCopy = _.cloneDeep(docData)
      let { items = [] } = docDataCopy
      items.forEach((d) => (d.selected = checked))
      setDocData(docDataCopy)
    }

    let xlsHeader = (header) => {
      header.eachCell({ includeEmpty: true }, (cell) => {
        let thinB = { style: "thin" }
        cell.alignment = { wrapText: true }
        cell.font = {
          bold: true,
        }

        cell.border = {
          bottom: thinB,
          top: thinB,
          right: thinB,
          left: thinB,
        }

        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "d9e8f4" },
        }
      })
    }

    const checkForEmptyArrays = (label, data, element) => {
      //
      let dup = label.filter((e) => !e.includes("allSelect"))
      let allDup = label.filter((e) => e.includes("allSelect"))

      if (dup.includes(element)) {
        if (allDup.filter((e) => e.includes(element)).length === 0) return true
      } else {
        return false
      }
    }

    const getName = (ele) => {
      if (ele === "customers") {
        return "names"
      }
      return ele
    }

    let downloadData = async () => {
      let { dates, states, stores, customers } = filterData
      let req = {
        loading_key: "invoicies",
        index: 1,
        size: docData.totalRecords,
        state: states,
        stores,
        customers,
      }
      req.dates = [dates.from, dates.to]

      let res = await api.p_invoicesList(req)

      if (!res) return

      res = isNil(res) ? res?.items : res
      let filename = `${"Invoices_"}${dfns.format(
        new Date(),
        "MM-dd-yyyy"
      )}.xlsx`

      let workbook = new Workbook()
      let worksheet = workbook.addWorksheet("INVOICES")

      worksheet.properties.defaultColWidth = 40

      let headers = [
        "Invoice No",
        "Generator Name",
        "Order No",
        "Invoice Date",
        "Invoiced Amount",
        "Generator Address",
      ] //Object.keys(data[0]).map((d) => keys[d])
      let row = worksheet.addRow(headers)
      xlsHeader(row)

      res.items.forEach((d) => {
        let row = [
          d["invoiceNumber"],
          d["generatorName"],
          d["orderNumber"],
          format(new Date(d["invoiceDate"]), "LL/dd/yyyy"),
          `$ ${formatNumber(d["invoiceTotal"].toFixed(2))}`,
          `${d["address"]},${d["city"]},${d["state"]}`,
        ]
        worksheet.addRow(row)
      })

      let worksheetdetails = workbook.addWorksheet("DETAILS")

      let labels = Object.keys(filterData)

      let values = Object.values(filterData)
      worksheetdetails.properties.defaultColWidth = 40
      let data = []
      data.push(["Invoices"])
      data.push([""])
      data.push(["Filter Applied"])

      const filterKeys = {
        states: "States",
        names: "Names",
        time_presets_lastquateryear: "Date Range",
        cities: "Cities",
        dates: "Date Range",
      }

      labels.forEach((element, index) => {
        if (element === "time_presets_lastquateryear" || element === "dates") {
          let checkDate = data.filter((element) => element[0] === "Date Range")

          //when user selects date through calendar
          if (
            format(new Date(filterData["dates"].from), "LL/dd/yyyy") !==
              format(new Date(), "LL/dd/yyyy") &&
            checkDate.length === 0
          ) {
            let row = [
              "Date Range",
              `From : ${format(
                new Date(filterData["dates"].from),
                "LL/dd/yyyy"
              )} To : ${format(
                new Date(filterData["dates"].to),
                "LL/dd/yyyy"
              )}`,
            ]
            data.push(row)
          }
          checkDate = data.filter((element) => element[0] === "Date Range")
          //when user selects date through dropdown
          if (
            format(
              new Date(filterData["time_presets_lastquateryear"].from),
              "LL/dd/yyyy"
            ) !== format(new Date(filterData["dates"].from), "LL/dd/yyyy") &&
            checkDate.length === 0
          ) {
            let row = [
              "Date Range",
              `From : ${format(
                new Date(filterData["time_presets_lastquateryear"].from),
                "LL/dd/yyyy"
              )} To : ${format(
                new Date(filterData["time_presets_lastquateryear"].to),
                "LL/dd/yyyy"
              )}`,
            ]
            data.push(row)
          }
        } else if (
          element !== "time_presets_lastquateryear" &&
          !element.includes("allSelect") &&
          element !== "dates"
        ) {
          let row = []
          if (values[index].length > 0) {
            row = [filterKeys[getName(element)], values[index].toString()]
            data.push(row)
          }
          if (values[index].length === 0) {
            if (checkForEmptyArrays(labels, filterData, element)) {
              data.push([filterKeys[getName(element)], ` `])
            }
          }
        } else if (element.includes("allSelect")) {
          let key = element.split(" ")
          if (key.length > 1) data.push([filterKeys[getName(key[1])], `All`])
        }
      })

      const { firstName, lastName } = user

      data.push([""])
      data.push(["Generated By:", ` ${firstName} ${lastName}`])
      data.push([
        "Generated Date: ",
        format(new Date(), "MMM dd, yyyy HH:mm z"),
      ])
      data.push([""])
      data.push(["Clean Earth Connect+"])

      //creating xlsx file
      data.forEach((ele, index) => {
        if (index === 0) {
          xlsHeader(worksheetdetails.addRow(ele))
          worksheetdetails.mergeCells(`A${1}:B${1}`)
        }
        if (ele[0].includes("Filter Applied")) {
          worksheetdetails.addRow(ele)
          worksheetdetails.mergeCells(`A${index + 1}:B${index + 1}`)
        }
        if (ele[0].length === 0) {
          worksheetdetails.addRow(ele)
          worksheetdetails.mergeCells(`A${index + 1}:B${index + 1}`)
        } else if (index !== 0 && !ele[0].includes("Filter Applied")) {
          worksheetdetails.addRow(ele)
        }
      })

      createOuterBorder(
        worksheetdetails,
        { row: 1, col: 1 },
        { row: data.length + 1, col: 2 },
        "thin"
      )

      const buffer = await workbook.xlsx.writeBuffer()
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

      const blob = new Blob([buffer], { type: fileType })

      saveAs(blob, filename)
    }

    const createOuterBorder = (
      worksheet,
      start = { row: 1, col: 1 },
      end = { row: 1, col: 1 },
      borderWidth = "medium"
    ) => {
      const borderStyle = {
        style: borderWidth,
      }
      for (let i = start.row; i <= end.row; i++) {
        const leftBorderCell = worksheet.getCell(i, start.col)
        const rightBorderCell = worksheet.getCell(i, end.col)
        leftBorderCell.border = {
          ...leftBorderCell.border,
          left: borderStyle,
        }
        rightBorderCell.border = {
          ...rightBorderCell.border,
          right: borderStyle,
        }
      }
      for (let i = start.col; i <= end.col; i++) {
        const topBorderCell = worksheet.getCell(start.row, i)
        const bottomBorderCell = worksheet.getCell(end.row, i)
        topBorderCell.border = {
          ...topBorderCell.border,
          top: borderStyle,
        }
        bottomBorderCell.border = {
          ...bottomBorderCell.border,
          bottom: borderStyle,
        }
      }
    }

    let reset = () => {
      setDocData([])
    }

    let viewDoc = (d) => openDocInNewWindow(d.downloadUrl, d.fileName)

    return (
      <Component
        {...props}
        {...{
          docData,
          updateDocData,
          //downloadDoc,
          downloadData,
          changeAllDocs,
          filterData,
          updateFilterData,
          selectedDoc,
          setSelectedDoc,
          download,
          reset,
          allDocsCheck,
          pageNo,
          gotoPage,
          submitted,
          pdfUrl,
          viewDoc,
          docsTypes,
          isStoreManager,
        }}
      />
    )
  }

export default enhancer
