import React from "react"
import Image from "shared/HelpImage/index"

function ChangeUnitOfMeasure() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">
        Full Circle Dashboard: Change Unit of measure on Dashboard.{" "}
      </h3>

      <p className="text-justify pl-3 pt-2">
        The user click on the pencil button to get the option to switch between
        Tons or Metric Tons.
      </p>
      <Image src="changeUnitOfMeasure.png" />
    </div>
  )
}
export default ChangeUnitOfMeasure
