import React, { Fragment, useState } from "react"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import ToggleButton from "shared/ToggleButton"
import enhancer from "./enhancer"
import Image from "shared/Image"
import classnames from "classnames"
import Input from "routes/Admin/Components/Input"
import Modal from "shared/Modal"
import { useSelector } from "react-redux"
import PrintLabelModal from "routes/Print/PrintLabelModal"
import BarChart from "./barchart"
import { getBackgrounColor } from "routes/Utilities/util"
// import Pagination from "shared/Pagination"

function MnIUserDashBoard(props) {
  let {
    goToCloneProfile,
    goToRecertifyProfile,
    viewProfile,
    weekDates,
    changeActiveOverview,
    overviewTypes,
    statuses,
    selectedGrid,
    changeStatus,
    sortArrows,
    // setShowInfo,
    //showInfo,
    deleteProfile,
    profileList,
    // gotoPage,
    // pageNo,
    // totalRecords,
    showDelete,
    editProfileUser,
    setCurrentData,
    currentData,
    setShowModal,
    profileCounts,
    showModal,
    handlePrintState,
    downloadDocument,
    setProfileNameAction,
    overviewModule,
    changeActiveModule,
    moduleOverviewList,
    printDocument,
    // mapDefaultGenerator,
    loading,
    gridLoader,
  } = props

  const [copyModal, setCopyModal] = useState(false)
  const [activeProfile, setActiveProfile] = useState("")
  const [profileName, setProfileName] = useState("")

  const printState = useSelector((state) => state.profileDetails.printStatus)

  const showdeleteMessage = useSelector(
    (state) => state.generatorDetails.showdeleteMessage
  )

  const getcountValues = (data, status) => {
    if (status === "Approved") {
      return data[0].approvedCount
    } else if (status === "Expired") {
      return data[0].expiredCount
    } else if (status === "Expiring Soon") {
      return data[0].expiringSoonCount
    } else if (status === "Pending Clean Earth Approval") {
      return data[0].pendingCleanEarthApprovalCount
    } else return data[0].pendingCustomerResponseCount
  }

  if (loading) {
    return (
      <div className="loader-parent">
        <div className="loader"></div>
      </div>
    )
  }

  return (
    <Fragment>
      <iframe
        id="pdf-frame"
        title="Print PDF"
        style={{ display: "none" }}
      ></iframe>
      {printState ? <PrintLabelModal /> : ""}
      <div className="mniDashBoardDiv">
        <Modal show={copyModal} style={{ padding: 20 }}>
          <div className="p-4" style={{ width: "40VW" }}>
            <div className="dsp-flx col-sm-12 mt-2">
              <div className="label" style={{ textAlign: "left" }}>
                <FormattedMessage id="profile.enterprofilename" />
              </div>
            </div>
            <div className="mt-4" style={{ textAlign: "left" }}>
              <div
                className="form-input-block col-sm-8 mt-2"
                style={{ minWidth: 85 }}
              >
                <span className="label pl-0" style={{ fontSize: 12 }}>
                  <FormattedMessage id="profile.name" />
                </span>
                <Input
                  name="profileName"
                  value={profileName}
                  onFieldChange={(e) => {
                    setProfileName(e.target.value)
                  }}
                />
              </div>
            </div>
            <div className="pl-4 mt-4" style={{ textAlign: "right" }}>
              <button
                className={`cancel-button mr-2`}
                onClick={() => {
                  setProfileName("")
                  setProfileNameAction("")
                  setCopyModal(false)
                  setActiveProfile("")
                }}
                style={{ padding: "6px 20px" }}
              >
                <FormattedMessage id="profile.cancelbtn" />
              </button>
              <button
                className={`${classnames("downloadbtn")} ${
                  profileName?.trim()?.length === 0 ? "disabledBgBtn" : ""
                }`}
                onClick={() => {
                  setProfileNameAction(profileName)
                  goToCloneProfile(
                    "editprofile",
                    activeProfile.status?.toLowerCase() == "draft"
                      ? activeProfile?.profileId
                      : activeProfile?.profile
                  )
                  setProfileName("")
                }}
                disabled={profileName?.trim()?.length === 0}
                style={{ padding: "6px 20px" }}
              >
                <FormattedMessage id="profile.okaybtn" />
              </button>
            </div>
          </div>
        </Modal>
        {/* Generator Info Delete popup*/}
        <Modal show={showModal} style={{ padding: 20 }}>
          <div style={{ padding: 20, fontSize: 12 }}>
            {!showdeleteMessage && (
              <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus text-left py-4">
                <div className="m-0 labelgenerator myprofile">
                  <FormattedMessage id="profile.generatorinfo.deleteprofile" />
                </div>
              </div>
            )}
            {!showdeleteMessage && (
              <div className="ml-3 mb-4" style={{ textAlign: "left" }}>
                <FormattedMessage id="profile.generatorinfo.suredelete" />{" "}
                {currentData?.profile}?
              </div>
            )}
            {showdeleteMessage && (
              <div className="ml-3 mb-4" style={{ textAlign: "left" }}>
                <FormattedMessage id="profile.generatorinfo.profiledeleted" />
              </div>
            )}

            {!showdeleteMessage && (
              <div
                className="dsp-flx col-sm-12 mt-2"
                style={{ flexDirection: "row" }}
              >
                <button
                  className="green-button"
                  style={{
                    background: "#ffffff",
                    color: "#0092BC",
                    border: "1px solid #0092BC",
                  }}
                  type="button"
                  onClick={() => {
                    setShowModal(false)
                  }}
                >
                  <FormattedMessage id="profile.generatorinfo.cancel" />
                </button>
                <button
                  className="green-button"
                  type="button"
                  onClick={() => {
                    deleteProfile(currentData?.profile)
                    setTimeout(() => {
                      showDelete(false)
                      setShowModal(false)
                    }, 3000)
                  }}
                >
                  <FormattedMessage id="profile.generatorinfo.confirm" />
                </button>
              </div>
            )}
          </div>
        </Modal>
      </div>
      <div>
        <div className="col-sm-12 col-md-12 col-lg-12 generatorstatus text-left my-4">
          {/* <div> */}
          {/* <label className="ml-2 weeklylabel"> */}
          <div className="m-0 labelgenerator myprofile">
            <FormattedMessage id={weekDates.headerLabel} />
            {/* </label> */}
            {/* <span className="mg-l"></span> */}
          </div>
          <div
            className="downloadsec btn-group overview-action"
            style={{
              marginTop: "-5px",
              height: "28px",
            }}
          >
            <ToggleButton
              onClickMethod={changeActiveOverview}
              classname={" btn-light weekly"}
              active={overviewTypes.last30Days.active}
              btnText="Last 30 days"
              clickValue="last30Days"
            />
            <ToggleButton
              onClickMethod={changeActiveOverview}
              classname={" btn-light last30days"}
              active={overviewTypes.last60Days.active}
              btnText="Last 60 days"
              clickValue="last60Days"
            />
            <ToggleButton
              onClickMethod={changeActiveOverview}
              classname={" btn-light last90days"}
              active={overviewTypes.last90Days.active}
              btnText="Last 90 days"
              clickValue="last90Days"
            />
          </div>
        </div>
        {/* <div className="pb-2" /> */}
        {/* <div className="ml-2 row my-3">
          {statuses?.map((status, i) => (
            <div key={status.key} className="col">
              <div
                className={
                  status.key === selectedGrid.key
                    ? "p-3 d-flex justify-content-between align-items-center text-center v-shaped-box"
                    : "p-3 d-flex justify-content-between align-items-center text-center status-box"
                }
                onClick={() => changeStatus(status)}
                style={{ fontSize: 14 }}
              >
                <div className="text-left">{status.value}</div>
                <div className="text-right">
                  {profileCounts.data && profileCounts.data.length > 0
                    ? getcountValues(profileCounts.data, status.value)
                    : "0"}
                </div>
              </div>
            </div>
          ))}
        </div> */}
        <div className="ml-2 mt-4 row" style={{ marginBottom: "3rem" }}>
          {statuses?.map((status, i) => (
            <div key={status.key} className="col">
              <div
                className="mniDashBoardTile-top"
                onClick={() => changeStatus(status)}
                style={{
                  fontSize: 14,
                  background: getBackgrounColor(status),
                  cursor:
                    status.key === selectedGrid.key ? "default" : "pointer",
                }}
              >
                <div className="mniDashBoardTile-lable-name">
                  {status.value}
                </div>
                <div className="mniDashBoardTile-tileQty">
                  {profileCounts.data && profileCounts.data.length > 0
                    ? getcountValues(profileCounts.data, status.value)
                    : "0"}
                </div>
              </div>
              <div
                className={
                  status.key === selectedGrid.key
                    ? "mniDashBoard-arrow-down"
                    : ""
                }
                style={{
                  borderTop:
                    status.key === selectedGrid.key
                      ? `30px solid ${getBackgrounColor(status)}`
                      : "",
                }}
              >
                {" "}
              </div>
            </div>
          ))}
        </div>
      </div>

      <>
        <div
          className="col-sm-12 col-md-12 col-lg-12 ml-4 p-0 table sticky isshipreport"
          style={{
            maxHeight: 400,
            overflowY: "auto",
            marginTop: "-20px",
            overflowX: "hidden",
          }}
        >
          <div className="row m-0 headers">
            <div className={`tr active`}>
              <div
                className={`th customsort inactive title-container`}
                colSpan={1}
                style={{
                  display: "inline-block",
                  boxSizing: "border-box",
                  width: 85,
                }}
              >
                <span className="title-text">
                  <FormattedMessage id="profile.profile" />
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    props?.sortData(profileList, {
                      fieldName: "profile",
                      type: "string",
                    })
                  }
                >
                  {sortArrows("profile")}
                </span>
              </div>
              <div
                className={`th customsort inactive title-container`}
                colSpan={1}
                style={{
                  display: "inline-block",
                  boxSizing: "border-box",
                  width: 70,
                }}
              >
                <span className="title-text">
                  <FormattedMessage id="profile.version" />
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    props?.sortData(profileList, {
                      fieldName: "version",
                      type: "number",
                    })
                  }
                >
                  {sortArrows("version")}
                </span>
              </div>
              <div
                className={`th customsort inactive title-container`}
                colSpan={1}
                style={{
                  display: "inline-block",
                  boxSizing: "border-box",
                  width: 175,
                }}
              >
                <span className="title-text">
                  <FormattedMessage id="profile.waste" />
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    props?.sortData(profileList, {
                      fieldName: "wasteName",
                      type: "string",
                    })
                  }
                >
                  {sortArrows("wasteName")}
                </span>
              </div>
              <div
                className={`th customsort inactive title-container`}
                colSpan={2}
                style={{
                  display: "inline-block",
                  boxSizing: "border-box",
                  width: 210,
                }}
              >
                <span className="title-text">
                  <FormattedMessage id="profile.generatorname" />
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    props?.sortData(profileList, {
                      fieldName: "generatorName",
                      type: "string",
                    })
                  }
                >
                  {sortArrows("generatorName")}
                </span>
              </div>
              <div
                className={`th customsort inactive title-container`}
                colSpan={1}
                style={{
                  display: "inline-block",
                  boxSizing: "border-box",
                  width: 75,
                }}
              >
                <span className="title-text">
                  <FormattedMessage id="profile.status" />
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    props?.sortData(profileList, {
                      fieldName: "status",
                      type: "string",
                    })
                  }
                >
                  {sortArrows("status")}
                </span>
              </div>
              <div
                className={`th customsort inactive title-container`}
                colSpan={1}
                style={{
                  display: "inline-block",
                  boxSizing: "border-box",
                  width: 100,
                }}
              >
                <span className="title-text">
                  <FormattedMessage id="profile.approveDate" />
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    props?.sortData(profileList, {
                      fieldName: "createdDate",
                      type: "date",
                    })
                  }
                >
                  {sortArrows("createdDate")}
                </span>
              </div>
              <div
                className={`th customsort inactive title-container`}
                colSpan={1}
                style={{
                  display: "inline-block",
                  boxSizing: "border-box",
                  width: 90,
                }}
              >
                <span className="title-text">
                  <FormattedMessage id="profile.expiry" />
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    props?.sortData(profileList, {
                      fieldName: "expiryDate",
                      type: "date",
                    })
                  }
                >
                  {sortArrows("expiryDate")}
                </span>
              </div>
              <div
                className={`th custom inactive title-container`}
                colSpan={1}
                style={{
                  display: "inline-block",
                  boxSizing: "border-box",
                  width: 180,
                  overflow: "visible",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FormattedMessage id="profile.actions" />
                  {/* <Image
                    style={{ cursor: "default" }}
                    className={`view-icon ml-1`}
                    src="Info_Icon.svg"
                    height="14px"
                    width="14px"
                    alt=""
                    onMouseEnter={() => setShowInfo(true)}
                    onMouseLeave={() => setShowInfo(false)}
                    onClick={() => setShowInfo((prev) => !prev)}
                  /> */}
                </div>
                {/* {showInfo && (
                  <Image
                    style={{ cursor: "default" }}
                    className={`view-icon ml-1 hover-image`}
                    src="profile_info.png"
                    height="230px"
                    width="150px"
                    alt=""
                    onClick={() => {}}
                  />
                )} */}
              </div>
            </div>
          </div>
          {!gridLoader && profileList?.length !== 0 && (
            <div
              className="row m-0 body"
              // style={{
              //   maxHeight: 400,
              //   overflowY: "scroll",
              //   minHeight: profileList?.length == 1 ? 70 : 0,
              // }}
            >
              {profileList?.map((data, i) => (
                <div
                  className="tr active"
                  key={i}
                  style={{ display: "flex", minHeight: 32 }}
                >
                  <div
                    className="td "
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 85,
                    }}
                  >
                    <span
                      style={{
                        color: "#337AB7",
                        cursor: "pointer",
                        textDecoration: "únderline",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        viewProfile(
                          selectedGrid?.key === "PENDING_C" ||
                            data?.profileId == 0
                            ? data?.profile?.charAt(0)?.toLowerCase() == "p"
                              ? data?.profile.substring(
                                  1,
                                  data?.profile?.length
                                )
                              : data?.profile
                            : data.status?.toLowerCase() == "draft"
                            ? data?.profileId?.charAt(0)?.toLowerCase() == "p"
                              ? data?.profileId.substring(
                                  1,
                                  data?.profileId?.length
                                )
                              : data?.profileId
                            : data?.profile?.charAt(0)?.toLowerCase() == "p"
                            ? data?.profile.substring(1, data?.profile?.length)
                            : data?.profile,
                          Number(data?.version)
                        )
                      }}
                    >
                      {data?.profile}
                    </span>
                  </div>
                  <div
                    className="td custom"
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 70,
                    }}
                  >
                    {data?.version}
                  </div>
                  <div
                    className="td custom"
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 175,
                    }}
                  >
                    {data?.wasteName}
                  </div>
                  <div
                    className="td custom info-tooltip"
                    colSpan={2}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 210,
                      overflow: "visible",
                    }}
                  >
                    <div className="ellipsis">{data?.generatorName}</div>
                    <div
                      className="tooltipI px-1"
                      style={{ wordBreak: "break-word" }}
                    >
                      <div className="">
                        {data?.generatorDetails || data?.generatorName}
                      </div>
                    </div>
                  </div>

                  <div
                    className="td custom"
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 75,
                    }}
                  >
                    {data?.status}
                  </div>
                  <div
                    className="td custom"
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 100,
                    }}
                  >
                    {data?.createdDate || "N/A"}
                  </div>

                  <div
                    className={`td custom ${
                      data?.expiryDate != "N/A" &&
                      new Date().getTime() >
                        new Date(data?.expiryDate).getTime()
                        ? "color-red"
                        : ""
                    }`}
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 90,
                    }}
                  >
                    {data?.expiryDate}
                  </div>
                  <div
                    className="td custom"
                    colSpan={1}
                    style={{
                      display: "inline-block",
                      boxSizing: "border-box",
                      width: 155,
                    }}
                  >
                    <div>
                      {data?.canCopy === 1 && (
                        <Image
                          title="Copy"
                          className="view-icon mr-2"
                          src="Copy.svg"
                          height="16px"
                          width="16px"
                          alt=""
                          onClick={() => {
                            setCopyModal(true)
                            setActiveProfile(data)
                          }}
                        />
                      )}
                      {data?.canPrint === 1 && (
                        <Image
                          title="Print"
                          className="view-icon mr-2"
                          src="Print.svg"
                          height="16px"
                          width="16px"
                          alt=""
                          onClick={() =>
                            printDocument(
                              selectedGrid?.key === "PENDING_C"
                                ? data.profile
                                : data.status?.toLowerCase() == "draft"
                                ? data.profileId
                                : data.profile,
                              data.status,
                              parseInt(data.version)
                            )
                          }
                        />
                      )}
                      {data?.canDownload === 1 && (
                        <Image
                          title="Download"
                          className="view-icon mr-2"
                          src="DownloadIcon.svg"
                          height="16px"
                          width="16px"
                          alt=""
                          onClick={() =>
                            downloadDocument(
                              selectedGrid?.key === "PENDING_C"
                                ? data.profile
                                : data.status?.toLowerCase() == "draft"
                                ? data.profileId
                                : data.profile,
                              data.status,
                              parseInt(data?.version?.trim())
                            )
                          }
                        />
                      )}
                      {data?.canEdit === 1 && (
                        <Image
                          title="Edit"
                          className="view-icon mr-2"
                          src="Edit.svg"
                          height="16px"
                          width="16px"
                          alt=""
                          onClick={() => editProfileUser(data)}
                        />
                      )}
                      {data?.canDelete === 1 && (
                        <Image
                          title="Delete"
                          className="view-icon mr-2"
                          src="Delete.svg"
                          height="16px"
                          width="16px"
                          alt=""
                          onClick={() => {
                            setCurrentData(data)
                            setShowModal(true)
                          }}
                        />
                      )}
                      {data?.canRecertify === 1 && (
                        <Image
                          title="Recertify"
                          className="view-icon mr-2"
                          src="Favourite.svg"
                          height="16px"
                          width="16px"
                          alt=""
                          onClick={() => goToRecertifyProfile(data)}
                        />
                      )}
                      {data?.canPrintLabel === 1 && (
                        <Image
                          title="Print Label"
                          className="view-icon mr-2"
                          src="PrintPDF.svg"
                          height="16px"
                          width="16px"
                          alt=""
                          onClick={() => {
                            handlePrintState(true, data)
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {gridLoader && (
          <div style={{ marginBottom: 200 }}>
            <div className="loader"></div>
          </div>
        )}
        {!gridLoader && profileList?.length == 0 && (
          <div
            className="td inactive"
            style={{
              background: "#ffffff",
              padding: 10,
              textAlign: "center",
              // fontSize: 12,
            }}
          >
            No Data Found
          </div>
        )}
        {/* <Pagination
          {...{
            pageNo: pageNo,
            totalRecords: profileCounts.data && profileCounts.data.length > 0 ? getcountValues(profileCounts.data, "Approved") : 0,
            // totalRecords: getcountValues(profileCounts.data, statuses?.find((status) => status.key === selectedGrid.key)?.value),
            gotoPage,
            data: profileList,
            pageSize: 10,
          }}
        /> */}
      </>
      <div className="container pt-4 mr-4 ml-4">
        <div className="row">
          <div className="col-sm-6">
            <div
              className="d-flex justify-content-between"
              style={{ alignItems: "center" }}
            >
              <div className="mr-4 weeklylabel">
                <FormattedMessage id="dashboard.module" />
              </div>
              <div
                className="downloadsec btn-group overview-action ml-4"
                style={{ height: 30 }}
              >
                <ToggleButton
                  onClickMethod={changeActiveModule}
                  classname={" btn-light weekly"}
                  active={overviewModule.last30Days.active}
                  btnText="Last 30 days"
                  clickValue="last30Days"
                />
                <ToggleButton
                  onClickMethod={changeActiveModule}
                  classname={" btn-light last30days"}
                  active={overviewModule.last60Days.active}
                  btnText="Last 60 days"
                  clickValue="last60Days"
                />
                <ToggleButton
                  onClickMethod={changeActiveModule}
                  classname={" btn-light last90days"}
                  active={overviewModule.last90Days.active}
                  btnText="Last 90 days"
                  clickValue="last90Days"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div>
              <div className="d-flex justify-content-between">
                <div className="mr-4 weeklylabel">
                  <FormattedMessage id="dashboard.wastesummary" />
                </div>
                <div className="downloadsec btn-group overview-action ml-4">
                  {/* <ToggleButton
                    onClickMethod={changeActiveWaste}
                    classname={" btn-light weekly"}
                    active={overviewWaste.last30Days.active}
                    btnText="Last 30 days"
                    clickValue="last30Days"
                  />
                  <ToggleButton
                    onClickMethod={changeActiveWaste}
                    classname={" btn-light last30days"}
                    active={overviewWaste.last60Days.active}
                    btnText="Last 60 days"
                    clickValue="last60Days"
                  />
                  <ToggleButton
                    onClickMethod={changeActiveWaste}
                    classname={" btn-light last90days"}
                    active={overviewWaste.last90Days.active}
                    btnText="Last 90 days"
                    clickValue="last90Days"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-4 d-flex">
          <div className="col-sm-6 mt-2">
            <div
              style={{
                width: "450px",
                borderRadius: "20px",
                background: "#F6F9FB",
              }}
            >
              <BarChart moduleOverviewList={moduleOverviewList} />
            </div>
          </div>
          <div className="col-sm-6">
            <Image
              className=""
              src="comingsoon.svg"
              alt=""
              style={{ width: "450px", height: "224px" }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default enhancer(MnIUserDashBoard)
