import React, { Fragment } from "react"
import enhancer from "./enhancer"
import { useIsTablet } from "shared"
import WeeklyOverview from "./components/WeeklyOverview"
import MyscheduleSec from "./components/ScheduleServices"
import WasteProcess from "./components/WasteProcess"
import DocProfile from "./components/DocProfile"

function Administrator(props) {
  let isTablet = useIsTablet()
  return (
    <Fragment>
      <div className="col-cust-rights">
        <div className="dashboardSection">
          <WeeklyOverview {...props} />
          <div className="row">
            <WasteProcess {...{ isTablet }} />
            <MyscheduleSec {...{ isTablet }} />
            {isTablet && <DocProfile {...{ isTablet }} />}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default enhancer(Administrator)
