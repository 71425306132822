import React, { Fragment } from "react"
import enhancer from "./enhancer"
import { useIsTablet } from "shared"
import QuartersOverview from "./components/QuartersOverview"
import ProfileReport from "./components/ProfileReport"
import WasteDispositionReport from "./components/WasteDispositionReport"
import MyDocuments from "./components/MyDocuments"

function Administrator(props) {
  let isTablet = useIsTablet()
  let { res } = props
  return (
    <Fragment>
      <div className="col-cust-rights">
        <div className="dashboardSection">
          <QuartersOverview {...props} />
          <div className="row">
            <WasteDispositionReport {...{ isTablet, res }} />
            <ProfileReport {...{ isTablet }} />
            {isTablet && <MyDocuments {...{ isTablet }} />}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default enhancer(Administrator)
