const colors = [
  "#0092BB",
  "#009CA6",
  "#50A684",
  "#659E25",
  "#8BA649",
  "#91D5AC",
  "#BA9D0A",
]

export default colors
