import React, { useEffect, useState } from "react"
import { pdfjs } from "react-pdf"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { pdfToObject } from "shared"

let enhancer = (Component) => (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  const location = useLocation()
  const history = useHistory()

  const [file, setFile] = useState("")
  const [fileName, setFileName] = useState("")
  const [numPages, setNumPages] = useState(null)
  const [loading, setLoading] = useState(true)
  let config = { step: 50, min: 50, max: 400 }
  const [zoomScale, setZoomScale] = useState(100)

  useEffect(() => {
    ;(async () => {
      const params = new URLSearchParams(location.search)
      const url = params.get("url")
      let fname = params.get("name")
      if (!url) {
        setLoading(false)
        return toast.error("Sorry something went wrong.")
      }
      const path = fname.split("/")
      fname = path[path.length - 1]
      document.title = fname
      setFileName(fname)
      history.replace("/pdfviewer")

      const blobData = await pdfToObject(url)
      setFile(blobData)
      setLoading(false)
    })()
  }, [])

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const download = () => {
    let anchor = document.createElement("a")
    document.body.appendChild(anchor)
    anchor.href = file
    anchor.download = fileName
    anchor.click()
  }

  const zoomIn = () => {
    setZoomScale(zoomScale + config.step)
  }

  const zoomOut = () => {
    setZoomScale(zoomScale - config.step)
  }

  return (
    <Component
      {...props}
      {...{
        file,
        fileName,
        numPages,
        onDocumentLoadSuccess,
        loading,
        download,
        zoomIn,
        zoomOut,
        zoomScale,
        config,
      }}
    />
  )
}

export default enhancer
