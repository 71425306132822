import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import { isRbsCorporateUser } from "shared"

function ReqHistoryDetail(props) {
  let { data } = props

  return (
    <div className="mt-4 mx-auto request-details">
      <div className="row">
        <div className="col-md-12">
          <p className="request-details__title">REQUEST DETAILS</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="request-details__card">
            <div className="request-details__card__header">
              <p className="mb-0">CONTACT INFORMATION</p>
            </div>
            <div className="request-details__card__body">
              <div className="row">
                <div className="col-md-3">
                  <p>CASE NUMBER :</p>
                </div>
                <div className="col-md-9">
                  <p>{data.caseNumber}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <p>CONTACT NAME :</p>
                </div>
                <div className="col-md-9">
                  <p>{data.createdBy}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <p>CONTACT EMAIL :</p>
                </div>
                <div className="col-md-9">
                  <p>{data.emailfrom}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <p>EMAIL CC :</p>
                </div>
                <div className="col-md-9">
                  <p className="mb-1">{data.additional_Emails__c}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="request-details__card">
            <div className="request-details__card__header">
              <p className="mb-0">REQUEST DETAILS</p>
            </div>
            <div className="request-details__card__body">
              <div className="row">
                <div className="col-md-3">
                  <p>REQUEST TYPE :</p>
                </div>
                <div className="col-md-9">
                  <p>{data.subject}</p>
                </div>
              </div>
              {isRbsCorporateUser() && (
                <div className="row">
                  <div className="col-md-3">
                    <p>BRAND NAME:</p>
                  </div>
                  <div className="col-md-9">
                    <p>{data.division}</p>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-3">
                  <p>STORE :</p>
                </div>
                <div className="col-md-9">
                  <p>{data.storeNumber}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <p>ADDRESS :</p>
                </div>
                <div className="col-md-9">
                  <p>{data.address}</p>
                </div>
              </div>
              {data.serviceTypeFromPortal == "New Schedule Request" && (
                <>
                  <div className="row">
                    <div className="col-md-3">
                      <p>SERVICE TYPE :</p>
                    </div>
                    <div className="col-md-9">
                      <p>{data.serviceType}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <p>INSTRUCTIONS :</p>
                    </div>
                    <div className="col-md-9">
                      <p className="mb-1">{data.description}</p>
                    </div>
                  </div>
                </>
              )}
              {data.serviceTypeFromPortal == "Report a Service Issue" && (
                <>
                  <div className="row">
                    <div className="col-md-3">
                      <p>SERVICE ISSUE :</p>
                    </div>
                    <div className="col-md-9">
                      <p>{data.service_Issues__c}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <p>DETAILS :</p>
                    </div>
                    <div className="col-md-9">
                      <p className="mb-1">{data.description}</p>
                    </div>
                  </div>
                </>
              )}
              {data.serviceTypeFromPortal ==
                "Change Request (Service Details)" && (
                <>
                  <div className="row">
                    <div className="col-md-3">
                      <p>DATE :</p>
                    </div>
                    <div className="col-md-9">
                      <p>{data.createdDate}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <p>DETAILS :</p>
                    </div>
                    <div className="col-md-9">
                      <p className="mb-1">{data.description}</p>
                    </div>
                  </div>
                </>
              )}
              {data.serviceTypeFromPortal == "Request Supplies" && (
                <>
                  <div className="row">
                    <div className="col-3">
                      <p>SUPPLIES :</p>
                    </div>
                    <div className="col-9 row">
                      <div className="suppliesitem row ml-3 mb-3">
                        <div className="col-7 border-rb border-t">Name</div>
                        <div className="col-3 border-b border-t">Qty</div>
                        {data.requestSupplies.map((a, i) => (
                          <Fragment key={i}>
                            <div className="col-7 border-rb">{a.item}</div>
                            <div className="col-3 border-b">{a.qty}</div>
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <p>DETAILS :</p>
                    </div>
                    <div className="col-md-9">
                      <p className="mb-1">{data.description}</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default enhancer(ReqHistoryDetail)
