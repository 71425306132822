import React from "react"
import enhancer from "./enhancer"
import { FormattedMessage } from "react-intl"
import LeftPanel from "./Components/LeftPanel"
import RightPanel from "./Components/RightPanel"
import Loading from "shared/Loading"
import Image from "shared/Image"
import Pagination from "shared/Pagination"

function Services(props) {
  let {
    setScheduleMode,
    serviceData,
    pageNo,
    gotoPage,
    resetMyschedule,
    fetchServicesWithShips,
    workOrderFilters,
    checkFilter,
    setFilterStatus,
    showfilterhint,
  } = props
  let { totalRecords = 0 } = serviceData
  return (
    <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0 ">
      <Loading id="schedules-services" height="400">
        <div className={`row  heading-label `}>
          <div className="col-3 label-name icon-alignment">
            <FormattedMessage id="myschedule.schedules" />{" "}
            <span className="schedule-count">
              {!!totalRecords && totalRecords > 1 ? (
                <FormattedMessage
                  id="myschedule.schedules_counts"
                  values={{ count: totalRecords }}
                />
              ) : (
                <FormattedMessage
                  id="myschedule.schedules_count"
                  values={{ count: totalRecords }}
                />
              )}
            </span>
          </div>
          {(totalRecords > 0 || checkFilter()?.length > 0) && (
            <div
              className={`col-2 label-name curs-ptr icon-alignment p-shipped ${
                workOrderFilters.Shipped && "select-background-filter"
              }`}
              title="Click on an icon to filter"
            >
              {workOrderFilters?.Shipped && (
                <div className="selected-filter">
                  <Image
                    className=""
                    src={"filtercheck.svg"}
                    width="12px"
                    alt=""
                  />
                </div>
              )}
              {(totalRecords > 0 || checkFilter()?.length > 0) && (
                <div
                  onClick={() => {
                    fetchServicesWithShips("Shipped")
                  }}
                >
                  <span className="icon-img">
                    <Image
                      className=""
                      src={"All_ServiceTypes_PickedUp_Icon.svg"}
                      width="30px"
                      alt=""
                    />
                  </span>
                  <span
                    className={`servicetype-icons  ${
                      workOrderFilters?.Shipped && "font-weight"
                    }`}
                  >
                    <FormattedMessage id="myschedule.allservicetypes" />
                  </span>
                </div>
              )}
            </div>
          )}
          {(totalRecords > 0 || checkFilter()?.length > 0) && (
            <div
              className={`col-2 label-name curs-ptr p-shipped icon-alignment ${
                workOrderFilters?.PartialNoShip && "select-background-filter"
              }`}
              title="Click on an icon to filter"
            >
              {workOrderFilters?.PartialNoShip && (
                <div className="selected-filter">
                  <Image
                    className=""
                    src={"filtercheck.svg"}
                    width="12px"
                    alt=""
                  />
                </div>
              )}
              {(totalRecords > 0 || checkFilter()?.length > 0) && (
                <div
                  onClick={() => {
                    fetchServicesWithShips("PartialNoShip")
                  }}
                >
                  {" "}
                  <span className="icon-img">
                    <Image
                      className=""
                      src={"Partial_No_Ship_Icon.svg"}
                      width="30px"
                      alt=""
                    />
                  </span>
                  <span
                    className={`servicetype-icons ${
                      workOrderFilters?.PartialNoShip && "font-weight"
                    }`}
                  >
                    <FormattedMessage id="myschedule.partialnoship" />
                  </span>
                </div>
              )}
            </div>
          )}
          {(totalRecords > 0 || checkFilter()?.length > 0) && (
            <div
              className={`col-2 label-name curs-ptr p-shipped icon-alignment ${
                workOrderFilters.NoShip && "select-background-filter"
              }`}
              title="Click on an icon to filter"
            >
              {workOrderFilters?.NoShip && (
                <div className="selected-filter">
                  <Image
                    className=""
                    src={"filtercheck.svg"}
                    width="12px"
                    alt=""
                  />
                </div>
              )}
              {(totalRecords > 0 || checkFilter()?.length > 0) && (
                <div
                  onClick={() => {
                    fetchServicesWithShips("NoShip")
                  }}
                >
                  <span className="icon-img">
                    <Image
                      className=""
                      src={"Full_No_Ship_Icon.svg"}
                      width="30px"
                      alt=""
                    />
                  </span>
                  <span
                    className={`servicetype-icons ${
                      workOrderFilters?.NoShip && "font-weight"
                    }`}
                  >
                    <FormattedMessage id="myschedule.fullnoship" />
                  </span>
                </div>
              )}
            </div>
          )}
          <div className="col-3 text-right">
            {totalRecords > 0 && !resetMyschedule && (
              <span
                className=" switch-service"
                onClick={() => {
                  if (checkFilter().length > 0) {
                    setFilterStatus(checkFilter()[0][0])
                  }
                  setScheduleMode(true)
                }}
              >
                <FormattedMessage id="myschedule.swichto" />
              </span>
            )}
          </div>
        </div>
        {totalRecords > 0 && showfilterhint && (
          <div>
            <div className="col-3 blue_color_text">
              <center>
                <Image className="" src={"myorders_filters_uparrow.svg"} />
                <FormattedMessage id="myschedule.iconsfilter" />
              </center>
            </div>
          </div>
        )}
        <div className="row m-0 service-section">
          {totalRecords == 0 && (
            <div className="col-sm-12 col-md-12 col-lg-12 text-center pt-2 nodatamsg">
              <FormattedMessage id="nodata.schedules" />
            </div>
          )}
          {totalRecords > 0 && (
            <>
              <LeftPanel {...props} />
              <RightPanel {...props} />
              <Pagination
                {...{ pageNo, totalRecords, gotoPage, data: serviceData }}
              />
            </>
          )}
        </div>
      </Loading>
    </div>
  )
}
export default enhancer(Services)
