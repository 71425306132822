import React, { useState } from "react"
import { useHarsco } from "shared"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    let [scheduleMode, setScheduleMode] = useState(true)
    let [resetMyschedule, setResetMyschedule] = useState(false)
    let [filterStatus, setFilterStatus] = useState("")

    let [filters, setFilters] = useState({})

    const { isPreviewCustomer, isSPAKCustomer } = useHarsco()

    let updateFilters = async (fData) => {
      setFilterStatus("")
      setResetMyschedule(false)
      setFilters(fData)
      setScheduleMode(false)
    }
    const reset = () => {
      setResetMyschedule(true)
    }

    return (
      <Component
        {...props}
        {...{
          scheduleMode,
          setScheduleMode,
          updateFilters,
          filters,
          reset,
          resetMyschedule,
          isPreviewCustomer,
          isSPAKCustomer,
          setFilterStatus,
          filterStatus,
        }}
      />
    )
  }

export default enhancer
