import React from "react"
import Image from "shared/HelpImage/index"

function OrderOverview() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Order Overview</h3>
      <p className="text-justify pl-3 pt-2">
        The My Order overview screen shows on the left a list of all scheduled
        orders and service status for the stores you selected. Near the top of
        the screen you will see the total number of orders in the list. Near the
        bottom you can choose to page forward and backward through the list.
      </p>
      <div className="img-section">
        <Image className="" src="MyOrderDetails.png" alt="My Orders Image" />
      </div>
      <p className="text-justify pl-3 pt-2">
        On the left is a list of all orders and, for each, the date scheduled,
        location, total weight and weight of hazardous material for the order.
      </p>
      <p className="text-justify pl-3 pt-2">
        Additionally, you will see a GREEN icon of a truck if all Service Types
        specified in the order were picked up, a GOLD icon if the order was a
        PARTIAL NO SHIP, and a RED icon if the order was a TOTAL NO SHIP.
      </p>
      <p className="text-justify pl-3 pt-2">
        The list is sorted in the order of Service Date, with the oldest Service
        Date being displayed first. You can also choose to sort with the newest
        Service Date displayed first, or sort the list by Store Number if you
        prefer. You can scroll up or down to view the entire list. Clicking on
        any of the orders on the left will display details for that order on the
        right.
      </p>
      <p className="text-justify pl-3 pt-2">
        Clicking on any of the three colored truck icons will filter the list of
        orders, displaying only the orders you selected.
      </p>
      <p className="text-justify pl-3 pt-2">
        Clicking on the GREEN truck icon will display a list of only orders who
        had all specified Service Types picked up. The GREEN icon will be
        highlighted and checked, indicating it was selected.
      </p>
      <div className="img-section">
        <Image className="" src="MyOrderAllFilters.png" alt="My Orders Image" />
      </div>
      <p className="text-justify pl-3 pt-2">
        Clicking again on the GREEN icon will deselect the icon and return the
        list again to displaying all orders.
      </p>
      <div className="img-section">
        <Image
          className=""
          src="MyOrdersSelectGrid.png"
          alt="My Orders Image"
        />
      </div>
      <p className="text-justify pl-3 pt-2">
        Clicking on the YELLOW icon will work the same way, displaying a list of
        just the PARTIAL NO SHIP orders. And, selecting the RED icon will
        display a list of just the FULL NO SHIP orders.
      </p>
      <p className="text-justify pl-3 pt-2">
        Clicking on “SWITCH TO SERVICES OVERVIEW” will take you to the My
        Orders, Services screen.
      </p>
      <div className="img-section pt-2">
        <Image className="" src="Switches.png" alt="My Orders Image" />
      </div>
      <p className="text-justify pl-3 pt-2">
        Clicking on “SWITCH TO SCHEDULES OVERVIEW” will return you to the My
        Orders preview screen
      </p>
    </div>
  )
}

export default OrderOverview
