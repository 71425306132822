import React from "react"
import searchs from "assets/icon/ic_search.svg"
import classnames from "classnames"
import _ from "lodash"
import "./style.scss"

let Input = (props) => {
  let {
    type,
    placeholder,
    update,
    required,
    value,
    d,
    i,
    hasSubmitted,
    //to for future reference
    // charRestricted,
    minCharRequired,
  } = props

  let hasError = () => {
    if (required && hasSubmitted) {
      if (
        type == "text" &&
        value == ""
        // || value.length < 4
      )
        return true
    }
    return false
  }

  let isEmpty = () => {
    if (type == "text") return _.isEmpty(value)
    return false
  }

  return (
    <div
      className={classnames(type + "search-box input-outer", {
        "has-error": hasError(),
        "position-relative": required,
        "is-not-empty": !isEmpty(),
      })}
    >
      <div className="input-inner">
        {value?.length === 0 && <span className="required-star-red"> * </span>}
        <input
          type={type}
          placeholder={placeholder}
          className="input-field"
          onChange={update(d, i)}
          required={required}
          value={value}
          // maxLength={charRestricted}
          minLength={minCharRequired}
        />
      </div>

      <img
        className={"serchicon mar-right"}
        src={searchs}
        height="9px"
        width="8px"
        style={{ position: "absolute" }}
        alt=""
      />
      <span className="filter-error" style={{ paddingTop: "2%" }}>
        This field is required
      </span>
    </div>
  )
}

export default Input
