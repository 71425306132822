import React from "react"
import Image from "shared/HelpImage/index"

function Alerts() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">My Alerts</h3>
      <p className="text-justify pl-3 pt-2">
        My Alerts gives the user an option to receive alerts or not. Click the
        My Alerts tab and then Access Setting button to see the options.
      </p>
      <div className="img-section">
        <Image className="" src="alerts.png" alt="Alerts Image" />
      </div>
      <p className="text-justify pl-3 pt-2">
        <p>
          The user can check the box they want to be alerted for or click on the
          “send me all updates” to receive alerts for all alert options listed.
        </p>
        <p>When done click on the Save Preferences button.</p>
      </p>
      <div className="img-section">
        <Image className="" src="Alerts_Lists.png" alt="Alerts Image" />
      </div>

      <p className="text-justify pl-3 pt-2">
        “SQG to LQG.” This is an alert that will trigger if a Small quantity
        generator has shipped too much RCRA hazardous waste causing a change in
        status to Large quantity generator. Use this alert to track any
        shipments that may prompt a change in generator status.
      </p>
      <p className="text-justify pl-3 pt-2 mb-2">
        “VSQG to SQG” This is an alert that will trigger if a Very small
        quantity generator has shipped to much RCRA hazardous waste causing a
        change in status to Small quantity generator.
      </p>
      <div className="img-section">
        <Image className="" src="Alerts_Lists_Noships.png" alt="Alerts Image" />
      </div>
      <p className="text-justify pl-3 pt-2 mb-2">
        “No Ship Takes Place.” This is when a stop is scheduled, the driver gets
        there, but the generator has no waste to pick up. Use this alert to
        track any services where no waste was generated.
      </p>
      <p className="text-justify pl-3 pt-2 mb-2">
        “Partial No Ship Takes Place” This is when a stop is scheduled, the
        driver gets there, but the generator does not have all the waste to be
        shipped. Use this alert to track any service where partial waste was
        generated.
      </p>
      <div className="img-section pt-3">
        <Image className="" src="alerts3.png" alt="Alerts Image" />
      </div>
      <p className="text-justify pl-3 pt-2">
        Once the alerts are saved, the user will be “Alerted” by the Bell icon
        that is on the header bar through out the site.
        <p>The number of Alerts will display on the bell.</p>
        <p>Alert preferences can be changed at any time.</p>
      </p>
      <div className="img-section">
        <Image className="" src="alerts4.png" alt="Alerts Image" />
      </div>
      <p className="text-justify pl-3 pt-2">
        <p>
          If you click on the bell to see the alert(s), there an option to View
          All. The user can view all the alerts defaulted by the most recent
          first.{" "}
        </p>
        <p>The user can sort by different predefined options.</p>
        <p>
          All alerts that have not been read yet will be bold, and ones that
          have been read, will be lighter in color.{" "}
        </p>
      </p>
      <div className="img-section">
        <Image className="" src="alerts5.png" alt="Alerts Image" />
      </div>
    </div>
  )
}
export default Alerts
