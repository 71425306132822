import React from "react"
import Image from "shared/HelpImage/index"
import { useSelector } from "react-redux"

function MyAccount() {
  const { changeCustomer } = useSelector((state) => state)
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">My Account</h3>
      <p className="text-justify pl-3">
        Once logged in, the dashboard is in view.
      </p>
      <div className="img-section">
        <Image
          src={
            changeCustomer?.customer?.businessSegmentId === 3
              ? "mi_myaccount1.png"
              : "2.1.2._MyAccount.png"
          }
          alt=""
        />
      </div>
      <p className="text-justify pl-3 pt-3">
        One thing to note is your name or company name appears in the top left
        hand side of your screen. You can change this by clicking on the Pencil
        icon under your initials.
      </p>
      <div className="img-section">
        <Image src="editProfile.png" alt="" />
      </div>
      <p className="text-justify pl-3 pt-4">
        Then click save changes and go back to the dashboard
      </p>
      <div className="img-section">
        <Image src="myAccount.png" alt="" />
      </div>
    </div>
  )
}
export default MyAccount
