import React from "react";
import { compose } from "redux";

let enhancer = compose(
    (Component) => ({ history, ...props }) => {
        return (
            <Component
              {...props}
              />
        );
    }
    );
    
    export default enhancer;
    