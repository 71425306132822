import { ADMIN, roles } from "config"
import { STORE_MANAGER } from "config"

const sharedMenu = [
  {
    path: "/support",
    label: "sidebar.support",
    icon: "ic_support.svg",
  },

  {
    path: "/logout",
    label: "sidebar.logout",
    icon: "ic_log_out.svg",
  },
]

const documentLibraryMenu = {
  path: "/documentlibrary",
  label: "sidebar.documentlibrary",
  icon: "ic_document_library.svg",
  role: [ADMIN],
}

const historicalldrs = {
  subMenu: [
    {
      path: "/documentlibrary/ldr",
      label: "sidebar.documentlibrary.historicalldrs",
    },
  ],
}

const documentLibraryStoresMenu = {
  path: "/documentlibrarystores",
  label: "sidebar.documentlibrary",
  icon: "ic_document_library.svg",
  role: [STORE_MANAGER],
}

const sideMenuList = [
  {
    path: "/",
    label: "sidebar.dashboard",
    icon: "ic_dashboard.svg",
    role: [ADMIN],
  },
  // {
  //   path: "/MnIdashboard",
  //   label: "sidebar.dashboard",
  //   icon: "ic_dashboard.svg",
  //   role: [ADMIN],
  // },
  {
    path: "/",
    label: "sidebar.home",
    icon: "ic_dashboard.svg",
    role: [STORE_MANAGER],
  },
  {
    path: "/reportgenerator",
    label: "sidebar.reportservice",
    icon: "ic_report_generator.svg",
    role: [ADMIN],
  },
  {
    path: "/reportgenerator",
    label: "sidebar.reportservice",
    icon: "ic_report_generator.svg",
    role: [STORE_MANAGER],
  },
  {
    path: "/myschedule",
    label: "sidebar.myschedule",
    icon: "ic_schedules.svg",
    role: [ADMIN],
  },
  {
    path: "/mystoreschedule",
    label: "sidebar.myschedule",
    icon: "ic_schedules.svg",
    role: [STORE_MANAGER],
  },
  {
    path: "/myprofile",
    label: "sidebar.myprofile",
    icon: "ic_profile_center.svg",
    role: [ADMIN],
  },
  // {
  //   path: "/profiles",
  //   label: "sidebar.profile",
  //   icon: "icon_MUI_sidepane_Profile.svg",
  //   role: [ADMIN],
  // },
  // {
  //   path: "/generator",
  //   label: "sidebar.generator",
  //   icon: "icon_MUI_sidepane_Generator.svg",
  //   role: [ADMIN],
  // },
  // {
  //   path: "/reports",
  //   label: "sidebar.reports",
  //   icon: "ic_report_generator.svg",
  //   role: [ADMIN],
  // },
  // {
  //   path: "/myalerts",
  //   label: "sidebar.alerts",
  //   icon: "ic_notification_settings.svg",
  //   role: [ADMIN],
  //   subMenu: [
  //     {
  //       path: "/myalerts",
  //       label: "sidebar.myalerts",
  //     },
  //     {
  //       path: "/alertsettings",
  //       label: "sidebar.alertsettings",
  //     },
  //   ],
  // },
  { ...documentLibraryMenu },
  {
    ...documentLibraryMenu,
    homeDepot: true,
    ...historicalldrs,
  },
  { ...documentLibraryStoresMenu },
  {
    ...documentLibraryStoresMenu,
    homeDepot: true,
    ...historicalldrs,
  },
  {
    path: "/requestserviceView",
    label: "sidebar.servicecenter",
    icon: "ic_truck.svg",
    role: [ADMIN],
    subMenu: [
      {
        path: "/requestserviceHistory",
        label: "sidebar.requestservice.requestHistory",
      },
    ],
  },
  {
    path: "/requestservice",
    label: "sidebar.servicecenter",
    icon: "ic_truck.svg",
    role: [ADMIN],
    subMenu: [
      {
        path: "/requestservice",
        label: "sidebar.requestservice.newRequest",
      },
      {
        path: "/requestserviceHistory",
        label: "sidebar.requestservice.requestHistory",
      },
    ],
  },
  {
    path: "/alerts",
    label: "sidebar.myalerts",
    icon: "ic_notification_settings.svg",
    role: [ADMIN],
  },
  {
    path: "/admin",
    label: "sidebar.admin",
    icon: "CA_Menu Symbols.svg",
    admin: true,
    role: [ADMIN],
  },
  ...sharedMenu,
]

const mandIMenuList = [
  {
    path: "/",
    label: "sidebar.dashboard",
    icon: "ic_dashboard.svg",
    role: [
      roles.CE_GENERIC_USER,
      roles.PORTAL_ADMIN,
      roles.CE_COMMERCIAL_USER,
      roles.CORPORATE_USER,
    ],
  },
  // {
  //   path: "/",
  //   label: "sidebar.dashboard",
  //   icon: "ic_dashboard.svg",
  //   role: [ADMIN],
  // },
  // {
  //   path: "/",
  //   label: "sidebar.home",
  //   icon: "ic_dashboard.svg",
  //   // role: [STORE_MANAGER],
  // },
  {
    path: "/generator",
    label: "sidebar.generator",
    icon: "icon_MUI_sidepane_Generator.svg",
    role: [
      roles.CE_GENERIC_USER,
      roles.PORTAL_ADMIN,
      roles.CE_COMMERCIAL_USER,
      roles.CORPORATE_USER,
    ],
  },
  {
    path: "/profiles",
    label: "sidebar.profile",
    icon: "icon_MUI_sidepane_Profile.svg",
    role: [
      roles.CE_GENERIC_USER,
      roles.PORTAL_ADMIN,
      roles.CE_COMMERCIAL_USER,
      roles.CORPORATE_USER,
    ],
  },
  {
    path: "/reports",
    label: "sidebar.reports",
    icon: "ic_report_generator.svg",
    role: [
      roles.CE_GENERIC_USER,
      roles.PORTAL_ADMIN,
      roles.CE_COMMERCIAL_USER,
      roles.CORPORATE_USER,
    ],
  },
  {
    path: "/myalerts",
    label: "sidebar.alerts",
    icon: "ic_notification_settings.svg",
    role: [roles.PORTAL_ADMIN, roles.CE_COMMERCIAL_USER, roles.CORPORATE_USER],
  },
  // {
  //   path: "/myalerts",
  //   label: "sidebar.alerts",
  //   icon: "ic_notification_settings.svg",
  //   role: [roles.PORTAL_ADMIN, roles.CE_COMMERCIAL_USER, roles.CORPORATE_USER],
  //   // subMenu: [
  //   //   {
  //   //     path: "/myalerts",
  //   //     label: "sidebar.myalerts",
  //   //   },
  //   //   {
  //   //     path: "/alertsettings",
  //   //     label: "sidebar.alertsettings",
  //   //   },
  //   // ],
  // },
  {
    path: "/admin",
    label: "sidebar.admin",
    icon: "CA_Menu Symbols.svg",
    admin: true,
    role: [ADMIN, roles.PORTAL_ADMIN],
  },
  {
    path: "/logout",
    label: "sidebar.logout",
    icon: "ic_log_out.svg",
  },
]

const fullCirclMenuList = [
  {
    path: "/",
    label: "sidebar.dashboard",
    icon: "ic_dashboard.svg",
  },
  {
    path: "/reportgenerator",
    label: "sidebar.reportservice",
    icon: "ic_report_generator.svg",
  },
  {
    path: "/myhistory",
    label: "sidebar.myhistory",
    icon: "ic_schedules.svg",
  },
  {
    path: "/invoices",
    label: "sidebar.invoices",
    icon: "ic_profile_center.svg",
  },
  //commented for the future reference
  // {
  //   path: "/documentlibrary",
  //   label: "sidebar.documentlibrary",
  //   icon: "ic_document_library.svg",
  // },
  {
    path: "/alerts",
    label: "sidebar.myalerts",
    icon: "ic_notification_settings.svg",
  },
  {
    path: "/capDB",
    label: "sidebar.capDB",
    icon: "ic_capDB.svg",
  },
  {
    path: "/admin",
    label: "sidebar.admin",
    icon: "CA_Menu Symbols.svg",
    admin: true,
    role: [ADMIN],
  },

  ...sharedMenu,
]

export { sideMenuList, fullCirclMenuList, mandIMenuList }
