import React from "react"
import Image from "shared/Image"
import { FormattedMessage } from "react-intl"

const ComingSoon = () => (
  <span className="tooltiptext">
    <Image className="clockimg" src="clock.svg" height="" width="" alt="" />
    <span className="comingsoon">
      <FormattedMessage id="popup.comingsoon" />
    </span>
    <span className="subtext">
      Stay connected, the feature will be live soon.
    </span>
  </span>
)

export default ComingSoon
