import React, { useState, useEffect } from "react"
import searchs from "assets/icon/ic_search.svg"
import classnames from "classnames"
import { FixedSizeList as List } from "react-window"
import "./style.scss"
import EllipsisText from "react-ellipsis-text"
import { toast } from "react-toastify"

let Select = (props) => {
  // isrestricted is to restrict the option to certain limit
  // limitoptions is the limit
  let {
    options,
    labelKey,
    onSelect,
    label,
    open,
    setOpen,
    clear,
    size,
    isrestricted = false,
    limitoptions = 0,
    isToggleButton = false,
    showAll = true,
    message = "",
  } = props
  let [search, setSearch] = useState("")
  const isDoubleSize = size == "double" ? true : false

  let selectedCount = options?.filter((d) => d.selected).length

  let updateSelect = (index) => (e) => {
    let opts = [...options]
    let opt = opts.find((d) => d.index == index)
    updateSelectOptions(opts, opt)
  }

  let updateSelectOptions = (opts, opt) => {
    if (isrestricted && selectedCount < limitoptions) {
      //if restriction are applied and limit threshold is not met, it will update select & unselect options
      opt.selected = !opt.selected
      onSelect(opts)
    } else if (isrestricted && selectedCount === limitoptions && opt.selected) {
      //if restriction are applied and limit threshold is met, it will only update un select option
      opt.selected = !opt.selected
      onSelect(opts)
    } else if (!isrestricted) {
      //if restriction are not applied, it will update selected  & unselected options
      opt.selected = !opt.selected
      onSelect(opts)
    } else if (isrestricted && selectedCount === limitoptions) {
      //if restriction are applied and limit threshold is met, it will restrict and throws an error
      toast.warn(`You can choose up to ${limitoptions} ${label} per query`, {
        autoClose: 3000,
      })
    } else if (isrestricted && showAll) {
      toast.warn(
        `You can choose up to ${limitoptions} ${label} or all ${label} per query`,
        {
          autoClose: 3000,
        }
      )
    }
  }

  let updateAll = (e) => {
    let opts = [...options]
    opts.forEach((d) => (d.selected = e.currentTarget.checked))
    onSelect(opts)
  }

  let opts = options

  let isFiltered = (d) => {
    if (search != "")
      return !d.toLowerCase().includes(search.toLowerCase().trim())
    return false
  }

  useEffect(() => {
    setSearch("")
    document.querySelectorAll(".list-window").forEach((d) => (d.scrollTop = 0))
  }, [open])

  let allState = "none"
  let selected = options.filter((d) => d.selected)
  // if (selected.length > 0) allState = "some"
  if (selected.length == options.length) allState = "all"

  let controls = (
    <div className={isrestricted ? "multi query-msg" : "multi"}>
      <hr className="line" />
      {isrestricted && <span className="message-query">{message}</span>}
      <div
        className={classnames("search-box inputfield", {
          "mb-45": search != "",
        })}
      >
        <input
          type="text"
          placeholder="Search"
          className="searchField"
          onChange={(e) => setSearch(e.currentTarget.value)}
          value={search}
        />
        <img
          className={classnames("serchicon", {
            "stores-serchicon": isrestricted && search?.length === 0,
            togglesearchicon:
              isToggleButton && !isrestricted && search?.length > 0,
            "toggle-search-icon":
              isToggleButton && !isrestricted && search?.length === 0,
          })}
          src={searchs}
          height="9px"
          width="8px"
          alt=""
        />
      </div>
      {search == "" && (
        <div className={"search-box all mt-2 "}>
          {showAll && (
            <label className="drop-container">
              All
              <input
                type="checkbox"
                name={"all" + label}
                className={allState}
                onClick={updateAll}
                checked={allState == "all"}
                onChange={(e) => {}}
              />
              <span className="checkmark"></span>
            </label>
          )}
          {clear && options?.length > 0 && (
            <span onClick={clear} className="clearall">
              {" "}
              Clear All{" "}
            </span>
          )}
        </div>
      )}
    </div>
  )
  let optsF = opts.filter((d) => !isFiltered(d[labelKey]))

  const Row = ({ index, style }) => (
    <div
      className={classnames("mt-1 list-element", {
        stringmore: optsF[index][labelKey].length > 20,
        lineHeight: isDoubleSize,
      })}
      key={optsF[index][labelKey]}
      style={style}
    >
      <label
        htmlFor={optsF[index][labelKey]}
        onClick={updateSelect(optsF[index].index)}
        className="drop-container"
      >
        <EllipsisText
          text={optsF[index][labelKey]}
          length={isDoubleSize ? 38 : 20}
        />
        <input
          type="checkbox"
          key={optsF[index][labelKey]}
          name={optsF[index][labelKey]}
          checked={optsF[index].selected}
          onChange={(e) => {}}
        />
        <span
          className={`checkmark ${isDoubleSize && "check-alignment"}`}
        ></span>
      </label>
    </div>
  )

  let heights = optsF.length > 6 ? 150 : optsF.length * 20

  return (
    <div
      className={classnames("custom-dropdown multi", {
        active: open,
        "multi-double": isDoubleSize,
      })}
    >
      <div
        className={`drop-select ${selectedCount ? "single" : ""}`}
        role="button"
        onClick={() => setOpen(!open)}
      >
        {label}
        {!!selectedCount && ` (${selectedCount})`}
        <span
          className={classnames("drop-arrows", { down: !open, up: open })}
        ></span>
      </div>
      <div
        className={classnames("controls", {
          "d-none": !open,
        })}
      >
        {controls}
        <List
          height={heights}
          itemCount={optsF.length}
          itemSize={isDoubleSize ? 28 : 20}
          width={95}
          className={`list-window`}
        >
          {Row}
        </List>
      </div>
    </div>
  )
}

export default Select
