import React, { useState, useEffect, useRef } from "react"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
} from "recharts"
import { useIntl } from "react-intl"
import { useWindowDimension } from "shared"
import colors from "./colors"
import _ from "lodash"

const Chart = ({ data, config, height }) => {
  const [width, setWidth] = useState(0)
  const elementRef = useRef(null)

  let dim = useWindowDimension()

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width - 20)
  }, dim)

  let { yUnit = "" } = config

  const intl = useIntl()

  let formatNumber = (n) => {
    let extra = {}
    let str = ""
    if (yUnit == "$")
      extra = {
        style: "currency",
        currency: "USD",
      }
    else str = yUnit

    return `${intl.formatNumber(n, {
      ...extra,
      //notation: "compact",
      maximumFractionDigits: 1,
    })}${str}`
  }

  return (
    <div ref={elementRef} className="bar-chart-wrapper">
      <BarChart
        width={width}
        height={height}
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        barGap={0}
      >
        <XAxis
          dataKey={config.x}
          tickFormatter={(d) => _.truncate(d, { length: 11 })}
        />
        <YAxis tickFormatter={formatNumber} domain={config.yDomain} />
        <Tooltip
          formatter={formatNumber}
          labelFormatter={(name) => {
            let tooltip = data.find((d) => d.tooltip?.includes(name))?.tooltip
            return tooltip ? tooltip : name
          }}
        />

        <CartesianGrid />
        <Bar dataKey={config.y.key} name={config.y.label} maxBarSize={30}>
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colors[index]}
              radius={[0, 0, 0, 0]}
            />
          ))}
        </Bar>
      </BarChart>
    </div>
  )
}

export default Chart
