import React from "react"

const importAll = (require) =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next)
    return acc
  }, {})

const images = importAll(
  require.context("assets/help", false, /\.(PNG|png|jpe?g|svg|jfif)$/)
)

const Image = ({ src, ...props }) => <img src={images[src]} {...props} />

export default Image
