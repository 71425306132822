export const MandIReportNames = [
  { l: "profile_listing_report", v: "PROFILE LISTING REPORT" },
]

export const ProfileListReportsHeaderMapping = {
  customerName: "customerName",
  generatorName: "generatorName",
  shippingAddressLine1: "shippingAddressLine1",
  address2: "address2",
  profileId: "profile",
  profileName: "profileWasteName",
  profileStatus: "profileStatus",
  paWasteCatogory: "wasteCategory",
  expirationDate: "profileExpiration",
  shippingCity: "city",
  shippingState: "state",
  shippingZip: "zip",
  recordCreateDatetime: "profileStartDate",
  epaId: "epaId",
  dotid: "dotId",
  properShippingName: "shippingName",
  nosDescription: "nosDescription",
  hazardClass: "hazClass",
  pg: "packingGroup",
  epa: "epaCodes",
  txInStateCode: "stateCodes",
  designatedFacility: "designatedFacility",
}

export const ProfileListReportsHeaders = [
  "profile",
  "customerName",
  "generatorName",
  "shippingAddressLine1",
  "address2",
  "city",
  "state",
  "zip",
  "epaId",
  "profileStatus",
  "profileStartDate",
  "profileExpiration",
  "profileWasteName",
  "dotId",
  "shippingName",
  "nosDescription",
  "hazClass",
  "packingGroup",
  "epaCodes",
  "stateCodes",
  "wasteCategory",
  "designatedFacility",
]

export const ProfileListReportsHeaders1 = [
  "profile",
  "customerName",
  "generatorName",
  "shippingAddressLine1",
  "address2",
  "city",
  "state",
  "zip",
  "epaId",
  "profileStatus",
  "profileStartDate",
  "profileExpiration",
  "profileWasteName",
  "dotId",
  "shippingName",
  "nosDescription",
  "hazClass",
  "packingGroup",
  "epaCodes",
  "stateCodes",
  "wasteCategory",
  "designatedFacility",
]
