import React, { Fragment } from "react"
import enhancer from "./enhancer"
import { FormattedMessage } from "react-intl"
import Loading from "shared/Loading"

function MyDocuments(props) {
  let { isTablet, navigate } = props

  //const navigate = () => { }

  return (
    <Fragment>
      <div
        className={` ${
          isTablet
            ? "col-sm-12 col-md-12 col-lg-12 pt-3 text-left "
            : "profileDocument pt-3"
        }`}
      >
        <div>
          <span className="weeklylabel">
            <FormattedMessage id="dashboard.mydocumet" />
          </span>
        </div>
        <div className="row documentTab mydocuments-block">
          <Loading id="documents-tab" height="200">
            {
              <div className="col-sm-6 col-md-6 col-lg-6 text-left" key={0}>
                <div
                  className="profileRow colorChange"
                  onClick={() => navigate("all")}
                >
                  <span className="paddingLeft10Percent"></span>
                  <label className="days c-p">{"Shipping Document"}</label>
                  <div className="arrow"></div>
                </div>
              </div>
            }
            {
              //(myDocumentsCount == null
              //|| myDocumentsCount == 0
              //) &&
              // (
              //   <div className="col-sm-12 col-md-12 col-lg-12 text-center pt-2 nodata fs13">
              //     <FormattedMessage id="nodata.documents" />
              //   </div>
              // )
            }
          </Loading>
        </div>
      </div>
    </Fragment>
  )
}
export default enhancer(MyDocuments)
