import React from "react"
import Image from "shared/HelpImage/index"
import { useHarsco } from "shared"

function FirstTimePasswordReset() {
  const { isFullCircle } = useHarsco()
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">
        First Time Login – Password Reset Walkthrough
      </h3>
      <p className="text-justify pl-3 pt-3">
        Please click on the internet browser of your choice on your computer.
        Once you open the webpage, please browse to the website:
        <br />
        <a
          href="https://connectplus.cleanearthinc.com/"
          target="_blank"
          rel="noreferrer"
        >
          <u>Connect + (cleanearthinc.com)</u>
        </a>{" "}
      </p>
      <div className="img-section">
        <Image src="first_time_password_reset.png" alt="Login Image" />
      </div>
      <p className="text-justify pl-3 pt-3">Please Click on ‘Login’ Button.</p>
      <div className="img-section">
        <Image src="forgot_password_login_in.png" alt="Login Image" />
      </div>
      <p className="text-justify pl-3 pt-3">
        Please enter your email ID in the ‘Email Address’ field and temporary
        password in the ‘Password’ field and click on ‘Sign in’.
      </p>
      <div className="img-section">
        <Image src="first_time_password_reset_2.png" alt="Login Image" />
      </div>
      <br />
      <div className="img-section">
        <Image src="first_time_password_reset_3.png" alt="Login Image" />
      </div>
      <p className="text-justify pl-3 pt-3">
        After clicking on ‘Sign in’ the page will be redirected to the password
        reset page.
      </p>
      <div className="img-section">
        <Image src="first_time_password_reset_4.png" alt="Login Image" />
      </div>
      <p className="text-justify pl-3 pt-3">
        Type your temporary password in the ‘password’ field and create a new
        password (
        <u>
          <i>
            Password must be between 8 and 64 characters and must have at least
            3 of a lowercase letter, an uppercase letter, digit, a symbol
          </i>
        </u>
        ) and click on continue. You will now be logged into the portal and the
        new password will take effect from subsequent logins.
      </p>
      <div className="img-section">
        <Image src="first_time_password_reset_5.png" alt="Login Image" />
      </div>
      <p className="text-justify pl-3 pt-3">
        If the password was updated successfully, you will then be taken to the
        Terms and Conditions page of Connect+. Please review these Terms and
        Conditions thoroughly
      </p>
      <div className="img-section">
        <Image src="first_time_password_reset_6.png" alt="Login Image" />
      </div>
      <p className="text-justify pl-3 pt-3">
        If you agree with all items in the statement, please scroll to the
        bottom and click on ‘ACCEPT’.
      </p>
      {!isFullCircle && (
        <p className="text-justify pl-3 pt-3">
          The final screen that you will see should be the ‘WELCOME TO CONNECT+’
          screen as shown below.
        </p>
      )}
      {!isFullCircle && (
        <div className="img-section">
          <Image src="first_time_password_reset_7.png" alt="Login Image" />
        </div>
      )}
    </div>
  )
}
export default FirstTimePasswordReset
