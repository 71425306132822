import React from "react"
import Image from "shared/HelpImage/index"

function ReportHistory() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Request History </h3>
      <p className="text-justify pl-3 pt-2">
        <p>From the Request History Page</p>
        <p>The user can:</p>
        <p>- Track requests raised by all users within the company.</p>
        <p>- Search for specific requests by type, status and created date.</p>
      </p>
      <div className="img-section">
        <Image src="requesthistory.png" alt="Report Service Issue Image" />
      </div>
    </div>
  )
}
export default ReportHistory
