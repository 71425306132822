import React from "react"
import Image from "shared/HelpImage/index"

function CAPDB() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">CAPDB</h3>

      <Image src="CAPDB_Fc.png" />

      <p className="text-justify pl-3 pt-2">
        The user can access the CAPDB by clicking on the menu.
      </p>
      <p className="text-justify pl-3 pt-2">
        The user will be directed to the CAPDB home page to login with their
        credentials.
      </p>
    </div>
  )
}
export default CAPDB
