import React from "react"
import { useHistory, useLocation } from "react-router-dom"

let enhancer = (Component) => (props) => {
  let history = useHistory()
  let location = useLocation()

  let myAccountService = location.state

  const onReturnToMyAccountScreenClick = (e) => {
    history.push("/profile")
  }

  return (
    <Component
      {...props}
      {...{
        onReturnToMyAccountScreenClick,
        myAccountService,
      }}
    />
  )
}

export default enhancer
