import React, { useState } from "react"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    let [scheduleMode, setScheduleMode] = useState(true)
    let [resetMyschedule, setResetMyschedule] = useState(false)

    let [filters, setFilters] = useState({})

    let updateFilters = async (fData) => {
      setResetMyschedule(false)
      setFilters(fData)
      setScheduleMode(false)
    }
    const reset = () => {
      setResetMyschedule(true)
    }

    return (
      <Component
        {...props}
        {...{
          scheduleMode,
          setScheduleMode,
          updateFilters,
          filters,
          reset,
          resetMyschedule,
        }}
      />
    )
  }

export default enhancer
