import React from "react"
import Image from "shared/HelpImage/index"

function ReportServiceIssue() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">
        New Request: Report a Service Issue
      </h3>
      <p className="text-justify pl-3 pt-2">
        <p>
          Report a Service Issue using this form. The contact information of the
          user is pre-populated.
        </p>
        <p>
          There is an option to cc other email addresses to receive confirmation
          of your request once it is submitted.
        </p>
        <p>
          Under Request details, select the Store and Service Issue from the
          drop-down menus as required.
        </p>
        <p>
          Fill out specific details in the space provided and hit submit request
          button.
        </p>
        <p>
          You will receive notification on your screen that your request was
          submitted successfully along with a confirmation number.
        </p>
      </p>
      <div className="img-section">
        <Image
          src="Request_Report_a_Service_Issue.png"
          alt="Report Service Issue Image"
        />
      </div>

      <p className="text-justify pl-3 pt-4">
        <p>
          Once the request is submitted, the user will get a “SUCCESS”
          confirmation.
        </p>
        <p>
          The users case number, for reference with the team member from Clean
          Earth that will be in contact, is in bold.
        </p>
        <p>The confirmation also shows the details of the report.</p>
        <p>
          This will be sent to Clean Earth Customer Service and they will be in
          contact right away.
        </p>
      </p>
      <div className="img-section">
        <Image src="reportServiceIssue2.png" alt="Report Service Issue Image" />
      </div>
    </div>
  )
}
export default ReportServiceIssue
