import React, { Fragment, useState } from "react"
import "./style.scss"
import Select from "shared/Select"
import enhancer from "./enhancer"
import Image from "shared/Image"
import DateRangePicker from "shared/DateRangePicker"
import classnames from "classnames"
import { useEffect } from "react"
import { isNil } from "lodash"
import Input from "shared/Input"

const Filter = (props) => {
  //updateToggle is a method for current toggle in filter
  //toggleButtons list of button to have toggle options
  //isToggleButton flag to verify whether to have toggle functionality or not
  let {
    update,
    reset,
    headReset,
    submit,
    config,
    clear,
    hasSubmitted,
    loaded,
    stateType,
    isToggleButton,
    toggleButtons,
    updateToggleStatus,
    isToggleHeadButton,
    toggleHeadButtons,
    updateToggleHeadStatus,
    toggleHeadStatus,
  } = props

  let [activeButton, setActiveButton] = useState()
  let [activeHeadButton, setActiveHeadButton] = useState()

  useEffect(() => {
    if (toggleButtons) {
      setActiveButton(toggleButtons[0])
      updateToggleStatus(toggleButtons[0])
    }
  }, [toggleButtons, toggleHeadStatus])

  useEffect(() => {
    let btn = toggleHeadButtons[0]
    if (toggleHeadButtons) {
      setActiveHeadButton(btn)
      //to do for future reference
      // updateToggleHeadStatus(btn)
    }
  }, [toggleHeadButtons])

  if (!loaded)
    return (
      <div className="loader-parent">
        <div className="loader"></div>
      </div>
    )

  const toggleButton = (toggle) => {
    setActiveButton(toggle)
    updateToggleStatus(toggle)
  }
  const toggleHeadButton = (toggle) => {
    setActiveHeadButton(toggle)
    updateToggleHeadStatus(toggle)
    headReset()
  }

  return (
    <div
      className={`col-12 headerFilter ${
        isToggleButton && "line-height-40"
      } p-0 `}
    >
      <div className="row m-0 no-select">
        <div
          className={`col-sm-12 filtertype text-left pr-2 ${"a" + stateType}`}
        >
          {isToggleHeadButton && stateType === "documentlibrary" && (
            <div className="filter-toggle">
              <span className="searchbytext">Search by :</span>
              <div>
                {toggleHeadButtons?.map((button, i) => {
                  // if (!isNil(button))
                  return (
                    <button
                      key={button}
                      className={classnames(
                        "filter-toggle-states filter-toggle-font",
                        {
                          "filter-toggle-font-active":
                            activeHeadButton === button,
                          "filter-toggle-font-inactive":
                            activeHeadButton !== button,
                          "filter-toggle-div": i === 0,
                          "filter-toggle-stores":
                            i === toggleHeadButtons.length - 1,
                        }
                      )}
                      onClick={() => toggleHeadButton(button)}
                    >
                      {button}
                    </button>
                  )
                })}
              </div>
            </div>
          )}
          {isToggleButton &&
            ((stateType === "documentlibrary" &&
              activeHeadButton === "Filters") ||
              stateType !== "documentlibrary") && (
              <div className="filter-toggle">
                {stateType != "documentlibrary" && (
                  <span className="searchbytext">Search by :</span>
                )}
                <div
                  style={{
                    marginLeft: stateType != "documentlibrary" ? "" : "6%",
                  }}
                >
                  {toggleButtons?.map((button, i) => {
                    // if (!isNil(button))
                    return (
                      <button
                        key={button}
                        className={classnames(
                          "filter-toggle-states filter-toggle-font",
                          {
                            "filter-toggle-font-active":
                              activeButton === button,
                            "filter-toggle-font-inactive":
                              activeButton !== button,
                            "filter-toggle-div": i === 0,
                            "filter-toggle-stores":
                              i === toggleButtons.length - 1,
                          }
                        )}
                        onClick={() => toggleButton(button)}
                      >
                        {button}
                      </button>
                    )
                  })}
                </div>
              </div>
            )}

          {config &&
            config.map((d, i) => {
              let Comp = null
              let requiredLabel = (
                <>
                  <span className="requiredLabel">* </span>
                  {d.label}
                </>
              )

              if (
                stateType === "documentlibrary" &&
                !isNil(activeHeadButton) &&
                activeHeadButton != "Filters"
              ) {
                if (d.type == "text" && d.isDocumentOption) {
                  Comp = (
                    <Input
                      type="text"
                      placeholder={"Enter Document #"}
                      className="filterField asterisk"
                      update={() => update(d, i)}
                      required={true}
                      d={d}
                      i={i}
                      hasSubmitted={hasSubmitted}
                      value={d.value}
                      charRestricted={d.isCharRestricted}
                      minCharRequired={d.minCharRequired}
                    />
                  )
                } else if (
                  ["multiple", "grouped", "single"].includes(d.type) &&
                  d.isDocumentOption &&
                  !d.toggletype
                ) {
                  Comp = (
                    <Select
                      options={d.options}
                      onSelect={update(d, i)}
                      clear={clear(d, i)}
                      labelKey="l"
                      label={d.label}
                      className="single"
                      maxHeight="70"
                      type={d.type}
                      selected={d?.value}
                      required={d.required}
                      hasSubmitted={hasSubmitted}
                      size={d.size}
                      hasSearch={d.hasSearch}
                      isrestricted={d.isrestricted}
                      limitoptions={d.limitoptions}
                      isToggleButton={isToggleButton}
                      showAll={d?.showAll}
                      message={d?.message}
                    />
                  )
                } else if (
                  ["month_year", "date"].includes(d.type) &&
                  d.isDocumentOption
                )
                  Comp = (
                    <div
                    // style={{ marginLeft: "25px", paddingLeft: "22px" }}
                    >
                      {
                        // stateType !== "documentLibrary" &&
                        !d.isFullCircle && (
                          <span className="mr-2">
                            <Image src="ic_or.svg" alt="icon" />
                          </span>
                        )
                      }
                      <DateRangePicker
                        {...d}
                        stateType={stateType}
                        activeHeadButton={activeHeadButton}
                        required={true}
                        onChange={update(d, i)}
                        disable={"future"}
                      />
                    </div>
                  )
              } else {
                //This is for toggle button options
                if (
                  ["multiple", "grouped", "single"].includes(d.type) &&
                  activeButton === d.toggleButtonLabel &&
                  d.toggletype
                ) {
                  Comp = (
                    <div>
                      <Select
                        options={d.options}
                        onSelect={update(d, i)}
                        clear={clear(d, i)}
                        labelKey="l"
                        label={d.required === true ? requiredLabel : d.label}
                        // className="single"
                        maxHeight="70"
                        type={d.type}
                        selected={d.value}
                        required={d.required}
                        hasSubmitted={hasSubmitted}
                        size={d.size}
                        hasSearch={d.hasSearch}
                        isrestricted={d.isrestricted}
                        limitoptions={d.limitoptions}
                        isToggleButton={isToggleButton}
                        showAll={d?.showAll}
                        message={d?.message}
                      />
                    </div>
                  )
                } else if (
                  ["multiple", "grouped", "single"].includes(d.type) &&
                  !d.toggletype &&
                  !d.isDocumentOption
                )
                  Comp = (
                    <Select
                      options={d.options}
                      onSelect={update(d, i)}
                      clear={clear(d, i)}
                      labelKey="l"
                      label={d.required === true ? requiredLabel : d.label}
                      className="single"
                      maxHeight="70"
                      type={d.type}
                      selected={d.value}
                      required={d.required}
                      hasSubmitted={hasSubmitted}
                      size={d.size}
                      hasSearch={d.hasSearch}
                      isrestricted={d.isrestricted}
                      limitoptions={d.limitoptions}
                      isToggleButton={isToggleButton}
                      showAll={d?.showAll}
                      message={d?.message}
                    />
                  )
                else if (["month_year", "date"].includes(d.type))
                  Comp = (
                    <>
                      {stateType !== "documentLibrary" && !d.isFullCircle && (
                        <span className="mr-2">
                          <Image src="ic_or.svg" alt="icon" />
                        </span>
                      )}
                      <DateRangePicker
                        {...d}
                        label={requiredLabel}
                        onChange={update(d, i)}
                      />
                    </>
                  )
              }
              return (
                <Fragment key={i}>
                  {[
                    "time_presets",
                    "time_presets_month",
                    "time_presets_quarter",
                    "time_presets_lastquateryear",
                  ].includes(d.key) && <br />}
                  {Comp !== null && (
                    <div
                      className={
                        (isToggleButton &&
                          [
                            "time_presets",
                            "time_presets_month",
                            "time_presets_quarter",
                          ].includes(d.key)) ||
                        (isToggleButton &&
                          !d.toggletype &&
                          ["multiple"].includes(d.type) &&
                          i === 0) ||
                        (d.toggletype && stateType !== "documentlibrary") ||
                        (["single"].includes(d.type) &&
                          activeHeadButton == "Document#")
                          ? "d-inline-block pr-2 p-l-56"
                          : "d-inline-block pr-2"
                      }
                      key={i}
                    >
                      {Comp}
                    </div>
                  )}
                </Fragment>
              )
            })}
          {/* {`Search by`} */}
          <div className="float-right">
            <div className="d-inline">
              <span className="reset mr-2" onClick={reset}>
                Clear filters
              </span>
              {stateType == "documentlibrary" ? (
                <span onClick={() => submit(stateType)} className={"apply"}>
                  Apply
                </span>
              ) : (
                <span onClick={submit} className={"apply"}>
                  Apply
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default enhancer(Filter)
