import React from "react"
import Image from "shared/HelpImage/index"

function Invoices() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Invoices</h3>

      <Image src="Invoice_Fc_table.png" />

      <p className="text-justify pl-3 pt-2">
        Invoices page provides Invoice details, by searching with Generator
        State, Generator City and Generator Name.
      </p>
      <p className="text-justify pl-3 pt-2">
        There are also Predefined searches or date ranges for the user to
        choose. Once the user has applied the filters, the download button
        displays and then the user can click on the download button and pull all
        this information into an excel spreadsheet.
      </p>
    </div>
  )
}
export default Invoices
