import React from "react"
import Image from "shared/HelpImage/index"

function MyProfiles() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3 ">My Profiles</h3>
      <p className="text-justify pl-3 pt-2">
        <p>
          My profile gives the user a listing of all the active profiles they
          have available to create pick ups.
        </p>
        <p>The listing shows the profile number, waste name.</p>
        <p>
          The user can search by waste name and able to do a quick view or
          download the signed profile as a pdf.
        </p>
      </p>
      <Image className="mt-4" src="my_profile_help.png" alt="" />
    </div>
  )
}
export default MyProfiles
