import React, { Fragment, useEffect, useState } from "react"
import Input from "routes/Admin/Components/Input"
import Select from "shared/Select"
import Image from "shared/Image"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import api from "api"
import _ from "lodash"
import {
  SELECTED_EPA_WASTE_CODES,
  SELECTED_STATE_CODE,
  SELECTED_EPA_UHCS,
  SELECTED_LDR_RCRA_CODE,
  SELECTED_LDR_UHCS,
  AVAILABLE_EPA_WASTE_CODES,
  AVAILABLE_EPA_UHCS,
  AVAILABLE_STATE_CODE,
  AVAILABLE_LDR_UHCS,
  AVAILABLE_LDR_RCRA_CODE,
} from "store/reducer/actionType"
import { useDispatch } from "react-redux"

function EditRegInfoCodes(props) {
  let {
    availCodes,
    profileId,
    selectedCodeOptions,
    setCode,
    type,
    searchHeader,
    setFieldValue,
    setCodeEdit,
    handleGetProfileData,
  } = props
  const dispatch = useDispatch()
  const [searchKeyword, setSearchKeyword] = useState("")
  const initValues = {
    initAvailable: availCodes,
    initSelected: selectedCodeOptions,
  }

  const originOpts = {
    id: 1,
    options: [
      {
        l: "1",
        v: 1,
      },
      {
        l: "2",
        v: 2,
      },
      {
        l: "3",
        v: 3,
      },
      {
        l: "4",
        v: 4,
      },
      {
        l: "5",
        v: 5,
      },
      {
        l: "6",
        v: 6,
      },
    ],
  }
  const [availableCodes, setAvailableCodes] = useState([...availCodes])
  const [searchCodes, setsearchCodes] = useState([...availCodes])
  const [selectedCodes, setSelectedCodes] = useState([...selectedCodeOptions])
  const [lInd, setLInd] = useState()
  const [rInd, setRInd] = useState()
  const [activeIndex, setActiveIndex] = useState(-1)

  const moveLeft = () => {
    if (rInd > -1) {
      let acodes = [...availableCodes]
      acodes.push(selectedCodes[rInd])
      let scodes = [...selectedCodes]
      scodes.splice(rInd, 1)
      setAvailableCodes([...acodes])
      if (searchKeyword?.length > 0)
        setsearchCodes([...initValues.initAvailable])
      else setsearchCodes([...acodes])
      setSelectedCodes([...scodes])
      setActiveIndex(-1)
      setLInd(-1)
      setRInd(-1)
    }
  }
  const moveRight = () => {
    if (lInd > -1) {
      let scodes = [...selectedCodes]
      scodes.push(availableCodes[lInd])
      let acodes = [...availableCodes]
      acodes.splice(lInd, 1)
      setAvailableCodes([...acodes])
      let nonIntersectingCodes = searchCodes?.filter(
        (avCode) =>
          !scodes.some((selCode) =>
            Object.keys(avCode).every((key) => avCode[key] === selCode[key])
          )
      )
      if (searchKeyword?.length === 0) setsearchCodes([...acodes])
      else setsearchCodes([...nonIntersectingCodes])
      setSelectedCodes([...scodes])
      setActiveIndex(-1)
      setRInd(-1)
      setLInd(-1)
    }
  }
  const moveAllLeft = () => {
    setAvailableCodes([...availableCodes, ...selectedCodes])
    if (searchKeyword?.length > 0)
      setsearchCodes([...initValues.initAvailable, ...selectedCodes])
    else setsearchCodes([...availableCodes, ...selectedCodes])
    setSelectedCodes([])
  }
  const moveAllRight = () => {
    setSelectedCodes([...selectedCodes, ...availableCodes])
    setAvailableCodes([])
    if (searchKeyword?.length > 0) setsearchCodes([...initValues.initAvailable])
    else setsearchCodes([])
  }

  const handleSave = async () => {
    let resCode = selectedCodes?.map((item) => item.l).join("\n")
    switch (type) {
      case "EPAWaste":
        let selectedEpaCodesReq = _.cloneDeep(selectedCodes)
        selectedEpaCodesReq.map(async (item) => {
          item.EpaCodeId = item.epaCodeId
          item.EpaCode = item.epaCode
          item.Desc = item.desc
          item.EpaCodeDesc = item.epaCodeDesc
          item.SeqNum = item.origin
          delete item.l
          delete item.v
          delete item.key
          delete item.epaCodeId
          delete item.epaCode
          delete item.desc
          delete item.epaCodeDesc
        })
        try {
          let epaWasteReq = {
            profileRequestId: profileId || 1,
            epaWasteCodes: selectedEpaCodesReq,
          }
          let res = await api.p_saveEpaWasteCode(epaWasteReq)
          let payloadRes = await api.g_getSelectedEpaWasteCodes(
            res?.data?.profileRequestId
          )
          dispatch({ type: SELECTED_EPA_WASTE_CODES, payload: payloadRes })
          let payloadResAv = await api.g_getAvailableEpaWasteCodes(
            res?.data?.profileRequestId
          )
          dispatch({ type: AVAILABLE_EPA_WASTE_CODES, payload: payloadResAv })
          let avLdrRes = await api.g_getAvailableLdrRcraCode(
            res?.data?.profileRequestId
          )
          dispatch({ type: AVAILABLE_LDR_RCRA_CODE, payload: avLdrRes })
          resCode = selectedCodes?.map((item) => item.epaCode).join("\n")
        } catch (error) {}
        break
      case "EPAUHC":
        let selectedCodesUhcReq = _.cloneDeep(selectedCodes)
        selectedCodesUhcReq.map(async (item) => {
          item.LdrCodesId = item.ldrCodesId
          delete item.l
          delete item.v
        })
        try {
          let epaUhcReq = {
            profileRequestId: profileId || 1,
            EpaUhcsRequest: selectedCodesUhcReq,
          }
          let res = await api.p_saveEpaUhc(epaUhcReq)
          let payloadRes = await api.g_getSelectedEpaUhcs(
            res?.data?.profileRequestId
          )
          dispatch({ type: SELECTED_EPA_UHCS, payload: payloadRes })
          let payloadResAv = await api.g_getAvailableEpaUhcs(
            res?.data?.profileRequestId
          )
          dispatch({ type: AVAILABLE_EPA_UHCS, payload: payloadResAv })
          let avLdrUhcRes = await api.g_getAvailableLdrUhcs(
            res?.data?.profileRequestId
          )
          dispatch({ type: AVAILABLE_LDR_UHCS, payload: avLdrUhcRes })
        } catch (error) {}
        break
      case "stateCode":
        let selectedStateCodeReq = _.cloneDeep(selectedCodes)
        selectedStateCodeReq.map(async (item) => {
          item.SeqNum = item.origin
          delete item.l
          delete item.v
          delete item.key
        })
        try {
          let epaStateReq = {
            profileRequestId: profileId || 1,
            stateCodeRequest: selectedStateCodeReq,
          }
          let res = await api.p_saveStateCode(epaStateReq)
          let payloadRes = await api.g_getSelectedStateCodes(
            res?.data?.profileRequestId || 1
          )
          dispatch({ type: SELECTED_STATE_CODE, payload: payloadRes })
          let payloadResAv = await api.g_getAvailableStateCode(
            res?.data?.profileRequestId
          )
          dispatch({ type: AVAILABLE_STATE_CODE, payload: payloadResAv })
        } catch (error) {}
        break
      case "LdrRCRA":
        try {
          let ldrRcraReq = {
            profileRequestId: profileId || 1,
            ldrRcraCodeDetailRequest: selectedCodes,
          }
          let res = await api.p_saveLdrRcraCode(ldrRcraReq)
          let payloadRes = await api.g_getSelectedLdrRcraCode(
            res?.data?.profileRequestId
          )
          dispatch({ type: SELECTED_LDR_RCRA_CODE, payload: payloadRes })
          let payloadResAv = await api.g_getAvailableLdrRcraCode(
            res?.data?.profileRequestId
          )
          dispatch({ type: AVAILABLE_LDR_RCRA_CODE, payload: payloadResAv })
        } catch (error) {}
        break
      case "LdrUHC":
        try {
          let ldrUhcReq = {
            profileRequestId: profileId || 1,
            ldrUhcDetailRequest: selectedCodes,
          }
          let res = await api.p_saveLdrUhc(ldrUhcReq)
          let payloadRes = await api.g_getSelectedLdrUhcs(
            res?.data?.profileRequestId
          )
          dispatch({ type: SELECTED_LDR_UHCS, payload: payloadRes })
          let payloadResAv = await api.g_getAvailableLdrUhcs(
            res?.data?.profileRequestId
          )
          dispatch({ type: AVAILABLE_LDR_UHCS, payload: payloadResAv })
        } catch (error) {}
        break
      default:
        break
    }
    setCode(resCode, type)
    if (type === "stateCode") {
      resCode.length > 0
        ? setFieldValue("epaStateCodeFlag", true)
        : setFieldValue("epaStateCodeFlag", false)
    }
    handleGetProfileData()
    setCodeEdit(false)
  }

  const handleCancel = () => {
    setAvailableCodes([...initValues.initAvailable])
    let lastSelectedCodes = initValues.initSelected
    setSelectedCodes([...lastSelectedCodes])
    let resCode =
      lastSelectedCodes?.length > 0
        ? lastSelectedCodes?.map((item) => item.l).join("\n")
        : ""
    setCode(resCode, type)
    setCodeEdit(false)
  }

  let originFlag = type === "EPAWaste" || type === "stateCode"
  // || type === "LdrRCRA" || type === "LdrUHC"

  useEffect(() => {
    if (searchKeyword?.length > 0) {
      let fCodes = searchCodes.filter((code) =>
        code?.l?.toLowerCase().includes(searchKeyword.toLowerCase())
      )
      setAvailableCodes([...fCodes])
    } else {
      let aCodes = [...searchCodes]
      setAvailableCodes([...aCodes])
    }
  }, [searchKeyword])

  const handleSearch = (event) => {
    setSearchKeyword(event.target.value)
    setRInd(-1)
    setLInd(-1)
  }

  return (
    <Fragment>
      <div>
        <div
          className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus text-left py-4"
          style={{ marginLeft: 15 }}
        >
          <div
            className="labelgenerator myprofile"
            style={{ fontWeight: "bold", marginLeft: originFlag ? 22 : 64 }}
          >
            <FormattedMessage id={`Edit ${searchHeader}`} />
          </div>
        </div>
        {(type === "EPAWaste" || type === "EPAUHC" || type === "stateCode") && (
          <div
            className="form-input-block col-sm-5"
            style={{ marginLeft: originFlag ? 34 : 77 }}
          >
            <span className="label">
              <FormattedMessage id={`Find ${searchHeader}`} />
            </span>
            <Input
              value={searchKeyword}
              placeholder="Search..."
              keyName="name"
              onFieldChange={handleSearch}
            />
          </div>
        )}
        <div className="col-sm-12 d-flex mt-2">
          <div
            className="col-sm-5  text-left text-subHeader"
            style={{ fontWeight: "bold", marginLeft: originFlag ? 30 : 75 }}
          >
            <FormattedMessage id="profile.stateRegInfo.availableHeader" />
          </div>
          <div
            // className={`col-sm-6 text-left text-subHeader
            //   ${
            //     type === "EPAWaste" || type === "stateCode"
            //       ? "select-epa"
            //       : "select-rcra"
            //   }
            // `}
            className="col-sm-6 text-left text-subHeader"
            style={{ fontWeight: "bold", marginLeft: originFlag ? 47 : 140 }}
          >
            <FormattedMessage id="profile.stateRegInfo.selectedHeader" />
          </div>
        </div>
        <div
          className="code-swapper  dsp-flx col-sm-12 col-md-12 col-lg-12 text-left py-2 position-relative"
          // style={{ justifyContent: "flex-start" }}
        >
          <div className={"code-selector col-sm-4"}>
            <div
              className="customerTableContainer"
              style={{
                height: "290px",
                // width: "300px",
                overflowY: "scroll",
                paddingTop: "12px",
              }}
            >
              {availableCodes?.map((item, i) => (
                <div
                  className="row customDataTab m-0"
                  key={i}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setLInd(i)
                    setRInd(-1)
                    setActiveIndex(i)
                  }}
                >
                  <div
                    className={`col-sm-12 px-1 py-2 mb-1 customCell 
                      ${activeIndex === i && lInd === i ? "activeIndex" : ""}
                    `}
                    style={{ borderRadius: "10px" }}
                  >
                    {item.l}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className={
              originFlag
                ? "move-buttons col-sm-1 ml-0"
                : "move-buttons col-sm-1 ml-2"
            }
          >
            <div className="col-sm-1 limg" onClick={moveRight}>
              <Image className="" src={"Swap_Right.svg"} alt="" />
            </div>
            <div className=" col-sm-1 limg" onClick={moveLeft}>
              <Image className="" src={"Swap_Left.svg"} alt="" />
            </div>
            <div className="col-sm-1 limg" onClick={moveAllRight}>
              <Image className="" src={"Swap_All_Right.svg"} alt="" />
            </div>
            <div className="col-sm-1 limg" onClick={moveAllLeft}>
              <Image className="" src={"Swap_All_Left.svg"} alt="" />
            </div>
          </div>
          <div
            className={`code-selector 
            ${originFlag ? "col-sm-6" : "col-sm-4"}
            `}
          >
            <div
              className="customerTableContainer mr-2"
              style={{
                height: "290px",
                // width: "300px",
                overflowY: "scroll",
                paddingTop: 8,
                overflowX: "hidden",
              }}
            >
              <div className="dataContainer">
                {selectedCodes.map((item, i) => (
                  <div
                    className="row customDataTab ml-0"
                    key={i}
                    style={{
                      cursor: "pointer",
                      marginRight: originFlag ? 0 : -32,
                      // height: "42px",
                      // display: "flex",
                      // justifyContent: "space-between",
                    }}
                    // onClick={() => {
                    //   setRInd(i)
                    //   setLInd(-1)
                    //   setActiveIndex(i)
                    // }}
                  >
                    <div
                      className={`${
                        originFlag ? "col-sm-8" : "col-sm-11"
                      } px-1 py-2 mb-1 mt-1 customCell ${
                        activeIndex === i && rInd === i ? "activeIndex" : ""
                      }`}
                      style={{
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        setRInd(i)
                        setLInd(-1)
                        setActiveIndex(i)
                      }}
                    >
                      {item.l}
                    </div>
                    <div
                      className={`${
                        originFlag ? "col-sm-1 px-1 py-2 mb-1 mt-1" : ""
                      }`}
                    ></div>
                    <div
                      key={i}
                      className={`${
                        originFlag ? "col-sm-3 px-1 py-2 mni-style" : ""
                      }`}
                      style={{
                        display: originFlag ? "flex" : "none",
                        zIndex: "1 !important",
                        background: "none",
                      }}
                      onClick={() => setActiveIndex(-1)}
                    >
                      <Select
                        name={"origin"}
                        labelkey={"l"}
                        label=""
                        className={"sort-dropdown"}
                        options={originOpts.options || []}
                        selected={
                          item?.seqNum
                            ? { l: `${item?.seqNum}`, v: item?.seqNum }
                            : item?.key
                        }
                        value={item?.key}
                        onSelect={(e) => {
                          setSelectedCodes((prevCodes) =>
                            prevCodes.map((code, index) =>
                              index === i
                                ? {
                                    ...code,
                                    origin: e?.v,
                                    key: { l: e?.l, v: e?.v },
                                  }
                                : code
                            )
                          )
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* {originFlag && (
              <div
                className="customerTableContainer ml-2"
                style={{
                  height: "290px",
                  width: "200px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  paddingTop: 5,
                }}
              >
                <div className="dataContainer edit-dropdown">
                  {selectedCodes?.map((item, i) => (
                    <div
                      key={i}
                      className={`col-sm-12 px-1 py-2 customCell origin-code-selector`}
                      style={{
                        display: originFlag ? "flex" : "none",
                        margin: "0.25rem 1rem 1.75rem",
                        zIndex: "1 !important",
                        background: "none",
                        // alignItems: "baseline"
                      }}
                      onClick={() => setActiveIndex(-1)}
                    >
                      <Select
                        name={"origin"}
                        labelkey={"l"}
                        label=""
                        className={"sort-dropdown"}
                        options={originOpts.options || []}
                        selected={
                          item?.seqNum
                            ? { l: `${item?.seqNum}`, v: item?.seqNum }
                            : item?.key
                        }
                        value={item?.key}
                        onSelect={(e) => {
                          setSelectedCodes((prevCodes) =>
                            prevCodes.map((code, index) =>
                              index === i
                                ? {
                                    ...code,
                                    origin: e?.v,
                                    key: { l: e?.l, v: e?.v },
                                  }
                                : code
                            )
                          )
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )} */}
          </div>
        </div>
        <div className="save-cancel">
          <div className="row m-0 float-right m-r-2r">
            <button
              type="button"
              className={`green-button`}
              onClick={handleSave}
            >
              Save
            </button>
          </div>
          <div className="row m-0 float-right m-r-2r">
            <button
              type="cancel"
              className={`cancel-button`}
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default EditRegInfoCodes
