import { sortOptions } from "routes/Utilities/util"
import {
  ALL_PROCESS_CODES,
  ALL_ORIGIN_CODES,
  ALL_SOURCE_CODES,
  ALL_FORM_CODES,
  ALL_VENDORS,
  ALL_DOCUMENT_TYPES,
  ALL_EPAWASTE_CODES,
  ALL_FACILITY_PROCESS_CODES,
  ALL_FREQUENCY_TYPES,
  ALL_LDR_CLASS,
  AVAILABLE_EPA_WASTE_CODES,
  AVAILABLE_EPA_UHCS,
  AVAILABLE_STATE_CODE,
  AVAILABLE_LDR_UHCS,
  AVAILABLE_LDR_RCRA_CODE,
  SELECTED_EPA_WASTE_CODES,
  SELECTED_STATE_CODE,
  SELECTED_EPA_UHCS,
  SELECTED_LDR_RCRA_CODE,
  SELECTED_LDR_UHCS,
  ALL_TEXAS_SHIPPING_WASTE_CODES,
  ALL_PROFILE_STATUS,
  WASTE_DESCRIPTION,
  SET_PROGRESS_STEP,
  SET_PROGRESS_STEP_ONLY,
  SET_PROFILE_ID,
  SET_ACTIVE_STEP,
  ALL_PROFILE_LIST,
  SORT_PROFILE_LIST,
  CURRENT_PROFILE,
  SET_NEW_PROGRESS_STEP,
  GET_DEFAULT_GENE,
  SET_PROFILE_NAME,
  GET_PRINT_STATUS,
  GET_PRINT_LABEL,
  GET_FORM_VALIDITY,
  GET_PROGRESS_STEP,
  RECERTIFY_PROFILE,
  CERTIFIED_PROFILE,
  GET_PROFILE,
  AVAILABLE_REG_CONST,
  AVAILABLE_DOT_CONST,
  GET_ALL_PROFILE_TEMPLATES,
  PROFILE_OVERVIEW_LIST,
  SORT_OVERVIEW_PROFILE_LIST,
  SET_CHEMICALS,
  ALL_ODORS,
  ALL_NON_PROCESS_CODES,
  GET_PROFILE_FOR_PRINT_LABEL,
} from "../actionType"
const initialState = {
  allVendors: [],
  allProcessCodes: [],
  nonFilterProcessCodes: [],
  allFormCodes: [],
  allSourceCodes: [],
  allOriginCodes: [],
  allEPAWasteCodes: [],
  allDocumentTypes: [],
  allFacilityProcessCodes: [],
  defaultGenerator: null,
  isPageValid: false,
  allFrequencyTypes: [
    {
      frequencyTypeId: 1,
      description: "Weekly",
    },
    {
      frequencyTypeId: 2,
      description: "Monthly",
    },
  ],
  allLDRClass: [],
  availableEpaWasteCodes: [],
  availableEpaUhcs: [],
  availableStateCode: [],
  availableLdrUhcs: [],
  allProfileStatus: [],
  sort: {
    sortField: "approvedDate",
    dir: "desc",
  },
  applicationProgress: {},
  currentStep: 1,
  currentProfile: {
    profileRequestId: "",
    generatorName: "",
    templateFlag: false,
  },
  profilesList: [],
  profilesOverviewList: [],
  availabledocDocuments: [],
  printStatus: false,
  printLabel: [],
  allProfileTemplates: [],
  profileName: "",
  chemicals: [],
  allOdors: [],
  getProfileForPrintLabel: [],
}

const getSortDir = (data, field) => {
  if (data.sort?.sortField === field) {
    return data.sort.dir === "desc" ? "asc" : "desc"
  }
  return "desc"
}

const profileDetails = (state = initialState, action) => {
  let newState = { ...state }
  switch (action.type) {
    case WASTE_DESCRIPTION:
      return newState
    case ALL_VENDORS:
      return {
        ...newState,
        allVendors: action.payload,
      }
    case ALL_PROCESS_CODES:
      return {
        ...newState,
        allProcessCodes: action.payload,
      }
    case ALL_NON_PROCESS_CODES:
      return {
        ...newState,
        nonFilterProcessCodes: action.payload,
      }
    case SET_PROGRESS_STEP_ONLY:
      return {
        ...newState,
        currentStep: action.progressStep,
      }
    case SET_PROGRESS_STEP:
      return {
        ...newState,
        applicationProgress: {
          ...newState.applicationProgress,
          [action.step]: action.percentage,
        },
        currentStep: action.progressStep,
      }
    case GET_PROGRESS_STEP:
      return {
        ...newState,
        applicationProgress: {
          ...newState.applicationProgress,
          [action.step]: action.percentage,
        },
        currentStep: action.progressStep,
      }
    case SET_NEW_PROGRESS_STEP:
      return {
        ...newState,
        applicationProgress: {},
      }
    case SET_PROFILE_ID:
      return {
        ...newState,
        currentProfile: {
          profileRequestId: action?.payload?.profileRequestId,
          generatorName: action?.payload?.generatorName,
        },
      }
    case CURRENT_PROFILE:
      return {
        ...newState,
        currentProfile: {
          ...action?.payload,
          generatorName: "",
          templateFlag: action?.payload?.templateFlag,
        },
      }
    case RECERTIFY_PROFILE:
      return {
        ...newState,
        recertifyProfile: action?.payload,
      }
    case CERTIFIED_PROFILE:
      return {
        ...newState,
        certifiedProfile: action?.payload,
      }
    case SET_ACTIVE_STEP:
      return {
        ...newState,
        currentStep: action.payload,
      }
    case ALL_FORM_CODES:
      return {
        ...newState,
        allFormCodes: action.payload,
      }
    case ALL_ORIGIN_CODES:
      return {
        ...newState,
        allOriginCodes: action.payload,
      }
    case ALL_SOURCE_CODES:
      return {
        ...newState,
        allSourceCodes: action.payload,
      }
    case ALL_EPAWASTE_CODES:
      return {
        ...newState,
        allEPAWasteCodes: action.payload,
      }
    case ALL_DOCUMENT_TYPES:
      return {
        ...newState,
        allDocumentTypes: action.payload,
      }
    case ALL_FACILITY_PROCESS_CODES:
      return {
        ...newState,
        allFacilityProcessCodes: action.payload,
      }
    case ALL_FREQUENCY_TYPES:
      return {
        ...newState,
        allFrequencyTypes: action.payload,
      }
    case ALL_LDR_CLASS:
      return {
        ...newState,
        allLDRClass: action.payload,
      }
    case AVAILABLE_EPA_WASTE_CODES:
      return {
        ...newState,
        availableEpaWasteCodes: action.payload,
      }
    case AVAILABLE_EPA_UHCS:
      return {
        ...newState,
        availableEpaUhcs: action.payload,
      }
    case AVAILABLE_STATE_CODE:
      return {
        ...newState,
        availableStateCode: action.payload,
      }
    case AVAILABLE_LDR_UHCS:
      return {
        ...newState,
        availableLdrUhcs: action.payload,
      }
    case AVAILABLE_LDR_RCRA_CODE:
      return {
        ...newState,
        availableLdrRcraCode: action.payload,
      }
    case AVAILABLE_REG_CONST:
      return {
        ...newState,
        availableRegConst: action.payload,
      }
    case AVAILABLE_DOT_CONST:
      return {
        ...newState,
        availableDotConst: action.payload,
      }
    case SELECTED_EPA_WASTE_CODES:
      return {
        ...newState,
        selectedEpaWasteCodes: action.payload,
      }
    case SELECTED_EPA_UHCS:
      return {
        ...newState,
        selectedEpaUhcs: action.payload,
      }
    case SELECTED_STATE_CODE:
      return {
        ...newState,
        selectedStateCode: action.payload,
      }
    case SELECTED_LDR_RCRA_CODE:
      return {
        ...newState,
        selectedLdrRcraCode: action.payload,
      }
    case SELECTED_LDR_UHCS:
      return {
        ...newState,
        selectedLdrUhcs: action.payload,
      }
    case ALL_TEXAS_SHIPPING_WASTE_CODES:
      return {
        ...newState,
        allTexasShippingWasteCodes: action.payload,
      }
    case ALL_PROFILE_STATUS:
      return {
        ...newState,
        allProfileStatus: action.payload,
      }
    case GET_PROFILE:
      return {
        ...newState,
        getProfile: action.payload,
      }
    case ALL_PROFILE_LIST:
      return {
        ...newState,
        profilesList: sortOptions(
          action.payload.data,
          "date",
          "approvedDate",
          "desc"
        ),
        sort: {
          ...newState.sort,
          dir: "desc",
          sortField: "approvedDate",
        },
        // sort on approved date here
      }
    case PROFILE_OVERVIEW_LIST:
      return {
        ...newState,
        profilesOverviewList: sortOptions(
          action.payload.data,
          "date",
          "createdDate",
          "desc"
        ),
        sort: {
          ...newState.sort,
          dir: "desc",
          sortField: "createdDate",
        },
        // sort on approved date here
      }
    case SORT_OVERVIEW_PROFILE_LIST:
      return {
        ...newState,
        profilesOverviewList: sortOptions(
          action.payload,
          action.data.type,
          action.data.fieldName,
          getSortDir(newState, action.data.fieldName)
        ),
        sort: {
          ...newState.sort,
          dir: getSortDir(newState, action.data.fieldName),
          sortField: action.data.fieldName,
        },
      }
    case SORT_PROFILE_LIST:
      return {
        ...newState,
        profilesList: sortOptions(
          action.payload,
          action.data.type,
          action.data.fieldName,
          getSortDir(newState, action.data.fieldName)
        ),
        sort: {
          ...newState.sort,
          dir: getSortDir(newState, action.data.fieldName),
          sortField: action.data.fieldName,
        },
      }
    case GET_DEFAULT_GENE:
      return {
        ...newState,
        defaultGenerator: action.payload.generatorId,
      }
    case SET_PROFILE_NAME:
      return {
        ...newState,
        profileName: action.payload,
      }
    case GET_PRINT_STATUS:
      return {
        ...newState,
        printStatus: action.payload,
      }
    case GET_PRINT_LABEL:
      return {
        ...newState,
        printLabel: action.payload,
      }
    case GET_ALL_PROFILE_TEMPLATES:
      return {
        ...newState,
        allProfileTemplates: action.payload,
      }
    case GET_FORM_VALIDITY:
      return {
        ...newState,
        isPageValid: action.payload,
      }
    case SET_CHEMICALS:
      return {
        ...newState,
        chemicals: action.payload,
      }
    case ALL_ODORS:
      return {
        ...newState,
        allOdors: action.payload,
      }
    case GET_PROFILE_FOR_PRINT_LABEL:
      return {
        ...newState,
        getProfileForPrintLabel: action.payload,
      }

    default:
      return state
  }
}

export default profileDetails
