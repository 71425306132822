import React, { useState } from "react"
import OutsideClickHandler from "react-outside-click-handler"
import SingleSelect from "./SingleSelect"
import MultiSelect from "./MultiSelect"
import GroupSelect from "./GroupSelect"
import classnames from "classnames"
import "./style.scss"
import _ from "lodash"
import { isHomeDepotSiteUser } from "shared"

let Select = (props) => {
  let {
    type = "single",
    required,
    options,
    selected,
    hasSubmitted,
    isError,
    // fromaNaics,
  } = props
  let [open, setOpen] = useState(false)
  let SelectC

  let opts = {
    single: SingleSelect,
    multiple: MultiSelect,
    grouped: GroupSelect,
  }

  let hasError = () => {
    if (open) return false
    if (required && hasSubmitted) {
      if (type == "multiple") return !options.find((d) => d.selected)
      if (type == "single") return !selected
      if (type == "grouped") return !selected[0]
    }
    return false
  }

  let isEmpty = () => {
    if (open) return true
    if (type == "multiple") return !options.find((d) => d.selected)
    if (type == "single") return !selected
    if (type == "grouped") return _.isEmpty(selected)
    return false
  }

  SelectC = opts[type]
  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div
        className={classnames(type, {
          "has-error":
            !isHomeDepotSiteUser() &&
            type === "multiple" &&
            options.length >= 0 &&
            hasError(),
          "position-relative": required,
          "is-not-empty": !isEmpty(),
        })}
      >
        <SelectC {...props} {...{ open, setOpen }} isError={isError} />
        <span className="filter-error" style={{ paddingTop: "2%" }}>
          This field is required
        </span>
      </div>
    </OutsideClickHandler>
  )
}

export default Select
