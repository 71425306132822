import React from "react"
import Image from "shared/HelpImage/index"
import { useSelector } from "react-redux"
import { useHarsco } from "shared"

function ForgotPassword() {
  const { isFullCircle } = useHarsco()
  const { changeCustomer } = useSelector((state) => state)
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">
        Forgot Password – Password Reset Walkthrough{" "}
      </h3>

      <p className="text-justify pl-3 pt-3">
        From here type in your email address and password and click the sign in
        button.
      </p>
      <p className="text-justify pl-3 pt-3">
        If you forget your password, click on the “forgot your password?” link.{" "}
      </p>
      <div className="img-section">
        <Image src="forgot_password_sign_in.png" alt="Login Image" />
      </div>

      <p className="text-justify pl-3 pt-3">
        Forgot password link will take you to a screen for you to type in your
        email address. It should be the same email address you used to login.
        Once you put in your email address click the “Send verification code”
        button.
      </p>
      <div className="img-section">
        <Image src="forgot_password_sign_in_2.png" alt="Login Image" />
      </div>
      <p className="text-justify pl-3 pt-3">
        You will be sent a verification code to the email address you provided.
        From there the user can finish resetting your password and login.
      </p>
      <div className="img-section">
        <Image src="forgot_password_sign_in_3.png" alt="Login Image" />
      </div>
      <p className="text-justify pl-3 pt-3">
        Enter the verification code and please click ‘Verify Code’.
      </p>
      <div className="img-section">
        <Image src="forgot_password_sign_in_4.png" alt="Login Image" />
      </div>
      <p className="text-justify pl-3 pt-3">
        Enter your email address and please click continue.
      </p>
      <div className="img-section">
        <Image src="forgot_password_sign_in_5.png" alt="Login Image" />
      </div>
      <p className="text-justify pl-3 pt-3">
        Enter the new password in the New Password and Confirm New Password box.
        Please follow the password criteria when creating the new password. Once
        done, please click ‘Continue’.
      </p>
      <div className="img-section">
        <Image src="forgot_password_sign_in_6.png" alt="Login Image" />
      </div>
      <p className="text-justify pl-3 pt-3">
        You will now be logged into the portal and the new password will take
        effect from subsequent logins.
      </p>
      <div className="img-section">
        <Image
          src={
            changeCustomer?.customer?.businessSegmentId === 3
              ? "mi_dashboard.png"
              : isFullCircle
              ? "FullCircle_Dashboard_page.png"
              : "forgot_password_login_in_remove_arrow.png"
          }
          alt="Login Image"
        />
      </div>
    </div>
  )
}
export default ForgotPassword
