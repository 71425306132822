import React, { useState, useEffect } from "react"
import { MultiSelect } from "react-multi-select-component"

//1. select all option - select all multiselect option
//2. unselect all option - unselect all multiselect options
//3.

const MultiSelectComponent = ({
  options = [{ value: "" }, { label: "" }],
  name = "",
  isAllReqd = false,
  disableSearch = true,
  isOpen,
  className = "",
  selected = [],
  handleOnChange,
}) => {
  const multiSelectOptions = options
  //eslint-disable-next-line
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    setIsSelected(selected.length > 0)
  }, [selected])
  // options?.length > 0
  //   ? [{ label: "All", value: "all" }, ...options]
  //   : [{ label: "All", value: "all" }]

  // const updateData = (selectedItems) => {
  //   if (selectedItems.find((item) => item.value === 'all')) {
  //     if (selectedItems.length === multiSelectOptions.length - 2) {
  //       return selectedItems.filter((item) => item.value !== 'all');
  //     } else return multiSelectOptions.filter((item) => item.value !== 'any');
  //   }
  //   if (
  //     selectedItems.length ===
  //     selected.filter((item) => item.value !== 'all').length
  //   ) {
  //     return [];
  //   }
  //   if (selectedItems.length === multiSelectOptions.length - 2) {
  //     return multiSelectOptions.filter((item) => item.value !== 'any');
  //   }
  //   return selectedItems;
  // };

  // const updateAny = (selectedItems) => {
  //   let anyIndex = selectedItems.findIndex((item) => item.value == 'any');
  //   let allIndex = selectedItems.findIndex((item) => item.value == 'all');
  //   if (selectedItems.find((item) => item.value === 'any')) {
  //     if (anyIndex < allIndex) {
  //       return updateData(selectedItems.filter((item) => item.value == 'all'));
  //     }
  //     if (anyIndex === 0 && allIndex == -1 && selectedItems.length > 1) {
  //       return selectedItems.filter((item) => item.value !== 'any');
  //     }
  //     return multiSelectOptions.filter((item) => item.value === 'any');
  //   }
  //   if (allIndex > 1) {
  //     return updateData(
  //       multiSelectOptions.filter((item) => item.value === 'all')
  //     );
  //   }
  //   return updateData(selectedItems);
  // };

  const onHandleChange = (selectedItems) => {
    handleOnChange(selectedItems)
  }

  const clearAllSelections = (e) => {
    e.stopPropagation()
    e.preventDefault()
    onHandleChange([])
  }

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const toggleDropdown = (isOpen) => {
    setIsDropdownOpen(isOpen)
  }

  // const customValueRenderer = (selected) => {
  //   return selected
  //     .filter((item) => item.value !== 'all')
  //     .map((item) => item.label);
  // };
  return (
    <div
      className={`multi-select-wrapper mni-multiselect ${
        isSelected ? "selected" : ""
      }`}
    >
      <MultiSelect
        options={
          isAllReqd
            ? multiSelectOptions
            : options?.length > 0
            ? options
            : [{ label: "", value: "" }]
        }
        value={selected?.filter((val) => val.value)}
        onChange={onHandleChange}
        name={name}
        className={className} // valueRenderer={customValueRenderer}
        hasSelectAll={isAllReqd}
        isOpen={isOpen}
        disableSearch={disableSearch}
        onMenuToggle={toggleDropdown}
        overrideStrings={{
          selectAll: (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Select All</span>
              <span
                onClick={clearAllSelections}
                style={{
                  cursor: "pointer",
                  color: "#000000",
                  marginLeft: "120px",
                  textDecoration: "underline",
                }}
              >
                Clear All
              </span>
            </div>
          ),
        }}
      />
      <div
        className={`dropdown-heading-dropdown-arrow ${
          isDropdownOpen ? "open" : ""
        }`}
      >
        <svg
          width="18"
          height="22"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M 5 8 L 15 8 L 10 13 Z" fill="#6fb744" />
        </svg>
      </div>

      {/* <MultiSelect
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy={"Select"}
        isCreatable={true}
      /> */}
    </div>
  )
}

export default MultiSelectComponent
