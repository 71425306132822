import React from "react"
import OutsideClickHandler from "react-outside-click-handler"
import Loading from "shared/Loading"
import Image from "shared/Image"

const MultiSelect = (props) => {
  const {
    open,
    onSelectField,
    name,
    placeholder,
    options,
    onSelectValue,
    value = [],
    loadingId,
    parentCls = "",
    searchClass = "",
    cls = "",
    hasId = false,
  } = props

  const [isSelectAllUnChecked, setisSelectAllUnChecked] = React.useState(false)
  let allState = "none"
  let selected = options.filter((d) => d.check)
  // if (selected.length > 0) allState = "some"
  if (selected.length == options.length) allState = "all"

  const checkSelectedValues = (name, ele, account = false) => {
    let selectedValues = []
    setisSelectAllUnChecked(false)
    options.forEach((element) => {
      if (ele.v === element.v && !account) {
        element.check = element?.check === undefined ? true : !element?.check
      }
      if (element.check) {
        selectedValues = [...selectedValues, element]
      }
      if (!element.check) {
        element.default = false
      }
      if (account && element.check && ele.v === element.v) {
        element.default =
          element?.default === undefined ? true : !element?.default
      } else if (account && element.check && ele.v !== element.v) {
        element.default = false
      }
    })
    onSelectValue(name, selectedValues, "multiple")
  }

  let updateAll = (e) => {
    let selectedValues = []

    let opts = [...options]
    opts.forEach((d) => {
      d.check = e.currentTarget.checked
      if (d.check) {
        setisSelectAllUnChecked(false)
        selectedValues = [...selectedValues, d]
      } else {
        d.default = false
        selectedValues = [...selectedValues, d]
        setisSelectAllUnChecked(true)
      }
    })
    onSelectValue(name, selectedValues, "multiple")
  }

  return (
    <OutsideClickHandler onOutsideClick={() => onSelectField(name, false)}>
      <div
        className={`col-sm-12 col-md-12 col-lg-12 input-text dp-flx p-0 ${
          open && parentCls.length > 0 ? "open open-border-label" : ""
        } ${cls}`}
        onClick={() => onSelectField(name, !open)}
      >
        <div
          className={`col-sm-12 col-md-12 col-lg-12 input-box ${
            open ? (parentCls.length > 0 ? "" : "open open-border-label") : ""
          }`}
        >
          <div
            className={`text-truncate-dropdown ${
              !value ? "place" : "place-select "
            } ${searchClass && "place-label"}`}
            title={value?.hasOwnProperty("l") ? value["l"] : ""}
          >
            {placeholder}{" "}
            {`${
              value.length && !isSelectAllUnChecked > 0
                ? `(${value.filter((x) => x.check)?.length})`
                : ""
            }`}
          </div>
        </div>
        <div className={"drop-arrows create-down"}></div>
      </div>
      {open && options?.length > 0 && (
        <div
          className={`drop-option controls-dropdown ${
            open && "open-border-options"
          }`}
        >
          {options?.length > 0 && (
            <Loading id={loadingId} height={110}>
              <div className="drop-down-options">
                <div className="dp slt-all-check">
                  <label className="multimain">
                    <input
                      type="checkbox"
                      checked={allState == "all"}
                      onClick={(e) => updateAll(e)}
                    />
                    <span className="geekmark checkbox-height"></span>
                  </label>
                  <span onClick={() => {}}>{`Select All`}</span>
                </div>
                {options?.map((ele, i) => {
                  return (
                    <div
                      className={`multi-opt ${cls} dp-fl-multi-select`}
                      key={hasId ? ele.v : i}
                    >
                      <div className="dp">
                        <label className="multimain">
                          <input
                            type="checkbox"
                            onClick={() => checkSelectedValues(name, ele)}
                            checked={ele.check}
                          />
                          <span className="geekmark checkbox-height"></span>
                        </label>
                        <span onClick={() => {}}>{ele.l}</span>
                      </div>
                      {ele.check && (
                        <Image
                          className="pad-1-r-rem"
                          src={
                            ele?.default
                              ? "defaultIconSelected.svg"
                              : "defaultIcon.svg"
                          }
                          alt="pad-1-r-rem"
                          onClick={() => checkSelectedValues(name, ele, true)}
                        />
                      )}
                    </div>
                  )
                })}
              </div>
            </Loading>
          )}
        </div>
      )}
    </OutsideClickHandler>
  )
}
export default MultiSelect
