import React from "react"
import Image from "shared/HelpImage/index"

function RequestService() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">New Request</h3>
      <p className="text-justify pl-3 pt-2">
        From the New Request page.
        <p className="pt-1">
          The user can:
          <br />
          - New Schedule request- Request a new service pick up
          <br />
          - Report a Service issue with an existing service
          <br />
          - Change Request – change a scheduled service
          <br />- Request Supplies
        </p>
      </p>
      <div className="img-section">
        <Image className="" src="newrequest.png" alt="Request Service Image" />
      </div>
    </div>
  )
}
export default RequestService
