import React, { Fragment } from "react"
import Select from "shared/Select"
import Image from "shared/Image"
import classnames from "classnames"
import { FormattedMessage } from "react-intl"
import { formattedDate, useIsTablet } from "shared"

function LeftServiceSection(props) {
  let {
    setActiveService,
    activeService,
    serviceData,
    sortBy,
    MyStoreSortByService,
    updatedupdateSortByOption,
  } = props
  let { items = [] } = serviceData
  /** Below function is used to render respective icons on workOrderType */

  let isTablet = useIsTablet()
  return (
    <Fragment>
      <div
        className={` ${
          isTablet
            ? "col-sm-5 col-md-5 col-lg-5 text-left service-tab "
            : "col-sm-4 col-md-4 col-lg-4 text-left service-tab "
        }`}
      >
        <div className="row m-0">
          <div className={`col-sm-4 col-md-4 col-lg-4 text-left p-0 `}>
            <label className="service-label">
              <FormattedMessage id="myschedule.servicesHistory" />
            </label>
          </div>

          <div
            className={` ${
              isTablet
                ? "col-sm-8 col-md-8 col-lg-8 sortybysection p-0  "
                : "col-sm-8 col-md-8 col-lg-8 text-left p-0 "
            }`}
          >
            <label className="sort-label">
              <FormattedMessage id="myschedule.sortby" />
            </label>
            <label>
              {MyStoreSortByService && (
                <Select
                  label="Please select"
                  options={MyStoreSortByService}
                  // onSelect={updateSortBy}
                  onSelect={(i) => updatedupdateSortByOption(i)}
                  labelKey="l"
                  className="sort-dropdown"
                  selected={sortBy}
                />
              )}
            </label>
          </div>
          <div className="each-services-block w-100">
            {items.map((d, i) => (
              <div
                key={d.workOrderId}
                className={classnames("each-service", {
                  active: d.workOrderId == activeService?.workOrderId,
                })}
                onClick={() => setActiveService(d)}
              >
                <div className="row m-0">
                  <div className="col-sm-2 col-md-2 col-lg-2 text-left p-0">
                    <div className="calendar-icon">
                      <span>
                        <Image
                          className={"ic_truck"}
                          width="34px"
                          src="ic_truck.svg"
                          alt=""
                        />
                        {/* <Image
                          className=""
                          src={getIconSource(d)}
                          width="30px"
                          alt=""
                        /> */}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-8 col-md-8 col-lg-8 text-left pl-1 p-0">
                    <div className="site-location">
                      <FormattedMessage id="myschedule.sitelocation" />
                    </div>
                    <div className="site-location p-t-10">
                      <FormattedMessage id="requestservicecheckout.addresslabel" />
                      <span className="site-address">{" " + d?.address}</span>
                    </div>
                    <div className="site-address">
                      {d?.city}, {d?.state}
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-2 col-lg-2 text-center right-block p-0">
                    <div className="service-label">
                      {formattedDate(d.formatTime)}
                    </div>
                    {/* <div className="service-date">
                      <label className="service-button">
                        {!_.isEmpty(d.serviceTypes) &&
                          d?.serviceTypes.map((d, i) => (
                            <span className="" key={i}>
                              {d.serviceType}
                            </span>
                          ))}
                      </label>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default LeftServiceSection
