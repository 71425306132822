import React from "react"
import Image from "shared/HelpImage/index"

function MyAlerts() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">My Alerts</h3>

      <Image src="MyAlertsAccessSettings_Fc.png" />

      <p className="text-justify pl-3 pt-2">
        My Alerts gives the user an option to receive alerts or not. Click the
        My Alerts tab and then Access Setting button to see the options.
        <br />
        The user can check the box they want to be alerted for to receive alerts
        for all alert options listed.
        <br />
        When done click on the Save Preferences button.
        <br /> <br /> <br /> <br />
        <Image src="HeaderBar_FC.png" />
        <br /> <br /> <br />
        Alert “New Data”. This is when the new quarterly data is uploaded in the
        portal.
        <br />
        Once the alert is saved, the user will be “Alerted” by the Bell icon
        that is on the header bar through out the site.
        <br />
        <br />
        <Image src="FullCircle_Alert_count.png" /> <br /> <br /> <br /> <br />
        The number of Alerts will display on the bell.
        <br />
        Alert preferences can be changed at any time.
      </p>
    </div>
  )
}
export default MyAlerts
