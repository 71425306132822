import React from "react"
import Loading from "shared/Loading"
import Modal from "shared/Modal"
import { FormattedMessage } from "react-intl"
import Image from "shared/Image"

function ExceptionRecords(props) {
  let {
    exceptionRecords,
    exceptionGlossaryData,
    exceptionGlossary,
    customShowGlossary,
    toggleModalGlossary,
    onClickOK,
    searchTerm,
    setSearchTerm,
    searchGlossary,
  } = props

  let searchedGlossary = searchGlossary(searchTerm, exceptionGlossaryData)

  return (
    <div className="col-4 pl-0">
      <div className="row m-0">
        <div className="col-6 p-0">
          <div className="text-left status mb-2">
            <FormattedMessage id="serviceorder.exceptionrecords" />
          </div>
        </div>
        {exceptionRecords.length !== 0 && (
          <div className="col-6 ">
            <div className="text-right status  request">
              <span className="pickup" onClick={exceptionGlossary}>
                <FormattedMessage id="serviceorder.exceptionglossary" />
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="row m-0 borders">
        <Loading id="get_exceptionRecords">
          <div className="col-12 p-0 m-0">
            <div className="row pr-3 m-0 ">
              <div
                className={`col-12  m-0 text-left execption scrollbar ${
                  exceptionRecords.length === 0 ? "noRecordsMsg" : ""
                }`}
              >
                {exceptionRecords.map((r, i) => (
                  <ul className="d-block exceptionrow" key={i}>
                    <li>{r.exceptionName}</li>
                  </ul>
                ))}

                {exceptionRecords.length === 0 && (
                  <div className="nodata">
                    <div>
                      <Image
                        className="norecords"
                        src="ic_no records_v2.svg"
                        height="118px"
                        width="118px"
                        alt=""
                      />
                    </div>
                    <FormattedMessage id="nodata.exceptionrecords" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Loading>
      </div>

      <Modal
        show={customShowGlossary}
        handleClose={toggleModalGlossary}
        wrapperclass={"glossaryModel"}
      >
        <div className="mt-3 mb-4 ml-2 mr-2 position-relative">
          <header className="glossaryHeading ml-4 mr-4">
            <FormattedMessage id="serviceorder.exceptionglossary" />
            <div className="form-input-block py-2 pb-3">
              <input
                className="input-box"
                placeholder="Search by keywords,topics"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </header>
          <span className="position-absolute closebtnglossary">
            <Image
              className="closeicon"
              src="ic_cancel_black.svg"
              height="14px"
              width="20px"
              onClick={onClickOK}
            />
          </span>
          <div className="summarydiv ml-4 mr-4">
            <table className="table table-bordered col-12">
              <tbody className="summaryContent">
                <tr>
                  <th>
                    <FormattedMessage id="exceptionglossary.exceptionName" />
                  </th>
                  <th>
                    <FormattedMessage id="exceptionglossary.description" />
                  </th>
                </tr>
                {searchedGlossary.map((d, i) => (
                  <tr key={i}>
                    <td>{d.exceptionName}</td>
                    <td>{d.explanation}</td>
                  </tr>
                ))}
                {!searchedGlossary[0] && (
                  <tr>
                    <td colSpan="2">
                      <FormattedMessage id="nodata.records" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default ExceptionRecords
