import React, { Fragment, useEffect } from "react"
import Modal from "shared/Modal"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import classnames from "classnames"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Form, Formik } from "formik"
import DatePicker from "react-datepicker"
import Input from "routes/Admin/Components/Input"
import Select from "shared/Select"
import { useSelector } from "react-redux"
import enhancer from "./enhancer"
import { format } from "date-fns"

const PrintLabelModal = (props) => {
  let {
    handlePrintState,
    formik,
    printerOpts,
    wasteTypeOpts,
    isFirstRender,
    mockCall,
  } = props

  const printState = useSelector((state) => state.profileDetails.printStatus)
  const currentProfile = useSelector(
    (state) => state.profileDetails?.currentProfile
  )

  useEffect(() => {
    if (isFirstRender) mockCall()
  }, [])

  const openCalender = () => {}

  return (
    <Fragment>
      <div className="parentDiv">
        <Modal show={printState}>
          <div className="modalPopup p-2">
            <Formik
              enableReinitialize={true}
              initialValues={formik?.values}
              {...formik}
            >
              {({ values, errors, touched, setFieldValue, isSubmitting }) => {
                return (
                  <Form>
                    <Container>
                      <div style={{ marginTop: 5, paddingBottom: 7 }}>
                        <div className="label" style={{ textAlign: "left" }}>
                          <FormattedMessage id="printlabel.heading" />{" "}
                          {currentProfile?.webProfileId}
                        </div>
                      </div>
                      <Row>
                        <Col>
                          <br></br>
                          <div
                            className="form-input-block"
                            style={{ marginBottom: 10 }}
                          >
                            <span className="label">
                              <FormattedMessage id="printlabel.labels" />
                              <span style={{ color: "red", padding: "1px" }}>
                                {"*"}
                              </span>
                            </span>
                            <Input
                              name="labelCount"
                              value={values?.labelCount}
                              inputMode="numeric"
                              pattern="[0-9]*"
                              onFieldChange={(e) => {
                                const numericValue = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                )
                                setFieldValue("labelCount", numericValue)
                              }}
                            />
                            <div
                              style={{
                                color: "red",
                                fontSize: 10,
                                position: "absolute",
                                left: 22,
                                top: 40,
                              }}
                            >
                              {errors.labelCount && touched.labelCount ? (
                                <div>{errors.labelCount}</div>
                              ) : null}
                            </div>
                          </div>
                          <br></br>
                          <div
                            className="form-input-block"
                            style={{ marginBottom: 12 }}
                          >
                            <span className="label">
                              <FormattedMessage id="printlabel.state" />
                            </span>
                            <Input
                              name="state"
                              value={values?.state}
                              onFieldChange={(e) => {
                                setFieldValue("state", e.target.value)
                              }}
                            />
                          </div>
                          <br></br>
                          <div className="form-input-block mni-style">
                            <span className="label pl-0">
                              <FormattedMessage id="printlabel.printerselect" />
                              <span style={{ color: "red", padding: "1px" }}>
                                {"*"}
                              </span>
                            </span>
                            <Select
                              name="printerFlag"
                              labelkey={"l"}
                              label=""
                              className={"sort-dropdown"}
                              options={printerOpts.options}
                              selected={values?.printerFlag}
                              onSelect={(e) => {
                                setFieldValue("printerFlag", e)
                              }}
                            />
                          </div>
                          <div
                            className="print-error"
                            // style={{
                            //   color: "red",
                            //   fontSize: 10,
                            //   paddingTop: 10,
                            // }}
                          >
                            {errors.printerFlag && touched.printerFlag ? (
                              <div>
                                {errors.printerFlag.l || errors.printerFlag.v}
                              </div>
                            ) : null}
                          </div>
                          <div
                            className="form-input-block"
                            style={{ marginTop: 71 }}
                          >
                            <span className="label" style={{ zIndex: 1 }}>
                              <FormattedMessage id="printlabel.customer" />
                            </span>
                            <Input
                              name="customIdNumber"
                              value={values?.customIdNumber}
                              onFieldChange={(e) => {
                                setFieldValue("customIdNumber", e.target.value)
                              }}
                            />
                          </div>
                        </Col>
                        <Col>
                          <br></br>
                          <Row>
                            <div
                              style={{
                                paddingLeft: 19,
                                fontSize: 13,
                                marginTop: 6,
                              }}
                            >
                              <span className="label">
                                <FormattedMessage id="printlabel.address" />
                              </span>
                              <span
                                className="ml-2 radio-container"
                                style={{ paddingLeft: 3 }}
                              >
                                <input
                                  id="radioCustomColor"
                                  type="radio"
                                  name="labelAddress"
                                  value={values?.labelAddress}
                                  checked={
                                    values?.labelAddress === true ? true : false
                                  }
                                  onChange={() => {
                                    setFieldValue("labelAddress", true)
                                  }}
                                />
                              </span>
                              <span
                                className="label"
                                style={{
                                  paddingLeft: 23,
                                  fontSize: 12,
                                  position: "fixed",
                                }}
                              >
                                <FormattedMessage id="printlabel.site" />
                              </span>
                              <span
                                className="ml-2 radio-container"
                                style={{ paddingLeft: 54 }}
                              >
                                <input
                                  id="radioCustomColor"
                                  type="radio"
                                  name="labelAddress"
                                  value={values?.labelAddress}
                                  checked={
                                    values?.labelAddress === false
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    setFieldValue("labelAddress", false)
                                  }}
                                />
                              </span>
                              <span
                                className="label"
                                style={{
                                  paddingLeft: 21,
                                  fontSize: 12,
                                  position: "fixed",
                                }}
                              >
                                <FormattedMessage id="printlabel.mailing" />
                              </span>
                            </div>
                          </Row>
                          <div
                            style={{
                              color: "red",
                              fontSize: 10,
                              paddingTop: 10,
                            }}
                          >
                            {errors.labelAddress && touched.labelAddress ? (
                              <div>{errors.labelAddress}</div>
                            ) : null}
                          </div>
                          <br></br>
                          <div
                            className="form-input-block date-picker-style date-picker"
                            style={{ marginTop: 10 }}
                            onClick={() => openCalender("begining", true)}
                          >
                            <span className="label">
                              <FormattedMessage id="printlabel.accumlation" />
                            </span>
                            <DatePicker
                              className="date-input-box"
                              onChange={(val, event) => {
                                event.stopPropagation()
                                const formattedDate = format(val, "MM/dd/yyyy")
                                setFieldValue(
                                  "accumlationDatetime",
                                  formattedDate
                                )
                              }}
                              minDate={new Date()}
                              dateFormat="MM/dd/yyyy"
                              name="accumlationDatetime"
                              value={
                                values?.accumlationDatetime
                                  ? format(
                                      new Date(values?.accumlationDatetime),
                                      "MM/dd/yyyy"
                                    )
                                  : ""
                              }
                              selected={
                                values?.accumlationDatetime
                                  ? new Date(values?.accumlationDatetime)
                                  : null
                              }
                            />
                          </div>
                          <br></br>
                          <div
                            className="form-input-block mni-style"
                            style={{ zIndex: 0, marginTop: 10 }}
                          >
                            <span className="label pl-0">
                              <FormattedMessage id="printlabel.wasteselect" />
                            </span>
                            <Select
                              name={"wasteTypeFlag"}
                              labelkey={"l"}
                              label=""
                              className={"sort-dropdown"}
                              options={wasteTypeOpts.options}
                              selected={values?.wasteTypeFlag}
                              onSelect={(e) => {
                                setFieldValue("wasteTypeFlag", e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="label-instructions">
                            <p>
                              <FormattedMessage id="printlabel.howtoprint" />
                              <br></br>
                              <FormattedMessage id="printlabel.howtoprintcondition" />
                            </p>
                          </div>
                          <div className="label-instructions-details">
                            <p style={{ padding: 2, margin: 2 }}>
                              <FormattedMessage id="printlabel.instruction" />
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row
                        style={{ position: "absolute", left: 38, bottom: 33 }}
                      >
                        <div className="downloadsec">
                          <button
                            className={classnames("downloadbtn")}
                            style={{
                              background: "#FFFFFF",
                              color: "#337AB7",
                              // marginRight: 15,
                            }}
                            onClick={() => {
                              handlePrintState(false)
                            }}
                          >
                            <FormattedMessage id="profile.cancelbtn" />
                          </button>
                          <button
                            className={classnames("downloadbtn")}
                            style={{ padding: "6px 20px", marginLeft: 10 }}
                            type="submit"
                            // disabled={isSubmitting}
                          >
                            <FormattedMessage id="printlabel.exportpdf" />
                          </button>
                        </div>
                      </Row>
                    </Container>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal>
      </div>
    </Fragment>
  )
}
export default enhancer(PrintLabelModal)
