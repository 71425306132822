import "./style.scss"
import React from "react"
import ExceptionRecords from "./Component/ExceptionRecords"
import History from "./Component/History"
import PendingService from "./Component/PendingService"
import ServiceDetails from "./Component/ServiceDetails"
import ShipmentDetails from "./Component/ShipmentDetails"
import SupportingDocument from "./Component/SupportingDocument"
import WasteComposition from "./Component/WasteComposition"
import enhancer from "./enhancer"
import Loading from "shared/Loading"
import { FormattedMessage } from "react-intl"

function ServiceOrder(props) {
  let { serviceDetails, isStoreManager } = props

  return (
    <div className="row m-0 servicehistory d-flex justify-content-center">
      <Loading id="get_serviceDetails">
        <>
          {serviceDetails.workOrderId && (
            <>
              <div className="col-12 mt-4 mb-2"></div>
              <ServiceDetails {...props} />
              <div className="col-12">
                <div className="row mt-3">
                  <WasteComposition {...props} />
                  <div className="col-4 ">
                    <div className="text-left status mb-2 ">
                      <FormattedMessage id="serviceorder.supportingDoc" />
                    </div>
                    <SupportingDocument {...props} />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row mt-3 m-0">
                  <div className="col-12 p-0">
                    <div className="text-left status mb-2">
                      <FormattedMessage id="serviceorder.documentlines" />
                    </div>
                    <ShipmentDetails {...props} />
                  </div>
                </div>
              </div>
              {!isStoreManager && (
                <div className="col-12">
                  <div className="row mt-3 m-0">
                    <ExceptionRecords {...props} />
                    <History {...props} />
                    <PendingService {...props} />
                  </div>
                </div>
              )}
            </>
          )}
        </>
      </Loading>
    </div>
  )
}
export default enhancer(ServiceOrder)
