import React, { useEffect, useState } from "react"
import { myAlertsOpts, locale, myAlertsOptsCommUser, roles } from "config"
import * as dfns from "date-fns"
import { useHistory } from "react-router-dom"
import api from "api"
import { useToday, formattedDate } from "shared"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
// import { host } from "api/urls"
import { CURRENT_PROFILE, GENERATOR_DETAILS } from "store/reducer/actionType"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    let history = useHistory()
    const dispatch = useDispatch()
    let today = useToday()
    let alertList = useSelector((state) => state?.alerts)
    const profile = useSelector((state) => state.profile)
    let { sortByOpts, filterByOpts } =
      profile?.roleId === roles.CE_COMMERCIAL_USER
        ? myAlertsOptsCommUser
        : myAlertsOpts
    let [filterBy, setFilterBy] = useState(filterByOpts[0])
    let [sortBy, setSortBy] = useState(sortByOpts[0])
    let [alerts, setAlerts] = useState([])
    let [loader, setLoader] = useState(false)

    const alertTypeToAlertText = {
      profile_approved: "myalerts.profileapproved",
      profile_creation: "myalerts.profilecreation",
      profile_resubmit: "myalerts.profileresubmit",
      profile_expiring: "myalerts.profileexpiring",
      generator_creation: "myalerts.generatorcreation",
    }

    // const [alert, setAlert] = useState({})

    // function markAlertReadN(d, i) {
    //   if (d.alertType === "profile_approved") {
    //     d.isRead = true
    //     setAlert({ ...d })
    //   }
    //   if (d.alertType === "profile_creation") {
    //     d.isRead = true
    //     setAlert({ ...d })
    //   }
    //   if (d.alertType === "profile_expiring") {
    //     d.isRead = true
    //     setAlert({ ...d })
    //   }
    //   if (d.alertType === "profile_resubmit") {
    //     d.isRead = true
    //     setAlert({ ...d })
    //   }
    //   //for resolving warning, used alert below
    //   <>{alert}</>
    // }

    const getProfileData = async (reqId, isNonDraft) => {
      let req
      if (isNonDraft) {
        req = {
          profileRequestId: 0,
          webProfileId: "",
          coreProfileNumber: reqId?.split("-")?.[0],
          coreVersionNumber: reqId?.split("-")?.[1]
            ? parseInt(reqId?.split("-")?.[1])
            : 0,
        }
      } else {
        req = {
          profileRequestId: reqId,
        }
      }
      try {
        let res = await api.p_getProfileById(req)
        if (!res) return
        dispatch({ type: CURRENT_PROFILE, payload: res })
      } catch (error) {}
    }

    const getAlertsAll = async () => {
      setLoader(true)
      try {
        let alerts = await api.g_alertsAll({
          dates: [dfns.sub(today, { days: 30 }), today],
          loading_key: "get_alerts",
        })
        if (!alerts) return
        dispatch({ type: "SET_ALERTS", alertList: alerts })
      } catch {
      } finally {
        setLoader(false)
      }
    }

    useEffect(() => {
      getAlertsAll()
    }, [])

    useEffect(() => {
      let alrts = _.cloneDeep(alertList?.alerts)
      // let alrts = _.cloneDeep(alertList)
      if (props.isHeaderAlerts) {
        alrts = alrts?.filter((d) => !d?.isRead)
      }

      alrts &&
        alrts.length > 0 &&
        alrts.forEach((d, i) => {
          let month = dfns.format(new Date(d.alertDate), "MMMM")
          let storesD = [...new Set(d.stores)]
          d.stores = `${storesD.join(",")}`
          d.month = month
          d.dateF = new Date(d.alertDate)
          d.date = formattedDate(d.dateF, today, { locale })
          d.index = i
        })
      alrts =
        alrts &&
        alrts.length > 0 &&
        alrts.sort(function (a, b) {
          return new Date(b.dateF) - new Date(a.dateF)
        })
      setAlerts(alrts || [])
    }, [alertList])

    const goToEditProfile = async (page, id) => {
      let profileId
      if (id.split("").includes("P")) {
        profileId = id.substr(1, id.length - 1)
      } else {
        profileId = id
      }
      await dispatch({ type: GENERATOR_DETAILS, payload: {} })
      getProfileData(profileId, !id?.split("")?.includes("P"))
      history.push(`/profiles/${page}/${profileId?.split("-")?.[0]}`)
    }

    const viewProfile = async (profileId) => {
      goToEditProfile("viewprofile", profileId)
    }

    const viewGenerator = async (alertData) => {
      const pathId = alertData?.stores
      const redirectPath = pathId ? `/generator?id=${pathId}` : "/generator"
      history.push(redirectPath)
    }

    function goToView(d, i) {
      if (d.alertType === "profile_creation") {
        viewProfile(d.alertData)
      }
      if (d.alertType === "generator_creation") {
        viewGenerator(d)
      }
    }

    const onClickAccessSettings = () => {
      history.push(`/alertsettings`)
    }

    const markAlertRead = async (d, index) => {
      if (!d.isRead) {
        index = d?.index
        await api.p_markAlertRead({
          notificationId: d.notificationId,
          // notificationId:
          //   d.alertType === "full_circle"
          //     ? [d.notificationId]
          //     : d.notificationId,
          loading_key: "alert_notification",
        })
        dispatch({ type: "UPDATE_IS_READ_ALERT", index })
      }
    }

    return (
      <Component
        {...props}
        {...{
          filterBy,
          setFilterBy,
          sortBy,
          setSortBy,
          alerts,
          sortByOpts,
          filterByOpts,
          alertTypeToAlertText,
          alertList,
          markAlertRead,
          goToView,
          onClickAccessSettings,
          loader,
        }}
      />
    )
  }

export default enhancer
