import React, { Fragment } from "react"
import enchancer from "../Admin/enhancer"
import "./style.scss"
import Image from "shared/Image"
import { FormattedMessage } from "react-intl"
import { roles } from "config"

const Admin = (props) => {
  const { goToAdminPages, profile } = props
  return (
    <Fragment>
      <div className="user-management">
        <div className="col-sm-6 col-md-6 col-lg-6 mt-4 mb-3">
          <div className="row m-0 admin-home">Administration home</div>
        </div>
        <div className="row m-0 px-4">
          <div
            className="col-sm-6 col-md-6 col-lg-6 mr-5 mt-3 mb-3 tile-box"
            onClick={() => goToAdminPages("createuser")}
          >
            <div className="row m-0">
              <div className="col-sm-2 col-md-2 col-lg-2 ">
                <div className="img">
                  <Image
                    className=""
                    src={"ic_user_management_icon.svg"}
                    height="60px"
                    width="60px"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0 fnt">
                <div className="title">
                  <FormattedMessage id="admintilecreate" />
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-sm-6 col-md-6 col-lg-6 mr-5 mt-3 mb-3 tile-box"
            onClick={() => goToAdminPages("usermanagement")}
          >
            <div className="row m-0">
              <div className="col-sm-2 col-md-2 col-lg-2 ">
                <div className="img">
                  <Image
                    className=""
                    src={"edit_user_icon.svg"}
                    height="60px"
                    width="60px"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0 fnt">
                <div className="title">
                  <FormattedMessage id="admintileedit" />
                </div>
              </div>
            </div>
          </div>
          {profile.roleId === roles.PORTAL_ADMIN && (
            <div
              className="col-sm-6 col-md-6 col-lg-6 mr-5 mt-3 mb-3 tile-box"
              onClick={() => goToAdminPages("manageannouncements")}
            >
              <div className="row m-0">
                <div className="col-sm-2 col-md-2 col-lg-2 ">
                  <div className="img">
                    <Image
                      className=""
                      src={"Manage_Announcements.svg"}
                      height="60px"
                      width="60px"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0 fnt">
                  <div className="title">
                    <FormattedMessage id="admintilemanagement" />
                  </div>
                </div>
              </div>
            </div>
          )}
          {profile.roleId === roles.PORTAL_ADMIN && (
            <div
              className="col-sm-6 col-md-6 col-lg-6 mr-5 mt-3 mb-3 tile-box"
              onClick={() => goToAdminPages("editannouncement")}
            >
              <div className="row m-0">
                <div className="col-sm-2 col-md-2 col-lg-2 ">
                  <div className="img">
                    <Image
                      className=""
                      src={"Announcement_Edit_Icon.svg"}
                      height="60px"
                      width="60px"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-sm-10 col-md-10 col-lg-10 text-left p-0 fnt">
                  <div className="title">
                    <FormattedMessage id="admintilemanagementedit" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default enchancer(Admin)
