import React, { useState, useEffect } from "react"
import api from "api"
import { roles } from "config"
import { useSelector } from "react-redux"

let enhancer = (Component) => (props) => {
  let { filters, resetMyschedule, filterStatus, setFilterStatus } = props
  let [workOrderFilters, setWorkOrderFilters] = useState({
    NoShip: false,
    Shipped: false,
    PartialNoShip: false,
  })
  let [serviceData, setServiceData] = useState([])

  const user = useSelector((state) => state.profile)

  useEffect(() => {
    ;(async () => {
      if (!filters.fiscal_span) return

      if (filterStatus) await fetchSchedulesFilter(filterStatus)
      else await fetchSchedules()
    })()
  }, [filters])

  let fetchSchedules = async (filter = "") => {
    if (!filters.fiscal_span) return
    let n_dates = [filters?.fiscal_span.from, filters?.fiscal_span.to]
    let myscheduleData = {
      scheduleTypes: filters.schedule_type,
      serviceTypes: filters.service_type,
      regions: filters.divisions_regions,
      divisions: filters.brands,
      states: filters.states,
      stores: filters.stores,
    }
    if (user?.roleId == roles.Brand_User) {
      myscheduleData.divisions = []
      myscheduleData.divisions.push(user.division)
    }
    try {
      let scheduleData = await api.p_schedulesSummary({
        dates: n_dates,
        ...myscheduleData,
        workOrderType: filter?.length > 0 ? filter : "",
        loading_key: "my-schedule",
      })
      if (!scheduleData) return
      setServiceData(scheduleData)
    } catch (error) {
      setServiceData([])
    }
  }

  let fetchSchedulesFilter = async (filter, flag = false) => {
    if (filter) {
      let initalValue = {
        NoShip: false,
        Shipped: false,
        PartialNoShip: false,
      }
      setWorkOrderFilters({
        ...initalValue,
        [filter]: !workOrderFilters[filter],
      })
      if (workOrderFilters[filter]) fetchSchedules()
      else fetchSchedules(filter)
    } else {
      fetchSchedules()
    }
  }

  const checkFilter = () => {
    return Object?.entries(workOrderFilters)?.filter((ele) => !!ele[1])
  }

  useEffect(() => {
    setServiceData([])
  }, [resetMyschedule])
  return (
    <Component
      {...props}
      {...{
        serviceData,
        filterStatus,
        workOrderFilters,
        fetchSchedulesFilter,
        checkFilter,
        setFilterStatus,
      }}
    />
  )
}

export default enhancer
