import React, { useState, useEffect, useRef } from "react"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
} from "recharts"
import "./style.scss"
import { useIntl } from "react-intl"
import { useWindowDimension } from "shared"
import colors from "./colors"

const Chart = ({ data, config, height }) => {
  const [width, setWidth] = useState(0)
  const elementRef = useRef(null)

  let dim = useWindowDimension()

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width)
  }, dim)

  let { yUnit = "", isXDay, fat } = config

  const intl = useIntl()

  let formatNumber = (n) => {
    let extra = {}
    let str = ""
    if (yUnit == "$")
      extra = {
        style: "currency",
        currency: "USD",
      }
    else str = yUnit

    return `${intl.formatNumber(n, {
      ...extra,
      notation: "compact",
    })}${str}`
  }

  return (
    <div ref={elementRef} className="bar-chart-wrapper">
      <BarChart
        width={width}
        height={height}
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        barGap={0}
      >
        <XAxis
          dataKey={config.x}
          tickFormatter={(d) => (isXDay ? d.match(/^.{0,3}/, "")[0] : d)}
        />
        <YAxis tickFormatter={formatNumber} domain={config.yDomain} />
        <Tooltip
          formatter={formatNumber}
          labelFormatter={(name) => {
            let tooltip = data.find((d) => d.tooltip?.includes(name))?.tooltip
            return tooltip ? tooltip : name
          }}
        />

        <CartesianGrid />
        <Bar
          dataKey={config.y.key}
          name={config.y.label}
          fill={colors.bar[0]}
          barSize={fat ? 60 : 10}
        />
        {config.z && (
          <Bar
            dataKey={config.z.key}
            name={config.z.label}
            fill={colors.bar[1]}
            barSize={10}
          />
        )}

        <Legend verticalAlign="bottom" iconType="circle" />
      </BarChart>
    </div>
  )
}

export default Chart
