import React, { useEffect, useState } from "react"
import { useField, useFormikContext } from "formik"
import classnames from "classnames"
import Select from "shared/Select"

const Select2 = (props) => {
  let { name, options, short, onselectchanged, resetstore } = props
  const [field, meta, helpers] = useField(name)
  const [val, setVal] = useState(false)
  const { setTouched, setValue } = helpers
  const { isSubmitting } = useFormikContext()

  let { value } = field

  useEffect(() => {
    if (isSubmitting) setTouched(true)
  }, [isSubmitting])
  useEffect(() => {
    if (name === "store") {
      if (resetstore === true) {
        setVal(false)
        onselectchanged(name, false, true)
      }
    }
  }, [resetstore])

  useEffect(() => {
    if (!val && value) {
      let newVal = options.find((d) => d.v == value)
      setVal(newVal)
    }
  }, [value])

  let onSelect = (d) => {
    setVal(d)
    setValue(d.v)
    // setTouched(true)
    if (name === "division" || name === "store") onselectchanged(name, d)
  }

  let hasError = meta.touched && meta.error
  return (
    <div className={classnames("select", { hasError: hasError })}>
      <Select
        {...props}
        onSelect={onSelect}
        selected={val}
        prependLabel={!short}
      />
      {hasError && (
        <div
          className={classnames("error wd-150", {
            "set-top": name.includes("supplies"),
          })}
        >
          {meta.error}
        </div>
      )}
    </div>
  )
}

export default Select2
