// import { useEffect } from "react"
// import { useLocation } from "react-router"
import { REACT_APP_GOOGLE_ANALYTICS_KEY as ANALYTICS_KEY } from "config"
import store from "store"

export const initializeGTag = () => {
  let script = document.createElement("script")
  script.src = `//www.googletagmanager.com/gtag/js?id=${ANALYTICS_KEY}`
  script.async = true
  document.body.appendChild(script)

  let dataLayer = window.dataLayer || []
  function gtag() {
    dataLayer.push(arguments)
  }
  gtag("js", new Date())
  gtag("config", ANALYTICS_KEY, { send_page_view: false })
  window.dataLayer = dataLayer
  window.gtag = gtag
}

export const usePageViews = () => {
  // const location = useLocation()
  // useEffect(() => {
  //   window.gtag("event", "page_view", {
  //     page_path: location.pathname,
  //   })
  // }, [location])
}

export const gtagLogoutEvent = () => {
  let {
    profile: { email },
  } = store.getState()
  if (email.includes("test@")) return

  window.gtag("event", "logout", {
    event_category: "User",
    event_label: "logout",
    user_id: email,
  })
}

export const sendGtagEvent = (eventName) => {
  const {
    profile: { email, customer_name, isStoreLevelUser },
  } = store.getState()
  if (email && !email.includes("test")) {
    const role = isStoreLevelUser ? "StoreManager" : "Admin"
    window.gtag("event", `${customer_name}.${role}.${eventName}`, {
      event_category: "User",
      event_label: `${customer_name}.${role}.${eventName}`,
      user_id: email,
    })
    window.gtag("event", `${email}.userLogged`, {
      event_category: "User",
      event_label: `${email}.${role}.${eventName}`,
      user_id: email,
    })
  }
}
