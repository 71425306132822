import React from "react"
import enhancer from "./enhancer"
import "./style.scss"
import Image from "shared/Image"
import { site } from "config"
import { FormattedMessage } from "react-intl"
import { login_slider_config } from "config"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"

function Login(props) {
  let { loading, onSubmit, onSSoSubmit, error, onChange, isSSOLoginButton } =
    props
  const array = Array.from(Array(11).keys())

  if (loading) {
    return (
      <div className="loader-parent">
        <div className="loader"></div>
      </div>
    )
  }
  return (
    <div className="login-section">
      <div className="row">
        <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
          <Slider {...login_slider_config}>
            {array.map((i) => (
              <img
                src={`/carousel/${i}.jpg`}
                className="img-carousel"
                key={i}
              />
            ))}
          </Slider>
        </div>
        <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 mt-5">
          <div className="ligo-img mb-2 mt-5">
            <Image className="loginLogo" src="logo_white.svg" alt="" />
          </div>
          <div className="loginText col-9 pb-2">
            <FormattedMessage id="login.onesource" />
          </div>
          <div className="my-3">
            <Image className="connectlogo" src="Connect_white.svg" />
          </div>
          <div className="login-card-block">
            <div className="login-card">
              {isSSOLoginButton && (
                <form onSubmit={onSubmit}>
                  <div className="login-field-error">
                    {error && (
                      <span className="field-error font-large">{`Please enter valid email `}</span>
                    )}
                  </div>
                  <div className="p-r-1">
                    <input
                      name="email"
                      className="email-style"
                      placeholder="Enter Your Email"
                      onChange={(e) => onChange(e.target.value)}
                      required
                    />
                  </div>
                  <div className="card-body py-0">
                    <button type="submit" className="green-button">
                      <span className="login">
                        <FormattedMessage id="login.loginWithPersonalAcc" />
                      </span>
                    </button>
                  </div>
                </form>
              )}
            </div>
            {/* Commented for future reference */}
            {/* <div className="divider mt-3">OR</div> */}
            {/* <div className="login-card">
              <div className="card-body py-1">
                <button className="green-button" onClick={signInB2B}>
                  <span className="login">
                    <FormattedMessage id="login.loginWithCorporateAcc" />
                  </span>
                </button>
              </div>
            </div> */}
          </div>
          {!isSSOLoginButton && (
            <form onSubmit={onSSoSubmit}>
              <div className="card-body py-0">
                <button type="submit" className="green-button">
                  <span className="login">
                    <FormattedMessage id="login.loginWithSSOAcc" />
                  </span>
                </button>
              </div>
            </form>
          )}
          <div className="assistcall mt-5">
            For assistance <b> Call us </b> at {site.number}
          </div>
        </div>
      </div>

      {loading}
    </div>
  )
}
export default enhancer(Login)
