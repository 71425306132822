import React from "react"
import enhancer from "./enhancer"
import Loading from "shared/Loading"
import { FormattedMessage } from "react-intl"
import "./style.scss"
import Image from "shared/Image"
import classnames from "classnames"
import { alerts, alertsCOMMUser } from "./constants"
import { roles } from "config"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

function AlertsSettings(props) {
  let {
    onCloseSuccess,
    success,
    // alerts_preference,
    allEmailChecked,
    allNotificationsChecked,
    updateSendMeAllUpdate,
    onClickSavePreferences,
    data,
    onSubmit,
    cancelAction,
  } = props
  let history = useHistory()
  const profile = useSelector((state) => state.profile)
  const isCECommercialUser = profile?.roleId == roles?.CE_COMMERCIAL_USER
  const alertsSource = isCECommercialUser ? alertsCOMMUser : alerts

  // const alerts_preferences = JSON.parse(alerts_preference)

  const getProfileLabel = (data, alerts) => {
    if (!isCECommercialUser) {
      switch (data) {
        case alerts[0]:
          return <FormattedMessage id="alertview.profileexpiring" />
        case alerts[1]:
          return <FormattedMessage id="alertview.profilecreation" />
        case alerts[2]:
          return <FormattedMessage id="alertview.profileapproved" />
        case alerts[3]:
          return <FormattedMessage id="alertview.generatorcreation" />
        default:
          return
      }
    }
    return <FormattedMessage id="alertview.generatorcreation" />
  }

  const getProfileSubLabel = (data, alerts) => {
    if (!isCECommercialUser) {
      switch (data) {
        case alerts[0]:
          return <FormattedMessage id="alertview.profileexpiring.subtext" />
        case alerts[1]:
          return <FormattedMessage id="alertview.profilecreation.subtext" />
        case alerts[2]:
          return <FormattedMessage id="alertview.profileapproved.subtext" />
        case alerts[3]:
          return <FormattedMessage id="alertview.generatorcreate.subtext" />
        default:
          return
      }
    }
    return <FormattedMessage id="alertview.generatorcreate.subtext" />
  }

  return (
    <div className="row m-0">
      <div className="col-12  p-0">
        <Loading id="accesssettings">
          <div className="mt-2 mb-4 text-left alertsettings">
            <div className="heading">
              <span
                className="alerts c-pointer"
                onClick={() => history.push("/myalerts")}
              >
                <FormattedMessage id="back" />
              </span>{" "}
              | <span className="myalerts">ALERTS</span>
            </div>
          </div>
          <div className="text-left alertsettings">
            <div
              className="text-left myalertsection"
              style={{
                fontSize: "1.25rem",
              }}
            >
              <FormattedMessage id="alertview.alertsSettings" />
            </div>
            <div className={`row successful ${!success ? "d-none" : ""}`}>
              <div className="tick col-1 pl-4">
                <Image
                  className="ticks"
                  src="ic_whitetick.svg"
                  height="30px"
                  width="30px"
                  alt=""
                />
              </div>
              <div className="text col-10 pl-0">
                Your alert preferences have been saved successfully!
              </div>

              <div className="closes col-1 pr-0 text-right pl-5">
                <Image
                  className="closeds"
                  src="ic_close_white.svg"
                  height="14px"
                  width="14px"
                  alt=""
                  onClick={() => onCloseSuccess()}
                />
              </div>
            </div>
            <div>
              <div className="d-flex mb-3">
                <div className="pr-5 mr-2 text-left">
                  <span className="switchlabel" style={{ fontSize: 14 }}>
                    <FormattedMessage id="alertview.email" />
                  </span>
                </div>
                <div className="pr-5 mr-2 text-left">
                  <span className="switchlabel" style={{ fontSize: 14 }}>
                    <FormattedMessage id="alertview.notifications" />
                  </span>
                </div>
              </div>
              <div className="d-flex">
                <div className="mr-4">
                  <label className="switch ml-2">
                    <input
                      type="checkbox"
                      checked={allEmailChecked}
                      onChange={(e) => updateSendMeAllUpdate(e, "email")}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="ml-3">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={allNotificationsChecked}
                      onChange={(e) => updateSendMeAllUpdate(e, "notification")}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div>
                <div>
                  {alertsSource?.map((val, i) => {
                    return (
                      <div key={i} className="my-2 d-flex">
                        <div className="ml-3 pr-5 mr-4 text-left">
                          <label
                            className={classnames("check-container", {
                              "c_gray ": data?.[val]?.email,
                            })}
                          >
                            <input
                              type="checkbox"
                              onChange={() => {
                                //e.preventDefault()
                                onClickSavePreferences(val, "email")
                              }}
                              checked={data?.[val]?.email}
                              value={data?.[val]?.email}
                            />
                            <span className={classnames("checkmark")} />
                          </label>
                        </div>
                        <div className="pr-5 ml-1 text-left">
                          <label
                            className={classnames("check-container", {
                              "c_gray ": data?.[val]?.notification,
                            })}
                          >
                            <input
                              type="checkbox"
                              onChange={() =>
                                onClickSavePreferences(val, "notification")
                              }
                              checked={data?.[val]?.notification}
                              value={data?.[val]?.notification}
                            />
                            <span className={classnames("checkmark")} />
                          </label>
                        </div>
                        <div
                          className="text-left"
                          style={{ fontSize: 13, marginTop: "-2px" }}
                        >
                          <div>
                            <span>{getProfileLabel(val, alertsSource)}</span>
                          </div>
                          <div>
                            <span style={{ lineHeight: 2 }}>
                              {getProfileSubLabel(val, alertsSource)}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 new-row-block mt-4 pt-4">
            <div
              className="col-sm-10 col-md-12 col-lg-12 text-left pr-0 mt-2 ml-4"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="row m-0 float-right m-r-2r">
                <button
                  type="button"
                  className={`green-button`}
                  onClick={() => onSubmit()}
                >
                  Save preferences
                </button>
              </div>
            </div>
            <div
              className="col-sm-10 col-md-12 col-lg-12 text-left pr-0 mt-2 ml-4"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="row ml-2 mt-3 float-right m-r-2r"
                style={{
                  color: "#000",
                  cursor: "pointer",
                  fontFamily: "OpenSansSemiBold",
                  textTransform: "uppercase",
                  fontSize: "0.75rem",
                  textDecoration: "underline",
                }}
                onClick={() => cancelAction()}
              >
                Reset to default
              </div>
            </div>
          </div>
        </Loading>
      </div>
    </div>
  )
}
export default enhancer(AlertsSettings)
