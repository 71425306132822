import React from "react"
import Image from "shared/HelpImage/index"

function ServiceDetails() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Service Details</h3>
      <p className="text-justify pl-3 pt-2">
        From the My orders overview screen you can press on
        <button
          style={{ marginRight: "0px" }}
          className="service-history"
        >{`SERVICE DETAILS`}</button>{" "}
        to open a new tab in your browser to see more detail on the order you
        selected from the list. Detail provided includes Waste Composition,
        Individual manifest lines, links to any supporting documents for the
        order, any exception records noted for the order and the Service History
        and pending orders for the location..
      </p>
      <p className="text-justify pl-3 pt-2">
        When you are done viewing the detail you can close this tab in your
        browser.
      </p>
      <div className="img-section pt-2">
        <Image
          className=""
          src="service_order_details_page.png"
          alt="Work Order Image"
        />
      </div>
    </div>
  )
}

export default ServiceDetails
