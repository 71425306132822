import React from "react"
import api from "api"
import { toast } from "react-toastify"
import { useIntl } from "react-intl"
import { syncUserProfile } from "shared"

let enhancer = (Component) => (props) => {
  const { formatMessage } = useIntl()

  const onAcceptClick = async () => {
    const res = await api.pa_acceptTerms({
      loading_key: "terms-conditions",
    })
    if (res) syncUserProfile("terms-conditions")
  }

  const onDeclineClick = () => {
    toast.error(formatMessage({ id: "agreement.declineMsg" }))
  }

  return (
    <Component
      {...{ props }}
      {...{
        onAcceptClick,
        onDeclineClick,
      }}
    />
  )
}

export default enhancer
