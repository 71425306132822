import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import { FormattedMessage, FormattedNumber } from "react-intl"
import _ from "lodash"
import Pagination from "shared/Pagination"
import Loading from "shared/Loading"
import Image from "shared/Image"
import Map from "routes/MyOrders/CorporateAdmin/Component/Services/Components/Map"
import Select from "../../../Admin/Components/Select"
import { isHomeDepot, isSiteUserSpecific } from "shared"

function StoreManager(props) {
  let {
    historyData,
    pageNo,
    gotoPage,
    serviceHistory,
    storeData,
    pendingservices,
    lastServiceDetails,
    onSelectField,
    onSelectValue,
    handleSearchChange,
    open,
    generator,
    store,
  } = props
  const totalRecords = historyData?.totalRecords

  return (
    <Fragment>
      <div className="store-dashboard text-left">
        <Loading id="store_dashboard">
          <div>
            <div className="dp-gene">
              <span className="bold my-2 text-baseRegular">HOME</span>

              <div className="col-sm-3 col-md-3 col-lg-3 text-left  pd-l-16">
                <div className="row m-0">
                  <div className="col-sm-10 col-md-12 col-lg-12 text-left p-0">
                    {!isSiteUserSpecific() && (
                      <div className="form-input-block">
                        <Select
                          name="generate"
                          onSelectField={onSelectField}
                          open={open}
                          search={true}
                          placeholder={"Select Generator"}
                          options={generator}
                          onSelectValue={onSelectValue}
                          value={store}
                          handleSearchChange={handleSearchChange}
                          loadingId={"generator_search"}
                          searchField={"generatorSearch"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!_.isEmpty(storeData) ? (
              <>
                <div className="d-flex p-3 addrs-sec card-border">
                  <h3 className="store-num bold mb-2 px-3 pt-1">
                    {storeData.storeNumber}
                  </h3>
                  <div className="storeNum-pipe pl-4">
                    <div className="fs-small">EPA ID: {storeData.epaId}</div>
                    <div className="fs-14">{storeData?.address}</div>
                  </div>
                </div>
              </>
            ) : (
              <div className="loader-parent loderHeight">
                <div className="loader loaderTop"></div>
              </div>
            )}
          </div>
          <div>
            <div className="bold my-2 pt-2 text-baseRegular">
              SERVICE DETAILS
            </div>
            <div className="bg-gray card-border">
              <div className="p-3 d-flex">
                <div>
                  <div className="service-dt-block">
                    <div className="bold fs-14 service_dt fs-16">
                      <div className="p-2">
                        <FormattedMessage id="dashboard.lastservicedate" />{" "}
                        {lastServiceDetails?.serviceDatetime}
                      </div>
                      <div className="p-2">
                        {lastServiceDetails?.serviceTypes?.map(
                          (last, index) => (
                            <span key={last}>
                              {` ${last} `}{" "}
                              {index ===
                              lastServiceDetails.serviceTypes.length - 1
                                ? ""
                                : "|"}
                            </span>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  {isHomeDepot() && (
                    <div>
                      <p className="homeDepotServiceNoteText">
                        <b>Service Note:</b> Please send any requests relating
                        to your next service date to:{" "}
                        <a href="mailto:thehomedepot@cleanearthinc.com">
                          <FormattedMessage id="mystoreorder.homedepotmail" />
                        </a>
                      </p>
                    </div>
                  )}
                </div>
                <div className="col-7 text-left ml-3">
                  <div className="row bold mb-1 text-baseRegular">
                    <div className="col-4">
                      <FormattedMessage id="dashboard.servicetype" />
                    </div>
                    <div className="col-4">
                      <FormattedMessage id="dashboard.servicefrequency" />
                    </div>
                    <div className="col-4">
                      <FormattedMessage id="dashboard.nextservicedate" />
                    </div>
                  </div>
                  {pendingservices?.map((s, i) => (
                    <div className="row text-left fs-14 py-1" key={i}>
                      <div className="col-4">{s?.serviceType}</div>
                      <div className="col-4">
                        <FormattedNumber value={s?.serviceFrequnecy} /> Days
                      </div>
                      <div className="col-4">{s?.scheduleDate || "NA"}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="row mt-4">
              <div className="col-6">
                <div className="bold text-baseRegular">SERVICE HISTORY</div>
                <div className="bg-gray p-3 mt-2 map-section card-border">
                  <Loading id="service_history">
                    <div className="row bold text-baseRegular mb-1">
                      <div className="col-4">NUMBER</div>
                      <div className="col-4">DATE</div>
                      <div className="col-4">STATUS</div>
                    </div>
                    {historyData?.items?.map((s, i) => (
                      <div className="row py-1 fs-14" key={i}>
                        <div className="col-4">
                          <u
                            className="c-black pointer"
                            onClick={() => serviceHistory(s.workOrderAzid)}
                          >
                            {s.workOrderId}
                          </u>
                        </div>
                        <div className="col-4">{s.serviceDatetime}</div>
                        <div className="col-4">{s.status}</div>
                      </div>
                    ))}
                    {_.isEmpty(historyData?.items) && (
                      <div className="col-12 text-center nodata pt-2">
                        <FormattedMessage id="nodata.serviceHistory" />
                      </div>
                    )}
                    {totalRecords > 0 && (
                      <>
                        <hr className="m-0 mt-3" />
                        <Pagination
                          {...{
                            pageNo,
                            totalRecords,
                            gotoPage,
                            pageSize: 5,
                            hastrue: true,
                          }}
                        />
                      </>
                    )}
                  </Loading>
                </div>
              </div>
              <div className="col-6">
                <div className="bold text-baseRegular">STORE ADDRESS</div>
                <div className="bg-gray p-2 map-section fs-14 mt-2 card-border">
                  <div className="icon-address">
                    <span className="landmark-icon">
                      <Image
                        src="ic_location.svg"
                        height="12px"
                        width="8px"
                        alt=""
                      />
                    </span>
                    <label className="store-address pl-2">
                      {storeData.address}
                    </label>
                  </div>
                  <div className="p-1" style={{ height: "90%" }}>
                    <Map
                      address={storeData.address}
                      height={"12rem"}
                      zoomControl={true}
                      panControl={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Loading>
      </div>
    </Fragment>
  )
}

export default enhancer(StoreManager)
