import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import Image from "shared/Image"
import { FormattedMessage } from "react-intl"
import { useIsTablet } from "shared"

function ListService(props) {
  let { onNewScheduleRequestClick } = props
  let isTablet = useIsTablet()

  let Spacer = () => (
    <div
      className={`${
        isTablet
          ? "col-sm-12 col-md-12 col-lg-12 text-left mt-4 mb-3 "
          : "col-sm-1 col-md-1 col-lg-1"
      }`}
    ></div>
  )
  let Cell = ({ id }) => (
    <div
      className={`${
        isTablet
          ? "col-sm-12 col-md-12 col-lg-12 text-left p-0 ml-4 request-title-block eachrow "
          : "col-sm-5 col-md-5 col-lg-5 text-left p-0 ml-4 request-title-block"
      }`}
      onClick={() => {
        onNewScheduleRequestClick(id)
      }}
    >
      <div className="row m-0">
        <div className="col-sm-2 p-0 circle-block text-center">
          <div className="circle-icon">
            <span className="icon-img">
              <Image
                className=""
                src={
                  id == "report_issue"
                    ? "ic_serviceissue.svg"
                    : id == "change_request"
                    ? "ic_changerequest.svg"
                    : id == "new_schedule_request"
                    ? "ic_newschedulerequest.svg"
                    : id == "request_supplies"
                    ? "ic_requestsupplies.svg"
                    : ""
                }
                height="60px"
                width="60px"
                alt=""
              />
            </span>
          </div>
        </div>

        <div className="col-sm-9 col-md-9 col-lg-9 text-left p-0 request-title">
          <div className="service-title">
            <FormattedMessage id={`requestservice.${id}`} />
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <Fragment>
      <div className=" requestServiceBlock">
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left mt-4 mb-2">
          <div className="m-0 labelgenerator">
            <FormattedMessage id="requestservice.title" />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 requestServiceTitle text-left">
          <div className="m-0 sub-title">
            <FormattedMessage id="requestservice.subtitle" />
          </div>
        </div>
        <div className="row m-0 px-0 new-row no-leftpadding">
          <Cell id="new_schedule_request" />
          <Spacer />
          <Cell id="change_request" />
        </div>
        <div className="row m-0 px-0 new-row no-leftpadding">
          <Cell id="report_issue" />
          <Spacer />
          <Cell id="request_supplies" />
        </div>
      </div>
    </Fragment>
  )
}
export default enhancer(ListService)
