import React, { Fragment, useState } from "react"
import { useSelector } from "react-redux"
import { toolbarPlugin } from "@react-pdf-viewer/toolbar"
import { printPlugin } from "@react-pdf-viewer/print"
import { getFilePlugin } from "@react-pdf-viewer/get-file"
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core"
import "@react-pdf-viewer/core/lib/styles/index.css"
import "@react-pdf-viewer/toolbar/lib/styles/index.css"
import "@react-pdf-viewer/print/lib/styles/index.css"
import { useHistory } from "react-router-dom"
import enhancer from "./enhancer"
import GeneratePdf from "./GeneratePdf"
import classnames from "classnames"

const Pdfviewer = (props) => {
  let { handlePrintState, loading } = props
  // const [pdfUrl, setPdfUrl] = useState(null)
  // const [pdfBytes, setPdfBytes] = useState(null)
  const history = useHistory()

  const printLabel = useSelector((state) => state.profileDetails.printLabel)
  const printState = useSelector((state) => state.profileDetails.printStatus)

  // useEffect(() => {
  //   const generatePDF = async (json) => {
  //     try {
  //       const pdfDoc = await PDFDocument.create()
  //       const page = pdfDoc.addPage()
  //       const { width, height } = page.getSize()
  //       let yPosition = height - 20

  //       Object.keys(json).forEach((key) => {
  //         page.drawText(`${key}: ${json[key]}`, { x: 10, y: yPosition })
  //         yPosition -= 20
  //       })

  //       const bytes = await pdfDoc.save()
  //       setPdfBytes(bytes)
  //     } catch (error) {
  //       console.error("Error generating PDF:", error)
  //     }
  //   }

  // if (printLabel && printLabel.length > 0) {
  // generatePDF(printLabel);
  // }
  // }, [printLabel])

  // useEffect(() => {
  //   if (pdfBytes) {
  //     const file = new Blob([pdfBytes], { type: "application/pdf" })
  //     const url = URL.createObjectURL(file)
  //     setPdfUrl(url)
  //   }
  // }, [pdfBytes])

  const toolbarPluginInstance = toolbarPlugin()
  const { Toolbar } = toolbarPluginInstance
  const printPluginInstance = printPlugin()
  const getFilePluginInstance = getFilePlugin()

  const [pdfData, setPdfData] = useState(null)

  let profileId = printLabel?.profileId
  profileId = profileId?.split("-")[0]

  const pdfNameWithProfileId = (pdfData, fileName) => {
    const pdfBlob = new Blob([pdfData], { type: "application/pdf" })
    const downloadLink = document.createElement("a")
    downloadLink.href = URL.createObjectURL(pdfBlob)
    downloadLink.download = fileName
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }
  const handleCustomDownload = () => {
    pdfNameWithProfileId(pdfData, `profile${profileId}.pdf`)
  }

  return (
    <Fragment>
      {printState ? (
        loading ? (
          <div className="">
            <div className="loader"></div>
          </div>
        ) : (
          <div
            className="print-label"
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                padding: "1rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            ></div>
            {/* <div style={{ flex: 1, overflow: 'auto' }}> */}
            <div className="pdf-viewer-container" style={{ height: "750px" }}>
              <GeneratePdf setPdfData={setPdfData} jsonData={printLabel} />
              {/* {pdfUrl && ( */}
              {pdfData && (
                // <div className="pdf-viewer-container" style={{ height: '750px' }}>
                <Worker
                  workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      backgroundColor: "#080808cf",
                    }}
                  >
                    {/* <div style={{ padding: '0 2rem' }}><button onClick={() => history.push('/profiles')}>Back</button> */}
                    {/* <div style={{ padding: "0 2rem" }}>
                    <button
                      onClick={() => {
                        handlePrintState(false)
                      }}
                    >
                      Back
                    </button> */}
                    <div className="downloadsec" style={{ padding: 10 }}>
                      <button
                        className={classnames("downloadbtn")}
                        style={{
                          float: "left",
                          background: "#FFFFFF",
                          color: "black",
                          borderColor: "black",
                        }}
                        onClick={() => {
                          handlePrintState(false)
                        }}
                      >
                        Back
                      </button>
                      <Toolbar>
                        {({ ZoomIn, ZoomOut, Print, Download }) => (
                          <>
                            <ZoomOut />
                            <ZoomIn />
                            <Print />
                            {/* <Download /> */}
                            <button
                              title="Download"
                              aria-label="Download"
                              className="rpv-core__minimal-button"
                              type="button"
                              data-testid="get-file__download-button"
                              onClick={handleCustomDownload}
                            >
                              <svg
                                aria-hidden="true"
                                className="rpv-core__icon"
                                focusable="false"
                                height="16px"
                                viewBox="0 0 24 24"
                                width="16px"
                              >
                                <path d="M5.5,11.5c-.275,0-.341.159-.146.354l6.292,6.293a.5.5,0,0,0,.709,0l6.311-6.275c.2-.193.13-.353-.145-.355L15.5,11.5V1.5a1,1,0,0,0-1-1h-5a1,1,0,0,0-1,1V11a.5.5,0,0,1-.5.5Z"></path>
                                <path d="M23.5,18.5v4a1,1,0,0,1-1,1H1.5a1,1,0,0,1-1-1v-4"></path>
                              </svg>
                            </button>
                          </>
                        )}
                      </Toolbar>
                    </div>
                    <Viewer
                      // fileUrl={pdfUrl}
                      fileUrl={URL.createObjectURL(pdfData)}
                      plugins={[
                        toolbarPluginInstance,
                        printPluginInstance,
                        getFilePluginInstance,
                      ]}
                      defaultScale={SpecialZoomLevel.PageWidth}
                    />
                  </div>
                </Worker>
              )}
            </div>
          </div>
        )
      ) : (
        history.push("/profiles")
      )}
    </Fragment>
  )
}

export default enhancer(Pdfviewer)
