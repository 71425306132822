import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import Filter from "shared/Filter"
import Services from "./Component/Services"

function Myschedule(props) {
  let { updateFilters, scheduleMode, reset } = props
  return (
    <Fragment>
      <div className="">
        <Filter onSubmit={updateFilters} Onreset={reset} />
        <div className="col-12 myschedules  mb-5">
          {!scheduleMode && <Services {...props} />}
        </div>
      </div>
    </Fragment>
  )
}
export default enhancer(Myschedule)
