import React, { useState, useEffect } from "react"
import * as dfns from "date-fns"
import api from "api"
import { useHistory } from "react-router-dom"
import {
  fixMissingDays,
  fixMissingDaysOrDates,
  monthNames,
  checkArrayHasDate,
  getDate,
  // , formattedDate
} from "shared"
import { useToday } from "shared"
import { useSelector, useDispatch } from "react-redux"
import { TotalSpendHideIDS, roles } from "config"
import _ from "lodash"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    let [activeState, setActiveState] = useState("")
    let [showEstimatedtotalspent, setshowEstimatedtotalspent] = useState(true)
    let [overViewData, setOverViewData] = useState(false)
    let history = useHistory()
    const profile = useSelector((state) => state.profile)
    const showTotalSpend = !TotalSpendHideIDS.includes(profile?.email)

    const dispatch = useDispatch()

    let today = useToday()

    let startOfWeek = dfns.startOfWeek(today)
    let endOfWeek = dfns.endOfWeek(today)

    const overviewTypesD = {
      weekly: {
        active: true,
        fromDate: getDate(startOfWeek, -7),
        toDate: getDate(endOfWeek),
        currentFromDate: getDate(startOfWeek),
        currentToDate: getDate(endOfWeek),
        previousFromDate: getDate(startOfWeek, -7),
        previousToDate: getDate(startOfWeek, -1),
        label: "weekly",
        legends: [
          { key: "cur", label: "Current Week" },
          { key: "prev", label: "Previous Week" },
        ],
        chartLabel: "week",
        xKey: "day",
        dashboardHeader: "dashboard.weekly",
      },
      last30Days: {
        active: false,
        fromDate: getDate(today, -59),
        toDate: getDate(today),
        currentFromDate: getDate(today, -29),
        currentToDate: getDate(today),
        previousFromDate: getDate(today, -59),
        previousToDate: getDate(today, -30),
        label: "last30Days",
        legends: [
          { key: "cur", label: "Last 30 days" },
          { key: "prev", label: "Previous Last 30 days" },
        ],
        chartLabel: "last 30 days",
        xKey: "date",
        dashboardHeader: "dashboard.30days",
      },
      last90Days: {
        active: false,
        fromDate: getDate(today, -179),
        toDate: getDate(today),
        currentFromDate: getDate(today, -89),
        currentToDate: getDate(today),
        previousFromDate: getDate(today, -179),
        previousToDate: getDate(today, -90),
        label: "last90Days",
        legends: [
          { key: "cur", label: "Last 90 days" },
          { key: "prev", label: "Previous Last 90 days" },
        ],
        chartLabel: "last 90 days",
        xKey: "date",
        dashboardHeader: "dashboard.90days",
      },
    }

    const [overviewTypes, setOverviewDuration] = useState(overviewTypesD)
    const [activeDuration, setActiveDuration] = useState(overviewTypesD.weekly)

    let changeActiveOverview = (overviewD) => {
      let overviewTypesN = { ...overviewTypes }
      if (overviewD !== "weekly") setActiveState("")
      // eslint-disable-next-line
      for (let key in overviewTypesN) {
        overviewTypesN[key]["active"] = false
        if (
          overviewTypesN.hasOwnProperty(key) &&
          key.toLowerCase() === overviewD.toLowerCase()
        ) {
          overviewTypesN[key]["active"] = true
          setActiveDuration(overviewTypesN[key])
        }
      }
      setOverviewDuration(overviewTypesN)
    }

    /**
     *  This method is used extract sub set from date and to date in given list
     * */
    const getListByDate = (list, key, fromDate, toDate) => {
      return list.filter(
        (ele) =>
          dfns.format(new Date(fromDate), "yyyy-MM-dd") <=
            dfns.format(new Date(ele?.date), "yyyy-MM-dd") &&
          dfns.format(new Date(toDate), "yyyy-MM-dd") >=
            dfns.format(new Date(ele?.date), "yyyy-MM-dd")
      )
    }

    /**
     * This is used to calculate trends for  different time metrics
     */
    const calculateTrend = (list, key) => {
      let total = 0,
        previousTotal = 0,
        currentTotal = 0,
        previousList = [],
        currentList = []

      previousList = getListByDate(
        list,
        key,
        activeDuration.previousFromDate,
        activeDuration.previousToDate
      )
      currentList = getListByDate(
        list,
        key,
        activeDuration.currentFromDate,
        activeDuration.currentToDate
      )

      previousList.forEach((ele) => (previousTotal = previousTotal + ele[key]))
      currentList.forEach((ele) => {
        currentTotal = currentTotal + ele[key]
      })

      total = currentTotal

      let variance = currentTotal - previousTotal
      let trend = variance < 0 ? "Down" : "Up"

      let percChange =
        previousTotal === 0 ? 0 : (variance / previousTotal) * 100
      return {
        previousTotal,
        currentTotal,
        previousList,
        currentList,
        total,
        trend,
        percChange,
      }
    }

    /**
     * This is used to calculate trends for Stop Completed different time metrics
     */
    const calculateTrendStopsCompleted = (list, key, previousL) => {
      let total = 0,
        previousTotal = 0,
        currentTotal = 0,
        previousPercentageTotal = 0,
        currentPencentageTotal = 0,
        previousListPercentage = [],
        currentListPercentage = [],
        previousList = [],
        currentList = []

      previousListPercentage = getListByDate(
        previousL,
        key,
        activeDuration.currentFromDate,
        activeDuration.currentToDate
      )
      currentListPercentage = getListByDate(
        list,
        key,
        activeDuration.currentFromDate,
        activeDuration.currentToDate
      )

      previousList = getListByDate(
        list,
        key,
        activeDuration.previousFromDate,
        activeDuration.previousToDate
      )
      currentList = getListByDate(
        list,
        key,
        activeDuration.currentFromDate,
        activeDuration.currentToDate
      )

      previousList.forEach((ele) => (previousTotal = previousTotal + ele[key]))
      currentList.forEach((ele) => (currentTotal = currentTotal + ele[key]))

      previousListPercentage.forEach(
        (ele) => (previousPercentageTotal = previousPercentageTotal + ele[key])
      )
      currentListPercentage.forEach(
        (ele) => (currentPencentageTotal = currentPencentageTotal + ele[key])
      )

      total = currentPencentageTotal

      let trend = "Completed"
      let percChange =
        previousPercentageTotal === 0
          ? 0
          : (currentPencentageTotal / previousPercentageTotal) * 100
      return {
        previousTotal,
        currentTotal,
        previousList,
        currentList,
        total,
        trend,
        percChange,
      }
    }

    useEffect(() => {
      if (profile.roleId === roles.Brand_User) {
        setshowEstimatedtotalspent(false)
      } else {
        setshowEstimatedtotalspent(true)
      }
    }, [])
    useEffect(() => {
      ;(async () => {
        let vKeys = {
          weightPickedUp: "weight",
          totalSpends: "spends",
          scheduledStops: "serviceStops",
          stopsCompleted: "serviceStops",
        }

        let n_dates = [activeDuration?.fromDate, activeDuration?.toDate]

        dispatch({
          type: "SET_LOADING_STATUS",
          loading_key: "weekly-overview",
          status: true,
        })
        try {
          let [weightPickedUp, totalSpends, scheduledStops, stopsCompleted] =
            await Promise.all([
              api.g_weightPickedUp({
                dates: n_dates,
              }),
              api.g_totalSpends({
                dates: n_dates,
              }),
              api.g_scheduled_Stops({
                dates: n_dates,
              }),
              api.g_stops_Completed({
                dates: n_dates,
              }),
            ])
          dispatch({
            type: "SET_LOADING_STATUS",
            loading_key: "weekly-overview",
            status: false,
          })

          let ovData = calculateTrend(
            weightPickedUp["week"],
            vKeys["weightPickedUp"]
          )

          let totalSpendsStats = calculateTrend(
            totalSpends["week"],
            vKeys["totalSpends"]
          )

          let scheduledStopsStats = calculateTrend(
            scheduledStops["week"],
            vKeys["scheduledStops"]
          )

          let stopsCompletedStats = calculateTrendStopsCompleted(
            stopsCompleted["week"],
            vKeys["stopsCompleted"],
            scheduledStops["week"]
          )

          if (!(activeDuration.label == "weekly")) {
            //weight picked up data
            let resWeightPickedUp = await getTilesData(
              ovData,
              vKeys.weightPickedUp
            )
            let resTotalSpendsStats = await getTilesData(
              totalSpendsStats,
              vKeys.totalSpends
            )
            let resScheduled_Stops = await getTilesData(
              scheduledStopsStats,
              vKeys.scheduledStops
            )
            let resStops_Completed = await getTilesData(
              stopsCompletedStats,
              vKeys.stopsCompleted
            )

            let da = {
              weightPickedUp: resWeightPickedUp,
              totalSpends: resTotalSpendsStats,
              serviceStops: resScheduled_Stops,
              stopsCompleted: resStops_Completed,
            }
            setOverViewData(da)
          } else {
            let resWeeklyWeightPickedUp = await getWeeklyTilesData(
              ovData,
              vKeys.weightPickedUp
            )
            let resWeeklyTotalSpendsStats = await getWeeklyTilesData(
              totalSpendsStats,
              vKeys.totalSpends
            )
            let resWeeklyScheduled_Stops = await getWeeklyTilesData(
              scheduledStopsStats,
              vKeys.scheduledStops
            )
            let resWeeklyStops_Completed = await getWeeklyTilesData(
              stopsCompletedStats,
              vKeys.stopsCompleted
            )

            let ovDataN = {
              weightPickedUp: resWeeklyWeightPickedUp,
              totalSpends: resWeeklyTotalSpendsStats,
              serviceStops: resWeeklyScheduled_Stops,
              stopsCompleted: resWeeklyStops_Completed,
            }
            setOverViewData(ovDataN)
          }
        } catch (err) {
          dispatch({
            type: "SET_LOADING_STATUS",
            loading_key: "weekly-overview",
            status: false,
          })
        }
      })()
    }, [overviewTypes])

    let getWeeklyTilesData = async (ovDataN, vKeys) => {
      if (!ovDataN) ovDataN = { perc: 0, total: 0 }
      let prec = ovDataN.percChange || ovDataN.perc || 0
      ovDataN.perc = Math.abs(prec) / 100

      ovDataN.prevWeek = fixMissingDays(ovDataN.previousList, vKeys)
      ovDataN.currWeek = fixMissingDays(ovDataN.currentList, vKeys)

      let chartData
      chartData = ovDataN.prevWeek.map((d2, i) => {
        let cur = ovDataN.currWeek[i]?.[vKeys] || 0
        let { day } = d2
        return { day, prev: d2[vKeys], cur }
      })

      ovDataN.chart = chartData
      ovDataN.tileChart = chartData

      return ovDataN
    }

    let getTilesData = async (ovData, vKeys) => {
      if (!ovData) ovData = { perc: 0, total: 0 }
      let prec = ovData.percChange || ovData.perc || 0
      ovData.perc = Math.abs(prec) / 100

      let fromDateExsits = checkArrayHasDate(
        ovData.currentList,
        activeDuration?.currentFromDate
      )
      let toDateExsits = checkArrayHasDate(
        ovData.currentList,
        activeDuration?.currentToDate
      )
      let fromDateExsitsPrev = checkArrayHasDate(
        ovData.currentList,
        activeDuration?.previousFromDate
      )
      let toDateExsitsPrev = checkArrayHasDate(
        ovData.currentList,
        activeDuration?.previousToDate
      )

      if (
        ovData?.currentList.length === 0 ||
        !fromDateExsits ||
        !toDateExsits
      ) {
        if (ovData?.currentList.length === 0 || !fromDateExsits) {
          let curDate = activeDuration?.currentFromDate //getDate(today, -90)
          let curDay = new Date(curDate).toLocaleString("default", {
            weekday: "long",
          })
          ovData.currentList.push({
            date: dfns.format(curDate, "yyyy-MM-dd") + "T00:00:00",
            day: curDay,
            [vKeys]: 0,
          })
        }
        if (ovData?.currentList.length === 0 || !toDateExsits) {
          ovData.currentList.push({
            date:
              dfns.format(activeDuration?.currentToDate, "yyyy-MM-dd") +
              "T00:00:00",
            day: new Date(activeDuration?.currentToDate).toLocaleString(
              "default",
              { weekday: "long" }
            ),
            [vKeys]: 0,
          })
        }
      }

      if (
        ovData?.previousList.length === 0 ||
        !fromDateExsitsPrev ||
        !toDateExsitsPrev
      ) {
        if (ovData?.previousList.length === 0 || !fromDateExsitsPrev) {
          let prevDate = activeDuration?.previousFromDate //getDate(today, -90)
          let prevDay = new Date(prevDate).toLocaleString("default", {
            weekday: "long",
          })

          ovData.previousList.push({
            date: dfns.format(prevDate, "yyyy-MM-dd") + "T00:00:00",
            day: prevDay,
            [vKeys]: 0,
          })
        }
        if (ovData?.previousList.length === 0 || !toDateExsitsPrev) {
          ovData.previousList.push({
            date:
              dfns.format(activeDuration?.previousToDate, "yyyy-MM-dd") +
              "T00:00:00",
            day: new Date(activeDuration?.previousToDate).toLocaleString(
              "default",
              { weekday: "long" }
            ),
            [vKeys]: 0,
          })
        }
      }

      ovData.previousList = fixMissingDaysOrDates(
        ovData.previousList,
        vKeys,
        activeDuration.label
      )

      ovData.currentList = fixMissingDaysOrDates(
        ovData.currentList,
        vKeys,
        activeDuration.label
      )

      let chartData = []

      let weekData = []
      let weight = 0
      let month90Days = 0

      if (activeDuration?.label == "last30Days") {
        weekData = segregateDataInWeeks(
          ovData?.currentList,
          ovData?.previousList,
          vKeys,
          activeDuration
        )
      }

      ovData.currentList.forEach((d3, i) => {
        let prev = ovData.previousList[i]?.[vKeys] || 0
        let { date } = d3

        if (activeDuration?.label == "last30Days") {
          chartData = [
            ...chartData,
            { date, pDate: ovData.currentList[i]?.date, prev, cur: d3[vKeys] },
          ]
        }

        if (activeDuration?.label == "last90Days") {
          chartData = segregateDataInWeeks(
            ovData.currentList,
            ovData.previousList,
            vKeys,
            activeDuration
          )
          let month = new Date(date).getMonth()
          if (i == 0 || month != month90Days) {
            weight = 0
            weight += d3[vKeys]
            month90Days = month
            let wN = {
              day: _.upperFirst(_.camelCase(monthNames[month])),
              cur: weight,
            }
            weekData = [...weekData, wN]
          } else {
            weight += d3[vKeys]
            weekData[weekData.length - 1].cur = weight
          }
        }
      })

      ovData.chart = chartData
      ovData.tileChart = weekData

      return ovData
    }

    let sumfunction = (total, num) => {
      return total + num
    }

    let segregateDataInWeeks = (cList, pList, vKeys, activeDuration) => {
      let resData = []
      let cfDate = activeDuration?.currentFromDate
      let ctDate = getDate(activeDuration?.currentFromDate, 7)
      let pfDate = activeDuration?.previousFromDate
      let ptDate = getDate(activeDuration?.previousFromDate, 7)
      let count = 0

      let filterList = (arr, fDate, tDate) => {
        return arr.filter(
          (dt) =>
            new Date(dt.date).setHours(0, 0, 0, 0) >=
              new Date(fDate).setHours(0, 0, 0, 0) &&
            new Date(dt.date).setHours(0, 0, 0, 0) <
              new Date(tDate).setHours(0, 0, 0, 0)
        )
      }

      resData = [
        {
          day: "remove",
          prev: pList[0][vKeys],
          date: cList[0].date,
          cur: cList[0][vKeys],
          pDate: pList[0].date,
        },
      ]

      for (let i = 0; cfDate !== ctDate; i++) {
        if (cfDate >= new Date(cList[cList.length - 1].date)) {
          break
        }
        var list = filterList(cList, cfDate, ctDate)

        let tWArr = list.map((k) => k[vKeys])
        let tW = tWArr.reduce(sumfunction)

        let listP = filterList(pList, pfDate, ptDate)

        let ptWArr = listP.map((j) => j[vKeys])
        let prev = ptWArr.reduce(sumfunction)

        count++
        resData = [
          ...resData,
          {
            day: `W${count}`,
            prev,
            cur: tW,
            date: list[list.length - 1]?.date,
            pDate: listP[listP.length - 1]?.date,
          },
        ]
        cfDate = ctDate
        ctDate = getDate(cfDate, 7)
        pfDate = ptDate
        ptDate = getDate(pfDate, 7)
      }

      return resData
    }

    let resetActiveState = () => setActiveState("")
    let is = (state) => activeState == state
    let onViewDetails = (type) => () => {
      if (type == "weightPickedUp")
        history.push("/reportgenerator/general_status")
    }

    let activateChartOnToggle = (value, activeDurationV) => {
      if (activeDurationV === "weekly") setActiveState(value)
      else setActiveState("")
    }

    return (
      <Component
        {...props}
        {...{
          activeState,
          setActiveState,
          resetActiveState,
          is,
          overViewData,
          onViewDetails,
          showTotalSpend,
          overviewTypes,
          changeActiveOverview,
          activeDuration,
          activateChartOnToggle,
          showEstimatedtotalspent,
        }}
      />
    )
  }

export default enhancer
