export const SET_USER = "SET_USER"
export const SET_LOADING_STATUS = "SET_LOADING_STATUS"
export const SET_STORE = "SET_STORE"
export const DESTORY_USER = "DESTORY_USER"
export const SET_ALERTS = "SET_ALERTS"
export const UPDATE_IS_READ_ALERT = "UPDATE_IS_READ_ALERT"
export const SET_CONFIG = "SET_CONFIG"
export const SET_FILTERS = "SET_FILTERS"
export const SET_PROFILE = "SET_PROFILE"
export const DESTORY_PROFILE = "DESTORY_PROFILE"
export const SET_SR_CONID = "SET_SR_CONID"
export const SET_DOWNLOAD_ALERTS = "SET_DOWNLOAD_ALERTS"
export const UPDATE_IS_READ_DOWNLOAD_ALERT = "UPDATE_IS_READ_DOWNLOAD_ALERT"
export const SET_GENERATORS = "SET_GENERATORS"
export const DELETE_GENERATORS = "DELETE_GENERATORS"
export const DELETE_CONFIG = "DELETE_CONFIG"
export const DELETE_STORE = "DELETE_STORE"
export const DELETE_ALTERS = "DELETE_ALTERS"
export const DELETE_FILTERS = "DELETE_FILTERS"
export const SET_CHANGE_CUSTOMER = "SET_CHANGE_CUSTOMER"
export const SET_CUSTOMERS = "SET_CUSTOMERS"
export const SET_ROLES = "SET_ROLES"
export const DELETE_ROLES = "DELETE_ROLES"
export const SET_PROGRESS_STEP = "SET_PROGRESS_STEP"
export const SET_CHEMICALS = "SET_CHEMICALS"
export const SET_PROGRESS_STEP_ONLY = "SET_PROGRESS_STEP_ONLY"
export const GET_PROGRESS_STEP = "GET_PROGRESS_STEP"
export const SET_NEW_PROGRESS_STEP = "SET_NEW_PROGRESS_STEP"
export const SET_PROFILE_ID = "SET_PROFILE_ID"
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP"
export const WASTE_DESCRIPTION = "WASTE_DESCRIPTION"
export const ALL_VENDORS = "ALL_VENDORS"
export const ALL_PROCESS_CODES = "ALL_PROCESS_CODES"
export const ALL_NON_PROCESS_CODES = "ALL_NON_PROCESS_CODES"
export const ALL_FORM_CODES = "ALL_FORM_CODES"
export const ALL_ORIGIN_CODES = "ALL_ORIGIN_CODES"
export const ALL_SOURCE_CODES = "ALL_SOURCE_CODES"
export const ALL_EPAWASTE_CODES = "ALL_EPAWASTE_CODES"
export const ALL_DOCUMENT_TYPES = "ALL_DOCUMENT_TYPES"
export const ALL_FACILITY_PROCESS_CODES = "ALL_FACILITY_PROCESS_CODE"
export const ALL_FREQUENCY_TYPES = "ALL_FREQUENCY_TYPE"
export const ALL_LDR_CLASS = "ALL_LDR_CLASS"
export const AVAILABLE_EPA_WASTE_CODES = "AVAILABLE_EPA_WASTE_CODES"
export const AVAILABLE_EPA_UHCS = "AVAILABLE_EPA_UHCS"
export const AVAILABLE_STATE_CODE = "AVAILABLE_STATE_CODE"
export const AVAILABLE_LDR_UHCS = "AVAILABLE_LDR_UHCS"
export const AVAILABLE_LDR_RCRA_CODE = "AVAILABLE_LDR_RCRA_CODE"
export const SELECTED_EPA_WASTE_CODES = "SELECTED_EPA_WASTE_CODES"
export const SELECTED_EPA_UHCS = "SELECTED_EPA_UHCS"
export const SELECTED_STATE_CODE = "SELECTED_STATE_CODE"
export const SELECTED_LDR_RCRA_CODE = "SELECTED_LDR_RCRA_CODE"
export const SELECTED_LDR_UHCS = "SELECTED_LDR_UHCS"
export const ALL_TEXAS_SHIPPING_WASTE_CODES = "ALL_TEXAS_SHIPPING_WASTE_CODES"
export const AVAILABLE_REG_CONST = "AVAILABLE_REG_CONST"
export const ALL_PROFILE_STATUS = "ALL_PROFILE_STATUS"
export const ALL_PROFILE_LIST = "ALL_PROFILE_LIST"
export const SORT_PROFILE_LIST = "SORT_PROFILE_LIST"
export const SORT_OVERVIEW_PROFILE_LIST = "SORT_OVERVIEW_PROFILE_LIST"
export const CURRENT_PROFILE = "CURRENT_PROFILE"
export const ALL_GENERATORS = "ALL_GENERATORS"
export const GENERATORS_LIST = "GENERATORS_LIST"
export const ALL_STATES = "ALL_STATES"
export const ALL_CITIES = "ALL_CITIES"
export const GENERATOR_DETAILS = "GENERATOR_DETAILS"
export const PROFILE_CRETAE_FROM_GENE = "PROFILE_CRETAE_FROM_GENE"
export const SET_NAICS_CDDETAILS = "SET_NAICS_CDDETAILS"
export const ALL_ER_SERVICE_NAMES = "ALL_ER_SERVICE_NAMES"
export const PHONE_NUMBER_BY_PROVIDER_NAME = "PHONE_NUMBER_BY_PROVIDER_NAME"
export const NAICS_CODE = "NAICS_CODE"
export const RECERTIFY_PROFILE = "RECERTIFY_PROFILE"
export const CERTIFIED_PROFILE = "CERTIFIED_PROFILE"
export const GET_PROFILE = "GET_PROFILE"
export const GENERATOR_ID_STATUS = "GENERATOR_ID_STATUS"
export const SHIP_COUNTRY_LIST = "SHIP_COUNTRY_LIST"
export const SHIP_STATE_LIST = "SHIP_STATE_LIST"
export const RETRIEVE_DOCUMENTS = "RETRIEVE_DOCUMENTS"
export const SET_DEFAULT_GENE = "SET_DEFAULT_GENE"
export const GET_DEFAULT_GENE = "GET_DEFAULT_GENE"
export const SET_PROFILE_NAME = "SET_PROFILE_NAME"
export const KNOW_YOUR_CSR = "KNOW_YOUR_CSR"
export const GET_PRINT_STATUS = "GET_PRINT_STATUS"
export const GET_PRINT_LABEL = "GET_PRINT_LABEL"
export const GET_FORM_VALIDITY = "GET_FORM_VALIDITY"
export const PROFILE_LIST_REPORT = "PROFILE_LIST_REPORT"
export const SET_SHOW_DELETE = "SET_SHOW_DELETE"
export const GET_ALL_PROFILE_TEMPLATES = "GET_ALL_PROFILE_TEMPLATES"
export const PROFILE_OVERVIEW_LIST = "PROFILE_OVERVIEW_LIST"
export const PROFILE_OVERVIEW_STATUS = "PROFILE_OVERVIEW_STATUS"
export const MODULE_OVERVIEW_LIST = "MODULE_OVERVIEW_LIST"
export const AVAILABLE_DOT_CONST = "AVAILABLE_DOT_CONST"
export const ALL_ODORS = "ALL_ODORS"
export const GET_PROFILE_FOR_PRINT_LABEL = "GET_PROFILE_FOR_PRINT_LABEL"
