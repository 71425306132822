import React from "react"
import Image from "shared/HelpImage/index"
import { useHarsco } from "shared"
import { roles } from "config"
import { useSelector } from "react-redux"

function commonHeader(customerType) {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Connect+ Header Bar</h3>

      <p className="text-justify pl-3 pt-2">
        Once logged in you will see your initial dashboard. The header bar on
        the top of the screen will stay the same throughout Connect+. Pressing
        the <Image src="logo.png" alt="Logo" height="23" /> logo will take you
        back to the dashboard from anywhere in Connect+. Pressing{" "}
        <Image src="news.png" alt="Logo" height="20" /> will take you to a new
        web page, cleanearthinc.com/news where you can get the latest
        information about Clean Earth.
      </p>
      {customerType?.customer?.businessSegmentId === 3 ? (
        <div className="img-section">
          <Image src="mi_header_help.png" alt="Header Bar" />
        </div>
      ) : (
        <>
          <p className="text-justify pl-3 pt-2">
            Pressing the{" "}
            <Image src="new_request_button.png" alt="Logo" height="20" /> button
            takes you to the “New Request Page”. From there you can request
            service, report an issue, request supplies, or change a service
            request.
          </p>
          <div className="img-section">
            <Image src="header_help.png" alt="Header Bar" />
          </div>
        </>
      )}
      <p className="text-justify pl-3 pt-3">
        Pressing{" "}
        <Image className="p-l-10" src="user_guide.svg" alt="Logo" height="20" />{" "}
        will take you to the Connect+ User Guide.
      </p>
      <p className="text-justify pl-3">
        Pressing <Image src="alertIcon.png" alt="Logo" height="25" /> will show
        you all your alerts, including those that you have not read.
      </p>
      <p className="text-justify pl-3">
        Pressing{" "}
        <Image className="p-l-5" src="logoutIcon.png" alt="Logo" height="25" />{" "}
        is another way for you to logout of Connect+.
      </p>
    </div>
  )
}

function enterpriseHeaderDetails() {
  return (
    <div>
      <p className="text-justify pl-3">
        As an Enterprise User you are allowed to select among any of the
        different customers that are a part of your customer enterprise.
        Initially you are displayed the customer account chosen as the default
        for your user-ID.
      </p>
      <div className="img-section">
        <Image src="Enterpriseuser.png" alt="" />
      </div>
      <p className="text-justify pl-3">
        Pressing the down arrow to the right of your default customer account
        will display a dropdown list of all customer accounts within your
        customer enterprise. Selecting any of those accounts will switch
        Connect+ to work with data for that account.
      </p>
    </div>
  )
}

function HeaderBar() {
  let { profile } = useHarsco()

  const { changeCustomer } = useSelector((state) => state)

  return (
    <>
      {commonHeader(changeCustomer)}
      {profile.roleId === roles.ENTERPRISE_USER && enterpriseHeaderDetails()}
    </>
  )
}

export default HeaderBar
