import React from "react"
import enhancer from "./enhancer"
import "./style.scss"
import Image from "shared/Image"
import { site } from "config"
import { FormattedMessage } from "react-intl"

function Logout(props) {
  let { onLoginClick } = props

  return (
    <div className="logout-section">
      <div className="row justify-content-center logout-arc">
        <div className="col-md-5 col-sm-12">
          <div className="ligo-img mb-5">
            <Image className="loginLogo" src="logo_Clean_earth.svg" alt="" />
          </div>
          <div className="loginText col-9">
            <FormattedMessage id="login.onesource" />
          </div>
          <div className="logoutText mt-4 mb-2">
            <FormattedMessage id="login.logoutText1" />
            <br />
            <FormattedMessage id="login.logoutText2" />
          </div>
          <button className="green-button" onClick={onLoginClick}>
            <span className="login">
              <FormattedMessage id="login.loginAgain" />
            </span>
          </button>

          <div className="assistcall mt-4">
            For assistance <b> Call us </b> at {site.number}
          </div>
        </div>
      </div>
    </div>
  )
}
export default enhancer(Logout)
