import React, { Fragment } from "react"
import Image from "shared/Image"
import classnames from "classnames"
import { FormattedMessage } from "react-intl"
import { formattedDate, useIsTablet } from "shared"
import Map from "./Map"
import _ from "lodash"

function RightServiceSection(props) {
  let {
    changeRequest,
    serviceHistory,
    activeService: service,
    isPreviewCustomer,
    isSPAKCustomer,
  } = props

  let sch_type = {
    "Urgent 3 Day": "yellow",
    ER: "red",
    Rework: "red",
    Urgent: "yellow",
  }

  let isTablet = useIsTablet()

  if (!service) return null

  let address = `${service.title}, ${service.storeAddress?.postalCode}`

  const getNoShip = (d) => {
    if (isPreviewCustomer && service?.workOrderType === "NoShip") {
      return true
    } else if (isSPAKCustomer && d.noShip) {
      return true
    }
    return false
  }

  return (
    <Fragment>
      <div
        className={` ${
          isTablet
            ? "col-sm-7 col-md-7 col-lg-7 text-left p-0 service-details "
            : "col-sm-8 col-md-8 col-lg-8 text-left p-0 service-details"
        }`}
      >
        <div className="row m-0 service-title-block">
          <div className={`col-sm-7 col-md-7 col-lg-7 text-left p-0 `}>
            <div className="col-sm-12 col-md-12 col-lg-12 text-left p-0 locationtitle">
              <div className="store">
                <FormattedMessage id="myschedule.locationNo" />
                {service.storeNumber || "NA"}
              </div>
              <div className="address">
                <FormattedMessage id="requestservicecheckout.addresslabel" />
                <span className="address1">
                  {service.storeAddress?.address}
                </span>
              </div>
              <div className="city">
                {service.storeAddress?.city}, {service.storeAddress?.state},{" "}
                {service.storeAddress?.country}
              </div>
            </div>
            {/* <label className="service-title">{service.title}</label> */}
          </div>

          <div className={`col-sm-5 col-md-5 col-lg-5 text-right p-0 `}>
            {service.canServiceHistory && service.workOrderAzId && (
              <button
                className="service-history"
                onClick={() => {
                  serviceHistory(service.workOrderAzId)
                }}
              >
                <FormattedMessage id="myschedule.servicehistory" />
              </button>
            )}
            <label className="service-date">
              {" "}
              <span>
                <Image
                  className=""
                  src="ic_calendar.svg"
                  height="12px"
                  width="14px"
                  alt=""
                />
              </span>
              <span className="">{formattedDate(service.formatTime)}</span>
            </label>
          </div>
        </div>
        {service.canChange && (
          <div className="text-right schedulelink ">
            <span className="c-p" onClick={changeRequest}>
              <FormattedMessage id="myschedule.link" />
            </span>
          </div>
        )}
        <div className="row m-0 middle-section-block">
          <div
            className={`col-sm-3 col-md-3 col-lg-3 text-center border-rights`}
          >
            <div className="schedule-label">
              <FormattedMessage id="myschedule.scheduletype" />
            </div>

            <div
              className={classnames(
                "schedule-data",
                sch_type[service.scheduleType] || "blue"
              )}
            >
              {service.scheduleType}
            </div>
          </div>
          <div
            className={`col-sm-3 col-md-3 col-lg-3 text-center border-rights`}
          >
            <div className="schedule-label">
              <FormattedMessage id="myschedule.serviceorder" />
            </div>
            <div className="schedule-data">{service.workOrderId}</div>
          </div>
          <div
            className={`col-sm-3 col-md-3 col-lg-3 text-center border-rights`}
          >
            <div className="schedule-label">
              <FormattedMessage id="myschedule.store" />
            </div>
            <div className="schedule-data po"> {service.poNumber || "-"}</div>
          </div>
          <div
            className={`col-sm-3 col-md-3 col-lg-3 text-center statusHeading`}
          >
            <div className="schedule-label">
              <FormattedMessage id="myschedule.service1" />
            </div>
            <div className="schedule-data f-12">
              {/* <FormattedMessage id="myschedule.services03" /> */}
              {service.scheduleStatus || "-"}
            </div>
          </div>
        </div>

        <div className="row m-0 middle-block">
          <div className={`col-sm-3 col-md-3 col-lg-3 `}>
            <div className="schedule-label-heading">
              <FormattedMessage id="myschedule.servicetypes" />
            </div>
          </div>
          <div className={`col-sm-3 col-md-3 col-lg-3 pl-0 `}>
            <div className="schedule-label-heading">
              <FormattedMessage id="myschedule.servicefreqeuncy" />
            </div>
          </div>
          <div className={`col-sm-4 col-md-4 col-lg-4 pl-0 `}>
            <div className="schedule-label-heading">
              <FormattedMessage id="myschedule.estimateservicedate" />
            </div>
          </div>
        </div>
        {!_.isEmpty(service.serviceTypes) &&
          service?.serviceTypes.map((d, i) => (
            <div className="row servicetype-row" key={i}>
              <div
                className={` ${
                  isTablet
                    ? "col-sm-3 col-md-3 col-lg-3 text-left"
                    : "col-sm-3 col-md-3 col-lg-3 text-left"
                }`}
              >
                <div className="labelname-service">{d.serviceType}</div>
              </div>
              <div
                className={` ${
                  isTablet
                    ? "col-sm-3 col-md-3 col-lg-3 p-0 text-left "
                    : "col-sm-3 col-md-3 col-lg-3 p-0 text-left"
                }`}
              >
                <div className="frequency-service frequency-service-block">
                  {d.frequency == -1
                    ? "On Call"
                    : d.frequency
                    ? `${d.frequency} days`
                    : "NA"}
                </div>
              </div>
              <div
                className={` ${
                  isTablet
                    ? "col-sm-4 col-md-4 col-lg-4 p-0 text-left "
                    : "col-sm-4 col-md-4 col-lg-4 p-0 text-left"
                }`}
              >
                <div className="frequency-service-block">
                  {d.estimatedServiceDate || "NA"}
                </div>
              </div>
              <div
                className={` ${
                  isTablet
                    ? "col-sm-2 col-md-2 col-lg-2 p-0 text-left "
                    : "col-sm-2 col-md-2 col-lg-2 p-0 text-left"
                }`}
              >
                {/* Todo render it based on noship flag */}
                <div
                  className={
                    getNoShip(d)
                      ? "frequency-service-block no-ship-message"
                      : ""
                  }
                >
                  {getNoShip(d) ? "no ship" : ""}
                </div>
              </div>
            </div>
          ))}

        <div className="store-details">
          <div className="store-labelname">
            <FormattedMessage id="myschedule.storeaddress" />
          </div>
          <div className="icon-address">
            <span className="landmark-icon">
              <Image
                className=""
                src="ic_location.svg"
                height="12px"
                width="8px"
                alt=""
              />
            </span>
            <label className="store-address">
              {`${service.storeAddress.address}, ${service.storeAddress.city}, ${service.storeAddress.state}, ${service.storeAddress.country}`}
            </label>
          </div>
          <div className="store-location">
            <Map address={address} />
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default RightServiceSection
