import React, { useState, useEffect, useRef } from "react"
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
} from "recharts"
import "./style.scss"
import colors from "./colors"
import _ from "lodash"

const Chart = ({ data, config, height, title }) => {
  const [width, setWidth] = useState(0)
  const elementRef = useRef(null)

  data.forEach((d) => {
    let total = d.onTime + d.late
    d.onTimeP = _.floor((d.onTime / total) * 100, 2)
    d.lateP = _.floor((d.late / total) * 100, 2)
  })

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width)
  }, [])

  return (
    <div ref={elementRef} className="ontime-graph">
      {title && <label className="m-0 labelgenerator">{title}</label>}
      <ComposedChart width={width} height={height} data={data}>
        <XAxis dataKey="date" />
        <YAxis unit={"%"} domain={[0, 100]} />
        <Tooltip
          formatter={(value, name, { dataKey, payload }) => {
            let val = ""
            if (dataKey == "onTimeP") val = payload.onTime
            if (dataKey == "lateP") val = payload.late
            if (dataKey == "onTimeP" && name == "On-time%")
              val = `${payload.onTimeP}%`
            return val
          }}
        />
        <CartesianGrid />
        <Bar
          dataKey="onTimeP"
          name="On-Time"
          fill={colors.ontime.ontime}
          stackId="a"
          label={{
            position: "center",
            content: (d) => data[d.index].onTime,
          }}
          barSize={30}
        />
        <Bar
          dataKey="lateP"
          name="Late"
          fill={colors.ontime.late}
          stackId="a"
          label={{ position: "center", content: (d) => data[d.index].late }}
        />
        <Line
          type="monotone"
          dataKey="onTimeP"
          name="On-time%"
          label={{
            position: "bottom",
            formatter: (d) => `${d}%`,
          }}
          stroke={colors.ontime.ontimeP}
          dot={false}
        />

        <Legend verticalAlign="bottom" iconType="rect" />
      </ComposedChart>
    </div>
  )
}

export default Chart
