import React from "react"
import Image from "shared/HelpImage/index"

function HeaderBar() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Connect+ Header Bar</h3>
      <p className="text-justify pl-3 pt-2">
        Once logged in you will see your initial dashboard. The header bar on
        the top of the screen will stay the same throughout Connect+. Pressing
        the <Image src="logo.png" alt="Logo" height="23" /> logo will take you
        back to the dashboard from anywhere in Connect+. Pressing{" "}
        <Image src="news.png" alt="Logo" height="20" /> will take you to a new
        web page, cleanearthinc.com/news where you can get the latest
        information about Clean Earth.
      </p>
      <div className="img-section">
        <Image src="HeaderBar_FC.png" alt="Header Bar" />
      </div>
      <p className="text-justify pl-3 pt-3">
        Pressing{" "}
        <Image className="p-l-10" src="user_guide.svg" alt="Logo" height="20" />{" "}
        will take you to the Connect+ User Guide.
      </p>
      <p className="text-justify pl-3">
        Pressing <Image src="alertIcon.png" alt="Logo" height="25" /> will show
        you all your alerts, including those that you have not read.
      </p>
      <p className="text-justify pl-3">
        Pressing{" "}
        <Image className="p-l-5" src="logoutIcon.png" alt="Logo" height="25" />{" "}
        is another way for you to logout of Connect+.
      </p>
    </div>
  )
}
export default HeaderBar
