import React from "react"
import Image from "shared/HelpImage/index"

function WeeklyOverview() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Retail Dashboard: Weekly Overview</h3>
      <h4 className="pt-4 text-left pl-3">Executive Overview</h4>
      <p className="text-justify pl-3">
        The overview dashboard shows informational tiles that run across the top
        of the screen, a waste process summary in the middle, “My Schedule”
        across the right side and “My Documents” on the bottom.
      </p>
      <p className="text-justify pl-3">
        Selecting either “Weekly”, “Last 30 Days” or “Last 90 Days” will change
        the informational tiles to match your chosen time frame.
      </p>
      <div className="img-section">
        <Image src="2.1.4.Weekly.png" alt="" />
      </div>
      <p>
        To switch among the three charts click on any of the three dots at the
        bottom of the Waste Summary. If you do not click on any of the dots the
        display will cycle between the three charts.
      </p>
    </div>
  )
}
export default WeeklyOverview
