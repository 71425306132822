import React, { Fragment, useEffect, useState, useRef } from "react"
import "./style.scss"
import enhancer from "./enhancer"
import { FormattedMessage } from "react-intl"
import GeneratorInfo from "./GeneratorInfo"
import ChemicalComposition from "./ChemicalComposition"
import AdditionalInfo from "./AdditionalInfo"
import StateRegulatoryInfo from "./StateRegulatoryInfo"
import RegulatedConstituents from "./RegulatedConstituents"
import GeneratorCertification from "./GeneratorCertification"
import Documents from "./Documents"
import WasteCharacteristics from "./WasteCharacteristics"
import ShippingInformation from "./ShippingInformation"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import Image from "shared/Image"
import PrintLabelModal from "routes/Print/PrintLabelModal"
import classNames from "classnames"
import Modal from "shared/Modal"
import Input from "routes/Admin/Components/Input"

function CreateProfile({
  goToCloneProfile,
  goToRecertifyProfile,
  handlePrintState,
  deleteProfile,
  goToEditProfile,
  downloadDocument,
  printDocument,
  setProfileNameAction,
  printLoader,
  setPrintLoader,
}) {
  const currentStep = useSelector((state) => state.profileDetails.currentStep)
  const [activeStep, setActiveStep] = useState(currentStep)
  const [seekingStep, setSeekingStep] = useState(0)
  const current_profile = useSelector(
    (state) => state.profileDetails.currentProfile
  )
  const [showAlert, setShowAlert] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showAlertInitial, setShowAlertInitial] = useState(false)
  const [copyModal, setCopyModal] = useState(false)
  const [activeProfile, setActiveProfile] = useState("")
  const [profileName, setProfileName] = useState("")
  const [fload, setFload] = useState(true)
  const [showInvalidGenPopup, setShowInvalidGenPopup] = useState(false)
  const [inActiveGenId, setInActiveGenId] = useState("")
  const { id, type } = useParams()
  const profileList = useSelector((state) => state.profileDetails.profilesList)
  const generatorOptions = useSelector(
    (state) => state.generatorDetails.generatorList
  )
  const profileOverviewList = useSelector(
    (state) => state.profileDetails.profilesOverviewList
  )
  const getProfileSelected = profileList?.find(
    (item) => item.profileId == id || item.profile == id
  )
  const getProfileOverview = profileOverviewList?.find(
    (item) => item.profileId == id || item.profile == id
  )

  const getProfile = getProfileSelected
    ? getProfileSelected
    : getProfileOverview

  const ref = useRef(null)
  const wcref = useRef(null)
  const ccref = useRef(null)
  const airef = useRef(null)
  const siref = useRef(null)
  const doref = useRef(null)
  const gcref = useRef(null)
  const rcref = useRef(null)
  const srref = useRef(null)
  const applicationProgress = useSelector(
    (state) => state.profileDetails.applicationProgress
  )

  const showdeleteMessage = useSelector(
    (state) => state.generatorDetails.showdeleteMessage
  )

  useEffect(() => {
    setActiveStep(currentStep)
  }, [currentStep])

  const deleteProfileUser = (x) => {
    deleteProfile(x.status?.toLowerCase() == "draft" ? x.profileId : x.profile)
  }

  const handleClick = (r) => {
    switch (r) {
      case 1:
        ref.current?.scrollIntoView({ behavior: "smooth" })
        return
      case 2:
        wcref.current?.scrollIntoView({ behavior: "smooth" })
        return
      case 3:
        ccref.current?.scrollIntoView({ behavior: "smooth" })
        return
      case 4:
        airef.current?.scrollIntoView({ behavior: "smooth" })
        return
      case 5:
        srref.current?.scrollIntoView({ behavior: "smooth" })
        return
      case 6:
        rcref.current?.scrollIntoView({ behavior: "smooth" })
        return
      case 7:
        siref.current?.scrollIntoView({ behavior: "smooth" })
        return
      case 8:
        doref.current?.scrollIntoView({ behavior: "smooth" })
        return
      default:
        gcref.current?.scrollIntoView({ behavior: "smooth" })
        return
    }
  }

  // const checkFormValid = () => {
  //   return false;
  // }
  const printState = useSelector((state) => state.profileDetails.printStatus)

  return (
    <Fragment>
      <iframe
        id="pdf-frame"
        title="Print PDF"
        style={{ display: "none" }}
      ></iframe>
      {printLoader ? (
        <div>
          <div className="loader" style={{ marginTop: 70 }}></div>
        </div>
      ) : (
        <>
          <div>
            {type == "viewprofile" && printState ? <PrintLabelModal /> : ""}
            {type !== "viewprofile" && (
              <div
                className="d-flex col-sm-12 col-md-12 col-lg-12 generatorstatus text-left ml-4 my-4"
                style={{ justifyContent: "space-between" }}
              >
                <div className="m-0 myprofile labelgenerator">
                  {type === "viewprofile" ? (
                    "View Profile"
                  ) : type === "editprofile" ? (
                    <span style={{ marginLeft: "-15px" }}>
                      <FormattedMessage id="profile.editprofile" />
                    </span>
                  ) : (
                    <span style={{ marginLeft: "-15px" }}>
                      <FormattedMessage id="profile.createnew" />
                    </span>
                  )}
                  {/* {id ? (
                <FormattedMessage id="profile.editprofile" />
              ) : (
                <FormattedMessage id="profile.createnew" />
              )} */}
                </div>
              </div>
            )}

            {type !== "viewprofile" && (
              <div className="col-sm-12 col-md-12 col-lg-12 text-left ml-4 my-4 position-relative profile-background">
                <div className="profileContainer"></div>
                <div className="m-0 myprofile d-flex">
                  {[
                    {
                      label: "Generator Information & Waste Description",
                      progress: applicationProgress?.["1"] || 0,
                    },
                    {
                      label: "Waste Characteristics (@70°F)",
                      progress: applicationProgress?.["2"] || 0,
                    },
                    {
                      label: "Chemical Composition",
                      progress: applicationProgress?.["3"] || 0,
                    },
                    {
                      label: "Additional Information",
                      progress: applicationProgress?.["4"] || 0,
                    },
                    {
                      label: "US EPA / State Regulatory Information",
                      progress: applicationProgress?.["5"] || 0,
                    },
                    {
                      label: "Regulated Constituents",
                      progress: applicationProgress?.["6"] || 0,
                    },
                    {
                      label: "Shipping Information",
                      progress: applicationProgress?.["7"] || 0,
                    },
                    {
                      label: "Documents",
                      progress: applicationProgress?.["8"] || 0,
                    },
                    {
                      label: "Generator Certification",
                      progress: applicationProgress?.["9"] || 0,
                    },
                  ].map((val, i) => (
                    <div
                      key={i}
                      className="d-flex progressBarStatus"
                      onClick={() => {
                        let filterInactiveGen = generatorOptions.filter(
                          (data) => data?.generatorId === inActiveGenId
                        )
                        if (
                          filterInactiveGen?.length > 0 &&
                          filterInactiveGen &&
                          filterInactiveGen[0].isActive === false
                        ) {
                          setShowInvalidGenPopup(true)
                          setSeekingStep(i)
                        } else {
                          setSeekingStep(i)
                          // if (current_profile?.generatorId && checkFormValid()) {
                          //   setActiveStep(i + 1)
                          //   dispatch({ type: SET_ACTIVE_STEP, payload: i + 1 })
                          // } else
                          // if (current_profile?.generatorId && !checkFormValid()) {
                          if (current_profile?.generatorId) {
                            setShowAlert(true)
                          }
                          if (!current_profile?.generatorId) {
                            setShowAlert(true)
                            setShowAlertInitial(true)
                          }
                        }
                      }}
                    >
                      <div
                        className={`position-relative profile-nav-container ${
                          i + 1 == activeStep ? "profileComplete" : ""
                        }`}
                      >
                        <div
                          className={`position-absolute inside-fill ${
                            i + 1 == activeStep ? "profileCurrent" : ""
                          }`}
                          style={{
                            height:
                              i + 1 == activeStep
                                ? "100%"
                                : 100 - val.progress + "%",
                          }}
                        ></div>
                      </div>
                      <span
                        className={`mt-2 profileTitle ${
                          i + 1 == activeStep ? "profileTitleComplete" : ""
                        }`}
                      >
                        {val?.label}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {type === "viewprofile" && (
              <div
                className="position-sticky ml-4"
                style={{
                  zIndex: 1000,
                  top: 55,
                  background: "#ffffff",
                  width: "100%",
                }}
              >
                <div
                  className="d-flex col-sm-12 col-md-12 col-lg-12 pl-0 generatorstatus text-left my-4 position-sticky"
                  style={{
                    justifyContent: "space-between",
                    zIndex: 10000,
                    top: 66,
                  }}
                >
                  <div className="m-0 labelgenerator myprofile">
                    <FormattedMessage id="profile.view" />
                  </div>
                  <div className="d-flex text-right cursor-pointer">
                    {getProfile?.canCopy == 1 && (
                      <div
                        className="mr-2"
                        onClick={() => {
                          setCopyModal(true)
                          setActiveProfile(getProfile)
                        }}
                      >
                        <Image
                          title="Copy"
                          src="vp_copy.svg"
                          alt="Copy"
                          height="26px"
                          width="26px"
                        />
                      </div>
                    )}
                    {getProfile?.canPrint == 1 && (
                      <div className="mr-2">
                        <Image
                          title="Print"
                          src="vp_print.svg"
                          alt="Print"
                          height="26px"
                          width="26px"
                          onClick={() => {
                            setPrintLoader(true)
                            printDocument(
                              getProfile?.status?.toLowerCase() == "draft"
                                ? getProfile?.profileId
                                : getProfile?.profile,
                              getProfile?.status,
                              getProfile?.version
                            )
                          }}
                        />
                      </div>
                    )}
                    {getProfile?.canDownload == 1 && (
                      <div
                        className="mr-2"
                        onClick={() =>
                          downloadDocument(
                            getProfile?.status?.toLowerCase() == "draft"
                              ? getProfile?.profileId
                              : getProfile?.profile,
                            getProfile?.status,
                            getProfile?.version
                          )
                        }
                      >
                        <Image
                          title="Download"
                          src="vp_download.svg"
                          alt="Download"
                          height="26px"
                          width="26px"
                        />
                      </div>
                    )}
                    {getProfile?.canEdit === 1 && (
                      <div
                        className="mr-2"
                        onClick={() => goToEditProfile("editprofile", id)}
                      >
                        <Image
                          title="Edit"
                          src="vp_edit.svg"
                          alt="Edit"
                          height="26px"
                          width="26px"
                        />
                      </div>
                    )}
                    {getProfile?.canDelete === 1 && (
                      <div className="mr-2" onClick={() => setShowModal(true)}>
                        <Image
                          title="Delete"
                          src="vp_delete.svg"
                          alt="Delete"
                          height="26px"
                          width="26px"
                        />
                      </div>
                    )}
                    {getProfile?.canRecertify === 1 && (
                      <div className="mr-2">
                        <Image
                          title="Recertify"
                          className="view-icon"
                          src="vp_recertify.svg"
                          height="26px"
                          width="26px"
                          alt=""
                          onClick={() => goToRecertifyProfile(getProfile)}
                        />
                      </div>
                    )}
                    {getProfile?.canPrintLabel === 1 && (
                      <div className="mr-2">
                        <Image
                          title="Print Label"
                          className="view-icon mr-2"
                          src="vp_printlabel.svg"
                          height="26px"
                          width="26px"
                          alt=""
                          onClick={() => {
                            handlePrintState(true, getProfile)
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="col-sm-12 col-md-12 col-lg-12 text-left my-4 position-sticky profile-background"
                  style={{ zIndex: 10000, top: 128 }}
                >
                  <div className="profileContainer"></div>
                  <div className="m-0 myprofile d-flex">
                    {[
                      {
                        label: "Generator Information",
                        progress: applicationProgress?.["1"] || 0,
                      },
                      {
                        label: "Waste Characteristics (@70°F)",
                        progress: applicationProgress?.["2"] || 0,
                      },
                      {
                        label: "Chemical Composition",
                        progress: applicationProgress?.["3"] || 0,
                      },
                      {
                        label: "Additional Information",
                        progress: applicationProgress?.["4"] || 0,
                      },
                      {
                        label: "US EPA / State Regulatory Information",
                        progress: applicationProgress?.["5"] || 0,
                      },
                      {
                        label: "Regulated Constituents",
                        progress: applicationProgress?.["6"] || 0,
                      },
                      {
                        label: "Shipping Information",
                        progress: applicationProgress?.["7"] || 0,
                      },
                      {
                        label: "Documents",
                        progress: applicationProgress?.["8"] || 0,
                      },
                      {
                        label: "Generator Certification",
                        progress: applicationProgress?.["9"] || 0,
                      },
                    ].map((val, i) => (
                      <div
                        key={i}
                        className="d-flex progressBarStatus"
                        onClick={() => {
                          setFload(false)
                          setActiveStep(i + 1)
                          if (i !== 0) {
                            handleClick(i + 1)
                          } else {
                            window.scrollTo(0, 0)
                          }
                        }}
                      >
                        <div
                          className={`position-relative profile-nav-container ${
                            i + 1 == activeStep ? "profileComplete" : ""
                          }`}
                        >
                          <div
                            className={`position-absolute inside-fill ${
                              i + 1 == activeStep ? "profileCurrent" : ""
                            }`}
                            style={{ height: 100 - val.progress + "%" }}
                          ></div>
                        </div>
                        <span
                          className={`mt-2 profileTitle ${
                            i + 1 == activeStep ? "profileTitleComplete" : ""
                          }`}
                        >
                          {val?.label}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    width: "80px",
                    position: "fixed",
                    bottom: "60px",
                    right: 0,
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#FFFFFF",
                  }}
                  onClick={() => {
                    window.scrollTo(0, 0)
                  }}
                >
                  <Image
                    title="Copy"
                    src="Arrow_back_to_top.svg"
                    alt="Copy"
                    height="30px"
                    width="30px"
                  />
                  <span style={{ fontSize: 12, marginTop: 8 }}>
                    Back to top
                  </span>
                </div>
              </div>
            )}
            {type !== "viewprofile" && (
              <div>
                {activeStep === 1 && (
                  <GeneratorInfo
                    showAlertInitial={showAlertInitial}
                    setShowAlertInitial={setShowAlertInitial}
                    seekingStep={seekingStep}
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                    showInvalidGenPopup={showInvalidGenPopup}
                    setInActiveGenId={setInActiveGenId}
                    setShowInvalidGenPopup={setShowInvalidGenPopup}
                  />
                )}
                {activeStep === 2 && (
                  <WasteCharacteristics
                    setSeekingStep={setSeekingStep}
                    seekingStep={seekingStep}
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                  />
                )}
                {activeStep === 3 && (
                  <ChemicalComposition
                    seekingStep={seekingStep}
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                  />
                )}
                {activeStep === 4 && (
                  <AdditionalInfo
                    seekingStep={seekingStep}
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                  />
                )}
                {activeStep === 5 && (
                  <StateRegulatoryInfo
                    seekingStep={seekingStep}
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                  />
                )}
                {activeStep === 6 && (
                  <RegulatedConstituents
                    seekingStep={seekingStep}
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                  />
                )}
                {activeStep === 7 && (
                  <ShippingInformation
                    seekingStep={seekingStep}
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                  />
                )}
                {activeStep === 8 && (
                  <Documents
                    seekingStep={seekingStep}
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                  />
                )}
                {activeStep === 9 && (
                  <GeneratorCertification
                    seekingStep={seekingStep}
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                  />
                )}
              </div>
            )}
            {type === "viewprofile" && (
              <div>
                <div ref={ref}>
                  {/* {!fload && activeStep !== 1 && (
                <div style={{ height: 250 }}></div>
              )} */}
                  {<GeneratorInfo />}
                </div>
                <div ref={wcref}>
                  {!fload && <div style={{ height: 210 }}></div>}
                  {/* <div style={{ height: 250 }}></div> */}
                  {
                    <WasteCharacteristics
                      genNameforView={getProfile && getProfile.generatorDetails}
                    />
                  }
                </div>
                <div ref={ccref}>
                  <div style={{ height: 210 }}></div>
                  {
                    <ChemicalComposition
                      genNameforView={getProfile && getProfile.generatorDetails}
                    />
                  }
                </div>
                <div ref={airef}>
                  <div style={{ height: 210 }}></div>
                  {
                    <AdditionalInfo
                      genNameforView={getProfile && getProfile.generatorDetails}
                    />
                  }
                </div>
                <div ref={srref}>
                  <div style={{ height: 210 }}></div>
                  {
                    <StateRegulatoryInfo
                      genNameforView={getProfile && getProfile.generatorDetails}
                    />
                  }
                </div>
                <div ref={rcref}>
                  <div style={{ height: 210 }}></div>
                  {
                    <RegulatedConstituents
                      genNameforView={getProfile && getProfile.generatorDetails}
                    />
                  }
                </div>
                <div ref={siref}>
                  <div style={{ height: 210 }}></div>
                  {
                    <ShippingInformation
                      genNameforView={getProfile && getProfile.generatorDetails}
                    />
                  }
                </div>
                <div ref={doref}>
                  <div style={{ height: 210 }}></div>
                  {
                    <Documents
                      genNameforView={getProfile && getProfile.generatorDetails}
                    />
                  }
                </div>
                <div ref={gcref}>
                  <div style={{ height: 210 }}></div>
                  {
                    <GeneratorCertification
                      genNameforView={getProfile && getProfile.generatorDetails}
                    />
                  }
                </div>
              </div>
            )}
          </div>
        </>
      )}
      <Modal show={copyModal} style={{ padding: 20 }}>
        <div className="p-4" style={{ width: "40VW" }}>
          <div className="dsp-flx col-sm-12 mt-2">
            {/* <h4 className="p-2" style={{ textAlign: "left" }}>
                          <FormattedMessage id="profile.enterprofilename" />
                        </h4> */}
            <div className="label" style={{ textAlign: "left" }}>
              <FormattedMessage id="profile.enterprofilename" />
            </div>
            {/* <span
                          style={{ color: "#CCCCCC", cursor: "pointer" }}
                          onClick={() => setPopup(false)}
                        >
                          x
                        </span> */}
          </div>
          <div className="mt-4" style={{ textAlign: "left" }}>
            <div
              className="form-input-block col-sm-8 mt-2"
              style={{ minWidth: 85 }}
            >
              <span className="label pl-0" style={{ fontSize: 12 }}>
                <FormattedMessage id="profile.name" />
              </span>
              <Input
                name="profileName"
                value={profileName}
                onFieldChange={(e) => {
                  setProfileName(e.target.value)
                }}
              />
            </div>
          </div>
          <div className="pl-4 mt-4" style={{ textAlign: "right" }}>
            <button
              className={`cancel-button mr-2`}
              onClick={() => {
                setProfileName("")
                setProfileNameAction("")
                setCopyModal(false)
                setActiveProfile("")
              }}
              style={{ padding: "6px 20px" }}
            >
              <FormattedMessage id="profile.cancelbtn" />
            </button>
            <button
              className={`${classNames("downloadbtn")} ${
                profileName?.trim()?.length === 0 ? "disabledBgBtn" : ""
              }`}
              onClick={() => {
                setProfileNameAction(profileName)
                goToCloneProfile(
                  "editprofile",
                  activeProfile.status?.toLowerCase() == "draft"
                    ? activeProfile?.profileId
                    : activeProfile?.profile
                )
                setProfileName("")
                setCopyModal(false)
              }}
              disabled={profileName?.trim()?.length === 0}
              style={{ padding: "6px 20px" }}
            >
              <FormattedMessage id="profile.okaybtn" />
            </button>
          </div>
        </div>
      </Modal>
      {/* Generator Info Delete popup*/}
      <Modal show={showModal} style={{ padding: 20 }}>
        <div style={{ padding: 20, fontSize: 12 }}>
          {!showdeleteMessage && (
            <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus text-left py-4">
              <div className="m-0 labelgenerator myprofile">
                <FormattedMessage id="profile.generatorinfo.deleteprofile" />
              </div>
            </div>
          )}
          {!showdeleteMessage && (
            <div className="ml-3 mb-4" style={{ textAlign: "left" }}>
              <FormattedMessage id="profile.generatorinfo.suredelete" />{" "}
              {getProfile?.profileId}?
            </div>
          )}
          {showdeleteMessage && (
            <div className="ml-3 mb-4" style={{ textAlign: "left" }}>
              <FormattedMessage id="profile.generatorinfo.profiledeleted" />
            </div>
          )}

          {!showdeleteMessage && (
            <div
              className="dsp-flx col-sm-12 mt-2"
              style={{ flexDirection: "row" }}
            >
              <button
                className="green-button"
                style={{
                  background: "#ffffff",
                  color: "#0092BC",
                  border: "1px solid #0092BC",
                }}
                type="button"
                onClick={() => {
                  setShowModal(false)
                }}
              >
                <FormattedMessage id="profile.generatorinfo.cancel" />
              </button>
              <button
                className="green-button"
                type="button"
                onClick={() => {
                  deleteProfileUser(getProfile)
                  setTimeout(() => {
                    setShowModal(false)
                  }, 3000)
                }}
              >
                <FormattedMessage id="profile.generatorinfo.confirm" />
              </button>
            </div>
          )}
        </div>
      </Modal>
    </Fragment>
  )
}
export default enhancer(CreateProfile)
