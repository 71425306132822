import React, { Fragment, useEffect, useState, useRef } from "react"
import { useTable, usePagination, useBlockLayout } from "react-table"
import _ from "lodash"
import { useSticky } from "react-table-sticky"
import { FormattedNumber } from "react-intl"
import "./style.scss"
import { useWindowDimension } from "shared"

function Table(props) {
  const dimension = useWindowDimension()
  let { type, columns = [], data, extra = {}, summary } = props
  let { total = false, groups = false } = summary

  let { cellColor = () => "" } = extra

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 120,
      maxWidth: 400,
    }),
    []
  )

  const {
    headerGroups,
    prepareRow,
    footerGroups,
    getTableProps,
    getTableBodyProps,
    page,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 20,
      },
      defaultColumn,
    },
    usePagination,
    useBlockLayout,
    useSticky
  )

  const RenderCell = ({ value, type, total }) => {
    return (
      <>
        {(() => {
          switch (type) {
            case "array":
              return (
                <ul className="text-left">
                  {value.map((d, i) => (
                    <li key={i}>{d}</li>
                  ))}
                </ul>
              )
            case "number":
              if (_.isNaN(value)) return "-"
              else {
                if (value !== undefined && type == "number") {
                  if (total) {
                    return (
                      <FormattedNumber
                        value={value}
                        maximumFractionDigits={2}
                      />
                    )
                  }
                  return <FormattedNumber value={value} />
                }
              }
              break
            case "currency":
              return (
                <FormattedNumber
                  value={value}
                  style="currency" // eslint-disable-line
                  currency="USD"
                />
              )
            default:
              return value
          }
        })()}
      </>
    )
  }

  let trClass = headerGroups[0].headers.length > 4 ? "active" : "inactive"
  let GroupRow = ({ row, cGroupVal }) => (
    <div className={`tr total ${trClass} `} {...row.getRowProps()}>
      {row.cells.map((cell, ci) => {
        return (
          <div
            key={`gh-${row.id}-${cell.column.id}`}
            {...cell.getCellProps()}
            className={`td ${
              ci == 0 && cGroupVal?.length > 16 ? "lengthytext" : ""
            }`}
          >
            {ci == 0 && cGroupVal + " Total"}
            {ci != 0 && (
              <RenderCell
                value={groups[cGroupVal]?.[cell.column.id]}
                type={cell.column.unit}
                total={true}
              />
            )}
          </div>
        )
      })}
    </div>
  )

  let lastR = {}

  lastR.row = _.last(page)
  prepareRow(lastR.row)
  lastR.cGroupVal = lastR.row?.cells?.[1].value
  lastR.cGroupVal1 = lastR.row.cells[1]

  let [dim, setDim] = useState({})
  const elementRef = useRef(null)

  useEffect(() => {
    setDim({
      maxHeight: dimension[1] * 0.71,
      width: elementRef.current.getBoundingClientRect().width,
    })
  }, [dimension])

  return (
    <div ref={elementRef}>
      <div
        style={dim}
        className={`table sticky  ${
          ["waste_summary_shipment_report", "exception_report"].includes(type)
            ? "isshipreport "
            : ""
        }`}
        {...getTableProps()}
      >
        <div className="headers">
          <div
            className={`tr ${trClass}`}
            {...headerGroups[0].getHeaderGroupProps()}
          >
            {headerGroups[0].headers.map((column) => (
              <div
                key={column.id}
                className={`th ${
                  column.Header.length > 30 ? "active" : "inactive"
                }`}
                {...column.getHeaderProps()}
              >
                {column.render("Header")}
              </div>
            ))}
          </div>
        </div>

        <div className="body" {...getTableBodyProps()}>
          {page.map((row, ri) => {
            prepareRow(row)
            let rowChange = false
            let cGroupVal = page[ri - 1]?.cells?.[1].value

            if (groups && ri != 0 && row.cells[1].value != cGroupVal)
              rowChange = true
            return (
              <Fragment key={row.id}>
                {rowChange && <GroupRow {...{ row, cGroupVal }} />}
                <div
                  className={`tr ${
                    row.cells.length > 4 ? "active" : "inactive"
                  }`}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, ci) => {
                    let renderCell = true
                    // if (ci == 0 && !rowChange && ri != 0) renderCell = false
                    return (
                      <div
                        key={`td-${row.id}-${cell.column.id}`}
                        style={{
                          ...cell.getCellProps().style,
                          background: !cell.column.id.includes("total")
                            ? cellColor(cell.value)
                            : "",
                        }}
                        className={`td ${
                          ci == 0 && cell?.value?.length > 16
                            ? "lengthytext"
                            : ""
                        }`}
                      >
                        {renderCell && (
                          <RenderCell
                            value={cell.value}
                            type={cell.column.unit}
                          />
                        )}
                      </div>
                    )
                  })}
                </div>
              </Fragment>
            )
          })}
          {groups && <GroupRow {...lastR} />}
        </div>

        {total && !canNextPage && (
          <div className="footer-table">
            <div
              className={`tr ${trClass}`}
              {...footerGroups[0].getHeaderGroupProps()}
            >
              {footerGroups[0].headers.map((column, i) => (
                <div
                  key={column.id}
                  className="td"
                  {...column.getHeaderProps()}
                >
                  {i == 0 && "Grand Total"}
                  {i != 0 &&
                    (!canNextPage ? (
                      <RenderCell
                        value={total[column.id]}
                        type={column.unit}
                        total={true}
                      />
                    ) : (
                      ""
                    ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Table
