import React, { useEffect, useState, useRef } from "react"
import { GoogleMap, Marker, LoadScriptNext } from "@react-google-maps/api"
import { REACT_APP_GOOGLE_MAP_API_KEY as MAP_API_KEY } from "config"
import api from "api"
import Loading from "shared/Loading"

const containerStyle = {
  height: "100%",
  width: "100%",
}

function MyComponent(props) {
  const { address } = props

  let [position, setPosition] = useState({ lat: 40.854885, lng: -88.081807 })
  const componentMounted = useRef(true)

  useEffect(() => {
    ;(async () => {
      if (address) {
        let response = await api.g_getLatLong({
          address,
          loading_key: "get_latlong",
        })
        if (componentMounted.current && response.results[0]) {
          setPosition(response.results[0].geometry.location)
        }
        return () => {
          // This code runs when component is unmounted
          componentMounted.current = false // (4) set it to false when we leave the page
        }
      }
    })()
  }, [address])

  return (
    <Loading id="get_latlong">
      <LoadScriptNext googleMapsApiKey={MAP_API_KEY} libraries={["places"]}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={position}
          zoom={18}
          options={{
            gestureHandling: "greedy",
          }}
        >
          <Marker position={position}></Marker>
        </GoogleMap>
      </LoadScriptNext>
    </Loading>
  )
}

export default React.memo(MyComponent)
