import React from "react"
import { useHistory } from "react-router-dom"
import { useHarsco } from "shared"

let enhancer = (Component) => (props) => {
  let history = useHistory()
  const { profile } = useHarsco()
  const goToAdminPages = (page) => {
    history.push(`/admin/${page}`)
  }

  return (
    <Component
      {...props}
      {...{
        goToAdminPages,
        profile,
      }}
    />
  )
}

export default enhancer
