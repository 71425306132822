import React from "react"
import enhancer from "./enhancer"
import Image from "shared/Image"
import UserTableGrid from "../Components/UserTableGrid"
import Input from "../Components/Input"
import Select from "../Components/Select"
import Pagination from "shared/Pagination"
import Loading from "shared/Loading"
import { FormattedMessage } from "react-intl"

const UserManagemt = (props) => {
  const {
    backToUserManagement,
    searchUser,
    fields,
    onSelectField,
    onInputChange,
    onSelectValue,
    open,
    handleSearchChange,
    customers,
    columnData,
    searchData,
    gotoPage,
    headers,
    submitted,
    userType,
    downloadUsers,
    resetOnSearch,
    clearAllData,
    user,
    roles,
    submitLink,
    cleanEarthStatus,
    error,
    resetCustomerId,
    resetUserType,
    customerDetails,
  } = props

  const { pageIndex, totalRecords = 0 } = searchData

  const onFieldChange = (event) => {
    onInputChange(event)
  }

  return (
    <div className="user-management">
      <div className="col-sm-6 col-md-9 col-lg-9 mt-4 mb-3">
        <div className="row m-0 ">
          <div
            className="dp-al-ctr back-link"
            onClick={() => backToUserManagement()}
          >
            <Image
              className=""
              src={"back-button.svg"}
              height="14px"
              width="14px"
              alt=""
            />
            <div className="p-14th underline">{`BACK`}</div>
          </div>
        </div>
        <div className="row m-0 admin-home pd-b-0">USER MANAGEMENT</div>
        <div className="row m-0 text-description pd-rl-1  mt-2">
          <span className="text-style m-r-h">
            <FormattedMessage id={"usermanagement.account"} />
          </span>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-3 dp-flx">
        <div className="col-sm-9 col-md-12 col-lg-12 text-left ">
          {!cleanEarthStatus && (
            <div className="col-sm-12 col-md-12 col-lg-12 pd-0 dp-flx">
              <div className="col-sm-5 col-md-3 col-lg-3 p-0 m-r-h">
                <div className="form-input-block">
                  <Select
                    name="customerId"
                    onSelectField={onSelectField}
                    open={open.customerId}
                    search={true}
                    placeholder={"Select Account"}
                    options={customers}
                    onSelectValue={onSelectValue}
                    value={fields.customerId}
                    handleSearchChange={handleSearchChange}
                    loadingId={"customer_search"}
                    searchField={"customerSearch"}
                    clear={resetCustomerId}
                    clsName={"search-text"}
                    parentCls={"input-text-search"}
                    searchClass={"place-label"}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-3 text-left p-0 m-r-h">
                <div className="form-input-block">
                  <Input
                    placeholder="Search by Last Name or Work Email"
                    name="searchText"
                    value={fields.searchText}
                    onFieldChange={onFieldChange}
                    clsName={"search-text p-0"}
                    parentCls={"input-text-search pad-l3th"}
                  />
                </div>
                <span className="field-error">{`${
                  !submitLink && error?.customerId
                    ? "Please select atleast one field"
                    : ""
                }`}</span>
              </div>
              <div className="col-sm-5 col-md-3 col-lg-3 p-0 m-r-h">
                <Select
                  name="userType"
                  onSelectField={onSelectField}
                  open={open.userType}
                  search={false}
                  placeholder={"Select User Type"}
                  options={userType}
                  onSelectValue={onSelectValue}
                  value={fields.userType}
                  handleSearchChange={handleSearchChange}
                  clsName={"search-text"}
                  parentCls={"input-text-search"}
                  searchClass={"place-label"}
                  clear={resetUserType}
                />
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 text-right button-align">
                <button
                  className="green-button m-r-1 "
                  onClick={() => {
                    gotoPage(1)
                    resetOnSearch()
                    searchUser()
                  }}
                >
                  {"SEARCH"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-3 dp-flx js-ctr">
        {user?.roleId !== roles.CUSTOMER_ADMIN && !submitted && (
          <div className="row m-0 pd-rl-1 mt-2">
            <span className="text-style m-r-h">
              <FormattedMessage id={"usermanagement.link"} />
            </span>
            <span
              className="text-style text-style-underline"
              onClick={() => clearAllData()}
            >
              <FormattedMessage id={"usermanagement.clickhere"} />{" "}
            </span>
          </div>
        )}
      </div>
      <div className="col-sm-3 col-md-12 col-lg-12 dp-flx">
        <div className="col-sm-3 col-md-6 col-lg-6 text-left"></div>
        <div className="col-sm-3 col-md-6 col-lg-6 text-right">
          {submitted && totalRecords > 0 && (
            <button
              className="green-button m-r-1"
              onClick={() => downloadUsers()}
            >
              {"Download"}
            </button>
          )}
        </div>
      </div>
      <Loading id={"custom_search"}>
        <div className="col-sm-12 col-md-12 col-lg-12 mt-4 mb-3">
          {" "}
          {submitted && totalRecords > 0 && (
            <UserTableGrid
              columnData={customerDetails}
              headers={headers}
              rows={columnData}
            />
          )}
          {submitted && totalRecords > 0 && (
            <Pagination
              {...{
                pageNo: pageIndex,
                totalRecords,
                gotoPage,
                data: searchData,
                pageSize: 20,
              }}
            />
          )}
          {submitted && totalRecords === 0 && !error?.customerId && (
            <div className="col-sm-12 col-md-12 col-lg-12 text-center pt-2 nodata">
              <FormattedMessage id="nodata.records" />
            </div>
          )}
        </div>
      </Loading>
    </div>
  )
}

export default enhancer(UserManagemt)
