import React from "react"
import Image from "shared/HelpImage/index"
import { useSelector } from "react-redux"

function ViewingAnnouncements() {
  const { changeCustomer } = useSelector((state) => state)
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Viewing Announcements</h3>
      <p className="text-justify pl-3 pt-3">
        When first logging in you will see a popup screen displaying any new
        announcements you have not seen yet.
      </p>
      <div className="img-section">
        <Image
          src={
            changeCustomer?.customer?.businessSegmentId === 3
              ? "mi_announcement.png"
              : "viewing_announcements.png"
          }
          alt=""
        />
      </div>
      <p className="text-justify pl-3 pt-3">
        In the upper-right corner of the display, you will see an indication of
        how many messages you have to view. In this example, there are five.
        Clicking on the right-pointing and left-pointing arrows will scroll
        among however many messages you have to view.
      </p>
      <p className="text-justify pl-3 pt-3">
        In the lower-left corner, you will see a series of dots also indicating
        how many messages you have to view. The highlighted dot indicates which
        message you are looking at. Clicking on any of the dots will take you
        directly to the selected message.
      </p>
      <p className="text-justify pl-3 pt-3">
        Clicking on OK <Image src="announcement_ok.svg" /> will remove the
        message display and return you to the Dashboard.
      </p>
      <div className="img-section">
        <Image
          src={
            changeCustomer?.customer?.businessSegmentId === 3
              ? "mi_announcement_marque.png"
              : "announcement_banner_1.png"
          }
          alt=""
        />
      </div>
    </div>
  )
}
export default ViewingAnnouncements
