import React, { Fragment } from "react"
import enhancer from "./enhancer"
import Administrator from "./Admin"
import StoreManager from "./StoreManager"

function Retail(props) {
  let { isStoreManager } = props
  return (
    <Fragment>{isStoreManager ? <StoreManager /> : <Administrator />}</Fragment>
  )
}
export default enhancer(Retail)
